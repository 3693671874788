import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container } from "react-grid-system";
import { Element, HBox, VBox } from "react-stylesheet";
import styled from "styled-components";
import { LanguageContext, Translation } from "../../../languages/";
import { DICT } from "../../../languages/dictionary-names";
import { AuthContext } from "../../../providers/auth-provider";
import { colors, font_size, gutters, viewport } from "../../00_base/variables";
import { Anchor, Image, Paragraph, Span } from "../../01_atoms";
import { ThemeContext } from "../../../providers/theme-provider";

const BOTTOM_BACKGROUND =
  "https://storage.googleapis.com/assets.youdoadventures.com/website/app-footer-bg.svg";
const FACEBOOK =
  "https://storage.googleapis.com/assets.youdoadventures.com/website/facebook-white.png";
const YOUTUBE =
  "https://storage.googleapis.com/assets.youdoadventures.com/website/youtube-white.png";
const INSTAGRAM =
  "https://storage.googleapis.com/assets.youdoadventures.com/website/instegram-white.png";
const FACEBOOK_BLACK =
  "https://storage.googleapis.com/assets.youdoadventures.com/website/facebook.png";
const YOUTUBE_BLACK =
  "https://storage.googleapis.com/assets.youdoadventures.com/website/youtube.png";
const INSTAGRAM_BLACK =
  "https://storage.googleapis.com/assets.youdoadventures.com/website/instegram.png";

const AppFooter = ({ isToolsMode, isAdminPage }) => {
  const { isAdmin } = useContext(AuthContext);
  const { userLanguage } = useContext(LanguageContext);

  const location = useLocation();
  const minimize = location.pathname.includes("create-adventure");
  const { mode } = useContext(ThemeContext);

  const darkMode = mode === "dark";

  return (
    <FooterStyle
      id={"footer"}
      minimizeFooter={minimize}
      style={{
        display: isToolsMode ? "none" : "inherit",
        position: isAdminPage && "relative",
        zIndex: isAdminPage && 100,
      }}
    >
      <FooterWrapper>
        <Container fluid>
          {!minimize ? (
            <VBox
              justifyContent={"center"}
              alignItems={"center"}
              className={"d-block d-md-flex"}
            >
              <Element
                margin={"auto"}
                className={"m-auto"}
                style={{ flex: "1 0 0" }}
              >
                <HBox
                  flexWrap={"wrap"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  className={"py-3"}
                >
                  <FooterLink>
                    <Link to={`/${userLanguage}/faq`}>
                      <Translation id={DICT.FAQ} />
                    </Link>
                  </FooterLink>
                  <FooterLink>
                    <Link to={`/${userLanguage}/contact-us`}>
                      <Translation id={DICT.CONTACT_US} />
                    </Link>
                  </FooterLink>
                  <FooterLink>
                    <Link to={`/${userLanguage}/terms`}>
                      <Translation id={DICT.TERMS} />
                    </Link>
                  </FooterLink>

                  <FooterLink style={{ width: "auto" }} className={"px-3"}>
                    <Anchor
                      href={"https://www.facebook.com/YOUDOBOX/"}
                      target={"_blank"}
                    >
                      <Image
                        width={25}
                        src={darkMode ? FACEBOOK_BLACK : FACEBOOK}
                      />
                    </Anchor>
                  </FooterLink>
                  <FooterLink style={{ width: "auto" }} className={"px-3"}>
                    <Anchor
                      href={"https://www.instagram.com/youdobox/"}
                      target={"_blank"}
                    >
                      <Image
                        width={25}
                        src={darkMode ? INSTAGRAM_BLACK : INSTAGRAM}
                      />
                    </Anchor>
                  </FooterLink>
                  {isAdmin && 11 === 22 && (
                    <FooterLink style={{ width: "auto" }} className={"px-3"}>
                      <Anchor
                        href={"https://www.instagram.com/youdobox/"}
                        target={"_blank"}
                      >
                        <Image
                          width={25}
                          src={darkMode ? YOUTUBE_BLACK : YOUTUBE}
                        />
                      </Anchor>
                    </FooterLink>
                  )}
                </HBox>

                <HBox
                  flexWrap="wrap"
                  justifyContent="center"
                  alignItems="center"
                  className="d-md-flex py-3"
                >
                  <FooterLink>
                    <Link to={`/${userLanguage}`}>
                      <Translation id={DICT.ESCAPE_ROOMS} />
                    </Link>
                  </FooterLink>
                  <FooterLink>
                    <Link to={`/${userLanguage}`}>
                      <Translation id={DICT.PRODUCTS} />
                    </Link>
                  </FooterLink>
                  <FooterLink>
                    <Link to={`/${userLanguage}/customize-adventures`}>
                      <Translation id={DICT.ADVENTURES} />
                    </Link>
                  </FooterLink>
                  <FooterLink>
                    <Link to={`/${userLanguage}/riddles-store`}>
                      <Translation id={DICT.RIDDLES} />
                    </Link>
                  </FooterLink>
                  <FooterLink>
                    <Link to={`/${userLanguage}/customize-adventures`}>
                      <Translation id={DICT.PERSONAL_BOXES} />
                    </Link>
                  </FooterLink>
                  <FooterLink>
                    <Link to={`/${userLanguage}/digital-adventures`}>
                      <Translation id={DICT.DIGITAL} />
                    </Link>
                  </FooterLink>
                </HBox>
                <VBox
                  justifyContent={"center"}
                  className={"align-items-center mt-4"}
                  style={{ flex: "1 0 0" }}
                >
                  <Span
                    color={mode === "light" ? colors.white : colors.black}
                    size={"xs"}
                  >
                    <Translation id={DICT.ALL_RIGHTS} />
                  </Span>
                </VBox>
              </Element>
            </VBox>
          ) : (
            <VBox
              justifyContent={"center"}
              className={"align-items-center mt-5 mt-md-0"}
              style={{ flex: "1 0 0" }}
            >
              {!minimize && (
                <HBox justifyContent={"center"} marginBottom={gutters.sm}>
                  <Element style={{ margin: `0 ${gutters.xxs}` }}>
                    <Anchor
                      href={"https://www.facebook.com/YOUDOBOX/"}
                      target={"_blank"}
                    >
                      <Image
                        width={20}
                        src={darkMode ? FACEBOOK_BLACK : FACEBOOK}
                      />
                    </Anchor>
                  </Element>

                  {isAdmin && (
                    <Element style={{ margin: `0 ${gutters.xxs}` }}>
                      <Image
                        width={20}
                        src={darkMode ? YOUTUBE_BLACK : YOUTUBE}
                      />
                    </Element>
                  )}

                  <Element style={{ margin: `0 ${gutters.xxs}` }}>
                    <Anchor
                      href={"https://www.instagram.com/youdo_escape_box/"}
                      target={"_blank"}
                    >
                      <Image
                        width={20}
                        src={darkMode ? INSTAGRAM_BLACK : INSTAGRAM}
                      />
                    </Anchor>
                  </Element>
                </HBox>
              )}
              <Span color={mode === "light" ? colors.white : colors.black}>
                <Translation id={DICT.ALL_RIGHTS} />
              </Span>

              {/*<YoudoAdventuresLogo width={'80px'}/>*/}
            </VBox>
          )}
        </Container>

        {!minimize && 1 === 2 && (
          <Paragraph className={"text-center"} style={{ fontSize: "1.1rem" }}>
            Developed by{" "}
            <a
              href={"https://tf-interactive.com"}
              target={"_blank"}
              rel="noopener noreferrer"
              style={{
                fontSize: "1.1rem",
                color: mode === "light" ? "#fff" : "#000",
              }}
            >
              TF-Interactive
            </a>
          </Paragraph>
        )}
      </FooterWrapper>
    </FooterStyle>
  );
};

export default AppFooter;

const FooterStyle = styled.div`
  width: 100%;
  padding: 180px 0 0;

  position: relative;
  z-index: 1;
  background-image: url(${BOTTOM_BACKGROUND});
  background-position: top center;
  background-repeat: no-repeat;
  .theme-dark & {
    background-color: ${colors.black};
  }
`;
const FooterWrapper = styled.div`
  background-color: ${colors.black};
  .theme-dark & {
    background-color: ${colors.white};
  }
  @media screen and (min-width: ${viewport.md}) {
    padding: ${gutters.lg} ${gutters.xl};
  }
`;
const FooterLink = styled.div`
  text-align: center;
  padding: ${gutters.xs};

  a,
  p {
    font-size: ${font_size.xs};
    color: ${colors.white};
    white-space: nowrap;
    text-align: center;
    .theme-dark & {
      color: ${colors.black};
    }
    &:hover {
      text-decoration: underline;
    }
  }
  @media screen and (min-width: ${viewport.md}) {
    width: auto;
    padding: 0 10px;
    text-align: right;
    a,
    p {
      text-align: right;
    }
  }
`;
