import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "../../../01_atoms";
import { SingleFrameHeader } from "./single-frame-header";
import { SingleFrameBody } from "./single-frame-body/single-frame-body";
import {
  closeActiveFrame,
  updateFrame,
  updateFrameTool,
} from "../../../../store/actions/frames-actions";
import { selectActiveFrame } from "../../../../store/selectors";

export const FrameContext = React.createContext({
  handleFrameChange: null,
  handleToolDataChange: null,
});

export const SingleFrame = ({
  frame,
  handleCloseFrame,
  handleChange,
  activeTab = 0,
  riddle,
}) => {
  const {
    id,
    title,
    categorydata = {},
    modals,
    clues = [],
    thumbnail,
    instructions = {},
    tips = [],
    editable,
    categories = [],
  } = frame;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;

  const closeFrame = () => {
    dispatch(closeActiveFrame());
    navigate(pathname.replace(pathname.split("/").pop(), ""));
  };

  const handleFrameChange = useCallback(
    (name, value) => {
      dispatch(updateFrame(id, name, value));
    },
    [dispatch, id]
  );

  const handleToolDataChange = useCallback(
    (name, value) => {
      dispatch(updateFrameTool(id, name, value));
    },
    [dispatch, id]
  );

  const bodyProps = {
    id,
    riddle,
    frame,
    categories,
    modals,
    categorydata,
    clues,
    instructions,
    tips,
    editable,
  };

  const BACK_BUTTON = (
    <Button
      className="frame-body-close"
      onClick={handleCloseFrame || closeFrame}
      value="חזור"
    />
  );

  const FRAME_HEADER = (
    <SingleFrameHeader title={title} thumbnail={thumbnail} />
  );

  const FRAME_BODY = <SingleFrameBody {...bodyProps} />;

  return (
    <FrameContext.Provider value={{ handleFrameChange, handleToolDataChange }}>
      <div className="frame-body" onClick={(e) => e.stopPropagation()}>
        {BACK_BUTTON}
        {FRAME_HEADER}
        {FRAME_BODY}
      </div>
    </FrameContext.Provider>
  );
};
