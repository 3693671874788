import React from 'react';
import {Col, Container, Row} from 'react-grid-system';
import {Element} from 'react-stylesheet';
import {Translation} from "../../../../languages/";
import {DICT} from "../../../../languages/dictionary-names";
import {gutters} from '../../../00_base/variables';
import {Circle, Heading, Image} from '../../../01_atoms';
import {Train} from "../../../03_organisms";
import LoadingCircle from "../../../03_organisms/loading-sections/loading-circle";

class PageCustomers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageData: {
                title: {rendered: ''}
            },
            isLoading: true,
            customers: ["", "", "", "", "", "", "", "", "", "", "", "", "", ""]
        }

    }

    componentDidMount() {
        fetch('https://admin.youdoadventures.com/wp-json/wp/v2/pages/864', {
            method: 'GET',
        }).then(
            response => response.json()
        ).then(
            success => {
                const acf = success.acf.customers;
                const customers = [];

                for (let i = 0; i < 100; i++) {
                    const current = acf[i + 1];
                    if (current && current.logo) {
                        customers.push(current)
                    }
                }

                this.setState({
                    pageData: success,
                    customers: customers,
                    isLoading: false
                })
            }
        ).catch(
            error => console.log(error)
        );
    }

    render() {
        const {customers, isLoading} = this.state;
        const trainItems = customers.map((customer, c) => {
            const {logo} = customer;
            if (isLoading) {
                return <LoadingCircle size={100}/>
            }
            return <div key={c} className={'d-flex justify-content-center align-items-center p-4'}>
                <Image src={logo} width={"100%"}/>
            </div>
        });
        return (
            <Element padding={`${gutters.xl} 0`}>
                {/* <MobileOnly>
                    <h4><Translation id={DICT.CUSTOMERS_TITLE}/></h4>
                    <Train items={trainItems} itemSize={140}/>
                </MobileOnly> */}
                {/* <MobileHidden> */}
                    <Container className="p-0">
                        <h3 className={"color-info mb-0"}><Translation id={DICT.CUSTOMERS_TITLE}/></h3>
                        {/*<p><Translation id={DICT.CUSTOMERS_DESCRIPTION}/></p>*/}
                        <Row>
                            {customers.map((customer, c) => {
                                const {name, logo} = customer;

                                return (
                                    <Col xs={6} sm={4} md={3} lg={2} key={c}
                                         className={'d-flex flex-column align-items-center py-5'}>
                                        {isLoading ?
                                            <LoadingCircle size={145}/>
                                            :
                                            <Circle size={145}
                                                    className={'d-flex justify-content-center align-items-center p-4'}
                                                    background={'#ffffff'}>
                                                <Image src={logo}/>
                                            </Circle>
                                        }
                                        <Heading tag={'h5'}
                                                 textAlign={'center'}
                                                 className={'mt-3 mb-4 d-none'}>
                                            {name}
                                        </Heading>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Container>
                {/* </MobileHidden> */}
            </Element>
        )
    }
}

export default PageCustomers;