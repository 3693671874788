import React, {useContext, useEffect, useState} from 'react';
import {Element, HBox} from 'react-stylesheet';
import {MdAdd, MdRemove} from "react-icons/md";
import classNames from "classnames";
import {ThemeContext} from "../../../../providers/theme-provider";
import {LanguageContext} from "../../../../languages";
import {colors, gutters} from "../../../00_base/variables";
import {BackgroundImage, Button, Heading, LoaderPageContent} from "../../../01_atoms";
import "../../../../assets/styles/screen-faq.scss";

const FAQ_BACKGROUND = "https://storage.googleapis.com/assets.youdoadventures.com/website/faq_bg.svg";

const ScreenFaq = ({sections, isSection}) => {
    const {mode} = useContext(ThemeContext);
    const {rtl} = useContext(LanguageContext);

    const darkMode = mode === "dark";


    const [faq, setFaq] = useState(['', '', '', '', '', '', '', '', '', '', '']);
    const [activeAnswer, setActiveAnswer] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch('https://admin.youdoadventures.com/wp-json/wp/v2/pages/751', {
            method: 'GET',
        }).then(
            response => response.json()
        ).then(
            success => {
                setFaq(success.acf);
                setIsLoading(false)
            }
        ).catch(
            error => console.log(error)
        );
    }, []);

    const showAnswer = (index) => {
        setActiveAnswer(activeAnswer === index ? -1 : index);
    }

    const titleTag = isSection ? "h3" : "h1";
    const items = sections?.length > 0 ? sections : Object.values(faq);

    return (
        <Element width={"100%"}
                 padding={`0 ${isSection ? 0 : "20px"} 30px`}
                 className={classNames("screen-faq", {container: !isSection})}>
            <div>

                {isLoading ?
                    <Element padding={`18rem 0 0`}>
                        <LoaderPageContent/>
                    </Element>
                    :
                    <BackgroundImage url={FAQ_BACKGROUND} size={300} position={"top left"}>
                        <Heading tag={titleTag} textAlign={"center"} isLoading={isLoading}>
                            שאלות נפוצות
                        </Heading>
                        <div className={"bg-white"}>
                            {items.map((item, i) => {
                                const isActive = activeAnswer === i;
                                const borderBottom = `${(isActive || i === items.length - 1) ? 4 : 0}px solid ${colors[darkMode ? "white" : "black"]}`
                                return (
                                    <Element key={i}>
                                        <HBox alignItems={'center'}
                                              padding={" 20px"}
                                              border={`4px solid ${colors[darkMode ? "white" : "black"]}`}
                                              borderBottom={borderBottom}
                                              cursor={'pointer'}
                                              onClick={() => showAnswer(i)}>
                                            <Button onClick={() => showAnswer(i)}
                                                    padding={`0 ${!rtl ? gutters.sm : 0} 0 ${rtl ? gutters.sm : 0}`}
                                                    background={"transparent"}
                                                    className={"color-black"}
                                                    fontSize={"50px"}>
                                                {isActive ? <MdRemove/> : <MdAdd/>}
                                            </Button>
                                            <Heading tag={'h5'}
                                                     className={"color-black"}
                                                     style={{
                                                         fontSize: "28px",
                                                         width: "100%",
                                                         marginTop: 0,
                                                         marginBottom: 0,
                                                     }}>
                                                {item.q || item.question}
                                            </Heading>
                                        </HBox>
                                        <div
                                            className={classNames("faq-content-wrapper color-black", {active: isActive})}>
                                            <p dangerouslySetInnerHTML={{__html: isActive ? (item.a || item.answer) : ''}}
                                            className={"color-black bg-white"}/>
                                        </div>
                                    </Element>
                                )
                            })}
                        </div>
                    </BackgroundImage>
                }
            </div>

        </Element>
    )
}

export default ScreenFaq;