import React, {useEffect} from 'react';
import {useStopwatch} from 'react-timer-hook';

const RiddleTimer = ({stop, finalTime}) => {
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
    } = useStopwatch({autoStart: true});

    const M = minutes < 10 ? `0${minutes}` : minutes;
    const S = seconds < 10 ? `0${seconds}` : seconds;

    useEffect(() => {
        if (stop) {
            stop(M, S)
        }
    }, [stop]);
    
    return (
        <div style={{textAlign: 'center', fontSize: "5rem"}}>
            {/*<span>{days}</span>:<span>{hours}</span>:*/}

            {finalTime ?
                <span style={{...spanStyle, fontWeight:"bold"}} className={spanClassName}>זמן פיתרון החידה:<br/> {finalTime}</span> :
                <>
                    <span style={spanStyle} className={spanClassName}>{M}</span>:
                    <span style={spanStyle} className={spanClassName}>{S}</span>
                </>
            }
        </div>
    );
}

const spanClassName = "color-black";

const spanStyle = {
    fontSize: "5rem"
}

export default RiddleTimer;