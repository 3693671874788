import React, {useCallback, useEffect, useState} from "react";
import {dictionaryList, LanguageContext, languageOptions} from "../languages";
import {useNavigate,useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {createLanguage, setAllLanguages} from "../store/actions/languages-actions";
import {languageObject} from "../store/models/build-models";
import YoudoAdventuresLogo from "../components/03_organisms/youdo-logo/youdo-logo";
// import en from "../languages/mock/en.json";
// import he from "../languages/mock/he.json";

export const LanguageProvider = ({children}) => {
        const defaultLanguage = window.localStorage.getItem('rcml-lang');

        const [userLanguage, setUserLanguage] = useState(defaultLanguage || 'he');
        const [languages, setLanguages] = useState(null);
        const [dictionary, setDictionary] = useState(dictionaryList["he"]);
        const [isLoading, setIsLoading] = useState(true);
        const rtl = userLanguage === "he" || userLanguage === "ar";

        const navigate = useNavigate();
        const location = useLocation();
        const dispatch = useDispatch();
        const pathname = location.pathname;

        // const isRouteAdmin = pathname.includes("/admin/");
        const isResetPassword = pathname.includes("/reset-password");

        useEffect(() => {
            dispatch(setAllLanguages(0, res => {
                setLanguages(res)
            }));
        }, [dispatch]);

        const updateRouteByLanguage = (lang) => {

            // history.push(url.replace("//", "/"));

            if (!isResetPassword && !pathname.includes("he") && !pathname.includes("en")) {
                const url = pathname.substring(0, 1) + lang + "/" + pathname.substring(1);
                navigate(url.replace("//", "/"));
                return
            }
            if (isResetPassword && !pathname.includes("he") && !pathname.includes("en")) {
                const url = `/${lang}` + pathname + location.search;
                navigate(url.replace("//", "/"));

            }
        };

        const filterByLanguage = (arr = []) => {
            return arr.filter(item => item.language_id === languages.find(lang => lang.name === userLanguage).id)
        };
        const getLanguageTranslations = useCallback((name, cb) => {
            switch (name) {
                case "he":
                    cb({});
                    break;
                case "en":
                    cb({});
                    break;
                default:
                    cb({});
            }
            // *** IN THE FUTURE WE'LL GET IT FROM SERVER
            // if (name === "he") {
            //     cb(en);
            // }
            //
            // const token = localStorage.getItem('token');
            // const url = `https://storage.googleapis.com/assets.youdoadventures.com/${name}.json`;
            // const fetchData = ({
            //     method: "GET",
            //     headers: {
            //         "Content-Type": "application/json; charset=utf-8",
            //         "access-Token": token
            //     }
            // });
            //
            // fetch(url, fetchData).then(
            //     response => {
            //         if (!response.ok) {
            //             cb(dictionaryList[userLanguage]);
            //             throw new Error('Something went wrong');
            //         }
            //         return response.json()
            //     }
            // ).then(res => {
            //         cb(res)
            //     }
            // ).catch(error => {
            //         cb(dictionaryList[userLanguage]);
            //     }
            // )
        });

        useEffect(() => {
            updateRouteByLanguage(userLanguage);

            getLanguageTranslations(
                userLanguage,
                res => {
                
                    
                    // setDictionary(res);
                    setIsLoading(false);
                });
        }, []);

        const userLanguageChange = selected => {
            setIsLoading(true);
            const newLanguage = languageOptions[selected] ? selected : 'en';
            getLanguageTranslations(
                newLanguage,
                res => {
                    setDictionary(res);
                    setUserLanguage(newLanguage);
                    setIsLoading(false);
                    const {pathname} = location;
                    let url;
                    if (!pathname.includes("he") && !pathname.includes("en")) {
                        url = `/${newLanguage}` + pathname + location.search;
                    } else {
                        url = pathname.substring(0, 1) + newLanguage + pathname.substring(3);
                    }
                    navigate(url)
                    setTimeout(setIsLoading, 1000);
                });
            window.localStorage.setItem('rcml-lang', newLanguage);
        };

        const createNewLanguage = (language, cb) => {
            dispatch(createLanguage(
                language,
                response => {
                    setLanguages(languages.concat(languageObject(response.language)))
                    if (cb) {
                        cb(response)
                    }
                }));
        };

        const provider = {
            languages,
            userLanguage,
            createLanguage: createNewLanguage,
            dictionary,
            rtl,
            getLanguageTranslations,
            filterByLanguage,
            userLanguageChange
        };

        const className = `lang-${userLanguage} lang-${rtl ? "rtl" : "ltr"}`;
        const loader = (
            <div className={"d-flex justify-content-center align-items-center"}
                 style={{height: "100vh", width: "100vw"}}>
                <YoudoAdventuresLogo/>
            </div>
        );

        return (
            <LanguageContext.Provider value={provider}>
                <div className={className}>
                    {isLoading ? loader : children}
                </div>
            </LanguageContext.Provider>
        );
    }
;