import React from 'react';

class IconClueX extends React.Component {
    render() {
        const {size, color, onClick, style, plus, minus, toggle, className} = this.props;
        return (
            <svg width={size} height={size} className={`icon-close ${className ? className : ''}`} viewBox="0 0 17 17" onClick={onClick && onClick} style={{...style && style, transform: (plus || minus) && 'rotate(45deg)', transition: 'transform .4s'}} data-toggle={toggle && toggle}>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
                    <g transform="translate(-1321.000000, -208.000000)" fillRule="nonzero" stroke={color ? color : '#151515'} strokeWidth="3">
                        <g transform="translate(-17.000000, 0.000000)">
                            <g transform="translate(569.000000, 181.000000)">
                                <g transform="translate(771.000000, 29.000000)">
                                    {!minus && <path d="M13.4350288,13.4350288 L8.8817842e-16,-3.97903932e-13" id="Line-4" />}
                                    <path d="M13.4350288,13.4350288 L5.55999691e-13,-5.41788836e-14" id="Line-4" transform="translate(6.717514, 6.717514) scale(-1, 1) translate(-6.717514, -6.717514) "/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
    static defaultProps = {
        size: '17px'
    }
}

export default IconClueX;