import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import PropTypes from "prop-types";
import {colors} from '../../00_base/variables';

const Icon = props => <FontAwesomeIcon {...props} />;

Icon.defaultProps = {
    color: colors.black
};
Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.oneOf(["lg","xs","sm","1x","2x","3x","4x","5x","6x","7x","8x","9x","10x"]),
    flip: PropTypes.oneOf(['horizontal', 'vertical', 'both']),
    rotation: PropTypes.number,
    spin: PropTypes.bool,
    pulse: PropTypes.bool,
    border: PropTypes.bool,
    pull: PropTypes.oneOf(['left', 'right']),
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default Icon;

// Font Awesome Docs: https://github.com/FortAwesome/react-fontawesome#get-started