import React from 'react';
import {Loader} from './simple-loader-styles';

class SimpleLoader extends React.Component {
    render() {
        return (
            <Loader {...this.props}/>
        )
    }
    static defaultProps = {
        color: '#07d',
        size: '30'
    }
}

export default SimpleLoader;

