export const dateFormat = (createdAt) => {
    const date = new Date(createdAt);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear() - 2000;
    return `${day}.${month}.${year}`;
};

export const getDayName = (date) => {
    const formatDate = new Date(date)
    let dayName;
    switch (formatDate.getDay()) {
        case 1:
            dayName = "שני";
            break;
        case 2:
            dayName = "שלישי";
            break;
        case 3:
            dayName = "רביעי";
            break;
        case 4:
            dayName = "חמישי";
            break;
        case 5:
            dayName = "שישי";
            break;
        case 6:
            dayName = "שבת";
            break;
        default:
            dayName = "ראשון";
    }
    return dayName;

}