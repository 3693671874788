import React from "react";
import { Page } from "../../../04_layout";
import Register from "./register";
import { useLocation, useParams } from "react-router-dom";

const ScreenRegister = () => {
  const params = useParams();
  const location = useLocation();
  const title = location?.title;
  const goAfterLogin = location?.goAfterLogin;
  const language = params?.lang || "he";
  return (
    <Page
      style={{ justifyContent: "center" }}
      className={`registration language-${language}`}
    >
      <Register title={title} goAfterLogin={goAfterLogin} language={language} />
    </Page>
  );
};

export default ScreenRegister;
