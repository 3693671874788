import styled from "styled-components";
import {viewport} from "../../00_base/variables";


const cssObject = (
    {
        width,
        height,
        paddingVertical,
        paddingHorizontal,
        marginVertical,
        marginHorizontal,
        backgroundColor,
        backgroundColorHover,
        backgroundColorActive,
        color,
        colorHover,
        colorActive,
        borderColor,
        borderColorHover,
        borderColorActive,
        fontSize,
        backgroundImage,
        backgroundImageDesktop,
        backgroundPosition = "center center",
        borderRadius,
        borderWidth,
        borderStyle = "solid",
        cursor,
        boxShadow,
        display
    }) => `
        box-sizing: border-box;
        ${display ? `display: ${display}` : ""};
        ${width ? `width: ${width}px` : ""};
        ${height ? `height: ${height}px` : ""};
        
        ${paddingVertical && paddingHorizontal ? `padding: ${paddingVertical}px ${paddingHorizontal}px` : ""};
        ${paddingVertical ? `padding-top: ${paddingVertical}px` : ""};
        ${paddingVertical ? `padding-bottom: ${paddingVertical}px` : ""};
        ${paddingHorizontal ? `padding-left: ${paddingHorizontal}px` : ""};
        ${paddingHorizontal ? `padding-right: ${paddingHorizontal}px` : ""};
        
        ${marginVertical && marginHorizontal ? `padding: ${marginVertical}px ${marginHorizontal}px` : ""};
        ${marginVertical ? `margin-top: ${marginVertical}px` : ""};
        ${marginVertical ? `margin-bottom: ${marginVertical}px` : ""};
        ${marginHorizontal ? `margin-left: ${marginHorizontal}px` : ""};
        ${marginHorizontal ? `margin-right: ${marginHorizontal}px` : ""}; 
        
        ${backgroundColor ? `background-color: ${backgroundColor}` : ""};
        ${color ? `color: ${color}` : ""};
        ${backgroundImage ? `background-image: url(${backgroundImage})` : ""}; 
        background-position: ${backgroundPosition};

        ${cursor ? `cursor: ${cursor}` : ""};
        ${fontSize ? `font-size: ${fontSize}px` : ""};
        
        ${borderColor ? `border-color: ${borderColor}` : ""};
        ${borderWidth ? `border-width: ${borderWidth}px` : ""};
        ${(borderStyle && borderWidth) ? `border-style: ${borderStyle}` : ""};
        ${borderRadius ? `border-radius: ${borderRadius}px` : ""};
        ${boxShadow ? `box-shadow: ${boxShadow}` : ""};
        
        ${(backgroundColorHover || colorHover || borderColorHover) &&
            `&:hover {
                ${backgroundColorHover ? `background-color: ${backgroundColorHover}` : ""};
                ${colorHover ? `color: ${colorHover}` : ""};
                ${borderColorHover ? `border-color: ${borderColorHover}` : ""};
            }`
        }
        
        ${(backgroundColorActive || colorActive || borderColorActive) &&
            `&.active {
                ${backgroundColorActive ? `background-color: ${backgroundColorActive}` : ""};
                ${colorActive ? `color: ${colorActive}` : ""};
                ${borderColorActive ? `border-color: ${borderColorActive}` : ""};
            }`
        }
`;

export const StyledDiv = styled.div`
    &.${props => props.className.split(" ")[0]}{
        ${props => cssObject(props)};
        ${props => `
            ${props.backgroundImageDesktop ? `        
                @media (min-width:${viewport.md}) {
                    background-image: url(${props.backgroundImageDesktop});
                ` : ""
            };
        `
    }
`;

export const StyledButton = styled.button`
    outline: 0;
    &.${props => props.className}{
        ${props => cssObject(props)}
    }
`;
