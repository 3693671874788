import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../../00_base/variables/v-colors';
import Label from '../../../01_atoms/text/a-label';
import Span from '../../../01_atoms/text/a-span';
import {fieldStyle} from '../field-style/m-field-style';

const RADIO_CHANGE = 'RADIO_CHANGE';

const inputReducer = (state, action) => {
    switch (action.type) {
        case RADIO_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid
            }
        default:
            return state
    }
}

const FieldRadio = ({
    name,
    label,
    options,
    dir,
    defaultValue,
    required,
    errorMessage,
    changeHandler,
    initialValue,
    initiallyValid,
}) => {
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: initialValue ? initialValue : '',
        isValid: initiallyValid,
    })

    useEffect(() => {
        changeHandler(name, inputState.value, inputState.isValid);
    }, [inputState, changeHandler, name]);

    const handleInputChange = event => {
        const value = event.target.value;
        let isValid = true;
        if (required && value.trim().length === 0) { isValid = false; }

        dispatch({
            type: RADIO_CHANGE,
            value: value,
            isValid: isValid
        })
    };

    return (
        <div style={fieldStyle.control}>
            <Label style={fieldStyle.label} value={label} />

            <div style={{ ...fieldStyle.container, ...fieldStyle[dir] }}>
                {options.map((option, index) => {
                    return <div key={index} style={radioWrapper}>
                        <label style={radioLabel}>
                            <input
                                checked={inputState.value === option && 'checked'}
                                type="radio"
                                value={option}
                                onChange={handleInputChange}
                                name={name} />
                            {option}
                        </label>
                    </div>
                })}
            </div>

            {!inputState.isValid && <Span value={errorMessage} />}
        </div>
    )
}

const
    radioWrapper = {
        display: 'flex',
        // padding: `${gutters.sm} ${gutters.md}`,
        marginRight: '1rem',
        borderWidth: 0,
        borderBottomWidth: '.1rem',
        borderBottomColor: colors.border
    },
    radioLabel = {
        display: 'flex',
    };

FieldRadio.defaultProps = {
    value: '',
    initialValue: '',
    initiallyValid: true,
    dir: 'horizontal'
};

FieldRadio.propTypes = {
    name: PropTypes.string.isRequired,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.array.isRequired,
    dir: PropTypes.oneOf(['horizontal', 'vertical']),
    errorMessage: PropTypes.string,
    changeHandler: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    initiallyValid: PropTypes.bool,
    required: PropTypes.bool,
};

export default FieldRadio; 