import React, {useContext, useEffect, useReducer, useState} from 'react';
import styled from 'styled-components';
import {VBox} from 'react-stylesheet';
import {Container} from 'react-grid-system';
import PropTypes from "prop-types";
import {colors} from '../../00_base/variables';
import Train from '../../03_organisms/train/train';
import {BackgroundImage, Button, Image, Paragraph} from '../../01_atoms';
import {USER_ANSWERS} from "./gallery-local-storage";
import {FrameContext} from "../../05_screens/tool/screen-tool";


const answersReducer = (state, action) => {
    state[action.index] = action.value + "";
    localStorage.setItem(USER_ANSWERS, state);
    return state;
}

const ToolGallery = (
    {
        items = [],
        userData,
        screenBackground,
        screenBackgroundImage,
        spaceBetweenImages = 12,
        framesPadding = 60,
        borderColor,
        borderWidth,
        numberBackground = 'rgba(0,0,0,0.9)',
        direction,
        isRotate,
        handleFrameFinish,
        handleFrameContinue,
        advancedDataType,
        gallery_view,
        nextFrameButton
    }) => {
    const localUserAnswers = localStorage.getItem(USER_ANSWERS)?.split(",");
    const [chosenAnswers, setChosenAnswers] = useReducer(answersReducer, localUserAnswers || items[0]?.images?.map(item => -1))
    const [warningMessage, setWarningMessage] = useState("");
    const [activeImage, setActiveImage] = useState(0);
    const frameContext = useContext(FrameContext);
    const isResolve = frameContext.isFrameFinish;

    useEffect(() => {
        handleFrameFinish();
    }, [handleFrameFinish]);

    const AdvancedList = ({list, index}) => {
        const [activeItem, setActiveItem] = useState(parseInt(chosenAnswers[index]));

        useEffect(() => {
            setChosenAnswers({index: index, value: activeItem})
        }, [activeItem, index])

        return (
            <ul>
                {list.map((item, listItemIndex) => {
                    return <li className={activeItem === listItemIndex ? "active" : ""}
                               onClick={() => !isResolve && setActiveItem(activeItem === listItemIndex ? null : listItemIndex)}
                               key={listItemIndex}>{item}</li>
                })}
            </ul>
        )
    }
    const [activeGallery, setActiveGallery] = useState(null);
    const userImages = userData;
    const gridView = gallery_view === "grid";
    const galleryView = gallery_view === "gallery";
    const isHorizontal = direction === "horizontal";

    const handleSubmit = () => {
        if (chosenAnswers.filter(item => item !== null && item >= 0).length < chosenAnswers.length) {
            setWarningMessage("יש לקבל החלטה על כל החשודים");
            return;
        }

        if (chosenAnswers[1] !== "0" || chosenAnswers[3] !== "0") {
            setWarningMessage("בחירה שגויה נסו שוב")
            return;
        }
        setWarningMessage("");
        handleFrameFinish();
        handleFrameContinue();
    };

    const getSquareContent = (value, index) => {
        if (advancedDataType === "list" && Array.isArray(value)) {
            return <AdvancedList list={value} index={index}/>
        }
        return (
            <span>{value}</span>
        )

    }

    if (items.length === 1) {
        const imagesWrapperStyle = isHorizontal ? containerHorizontal : containerVertical;
        const dynamicWrapperStyle = {
            backgroundColor: screenBackground,
            backgroundImage: screenBackgroundImage,
            border: `${borderColor} ${borderWidth}px solid`,
            width: `calc(100% - ${borderWidth || 0}px)`
        };

        return (
            <Wrapper {...dynamicWrapperStyle}>
                {warningMessage && <GalleryWarningMessage className="gallery-warning-message">
                    <Paragraph value={warningMessage} fontSize="22px" color={"#fff"}/>
                    <Button onClick={() => setWarningMessage("")}>חזור</Button>
                </GalleryWarningMessage>}

                <ImagesWrapper fluid={true}
                               horizontal={isHorizontal}
                               gridView={gridView}
                               galleryView={galleryView}
                               style={imagesWrapperStyle}>
                    {items[0].images.map((image, index) => {
                        const frame = items[0].frames[index];

                        const dynamicStyle = {
                            rotate: isRotate,
                            horizontal: isHorizontal,
                            spaceBetweenImages: spaceBetweenImages,
                            width: galleryView ? "33.33%" : gridView ? "50%" : "100%"
                        };

                        const number = items[0].hints && items[0].hints[index];
                        const showCover = advancedDataType && advancedDataType !== "none";
                        return (
                            <ImageWrapper key={index} order={index} onClick={() =>setActiveImage(index)} {...dynamicStyle}>
                                <Cover>
                                    <UserImageWrapper style={{zIndex: !!frame ? 0 : 1}}
                                                      framesPadding={!frame ? 0 : gridView ? framesPadding / 2 : framesPadding}>
                                        <BackgroundImage url={image}
                                                         cover={{
                                                             component: showCover && <NumberCover isActive={true}
                                                                                                  isList={advancedDataType === "list"}
                                                                                                  background={numberBackground}>
                                                                 {getSquareContent(number, index)} </NumberCover>
                                                         }}
                                                         className={"image-with-frame"}/>
                                    </UserImageWrapper>
                                    <img alt="cover" src={frame || image}
                                         style={{
                                             display: 'block', "width": '100%',
                                             opacity: frame ? 1 : .6,
                                             filter: `blur(${frame ? 0 : 10}px)`
                                         }}/>
                                </Cover>
                            </ImageWrapper>
                        )
                    })}
                    {galleryView && <ActiveImage>
                        <Image width={"100%"} src={items[0].images[activeImage]}/>
                    </ActiveImage>}
                </ImagesWrapper>
                {advancedDataType && advancedDataType === "list" &&
                <SubmitButton onClick={handleSubmit}>אישור</SubmitButton>}


                {/*{nextFrameButton && nextFrameButton}*/}

            </Wrapper>
        );
    }

    const activeItem = items && items.find(item => item.id === activeGallery);
    const activeMode = activeItem && activeItem.id >= 0;

    const Item = ({index, number, image}) => {
        const [numberActive, setNumberActive] = useState(null);
        useEffect(() => {
            if (numberActive >= 0) {
                setTimeout(() => {
                    setNumberActive(null);
                }, 2500)
            }
        }, [numberActive]);

        const showCover = !advancedDataType && advancedDataType !== "none";

        return (
            <TrainItemWrapper key={index} onClick={() => setNumberActive(number)}>

                <BackgroundImage url={image}
                                 cover={{
                                     component: showCover && <NumberCover isActive={true}
                                                                          isList={advancedDataType === "list"}
                                                                          background={numberBackground}> {getSquareContent(number)} </NumberCover>
                                 }}
                                 style={{width: '200px', height: '200px'}}/>

            </TrainItemWrapper>
        )
    };


    let nextImageToUse = activeGallery * 5;
    const TrainItem = (image, index) => {
        const editableItems = activeItem.editable && activeItem.editable.split(',');

        let url;
        if (editableItems && editableItems.some(item => parseInt(item - 1) === index)) {
            url = userImages[nextImageToUse];
            nextImageToUse++;
        } else {
            url = image;
        }

        return (
            <Item image={url} number={activeItem.hints[index]} index={index}/>
        )
    };

    return (
        <Wrapper backgroundColor={screenBackground}
                 backgroundImage={screenBackgroundImage}
                 border={`${borderColor} ${borderWidth}px solid`}
                 isTrain={items.length > 1}>
            <Container style={container}>

                <ImagesWrapper horizontal={activeMode} style={{...row, flexDirection: activeMode ? 'row' : 'column'}}>
                    {items.map((item, i) => {
                        return (
                            <GalleryTrigger key={i}
                                            backgroundImage={item.icon}
                                            backgroundColor={item.color}
                                            activeMode={activeMode}
                                            isActive={activeGallery === item.id}
                                            onClick={() => setActiveGallery(item.id)}>
                                {item.icon ? <Image
                                    src={typeof item.icon === "string" ? item.icon : item.icon[0]}/> : `גלריה ${item.id + 1}`}
                            </GalleryTrigger>
                        )
                    })}
                </ImagesWrapper>

                {activeMode &&
                <VBox width='100%'>
                    {/*<Image src={activeItem.icon} style={{alignSelf: 'center', width: '100px'}}/>*/}
                    <Train spaceBetweenItems={'15px'}
                           items={activeItem.images.map(TrainItem)}/>
                </VBox>
                }


                {/*{nextFrameButton && nextFrameButton}*/}

            </Container>
        </Wrapper>
    )
};


const Wrapper = styled.div`
     background: ${props => !props.backgroundImage && props.backgroundColor};
    // background-image: ${props => props.backgroundImage && `url(${props.backgroundImage})`};
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    // flex: 1;
    border: ${props => props.border};
    width: ${props => props.width};
    display: flex;
    flex-direction: column;
    justify-content: center;
     max-height: 100%;
    max-width: ${props => props.isTrain ? "100%" : "500px"};
`;

const ImagesWrapper = styled.div`
    display: ${props => (props.horizontal || props.gridView || props.galleryView) ? 'flex' : 'block'};
    flex-wrap: ${props => props.gridView || props.galleryView ? "wrap" : "nowrap"};
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: ${props => props.horizontal ? "10px" : '0px'};
    padding-top: ${props => props.horizontal ? "10px" : '0px'};
    overflow: auto hidden;
    max-width: 100vw;
    align-items: center;
   
    overflow: auto;
     &::-webkit-scrollbar {
        // display: none;
        background-color: rgba(0,0,0,0.3);
        border-radius: 10px
        margin-top: 20px;
    }

    &::-webkit-scrollbar-track {
        // width: 50px
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${colors.gray_3};
        border-radius: 10px
        width: 50px
    }
`;

const ActiveImage = styled.div`
    width:100%;
    order: 2;
`;

const container = {
    // width: '100vw',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    overflow: 'hidden',
    maxWidth: '100%',
    height: '100%'
};

const containerHorizontal = {
    display: "flex",
    overflowY: 'hidden',
    overflowX: 'auto',
    maxWidth: '100vw',
    alignItems: 'center'

};
const containerVertical = {};

const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};
const row = {
    justifyContent: 'center',
    alignItems: 'center',
};
const UserImageWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    padding: ${props => props.framesPadding + "px"};
    top: 0;
    left: 0;
`;
const NumberCover = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    font-size: 12rem;
    font-family: 'Fedra Sans Hebrew Medium';
    display: ${props => props.isActive ? 'flex' : 'none'};
    z-index: 1;
    background-color: ${props => props.background};
    color: ${colors.white};
    justify-content: center;
    flex-direction: column;
    align-items: center;
    line-height: ${props => props.isList ? "auto" : "15rem"};
    padding: 0 5px;
    ul {
        list-style:none;
        padding: 0;
        margin: 0;
    }
    li {
        color:#fff;
        font-size: 18px;
        cursor: pointer;
        
        &:hover {

        }
        &.active {
            text-decoration: underline;
            color: #a2c5d2;
        }
        
    }  
`;
const GalleryWarningMessage = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    color: #fff;
    z-index: 1000;
`;

const ImageWrapper = styled.div`
    display: flex;    
    justify-content:center;
    // margin-top: ${props => props.spaceBetweenImages / 2 + "px"};
    padding: ${props => props.spaceBetweenImages / 2 + "px"};  
    transform: rotate(${props => props.rotate ? randomIntFromInterval(-10, 10) : 0}deg);
    width: ${props => props.width};
    order: ${props => props.order};
    .image-without-frame {
        // height: 100%;
    }
    .image-with-frame {
        height: 100%;
        width: 100%;
    }
`;
const TrainItemWrapper = styled.div`
    position: relative;
    border-radius: 20px;
    overflow: hidden;
`;
const Cover = styled.div`
    width: 100%;
    position: relative;
    background-color: ${colors.gray_6};
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const GalleryTrigger = styled.div`
    width: 100%;
    width: ${props => props.activeMode ? props.isActive ? '20rem' : '9rem' : '20rem'};
    max-width: ${props => props.activeMode && "33.33%"};
    color: ${colors.black};
    font-weight: bold;
    border: 0;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
    cursor: pointer; 
    align-items: center;
    font-size: ${props => props.activeMode ? '16px' : '40px'};
    @media (max-width: 375px) {
        width: ${props => props.activeMode ? props.isActive ? '10rem' : '9rem' : '10rem'};
    }
`;

const SubmitButton = styled.button`
    margin: 10px auto 10px;
    display: block;
    cursor: pointer;
    width: auto;
    background-color: #279ee6;
    color: #ffffff;
    font-size: 1.6rem;
    border: 0;
    border-radius: 0;
    padding: 5px 15px;
    outline: none;
`;

ToolGallery.defaultProps = {
    width: '100%',
    images: []
};

ToolGallery.propTypes = {
    images: PropTypes.array
};

export default ToolGallery;

