import React, {useState} from "react";
import styled from "styled-components";
import {MdFormatColorFill, MdFormatColorText} from "react-icons/md";
import {colors, gutters} from "../../00_base/variables";
import {FieldColor} from "../../02_molecules";
import {FieldLabel} from "../../02_molecules/form-fields/field-style/m-field-style";


export const ColorPickerBgText = ({label, bgColor, textColor, bgName="bgColor", textName="textColor", handleChange, className = ""}) => {

    const TEXT = textName;
    const BACKGROUND = bgName;
    const [status, setStatus] = useState("");

    if (!status) {
        return (
            <div className={`d-flex ${className}`}>
                {label && <FieldLabel style={{whiteSpace: "nowrap"}}>{label}</FieldLabel>}
                <Square backgroundColor={bgColor} onClick={() => setStatus(BACKGROUND)}>
                    <MdFormatColorFill size={18} color={colors[bgColor ? "white" : "black"]}/>
                </Square>
                <Square backgroundColor={textColor} onClick={() => setStatus(TEXT)}>
                    <MdFormatColorText size={18} color={colors[textColor ? "white" : "black"]}/>
                </Square>
            </div>
        )
    }

    const changeHandler = (name, value) => {
        handleChange(name, value);
        setStatus("");
    }

    return (
        <div className={`d-flex align-items-start ${className}`}>
            {label && <FieldLabel style={{whiteSpace: "nowrap"}}>{label}</FieldLabel>}
            <FieldColor changeHandler={changeHandler}
                        className={className}
                        name={status}
                        defaultMenuActive={true}
                        controlStyle={{marginBottom: 0}}
                        value={status === TEXT ? textColor : bgColor}/>
        </div>
    )
}

const Square = styled.div`
    width: 23px;
    height: 23px;
    border: 1px solid ${colors.black};
    cursor: pointer;
    background-color:${props => props.backgroundColor};
    display: flex;
    align-items: center;
    justify-content:center;
    &:first-child {
        margin-left: ${gutters.xs};
    }
`;