import React from 'react';
import { MdDelete, MdCropRotate, MdOpenInNew } from 'react-icons/md';
import ToolTip from '../../tooltip/tooltip';
import {colors} from '../../../00_base/variables';

const FileActions = ({deleteHandler, cropToggleHandler, imageSrc, fileInput, fileIndex}) => {
    const isAdmin = JSON.parse(localStorage.getItem("isAdmin"));

    return (
        <div className="image-preview-actions">
            {/* <div className="image-preview-actions-btn">
                <button onClick={() => fileInput.click()}>
                    <MdUpdate size={18}
                        className={'trigger'} />
                </button>
            </div> */}
            <div className="image-preview-actions-btn">
                <ToolTip
                    backgroundColor={colors.white}
                    textColor={colors.black}
                    offset={{top: "-5px",}}
                    id={`tooltip-remove-image-${fileIndex}`}
                    tip="מחיקה"
                    placeholder={
                        <button onClick={() => {
                            deleteHandler('' + fileIndex);
                        }}>
                            <MdDelete size={24}
                                      className={'trigger'}/>
                        </button>
                    }/>

            </div>
            <div className="image-preview-actions-btn">
                <ToolTip
                    backgroundColor={colors.white}
                    textColor={colors.black}
                    offset={{top: "-5px"}}
                    id={`tooltip-edit-image-${fileIndex}`}
                    tip="עריכה"
                    placeholder={
                        <button onClick={() => {
                            cropToggleHandler('' + fileIndex);
                        }}>
                            <MdCropRotate size={24} className={'trigger'}/>
                        </button>
                    }/>
            </div>
            {isAdmin &&
            <div className="image-preview-actions-btn">
                <ToolTip
                    backgroundColor={colors.white}
                    textColor={colors.black}
                    offset={{top: "-5px"}}
                    id={`tooltip-open-image-${fileIndex}`}
                    tip="פתח תמונה בחלון חדש"
                    placeholder={
                        <div onClick={() => cropToggleHandler('' + fileIndex)}>
                            <a href={imageSrc}  rel="noopener noreferrer" target='_blank'>
                                <MdOpenInNew size={32} className={'trigger'} color={colors.white}/>
                            </a>
                        </div>
                    }/>
            </div>
            }
        </div>
    )
}

export default FileActions;