import React, {useContext, useEffect, useState} from 'react';
import {Element} from 'react-stylesheet';
import {Container} from 'react-grid-system';
import {DICT} from "../../../../languages/dictionary-names";
import {Translation} from "../../../../languages/";
import {LanguageContext} from "../../../../languages";
import {font_family} from "../../../00_base/variables";
import {Heading, LoaderPageBulletList} from '../../../01_atoms/index.js';
import {AdventuresForBusinessCard, Subscribe} from '../../../03_organisms';
import "../../../../assets/styles/screen-about.scss";

const ABOUT_IMAGE = "https://storage.googleapis.com/assets.youdoadventures.com/website/about_us_color_image.svg";

const ScreenAbout = ({homepage}) => {
    const [pageData, setPageData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const {rtl} = useContext(LanguageContext);

    useEffect(() => {
        setPageData({
            title: <Translation id={DICT.ABOUT_US_DESCRIPTION_1}/>,
            description: [
                <Translation id={DICT.ABOUT_US_DESCRIPTION_1}/>,
                <Translation id={DICT.ABOUT_US_DESCRIPTION_2}/>,
                <Translation id={DICT.ABOUT_US_DESCRIPTION_3}/>
            ]
        })
        setIsLoading(false)
    }, []);

    return (
        <Element width='100%' className="about-us">
            <img className="about-us-color-image" src={ABOUT_IMAGE} alt="about us"/>
            <Element className='py-0 py-md-7' position='relative' zIndex={1}>
                <Container>
                    <Heading tag={homepage ? 'h2' : 'h1'}
                             fontFammily={font_family.medium}
                             className={`font-size-xxl mt-5 mt-md-0 mb-3 mb-md-0 text-${rtl ? "right" : "left"}`}
                             value={<Translation id={DICT.ABOUT_US_TITLE}/>}/>

                    {isLoading ?
                        <LoaderPageBulletList/>
                        :
                        <div className="about-us-description">
                            {pageData.description.map((paragraph, index) => {
                                return (
                                    <div className="about-us-paragraph color-black py-lg-3"
                                         key={index}>
                                        <p>{paragraph}</p>
                                    </div>
                                )
                            })}
                        </div>
                    }

                    <div className="d-none d-md-block py-7">
                        <Subscribe/>
                    </div>
                    <div className="py-md-7 py-4">
                        <AdventuresForBusinessCard/>
                    </div>
                </Container>
            </Element>
        </Element>
    );
}

export default ScreenAbout;