import React, {useContext} from 'react';
import classNames from "classnames";
import {MdCropRotate, MdDelete, MdOpenInNew} from 'react-icons/md';
import {AuthContext} from "../../../../providers/auth-provider";

const FileActions = ({deleteHandler, imageSrc, cropToggleHandler, smallPreview, deleteOnly, fileIndex}) => {
    const {isAdmin} = useContext(AuthContext);
    const iconSize = smallPreview ? 16 : 20;
    const iconProps = {size: iconSize, className:"trigger"};
    const itemWRapperClassName = "image-preview-actions-btn";
    return (
        <div className={classNames("image-preview-actions", {small:smallPreview})}>

            {!deleteOnly &&
            <div className={itemWRapperClassName}>
                <button onClick={() => cropToggleHandler('' + fileIndex)}>
                    <MdCropRotate {...iconProps}/>
                </button>
            </div>
            }

            <div className={itemWRapperClassName}>
                <button onClick={() => deleteHandler('' + fileIndex)}>
                    <MdDelete {...iconProps}/>
                </button>
            </div>

            {isAdmin && !deleteOnly &&
            <div className={itemWRapperClassName}>
                <button onClick={() => window.open(imageSrc)}>
                    <MdOpenInNew {...iconProps}/>
                </button>
            </div>
            }

        </div>
    )
};

export default FileActions;