import React from 'react';

class IconAge extends React.Component {
    render() {
        const {color, width, height} = this.props;
        return (
            <svg width={width} height={height} viewBox="0 0 14 27" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g fill={color}>
                        <circle id="Oval" cx="7" cy="3.79158547" r="3"/>
                        <path d="M13.8772146,14.1976134 L11.6272542,9.59311663 C10.7907304,7.90110276 8.97345468,6.79158547 7.01195069,6.79158547 C5.05044671,6.79158547 3.23317096,7.90110276 2.3966472,9.59311663 L0.1178411,14.1698754 C-0.170615368,14.752372 0.0889954536,15.4458203 0.694754037,15.7231996 C1.30051262,16.000579 2.02165379,15.7509376 2.31011026,15.168441 L4.21392295,11.3960822 L3.43509049,25.3205242 C3.40624484,26.0971863 4.01200342,26.7351587 4.79083589,26.7906346 C5.598514,26.8183725 6.26196388,26.2358759 6.31965517,25.4869518 L6.78118552,16.9991445 L7.24271587,16.9991445 L7.70424622,25.4869518 C7.73309187,26.2358759 8.3676961,26.7906346 9.14652856,26.7906346 C9.17537421,26.7906346 9.20421985,26.7906346 9.2330655,26.7906346 C10.0407436,26.7628967 10.6465022,26.0971863 10.5888109,25.3205242 L9.80997844,11.3960822 L11.6849455,15.168441 C11.886865,15.58451 12.3195497,15.8341514 12.7810801,15.8341514 C12.9541539,15.8341514 13.1272278,15.8064134 13.3003017,15.7231996 C13.9349059,15.4735583 14.1656711,14.7801099 13.8772146,14.1976134 Z" />
                    </g>
                </g>
            </svg>
        )
    }

    static defaultProps = {
        color: '#231F20',
        width: '14px',
        height: '27px'
    }
}

export default IconAge;