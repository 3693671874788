import React, {useState, useContext, useReducer} from "react";
import {useDispatch} from "react-redux";
import {MdEdit, MdSave} from 'react-icons/md';
import {Col, Row} from "react-grid-system";
import {LanguageContext} from "../../../../languages";
import {LanguageTranslation} from "./language-translation";
import {updateLanguage} from "../../../../store/actions/languages-actions";
import {Button} from "../../../01_atoms";
import {FieldText, FilesUploader} from "../../../02_molecules";

const newLanguageReducer = (state, action) => {
    return {...state, [action.name]: action.value};
};

export const SingleLanguage = (
    {id, title, name, translations, flag = ""}
) => {
    const isNewLanguage = !(id >= 0);

    const {createLanguage} = useContext(LanguageContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditable, setIsEditable] = useState(isNewLanguage);
    const [newLanguage, dispatchNewLanguage] = useReducer(newLanguageReducer, {
        title: "",
        name: "",
        flag: "",
        translations: {}
    });

    const dispatch = useDispatch();

    const changeHandler = (fieldName, value) => {
        if (isNewLanguage) {
            dispatchNewLanguage({name: fieldName, value: value});
        } else {
            dispatch(updateLanguage({
                id, title, name, flag, translations, [fieldName]: value
            }))
        }
    };

    const submitHandler = (json) => {
        setIsLoading(true);
        if (isNewLanguage) {
            createLanguage(newLanguage, () => setIsLoading(false));
        } else {
            // changeHandler("translations", value);
            let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(json));
            let dlAnchorElem = document.getElementById('download-json');
            dlAnchorElem.setAttribute("href", dataStr);
            dlAnchorElem.setAttribute("download", `${name}.json`);
            dlAnchorElem.click();
        }
    };

    return (
        <Row className={"align-items-end"}>
            <Col>
                {isEditable ?
                    <FieldText name="title"
                               value={title}
                               label="שם"
                               controlStyle={{marginBottom: 0}}
                               changeHandler={changeHandler}/>
                    :
                    <p>{title}</p>
                }
            </Col>
            <Col>
                {isEditable ?
                    <FieldText name="name"
                               value={name}
                               label="קוד"
                               controlStyle={{marginBottom: 0}}
                               changeHandler={changeHandler}/>
                    :
                    <p>{name}</p>}
            </Col>
            <Col>
                {isEditable ?
                    <FilesUploader name="flag"
                                   value={flag}
                                   multiple={false}
                                   previewWidth={"100px"}
                                   previewHeight={"100px"}
                                   triggerValue="הכנס דגל"
                                   changeHandler={changeHandler}/>
                    :
                    <img src={flag} width={100}/>
                }
            </Col>
            {!isNewLanguage &&
            <Col className={"d-flex align-items-center"} style={{height: "60px"}}>
                {(!isEditable ?
                        <MdEdit size={30} cursor={"pointer"}
                                onClick={() => setIsEditable(true)}/>
                        :
                        <MdSave size={30} cursor={"pointer"}
                                onClick={() => setIsEditable(false)}/>
                )}
            </Col>
            }
            <Col xs={12}>
                <a id="download-json" style={{display: "none"}}/>
                {isNewLanguage ?
                    <Button onClick={submitHandler}
                            className={`d-${!(newLanguage.title && newLanguage.name && newLanguage.flag) ? "none" : "block"} mx-auto mt-5`}
                            value={isLoading ? "..." : "צור שפה חדשה"}/>
                    :
                    <LanguageTranslation language={name} onSave={submitHandler}/>
                }

            </Col>
        </Row>
    )
}