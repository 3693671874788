import React, { useContext, useReducer, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Col, Row } from "react-grid-system";
import { Element, HBox } from "react-stylesheet";
import { DICTIONARY } from "../registration-dictionary";
import { AuthContext } from "../../../../providers/auth-provider";
import { LanguageContext, Translation } from "../../../../languages";

import {
  colors,
  font_family,
  font_size,
  gutters,
  viewport,
} from "../../../00_base/variables";
import { Button, Heading, SimpleLoader } from "../../../01_atoms";
import { FieldCheckbox, FieldText, FilesUploader } from "../../../02_molecules";

const AVATAR =
  "https://admin.youdoadventures.com/wp-content/uploads/2018/04/placeholder.png";

const reducer = (state, action) => {
  return { ...state, [action.name]: action.value };
};

const defaultForm = {
  firstName: "",
  lastName: "",
  username: "",
  password: "",
  repeatPassword: "",
  email: "",
  phone: "",
  terms: false,
  profileImage: "",
  avatar: "",
  newsletter: false,
};
const emailRegex =
  /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Register = ({
  actionAfterLogin,
  goAfterLogin,
  title,
  subtitle,
  language = "he",
}) => {
  const { userLanguage } = useContext(LanguageContext);
  const { register, isLogin } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [form, dispatchForm] = useReducer(reducer, defaultForm);
  const [warningsActive, setWarningsActive] = useState(false);

  const navigate = useNavigate();

  const {
    username,
    lastName,
    password,
    terms,
    email,
    phone,
    passwordRepeat,
    avatar,
    newsletter,
  } = form;

  const usernameValid = !!username;
  const lastNameValid = !!lastName;
  const emailValid = emailRegex.test(String(email).toLowerCase());
  const passwordValid = !!password;
  const passwordMatch = password === passwordRepeat;
  const termsValid = !!terms;

  if (isLogin) {
    navigate("/");
  }

  const checkValidation = () => {
    const allValid =
      usernameValid &&
      lastNameValid &&
      emailValid &&
      passwordValid &&
      passwordMatch &&
      termsValid;
    setWarningsActive(!allValid);
    return allValid;
  };

  const handleChange = (name, value) => {
    dispatchForm({
      type: "change",
      name: name,
      value: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (checkValidation()) {
      const userData = {
        name: username,
        lastName: lastName,
        email: email.toLowerCase(),
        phone: phone,
        password: password,
        avatar: avatar,
        terms: terms,
      };
      register(userData, actionAfterLogin, goAfterLogin);
    } else {
      setIsLoading(false);
    }
  };

  const controlStyle = { marginBottom: gutters.md };
  const inputStyle = {};
  const fieldTextProps = {
    borderRadius: 0,
    border: 0,
    marginBottom: 0,
    controlStyle: controlStyle,
    inputStyle: inputStyle,
    changeHandler: handleChange,
  };
  return (
    <Wrapper>
      <FormWrapper className={"container"}>
        <Heading
          tag={"h3"}
          className={"form-title responsive-text"}
          color={colors.black}
          textAlign={"center"}
          fontFamily={font_family.medium}
          value={title || DICTIONARY.registerTitle[language]}
        />

        {subtitle && (
          <Heading
            tag={"h5"}
            className={"form-subtitle"}
            textAlign={"center"}
            fontWeight={"bold"}
            value={subtitle}
          />
        )}

        <form id={"register-form"} onSubmit={handleSubmit}>
          <Row>
            <Col sm={6} className={"px-3"}>
              <FieldText
                type={"text"}
                label={DICTIONARY.firstName[language]}
                name={"username"}
                {...fieldTextProps}
                value={username}
                fontSize={font_size.sm}
                errorMessage={DICTIONARY.firstNameWarning[language]}
                errorMessageActive={warningsActive && !usernameValid}
              />
            </Col>
            <Col sm={6} className={"px-3"}>
              <FieldText
                type={"text"}
                label={DICTIONARY.lastName[language]}
                name={"lastName"}
                value={lastName}
                {...fieldTextProps}
                errorMessage={DICTIONARY.lastNameWarning[language]}
                errorMessageActive={warningsActive && !lastNameValid}
              />
            </Col>
            <Col sm={12} className={"px-3"}>
              <FieldText
                type={"text"}
                label={DICTIONARY.phoneNumber[language]}
                name={"phone"}
                {...fieldTextProps}
                errorMessage={DICTIONARY.phoneNumberWarning[language]}
                value={phone}
              />
            </Col>
            <Col sm={12} className={"px-3"}>
              <FieldText
                type={"email"}
                label={DICTIONARY.emailAddress[language]}
                name={"email"}
                value={email}
                {...fieldTextProps}
                errorMessage={DICTIONARY.emailAddressWarning[language]}
                errorMessageActive={warningsActive && !emailValid}
              />
            </Col>

            {/*< FieldText type={'text'}*/}
            {/*label={'שם משתמש'}*/}
            {/*name={'username'}*/}
            {/*value={username}*/}
            {/*change={handleChange}*/}
            {/*errorMessage={'שם המשתמש קיים'}*/}
            {/*notValid={usernameExist}/>*/}

            <Col sm={6} className={"px-3"}>
              <FieldText
                type={"password"}
                label={DICTIONARY.password[language]}
                name={"password"}
                value={password}
                {...fieldTextProps}
                errorMessage={DICTIONARY.passwordWarning[language]}
                errorMessageActive={warningsActive && !passwordValid}
              />
            </Col>
            <Col sm={6} className={"px-3"}>
              <FieldText
                type={"password"}
                label={DICTIONARY.passwordRepeat[language]}
                name={"passwordRepeat"}
                value={passwordRepeat}
                {...fieldTextProps}
                errorMessage={DICTIONARY.passwordRepeatWarning[language]}
                errorMessageActive={warningsActive && !passwordMatch}
              />
            </Col>

            <Col sm={12} className={"d-flex justify-content-center pb-5"}>
              <FilesUploader
                name={"avatar"}
                value={avatar}
                multiple={false}
                previewWidth={160}
                triggerStyle={triggerStyle}
                triggerValue={<p color={"#000"}>תמונת פרופיל</p>}
                label={"תמונת פרופיל"}
                changeHandler={handleChange}
              />
            </Col>
          </Row>

          <Element>
            <FieldCheckbox
              inputStyle={{ fontSize: font_size.xs }}
              changeHandler={handleChange}
              options={[DICTIONARY.approveTerms[language]]}
              name={"terms"}
              // required={true}
              borderColor={colors.action}
              controlStyle={{ marginBottom: gutters.md }}
              labelStyle={{ fontSize: font_size.xs }}
              value={[terms ? DICTIONARY.approveTerms[language] : ""]}
              language={language}
              errorMessageActive={warningsActive && !termsValid}
            />
          </Element>

          <Element>
            <FieldCheckbox
              changeHandler={handleChange}
              options={[DICTIONARY.hearMore[language]]}
              name={"terms"}
              inputStyle={{ fontSize: font_size.xs }}
              labelStyle={{ fontSize: font_size.xs }}
              language={language}
              borderColor={colors.action}
              value={[newsletter ? DICTIONARY.hearMore[language] : ""]}
            />
          </Element>

          {/* <HBox  justifyContent="center" style={{display: "none !important"}}>

                                    <FilesUploader
                                        changeHandler={this.handleProfileChange}
                                        triggerValue={'תמונת פרופיל'}
                                        // value={profileImage}
                                        previewWidth={'15rem'}
                                        previewHeight={'15rem'}
                                        name={'profileImage'} />

                                </HBox> */}

          <HBox justifyContent={"center"} marginTop={"25px"}>
            <Button
              width={"100px"}
              type={"submit"}
              value={
                isLoading ? (
                  <SimpleLoader size={18} color={colors.white} />
                ) : (
                  DICTIONARY.register[language]
                )
              }
            />
          </HBox>
        </form>
        <Button
          textAlign="center"
          className="mt-4"
          underline
          background={colors.info}
          fontSize={font_size.xs}
          fontFamily={font_family.medium}
          value={DICTIONARY.iHaveExistAccount[language]}
        >
          <Link to={`/${userLanguage}/login`} style={{ color: "#fff" }}>
            <Translation id={DICTIONARY.iHaveExistAccount[language]} />
          </Link>
        </Button>
      </FormWrapper>
    </Wrapper>
  );
};

export default Register;

const Wrapper = styled.div`
    // min-height: calc(100vh - 220px);
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 ${gutters.sm};
    h2.form-title {
      text-align: center;
      margin-bottom: ${gutters.sm};
    }
  `,
  FormWrapper = styled.div`
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center; 
        width: 66rem; 
        margin: ${gutters.xl} 0;
        max-width: 100%; 
        form {
            margin-top: ${gutters.md} 
            width: 100%;
            .field-submit{
                width: 50%;
                input {
                    width: 100%;
                }
            }            
            @media screen and (min-width: ${viewport.sm}) {

            }
        }
    `;

const triggerStyle = {
  width: "150px",
  height: "150px",
  backgroundColor: "#fff",
  border: "1px dashed #000",
  color: "#000",
};
