import React from 'react';
import PropTypes from 'prop-types';
import {FieldVideo} from '../../../../02_molecules';


const CreateToolVideo = ({toolData, handleToolDataChange}) => {
    return (
        <div>
            <FieldVideo name={'videoUrl'}
                        value={toolData && toolData.videoUrl}
                        changeHandler={handleToolDataChange}/>
        </div>
    )
}


CreateToolVideo.defaultProps = {

    toolData: {videoUrl: ''}
};

CreateToolVideo.propTypes = {
    toolData: PropTypes.object
};


export default CreateToolVideo;