import React from "react";
import {FieldText} from "../../../../../02_molecules";

export const AdditionalDataContinue = (
    {handleToolDataChange, continueButtonValue = "המשך"}
) => {
    return (
        <div className="mt-5">
            <FieldText changeHandler={handleToolDataChange}
                       name="continueButtonValue"
                       label={"טקסט כפתור המשך"}
                       value={continueButtonValue}/>
        </div>
    )
};