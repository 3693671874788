import React, {createContext, useContext, useState} from "react";
import {RouteContext} from "../components/04_layout/app-content/l-app-content";
import {AuthContext} from "./auth-provider";
const LIGHT = "light";
const DARK = "dark";

export const ThemeContext = createContext({
    mode: 'light',
    toggle: null
});
export const ThemeProvider = ({children}) => {
        const {admin} = useContext(RouteContext);
        const {isLogin} = useContext(AuthContext);
        const [themeMode, setThemeMode] = useState(localStorage.getItem("themeMode") || LIGHT);
        const ignoreTheme = (admin || !isLogin);

        const provider = {
            mode: ignoreTheme ? LIGHT : themeMode,
            toggle: (mode) => {
                if (mode) {
                    localStorage.setItem("themeMode", mode);
                    setThemeMode(mode)
                }
            }
        };

        return (
            <ThemeContext.Provider value={provider}>
                <div className={`theme-${provider.mode}`}>
                    {children}
                </div>
            </ThemeContext.Provider>
        );
    }
;