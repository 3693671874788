import React from "react";
import {Element, HBox} from "react-stylesheet";
import {MdClose} from "react-icons/md";
import {SingleFrameTool} from "../single-frame-tool";
import {FieldCheckbox, FieldOptions, FieldText, FilesUploader} from "../../../../../02_molecules";

const locationOptions = [
    {label: "מעל הכלי", value: 1},
    {label: "מתחת הכלי", value: 2},
    {label: "בר עליון", value: 3}
];

export const FrameModalContext = React.createContext({handleModalChange: null});

export const FrameModal = (
    {
        toolId,
        toolData,
        tips,
        instructions,
        removeModal,
        editable,
        modalIcon,
        modalIconLocation,
        modalIconSize = 200,
        handleModalChange
    }) => {
    const handleChange = (name, value) => {
        if (name === "categories") {
            handleModalChange("toolId", value[0]);
        } else {
            handleModalChange("toolData", {...toolData, [name]: value});
        }
    };
    const editableLabel = "התאמה אישית";
    return (
        <FrameModalContext.Provider value={{handleModalChange: handleModalChange}}>
            <div className={"single-frame-modal"}>
                <HBox justifyContent={"space-between"} alignItems={"center"}>
                    <FilesUploader name={"modalIcon"}
                                   value={modalIcon}
                                   multiple={false}
                                   previewWidth={80}
                                   previewHeight={80}
                                   controlStyle={{width: "80px"}}
                                   changeHandler={handleModalChange}
                                   triggerValue={"איקון לפתיחת הפופאפ"}/>
                    <Element padding={"10px"} onClick={removeModal}>
                        <MdClose size={20} style={{cursor: "pointer"}}/>
                    </Element>
                </HBox>

                {modalIcon &&
                <FieldText changeHandler={handleModalChange}
                           name="modalIconSize"
                           value={modalIconSize}
                           label="גודל איקון"/>}

                <FieldOptions options={locationOptions}
                              label="מיקום האיקון"
                              labelStyle={{paddingRight: "5px", marginBottom:"10px"}}
                              inputStyle={{fontSize: "16px", textDecoration:"none"}}
                              value={locationOptions.find(option => option.value === modalIconLocation)}
                              name="modalIconLocation"
                              changeHandler={handleModalChange}/>

                <SingleFrameTool categorydata={toolData}
                                 handleChange={handleChange}
                                 categories={[toolId]}
                                 id={toolId}
                                 handleCategoryDataChange={handleChange}/>

                <FieldCheckbox changeHandler={handleModalChange}
                               value={editable ? [editableLabel] : []}
                               options={[editableLabel]}
                               name={"editable"}/>



                {/*<SingleFrameEditable editable={editable}*/}
                {/*                     tips={tips}*/}
                {/*                     instructions={instructions}/>*/}
            </div>
        </FrameModalContext.Provider>
    )
};