import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { colors } from "../../00_base/configurations/configurations";
import PropTypes from "prop-types";

const ToolTip = (props) => {
  const { id, placeholder, tip } = props;
  return (
    <>
      <span data-tip data-for={`tooltip-${id}`}>
        {placeholder}
      </span>
      <ReactTooltip {...props} id={`tooltip-${id}`}>
        <span>{tip}</span>
      </ReactTooltip>
    </>
  );
};

ToolTip.defaultProps = {
  effect: "solid",
  backgroundColor: "rgba(0,0,0,0.9)",
  textColor: colors.white,
  offset: { top: 15 },
};

ToolTip.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.any.isRequired,
  tip: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["success", "warning", "error", "info", "light"]),
  effect: PropTypes.oneOf(["float", "solid"]),
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  offset: PropTypes.object,
};

export default ToolTip;
