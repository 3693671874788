import React from 'react';
import {Element, HBox} from 'react-stylesheet';
import {Link} from 'react-router-dom';
import BoxAnimated from "./box-animated";
import {Heading, LoaderInstagram, Span} from '../../01_atoms';
import {font_family, font_size, gutters} from '../../00_base/variables';


const Box = ({title, subtitle, image, date, url, imageHeight, textAlign, isLoading}) => {
    return (
        <Element>
            <Link to={url} style={{textDecoration: 'none !important'}}>
                {isLoading ? <LoaderInstagram/> : <BoxAnimated url={image} height={imageHeight}/>}
                <Element color={'#000'}
                         textAlign={textAlign ? textAlign : 'center'}
                         paddingTop={gutters.sm}>
                    <HBox justifyContent={'space-between'}>
                        <Heading tag={'h5'}
                                 className={"color-black"}
                                 style={{margin: 0}}
                                 isLoading={isLoading}
                                 fontFamily={font_family.medium}>
                            {title}
                        </Heading>
                        {date &&
                        <Span color={'#A4465E'}
                              fontWeight={'bold'}
                              isLoading={isLoading}
                              paddingRight={gutters.sm}>
                            {date}
                        </Span>}
                    </HBox>
                    <p dangerouslySetInnerHTML={{__html: subtitle}}
                       className={"color-black"}
                       style={{fontSize: font_size.sm}}/>
                </Element>
            </Link>
        </Element>
    )
}

export default Box;