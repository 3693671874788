import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import {Element, HBox} from 'react-stylesheet';
import toolReducer from '../create-tool-reducer';
import CreateToolActions from '../create-tool-actions';
import {gutters} from '../../../../00_base/variables';
import {Button, Circle} from '../../../../01_atoms';
import {FieldText, FieldTextArea, FilesUploader} from '../../../../02_molecules';
import {Grid} from '../../../../03_organisms';

const THUMBNAIL = "https://storage.googleapis.com/assets.youdoadventures.com/website/default_thumbnail.jpg";

const UPDATE_INPUT = 'UPDATE_INPUT';
const ADD_SECTION = 'ADD_SECTION';

const initialSection = {
    id: 0,
    title: '',
    description: '',
    image: null,
    color: null
};

const CreateToolDefiner = ({toolData, submitHandler}) => {
    const [formState, dispatchFormState] = useReducer(toolReducer, {
        toolData: {
            items: !!toolData.items ? toolData.items : [initialSection]
        }
    });

    const handleChange = (name, value, index) => {
        dispatchFormState({
            type: UPDATE_INPUT,
            name: name,
            value: value,
            section: index
        });
    };

    const handleSubmit = () => {
        submitHandler(formState)
    };

    const addSection = () => {
        dispatchFormState({
            type: ADD_SECTION,
            initialSection: initialSection
        })
    };

    const renderSection = ({id, title, description, image, color}, index) => {
        const onChange = (name, value, isValid) => handleChange(name, value, index);
        const imageSrc = !!image ? typeof image === 'string' ? image : !!image[0] ? image[0] : THUMBNAIL : THUMBNAIL;

        return (
            <Element key={id}
                // border={'1px solid #000'}
                     style={{
                         borderRight: index & 1 ? '1px solid #000' : '2px solid #000',
                         borderLeft: !(index & 1) ? '1px solid #000' : '2px solid #000',
                         borderBottom: '1px solid #000',
                         borderTop: index > 1 ? '1px solid #000' : '2px solid #000'
                     }}
                     position={'relative'}
                     background={'#f1f1f1'}
                     padding={`${gutters.lg} 0`}>

                <Circle size={30}
                        background={color}
                        style={{fontWeight: 'bold', position: 'absolute', top: gutters.md, left: gutters.md}}>
                    {index + 1}
                </Circle>
                <CreateToolActions
                    index={index}
                    color={color}
                    handleChange={handleChange}
                    dispatchFormState={dispatchFormState}
                    length={formState.toolData.items.length}/>

                <Element marginTop={gutters.lg}>
                    <Element style={formStyle}>
                        <HBox width={'100%'}>
                            <Element padding={`0 ${gutters.sm}`}>
                                <FilesUploader
                                    name={'image'}
                                    previewStyle={{width: '100px', height: '100px', marginTop: 0, marginBottom: 0,}}
                                    previewWidth={'90px'}
                                    previewHeight={'90px'}
                                    initialValue={imageSrc}
                                    index={index}
                                    label={'תמונה'}
                                    triggerValue={'העלו תמונה'}
                                    singleFile={true}

                                    hideTrigger
                                    maxFiles={1}
                                    setIsFileLoading={data => console.log('data', data)}
                                    changeHandler={onChange}
                                    value={[imageSrc]}/>
                            </Element>
                            <Element width={'100%'} padding={`0 ${gutters.sm}`}>
                                <FieldText
                                    label={'כותרת'}
                                    placeholder={`הכנס כותרת`}
                                    value={title}
                                    initialValue={title}
                                    index={index}
                                    controlStyle={{marginBottom: 0, width: '100%'}}
                                    inputStyle={{backgroundColor: '#fff'}}
                                    name={'title'}
                                    changeHandler={onChange}/>
                                <FieldTextArea
                                    label={'תיאור'}
                                    placeholder={`הכנס תיאור`}
                                    value={description}
                                    initialValue={description}
                                    index={index}
                                    controlStyle={{width: '100%'}}
                                    inputStyle={{backgroundColor: '#fff'}}
                                    name={'description'}
                                    changeHandler={onChange}/>
                            </Element>
                        </HBox>

                    </Element>
                </Element>
            </Element>
        )
    }

    return (
        <Element>
            {!!formState.toolData.items &&
            <Grid
                gutterWidth={0}
                cellSizes={{xs: 12, md: 6}}
                items={formState.toolData.items.map(renderSection)}/>}

            <Element marginTop={gutters.md}>
                <Button onClick={addSection}>add section</Button>
                <Button onClick={handleSubmit}>save</Button>
            </Element>
        </Element>
    )
}

const formStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
};

CreateToolDefiner.defaultProps = {
    submitHandler: (toolData) => console.log('toolData', toolData),
    toolData: {items: []}
};

CreateToolDefiner.propTypes = {
    toolData: PropTypes.object,
    submitHandler: PropTypes.func
};


export default CreateToolDefiner;