import React from "react";
import {
  MdAddBox,
  MdDesktopMac,
  MdComment,
  MdList,
  MdRemoveRedEye,
  MdRoom,
} from "react-icons/md";
import { AdventureMain } from "./adventure-main";
import { GameEntranceUi } from "../../03_organisms";
import { AdventureLobby } from "./adventure-lobby";
import { AdventureTypeSequence } from "../adventureType/adventure-type-sequence/adventure-type-sequence";
import { tabsRoutes } from "./single-adventure";
import { colors } from "../../00_base/variables";
import { AdminConversation } from "../../03_organisms/admin-conversation/o-admin-conversation";

export const adventureTabsItems = (
  handleAdventureDataChange,
  handleChange,
  handleJsonDataChange,
  singleAdventure,
  activeFrameRoute,
  togglePreview
) => {
  const { adventure, adventureType, activeFrame } = singleAdventure;

  const addTabsRoutes = (item, index) => {
    return {
      ...item,
      name: `/he/admin/reservation/${adventure.id}/${tabsRoutes[index]}/${
        activeFrame || activeFrameRoute ? activeFrame || activeFrameRoute : ""
      }`,
    };
  };

  const main = (
    <AdventureMain adventure={adventure} handleChange={handleChange} />
  );

  const welcome = (
    <>
      <div className={"d-flex justify-content-between align-items-center"}>
        <h4>מסך ראשי</h4>
        <MdRemoveRedEye
          size={20}
          color={colors.green}
          onClick={togglePreview}
          cursor={"pointer"}
        />
      </div>
      <GameEntranceUi
        entrance_image_desktop={
          adventure.json_data?.entrance_image_desktop ||
          adventureType?.entrance_image_desktop
        }
        entrance_image_mobile={
          adventure.json_data?.entrance_image_mobile ||
          adventureType?.entrance_image_mobile
        }
        logo_url={
          adventure.thumbnail ||
          adventure.json_data?.logo_url ||
          adventureType?.logo_url
        }
        opening_video_url={
          adventure.json_data?.opening_video_url ||
          adventureType?.opening_video_url
        }
        welcome_title_size={
          adventure.json_data?.welcome_title_size ||
          adventureType?.welcome_title_size
        }
        bg_color={adventure.json_data?.bg_color || adventureType?.bg_color}
        text_color={
          adventure.json_data?.text_color || adventureType?.text_color
        }
        continue_bg_color={
          adventure.json_data?.continue_bg_color ||
          adventureType?.continue_bg_color
        }
        continue_text_color={
          adventure.json_data?.continue_text_color ||
          adventureType?.continue_text_color
        }
        logo_type={adventure.json_data?.logo_type || adventureType?.logo_type}
        welcome_pre_title={
          adventure.json_data?.welcome_pre_title ||
          adventureType?.welcome_pre_title
        }
        entrance_cta={
          adventure.json_data?.entrance_cta || adventureType?.entrance_cta
        }
        title="מסך ראשי"
        handleJsonDataChange={handleJsonDataChange}
        handleChange={handleJsonDataChange}
      />
    </>
  );

  const lobby = <AdventureLobby />;
  console.log("adventure.adventuredata_id", singleAdventure);
  const riddlesFrames = (
    <AdventureTypeSequence
      adventureDataId={adventure.adventuredata_id}
      handleChange={handleChange}
    />
  );

  const adventureComments = (
    <>
      <h4 className="admin-conversation-title">הערות</h4>
      <AdminConversation
        comments={adventure.json_data?.admin_comments}
        handleChange={(comments) =>
          handleJsonDataChange("admin_comments", comments)
        }
      />
    </>
  );
  const items = [
    { label: "כללי", Icon: MdAddBox, key: "main", component: main },
    {
      label: "מסך ראשי",
      Icon: MdDesktopMac,
      key: "welcome",
      component: welcome,
    },
    { label: "לובי", Icon: MdRoom, key: "lobby", component: lobby },
    { label: "הרפתקה", Icon: MdList, key: "riddles", component: riddlesFrames },
    {
      label: "הערות",
      Icon: MdComment,
      key: "comments",
      component: adventureComments,
    },
  ];

  return items
    .map(({ label, Icon, key, component }) => {
      const item = (
        <div className="single-adventure-type-label" key={key}>
          <Icon />
          <span>{label}</span>
        </div>
      );
      return {
        label: item,
        content: component,
      };
    })
    .map(addTabsRoutes);
};
