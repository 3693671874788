export const
    breakpoints     = [576, 768, 992, 1200, 1440],
    containerWidths = [540, 720, 960, 1140, 1366],
    gutterWidth     = 50,
    gridColumns     = 12,
    fontSizeBase    = 16,
    colors = {
            primary: '#00d99d',
            secondary: '#f2c642',
            success: '#7ec166',
            info: '#e83e8c',
            warning: '#dc3545',
            danger: '#fd7e14',
            light: '#495057',
            dark: '#6c757d',
            gray_loading: '#ebebeb'
    };