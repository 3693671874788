import React from "react";
import {
    desktopArrows,
    desktopIconsProps,
    desktopLargeArrows,
    desktopLargeIconsProps,
    desktopMaxArrows,
    desktopMaxIconsProps,
    mobileArrows,
    mobileIconsProps,
    tabletLandscapeArrows,
    tabletLandscapeIconsProps,
    tabletPortraitArrows,
    tabletPortraitIconsProps,
} from "../constants";
import {
    DesktopLargeOnly,
    DesktopMaxOnly,
    DesktopOnly,
    MobileOnly,
    TabletLandscapeOnly,
    TabletPortraitOnly
} from '../../../01_atoms/responsive/responsive';
import {screenWidth} from "../../../00_base/variables/v-breakpoints";
import styled from "styled-components";

export const IconsTemplateArrows = ({activeIcons, renderToolIcon, showArrows}) => {
    return (
        <IconsWrapper>
            {activeIcons.map((icon, index) =>
                <div key={index}>
                    <MobileOnly>
                        {showArrows && mobileArrows[index]}
                        <div style={{position: "absolute", width:"100px", ...mobileIconsProps[index] || {}}}>
                            {renderToolIcon(icon, index)}
                        </div>
                    </MobileOnly>

                    <TabletPortraitOnly>
                        {showArrows && tabletPortraitArrows[index]}
                        <div style={{
                            margin: "10px",
                            position: "absolute", ...tabletPortraitIconsProps[index] || {}
                        }}>
                            {renderToolIcon(icon, index, {maxWidth: "180px"})}
                        </div>
                    </TabletPortraitOnly>

                    <TabletLandscapeOnly>
                        {showArrows && tabletLandscapeArrows[index]}
                        <div style={{
                            margin: "10px",
                            position: "absolute", ...tabletLandscapeIconsProps[index] || {}}}>
                            {renderToolIcon(icon, index, {maxWidth: "180px"})}
                        </div>
                    </TabletLandscapeOnly>

                    <DesktopOnly>
                            {showArrows && desktopArrows[index]}
                        <div style={{margin: "10px", position: "absolute", ...desktopIconsProps[index] || {}}}>
                            {renderToolIcon(icon, index, {width: "150px"})}
                        </div>
                    </DesktopOnly>

                    <DesktopLargeOnly>
                        {showArrows && desktopLargeArrows[index]}
                        <div style={{margin: "10px", position: "absolute", ...desktopLargeIconsProps[index] || {}}}>
                            {renderToolIcon(icon, index, {width: "150px"})}
                        </div>
                    </DesktopLargeOnly>

                    <DesktopMaxOnly>
                        {showArrows && desktopMaxArrows[index]}
                        <div style={{margin: "10px", position: "absolute", ...desktopMaxIconsProps[index] || {}}}>
                            {renderToolIcon(icon, index, {width: "150px"})}
                        </div>
                    </DesktopMaxOnly>
                </div>
            )}
        </IconsWrapper>
    )
}
const IconsWrapper = styled.div`
    height: 40%;
    width: 90%;
    position: absolute;
    flexDirection: column;
    bottom: 0;
    right: 13%;
    display: flex;
    justify-content: flex-start
    margin: 10% 10% 2%
    
    @media (min-width: ${screenWidth.mobileLgMax}) {
        height: 31%;
        
        width: 95%;
        right: 0;
    }
    @media (min-width: ${screenWidth.tabletMax}) {
    margin: 10%;
    }
    `;