import React from "react";
import {Image} from "../../01_atoms";
import {gutters} from "../../00_base/variables";

export const ScreenToolIcon = ({icon, width = 100}) => {
    return (
        <div style={iconWrapper}>
            {icon && <Image src={icon} width={width}/>}
        </div>

    )
};

const iconWrapper = {
    textAlign: "center",
    padding: `${gutters.md} 0`
};