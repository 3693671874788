import React, {useContext, useState} from "react";
import classNames from "classnames";
import {AdditionalDataTool} from "./additional-data-tool";
import {AdditionalDataTextBefore} from "./additional-data-text-before";
import {AdditionalDataTextAfter} from "./additional-data-text-after";
import {AdditionalDataPassword} from "./additional-data-password";
import {AdditionalDataContinue} from "./additional-data-continue";
import {AdditionalDataActionButtons} from "./additional-data-action-buttons";
import {AdditionalDataAudio} from "./additional-data-audio";
import {AdditionalDataImageBefore} from "./additional-data-image-before";
import {AdditionalDataImageAfter} from "./additional-data-image-after";
import {FrameContext} from "../../single-frame";
import "../../../../../../assets/styles/frame-additional-data.scss";

export const SingleFrameAdditionalData = ({categorydata}) => {
    const {handleToolDataChange} = useContext(FrameContext);
    const {extraTool, textAfter, topBarTitle, textBefore, toolPassword, actionButtons, imageAfter, imageBefore,frameAudio} = categorydata;

    const [isOpen, setIsOpen] = useState(extraTool || topBarTitle || textBefore || textAfter || imageAfter || imageBefore || toolPassword?.innerPassword || frameAudio || actionButtons?.length > 0);

    const innerProps = {
        handleToolDataChange: handleToolDataChange,
        ...categorydata
    };


    const getActiveItemsLength = () => {
        let length = 0;
        if (extraTool) length++;
        if (topBarTitle) length++;
        if (textBefore) length++;
        if (textAfter) length++;
        if (imageAfter) length++;
        if (imageBefore) length++;
        if (toolPassword) length++;
        if (frameAudio) length++;
        if (actionButtons?.length > 0) length++;
        return length ? `(${length})` : "";
    };

    return (
        <div className={"single-frame-box"}>
            <h4 className="single-frame-box-title" onClick={() => setIsOpen(!isOpen)}>{`תוספות ${getActiveItemsLength()}`}</h4>

            <div className={classNames("additional-data-body", {active: isOpen})}>
                    <AdditionalDataTool {...innerProps}/>
                    <AdditionalDataTextBefore {...innerProps}/>
                    <AdditionalDataTextAfter {...innerProps}/>
                    <AdditionalDataImageBefore {...innerProps}/>
                    <AdditionalDataImageAfter {...innerProps}/>
                    <AdditionalDataActionButtons {...innerProps}/>
                    <AdditionalDataAudio {...innerProps}/>
                    <AdditionalDataPassword {...innerProps}/>
                    <AdditionalDataContinue {...innerProps}/>
            </div>

        </div>
    )
};