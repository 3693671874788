class Blog {
    constructor(
        id,
        title,
        subtitle,
        description,
        description_short,
        url,
        ordinal_position,
        main_image,
        thumbnail_image,
        language_id,
        is_active,
        created_at
    ) {
        this.id = id;
        this.title = title;
        this.subtitle = subtitle;
        this.description = description;
        this.description_short = description_short;
        this.url = url;
        this.ordinal_position = ordinal_position;
        this.main_image = main_image;
        this.thumbnail_image = thumbnail_image;
        this.language_id = language_id;
        this.is_active = is_active;
        this.created_at = created_at;
    }
}

export default Blog