import React, {useContext, useEffect, useState} from "react";
import _ from "lodash";
import classNames from "classnames";
import {MdArrowDropDown, MdArrowDropUp, MdSave} from "react-icons/md";
import {DICTIONARY_CREATE, dictionaryList} from "./dictionary-create";
import {Heading, SimpleLoader} from "../../../01_atoms";
import {FieldText} from "../../../02_molecules";
import {Table, Td, Tr} from "../../../03_organisms";
import {LanguageContext} from "../../../../languages";

export const LanguageTranslation = ({language, onSave}) => {
    const [translation, setTranslation] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isSaved, setIsSaved] = useState("true");
    const {getLanguageTranslations} = useContext(LanguageContext);

    useEffect(() => {
        getLanguageTranslations(
            language,
            res => {
                setTranslation(res);
                setIsLoading(false);
            });
    }, [getLanguageTranslations, language]);

    useEffect(() => {
        setIsSaved(_.isEqual(translation, dictionaryList[language]) ? "true" : "");
    }, [language, translation]);

    const handleChange = (name, value) => {
        setTranslation({...translation, [name]: value})
    };

    const handleSubmit = () => {
        onSave(translation);
        // setIsSaved("loading");
        // setTimeout(() => {
        //     setIsSaved("false");
        // }, 1000);
    };

    const renderRow = (name, index) => {
        return (
            <Tr key={index}>
                <Td style={{width: "25%"}} vAlign={"center"}>
                    {name}
                </Td>
                <Td style={{width: "75%"}} vAlign={"center"}>
                    <FieldText changeHandler={handleChange}
                               controlStyle={{marginBottom: 0}}
                               inputStyle={{backgroundColor: !!translation[name] ? "#fff" : "transparent"}}
                               value={translation[name]}
                               name={name}/>
                </Td>
            </Tr>
        )
    };

    const Section = ({group, items, index}) => {
        const [isActive, setIsActive] = useState(true);
        const toggle = () => setIsActive(!isActive);

        return (
            <div className={classNames({"mt-3": index >= 0})}>
                <Heading tag={"h5"}
                         style={{display:"flex", alignItems:"center", marginTop:0,  marginBottom:0, cursor: "pointer"}}
                         onClick={toggle}>
                    {group}
                    {isActive ? <MdArrowDropUp/> : <MdArrowDropDown/>}
                </Heading>
                {isActive &&
                <Table>
                    <tbody>
                    {items.map(renderRow)}
                    </tbody>
                </Table>
                }
                <hr/>
            </div>
        )
    };

    if (isLoading) {
        return <SimpleLoader/>
    }

    return (
        <div>
            <div className={`mt-5 d-flex justify-content-${isSaved ? "center" : "start"}`}
                 style={{width:"100px"}}>
                {!isSaved && <MdSave size={25} cursor={"pointer"} onClick={handleSubmit}/>}
                {isSaved === "loading" && <SimpleLoader size={25}/>}
            </div>
            {Object.entries(DICTIONARY_CREATE).map(([key, value], index) => {
                return <Section key={index}
                                group={key}
                                index={index}
                                items={value}/>
            })}

            {!isSaved && <MdSave/>}
        </div>
    )
}