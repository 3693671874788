import React, {useState, useEffect, useContext, useCallback} from "react";
import styled from "styled-components";
import classNames from "classnames";
import {TriviaOption} from "./trivia-option";
import {TriviaContext} from "./tool-trivia";
import {FrameContext} from "../../05_screens/tool/screen-tool";
import {useEventListener} from "../../../hooks/use-event-listener";

export const TriviaSection = (
    {
        questionIndex = 0,
        question,
        questionImageUrl,
        questionLabel,
        options,
        color,
        bgColor,
        textColor,
        toolData
    }) => {
    const triviaContext = useContext(TriviaContext);
    const frameContext = useContext(FrameContext);
    const {userAnswers, adventureName, setUserAnswers, activeSection, isResolve, isGrid, isBlocks} = triviaContext;

    const [activeOption, setActiveOption] = useState(userAnswers[questionIndex]?.u);

    const isActive = activeSection === questionIndex;
    const frameTextColor = frameContext?.textColor;

    const sectionClassName = classNames("trivia-section", {active: isActive || isBlocks || (isGrid && userAnswers[questionIndex]?.u >= 0)});


    const keyPressHandler = ({key}) => {
        if (isActive && key > 0 && key <= options.length) {
            setActiveOption(key - 1)
        }
    };

    useEventListener('keydown', keyPressHandler);


    useEffect(() => {
        setUserAnswers({questionIndex: questionIndex, answerIndex: activeOption});
    }, [questionIndex, activeOption, setUserAnswers]);

    return (
        <TriviaSectionWrapper backgroundColor={bgColor || color} className={sectionClassName}>

            {questionLabel &&
            <TriviaLabel className="trivia-question-label" color={frameTextColor}>
                {questionLabel.replace("{name}", adventureName) || `שאלה ${questionIndex + 1}`}
            </TriviaLabel>}

            <TriviaQuestion className="trivia-question" color={frameTextColor}>
                {question?.replace("{name}", adventureName)}
            </TriviaQuestion>

            {questionImageUrl &&
            <div className="trivia-question-image">
                <img src={questionImageUrl} alt=""/>
            </div>}

            <div className="trivia-options">
                {options.map((option, index) => {
                    return <TriviaOption value={option}
                                         color={bgColor || color}
                                         bgColor={bgColor}
                                         textColor={textColor}
                                         active={activeOption === index}
                                         onClick={() => !isResolve && setActiveOption(index)}
                                         index={index}
                                         key={index}/>
                })}
            </div>
        </TriviaSectionWrapper>
    )
};


const TriviaSectionWrapper = styled.div`
    .trivia-view-grid & {
        background-color: ${props => props.backgroundColor};
    }
`;

const TriviaLabel = styled.h6`
    color: ${props => props.color};
`;

const TriviaQuestion = styled.h4`
    color: ${props => props.color};
`;