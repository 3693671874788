import {fetchData} from "./fetch-data";
import {
    adventureObject,
    languageObject,
} from "../models/build-models";

const extension = 'languages';


export const SET_ALL_LANGUAGES = 'SET_LANGUAGES';
export const SET_ACTIVE_LANGUAGE = 'SET_ACTIVE_LANGUAGE';
export const CREATE_LANGUAGE = 'CREATE_LANGUAGE';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const DELETE_LANGUAGE = 'DELETE_LANGUAGE';

export const getLanguage = (adventureId, cb) => {
    fetchData(
        "get",
        `${extension}/${adventureId}`,
        {},
        response => {
            const adventure = adventureObject(response);
            cb(adventure);
        }
    )
};


export const setAllLanguages = (pageIndex = 0, cb) => {
    // const MOCK = [
    //     {
    //         id: 0,
    //         title: "עברית",
    //         name: "he",
    //         translations: {},
    //         flag: ""
    //     },
    //
    //     {
    //         id: 1,
    //         title: "English",
    //         name: "en",
    //         translations: {},
    //         flag: ""
    //     }
    // ];
    // return  (dispatch, getState) => {
    //
    //     dispatch({
    //         type: SET_ALL_LANGUAGES,
    //         allLanguages: MOCK
    //     });
    //     if (cb) {
    //         cb(MOCK);
    //
    //     }
    // }

    return async (dispatch, getState) => {

        await fetchData(
            'GET',
            `${extension}/?page=${pageIndex}`,
            {},
            response => {
                let allLanguages = [];
                for (const key in response) {
                    const language = response[key];
                    allLanguages.push(languageObject(language))
                }
                dispatch({
                    type: SET_ALL_LANGUAGES,
                    allLanguages: allLanguages
                });
                if (cb) {
                    cb(allLanguages);
                }
            }
        )
    }
};


export const setSingleLanguage = (languageId, cb) => {
    return async (dispatch) => {
        await fetchData(
            "GET",
            `${extension}/${languageId}`,
            "",
            response => {
                dispatch({
                    type: SET_ACTIVE_LANGUAGE,
                    language: languageObject(response.language)
                });
            }
        )
    }
};

export const createLanguage = (language, cb) => {
    return async (dispatch) => {
        debugger
        await fetchData(
            'POST',
            extension,
            language,
            response => {
                dispatch({
                    type: CREATE_LANGUAGE,
                    language: languageObject(response.language)
                });
                if (cb) {
                    cb(response)
                }
            }
        )
    }
};

export const updateLanguage = (language, callback) => {
    return async (dispatch) => {
        await fetchData(
            "PUT", `${extension}/${language.id}`, language,
            success => {
                dispatch({type: UPDATE_LANGUAGE, ...success.data});
                if (callback) {
                    callback(success.data)
                }
            }
        )
    };
};

export const deleteAdventure = languageId => {
    return async (dispatch) => {
        await fetchData(
            "DELETE",
            `${extension}/${languageId}`,
            {},
            success => {
                dispatch({type: DELETE_LANGUAGE, id: languageId});
                return success
            }
        )
    }
};