
export const selectAdventure = state => state;

// ADVENTURE //
export const selectAllAdventures = state => state.allAdventures;
export const selectCurrentAdventure = state => state.adventure;
export const selectUserAdventures = state => state.userAdventures;
export const selectIsUserAdventuresLoaded= state => state.isUserAdventuresLoaded;
export const selectUserAdventuresPageIndex = state => state.userAdventuresPageIndex;


// ADVENTURE TYPE //
export const selectAllAdventureTypes = state => state.allAdventureTypes;
export const selectCurrentAdventureType = state => state.adventureType;
export const selectSingleAdventureType = state => state.singleAdventureType;
export const selectShowInSiteAdventureTypes = state => state.showInSiteAdventureTypes;


// ADVENTURE DATA //
export const selectCurrentAdventureData = state => state.adventureData;


// RIDDLES //
export const selectCurrentRiddles = state => state.riddles;
export const selectShowInStoreRiddles = state => state.showInStoreRiddles;

// FRAMES //
export const selectCurrentFrames = state => state.frames;
export const selectActiveFrame = state => state.activeFrame;


// TEMPLATES //
export const selectAllAdventureTemplates = state => state.allAdventureTemplates;


// CATEGORIES //
export const selectAllCategories = state => state.allCategories;
export const selectActiveCategory = state => state.activeCategory;

// TAGS //
export const selectAllTags = state => state.allTags;

// BLOG //
export const selectAllBlogs = state => state.allBlogs;
export const selectActiveBlog = state => state.activeBlog;

// LANGUAGE //
export const selectAllLanguages = state => state.allLanguages;