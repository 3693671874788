import {fetchData} from "./fetch-data";
import AdventureData from "../models/adventureData";
import {duplicateRiddles, setAdventureRiddles} from "./riddle-actions";

const extension = 'adventureData';

export const UPDATE_ADVENTURE_DATA = "UPDATE_ADVENTURE_DATA";
export const SET_SINGLE_ADVENTURE_DATA = 'SET_SINGLE_ADVENTURE_DATA';

const adventureDataModel = (adventureData) => {
    return new AdventureData(
        adventureData.id,
        adventureData.adventurename,
        adventureData.description,
        adventureData.description_homepage,
        adventureData.themeid,
        adventureData.istimeron,
        adventureData.riddles,
        adventureData.thumbnail,
        adventureData.thumbnail_mobile,
        adventureData.thumbnail_tablet,
        adventureData.thumbnail_desktop,
        adventureData.welcome_screen_url_mobile,
        adventureData.welcome_screen_url_tablet,
        adventureData.welcome_screen_url_desktop,
        adventureData.adventure_logo,
        adventureData.adventure_type,
        adventureData.is_main,
        adventureData.title_label,
        adventureData.icon_label,
        adventureData.icon,
        adventureData.video_url,
        adventureData.created_at
    );
}

export const getAdventureData = async (adventureDataId, cb) => {
    await fetchData(
        `get`,
        `${extension}/${adventureDataId}`,
        {},
        success => cb(adventureDataModel(success))
    )
};

export const setSingleAdventureData = adventureDataId => {
    return async (dispatch, getState) => {
        await getAdventureData(
            adventureDataId,
            success => {
                dispatch({
                    type: SET_SINGLE_ADVENTURE_DATA,
                    adventureData: adventureDataModel(success)
                })
            })
    }
};

export const createAdventureData = (adventureData = {}, cb) => {
    return async (dispatch, getState) => {
        await fetchData("post",
            extension,
            {...adventureData, themeid: !!adventureData.themeid ? adventureData.themeid : 0, id: null},
            success => {
                if (cb) {
                    cb(adventureDataModel(success.data));
                }

            }
        )
    }
};

export const updateAdventureData = (adventureData, callback) => {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_ADVENTURE_DATA,
            adventureData: adventureData
        });
        await fetchData(
            "put",
            `adventureData/${adventureData.id}`,
            adventureData
        )
    }
};

export const reorderAdventureDataRiddles = (riddles) => {
    return (dispatch, getState) => {
        const adventureData = getState().adventureData;
        dispatch(updateAdventureData({...adventureData, riddles: riddles}));
    }
};


export const duplicateAdventureData = (adventureDataId, cb) => {
    return async (dispatch) => {
        await getAdventureData(adventureDataId, adventureData => {
                const create_adventureData = (newRiddles) => {
                    const data = {...adventureData, riddles: newRiddles.map(riddle => riddle.id)};
                    dispatch(createAdventureData(data, callback));
                };
                const duplicate_riddles = (riddles, frames) => {
                    dispatch(duplicateRiddles(riddles, frames, create_adventureData))
                };

                const callback = newAdventureData => cb(newAdventureData.id);

                dispatch(setAdventureRiddles(adventureData.riddles, duplicate_riddles))
            }
        )
    }
};