import React from 'react';
import PropTypes from 'prop-types';
import {Translation} from "../../../../../languages";
import {DICT} from "../../../../../languages/dictionary-names";
import {FilesUploader} from '../../../../02_molecules';

const checkIsValid = (toolData) => {
    const expectedLength = toolData.images_min || 12;
    const invalidLength = expectedLength - toolData.items.map(item => item.images.length)[0];
    const validLength = expectedLength - invalidLength;

    if (invalidLength <= 0) {
        return 100
    } else {
        return validLength / expectedLength * 100;
    }
};

const CreateToolGallery = ({toolData, handleToolDataChange, invalidFields, checkIfValid}) => {
    const isEditable = toolData.items.some((item) => item.editable);
    const {items = [{images: [], frames: []}], userData, images_min, images_max} = toolData;

    return (
        <div className='create-tool-gallery'>
            {isEditable ?
                <FilesUploader name={'userData'}
                               value={userData}
                               backgroundSize={"cover"}
                               maxItems={images_max}
                               minItems={images_min}
                               expectedLength={images_min}
                               triggerValue={<Translation id={DICT.UPLOAD_IMAGES}/>} // ${finish ? "משימה הושלמה" : "העלו תמונות"} (${images_max || 35} / ${(userData || images || []).length})` <Placeholders />
                               changeHandler={(name, value) => handleToolDataChange(checkIsValid, name, value)}/>
                :
                items.map((item, index) => {
                    return (
                        <FilesUploader key={index}
                                       name={'images'}
                                       backgroundSize={"cover"}
                                       value={item.images}
                                       minItems={images_min || 5}
                                       maxItems={images_max || 15}
                                       expectedLength={images_min || 15}
                                       triggerValue={<Translation
                                           id={DICT.UPLOAD_IMAGES}/>} // ${finish ? "משימה הושלמה" : "העלו תמונות"} (${images_max || 35} / ${(userData || images || []).length})` <Placeholders />
                                       changeHandler={(name, value) => handleToolDataChange(checkIsValid, name, value, index)}/>
                    )
                })}
        </div>
    )
};


CreateToolGallery.defaultProps = {
    handleToolDataChange: (toolData) => console.log('toolData', toolData),
    toolData: {items: []}
};

CreateToolGallery.propTypes = {
    toolData: PropTypes.object,
    warningActive: PropTypes.bool,
    handleToolDataChange: PropTypes.func
};


export default CreateToolGallery;