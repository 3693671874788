import styled from 'styled-components';
import {fadeIn} from '../../00_base/animations/keyframes';
import {gutters} from "../../00_base/variables";

const ModalWrapper = styled.div`
    animation: ${fadeIn} .2s ease-out;
    position: fixed;
    background-color: ${props => props.backgroundColor};
    width: 100%;
    height: 100%;
    z-index: ${props => props.zIndex || 9999};
    padding:${props => props.padding || `0 ${gutters.sm}`};
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity ${props => props.speed};
    opacity: 0;
    &.active, &.closing {
        opacity: 1;
    }
`;

const ModalContent = styled.div`
    width: ${props => props.width};
    height: ${props => props.height ? props.height : ''};
    max-height: ${props => props.maxHeight ? props.maxHeight : '80%'};
    overflow: ${props => props.overflow ? props.overflow : ''};
    background-color: ${props => props.backgroundColor};
    padding: ${props => props.padding};
    border-radius: ${props => props.borderRadius || '10px'};
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar-track {
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        display: none;
    }
`;

const ModalTitle = styled.h3`
    font-size: ${props => props.fontSize};
    text-align: ${props => props.textAlign};
`;

const ModalSubtitle = styled.h5`
    font-size: ${props => props.fontSize};
    text-align: ${props => props.textAlign};
`;

const ModalDescription = styled.p`
    font-size: ${props => props.fontSize};
    text-align: ${props => props.textAlign};
`;

const ModalCloseTrigger = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    z-index:10;
    border-radius: 50%;
    cursor:pointer;
    background-color:#000;
    color:#fff;
    display:flex;
    justify-content:center;
    align-items:center;
    width:25px;
    height: 25px;
`;

export { ModalWrapper, ModalContent, ModalTitle, ModalSubtitle, ModalDescription, ModalCloseTrigger };