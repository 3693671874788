import {fetchData} from "./fetch-data";
import {addFrameToRiddle} from "./riddle-actions";
import Template from "../models/template";
import {setAdventureTemplates} from "./templates-actions";

const extension = 'riddlesTemplate';
export const SET_FRAMES = "SET_FRAMES";
export const SET_ACTIVE_FRAME = "SET_ACTIVE_FRAME";
export const CREATE_FRAME = "CREATE_FRAME";
export const UPDATE_FRAME = "UPDATE_FRAME";
export const DELETE_FRAME = "DELETE_FRAME";

const frameModel = (frame) => {
    return new Template(
        frame.id,
        frame.title,
        frame.description,
        frame.categories,
        frame.categorydata,
        frame.description_short,
        frame.printimageurl,
        frame.riddle_name,
        frame.show_in_store,
        frame.thumbnail,
        frame.user_email,
        frame.videourl,
        frame.is_published,
        frame.tags,
        frame.created_at,
        frame.editable,
        frame.clues || [],
        frame.instructions || {title: "", subtitle: "", description: ""},
        frame.tips || [],
        frame.modals
    )
};
export const getSingleFrame = (frameId) => new Promise(resolve => {
    if (!frameId) {
        resolve({})
    } else {
        fetchData(
            "get",
            `${extension}/${frameId}`,
            {},
            frameData => {
                const frame = frameModel(frameData);
                resolve(frame)
            }
        )
    }
});

export const setActiveFrame = (frameId) => {
    return (dispatch) => {
        dispatch({
            type: SET_ACTIVE_FRAME,
            frameId: frameId
        })
    }
};

export const closeActiveFrame = () => {
    return (dispatch) => {
        dispatch({
            type: SET_ACTIVE_FRAME,
            frameId: null
        })
    }
};

export const createFrame = (templateData, riddleId, cb) => {
    return async (dispatch, getState) => {
        fetchData('POST',
            extension,
            {...templateData, category: templateData.categories},
            (newFrame) => {
                dispatch({
                    type: CREATE_FRAME,
                    frame: frameModel(newFrame.data)
                })
                if (riddleId && newFrame?.data) {
                    dispatch(addFrameToRiddle(riddleId, newFrame?.data.id))
                }
                if (cb) {
                    cb(newFrame.data.id)
                }
            }
        )
    }
};

export const getRiddleFrames = (riddlestemplates, cb) => {
    return async (dispatch, getState) => {
        const getFrame = (templateId) => new Promise(resolve => {
            dispatch(getSingleFrame(templateId, (template) => {
                resolve(template)
            }))
        });
        Promise.all(riddlestemplates.map(getFrame)).then(
            response => {
                const frame = frameModel(response);
                setAdventureTemplates(frame)
                if (cb) {
                    cb(frame)
                }
            }
        )
    };
};

export const deleteFrame = (frameId) => {
    return async (dispatch, getState) => {
        fetchData(
            'DELETE',
           `${extension}/${frameId}`,
            {},
            success => {
                dispatch({
                    type: DELETE_FRAME,
                    frameId: frameId
                })
            }
        )
    }
}

export const updateFrame = (frameId, name, value) => {
    return async (dispatch, getState) => {
        const frame = getState().frames.find(frame => frame.id === frameId);
        const data = {...frame, [name]: value};
        data.category = data.categories;
        fetchData('PUT',
            `${extension}/${frameId}`,
            data,
            () => {
                dispatch({
                    type: UPDATE_FRAME,
                    frameId: frameId,
                    name: name,
                    value: value
                });
            },
  error => console.log(error),
    {
            successTitle:"הפריים עודכן בהצלחה",
            failTitle:"שגיאה בעדכון הפריים",
            },
        )
    };
};

export const updateFrameTool = (frameId, name, value) => {
    return async (dispatch, getState) => {
        const frame = getState().frames.find(frame => frame.id === frameId);
        const categorydata = {...frame.categorydata, [name]: value};
        dispatch(updateFrame(frameId, "categorydata", categorydata));
    };
};

export const updateUserFrameData = (checkIfToolValid, frameId, name, value, index, optionIndex) => {
    return async (dispatch, getState) => {
        const frames = getState().frames;
        const frame = frames.find(frame => frame.id === frameId);
        const {categorydata} = frame;

        if (index >= 0) {
            if (optionIndex >= 0) {
                categorydata.items[index][name] = categorydata.items[index][name].map((option, i) => i === optionIndex ? value : option);
            } else {
                if (!!categorydata.items) {
                    categorydata.items[index][name] = value;
                }
            }
        } else {
            if (name) {
                categorydata[name] = value;
            }
        }
        const isValid = checkIfToolValid(categorydata);

        await fetchData("put",
            `riddlesTemplate/${frameId}`,
            {...frame, category: frame.categories, categorydata: {...categorydata, isValid: isValid}},
            (success) => {
                dispatch({
                    type: SET_FRAMES,
                    frames: frames.map(item => frameId === item.id ? success.data : item)
                })
            })
    }
};