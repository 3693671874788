export const paymentDictionary = {
    mainTitle: {
        he: "ההזמנה שלך",
        en: "Your Order"
    },
    adventures: {
        he: "הרפתקאות",
        en: "adventures"
    },

    adminPriceLabel: {
        he: "מחיר מותאם אישית (אדמין בלבד)",
        en: "Custom price (admins only)"
    },
    adminPricePlaceholder: {
        he: "הכנס מחיר מותאם אישית",
        en: "Insert custom price"
    },
    shekel: {
        he: 'ש"ח',
        en: "NIS"
    },
    adventuresLength: {
        he: "כמות הרפתקאות",
        en: "How many adventures?"
    },
    howToGetAdventure: {
        he: "איך תרצו לקבל את ההרפתקה",
        en: "How would you like to get the adventure"
    },
    selfCollection: {
        he: "איסוף עצמי",
        en: "Self collection"
    },
    comment: {
        he: "הערות",
        en: "Comment"
    },
    delivery: {
        he: "משלוח",
        en: "delivery"
    },
    deliveryAddress: {
        he: "כתובת למשלוח",
        en: "Address"
    },
    deliveryMessage: {
        he: 'עלות משלוח 40 ש"ח. המשלוח יסופק עד 7 ימי עסקים.',
        en: "Delivery cost 40 NIS. Delivery will be delivered up to 7 business days."
    },
    deliveryWarning: {
        he:"",
        en:""
    },
    customerDetails: {
        he: "פרטי הלקוח.ה",
        en: "Customer Details"
    },
    mailPlaceholder: {
        he: 'כתובת דוא"ל למעקב',
        en: "Tracking Email Address"
    },
    mailWarning: {
        he:"יש להזין כתובת מייל",
        en:"Please insert your mail address"
    },
    couponDiscount: {
        he: "הנחת קופון",
        en: "Coupon discount"
    },
    phonePlaceholder: {
        he: "מספר טלפון ליצירת קשר",
        en: "Contact phone number"
    },
    contactPlaceholder: {
        he: "איש קשר",
        en: "Contact"
    },
    contactWarning: {
        he: "יש להזין איש קשר",
        en: "Please insert contact name"
    },
    couponLabel: {
        he: "קוד קופון",
        en: "Coupon Code"
    },
    couponPlaceholder: {
        he: "אם ברשותך קוד קופון הכנס אותו כאן:",
        en: "Insert coupon code (if you have one)"
    },
    couponNotExist: {
        he: "קופון לא קיים",
        en: "Coupon not exist"
    },
    send: {
        he: "שלח",
        en: "Send"
    },
    confirmTerms: {
        he:"הנני מאשר את",
        en:"I confirm the"
    },
    termsWarning: {
        he: "יש לאשר את התקנון",
        en: "Please confirm the terms"
    },
    terms: {
        he: "תקנון",
        en: "Terms and Conditions"
    },
    pay: {
        he: "לתשלום",
        en: "Go to payment"
    },
    finishOrder: {
        he: "לסיום ההזמנה",
        en: "Finish ordering"
    },
    cityPlaceholder: {
        he:"עיר",
        en:"City"
    },
    cityWarning: {
        he:"יש להזין עיר",
        en:"Please insert a city"
    },
    streetPlaceholder: {
        he:"רחוב",
        en:"Street"
    },
    streetWarning: {
        he:"יש להזין רחוב",
        en:"Please insert street name"
    },
    homeNumber: {
        he:"מס' בית",
        en:"Home number"
    },
    apartment: {
        he:"מס' דירה",
        en:"Apartment number"
    },
    postalCode: {
        he:"מיקוד",
        en:"Postal Code"
    },
};