
export const spaceDictionary = {
    chooseLanguage: {
        he:"בחר שפה",
        en: "Choose adventure language"
    },
    mainParagraph: {
        he: `                        הצטרפו לחוויה מסחררת, מאתגרת ומלאת אדרנלין, שתיקח אתכם הרחק מעבר לגלקסיית שביל החלב. הרפתקת "חדר
             בריחה" ייחודית מסוגה בשילוב כלים דיגיטליים. ברמת קושי המתאימה גם למשפחות עם ילדים וגם לקבוצות של
             מבוגרים.<br/>
             היקום מחכה!
             <br/><br/>
             * את המשחק ניתן לשחק על גבי סלולרי או דסקטופ.<br/>
             * יש צורך בחיבור לאינטרנט.<br/>
             * שימו לב, קוד המשחק תקף ל24 שעות מרגע שהתחלתם לשחק.
             <br/><br/>
             במהלך המשחק תופיע חידה בהתאמה אישית. בחרו את גיבור.ת ההרפתקה שלכם (מישהו/י שכל הקבוצה מכירה)
             ומלאו את הפרטים שלו.ה כאן. הפרטים יופיעו במשחק בלבד ולא ייעשה בהם שימוש נוסף.`,
        en: `Join a thrilling, challenging and adrenaline-pumped adventure, which will take you far beyond the milky way galaxy. A unique escape room-like experience incorporating digital tools. At a suitable level of difficulty for families with children and groups of adults as well.<br/>
             The universe awaits!
             <br/><br/>
             * The game can be played on mobile or desktop.<br/>
             * Internet connection is required.<br/>
             *  Note, the game code is valid for 24 hours from when you start the game.
             <br/><br/>
           During the game, a custom-matched riddle will appear. Choose the hero of your adventure (someone everyone in the group knows) and fill out their details here. The details will appear on the game alone and no further use of them will be made.`
    },
    price: {
        he:`מחיר ההרפתקה: 99 ש"ח`,
        en:"The adventure's price: 99 NIS"
    },
    createNewAdventure: {
        he:"צור הרפתקה",
        en:"Create an adventure"
    },
    heroName: {
        he:"שם גיבור.ת ההרפתקה",
        en:"Name of the hero"
    },
    heroPhone: {
        he:"מספר הטלפון של גיבור.ת ההרפתקה",
        en:"Phone number of the hero"
    },
    heroImage: {
        he:"תמונה של גיבור.ת ההרפתקה",
        en:"Attach a photo of the hero"
    },
    purchaseButton: {
        he: "לרכישה",
        en: "Purchase"
    },
    pleaseRegister: {
        he:"בכדי ליצור הרפתקה חדשה אנא בצע הרשמה לאתר",
        en:"Register"
    },
    nameWarning: {
        he: "שם הגיבור חייב להכיל 2 תוים ומעלה",
        en: "Hero name must contain at least 2 digits"
    },
    phoneWarning: {
        he:"יש למלא מספר טלפון",
        en:"Please insert the hero phone number"
    },
    imageWarning: {
        he:"יש להעלות את תמונת הגיבור",
        en:"Please upload an image of the hero"
    },
};