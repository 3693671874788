import React from 'react';
import {Element, HBox} from 'react-stylesheet';
import {gutters} from '../../../00_base/variables';
import {FieldCheckbox, FieldColor, FieldRadio, FieldSelect, FieldText, FilesUploader} from '../../../02_molecules';
import {Switcher} from "../../../01_atoms";

const CreateToolTifzoret = (
    {
        handleToolDataChange, templatedata, number = "",
        correctImages = [],
        wrongImages = [],
        correct_min = 15,
        correct_max = 35,
        wrong_min = 0,
        wrong_max = 50,
        show_numbers = true,
        show_letters = true,
        itemBackgroundColor = "#000000",
        squareBackgroundColor = "#ffffff",
        squareStatus = "flicker",
        squares_language = "hebrew"
    }) => {

    const onChange = (name, value) => {
        handleToolDataChange(name, value);
    };

    const squaresLanguages = [
        {id: 0, label: "עברית", value: "hebrew"},
        {id: 1, label: "אנגלית", value: "english"}
    ];

    const squareStatusOptions = [
        {id: 0, value: "flicker", label: "נעלם"},
        {id: 1, value: "permanent", label: "קבוע"}
    ];

    return (
        <div className={"create-tool-tifzoret"}>
            <div style={{paddingLeft: gutters.sm, paddingRight: gutters.sm}}>
                <div>
                    <FieldText label={'מספר ליצירת התפזורת'}
                               placeholder={`הכנס מספר`}
                               value={number}
                               controlStyle={{marginBottom: 0, width: '100%'}}
                               inputStyle={{backgroundColor: '#fff'}}
                               name={'number'}
                               changeHandler={onChange}/>
                </div>
                <HBox>
                    <Element padding={`${gutters.sm} 0 ${gutters.sm} ${gutters.sm}`}>
                        <FieldText label="מינימום תמונות נכונות"
                                   name="correct_min"
                                   value={correct_min}
                                   changeHandler={onChange}/>
                    </Element>
                    <Element padding={`${gutters.sm}`}>
                        <FieldText label="מקסימום תמונות נכונות"
                                   name="correct_max"
                                   value={correct_max}
                                   changeHandler={onChange}/>
                    </Element>
                </HBox>
                <HBox>
                    <Element padding={`0 0 ${gutters.sm} ${gutters.sm}`}>
                        <FieldText label="מינימום תמונות לא נכונות"
                                   name="wrong_min"
                                   value={wrong_min}
                                   changeHandler={onChange}/>
                    </Element>
                    <Element padding={`0 ${gutters.sm}`}>
                        <FieldText label="מקסימום תמונות לא נכונות"
                                   name="wrong_max"
                                   value={wrong_max}
                                   changeHandler={onChange}/>
                    </Element>
                </HBox>
                <Element>

                    <FieldColor changeHandler={onChange}
                                name="itemBackgroundColor"
                                label="צבע רקע"
                                value={itemBackgroundColor}/>
                    <h5 className="my-2">ריבוע בלחיצה</h5>
                    <FieldSelect label={"אופן הצגת הריבוע"}
                                 options={squareStatusOptions}
                                 value={squareStatusOptions.find(item => item.value === squareStatus)}
                                 changeHandler={(name, value) => onChange(name, value.value)}
                                 controlStyle={{position: "relative", zIndex: 5}}
                                 name="squareStatus"/>
                    <FieldColor changeHandler={onChange}
                                name="squareBackgroundColor"
                                label="צבע ריבוע"
                                value={squareBackgroundColor}/>
                    <FieldCheckbox changeHandler={onChange}
                                   value={show_letters ? ["הראה אותיות"] : []}
                                   name='show_letters'
                                   options={["הראה אותיות"]}/>
                    <FieldCheckbox changeHandler={onChange}
                                   value={show_numbers ? ["הראה מספרים"] : []}
                                   name='show_numbers'
                                   options={["הראה מספרים"]}/>


                    <hr/>

                    <FieldSelect label='שפת תוים בלחיצה על תמונה'
                                 name='squares_language'
                                 value={squaresLanguages.find(lang => lang.value === squares_language)}
                                 changeHandler={(name, value) => onChange(name, value.value)}
                                 options={squaresLanguages}/>

                </Element>
                <Element marginTop={gutters.md}>
                    <FilesUploader multiple={true}
                                   name={'correctImages'}
                                   previewWidth={'14rem'}
                                   previewHeight={'14rem'}
                                   triggerStyle={{width: "100%"}}
                                   label={'תמונות נכונות'}
                                   triggerValue={'העלה תמונות נכונות'}
                                   setIsFileLoading={data => console.log('data', data)}
                                   changeHandler={onChange}
                                   value={correctImages}/>
                </Element>
                <Element marginTop={gutters.md}>
                    <FilesUploader multiple={true}
                                   name={'wrongImages'}
                                   previewWidth={'14rem'}
                                   previewHeight={'14rem'}
                                   triggerStyle={{width: "100%"}}
                                   label={'תמונות לא נכונות'}
                                   triggerValue={'העלה תמונות לא נכונות'}
                                   setIsFileLoading={data => console.log('data', data)}
                                   changeHandler={onChange}
                                   value={wrongImages}/>
                </Element>
            </div>

        </div>
    )
}

CreateToolTifzoret.defaultProps = {};

CreateToolTifzoret.propTypes = {};

export default CreateToolTifzoret;