import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {duplicateAdventure} from "../../../store/actions/adventure-actions";
import {selectAllAdventureTypes} from "../../../store/selectors";
import {Button, Heading, Span} from "../../01_atoms";
import {FieldSelect, FieldText} from "../../02_molecules/form-fields";

export const AdventureMain = ({adventure, handleChange}) => {
    const allAdventureTypes = useSelector(selectAllAdventureTypes);
    const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
    const adventureType = allAdventureTypes.find(type => type.id === adventure.adventuretype);
    const dispatch = useDispatch();

    const duplicate = () => {
        setIsDuplicateLoading(true);
        dispatch(duplicateAdventure(adventure.id, () => setIsDuplicateLoading(false)));
    }
    return (
        <div>
            <h4>פרטים כלליים</h4>
            <FieldText changeHandler={handleChange}
                       value={adventure ? adventure.title : ""}
                       label="כותרת ההזמנה"
                       placeholder={"הכניסו את כותרת ההזמנה"}
                       name="title"/>

            <FieldText changeHandler={handleChange}
                       value={adventure ? adventure.adventure_name : ""}
                       label="גיבור ההרפתקה"
                       placeholder="הכניסו את גיבור ההרפתקה"
                       name="adventure_name"/>

            <FieldSelect label={"סוג הרפתקה"}
                         name={"adventureType"}
                         value={{id: adventureType?.id, value: adventureType?.id, label: adventureType?.name}}
                         changeHandler={(name, value) => handleChange(name, value.id)}
                         options={allAdventureTypes ? allAdventureTypes.map(option => {
                             return {id: option.id, value: option.id, label: option.name}
                         }) : []}
                         controlStyle={{position: "relative", zIndex: 10}}/>


            {adventure &&
            <>
                <Heading tag={"h5"} textAlign={"right"}>
                    <Span fontWeight="normal">יוצר ההרפתקה: </Span>
                    {adventure?.user_email}
                </Heading>

                <Heading tag={"h5"} textAlign={"right"}>
                    <Span fontWeight="normal">סיסמא: </Span>
                    {adventure.passcode}
                </Heading>

                <Heading tag={"h5"} textAlign={"right"}>
                    <a href={`/he/create-adventure/${adventure.id}`}
                       rel="noopener noreferrer"
                       target="_blank">עמוד עריכה באתר </a>
                </Heading>
                {adventureType &&
                <Heading tag={"h5"} textAlign={"right"}>
                    <a href={`https://${adventureType.game_subdomain || "play"}.youdoadventures.com/`}
                       rel="noopener noreferrer"
                       target="_blank">עמוד המשחק </a>
                </Heading>
                }
                <div>
                    <Button onClick={duplicate}
                            style={{marginLeft:"10px"}}
                            value={isDuplicateLoading ? "..." : "שכפל הרפתקה"}/>
                    {/*<Button>מחק הרפתקה</Button>*/}
                </div>
            </>
            }
        </div>
    )
};