import React from "react";
import {TabletOnly} from "../../01_atoms";
import {MdClose, MdMenu} from "react-icons/md";
import classNames from "classnames";
import "../../../assets/styles/burger-menu.scss";
import {tabsItems} from "../../04_layout/app-sidebar/sidebar-items";

export const BurgerMenu = ({isActive, toggle}) => {
    const renderItem = (item) => {
        return <div key={item.id} className={"mobile-menu-item"}>
            <a href={item.href}>{item.label}</a>
        </div>
    };
    const BurgerIcon = isActive ? MdClose : MdMenu;
    return <TabletOnly id="app-mobile-nav">
        <div className={classNames("burger-menu", {active: isActive})}>
            <BurgerIcon className={"burger-menu-icon"} onClick={() => toggle(!isActive)}/>
            {isActive &&
            <div className="mobile-menu">
                {tabsItems.map(renderItem)}
            </div>
            }
        </div>
    </TabletOnly>
};