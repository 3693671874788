import React, {useContext} from "react";
import {Col, Row} from "react-grid-system";
import {Button, Image, Heading, Paragraph} from "../../01_atoms";
import {Link} from "react-router-dom";
import {LanguageContext, Translation} from "../../../languages";
import {DICT} from "../../../languages/dictionary-names";
import {ThemeContext} from "../../../providers/theme-provider";

const COOL_BOX = "https://storage.googleapis.com/assets.youdoadventures.com/website/cool_box.svg";


export const ContactUsCard = () => {
    const {userLanguage, rtl} = useContext(LanguageContext);
    const {mode} = useContext(ThemeContext);
    const darkMode = mode === "dark";
    return <Row className={"align-items-center justify-content-center py-7"}>
        <Col sm={7}>
            <Heading tag="h3" textAlign={rtl ? "right" : "left"}>
                <Translation id={DICT.DID_FOUND_WHAT_YOU_LOOKING_FOR}/>
            </Heading>
            <Paragraph color={darkMode ? "#fff" : "#000"}>בחרו מתוך המבחר את הקופסה שהכי מדברת אליכם. לפניכם מתגי חיפוש בעזרתם תוכלו לדייק
                את
                ההעדפות שלכם ולמצוא בדיוק את מה שמתאים לכם.</Paragraph>
            <Button className={`d-flex ${rtl ? "mr" : "ml"}-auto`}
                    fontWeight="bold"
                    size="md"
                    style={{borderRadius: "20px", whiteSpace: "nowrap"}}
                    color="#fff">
                <Link to={`/${userLanguage}/contact-us`} style={{color: "#fff"}}>
                    <Translation id={DICT.CLICK_HERE}/>
                </Link>
            </Button>
        </Col>
        <Col sm={3}>
            <Image src={COOL_BOX} width={"100%"}/>
        </Col>
    </Row>
}