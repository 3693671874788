import React, {useEffect, useState} from "react";
import {Col, Row} from "react-grid-system";
import {FieldCheckbox, FieldText, FilesUploader} from "../../../../../02_molecules";
// import {MdArrowDropDown, MdArrowDropUp} from "react-icons/all";

export const AdditionalDataImageAfter = (
    {handleToolDataChange, imageAfterSize, imageAfter}
) => {
    const [isActive, setIsActive] = useState(imageAfter);
    const [isOpen, setIsOpen] = useState(imageAfter);

    useEffect(() => setIsOpen(isActive), [isActive]);

    const toggle = (name, value) => {
        setIsActive(value);
        if (!value) {
            handleToolDataChange("imageAfter", "");
        }
    };

    // const IconDrop = isOpen ? MdArrowDropUp : MdArrowDropDown;
    const IconDrop = false

    const wrapperStyle = {borderBottom: `${isOpen ? 1 : 0}px dashed #000`, marginBottom: "10px"};

    return (
        <div style={wrapperStyle}>
            <div className={"d-flex justify-content-between align-items-center"}>
                <FieldCheckbox options={["תמונה מתחת הכלי"]}
                               name="isActive"
                               controlStyle={{marginBottom: 0}}
                               value={isActive ? ["תמונה מתחת הכלי"] : []}
                               changeHandler={toggle}/>
                {isActive && <IconDrop onClick={() => setIsOpen(!isOpen)} cursor={"pointer"} size={20}/>}
            </div>

            {isActive && isOpen &&
            <Row className="p-3">
                <Col xs={12}>
                    <FilesUploader changeHandler={handleToolDataChange}
                                   name="imageAfter"
                                   multiple={false}
                                   previewWidth={"100%"}
                                   value={imageAfter}/>
                </Col>
                {imageAfter &&
                <Col xs={3}>
                    <FieldText changeHandler={handleToolDataChange}
                               label="גודל תמונה"
                               name="imageAfterSize"
                               value={imageAfterSize}/>
                </Col>}

            </Row>}
        </div>
    )
}