class Coupon {
    constructor(
        id,
        name,
        type,
        sum,
        max_uses,
        is_active,
        adventuretype_id,
        count,
        created_at
    ) {

        this.id = id;
        this.name = name;
        this.type = type;
        this.sum = sum;
        this.max_uses = max_uses;
        this.is_active = is_active;
        this.adventuretype_id = adventuretype_id;
        this.count = count;
        this.created_at = created_at;
    }
};
export default Coupon;