import React, {useState} from "react";
import {Element, HBox} from "react-stylesheet";
import {gutters} from "../../../00_base/variables";
import {Button} from "../../../01_atoms";
import {FieldText, FilesUploader} from "../../../02_molecules";
import CreateToolActions from "../create-tool-actions";
import {CreateGalleryAdvancedData} from "./advanced-numbers/gallery-advanced-numbers";

const THUMBNAIL = "https://storage.googleapis.com/assets.youdoadventures.com/website/default_thumbnail.jpg";

export const CreateToolGallerySection = ({gallery, index, advancedDataType, toggleNumbers,removeSection, handleToolDataChange, handleChange, galleryItems=[], advancedData, setAdvancedData}) => {

    const onChange = (name, value) => handleToolDataChange(name, value, index);
    const [imagesOpen, setImagesOpen] = useState(false);
    const [framesOpen, setFramesOpen] = useState(false);


    const handleImagesChange = (name, value, isValid) => {
        const editableItems = galleryItems[index].editable;

        if (editableItems && name === "images") {
            const emptyIndexes = editableItems.split(',');
            for (let i = 0; i < emptyIndexes.length; i++) {
                value[emptyIndexes[i] - 1] = THUMBNAIL;
            }
        }
        handleChange(name, value, gallery.id);
    };

    return (
        <div className="create-tool-gallery-section" key={gallery.id}>
            <HBox justifyContent={'space-between'} alignItems={'center'}>
                <CreateToolActions index={index}
                                   color={gallery.color}
                                   handleChange={handleChange}
                                   length={galleryItems?.length || 0}/>

                {!advancedData && gallery?.images?.length > 0 &&
                <HBox>
                    <Button value='פעולות בלחיצה על תמונה'
                            pointer
                            onClick={toggleNumbers}/>
                </HBox>}
            </HBox>

            <Element marginTop={gutters.md}>
                <Button onClick={removeSection} style={{marginBottom:"15px"}}>מחק</Button>
                {advancedData &&
                <div>
                    <CreateGalleryAdvancedData items={gallery.images}
                                               sectionIndex={index}
                                               galleryItems={galleryItems}
                                               removeNumbers={toggleNumbers}
                                               advancedDataType={advancedDataType}
                                               onChange={onChange}
                                               gallery={gallery}
                                               handleChange={handleChange}/>
                    <FieldText type='text'
                               name='editable'
                               value={gallery.editable}
                               placeholder="לדוגמא: 3, 4, 8, 13"
                               label='הכניס.י את מספרי התמונות הניתנות לעריכה ע"י היוזר (הפרד עם פסיק בין מספר למספר)'
                               changeHandler={(name, value) => handleChange('editable', value, gallery.id)}/>
                 </div>

                }
                {galleryItems.length > 1 &&
                <FilesUploader maxItems="1"
                               name={'icon'}
                               previewStyle={{width: '100px', height: '100px', marginTop: 0, marginBottom: 0,}}
                               previewWidth={'140px'}
                               previewHeight={'140px'}
                               multiple={false}
                               label={`איקון גלריה ${index + 1}`}
                               triggerValue={'העלה איקון'}
                               index={index}
                               setIsFileLoading={data => console.log('data', data)}
                               changeHandler={handleImagesChange}
                               value={gallery.icon}/>
                }

            </Element>
             <Element marginTop={gutters.md}>
                 <div className="gallery-section-header" onClick={() => setImagesOpen(!imagesOpen)}>
                     תמונות {gallery.images ? `(${gallery.images.length})` : ""}
                 </div>
                 {imagesOpen &&
                <FilesUploader name={'images'}
                               previewStyle={{width: '100px', height: '100px', marginTop: 0, marginBottom: 0,}}
                               previewWidth={'140px'}
                               previewHeight={'140px'}
                               value={gallery.images}
                               triggerValue={'העלה תמונות'}
                               index={index}
                               setIsFileLoading={data => console.log('data', data)}
                               changeHandler={handleImagesChange}/>}
            </Element>

            <Element marginTop={gutters.md}>
                <div className="gallery-section-header" onClick={() => setFramesOpen(!framesOpen)}>
                    מסגרות {gallery.frames && gallery.frames.length > 0 ? `(${gallery.frames.length})` : ""}
                </div>
                {framesOpen &&
                <FilesUploader name={'frames'}
                               multiple={true}
                               previewStyle={{width: '100px', height: '100px', marginTop: 0, marginBottom: 0,}}
                               previewWidth={'140px'}
                               previewHeight={'140px'}
                               index={index}
                               triggerValue={'העלה מסגרות'}
                               setIsFileLoading={data => console.log('data', data)}
                               changeHandler={handleImagesChange}
                               value={gallery.frames}/>}
            </Element>
         </div>
    )
};