import React from "react";
import {FieldText, FieldTextArea} from "../../../02_molecules";

export const RiddleMainDetails = ({handleChange, riddle}) => {
    // const riddle = useSelector(state => state.riddles.singleRiddle);
    const {title, description} = riddle;

    return (
        <div className={"riddle-main-details"}>
            <FieldText label={"כותרת החידה"}
                       name="title"
                       placeholder={"הכניסו את כותרת החידה"}
                       value={title}
                       changeHandler={handleChange}/>

            <FieldTextArea label={"תיאור החידה"}
                           name={"description"}
                           value={description}
                           changeHandler={handleChange}/>


        </div>
    )
};