import {API_URL, fetchData} from "./fetch-data";
import Riddle from "../models/riddle";
import {updateAdventureData} from "./adventure-data-actions";
import {createFrame, getSingleFrame} from "./frames-actions";
import {adventureObject} from "../models/build-models";
import {SET_ADVENTURES} from "./adventure-actions";

const extension = 'riddles';


const riddleModel = (riddleData) => {
    return new Riddle(
        riddleData.id,
        riddleData.title,
        riddleData.riddle_name,
        riddleData.subtitle,
        riddleData.description,
        riddleData.description_short,
        riddleData.templatedata,
        riddleData.thumbnail,
        riddleData.videourl,
        riddleData.user_email,
        riddleData.is_published,
        riddleData.keywords,
        riddleData.accessories,
        riddleData.payment_type,
        riddleData.print_files,
        riddleData.riddlestemplates,
        riddleData.tags,
        riddleData.created_at,
        riddleData.instructions,
        riddleData.is_main,
        riddleData.show_in_store,
        riddleData.editable,
        riddleData.level,
        riddleData.language_id,
        riddleData.author
    )
};


export const CREATE_RIDDLE = "CREATE_RIDDLE";
export const UPDATE_RIDDLE = "UPDATE_RIDDLE";
export const SET_ACTIVE_RIDDLE = "SET_ACTIVE_RIDDLE";
export const SET_RIDDLES_AND_FRAMES = "SET_RIDDLES_AND_FRAMES";
export const SET_SHOW_IN_STORE_RIDDLES = "SET_SHOW_IN_STORE_RIDDLES";

export const getRiddle = (riddleId, cb) => new Promise(async resolve => {
    await fetchData(
        "get",
        `${extension}/${riddleId}`,
        {},
        riddle => {
            if(cb) {cb(riddleModel(riddle))};
            resolve(riddleModel(riddle))
        }
    )
});

export const setAdventureRiddles = (riddlesIds, cb) => {
    return async (dispatch) => {
        Promise.all(riddlesIds.map((id) => getRiddle(id))).then(
            riddles => {
                Promise.all(riddles.map(riddle => riddle.riddlestemplates).flat().map(getSingleFrame)).then(
                    frames => {
                        dispatch({
                            type: SET_RIDDLES_AND_FRAMES,
                            riddles: riddles,
                            frames: frames
                        });
                        if (cb) {
                            cb(riddles, frames)
                        }
                    }
                );
            }
        );
    };
};

export const setActiveRiddle = (riddleId) => {
    return (dispatch) => {
        dispatch({
            type: SET_ACTIVE_RIDDLE,
            riddleId: riddleId
        })
    }
};

export const createRiddle = (riddleData, adventureData, cb) => {
    return async (dispatch, getState) => {
        await fetchData(
            'post',
            extension,
            riddleData,
            success => {
                dispatch({
                    type: CREATE_RIDDLE,
                    riddle: riddleModel(success?.data)
                });
                if (adventureData?.id && success?.data) {
                    dispatch(updateAdventureData({
                        ...adventureData,
                        riddles: adventureData.riddles.concat(success.data.id)
                    }))
                }
                if (cb) {
                    cb(riddleModel(success.data))
                }
            })
    }
};

export const duplicateRiddles = (riddles, frames, cb) => {
    return async (dispatch) => {
        const duplicateFrame = (frame) => new Promise(resolve => {
            dispatch(
                createFrame(
                    {...frame, id: null},
                    null,
                    resolve
                ))
        });
        const duplicateRiddle = (riddle) => new Promise(resolve => {
            Promise.all(riddle.riddlestemplates.map(templateId => {
                return frames.find(frame => frame.id === templateId)
            }).map(duplicateFrame)).then(
                newFrames => {
                    dispatch(
                        createRiddle(
                            {...riddle, riddlestemplates: newFrames},
                            null,
                            newRiddle => resolve(newRiddle))
                    )
                }
            );
        });
        Promise.all(riddles.map(duplicateRiddle)).then(cb);
    };
};

export const updateRiddle = (riddleData, callback) => {
    return async (dispatch, getState) => {
        const riddles = getState().riddles;
        await fetchData(
            'put',
            `${extension}/${riddleData.id}`,
            riddleData,
            response => {
                if (callback) { callback(response.data)}
                dispatch({
                    type: UPDATE_RIDDLE,
                    riddles: riddles.map(riddle => riddleModel(riddleData.id === riddle.id ? riddleData : riddle))
                });
            }
        )
    };
};

export const publishRiddle = (riddleId, callback) => {
    return async (dispatch, getState) => {
        await fetchData(
            'post',
            `publish/riddles/${riddleId}`,
            {},
            response => {
                if (callback) { callback(response.data)}
            }
        )
    }
};

export const unPublishRiddle = (riddleId, callback) => {
    return async (dispatch, getState) => {
        await fetchData(
            'post',
            `unpublish/riddles/${riddleId}`,
            {},
            response => {
                if (callback) { callback(response.data)}
            }
        )
    }
};

export const addFrameToRiddle = (riddleId, frameId, callback) => {
    return (dispatch, getState) => {
        const riddles = getState().riddles;
        const riddle = riddles.find(riddle => riddle.id === riddleId);
        const templates = riddle.riddlestemplates.concat(frameId);
        const riddleData = {...riddle, riddlestemplates: templates};

        dispatch(updateRiddle(riddleData, callback));
    }
};

export const removeFrameFromRiddle = (riddleId, frameId, callback) => {
    return (dispatch, getState) => {
        const riddles = getState().riddles;
        const riddle = riddles.find(riddle => riddle.id === riddleId);
        const templates = riddle.riddlestemplates.filter(template => template !== frameId);
        const riddleData = {...riddle, riddlestemplates: templates};
        dispatch(updateRiddle(riddleData, callback));
    }
};

export const reorderRiddleFrames = (riddleId, updatedFramesOrder) => {
    return (dispatch, getState) => {
        const riddles = getState().riddles;
        const riddle = riddles.find(riddle => riddle.id === riddleId);
        const riddleData = {...riddle, riddlestemplates: updatedFramesOrder};
        dispatch(updateRiddle(riddleData));
    }
};

export const updateRiddleToolData = (checkIfToolValid, riddleId, name, value, index, optionIndex) => {
    return async (dispatch, getState) => {
        const riddles = getState().riddles;
        const riddle = riddles.find(riddle => riddle.id === riddleId);
        const {templatedata} = riddle;
        if (index >= 0) {
            if (optionIndex >= 0) {
                templatedata.items[index][name] = templatedata.items[index][name].map((option, i) => i === optionIndex ? value : option);
            } else {
                if (!!templatedata.items) {
                    templatedata.items[index][name] = value;
                }
            }
        } else {
            templatedata[name] = value;
        }
        const isValid = checkIfToolValid(templatedata);
        await fetchData("put",
            `riddles/${riddleId}`,
            {...riddle, templatedata: {...templatedata, isValid: isValid}},
            (success) => {
                dispatch({
                    type: UPDATE_RIDDLE,
                    riddles: riddles.map(riddle => riddleId === riddle.id ? success.data : riddle)
                })
            })

    }
};

export const setShowInStoreRiddles = (pageIndex=0) => {
    return async (dispatch) => {
        await fetchData(
            "get",
            `riddles-show-in-store?page=${pageIndex}`,
            {},
            mainRiddles => {
                let riddles = [];
                for (const key in mainRiddles) {
                    const riddle = mainRiddles[key];
                    riddles.push(riddleModel(riddle))
                }
                dispatch({
                    type: SET_SHOW_IN_STORE_RIDDLES,
                    show_in_store: riddles
                });
            }
        )
    };
};