import React, {useContext} from 'react';
import PropTypes from "prop-types";
import {Circle, Image} from '../../01_atoms';
import {Link} from 'react-router-dom';
import {ThemeContext} from "../../../providers/theme-provider";

const LOGO = "https://storage.googleapis.com/assets.youdoadventures.com/website/youdo_logo.png";
const LOGO_WHITE = "https://storage.googleapis.com/assets.youdoadventures.com/website/youdo_logo_white.png";

const AppLogo = ({className = "", width, circle, circleSize, style}) => {
    const {mode, toggle} = useContext(ThemeContext);
    const YOUDO_LOGO = mode === "light" ? LOGO : LOGO_WHITE;
    if (circle) {
        return (
            <Circle size={circleSize} style={style} className={className}>
                <a href={'/'}>
                    <Image
                        src={YOUDO_LOGO}
                        width={width || 100}
                        style={{display: 'block'}}/>
                </a>
            </Circle>
        )
    }
    return (
        <div style={{overflow: 'hidden', display: "flex", alignItems: "center", ...style}}
             className={`${className} app-logo`}>
            <Link to={'/'}>
                <img src={YOUDO_LOGO} alt={"youdo logo"} width={width || '100%'}/>
                {/* <Image src={'https://www.tf-interactive.com//wp-content/themes/allenby/images/tf-logo-vector.svg'} width={width || 100} /> */}
            </Link>
        </div>
    )
}

AppLogo.propTypes = {
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    circle: PropTypes.bool
};
export default AppLogo;