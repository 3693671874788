import React from 'react';
import {Element, VBox} from 'react-stylesheet';
import Modal from "../../../03_organisms/modal/modal";
import {gutters} from "../../../00_base/variables";
import Heading from "../../../01_atoms/text/a-heading";
import Paragraph from "../../../01_atoms/text/a-paragraph";

class PaymentFail extends React.Component {

    render() {
        return (
            <Modal hideTrigger={true} isActive={"active"} closeModal={() => window.location.reload()} background={'#000'}>
                    <VBox alignItems={'center'} maxWidth={'30rem'}>
                        <Element marginBottom={gutters.lg}>
                            <Heading tag={'h3'}
                                  textAlign={'center'}>שגיאה</Heading>
                            <Paragraph textAlign={'center'}>התגלתה שגיאה בתהליך תשלום. אנא נסו שנית.</Paragraph>
                        </Element>
                    </VBox>

            </Modal>
        )
    }
}

export default PaymentFail;