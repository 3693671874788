import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import Span from '../../../01_atoms/text/a-span';
import {FieldLabel, fieldStyle} from '../field-style/m-field-style';
import {Wysiwyg} from "../wysiwyg/wysiwyg";

const INPUT_CHANGE = 'INPUT_CHANGE';
const INPUT_BLUR = 'INPUT_BLUR';

const inputReducer = (state, action) => {
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid
            };
        case INPUT_BLUR:
            return {
                ...state,
                blur: true
            };
        default:
            return state
    }
};

const FieldTextArea = (
    {
        name,
        label,
        rows,
        dir,
        placeholder,
        errorMessage,
        changeHandler,
        focusHandler,
        value,
        labelStyle,
        initiallyValid,
        required,
        email,
        min,
        max,
        minLength,
        controlStyle = {},
        toolbarHidden=true,
        wysiwyg = false,
        inputStyle,
        inputStyleWithValue = {backgroundColor:"#fff"},

    }) => {
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: value ? value : '',
        isValid: initiallyValid,
        blur: false
    });

    const handleInputChange = event => {
        const value = event.target.value;
        const emailRegex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = true;
        if (required && value.trim().length === 0) {
            isValid = false;
        }
        if (email && !emailRegex.test(value.toLowerCase())) {
            isValid = false;
        }
        if (min != null && +value < min) {
            isValid = false;
        }
        if (max != null && +value > max) {
            isValid = false;
        }
        if (minLength != null && value.length < minLength) {
            isValid = false;
        }
        dispatchValue(value, isValid);
    };

    const dispatchValue = (value) => {
        dispatch({
            type: INPUT_CHANGE,
            value: value,
            isValid: true
        })
    };

    const handleInputBlur = () => {
        changeHandler(name, inputState.value, inputState.isValid);
        dispatch({type: INPUT_BLUR})
    };

    const hasValue = inputState.value.length > 0;

    return (
        <div style={{...fieldStyle.control, ...controlStyle, ...fieldStyle[dir]}}>
            {label &&  <FieldLabel style={labelStyle}>{label}</FieldLabel>}
            {wysiwyg ?
                <Wysiwyg onChange={dispatchValue}
                         onBlur={handleInputBlur}
                         onFocus={focusHandler}
                         toolbarHidden={toolbarHidden}
                         html={inputState.value}/>
                :
                <textarea placeholder={placeholder}
                          rows={rows}
                          onChange={handleInputChange}
                          onBlur={handleInputBlur}
                          onFocus={focusHandler}
                          value={inputState.value.toString()}
                          style={{...fieldStyle.input, ...inputStyle, ...hasValue && inputStyleWithValue}}/>}
            {!inputState.isValid && <Span value={errorMessage}/>}
        </div>
    )
};

FieldTextArea.defaultProps = {
    value: '',
    rows: 3,
    dir: 'vertical',
    initiallyValid: true
};

FieldTextArea.propTypes = {
    name: PropTypes.string.isRequired,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rows: PropTypes.number,
    dir: PropTypes.oneOf(['horizontal', 'vertical']),
    errorMessage: PropTypes.string,
    changeHandler: PropTypes.func.isRequired,
    initiallyValid: PropTypes.bool,
    required: PropTypes.bool,
    email: PropTypes.bool,
    min: PropTypes.bool,
    max: PropTypes.bool,
    minLength: PropTypes.number,
};

export default FieldTextArea; 