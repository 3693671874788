import React from 'react';
import PropTypes from 'prop-types';
import {colors} from "../../00_base/variables";

const Heading = ({className, onClick, tag, color, textAlign, textDecoration, fontWeight, style, value, children}) => {
    const props = {
        style: {
            color, fontWeight: fontWeight, textAlign, textDecoration, ...style
        },
        className,
        onClick: onClick
    };
    return React.createElement(
        tag,
        props,
        children || value
    )
};

Heading.defaultProps = {
    tag: 'h2',
    color: colors.gray_10,
    value: ''
};

Heading.propTypes = {
    tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    color: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    textDecoration: PropTypes.string
};

export default Heading;