import React from "react";
import {Col, Row} from "react-grid-system";
import {FieldText} from "../../../../02_molecules";

export const CssCreateMargin = ({marginHorizontal, marginVertical, labelStyle, inputStyle, changeHandler}) => {
    const fieldCommonProps = {labelStyle, inputStyle, changeHandler};
    return (
        <Row justify={"start"}>
            <Col xs={6}>
                <FieldText type="number"
                           label="margin X"
                           value={marginHorizontal}
                           name="marginHorizontal"
                           {...fieldCommonProps}/>
            </Col>
            <Col xs={6}>
                <FieldText type="number"
                           value={marginVertical}
                           label="margin Y"
                           name="marginVertical"
                           {...fieldCommonProps}/>
            </Col>
        </Row>
    )
}