import React, {useContext, useState} from "react";
import {Col, Row} from "react-grid-system";
import {FieldText} from "../../../../../02_molecules";
import {ColorPickerBgText} from "../../../../../03_organisms";
import {FrameContext} from "../../single-frame";

export const SingleFrameTopBar = (
    {categorydata}
) => {
    const {handleToolDataChange} = useContext(FrameContext);
    const [isOpen, setIsOpen] = useState(false);
    const {topBarTitleSize = 18, topBarTextColor = "#000", topBarBgColor, topBarTitle} = categorydata;

    const handleColorsChange = (name, value) => {
        if (name === "bgColor") {
            handleToolDataChange("topBarBgColor", value);
        }
        if (name === "textColor") {
            handleToolDataChange("topBarTextColor", value);
        }
    };
    return (
        <div className={"single-frame-box"}>
            <h4 className="single-frame-box-title" onClick={() => setIsOpen(!isOpen)}>בר עליון</h4>

            {isOpen &&
            <Row className={"pt-3 px-3 pb-0"}>
                <Col xs={5}>
                    <FieldText changeHandler={handleToolDataChange}
                               label="גודל טקסט"
                               name="topBarTitleSize"
                               value={topBarTitleSize}/>
                </Col>
                <Col>
                    <ColorPickerBgText handleChange={handleColorsChange}
                                       bgColor={topBarBgColor}
                                       textColor={topBarTextColor}/>
                </Col>
                <Col xs={12}>
                    <FieldText changeHandler={handleToolDataChange}
                               name="topBarTitle"
                               label="הכנס טקסט שיופיע בבר העליון"
                               value={topBarTitle}/>
                </Col>
            </Row>}
        </div>
    )
}