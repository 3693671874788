import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {MdDelete, MdEdit, MdKeyboardArrowDown, MdKeyboardArrowUp} from 'react-icons/md';
import {Element, HBox} from 'react-stylesheet';
import {colors, gutters} from '../../00_base/variables';
import {Button, Circle, Heading, Image, SimpleLoader} from '../../01_atoms';
import {FieldText, ToolTip} from '../../02_molecules';
import {NavigationGroup} from "../navigation-groups/navigation-group";
import '../../../assets/styles/drag-drop.css';

const THUMBNAIL = 'https://storage.googleapis.com/assets.youdoadventures.com/website/default_thumbnail.jpg';

const DragDrop = ({
                      title,
                      name,
                      value,
                      changeHandler,
                      changeFieldHandler,
                      editItemHandler,
                  }) => {
    const dispatch = useDispatch();
    const [allRiddles, setAllRiddles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageIndex, setPageIndex] = useState(0);
    const [groupIndex, setGroupIndex] = useState(0);
    const [error, setError] = useState(null);
    const [activeRiddles, setActiveRiddles] = useState(value);
    const [titleLoading, setTitleLoading] = useState(false);
    const [passwordLoading, setPasswordLoading] = useState(false);

    useEffect(() => {
        if (activeRiddles && activeRiddles[0] && activeRiddles[0].id) {
            changeHandler(name, activeRiddles.map(item => item.id), true);
        } else {
            async function fetch(callback) {

                // const handleSuccess = (response) => {
                //     riddles = riddles.map(item => item === response.id ? response : item);
                //     index = index + 1;
                //
                //     if (index === activeRiddles.length) {
                //         callback(riddles);
                //     }
                // };

                // for await (let riddle of activeRiddles) {
                //     dispatch(riddlesActions.setSingleRiddle(riddle)).then(handleSuccess);
                // }
            }
            fetch(riddles => setActiveRiddles(riddles));
        }
    }, [dispatch, name, changeHandler,  activeRiddles]);

    useEffect(() => {
        const getRiddlesByPageIndex = () => {
            try {
                // NOTICE: riddlesActions.getAllRiddles is an old use. it use to return all the riddles...
                // dispatch(riddlesActions.getAllRiddles(pageIndex)).then(response => {
                //     setAllRiddles(response);
                //     setIsLoading(false);
                // });
                setAllRiddles([]);
            } catch (err) {
                setError(err.message);
                setIsLoading(false);
            }
        };
        setIsLoading(true);
        if (pageIndex === 0 && allRiddles && allRiddles.length > 0) {
            setIsLoading(null);
        } else {
            getRiddlesByPageIndex();
        }
    }, [dispatch, allRiddles, pageIndex]);

    const riddleClickHandler = (riddle) => {
        setActiveRiddles(activeRiddles.concat(riddle));
    };
    const removeRiddleHandler = (id) => {
        setActiveRiddles(activeRiddles.filter(riddle => riddle.id !== id));
    };
    const changeCompleteOrderHandler = (id, dir) => {
        const indexA = activeRiddles.indexOf(activeRiddles.find(item => parseInt(item.id) === id));
        const indexB = dir === 'up' ? indexA - 1 : indexA + 1;
        // console.log('index', index);
        let updated = activeRiddles;
        let temp = updated[indexA];
        updated[indexA] = updated[indexB];
        updated[indexB] = temp;
        setActiveRiddles(updated.map(riddle => {
            return {id: riddle.id, title: riddle.title}
        }));
        setTimeout(() => {
            setActiveRiddles(updated);
            changeHandler(name, updated.map(item => item.id), true);
        });
    };

    if (error) {
        return <div>
            {error}
        </div>
    }

    return (
        <>
            {!!title &&
            <Heading tag='h5'
                     className="header"
                     style={{margin: 0}}>
                {title}
            </Heading>}
            {isLoading ? <SimpleLoader/> :
                <Element className={'container-drag'}>

                    <div className='active-riddles-list'>
                        {activeRiddles && activeRiddles.filter(riddle => riddle.id).map((riddle, index) => {
                            const riddleName = {
                                marginBottom: 0,
                                opacity: titleLoading === riddle.id ? .5 : 1
                            };
                            const passwordField = {
                                opacity: passwordLoading === riddle.id ? .5 : 1,
                                maxWidth: "8rem",
                                marginBottom: 0
                            };
                            return (
                                <div key={riddle.id} className="active-riddle-item">
                                    <HBox alignItems="center">
                                        <p style={{fontSize: "13px"}}>
                                            {index + 1}
                                        </p>
                                        <ToolTip
                                            id={'tip-delete-riddle-icon'}
                                            tip={'מחק חידה'}
                                            offset={{top: 0}}
                                            placeholder={<Button
                                                className={"edit-button"}
                                                onClick={() => {
                                                    removeRiddleHandler(riddle.id)
                                                }}>
                                                <MdDelete size={15} color={'#fff'}/>
                                            </Button>}/>
                                        <ToolTip
                                            id={'tip-edit-riddle-icon'}
                                            tip={'ערוך חידה'}
                                            offset={{top: 0}}
                                            placeholder={<Button
                                                className={"edit-button"}
                                                onClick={() => {
                                                    editItemHandler(riddle.id)
                                                }}>
                                                <MdEdit size={15} color={'#fff'}/>
                                            </Button>}/>

                                        <Circle size={30} style={{marginLeft: gutters.sm, marginRight: gutters.sm}}>
                                            <Image src={riddle.thumbnail || THUMBNAIL}/>

                                        </Circle>

                                    </HBox>

                                    <div>

                                        <FieldText
                                            placeholder={"שם החידה"}
                                            controlStyle={riddleName}
                                            inputStyle={{width: "100%", borderRadius: 0}}
                                            value={riddle.title}
                                            name="title"
                                            changeHandler={(name, value) => {
                                                setTitleLoading(riddle.id);
                                                // dispatch(
                                                //     riddlesActions.updateRiddle(
                                                //         {...riddle, title: value},
                                                //         () => setTitleLoading(false))
                                                // )
                                            }}/>

                                        {/* <Image src={item.thumbnail}/>                 */}
                                    </div>

                                    {changeFieldHandler &&
                                    <div className="label-component">
                                        <FieldText
                                            controlStyle={passwordField}
                                            inputStyle={{borderRadius: 0, backgroundColor: colors.white}}
                                            name="password"
                                            placeholder="סיסמא"
                                            value={riddle.templatedata && riddle.templatedata.password}
                                            changeHandler={(name, value) => {
                                                setPasswordLoading(riddle.id);
                                                // dispatch(
                                                //     riddlesActions.updateRiddleField(
                                                //         riddle.id,
                                                //         name,
                                                //         value,
                                                //         () => setPasswordLoading(false))
                                                // )
                                            }}/>
                                    </div>
                                    }

                                    <HBox>
                                        <Button
                                            style={{
                                                padding: `0 ${gutters.xs}`,
                                                marginRight: `${gutters.xxs}`,
                                                opacity: index <= activeRiddles.length - 2 ? 1 : .4
                                            }}
                                            onClick={() => index <= activeRiddles.length - 2 && changeCompleteOrderHandler(riddle.id, 'down')}>
                                            <MdKeyboardArrowDown size={30}/>
                                        </Button>
                                        <Button
                                            style={{
                                                padding: `0 ${gutters.xs}`,
                                                marginRight: `${gutters.xxs}`,
                                                opacity: index > 0 ? 1 : .3
                                            }}
                                            onClick={() => index > 0 && changeCompleteOrderHandler(riddle.id, 'up')}>
                                            <MdKeyboardArrowUp size={30}/>
                                        </Button>
                                    </HBox>
                                </div>
                            )
                        })}
                    </div>
                    <div className='riddles-list'>

                        {allRiddles.filter(riddle => !activeRiddles.some(activeRiddle => activeRiddle.id === riddle.id)).map(riddle => {

                            const fulldate = new Date(riddle.created_at);
                            const day = fulldate.getDate();
                            const month = fulldate.getMonth();
                            const year = fulldate.getFullYear();
                            const minutes = fulldate.getMinutes();
                            const hours = fulldate.getHours();
                            const time = `${hours}:${minutes}  ${day}/${month + 1}/${year}`;
                            return (
                                <div className='riddles-list-item' key={riddle.id}
                                     onClick={() => riddleClickHandler(riddle)}>
                                    <p>{riddle.title}</p>
                                    <p>{time}</p>
                                </div>
                            )
                        })}
                        <NavigationGroup setGroupIndex={setGroupIndex}
                                         setPageIndex={setPageIndex}
                                         pageIndex={pageIndex}
                                         groupIndex={groupIndex}/>

                    </div>
                </Element>
            }
        </>
    );
}

export default DragDrop;