import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import TailLoader from '../../01_atoms/loaders/tail-loader';
import {Element, HBox} from 'react-stylesheet';
import {Anchor, Button, Circle, Heading, Image, Paragraph, Span} from '../../01_atoms';
import {Grid} from '../../03_organisms';
import {colors, font_size, gutters, zIndex} from '../../00_base/variables';
import {Card} from '../../02_molecules';
import {
    MdCheck,
    MdComputer,
    MdDelete,
    MdDeleteSweep,
    MdEdit,
    MdStore,
    MdViewHeadline,
    MdViewModule
} from 'react-icons/md';
import PropTypes from 'prop-types';
import Feed from '../feed/feed';
import {Col, Row} from 'react-grid-system';
import {NavigationGroup} from "../navigation-groups/navigation-group";
import {fetchData} from "../../../store/actions/fetch-data";

const THUMBNAIL = 'https://storage.googleapis.com/assets.youdoadventures.com/website/default_thumbnail.jpg';

const Gallery = ({
                     apiName,
                     name,
                     title,
                     label,
                     items,
                     filterButton,
                     deletItemHandler,
                     setLoadingBeforeDelete,
                     loadingBeforeDelete,
                     viewMode,
                     thumbWidth = 50,
                     isLoading,
                     hideThumbnail,
                     hideActions,
                     setPageIndex,
                     pageIndex,
                     toggleIsMain,
                     filterComponent,
                 }) => {
    const [checkedItems, setCheckedItems] = useState([]);
    const [removedItems, setremovedItems] = useState([]);
    const [mode, setMode] = useState(viewMode || 'grid');
    const [groupIndex, setGroupIndex] = useState(0);
    const [itemsLength, setItemsLength] = useState(0);

    useEffect(() => {
        if (apiName) {
            fetchData(
                "get",
                `${apiName}/count`,
                "",
                response => {
                    if (response && response.count) {
                        setItemsLength(parseInt(response.count))
                    }
                }
            )
        }
    }, [apiName]);

    const deleteItemsHandler = () => {
        if (window.confirm('Are you sure?')) {
            for (let i = 0; i < checkedItems.length; i++) {
                deletItemHandler(checkedItems[i]);
            }
            setremovedItems(checkedItems);
        }
    };

    const IconMark = ({Icon, backgorund, onClick}) => {
        return <Element onClick={onClick}>
            <Circle backgorund={backgorund || colors.white} size={30}>
                <Icon size={20}/>
            </Circle>
        </Element>
    };

    const renderGridThumbnail = item => {
        return (
            <Element margin={`2px 0`} position={'relative'}>
                <HBox
                    position={'absolute'}
                    width={'100%'}
                    justifyContent={'space-between'}
                    padding={gutters.xs}
                    zIndex={1}>
                    {item.is_main &&
                    <IconMark style onClick={() => toggleIsMain && toggleIsMain(item.id)} Icon={MdComputer}/>}
                    {item.show_in_store && <IconMark Icon={MdStore}/>}
                </HBox>
                {loadingBeforeDelete === item.id &&
                <DeleteRiddleCover>
                    <Heading tag={'h5'} color={colors.white}>{`למחוק את ה${label}?`}</Heading>
                    <HBox>
                        <Button onClick={() => deletItemHandler(item.id)}
                                style={{margin: `0 ${gutters.xs}`}}>כן</Button>
                        <Button onClick={() => setLoadingBeforeDelete(null)}
                                style={{margin: `0 ${gutters.xs}`}}>לא</Button>
                    </HBox>
                </DeleteRiddleCover>}
                <Link to={`/${name}/${item.id}`} style={{position: "relative"}}>
                    <span style={itemIdStyle}>{item.id}</span>
                    <Card cardType={4}
                          height={'260px'}
                          wrapperStyle={{borderRadius: 0}}
                          {...item}
                          titleStyle={{color: colors.white}}
                          bodyStyle={{backgroundColor: '#000',}}
                          description={item.description_short}
                          image={item.thumbnail ? item.thumbnail : THUMBNAIL}
                        // actions={[{
                        //     label: <MdEdit/>,
                        //     href: `/${name}/${item.id}`,
                        //     style: {backgroundColor: 'transparent', cursor: 'pointer'}
                        // },
                        // {
                        //     label: isLoading === item.id ? '...' : <MdDelete />,
                        //     onClick: () => setLoadingBeforeDelete(item.id),
                        //     style: { backgroundColor: 'transparent', cursor: 'pointer' }
                        // }
                    />
                </Link>
            </Element>
        )
    };

    const renderFeedRow = (item, index) => {
        const fulldate = new Date(item.created_at);
        const day = fulldate.getDate();
        const month = fulldate.getMonth();
        const year = fulldate.getFullYear();
        const minutes = fulldate.getMinutes();
        const hours = fulldate.getHours();

        const isChecked = checkedItems.some(checkedItem => checkedItem === item.id);

        return <Element position={'relative'}>
            {loadingBeforeDelete === item.id &&
            <DeleteRiddleCover>
                <Heading tag={'h5'} color={colors.white} style={{margin: 0}}>{`למחוק את ה${label}?`}</Heading>
                <HBox marginTop={gutters.sm}>
                    <Button onClick={() => deletItemHandler(item.id)} style={{margin: `0 ${gutters.xs}`}}>כן</Button>
                    <Button onClick={() => setLoadingBeforeDelete(null)} style={{margin: `0 ${gutters.xs}`}}>לא</Button>
                </HBox>
            </DeleteRiddleCover>}
            <Row key={item.id} gutterWidth={20} style={{
                padding: `${gutters.sm} ${gutters.md}`,
                backgroundColor: index & 1 ? colors.gray_2 : colors.gray_1,
                alignItems: 'center'
            }}>
                <Col xs={1}>
                    {item.checkboxComponent ? item.checkboxComponent :
                        <HBox
                            justifyContent={'center'}
                            alignItems={'center'}
                            width={'36px'}
                            height={'36px'}
                            borderRadius={'5px'}
                            border={`2px solid ${colors.primary}`}
                            style={{cursor: 'pointer'}}
                            position={'relative'}
                            transition={'background .3s'}
                            background={colors[isChecked ? 'primary' : 'white']}>
                            <input
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    opacity: 0,
                                    margin: 0
                                }}
                                type={'checkbox'}
                                checked={isChecked}
                                data-index={index}
                                onChange={(name, value) => {
                                    setCheckedItems(!!isChecked ? checkedItems.filter(checkedItem => checkedItem !== item.id) : checkedItems.concat(item.id))
                                }}/>
                            {isChecked && <MdCheck size={20} color={colors.white}/>}
                        </HBox>
                    }
                </Col>

                <Col xs={!!item.user_email || (!!year && !!month && !!day) || (!!minutes && !!hours) ? 3 : 0}>
                    {item.id && apiName &&
                    <Element marginBottom={"10px"}>
                        <Anchor href={`https://api.youdoadventures.com/${apiName}/${item.id}`}
                                target={"_blank"}
                                value={`#${item.id}`}/>
                    </Element>}
                    {!!item.user_email &&
                    <Heading tag={'h6'} style={{marginTop: 0, marginBottom: gutters.sm}}>
                        {item.user_email}
                    </Heading>
                    }

                    {!!year && !!month && !!day &&
                    <Paragraph style={{margin: 0}}>
                        {`${year} / ${month + 1} / ${day}`} / {`${minutes < 10 ? '0' + minutes : minutes} : ${hours < 10 ? '0' + hours : hours}`}
                    </Paragraph>}


                    <HBox marginTop={gutters.sm}>
                        {item.is_main &&
                        <IconMark Icon={MdComputer}
                                  onClick={() => toggleIsMain(item.id)}/>
                        }
                        {item.show_in_store && <IconMark Icon={MdStore}/>}
                    </HBox>
                </Col>
                <Col xs={hideThumbnail ? 0 : 2}>
                    {item.previewImage ? item.previewImage :
                        <Image width={thumbWidth || "auto"} src={item.thumbnail || THUMBNAIL}/>}

                </Col>
                <Col>
                    <Heading tag={'h5'} style={{margin: item.description_short && 0}}>{item.title}</Heading>
                    {item.description_short && <Paragraph style={{margin: 0}}>{item.description_short}</Paragraph>}
                </Col>
                {item.extraCellComponent &&
                <Col>
                    {item.extraCellComponent}
                </Col>}
                {!hideActions &&
                <Col xs={2}>
                    <HBox>
                        <Button variant={'link'}>
                            <Link to={`/${name}/${item.id}`}>
                                <MdEdit size={25} color={colors.gray_10}/>
                            </Link>
                        </Button>
                        <Button variant={'link'} onClick={() => setLoadingBeforeDelete(item.id)}>
                            <MdDelete size={25} color={colors.gray_10}/>
                        </Button>
                    </HBox>
                </Col>
                }
            </Row>
        </Element>
    };

    return (
        <GalleryWrapper>
            <GalleryHeader>
                <HBox justifyContent={'space-between'} alignItems={'center'}>
                    <Heading tag={'h1'}
                             fontSize={font_size.lg}
                             textAlign={'center'}
                             value={title}/>

                    <GalleryActions>

                        {filterButton && filterButton}

                        {mode === 'table' && checkedItems.length > 0 &&
                        <Element padding={`0 ${gutters.xs}`}>
                            <Button onClick={deleteItemsHandler}>
                                <Paragraph style={{
                                    margin: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize: font_size.sm,
                                    backgroundColor: 'transparent',
                                    color: colors.black
                                }}>
                                    <MdDeleteSweep size={20}/>
                                    {/* {<MdAdd/>} */}
                                </Paragraph>
                            </Button>
                        </Element>
                        }
                        {!viewMode &&
                        <Element padding={`0 ${gutters.xs}`}>
                            <Button onClick={() => setMode(mode === 'grid' ? 'table' : 'grid')}>
                                <Paragraph
                                    style={{
                                        margin: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: font_size.sm,
                                        backgroundColor: 'transparent',
                                        color: colors.black
                                    }}>
                                    {mode === 'table' ? <MdViewModule size={20}/> : <MdViewHeadline size={20}/>}
                                    {/* {<MdAdd/>} */}
                                </Paragraph>
                            </Button>
                        </Element>
                        }
                        <Element padding={`0 ${gutters.xs}`}>
                            <Button>
                                <Link to={`/create/${name}`}
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          fontSize: font_size.sm,
                                          backgroundColor: 'transparent',
                                          color: colors.black
                                      }}>
                                    <Span fontWeight={'bold'}> צור {label}</Span>
                                    {/* {<MdAdd/>} */}
                                </Link>
                            </Button>
                        </Element>
                    </GalleryActions>
                </HBox>

            </GalleryHeader>

            <NavigationGroup id={`navigation-group-${name}`}
                             setGroupIndex={setGroupIndex}
                             itemsLength={itemsLength}
                             setPageIndex={setPageIndex}
                             pageIndex={pageIndex}
                             groupIndex={groupIndex}/>
            {filterComponent && filterComponent}
            {isLoading ?
                <HBox justifyContent={'center'} width={'100%'} paddingTop={gutters.xxxl}>
                    <TailLoader/>
                </HBox>
                :
                <div className={'infinite-scroll'}>
                    <GalleryContent tabContentStyle={{backgroundColor: colors.white, border: '1px solid #888'}}>
                        {mode === 'grid' &&
                        <Grid cellSizes={{xs: 6, sm: 6, lg: 4, xl: 3}}
                              items={items.map(renderGridThumbnail)}
                              gutterWidth={4}/>
                        }
                        {mode === 'table' &&

                        <Feed
                            className={'active'}
                            items={items.filter(item => !removedItems.some(id => id === item.id)).map(renderFeedRow)}
                            spaceBetweenItems={'0'}/>
                        }
                    </GalleryContent>
                </div>
            }
        </GalleryWrapper>
    )
};

const itemIdStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    zIndex: 1,
    color: "#000",
    backgroundColor: "#fff",
    padding: "0 3px"
};  
const GalleryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    // max-height: 100vh;
    overflow: hidden;
    width: 100%;
    &::-webkit-scrollbar {
        display: none !important; 
    }
    &::-webkit-scrollbar-track {
        display: none !important;
    }
    &::-webkit-scrollbar-thumb {
        display: none !important;
    }
`;
const GalleryHeader = styled.div`
    border-bottom: 1px solid ${colors.gray_6};
    padding: 0 ${gutters.md};
`;
const GalleryActions = styled.div`
    display: flex;
    align-items: center;
`;
const GalleryContent = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 ${gutters.xs};
    &::-webkit-scrollbar {
        display: none; 
    }
    &::-webkit-scrollbar-track {
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        display: none;
    }
`;

const DeleteRiddleCover = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: ${zIndex.medium};
`;

Gallery.defaultProps = {}

Gallery.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    deletItemHandler: PropTypes.func,
    setLoadingBeforeDelete: PropTypes.func,
    loadingBeforeDelete: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isLoading: PropTypes.bool
};


export default Gallery;