import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector,} from 'react-redux';
import * as categoriesActions from '../../../../store/actions/categories-actions';
import {useNavigate} from 'react-router-dom'
import {
    Table,
    TableContainer,
    tableHeader,
    TableSearchBar,
    TableTitle,
    TableWrapper,
    Tr
} from "../../../03_organisms";
import {CategoryItem} from "./category-item";
import SVG_API from "../../../../assets/images/svg-icons/svg-api.svg";
import {selectActiveCategory, selectAllCategories} from "../../../../store/selectors";
import {AuthContext} from "../../../../providers/auth-provider";
import {CategoryModal} from "./category-modal";
import "../../../../assets/styles/categories-gallery.scss";

const ScreenCategoriesGallery = () => {
    const navigate = useNavigate()
    const allCategories = useSelector(selectAllCategories);
    const activeCategory=  useSelector(selectActiveCategory)
    // const [activeCategory, setActiveCategory] = useState(null);

    const [filterByName, setFilterByName] = useState("");
    const [markItems, setMarkItems] = useState([]);
    const {isTom} = useContext(AuthContext);

    const [innerMenuActive, setInnerMenuActive] = useState(null);


    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(categoriesActions.setAllCategories());
    }, [dispatch]);


    const createNewCategory = () => {
        dispatch(categoriesActions.createCategory(
            {name: `כלי-${Math.random().toString(36).substr(2, 9)}`},
            (data) => navigate(`/category/${data.id}`))
        );
    };


    // const deleteCategoryHandler = (categoryId) => {
    //     if (window.confirm("Are you sure?")) {
    //         dispatch(categoriesActions.deleteCategory(categoryId));
    //     }
    // };

    const filterBySearch = (str) => {
        setFilterByName(str);
    };

    const deleteMultipleCategories = () => {
        for (let i = 0; i < markItems.length; i++) {
            dispatch(categoriesActions.deleteCategory(markItems[i]));
        }
    };

    const deleteCategoryButton = <span className={"button-delete-items"}
                                       style={{cursor: "pointer", color: "red", textDecoration: "underline"}}
                                       onClick={deleteMultipleCategories}>מחק</span>;
    const open_api = id => window.open(`https://api.youdoadventures.com/categories/${id}`, '_blank');
    const toggleMarkItem = (id, isChecked) => {
        if (isChecked) {
            setMarkItems(markItems.concat(id))
        } else {
            setMarkItems(markItems.filter(item => item !== id))
        }
    };
    const tomsOtherHeaderItems = [
        markItems.length > 0 ? deleteCategoryButton :
            "",
        "id",
    ];

    const innerMenuItems = [
        // {Icon: () => <img src={SVG_CLOSE} alt="remove"/>, label: "מחיקה", onClick: deleteCategoryHandler},
        {Icon: () => <img src={SVG_API} alt="api"/>, label: "API", onClick: open_api},
    ];

    const tableHeaderItems = (isTom ? tomsOtherHeaderItems : []).concat([
        "כותרת",
        "תמונה",
        "שם",
        "תאריך יצירה"
    ]);

    const tableRow = (item, index) => {
        return <CategoryItem item={item}
                             key={item.id}
                             index={index}
                             setInnerMenuActive={() => setInnerMenuActive(innerMenuActive === index ? null : index)}
                             closeInnerMenu={() => setInnerMenuActive(null)}
                             innerMenuItems={innerMenuItems}
                             toggleMarkItem={toggleMarkItem}
                             menuActive={innerMenuActive === index}/>
    };

    return (
        <TableWrapper>

            {activeCategory?.id &&
            <CategoryModal id={activeCategory.id}
                           frame={""}
                           categories={activeCategory.id}
                           categorydata={activeCategory}/>}

            <TableTitle>
                <h1>כלים דיגיטליים</h1>
            </TableTitle>
            <TableContainer fluid>
                <TableSearchBar
                    handleSearchClick={filterBySearch}
                    addLabel={"+ צור כלי חדש"}
                    handleAddClick={createNewCategory}/>

                <Table cellSpacing="0" cellPadding="0">
                    <thead><Tr>{tableHeaderItems.map(tableHeader)}</Tr></thead>
                    <tbody>{allCategories.map(tableRow)}</tbody>
                </Table>

            </TableContainer>
        </TableWrapper>
    )
};

export default ScreenCategoriesGallery;
//
//
//
// <div style={{width: '100%'}}>
//     <Gallery
//         loadingBeforeDelete={loadingBeforeDelete}
//         setLoadingBeforeDelete={setLoadingBeforeDelete}
//         getNextPage={() => setPageIndex(pageIndex + 1)}
//         deletItemHandler={deletCategoryHandler}
//         isLoading={isLoading && allCategories.length < 1}
//         title={'מאגר הקטגוריות'}
//         label={'קטגוריה'}
//         pluralLabel={"קטגוריות"}
//         thumbWidth={50}
//         apiName='categories'
//         setPageIndex={setPageIndex}
//         name='category'
//         items={allCategories.map(cat => { return { ...cat, thumbnail: cat.icon } })} />
// </div>