import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {colors, font_family, font_size} from "../../../00_base/variables";
import styled from "styled-components";
import Heading from "../../../01_atoms/text/a-heading";
import {useSelector} from "react-redux";
import {selectCurrentAdventure, selectCurrentAdventureType} from "../../../../store/selectors";
import {LanguageContext, Translation} from "../../../../languages";
import {DICT} from "../../../../languages/dictionary-names";

export const PaymentSuccessMessage = () => {
    const adventure = useSelector(selectCurrentAdventure);
    const adventureType = useSelector(selectCurrentAdventureType);
    const {userLanguage} = useContext(LanguageContext);
    const isEmployeeBirthday = adventureType && (adventureType.id === 324 || adventureType.id === 330 || adventureType.id === 332);
    return (
        <PasscodeWrapper className="adventure-paid-modal">
            <Heading margin={0} tag="h3" color={colors.white}>
                <Translation id={DICT.ADVENTURE_PROCESS_FINISH}/>
            </Heading>

            {!isEmployeeBirthday ?
                <>
                    {adventureType?.id !== 31 &&
                    <Heading tag="h5"
                             color={colors.white}
                             value=" אנו ניצור אתכם קשר כשהקופסה תהיה מוכנה לתיאום משלוח או איסוף"/>
                    }
                    <p>סיסמת ההרפתקה:</p>
                    <p className="adventure-paid-passcode mb-2 mt-2" style={passcodeStyle}>{adventure.passcode}</p>
                    <p>הסיסמא ושאר פרטי ההרפתקה מחכים לכם בתיבת המייל</p>
                </>
                :
                <p><Translation id={DICT.ADVENTURE_DETAILS_WAITING_IN_EMAIL}/></p>
            }
            <p style={{fontSize: font_size.xs}}><Translation id={DICT.NO_EMAIL_LOOK_AT_PROMOTIONS}/></p>
            <p style={{fontSize: font_size.xs}}>
                <Translation id={DICT.HAVE_ANY_PROBLEM}/>?
                <a href={`/${userLanguage}/contact-us`} target="_blank" rel="noopener noreferrer">
                    <Translation id={DICT.CONTACT_US}/>
                </a>
            </p>

            {adventureType &&
            <Link to={`/${userLanguage}/adventure/${adventureType.id}`}>ליצירת הרפתקה נוספת</Link>}
        </PasscodeWrapper>
    )
};


const passcodeStyle = {

    color: colors.white,
    lineHeight: "initial",
    fontFamily: font_family.medium
}

const PasscodeWrapper = styled.div`
    text-align: center;
    background: ${colors.gray_6};
    color:${colors.white};
    padding: 20px;
    border-radius: 10px;
    border: 2px solid ${colors.white};
    border-radius: 3rem;
    margin-top: 20px
    h5 {
        margin: 0px 0 10px;
    }
    p {
        color: #fff;
        font-size: ${font_size.sm};
    }
    a {
        text-decoration: underline;
        color:#fff
    }
    .adventure-paid-url {
        font-size: 20px;
        @media(min-width:780px) {
            font-size: 22px;    
        }
    }
    .adventure-paid-passcode {
        font-size:${font_size.xxl};
        @media(min-width:780px) {
            font-size: 110px;            
        }
    }
}`;
