import {Button} from "../../../01_atoms";
import React from "react";

export const ToolActionButtons = (
    {
        actionButtons,
        setActionsButtonsActive,
        isFrameFinish,
        actionsButtonsActive,
        setFinalButtonChosen,
        actionButtonBackgroundColor,
        actionButtonColor,
        actionButtonSize
    }) => {
    return (
        <div className="screen-tool-actions-buttons">

            <div className="screen-tool-buttons-trigger">
                <Button onClick={() => setActionsButtonsActive(!actionsButtonsActive)}>המשך</Button>
            </div>

            {((actionButtons && actionsButtonsActive) || (actionButtons && isFrameFinish)) &&
            <div className="screen-tool-buttons">
                {actionButtons.map((button, index) => {
                    return <Button key={index}
                                   value={button}
                                   onClick={() => setFinalButtonChosen(index + 1)}
                                   style={{
                                       backgroundColor: actionButtonBackgroundColor,
                                       color: actionButtonColor,
                                       fontSize: actionButtonSize,
                                   }}/>
                })}
            </div>}
        </div>
    )
}
