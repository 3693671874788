import React from "react";
import ToolDefiner from "../../tools/definer";
import ToolGallery from "../../tools/gallery";
import ToolImage from "../../tools/image";
import ToolVideo from "../../tools/viedo";
import ToolTrivia from "../../tools/trivia";
import ToolTruthOrLie from "../../tools/lie-truth/tool-truth-lie";
import ToolTifzoret from "../../tools/tifzoret";
import ToolTranslator from "../../tools/translator";
import ToolTreasure from "../../tools/treasure";
import ToolPuzzle from "../../tools/puzzle";
import ToolContactList from "../../tools/contact-list";
import {ToolSlider} from "../../tools/slider/tool-slider";
import {ToolContent} from "../../tools/content/tool-content";
import {ToolCoder} from "../../tools/coder/tool-coder";
import {ToolCuttingWires} from "../../tools/cutting-wires/tool-cutting-wires";
import {ToolAudio} from "../../tools/audio/tool-audio";
import {ToolAudioList} from "../../tools/audio-list/tool-audio-list";
import {ToolMaze} from "../../tools/maze/tool-maze";
import {GoogleMaps} from "../../tools/googleMaps/google-maps";
import {ToolRotaries} from "../../tools/rotaries/tool-rotaries";
import {ToolChooseItem} from "../../tools/choose-item/tool-choose-item";
import {ToolGalleryImages} from "../../tools/gallery-images/tool-gallery-images";
import {ToolPeekLock} from "../../tools/peek-lock/tool/tool-peek-lock";
import {ToolSwitches} from "../../tools/switches/tool-switches";


export const tools = [
    { id: 7, component: props => <ToolImage {...props}/> },
    { id: 15, component: props => <ToolTranslator {...props}/> },
    { id: 38, component: props => <ToolPuzzle {...props}/> },
    { id: 46, component: props => <ToolTifzoret {...props}/> },
    { id: 47, component: props => <ToolContactList {...props}/> },
    { id: 48, component: props => <ToolDefiner {...props}/> },
    { id: 49, component: props => <ToolTrivia {...props}/> },
    { id: 56, component: props => <ToolGallery {...props}/> },
    { id: 57, component: props => <ToolVideo {...props}/> },
    { id: 60, component: props => <ToolTreasure {...props}/> },
    { id: 61, component: props => <ToolSwitches {...props}/> },
    { id: 62, component: props => <ToolContent {...props}/> },
    { id: 63, component: props => <ToolCoder {...props}/> },
    { id: 64, component: props => <ToolRotaries {...props}/> },
    { id: 65, component: props => <ToolSlider {...props}/> },
    { id: 66, component: props => <ToolTruthOrLie {...props}/> },
    { id:73, component: props => <ToolCuttingWires {...props}/> },
    { id:74, component: props => <ToolMaze {...props}/> },
    { id:75, component: props => <ToolAudio {...props}/> },
    { id:76, component: props => <ToolAudioList {...props}/> },
    { id:77, component: props => <GoogleMaps {...props}/> },
    { id:78, component: props => <ToolChooseItem {...props}/> },
    { id:79, component: props => <ToolTruthOrLie {...props}/> },
    { id:80, component: props => <ToolGalleryImages {...props}/> },
    { id:81, component: props => <ToolPeekLock {...props}/> },
    { id: 296, component: props => <ToolTrivia {...props}/> },
];
