import React from "react";
import {BackgroundImage, Image} from "../../../../01_atoms";
import {useSelector} from "react-redux";
import {selectCurrentAdventureType} from "../../../../../store/selectors";

export const AdventureHeader = () => {
    const adventureType = useSelector(selectCurrentAdventureType);
    const {logo_url, site_image_mobile, site_image_tablet, site_image_desktop} = adventureType;

    return (
        <div className="single-adventure-container">
            <BackgroundImage className="single-adventure-container-image"
                             position="center center"
                             mobileUrl={site_image_mobile}
                             tabletUrl={site_image_tablet}
                             dektopUrl={site_image_desktop}
                             url={site_image_desktop}>
                {logo_url && <Image src={logo_url}/>}
            </BackgroundImage>
        </div>
    )
} 