import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector, useStore} from "react-redux";
import {Container} from "react-grid-system";
import {AdventureTypeSequence} from "../adventureType/adventure-type-sequence/adventure-type-sequence";
import {colors} from "../../00_base/variables";
import {Button, Heading, SimpleLoader} from "../../01_atoms";
import {FieldSelect, FieldText, FilesUploader} from "../../02_molecules";

import {
    createAdventureType,
    setAllAdventureTypes,
    setSingleAdventureType
} from "../../../store/actions/adventure-type-actions";
import {
    duplicateAdventureData,
    setSingleAdventureData,
    updateAdventureData
} from "../../../store/actions/adventure-data-actions";
import {selectAllAdventureTypes, selectCurrentAdventureType} from "../../../store/selectors";
import {createAdventure} from "../../../store/actions/adventure-actions";

export const NewAdventure = () => {
    const defaultFields = {
        title: "הזמנה חדשה",
        adventuretype: null,
        adventuredata_id: null,
        thumbnail: ""
    }
    const allAdventureTypes = useSelector(selectAllAdventureTypes);
    const adventureType = useSelector(selectCurrentAdventureType);

    const [isLoading, setIsLoading] = useState(true);
    const [isAdventureTypeLoading,     setIsAdventureTypeLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [adventureTypeLoading, setAdventureTypeLoading] = useState(false);
    const [newAdventure, setNewAdventure] = useState(defaultFields);
    const [currentAdventureType, setCurrentAdventureType] = useState({});

    const dispatch = useDispatch();
    const store = useStore();

    const handleChange = useCallback((name, value) => {
        setNewAdventure({...newAdventure, [name]: value})
    }, [newAdventure]);

    useEffect(() => {
        dispatch(setAllAdventureTypes()).then(() => setIsLoading(false));
    }, [dispatch]);

    useEffect(() => {
        if (currentAdventureType && currentAdventureType.id) {
            setAdventureTypeLoading(true);
            setNewAdventure({...newAdventure, adventureType: currentAdventureType.id})
            dispatch(setSingleAdventureType(currentAdventureType.id, ));
        }
    }, [dispatch, currentAdventureType]);

    useEffect(() => {
        if (adventureType.id === 31) {
            setAdventureTypeLoading(false);
        } else {
        if (adventureType.adventuredata_id) {
            // TODO: Duplicate adventureData and its riddles and then set the new adventureDataId as the singleAdventureData

                dispatch(duplicateAdventureData(
                    adventureType.adventuredata_id,
                    newAdventureDataId => {
                        setNewAdventure({...newAdventure, adventuredata_id: newAdventureDataId});
                        dispatch(setSingleAdventureData(newAdventureDataId)).then(() => setAdventureTypeLoading(false));
                    }
                ))
            }

        }
    }, [dispatch, adventureType]);

  
    const handleSubmit = () => {

        if (newAdventure.adventureType && !adventureTypeLoading) {
            setSubmitLoading(true);
            const json_data = {
                welcome_title_size: adventureType.welcome_title_size,
                bg_color: adventureType.bg_color,
                text_color: adventureType.text_color,
                continue_bg_color: adventureType.continue_bg_color,
                continue_text_color: adventureType.continue_text_color,
                logo_type: adventureType.logo_type,
                welcome_pre_title:adventureType.welcome_pre_title,
                entrance_cta:adventureType.entrance_cta,
            }
            dispatch(createAdventure({...newAdventure}, (res) => {
                window.location.href = `/he/admin/reservation/${res.id}`;
                setSubmitLoading(false);
            }))
        } else {
            alert("יש לבחור סוג הרפתקה")
        }
    };

    const handleAddRiddle = riddle => {
        const singleAdventureData = store.getState().adventureDatas.singleAdventureData;
        dispatch(updateAdventureData({
            ...singleAdventureData,
            riddles: singleAdventureData.riddles.concat(riddle.id)
        }))
    };

    const handleNewAdventureType = (value) => {
        dispatch(createAdventureType(
            {name: value},
            response => {
                handleChange("adventureType", response.id);
                setCurrentAdventureType({id: response.id, value: response.id, label: response.name});
            }));
    };
    const loadingContainer = {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    };
    if (isLoading) {
        return <div style={loadingContainer} className="single-adventure"><SimpleLoader/></div>
    }

    return (
        <div className="single-adventure">
            <div className={"single-adventure-header"}>
                <Heading tag="h1" textAlign="center" value="הזמנה חדשה"/>
            </div>
            <Container style={{minHeight: "100vh"}}>
                <div className="single-adventure-form">
                    <FieldText changeHandler={handleChange}
                               value={newAdventure.title}
                               label="כותרת ההזמנה"
                               placeholder="הכניסו את כותרת ההזמנה"
                               name="title"/>

                    <FilesUploader name="thumbnail"
                                   changeHandler={handleChange}
                                   multiple={false}
                                   label={"לוגו ההרפתקה"}
                                   triggerValue={"לוגו ההרפתקה"}
                                   controlStyle={{width: "200px", marginBottom: "20px"}}
                                   previewHeight={200}
                                   previewWidth={200}/>

                    <FieldSelect label="סוג הרפתקה"
                                 name={"adventureType"}
                                 options={allAdventureTypes.map(item => {
                                     return {label: item.name, value: item.id, id: item.id}
                                 })}
                                 value={currentAdventureType}
                                 changeHandler={(name, value) => {
                                     setIsAdventureTypeLoading(true);
                                     setCurrentAdventureType(value);
                                 }}
                                 customControlStyle={customControlStyle}
                                 inputStyle={inputStyle}
                                 createOptionHandler={handleNewAdventureType}
                    />

                    {adventureTypeLoading && <SimpleLoader/>}

                    {newAdventure.adventuredata_id &&
                    <AdventureTypeSequence adventureDataId={newAdventure.adventuredata_id}
                                           handleAddRiddle={handleAddRiddle}
                                           handleChange={handleChange}
                    />
                    }
                </div>
                <Button onClick={handleSubmit} className={"block"}
                        value={submitLoading ? <SimpleLoader/> : "צור הזמנה"}/>
            </Container>

        </div>
    )
};

const customControlStyle = {
    border: `1px solid ${colors.gray_2}`,
    cursor: "pointer",
    borderRadius: "4px",
    display: "flex",
    paddingRight: "10px",
    flexDirection: "row-reverse",
    justifyContent: "center",
    height: "36px"
};

const inputStyle = {
    height: "100%",
    border: "1px solid red"
};