import React, {useContext} from 'react';
import {Col, Row} from 'react-grid-system';
import {font_family, gutters} from '../../../../00_base/variables';
import {IconClock, IconPlayers, IconPuzzle} from '../../../../02_molecules/icons';
import {LanguageContext, Translation} from "../../../../../languages";
import {useSelector} from "react-redux";
import {selectCurrentAdventureType} from "../../../../../store/selectors";
import "../../../../../assets/styles/adventure-details.scss";
import {DICT} from "../../../../../languages/dictionary-names";

export const AdventureDetails = ({numOfRiddles, hideList, modal, scrollToForm}) => {
    const {gameTime = 45, name, description, base_price, players = 8} = useSelector(selectCurrentAdventureType);
    const {userLanguage} = useContext(LanguageContext);
    const english = userLanguage === "en";

    const price = parseInt(base_price).toFixed(0);

    const items = [
        {label: `${players || 8} ${english ? "players" : "שחקנים"}`, icon: IconPlayers},
        {label: `${numOfRiddles || 6} ${english ? "riddles" : "חידות"}`, icon: IconPuzzle},
        {label: `${gameTime} ${english ? "minutes" : "דקות"}`, icon: IconClock}
    ];

    const renderItem = (item, index) => {
        const Icon = item.icon;
        const label = item.label;
        return (
            <div key={index} className="single-adventure-detail">
                {Icon && <div className="single-adventure-details-icon">
                    <Icon/>
                </div>}
                <span className={"color-black"}>{label}</span>
            </div>
        )
    };


    const hasPrice = price > 0;
    return (
        <Row className={"single-adventure-details"} style={{alignItems: 'flex-start', padding: `${gutters.xxxl} 0`}}>

            <Col md={8} lg={9}>
                <div className='d-flex align-items-end flex-wrap'>
                    <h1 className='single-adventure-title'>{name}</h1>
                    {!hideList &&
                    <div className='single-adventure-details-item mt-5 '>
                        {items.map(renderItem)}
                    </div>
                    }
                </div>
                <p  className="color-black single-adventure-description" dangerouslySetInnerHTML={{__html:description}}/>
            </Col>

            <Col md={4} lg={3} className={"d-flex flex-column align-items-center"}>

                <p className={`single-adventure-price color-${hasPrice ? "black" : "action"}`} style={{fontFamily: font_family.medium}}>
                    {hasPrice ? price : <Translation id={DICT.FREE}/>}
                    {hasPrice &&
                    <span style={{fontFamily: font_family.medium}}>
                        {english ? "nis" : 'ש"ח'}
                    </span>}
                </p>
                {modal}
            </Col>
        </Row>
    )
};