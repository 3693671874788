import React, {useState} from "react";
import classNames from "classnames";
import CreateToolClues from "../../../tools/clues/create-tool-clues";

export const SingleFrameClues = ({clues, id, handleChange}) => {
    const [isOpen, setIsOpen] = useState(clues?.length > 0);
    return (
        <div className={"single-frame-box"}>
            <h4 className="single-frame-box-title" onClick={() => setIsOpen(!isOpen)}>
                {`רמזים ${clues?.length > 0 ? `(${clues.length})` : ""}`}
            </h4>
            <div className={classNames("single-frame-box-content additional-data-body", {active: isOpen})}>
                <CreateToolClues toolClues={clues}
                                 isOpen={true}
                                 frameId={id}
                                 changeHandler={handleChange}/>
            </div>
        </div>
    )
};