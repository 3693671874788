import {couponObject} from "../models/build-models";
import {API_URL} from "./fetch-data";

const api = API_URL;
const get_extension = 'coupons_with_count';
const post_extension = 'coupons';
const get_url = `${api}/${get_extension}`;
const post_url = `${api}/${post_extension}`;


const token = localStorage.getItem("token");


export const SET_COUPONS = 'SET_COUPONS';
export const SET_SINGLE_COUPON = 'SET_SINGLE_COUPON';
export const CREATE_COUPON = 'CREATE_COUPON';
export const UPDATE_COUPON = 'UPDATE_COUPON';
export const DELETE_COUPON = 'DELETE_COUPON';

export const getAllCoupons = (pageIndex) => {
    return async(dispatch) => {
        try {
            const response = await fetch(`${get_url}?page=${pageIndex}`, {
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "access-Token": token
                }
            });
            if (!response.ok) {
                // throw new Error('Something went wrong');
                console.log('Something went wrong');
            }
            const couponsData = await response.json();

            let allCoupons = [];
            for (const key in couponsData) {
                const couponData = couponsData[key];
                allCoupons.push(couponObject(couponData))
            }

            dispatch({
                type: SET_COUPONS,
                allCoupons: allCoupons
            });

            return allCoupons.filter(coupon => parseInt(coupon.count) < coupon.max_uses)
        } catch (error) {
            throw error
        }
    }
};

export const getSingleCoupon = couponId => {
    return async(dispatch, getState) => {
        try {
            const response = await fetch(`${get_url}/${couponId}`, {
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "access-Token": token
                }
            });
            if (!response.ok) {
                throw new Error('Something went wrong');
            }

            const couponData = await response.json();
            return couponObject(couponData)
        } catch (error) {
            throw error
        }
    }
};

export const createCoupon = (couponData, callback = success => console.log(success)) => {
    return async(dispatch, getState) => {
        fetch(post_url, {
            method: 'POST',
            body: JSON.stringify(couponData),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "access-Token": token
            }
        }).then(response => response.ok ? response.json() : alert('error')).then(
            success => {
                dispatch({
                    // type: CREATE_COUPON,
                    ...couponObject(success.data)
                });
                callback(success.data);
            }
        )
    }
};

export const updateCoupon = (couponData, callback = success => console.log(success)) => {
    return async(dispatch, getState) => {
        fetch(`${api}/toggle_coupon/${couponData.id}`, {
            method: 'POST',
            body: JSON.stringify({ is_active: couponData.is_active }),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "access-Token": token
            }
        }).then(response => response.ok ? response.json() : alert('error')).then(
            success => {
                dispatch({
                    type: UPDATE_COUPON,
                    ...couponObject(success.data)
                });
                console.log('success', success);
                callback(success.data);
            }
        );
    };
};

export const deleteCoupon = couponId => {
    return async(dispatch, getState) => {
        fetch(`${get_url}/${couponId}`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "access-Token": token
            }
        }).then(
            response => response.json()
        ).then(
            success => {
                dispatch({ type: DELETE_COUPON, id: couponId })
                return success
            }
        )
    }
};