import React from "react";
import {colors} from "../../../00_base/variables";
import {Heading, Button, Image} from "../../../01_atoms";
import {Modal} from "../../../03_organisms";

export const RiddleModal = ({riddle, closeModal}) => {
    const printImage = (source) => {
        let Pagelink = "about:blank";
        let pwa = window.open(Pagelink, "_new");
        pwa.document.open();
        pwa.document.write(imageToPrint(source));
        pwa.document.close();
    };
    const imageToPrint = (source) => {
        return "<html><head><script>function step1(){\n" +
            "setTimeout('step2()', 10);}\n" +
            "function step2(){window.print();window.close()}\n" +
            "</script></head><body onload='step1()'>\n" +
            "<img style='max-width: 100%' src='" + source + "' /></body></html>";
    };

    return (
        <Modal isActive={'active'}
               hideTrigger={true}
               contentStyle={{width: '1000px', padding: "20px", maxHeight: '100%'}}
               closeModal={closeModal} closePreActions={closeModal}>
            <Heading tag={'h2'} className={'responsive-text my-3'}> {riddle.title} </Heading>
            <Button style={{
                width: '100%',
                backgroundColor: colors.action,
                color: colors.text,
                marginBottom: "20px"
            }}
                    onClick={() => printImage(riddle.thumbnail)}>להדפסה</Button>
            <Image src={riddle.thumbnail} style={{width: '100%', maxWidth: '800px'}}/>
            <Button
                style={{
                    width: '100%',
                    backgroundColor: colors.action,
                    color: colors.text,
                    marginTop: "20px"
                }}
                onClick={() => printImage(riddle.thumbnail)}>להדפסה</Button>
        </Modal>
    )
};
