import {fetchData} from "./fetch-data";
import {
    adventureObject,
    blogObject,
} from "../models/build-models";

const extension = 'blog';


export const SET_ALL_BLOGS = 'SET_BLOGS';
export const SET_ACTIVE_BLOG = 'SET_ACTIVE_BLOG';
export const CREATE_BLOG = 'CREATE_BLOG';
export const UPDATE_BLOG = 'UPDATE_BLOG';
export const DELETE_BLOG = 'DELETE_BLOG';

export const getBlog = (blogId, onSuccess, onError) => {
    fetchData(
        "get",
        `${extension}/${blogId}`,
        {},
        onSuccess,
        onError
    )
};


export const setAllBlogs = (lang, pageIndex = 0) => {
    return async (dispatch, getState) => {
        await fetchData(
            'GET',
            `${extension}/?page=${pageIndex}`,
            {},
            response => {
                let allBlogs = [];
                for (const key in response) {
                    const blog = response[key];
                    allBlogs.push(blogObject(blog))
                }
                dispatch({
                    type: SET_ALL_BLOGS,
                    allBlogs: allBlogs
                });
            }
        )
    }
};


export const setSingleBlog = (blogId, cb) => {
    return async (dispatch) => {
        await fetchData(
            "GET",
            `${extension}/${blogId}`,
            "",
            response => {
                dispatch({
                    type: SET_ACTIVE_BLOG,
                    blog: blogObject(response)
                });
                if (cb) {
                    cb(response)
                }
            }
        )
    }
};

export const createBlog = (blog, callback, errorCallback) => {
    return async (dispatch) => {
        await fetchData(
            'POST',
            extension,
            blog,
            response => {
                if (callback) {
                    callback(response)
                }
                dispatch({type: CREATE_BLOG, blog: blogObject(response.blog)});
            },
            err => {
                if (errorCallback) {
                    errorCallback(err)
                }
            }
        )
    }
};

export const updateBlog = (blog, callback, errorCallback) => {
    return async (dispatch) => {
        await fetchData(
            "PUT",
            `${extension}/${blog.id}`,
            blog,
            success => {
                if (callback) {
                    callback(success.data)
                }
                dispatch({type: UPDATE_BLOG, ...success.data});
            },
            error => {
                if (errorCallback) {
                    errorCallback(error)
                }
            }
        )
    };
};
// export const updateBlog = (blog, onSuccess, onError) => {
//     fetchData(
//         "PUT",
//         `${extension}/${blog.id}`,
//         blog,
//         onSuccess,
//         onError,
//     )
// };

export const deleteBlog = (blogId, callback, errorCallback) => {
    return async (dispatch) => {
        await fetchData(
            "DELETE",
            `${extension}/${blogId}`,
            {},
            res => {
                dispatch({type: DELETE_BLOG, id: blogId});
                if (callback) {
                    callback(res)
                }
                return res
            },
            err => {
                if (errorCallback) {
                    errorCallback(err)
                }
            }
        )
    }
};