export const DICTIONARY = {
    registerTitle: {
        he: "הצטרפו לקהילת ההרפתקנים שלנו",
        en: "Join our adventurous community"
    },
    iHaveExistAccount: {
        he:"יש לי משתמש קיים",
        en: "I have an existing user"
    },
    firstName: {
        he: "שם פרטי",
        en: "Private name"
    },
    firstNameWarning: {
        he: "שם פרטי לא תקין",
        en: "Private name is not valid"
    },
    lastName: {
        he: "שם משפחה",
        en: "Last name"
    },
    lastNameWarning: {
        he: "שם משפחה לא תקין",
        en: "Last name is not valid"
    },
    phoneNumber: {
        he: "טלפון",
        en: "Phone number"
    },
    phoneNumberWarning: {
        he: "טלפון לא תקין",
        en: "Phone number is not valid"
    },
    emailAddress: {
        he: 'כתובת דוא"ל',
        en: "Email address"
    },
    emailAddressWarning: {
        he: 'דוא"ל לא תקין',
        en: "Email address is not valid"
    },
    password: {
        he: "סיסמא",
        en: "password"
    },
    passwordWarning: {
        he: "יש למלא סיסמא",
        en: "Please insert a password"
    },
    passwordRepeat: {
        he: "סיסמא בשנית",
        en: "Repeat password"
    },
    passwordRepeatWarning: {
        he: "סיסמא לא תואמת",
        en: "Password is not match"
    },
    approveTerms: {
        he: "אני מאשר את תקנון ההרשמה",
        en: "I approve the registration policy"
    },
    approveTermsWarning: {
        he: "יש לאשר את התקנון",
        en: "Please approve registration policy"
    },
    hearMore: {
        he: "אני מעוניינ.ת לשמוע מידי פעם על הרפתקאות נוספות ועוד דברים מגניבים",
        en: "I'm interested in hearing from time to time about more adventures and other cool stuff"
    },
    register: {
        he: "הירשם",
        en: "Register"
    },
    login: {
        he: "התחברות למערכת",
        en:"Login"
    },
    forgotPassword: {
        he:"איפוס סיסמא",
        en:"Reset your password"
    },
    notAccount: {
        he: "אין לך משתמש קיים?",
        en:"Don't have an existing user?"
    },
    toRegister: {
        he:"להרשמה",
        en:"Go to register",
    },
    emailPasswordNotMatch: {
        he: "כתובת המייל אינה תואמת את הסיסמא",
        en:"The email address does not match the password"
    },
    or: {
        he:"או",
        en:"or"
    }
};