import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import {AuthContext} from "../../../providers/auth-provider";
import _ from "lodash";
import {colors} from "../../00_base/variables";
import {Button} from "../../01_atoms";
import {FieldTextArea} from "../../02_molecules";
import {Comment} from "./comment";
import "../../../assets/styles/admin-conversation.scss";

export const AdminConversation = ({comments = [], handleChange}) => {
    const [renderedComments, setRenderedComments] = useState(comments);
    const [newComment, setNewComment] = useState("");
    const [newCommentFocus, setNewCommentFocus] = useState(false);

    const {userData} = useContext(AuthContext);

    const handleDeleteNewComment = () => {
        setNewCommentFocus(false);
        setNewComment("");
    };
    useEffect(() => handleChange(renderedComments), [renderedComments]);

    const submitHandler = () => {
        const data = {
            id: _.random(1, 9999),
            created_at: moment().format('MMMM Do YYYY, h:mm:ss a'),
            user_id: userData.id,
            full_name: userData.name + " " + userData.lastName,
            content: newComment
        };
        const updatedComments = renderedComments.concat(data);
        setNewComment("");
        setNewCommentFocus(false);
        setRenderedComments(updatedComments)
    };

    const updateHandler = (comment) => {
        const updatedComments = renderedComments.map((item) => item.id === comment.id ? comment : item);
        setRenderedComments(updatedComments)
    };

    const deleteHandler = (id) => {
        if (window.confirm("למחוק את התגובה?")) {
            const updatedComments = renderedComments.filter((item) => item.id !== id);
            setRenderedComments(updatedComments);
        }
    };

    const renderComment = item => <Comment comment={item}
                                           updateHandler={updateHandler}
                                           deleteHandler={() => deleteHandler(item.id)}
                                           key={item.id}/>;

    return (
        <div className="admin-conversation">
            {newCommentFocus ?
                <FieldTextArea changeHandler={(name, value) => setNewComment(value)}
                               controlStyle={{marginBottom: 0}}
                               value={newComment}
                               wysiwyg={true}
                               toolbarHidden={false}
                               name="new-comment"/>
                :
                <div onClick={() => setNewCommentFocus(true)}
                     className="admin-conversation-placeholder d-flex align-items-center px-3 color-gray-400">
                    הכנס תגובה...
                </div>
            }
            {newCommentFocus &&
            <div className="d-flex justify-content-end py-3">
                <Button className="ml-3"
                        radius="5px"
                        style={{backgroundColor: "transparent", color: colors.gray_6}}
                        value="מחק"
                        onClick={handleDeleteNewComment}/>
                <Button radius="5px"
                        onClick={submitHandler}
                        value="שמור"/>
            </div>
            }
            {renderedComments.length > 0 && _.sortBy(renderedComments, (dateObj) => {
                return dateObj.created_at;
            }).map(renderComment)}
        </div>
    )
};