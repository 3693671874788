import React from "react";
import {FieldCheckbox, FieldText} from "../../../02_molecules";

export const CreateToolGoogleMaps = (
    {
        searchZoomLevel = 16,
        startZoomLevel = 6,
        isOnSatellite = true,
        defaultSearchString = "israel",
        handleToolDataChange
    }
) => {
    return (
        <div className="create-tool-google-maps">

            <FieldText label="startZoomLevel"
                       name="defaultSearchString"
                       value={defaultSearchString}
                       changeHandler={handleToolDataChange}/>

            <FieldText type="number"
                       label="searchZoomLevel"
                       name="searchZoomLevel"
                       value={searchZoomLevel}
                       changeHandler={handleToolDataChange}/>

            <FieldText type="number"
                       label="זום התחלתי"
                       name="startZoomLevel"
                       value={startZoomLevel}
                       changeHandler={handleToolDataChange}/>

            <FieldCheckbox changeHandler={handleToolDataChange}
                           options={["תמונת לווין"]}
                           name={"isOnSatellite"}
                           value={[isOnSatellite ? "תמונת לווין" : ""]}/>

        </div>
    )
};