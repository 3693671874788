import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { viewport } from "../../00_base/variables";

const THUMBNAIL =
  "https://storage.googleapis.com/assets.youdoadventures.com/website/default_thumbnail.jpg";

const BackgroundImage = (props) => {
  return (
    <BackgroundImageContainer
      {...props}
      className={`background-image ${props.className}`}
    >
      {props.cover.component && (
        <BackgroundImageCover cover={props.cover}>
          {props.cover.component}
        </BackgroundImageCover>
      )}
      {props.children}
    </BackgroundImageContainer>
  );
};


const BackgroundImageContainer = styled.div`
    ${props => `
        width: ${props.square ? '100%' :  props.width};
        padding: ${props.padding};
        padding-bottom: ${props.square && '100%'};
        background-position: ${props.position};
        background-size: ${props.size};
        background-attachment: ${props.attachment};
        background-repeat: ${props.repeat};
        border-radius: ${props.circle ? '50%' : props.radius && props.radius};
        overflow: hidden;
        position: relative;
    `}

    background-image: url(${props =>
    (props.mobileUrl ? props.mobileUrl :
        props.tabletUrl ? props.tabletUrl :
            props.desktopUrl ? props.desktopUrl :
                props.desktopLgUrl ? props.desktopLgUrl :
                    props.url)?.replace("http://", "https://")
});
        @media screen and (min-width: ${viewport.sm}) {
            ${props => `
                background-image: url(${(props.tabletUrl || props.url)?.replace("http://", "https://")});
            `}
        }
        @media screen and (min-width: ${viewport.md}) {
            ${props => `
                background-image: url(${(props.desktopUrl || props.url)?.replace("http://", "https://")});
            `}
        }
        @media screen and (min-width: ${viewport.lg}) {
            ${props => `
                background-image: url(${(props.desktopLgUrl || props.url)?.replace("http://", "https://")});
            `}
        }
        
        @media screen and (min-width: ${viewport.xl}) {
            background-image: url(${props => (props.url || props.url)?.replace("http://", "https://")});
        }
        
 
    }

    height: ${props =>
    props.mobileHeight ? props.mobileHeight :
        props.tabletHeight ? props.tabletHeight :
            props.desktopHeight ? props.desktopHeight :
                props.desktopLgHeight ? props.desktopLgHeight :
                    props.height
};
        
        @media screen and (min-width: ${viewport.sm}) {
            ${props => props.tabletHeight && `
                height: ${props.tabletHeight};
            `}
        }
        @media screen and (min-width: ${viewport.md}) {
            ${props => props.desktopHeight && `
                height: ${props.desktopHeight};
            `}
        }
        @media screen and (min-width: ${viewport.lg}) {
            ${props => props.desktopLgHeight && `
                height: ${props.desktopLgHeight};
            `}
        }
        
        @media screen and (min-width: ${viewport.xl}) {
            height: ${props => props.height}
        }
        
        
        @media screen and (max-width: ${viewport.md}) {
            ${props => props.styleMobile} 
        }
`;

const BackgroundImageCover = styled.div`
  ${(props) => `
        opacity: ${props.cover.showOn === "always" ? 1 : 0};
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: ${props.cover.backgroundColor};
        &:hover {
            opacity: 1;
        }
    `}
`;

BackgroundImage.defaultProps = {
  className: "",
  position: "center center",
  size: "cover",
  repeat: "no-repeat",
  url: THUMBNAIL,
  cover: {
    component: null,
    showOn: "hover",
    backgroundColor: "rgba(0,0,0,0.8)",
  }, // 'hover', 'click' 'always'
};

BackgroundImage.propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  /**
   * background-position
   */
  position: PropTypes.string,
  /**
   * background-size
   */
  size: PropTypes.string,
  /**
   * background-repeat
   */
  repeat: PropTypes.string,
  /**
   * circle with background image
   */
  circle: PropTypes.bool,
  /**
   * border-radius
   */
  radius: PropTypes.string,
  /**
   * define a cover for the image
   */
  cover: PropTypes.shape({
    component: PropTypes.element,
    showOn: PropTypes.oneOf(["hover"], ["always"]),
    backgroundColor: PropTypes.string,
  }),
  className: PropTypes.string,
};

export default BackgroundImage;
