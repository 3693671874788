import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Element, HBox} from 'react-stylesheet';
import {API_URL, fetchData} from "../../../../store/actions/fetch-data";
import {LanguageContext} from "../../../../languages";
import {colors, font_size, gutters, viewport} from "../../../00_base/variables";
import {BackgroundImage, Circle, Image, SimpleLoader, Span} from "../../../01_atoms";
import {IconHeart, IconSpeechBubble, IconTrash, IconUpload} from "../../../02_molecules/icons";
import {BoxAnimated} from "../../../03_organisms";
import {AuthContext} from "../../../../providers/auth-provider";
import {ThemeContext} from "../../../../providers/theme-provider";

const THUMBNAIL = "https://storage.googleapis.com/assets.youdoadventures.com/website/default_thumbnail.jpg";

const UserReview = ({review, theme, isTom, adventure_type, adventure_id, setActiveReview, inSlider, inTrain, background, isLoading, isSingle}) => {
    const [comments, setComments] = useState([]);
    const [discard, setDiscard] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
        fetchData(
            'get',
            `reviewsComments/${review.id}`,
            "",
            success => getCommentsSuccess(success)
        );
    }, [review.id]);

    const getCommentsSuccess = (comments) => {
        setComments(comments)
    };

    // const handlePublishSuccess = (success) => {
    //     window.location.reload()
    // }

    const handleDeleteReview = (id) => {
        setDeleteLoading(true);
        fetch(`${API_URL}/reviews/${id}`, {
            method: 'DELETE',
            headers: {
                'access-Token': localStorage.token && localStorage.token
            }
        }).then(
            response => response.json()
        ).then(
            success => handleDeleteSuccess()
        ).then(
            error => console.log('error', error)
        );
    };

    const handleDeleteSuccess = () => {
        setDiscard(true);
        setDeleteLoading(true);
    };

    const created_at = new Date(review.created_at);
    const dd = created_at.getDate();
    const mm = created_at.getMonth() + 1;
    const yy = created_at.getFullYear().toString().substr(-2);

    const {isAdmin} = useContext(AuthContext);
    const {rtl} = useContext(LanguageContext);
    const {mode} = useContext(ThemeContext);
    const darkMode = mode === "dark";
    return (
        <ReviewWrapper
            adventure_id={adventure_id}
            className={`review review-${review.id}
            mb-4 mb-sm-4 mb-md-4
                ${isSingle ? 'col-12' :
                inSlider ? 'col-6 px-md-5' :
                    inTrain ? 'col-auto px-md-5 px-lg-5 px-wide-6' :
                        `col-md-6 col-lg-4 mb-5 mb-md-5 px-md-3 px-lg-4 px-xl-5 px-wide-5 px-widest-6`}
                    px-3 px-sm-3
                    
                    ${discard ? 'd-none' : ''}
                    ${review.id === 19 && !isTom ? 'd-none' : ''}`}
            theme={theme}
            isSingle={isSingle}
            inSlider={inSlider}
            onClick={() => !isSingle && setActiveReview(review.id)}>
            <Element className={'review-container'} width={'100%'}>


                {!inTrain && !inSlider &&
                <HBox alignItems={'center'}
                      justifyContent={'space-between'}
                      className={'review-user'}
                      color={'#000000'}>

                    <div>
                        {review.icon &&
                        <Circle
                            size={35}
                            marginLeft={gutters.sm}
                            background={'transparent'}
                            isLoading={isLoading}>
                            <Image
                                src={review.icon ? review.icon : 'https://admin.youdoadventures.com/wp-content/uploads/2019/05/placeholder_tr.png'}
                                className={'review-icon'}
                                width={'27px'}/>
                        </Circle>
                        }

                        <Span tag={'span'} paddingRight={gutters.sm}
                              fontWeight={'bold'}
                              isLoading={isLoading}
                              style={{color:darkMode && "#fff", visibility: review.name ? 'visible' : 'hidden'}}
                              fontSize={font_size.xs}
                              value={`${review.name ? review.name.replace('null', '') : "no title"}`}/>
                    </div>
                    {isAdmin && review.adventure_id &&
                    <a href={`/instructions/?id=${review.adventure_id}`}>
                        {review.adventure_id === 1965 ? review.id : review.adventure_id}
                    </a>}

                    {!!isAdmin && (
                        deleteLoading ?
                            <Element width={25}>
                                <SimpleLoader size={18}/>
                            </Element>
                            :
                            <HBox alignItems={"center"}>
                                <IconTrash width={'15px'}
                                           height={'28px'}
                                           color={darkMode ? "#fff" : "#000"}
                                           onClick={(e) => {
                                               e.stopPropagation();
                                               handleDeleteReview(review.id)
                                           }}/>
                                <a href={review.images[0]}
                                   rel="noopener noreferrer"
                                   style={{marginRight: rtl ? "15px" : 0, marginLeft: !rtl ? "15px" : 0}}
                                   target={"_blank"}>
                                    <IconUpload color={darkMode ? "#fff" : "#000"}/>
                                </a>
                            </HBox>
                    )}

                </HBox>}

                <Element className={'review-content'} background={'#fff'} position={'relative'}>
                    <BoxAnimated
                        onClick={() => !isSingle && setActiveReview(review.id)}
                        url={review.images[0]}
                        isLoading={isLoading}
                        isSingle={isSingle}
                        height={isSingle ? '60rem' : '35rem'}
                        tabletHeight={(inSlider || inTrain) && '25rem'}
                        mobileHeight={(inSlider || inTrain) && '10rem'}/>
                </Element>

                <Element className={'review-content'}
                         onClick={() => !isSingle && setActiveReview(review.id)}>
                    <BackgroundImage url={review.images[0] || THUMBNAIL}
                                     height={'100%'}
                                     transition={'all .5s'}
                                     hoverStyle={{transform: 'scale(1.1) rotate(3deg)'}}/>
                </Element>

                <HBox justifyContent={'space-between'} className={`review-footer ${inTrain ? 'd-md-flex' : ''}`}

                      alignItems={'center'}
                      background={(inSlider || inTrain) ? colors.gray : background}
                      color={adventure_type === 1 ? theme?.color_1 : theme?.black}>

                    <Element className={'review-footer-item'}
                             fontWeight={'bold'}
                             whiteSpace={'nowrap'}
                             overflow={'hidden'}>
                        {review.title ? review.title : 'הרפתקה'}
                    </Element>

                    {isSingle && review.text &&
                    <Element className={'review-footer-item'}
                             fontWeight={'bold'}

                             overflow={'hidden'}>
                        {review.text}
                    </Element>
                    }
                    <Element className={'review-footer-item'} fontWeight={'bold'}>
                        {`${dd + '.' + mm + '.' + yy}`}
                    </Element>
                    {isAdmin && 1 === 2 &&
                    <HBox>
                        {/*{!review.is_published && !isLoading &&*/}
                        {/*<ReviewPublishButton handlePublishSuccess={this.handlePublishSuccess}*/}
                        {/*type={'reviews'}*/}
                        {/*data={review}*/}
                        {/*id={review.id}*/}
                        {/*style={{*/}
                        {/*// position: !inTrain && !inSlider && 'absolute',*/}
                        {/*left: '0',*/}
                        {/*fontSize: font_size.xxs,*/}
                        {/*padding: '0'*/}
                        {/*// top: gutters.sm,*/}
                        {/*// zIndex: 1,*/}
                        {/*}}>*/}
                        {/*פרסם*/}
                        {/*</ReviewPublishButton>}*/}

                        {comments && comments.filter(comment => comment.is_published).length > 0 &&
                        <HBox className={'review-footer-item'}>
                            <IconSpeechBubble color={adventure_type === 1 ? theme.color_1 : theme.black}/>
                            <Element paddingRight={gutters.xxs}
                                     fontWeight={'bold'}>
                                {comments && comments.filter(comment => comment.is_published).length}
                            </Element>
                        </HBox>
                        }

                        {1 === 3 &&
                        <HBox className={'review-footer-item'}>
                            <IconHeart
                                color={adventure_type === 1 ? theme.color_1 : theme.black}/>
                            <Element paddingRight={gutters.xxs} fontWeight={'bold'}>
                                {review.likes}
                            </Element>
                        </HBox>
                        }
                    </HBox>
                    }
                </HBox>
            </Element>
        </ReviewWrapper>
    )
}

export default UserReview;

const ReviewWrapper = styled.div`
    margin-bottom: ${gutters.lg};
    
    .review-container {
        position: relative;
    }
    .review-content {
       
        // height: 30rem;
        cursor: pointer;
        overflow: hidden;
    }
    .review-user {
        padding-top: ${gutters.sm};
        padding-bottom: ${gutters.sm};
    }
    .review-footer {
        padding: ${gutters.xs};
        background-color: #fff;
        // position: absolute;
        bottom: 0;
        width: 100%;
        .review-footer-item {
            padding: 0 .3rem;
            font-size: ${font_size.xxs};
            font-weight: bold;
            align-items: center;
        }
    }
    
    ${props => props.inTrain && `
        @media screen and (max-width: ${viewport.sm}) {
            margin-bottom: ${gutters.sm};
        }
    `}
    
    ${props => props.inSlider && `
        @media screen and (max-width: ${viewport.sm}) {
            padding: 0;
            margin-bottom: 0;
            &:nth-child(3) {
                max-width: 100%;
                flex: 100% 0 0;
        }
    `}
    
`;
