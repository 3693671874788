import React from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components'
import classNames from "classnames";
import "../../../../../../../assets/styles/letters-coder.scss";

export const LettersCoder = (
    {
        isButtons3D,
        onInputAdd,
        textColor,
        buttonsBackgroundColor,
        isHebrew,
}) =>{  

    const getLettersRow =(lettersRow, className) => {
        return (
            <div className={"letter-row " + className}>
                {lettersRow.map(letter=>
                    <LetterButton className={classNames("letter", {isButtons3D})}
                                  key={letter}
                                  backgroundColor={buttonsBackgroundColor}
                                  textColor={textColor}
                                  onClick={()=>onInputAdd(letter)}>
                        {letter}
                    </LetterButton>
                )}
            </div>
        )
    }
        
        const hebrewLettersFirstRow = [ "ק","ר","א","ט","ו","ן","ם","פ"]
        const hebrewLettersSecondRow = ["ש","ד","ג","כ","ע","י","ח","ל","ך","ף"]
        const hebrewLettersThirdRow = ["ז","ס","ב","ה","נ","מ","צ","ת","ץ"]

        const englishLettersFirstRow = ["q","w","e","r","t","y","u","i","o","p"]
        const englishLettersSecondRow = ["a","s","d","f","g","h","j","k","l"]
        const englishLettersThirdRow = ["z","x","c","v","b","n","m"]

        return ([
            getLettersRow(isHebrew ? hebrewLettersFirstRow : englishLettersFirstRow, "first"),
            getLettersRow(isHebrew ? hebrewLettersSecondRow : englishLettersSecondRow, "second"),
            getLettersRow(isHebrew ? hebrewLettersThirdRow : englishLettersThirdRow, "third"),
        ])
}

const LetterButton = styled.div`
    ${({textColor, backgroundColor}) => `
        color: ${textColor};
        background-color:${backgroundColor};
    `}
`;

LettersCoder.defaultProps = {
    buttonTextSize: 25,
    backgroundColor: "white",
    isButtons3D: true,
    isHebrew: false,
};

LettersCoder.propTypes = {
    buttonsBackgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    buttonTextSize: PropTypes.number,
    backgroundColor: PropTypes.string,
    isButtons3D: PropTypes.bool,
    isHebrew: PropTypes.bool,
};