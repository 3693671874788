import React, {useEffect, useState} from "react";
import {FieldCheckbox, FieldText, FilesUploader} from "../../../../../02_molecules";
import CreateToolCoder from "../../../../tools/coder";
// import {MdArrowDropDown, MdArrowDropUp} from "react-icons/all";

export const AdditionalDataPassword = (
    {handleToolDataChange, innerPassword, innerPasswordLabel, coderData = {}, modalIcon, toolPassword, isPasswordModal}
) => {
    const [isActive, setIsActive] = useState(!!toolPassword);
    const [isOpen, setIsOpen] = useState(!!toolPassword);

    const [isModalActive, setIsModalActive] = useState(isPasswordModal);

    useEffect(() => setIsOpen(isActive), [isActive]);

    const toggleFramePassword = (name, value) => {
        setIsActive(value);
        handleToolDataChange("toolPassword", value);
    };

    const toggleIsModalActive = (name, value) => {
        setIsModalActive(value);
        handleToolDataChange(IS_MODAL_NAME, value);
    };

    const handleCoderDataChange = (name, value) => {
        handleToolDataChange(CODER_DATA, {...coderData, [name]: value});
    };

    // const IconDrop = isOpen ? MdArrowDropUp : MdArrowDropDown;
    const wrapperStyle = {borderBottom: `${isOpen ? 1 : 0}px dashed #000`, marginBottom: "10px"};

    return (
        <div style={wrapperStyle}>
            <div className={"d-flex justify-content-between align-items-center"}>
                <FieldCheckbox options={[PASSWORD_ACTIVE_LABEL]}
                               name="password"
                               controlStyle={{marginBottom: 0}}
                               value={isActive ? [PASSWORD_ACTIVE_LABEL] : []}
                               changeHandler={toggleFramePassword}/>
                {/* {isActive && <IconDrop onClick={() => setIsOpen(!isOpen)} cursor={"pointer"} size={20}/>} */}
            </div>

            {isActive && isOpen &&
            <div className={"pt-3 px-3 pb-0"}>
                <FieldCheckbox changeHandler={toggleIsModalActive}
                               options={[IS_MODAL]}
                               dir="vertical"
                               name={IS_MODAL_NAME}
                               value={isModalActive ? [IS_MODAL] : []}/>
                {isModalActive ?
                    <>
                        <FilesUploader name="modalIcon"
                                       value={modalIcon}
                                       triggerValue="איקון לפתיחת הפופאפ"
                                       previewWidth={"100%"}
                                       multiple={false}
                                       changeHandler={handleToolDataChange}/>
                        <CreateToolCoder {...coderData}
                                         handleToolDataChange={handleCoderDataChange}/>
                    </>
                    :
                    <>
                        <FieldText changeHandler={handleToolDataChange}
                                   name={INNER_PASSWORD_LABEL}
                                   label="כותרת שדה סיסמת יציאה"
                                   placeholder="הכנס כותרת שדה סיסמת יציאה"
                                   value={innerPasswordLabel}
                                   controlStyle={{marginLeft: "10px"}}/>
                        <FieldText changeHandler={handleToolDataChange}
                                   name={INNER_PASSWORD}
                                   label="סיסמת יציאה"
                                   placeholder="הכנס סיסמת יציאה"
                                   value={toolPassword}/>
                    </>}

            </div>}
        </div>
    )
};

const CODER_DATA = "coderData";
const IS_MODAL_NAME = "isPasswordModal";
const IS_MODAL = "הצג כפופאפ";
const INNER_PASSWORD = "innerPassword";
const INNER_PASSWORD_LABEL = "innerPasswordLabel";
const PASSWORD_ACTIVE_LABEL = "סיסמא ליציאה מהכלי";