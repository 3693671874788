import React from "react";
import arrowImg from "../../../assets/images/arrow.png";

//Arrows

const arrowStyle = (width, top, right, rotate, scaleX = 1) => {
    return {
        position: "absolute",
        width: width + "px",
        top: top + "%",
        right: right + "%",
        transform: `rotate(${rotate + "deg"}) scaleX(${scaleX})`
    };
};

const iconStyle = (top, right) => {
    return {
        top: top + "%",
        right: right + "%",
    }
};

export const mobileArrows = [
    <img style={arrowStyle(20, -50, -5, -22, -1)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(20, -25, 25, 73)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(20, 4, 57, 32)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(20, 32, 37, -54, -1)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(20, 60, 44, 45)} alt="menu arrow" src={arrowImg}/>,

];

export const tabletPortraitArrows = [
    <img style={arrowStyle(35, -100, -2, 23, -1)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(35, -75, 33, 55)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(35, 0, 35, -56)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(35, 53, 18, 59, -1)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(35, 65, 50, 125, -1)} alt="menu arrow" src={arrowImg}/>,
];

export const tabletLandscapeArrows = [
    <img style={arrowStyle(35, -24, 0, 15, -1)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(35, 0, 18, 67)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(35, 34, 35, 113, -1)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(35, 14, 56, 41)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(35, 64, 72, 92, -1)} alt="menu arrow" src={arrowImg}/>,
];

export const desktopArrows = [
    <img style={arrowStyle(35, -18, 0, 23, -1)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(35, 7, 18, 55)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(35, 48, 38, 110, -1)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(35, 15, 58, 59)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(35, 76, 74,112, -1)} alt="menu arrow" src={arrowImg}/>,
];

export const desktopLargeArrows = [
    <img style={arrowStyle(40, -10, 1, 23, -1)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(40, 6, 20, 77)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(40, 44, 37, 110, -1)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(40, 16, 58, 59)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(40, 75, 71, 114, -1)} alt="menu arrow" src={arrowImg}/>,
];

export const desktopMaxArrows = [
    <img style={arrowStyle(40, -10, 8, 23, -1)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(40, 4, 26, 77)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(40, 39, 42, 110, -1)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(40, 17, 59, 59)} alt="menu arrow" src={arrowImg}/>,
    <img style={arrowStyle(40, 91, 71, 92, -1)} alt="menu arrow" src={arrowImg}/>,
];

//Icons

export const mobileIconsProps = [
    iconStyle(-34, -6),
    iconStyle(-16, 30),
    iconStyle(18, 47),
    iconStyle(43, 10),
    iconStyle(76, 39),
];

export const tabletPortraitIconsProps = [
    iconStyle(-95, 5),
    iconStyle(-51, 33),
    iconStyle(3, 9.5),
    iconStyle(50, 28),
    iconStyle(33, 55),
];

export const tabletLandscapeIconsProps = [
    iconStyle(0, 2),
    iconStyle(20, 22),
    iconStyle(-4, 40.5),
    iconStyle(38, 57),
    iconStyle(46, 78),
];

export const desktopIconsProps = [
    iconStyle(0, 0),
    iconStyle(20, 22),
    iconStyle(-4, 40.5),
    iconStyle(38, 57),
    iconStyle(37, 75),
];

export const desktopLargeIconsProps = [
    iconStyle(0, 3),
    iconStyle(20, 22),
    iconStyle(-4, 40.5),
    iconStyle(38, 57),
    iconStyle(37, 75),
];

export const desktopMaxIconsProps = [
    iconStyle(0,12),
    iconStyle(20, 30),
    iconStyle(-4, 45),
    iconStyle(38, 60),
    iconStyle(62, 75),
];
