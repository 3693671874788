import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const Block = props => <BlockStyle {...props} className={'block'} height={props.height}>
    <BlockContainer width={props.width}>
        {props.children}
    </BlockContainer>
</BlockStyle>;

const BlockStyle = styled.div`
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: ${props => props.alignItems};
    justify-content: ${props => props.justifyContent};    
    width: ${props => props.width}; 
    height: ${props => props.height}; 
    max-height: ${props => props.maxHeight}; 
    background-color: ${props => props.backgroundColor};
    padding-top: ${props => props.paddingTop ? props.paddingTop : props.paddingVertical};
    padding-bottom: ${props => props.paddingBottom ? props.paddingBottom : props.paddingVertical};
    padding-left: ${props => props.paddingLeft ? props.paddingLeft : props.paddingHorizontal};
    padding-right: ${props => props.paddingRight ? props.paddingRight : props.paddingHorizontal};
    ${props => props.backgroundImage && `
        background-image: url(${props.backgroundImage});
        background-size: cover;
    `};
`;

const BlockContainer = styled.div`
    width: ${props => props.width};
`;

Block.defaultProps = {
    width: '100%',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: '0',
    backgroundColor: '#ffffff'
};
Block.propTypes = {
    paddingTop: PropTypes.string,
    paddingBottom: PropTypes.string,
    paddingLeft: PropTypes.string,
    paddingRight: PropTypes.string,
    paddingVertical: PropTypes.string,
    backgroundColor: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    maxHeight: PropTypes.string,
    justifyContent: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
    alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch'])
};

export default Block;