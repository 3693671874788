import React, {useContext, useState} from "react";
import {useSelector} from "react-redux";
import FieldCheckbox from "../../../02_molecules/form-fields/checkbox/m-field-checkbox";
import {DICT} from "../../../../languages/dictionary-names";
import {Translation} from "../../../../languages";
import {RiddlesStoreContext} from "./riddles-store-provider";
import classNames from "classnames";
import {ChooseTag} from "../../../03_organisms/choose-tag-field/o-choose-tag";
import {ThemeContext} from "../../../../providers/theme-provider";
import {ToggleIcon} from "../../../03_organisms";
import {buildStars} from "./stars-dictionary";
import {colors} from "../../../00_base/variables";

export const RiddlesStoreFilter = (
    {
        handleChange,
        levelFilter,
        tagsFilter
    }) => {
    const {mode} = useContext(ThemeContext);
    const [levelFilterOpen, setLevelFilterOpen] = useState(true);
    const renderStars = (length) => {
        return <div className="d-flex align-items-center mx-3">
            {buildStars(length).map((Star, index) =>
            <Star size={20} color={colors.yellow} key={index}/>)}
        </div>
    };

    const RenderLabel = ({length, id}) => {
        return (
            <div className="d-flex align-items-center">
                <Translation id={id}/>
                {renderStars(length)}
            </div>
        )
    };

    const levelFilterOptions = [
        {label: <RenderLabel id={DICT.EASY} length={1}/> , name: "easy", value: 1},
        {label: <RenderLabel id={DICT.MEDIUM} length={2}/>, name: "medium", value: 2},
        {label: <RenderLabel id={DICT.HARD} length={3}/>, name: "hard", value: 3},
        {label: <RenderLabel id={DICT.EXPERT} length={4}/>, name: "expert", value: 4},
    ];

    const FilterSection = ({title, filterName, options, value}) => {
        const changeHandler = (name, value) => {
            const itemValue = options.find(option => option.name === name).value;
            handleChange(filterName, itemValue);
        };

        return (
            <div className={"mb-6"}>
                <div className="d-flex justify-content-between align-items-center mb-3"
                     onClick={() => setLevelFilterOpen(!levelFilterOpen)}
                     style={{width:"100%",borderBottom: `2px solid ${mode === "dark" ? "#fff" : "#000"}`}}>
                    <h6 className="m-0 color-black" >{title}</h6>
                    <ToggleIcon isOpen={levelFilterOpen}
                                toggle={() => setLevelFilterOpen(!levelFilterOpen)}/>
                </div>
                {levelFilterOpen &&
                <div className={"d-lg-block level-filters-wrapper"}>

                    {options.map((option, index) => {
                        const isChecked = value.some(item => item === option.value);
                        const labelStyle = {
                            width: "100%",
                            flexDirection: "row-reverse",
                            justifyContent: "space-between",
                            cursor: "pointer"
                        };
                        return <FieldCheckbox key={index}
                                              name={option.name}
                                              options={[option.label]}
                                              inputStyle={{width: "100%"}}
                                              marginLeft={"0px"}
                                              marginRight={"0px"}
                                              labelStyle={{...labelStyle, color: mode === "dark" ? "#fff" : "#000"}}
                                              value={isChecked ? [option.label] : []}
                                              changeHandler={changeHandler}/>
                    })}
                </div>
                }
            </div>
        )
    };

    return (
        <div style={{width:"100%"}}>
            <FilterSection title="רמת קושי"
                           options={levelFilterOptions}
                           value={levelFilter}
                           changeHandler={handleChange}
                           filterName="level"/>

            <ChooseTag tags={tagsFilter}
                       title={"תגיות"}
                       showToggleIcon={true}
                       changeHandler={(tags) => handleChange("tags", tags)}/>
        </div>
    )
};