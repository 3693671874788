import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import Label from '../../../01_atoms/text/a-label';
import Span from '../../../01_atoms/text/a-span';
import {fieldStyle} from '../field-style/m-field-style';

const SELECTED_CHANGE = 'SELECTED_CHANGE';

const inputReducer = (state, action) => {
    switch (action.type) {
        case SELECTED_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid
            }
        default:
            return state
    }
}

const FieldSelect = ({
    name,
    label,
    options,
    defaultValue,
    required,
    dir,
    errorMessage,
    changeHandler,
    initialValue,
    initiallyValid,
}) => {
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: initialValue ? initialValue : '',
        isValid: initiallyValid,
        blur: false
    })

    useEffect(() => {
        changeHandler(name, inputState.value, inputState.isValid);
    }, [inputState, changeHandler, name]);

    const handleInputChange = event => {
        const value = event.target.value;
        let isValid = true;
        if (required && value.trim().length === 0) { isValid = false; }

        dispatch({
            type: SELECTED_CHANGE,
            value: value,
            isValid: isValid
        })
    };

    return (
        <div style={{ ...fieldStyle.control, ...fieldStyle[dir] }}>
            <Label style={fieldStyle.label} value={label} />
            <select
                onChange={handleInputChange}
                name={name}
                value={inputState.value || defaultValue}
                style={fieldStyle.input}>
                {options.map((option, index) => {
                    return <option key={index}>{option}</option>
                })}
            </select>
            {!inputState.isValid && <Span value={errorMessage} />}
        </div>
    )
};

FieldSelect.defaultProps = {
    value: '',
    initialValue: '',
    dir: 'vertical',
    initiallyValid: true
};

FieldSelect.propTypes = {
    name: PropTypes.string.isRequired,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dir: PropTypes.oneOf(['horizontal', 'vertical']),
    options: PropTypes.array.isRequired,
    errorMessage: PropTypes.string,
    changeHandler: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    initiallyValid: PropTypes.bool,
    required: PropTypes.bool,
};

export default FieldSelect; 