import React from 'react';
import {Element} from 'react-stylesheet';
import {colors, gutters} from "../../00_base/variables";

const LoadingDescription = (
    {
        height = '7rem',
        width,
        background = colors.gray_1
    }
) => {
    return (
        <Element width={width ? width : `${(Math.random() * 200) + 100}px`}
                 minWidth={'60%'}
                 height={height}
                 background={background}
                 borderRadius={'1rem'}
                 margin={`${gutters.sm} 0`}/>
    )
}

export default LoadingDescription;