import React from 'react';

class IconParticipants extends React.Component {
    render() {
        const {color, width, height} = this.props;
        return (
            <svg width={width} height={height} viewBox="0 0 19 17" version="1.1">
                <defs>
                    <polygon id="path-1" points="0.0002375 0.475475 11.8752375 0.475475 11.8752375 14.25 0.0002375 14.25"/>
                </defs>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="participants-kids">
                        <path d="M17.8125,2.6127375 C17.8125,1.4323625 17.0145,0.4752375 16.03125,0.4752375 C15.048,0.4752375 14.25,1.4323625 14.25,2.6127375 C14.25,3.7931125 15.048,4.7502375 16.03125,4.7502375 C17.0145,4.7502375 17.8125,3.7931125 17.8125,2.6127375" fill={color}/>
                        <path d="M4.75,2.6127375 C4.75,1.4323625 3.952,0.4752375 2.96875,0.4752375 C1.9855,0.4752375 1.1875,1.4323625 1.1875,2.6127375 C1.1875,3.7931125 1.9855,4.7502375 2.96875,4.7502375 C3.952,4.7502375 4.75,3.7931125 4.75,2.6127375" fill={color}/>
                        <path d="M4.75,7.006725 C4.75,6.19685 4.9198125,5.4380375 5.2000625,4.750475 L2.375,4.750475 C1.06875,4.750475 2.30926389e-14,5.819225 2.30926389e-14,7.125475 L2.30926389e-14,9.500475 L5.312875,9.500475 C4.966125,8.75235 4.75,7.9116 4.75,7.006725" fill={color}/>
                        <path d="M16.625,4.75 L13.6028125,4.75 C13.8830625,5.4375625 14.052875,6.1975625 14.052875,7.00625 C14.052875,7.9123125 13.8379375,8.751875 13.49,9.5 L19,9.5 L19,7.125 C19,5.81875 17.93125,4.75 16.625,4.75" fill={color}/>
                        <g id="Group-11" transform="translate(3.562500, 2.375000)">
                            <mask fill="white"/>
                            <g/>
                            <path d="M9.1202375,8.4139125 C8.8589875,8.3521625 8.5918,8.312975 8.3127375,8.312975 L7.4138,8.312975 C8.53005,7.62185 9.3031125,6.241975 9.3031125,4.631725 C9.3031125,2.3362875 7.75105,0.475475 5.839175,0.475475 C3.924925,0.475475 2.3752375,2.3362875 2.3752375,4.631725 C2.3752375,6.241975 3.1471125,7.62185 4.2633625,8.312975 L3.5627375,8.312975 C3.2302375,8.312975 2.913175,8.3735375 2.6068,8.460225 C1.11055,8.882975 0.0002375,10.2486 0.0002375,11.875475 L0.0002375,14.250475 L11.8752375,14.250475 L11.8752375,11.875475 C11.8752375,10.1951625 10.6924875,8.783225 9.1202375,8.4139125" fill={color}/>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }

    static defaultProps = {
        color: '#000',
        width: '19px',
        height: '17px'
    }
}

export default IconParticipants;