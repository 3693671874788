import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import Label from '../../../01_atoms/text/a-label';
import Span from '../../../01_atoms/text/a-span';
import {fieldStyle} from '../field-style/m-field-style';
import {colors, gutters} from "../../../00_base/variables";

const RADIO_CHANGE = 'RADIO_CHANGE';

const inputReducer = (state, action) => {
    switch (action.type) {
        case RADIO_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid
            }
        default:
            return state
    }
};

const FieldRadio = ({
                        name,
                        label,
                        options,
                        dir,
                        defaultValue,
                        required,
                        note,
                        noteStyle,
                        noteCondition,
                        errorMessage,
                        changeHandler,
                        initialValue,
                        labelStyle,
                        inputStyle,
                        initiallyValid,
                    }) => {
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: initialValue ? initialValue : '',
        isValid: initiallyValid,
    });

    // useEffect(() => {
    //     debugger
    //     changeHandler(name, inputState.value, inputState.isValid);
    // }, [inputState, name, changeHandler]);

    const handleInputChange = event => {
        const value = event.target.value;
        let isValid = true;
        if (required && value.trim().length === 0) {
            isValid = false;
        }

        dispatch({
            type: RADIO_CHANGE,
            value: value,
            isValid: isValid
        });

        changeHandler(name, value, isValid);
    };

    return (
        <div style={fieldStyle.control}>
            <Label style={fieldStyle.label} value={label}/>

            <div style={{...fieldStyle.container, ...fieldStyle[dir]}}>
                {options.map((option, index) => {
                    return <div key={index} style={radioWrapper}>
                        <label style={{...radioLabel, ...labelStyle}}>
                            <input type='radio'
                                   checked={inputState.value === option && 'checked'}
                                   value={option}
                                   onChange={handleInputChange}
                                   name={name}
                                   style={{...defaultInputStyle, ...inputStyle}}/>
                            {option}
                        </label>
                    </div>
                })}
            </div>
            {note && noteCondition === inputState.value && <Span value={note} style={{...noteStyle}}/>}
            {!inputState.isValid && <Span value={errorMessage}/>}
        </div>
    )
};

const
    radioWrapper = {
        display: 'flex',
        // padding: `${gutters.sm} ${gutters.md}`,
        marginLeft: gutters.sm,
        marginBottom: gutters.sm,
        borderWidth: 0,
        borderBottomWidth: '.1rem',
        borderBottomColor: colors.border
    },
    radioLabel = {
        display: 'flex',
        alignItems: 'center',
        fontSize: '18px'
    };

const defaultInputStyle = {
    width: '20px', height: '20px', cursor: 'pointer'
};

FieldRadio.defaultProps = {
    value: '',
    initialValue: '',
    initiallyValid: true,
    dir: 'horizontal'
};

FieldRadio.propTypes = {
    name: PropTypes.string.isRequired,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.array.isRequired,
    dir: PropTypes.oneOf(['horizontal', 'vertical']),
    errorMessage: PropTypes.string,
    changeHandler: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    initiallyValid: PropTypes.bool,
    required: PropTypes.bool,
};

export default FieldRadio; 