import React from 'react';

class IconHeart extends React.Component {
    render() {
        const {color} = this.props;
        return (
            <svg width="18px" height="16px" viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg">

                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                    <g transform="translate(-309.000000, -3373.000000)" stroke={color ? color : '#000000'}>
                        <path d="M323.3571,3374.33856 C320.219888,3372.93925 318.000226,3376.34666 318.000226,3376.34666 C318.000226,3376.34666 315.780563,3372.93925 312.643351,3374.33629 C310.189803,3375.4312 309.285746,3378.48651 310.608098,3380.84444 C311.64484,3382.6958 313.794786,3385.04237 317.865292,3387.95457 C317.945252,3388.01514 318.055199,3388.01514 318.135159,3387.95457 C322.205665,3385.04237 324.355611,3382.6958 325.392353,3380.84671 C326.714705,3378.48878 325.808399,3375.43347 323.3571,3374.33856 Z"></path>
                    </g>
                </g>
            </svg>
        )
    }
}

export default IconHeart;