import React from 'react';

class IconLamp extends React.Component {
    render() {
        const {color} = this.props;
        return (
            <svg width="14px" height="22px" viewBox="0 0 14 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g fill={color ? color : '#7540FC'}>
                        <path d="M9.5,17 L4.5,17 C4.225,17 4,17.45 4,18 C4,18.55 4.225,19 4.5,19 L9.5,19 C9.775,19 10,18.55 10,18 C10,17.45 9.775,17 9.5,17" id="Fill-3"/>
                        <path d="M8.5,20 L5.5,20 C5.225,20 5,20.45 5,21 C5,21.55 5.225,22 5.5,22 L8.5,22 C8.775,22 9,21.55 9,21 C9,20.45 8.775,20 8.5,20" id="Fill-5"/>
                        <path d="M8.75,11.4516667 L8.75,12.4083333 L8.75,14.0266796 L5.25,14.0266796 L5.25,12.4083333 L5.25,11.4516667 L4.382,10.97 C2.7335,10.055 1.75,8.44666667 1.75,6.66666667 C1.75,3.91 4.1055,1.66666667 7,1.66666667 C9.89625,1.66666667 12.25,3.91 12.25,6.66666667 C12.25,8.44666667 11.2665,10.055 9.61975,10.97 L8.75,11.4516667 Z M0,6.66666667 C0,9.12833333 1.4175,11.2533333 3.5,12.4083333 L3.5,15 C3.5,15.5522847 3.94771525,16 4.5,16 L9.5,16 C10.0522847,16 10.5,15.5522847 10.5,15 L10.5,12.4083333 C12.58425,11.2533333 14,9.12833333 14,6.66666667 C14,2.985 10.86575,0 7,0 C3.13425,0 0,2.985 0,6.66666667 Z" id="Fill-18"/>
                    </g>
                </g>
            </svg>
        )
    }
}

export default IconLamp;