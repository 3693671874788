import React from "react";
import {Col, Row} from "react-grid-system";
import {FieldOptions} from "../../../../02_molecules";

export const CssCreateDisplay = ({display, labelStyle, inputStyle, changeHandler}) => {
    const fieldCommonProps = {labelStyle, inputStyle, changeHandler};
    const options = [
        {id: 0, label: "block", value: "block"},
        {id: 1, label: "inline-block", value: "inline-block"},
        {id: 2, label: "inline", value: "inline"},
        {id: 3, label: "flex", value: "flex"},
        {id: 4, label: "inline-flex", value: "inline-flex"},
        {id: 5, label: "none", value: "none"},
    ];

    return (
        <Row justify={"start"}>
            <Col xs={12}>
                <FieldOptions options={options}
                              label={"display"}
                              name={"display"}
                              value={options.find(item => item.value === display)}
                              inputStyle={{fontSize:"18px", marginBottom:"10px"}}
                              changeHandler={changeHandler}/>
            </Col>
        </Row>
    )
}