import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Container } from "react-grid-system";
import { selectAdventure } from "../../../store/selectors";
import {
  setSingleAdventureType,
  updateAdventureType,
} from "../../../store/actions/adventure-type-actions";
import { setSingleAdventureData } from "../../../store/actions/adventure-data-actions";
import { setAdventureRiddles } from "../../../store/actions/riddle-actions";
import { AdventureTypeMainDetails } from "./adventure-type-main-details/adventure-type-main-details";
import { AdventureTypeSiteDetails } from "./adventure-type-site-details/adventure-type-site-details";
import { AdventureTypeGame } from "./adventure-type-game/adventure-type-game";
import { AdventureTypeSequence } from "./adventure-type-sequence/adventure-type-sequence";
import { SingleFrame } from "../riddle/template/single-frame";
import CreateRiddle from "../riddle";
import { AdventureTypeUi } from "./adventure-type-ui/adventure-type-ui";
import { LoaderPageContent } from "../../01_atoms";
import { Tabs } from "../../03_organisms";
import { TableTitle } from "../../03_organisms";
import { useParams } from "react-router-dom";
import "../../../assets/styles/create-adventure-type.scss";
const CreateAdventureType = (props) => {
  const params = useParams();
  const { id } = props;

  const activeTab = params.activeTab ;
  const singleAdventure = useSelector(selectAdventure);

  const {
    adventureData,
    adventureType,
    riddles,
    frames,
    activeFrame,
    activeRiddle,
  } = singleAdventure;
  const adventureDataId = adventureType?.adventuredata_id;

  const adventureTypeId =
    id > 0 ? id : !!params ? parseInt(params?.id) : null;
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (adventureTypeId && !adventureType?.id) {
      dispatch(setSingleAdventureType(adventureTypeId));
    }
  }, [dispatch, adventureType, adventureTypeId]);

  useEffect(() => {
    if (adventureDataId) {
      dispatch(setSingleAdventureData(adventureDataId));
    }
  }, [dispatch, adventureDataId, adventureType.id]);

  useEffect(() => {
    if (adventureData?.riddles) {
      dispatch(
        setAdventureRiddles(adventureData?.riddles, () => setIsLoading(false))
      );
    } else {
      setIsLoading(false);
    }
  }, [dispatch, adventureData]);

  const handleChange = (name, value) => {
    dispatch(updateAdventureType({ ...adventureType, [name]: value }));
  };

  if (isLoading) {
    return (
      <div className="adventure-type" style={{ paddingTop: "50px" }}>
        <LoaderPageContent />
      </div>
    );
  }

  const tabsItems = [
    {
      name: "details",
      label: "פרטי ההרפתקה",
      content: <AdventureTypeMainDetails handleChange={handleChange} />,
    },
    {
      name: "website",
      label: "עמוד המוצר באתר",
      content: <AdventureTypeSiteDetails handleChange={handleChange} />,
    },
    {
      name: "game",
      label: "כניסה ולובי",
      content: (
        <AdventureTypeGame
          handleChange={handleChange}
          handleJsonDataChange={handleChange}
        />
      ),
    },
    {
      name: "sequence",
      label: "רצף ההרפתקה",
      content: (
        <AdventureTypeSequence
          handleChange={handleChange}
          adventureDataId={adventureDataId}
        />
      ),
    },
    {
      name: "ui",
      label: "עיצוב",
      content: (
        <AdventureTypeUi
          handleChange={handleChange}
          adventureDataId={adventureDataId}
        />
      ),
    },
  ];

  const singleFrame = activeFrame && (
    <div
      className={classNames("adventure-type-single-frame", {
        active: activeFrame,
      })}
    >
      <SingleFrame frame={frames.find((frame) => frame.id === activeFrame)} />
    </div>
  );

  const singleRiddle = activeRiddle && (
    <div
      className={classNames("single-riddle-modal", { active: activeRiddle })}
    >
      <Container className="single-riddle-modal-content">
        <CreateRiddle
          riddle={riddles.find((riddle) => riddle.id === activeRiddle)}
        />
      </Container>
    </div>
  );
  return (
    <div className="adventure-type">
      <TableTitle className="adventure-type-header">
        <h1>
          {!adventureType.name || adventureType.name.includes("הרפתקה-")
            ? "יצירת הרפתקה"
            : adventureType.name}
        </h1>
      </TableTitle>
      {singleRiddle}
      {singleFrame}
      <Container className="adventure-type-container">
        <Tabs
          items={tabsItems}
          activeTab={tabsItems.findIndex((item) => item.name === activeTab)}
          tabLabelStyle={tabLabelStyle}
          contentStyle={contentStyle}
        />
      </Container>
    </div>
  );
};

const tabLabelStyle = {
  padding: "10px 20px",
  backgroundColor: "transparent",
  color: "#b3b3b3",
  fontWeight: "bold",
  fontSize: "18px",
};
const contentStyle = {
  // borderRadius: "10px",
  padding: "30px 0 ",
  // backgroundColor: "#f2f2f2",
  minHeight: "600px",
  overflow: "visible"
};

export default CreateAdventureType;
