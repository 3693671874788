import React, {useState} from 'react';
import {Element, HBox} from 'react-stylesheet';
import {MdCollections, MdDesktopMac, MdDns, MdPhoneIphone} from 'react-icons/md';
import {gutters} from '../../00_base/variables';
import {Anchor, Button, Heading, SimpleLoader} from '../../01_atoms';
import {ToolTip} from '../../02_molecules';

const CreatorHeader = ({
    title,
    showButtons,
    url,
    url_website,
    api,
    duplicator,
    id,
    name
}) => {
    const [duplicateLoading, setDuplicateLoading] = useState(false);
    return (
        <HBox justifyContent={'space-between'}>
            <Element paddingBottom={gutters.sm}>
                <Heading
                    tag={'h3'}
                    value={title}
                    textAlign={'center'}
                    style={{ marginBottom: gutters.xs }} />
                {!!name && !!id &&
                    <Anchor
                        href={`/he/admin/riddle/${id}`}
                        target="/blank"
                        value={`${name} #${id}`} />}
            </Element>
            {showButtons &&
                <HBox alignItems={'center'}>
                    {url &&
                        <ToolTip
                            id={'tip-header-url'}
                            tip={'צפה בכלים'}
                            place={'bottom'}
                            offset={{ top: 0 }}
                            placeholder={<Button fontWeight={'bold'} textAlign={'center'} style={{ textDecoration: 'underline', marginLeft: '10px' }}>
                                <Anchor target={'_blank'}
                                        href={`https://${url}`}>
                                    <MdPhoneIphone /></Anchor>
                            </Button>} />
                    }
                    {url_website &&
                    <ToolTip
                        id={'tip-header-url-website'}
                        tip={'עמוד ההרפתקה'}
                        place={'bottom'}
                        offset={{ top: 0 }}
                        placeholder={<Button fontWeight={'bold'} textAlign={'center'} style={{ textDecoration: 'underline', marginLeft: '10px' }}>
                            <Anchor target={'_blank'}
                                    href={`https://${url_website}`}>
                                <MdDesktopMac />
                            </Anchor>
                        </Button>} />
                    }
                    <ToolTip
                        id={'tip-header-api'}
                        tip={'api'}
                        place={'bottom'}
                        offset={{ top: 0 }}
                        placeholder={
                            <Button fontWeight={'bold'} textAlign={'center'} style={{ textDecoration: 'underline', marginLeft: '10px' }}>
                                <Anchor target={'_blank'} href={api}><MdDns /></Anchor>
                            </Button>
                        } />
                    <ToolTip
                        id={'tip-header-duplicator'}
                        tip={'שכפל'}
                        place={'bottom'}
                        offset={{ top: 0 }}
                        placeholder={
                            <Button onClick={() => {
                                setDuplicateLoading(true);
                                duplicator();
                            }}>
                                {duplicateLoading ? <SimpleLoader color="#fff" size={20} /> : <MdCollections />}
                            </Button>
                        } />
                </HBox>
            }
        </HBox>
    )
}

export default CreatorHeader;