import React from 'react';

class IconCamera extends React.Component {
    render() {
        const {color, width, height, onClick} = this.props;

        return (
            <svg width={width} height={height} viewBox="0 0 61 49" version="1.1" xmlns="http://www.w3.org/2000/svg" onClick={onClick && onClick}>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-941.000000, -735.000000)" fill={color}>
                        <g>
                            <g id="Group" transform="translate(941.000000, 735.000000)">
                                <path
                                    d="M30.8255417,13.72 C22.6509062,13.72 16,20.2378881 16,28.2496535 C16,36.261419 22.6509062,42.7793071 30.8255417,42.7793071 C39.0008125,42.7793071 45.6523542,36.261419 45.6523542,28.2496535 C45.6523542,20.2385108 39.0008125,13.72 30.8255417,13.72 Z M30.8255417,39.0436798 C24.7528646,39.0436798 19.8125,34.2014998 19.8125,28.2502763 C19.8125,22.2990527 24.7528646,17.4568727 30.8255417,17.4568727 C36.8988542,17.4568727 41.8398542,22.29843 41.8398542,28.2502763 C41.8398542,34.2021225 36.8988542,39.0436798 30.8255417,39.0436798 Z"
                                    id="Shape" fillRule="nonzero"/>
                                <path
                                    d="M13.5551042,13.72 L7.90625,13.72 C6.85336458,13.72 6,14.5562973 6,15.588125 C6,16.6199527 6.85336458,17.45625 7.90625,17.45625 L13.5551042,17.45625 C14.6079896,17.45625 15.4613542,16.6199527 15.4613542,15.588125 C15.4613542,14.5562973 14.6079896,13.72 13.5551042,13.72 Z"
                                    id="Path"/>
                                <path
                                    d="M55.9166667,6.99176917 L44.1690833,6.99176917 L43.0501146,3.65156167 C42.3517917,1.56984771 40.1399063,0 37.903875,0 L23.096125,0 C20.8600938,0 18.6482083,1.56984771 17.9498854,3.65218438 L16.8309167,6.99239188 L5.08333333,6.99239188 C2.28051042,6.99239188 0,9.22729208 0,11.9740585 L0,43.5765065 C0,46.3232729 2.28051042,48.5581731 5.08333333,48.5581731 L55.9166667,48.5581731 C58.7194896,48.5581731 61,46.3232729 61,43.5765065 L61,11.9740585 C61,9.22666938 58.7194896,6.99176917 55.9166667,6.99176917 Z M57.1875,43.5758838 C57.1875,44.262731 56.6175312,44.8213004 55.9166667,44.8213004 L5.08333333,44.8213004 C4.38246875,44.8213004 3.8125,44.262731 3.8125,43.5758838 L3.8125,11.9734358 C3.8125,11.2865885 4.38246875,10.7280192 5.08333333,10.7280192 L18.2116771,10.7280192 C19.0351771,10.7280192 19.7659063,10.2093031 20.02325,9.44274917 L21.5723958,4.81727167 C21.7515833,4.2817425 22.5204375,3.73625 23.0954896,3.73625 L37.903875,3.73625 C38.4789271,3.73625 39.2477813,4.2817425 39.4269688,4.81727167 L40.9761146,9.44274917 C41.2328229,10.2099258 41.9641875,10.7280192 42.7876875,10.7280192 L55.9166667,10.7280192 C56.6175312,10.7280192 57.1875,11.2865885 57.1875,11.9734358 L57.1875,43.5758838 Z"
                                    id="Shape" fillRule="nonzero"/>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }

    static defaultProps = {
        width:'61px',
        height:'49px',
        color: '#ffffff'
    }
}
export default IconCamera;