import React, { useContext, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { Container } from "react-grid-system";
import { Element, HBox } from "react-stylesheet";
import { MdCheckCircle } from "react-icons/md";
import {
  selectCurrentAdventure,
  selectCurrentAdventureType,
} from "../../../../store/selectors";
import { AuthContext } from "../../../../providers/auth-provider";
import { LanguageContext, Translation } from "../../../../languages";
import {
  colors,
  font_family,
  font_size,
  gutters,
} from "../../../00_base/variables";
import { Button, Heading, Paragraph, Span } from "../../../01_atoms";
import FieldText from "../../../02_molecules/form-fields/text";
import FieldCheckbox from "../../../02_molecules/form-fields/checkbox";
import FieldTextArea from "../../../02_molecules/form-fields/textarea";
import { Form } from "../../../03_organisms";
import {
  deliveryFields,
  mainFormFields,
  selfCollectingFields,
} from "./main-form-fields";
import TranzilaIframe from "./tranzila-iframe";
import { API_URL, fetchData } from "../../../../store/actions/fetch-data";
import { PaymentSuccessMessage } from "./payment-success-message";
import { paymentDictionary } from "./payment-dictionary";
import { DICT } from "../../../../languages/dictionary-names";
import { FieldLabel } from "../../../02_molecules/form-fields/field-style/m-field-style";
import { ThemeContext } from "../../../../providers/theme-provider";
import { useParams } from "react-router-dom";

const CHANGE_FIELD = "CHANGE_FIELD";

const paymentReducer = (state, action) => {
  if (action.type === CHANGE_FIELD) {
    return {
      ...state,
      [action.name]: action.value,
    };
  }
};

const Payment = ({ adventureId, hideTitle }) => {
  const params = useParams();
  const id = adventureId || params?.id;
  const adventure = useSelector(selectCurrentAdventure);
  const adventureType = useSelector(selectCurrentAdventureType);

  const { isCreator, isAdmin, userData } = useContext(AuthContext);
  const { userLanguage, rtl, dictionary } = useContext(LanguageContext);

  const deliveryPrice =
    adventureType?.id === 31 || adventureType?.id === 40 ? 0 : 40;

  const fullName = userData.name + " " + userData.lastName;

  const defaultFormValue = {
    id: id,
    terms: false,
    deliveryType: adventureType?.id === 31 || adventureType?.id === 40 ? 1 : 0,
    selfCollection: "צור יצחק",
    calculated_price: null,
    boxesLength: 1,
    email: userData.email,
    city: null,
    full_name: fullName,
    street: null,
    apartment: null,
    notes: "",
  };
  const { mode } = useContext(ThemeContext);
  const [originalPrice, setOriginalPrice] = useState(
    parseInt(adventureType.base_price)
  );
  const [couponStatus, setCouponStatus] = useState("");
  const [couponDiscountValue] = useState(0);
  const [tranzillaActive, setTranzillaActive] = useState(false);
  const [adminPrice, setAdminPrice] = useState("");
  const [paymentForm, dispatchPaymentForm] = useReducer(
    paymentReducer,
    defaultFormValue
  );
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [validationsActive, setValidationsActive] = useState(false);

  const { delivery, deliveryAddress, confirmTerms, shekel } = paymentDictionary;

  const sum = adventureType.base_price;

  const main_fields = mainFormFields(
    paymentForm,
    validationsActive,
    adventureType,
    userLanguage,
    dictionary
  );
  const selfColecting_fields = selfCollectingFields(
    paymentForm,
    validationsActive,
    userLanguage
  );
  const delivery_fields = deliveryFields(
    paymentForm,
    validationsActive,
    userLanguage
  );

  const fieldsStyle = {
    // backgroundColor: colors.white,
    // borderRadius: 0,
    // border: 0
  };
  const { full_name, email, boxesLength, deliveryType, terms, notes } =
    paymentForm;
  const deliveryActive = deliveryType === `${deliveryAddress[userLanguage]}`;

  if (!id) {
    window.location.href = "/";
  }

  const handleFieldChange = (name, value) => {
    dispatchPaymentForm({
      type: CHANGE_FIELD,
      name: name,
      value: value,
    });
  };

  const handleSubmit = () => {
    if (full_name && email && boxesLength > 0 && terms) {
      if (adminPrice === "0" || sum === 0) {
        purchaseFree();
      } else {
        setTranzillaActive(true);
      }
    } else {
      setValidationsActive(true);
    }
  };

  const purchaseFree = () => {
    const data = {
      full_name: full_name,
      adventureId: adventure.id,
      email: email,
      passcode: adventure.passcode,
      sum: 0,
    };
    fetchData("post", "purchasef", data, (success) =>
      setPaymentSuccessful(true)
    );
  };

  const useCoupon = () => {
    const data = {
      coupon_name: couponCode,
      adventure_id: adventure.id,
      adventure_type: adventureType.id,
    };
    const url = `${API_URL}/use_coupon`;
    const token = localStorage.getItem("token");

    fetch(`${url}`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-Token": token,
      },
    })
      .then(
        (response) =>
          response.status >= 200 && response.status < 300 && response.json()
      )
      .then((success) => {
        if (success && success[0] && success[0].use_coupon) {
          setOriginalPrice(
            success[0].use_coupon === "0.00" ? 0 : success[0].use_coupon
          );
          setCouponStatus("used");
          // setCouponDiscountValue(success[0].use_coupon === "0.00" ? 0 : success[0].use_coupon);
        } else {
          setCouponStatus("notExist");
          setCouponCode("");
        }
      })
      .then((error) => console.log(error));
  };

  const getSummery = () => {
    const price = parseInt(parseInt(originalPrice).toFixed());
    // const boxesLength = 2;
    const discountedPrice = price - 50;
    let summery = "";
    let value = price;

    if (boxesLength > 1) {
      summery = `1 * ${price} + ${boxesLength - 1}  * ${discountedPrice}`;
      value = price + (boxesLength - 1) * discountedPrice;
    }
    return { value: value, summery: summery };
  };

  const price = `${
    adminPrice ||
    getSummery().value +
      (deliveryActive ? deliveryPrice : 0) -
      couponDiscountValue
  } `;

  return (
    <Container
      style={{
        padding: 0,
        textAlign: rtl ? "right" : "left",
        direction: rtl ? "rtl" : "ltr",
      }}
    >
      {!hideTitle && <Heading tag="h1" value={adventure.title} />}
      <Element maxWidth="60rem" marginBottom={gutters.sm}>
        <Heading
          tag="h4"
          className="color-black"
          style={{ fontFamily: font_family.medium }}
        >
          <Translation id={DICT.PAYMENT_MAIN_TITLE} />
        </Heading>
        <HBox
          justifyContent={"space-between"}
          flexDirection={"row"}
          background={colors.white}
          marginBottom={boxesLength > 1 ? 0 : gutters.lg}
          padding={`${gutters.md} ${gutters.sm}`}
        >
          {rtl ? (
            <Span value={getSummery().value} fontSize={font_size.sm} />
          ) : (
            <Span value={`${adventure.title}`} fontSize={font_size.sm} />
          )}

          <Span fontSize={font_size.sm}>
            {price} <Translation id={DICT.SHEKEL} />
          </Span>
        </HBox>

        {boxesLength > 1 && (
          <>
            <Paragraph textAlign="left">
              <Translation
                id={
                  adventureType.id === 31
                    ? DICT.FIRST_ADVENTURE
                    : DICT.FIRST_BOX
                }
              />
              {` - ${originalPrice.toFixed()} `}
              <Translation id={DICT.SHEKEL} />
            </Paragraph>
            <Paragraph textAlign="left">
              {`${boxesLength - 1} `}
              <Translation
                id={
                  adventureType.id === 31
                    ? DICT.OTHER_ADVENTURES
                    : DICT.OTHER_BOXES
                }
              />
              {` - ${(originalPrice.toFixed() - 50) * (boxesLength - 1)} ${
                shekel[userLanguage]
              }`}
            </Paragraph>
          </>
        )}

        {deliveryActive && (
          <Paragraph textAlign="left">
            {`${delivery[userLanguage]} - ${deliveryPrice} `}
            <Translation id={DICT.SHEKEL} />
          </Paragraph>
        )}

        {couponDiscountValue > 0 && (
          <Paragraph textAlign="left">
            {couponDiscountValue} <Translation id={DICT.SHEKEL} />{" "}
            <Translation id={DICT.COUPON_DISCOUNT} />
          </Paragraph>
        )}

        {isAdmin && !isCreator && (
          <FieldText
            type="number"
            label={<Translation id={DICT.INSERT_CUSTOM_PRICE} />}
            controlStyle={{ marginBottom: gutters.md }}
            inputStyle={fieldsStyle}
            name="admin-price"
            changeHandler={(name, value) => setAdminPrice(value)}
          />
        )}

        <Form
          fields={main_fields}
          fieldsStyle={fieldsStyle}
          hideSubmitButton={true}
          handleFieldChange={handleFieldChange}
        />

        {adventureType.id !== 31 && adventureType.id !== 263 && (
          <Heading
            tag="h4"
            className={"color-black"}
            style={{ fontFamily: font_family.medium }}
          >
            <Translation id={DICT.CUSTOMER_DETAILS} />
          </Heading>
        )}

        <Element style={{ margin: `0 -${!deliveryActive ? gutters.sm : 0}` }}>
          {adventureType.id !== 31 && adventureType.id !== 263 && (
            <Form
              hideSubmitButton={true}
              key={deliveryActive}
              fieldsStyle={fieldsStyle}
              fields={deliveryActive ? delivery_fields : selfColecting_fields}
              formStyle={{ width: "100%", display: "flex", flexWrap: "wrap" }}
              handleFieldChange={handleFieldChange}
            />
          )}

          <div style={{ padding: `0 ${gutters.md}` }}>
            <FieldLabel className="color-black" style={{ position: "static" }}>
              <Translation id={DICT.COMMENTS} />
            </FieldLabel>
            <FieldTextArea
              changeHandler={handleFieldChange}
              name={"notes"}
              rows={3}
              value={notes}
            />
          </div>
        </Element>

        <HBox flexDirection={"row"} paddingTop={gutters.sm}>
          <FieldText
            label={<Translation id={DICT.COUPON_INSTRUCTION} />}
            controlStyle={{
              maxWidth: "70%",
              marginBottom: "0",
              paddingLeft: rtl ? gutters.sm : 0,
              paddingRight: !rtl ? gutters.sm : 0,
            }}
            name="coupon_code"
            value={couponCode}
            errorMessageActive={couponStatus === "notExist"}
            errorMessage={<Translation id={DICT.COUPON_NOT_EXIST} />}
            warningStyle={{ textAlign: rtl ? "right" : "left" }}
            changeHandler={(name, value) => {
              setCouponStatus("");
              setCouponCode(value);
            }}
          />

          <div>
            {couponStatus === "used" && (
              <MdCheckCircle color={colors.success} size={35} />
            )}

            {(!couponStatus || couponStatus === "notExist") && (
              <Button
                value={<Translation id={DICT.SEND} />}
                style={{ whiteSpace: "nowrap" }}
                onClick={useCoupon}
              />
            )}
          </div>
        </HBox>

        <HBox
          xs={12}
          marginBottom="20px"
          className="form-item form-item-terms"
          flexDirection="row"
        >
          <label style={{ cursor: "pointer", display: "flex" }}>
            <FieldCheckbox
              name="terms"
              options={[confirmTerms[userLanguage]]}
              value={terms ? [confirmTerms[userLanguage]] : []}
              borderColor={terms ? "#151515" : "red"}
              inputWrapperStyle={
                !rtl && { marginLeft: 0, marginRight: gutters.sm }
              }
              changeHandler={(name, value) => {
                dispatchPaymentForm({
                  type: CHANGE_FIELD,
                  name: name,
                  value: value,
                });
              }}
            />
            <span
              style={{
                position: "relative",
                fontSize: font_size.sm,
                padding: "0 6px",
              }}
            >
              <a
                target={"blank"}
                href={"/terms"}
                style={{
                  textDecoration: "underline",
                  whiteSpace: "nowrap",
                  color: mode === "dark" && "#fff",
                }}
              >
                <Translation id={DICT.TERMS} />
              </a>
            </span>
          </label>
        </HBox>

        {tranzillaActive && !!terms ? (
          <TranzilaIframe
            {...paymentForm}
            {...adventure}
            adventureId={id}
            singleBoxPrice={originalPrice}
            deliveryType={deliveryActive ? 1 : 0}
            sum={price}
          />
        ) : (
          <Button onClick={handleSubmit}>
            {!parseInt(price.replace(shekel[userLanguage], "")) ? (
              <Translation id={DICT.FINISH_ORDER} />
            ) : (
              <Translation id={DICT.GO_TO_PAYMENT} />
            )}
          </Button>
        )}

        {validationsActive && !terms && (
          <p style={{ fontSize: "16px" }}>
            <Translation id={DICT.TERMS_WARNING} />
          </p>
        )}
        {paymentSuccessful && (
          <PaymentSuccessMessage
            adventuretype={adventureType}
            passcode={adventure.passcode}
          />
        )}
      </Element>
    </Container>
  );
};

export default Payment;
