import theme from "../theme/theme";


/* Main colors */
const main = {
    primary: theme && theme ? theme.main : '#279ee6' || '#279ee6',
    primary_light: theme && theme ? theme.main : '#279ee6' || '#279ee6',
    primary_dark: theme && theme ? theme.main : '#279ee6' || '#279ee6',
    primary_hover: theme && theme ? theme.main : '#279ee6' || '#279ee6',
    secondary: theme && theme ? theme.sub : '#368b7f' || '#368b7f',
    secondary_light: theme && theme ? theme.sub : '#368b7f' || '#368b7f',
    secondary_dark: theme && theme ? theme.sub : '#29665d' || '#29665d',
    secondary_hover: theme && theme ? theme.sub : '#368b7f' || '#368b7f',
};


/* Shades */
const shades = {
    white: '#ffffff',
    gray_1: '#e8e7e8',
    gray_2: '#d1d0d2',
    gray_3: '#bbbbbc',
    gray_4: '#a5a5a6',
    gray_6: '#7b7a7d',
    gray_10: '#2e2e31',
    black: '#000000'
};

/* Feedback and Action colors  */
const feedbacks = {
    success: 'green',
    success_light: 'green',
    success_dark: 'green',
    success_hover: 'green',
    error: 'orange',
    error_light: 'orange',
    error_dark: 'orange',
    error_hover: 'orange',
    warning: 'yellow',
    warning_light: 'yellow',
    warning_dark: 'yellow',
    warning_hover: 'yellow',

    action:theme && theme ? theme.buttons : 'yellow' || 'yellow',
    action_light: theme && theme ? theme.buttons : 'yellow' || 'yellow',
    action_dark: theme && theme ? theme.buttons : 'yellow' || 'yellow',
    action_hover: theme && theme ? theme.buttons : 'yellow' || 'yellow'
};

/* typography */
const typography = {
    text: shades.gray_10,
    text_heading: shades.black,
    text_subtitle: shades.gray_6,
    link: main.primary,
    link_visited: main.primary_dark
};

/* border */
const borders = {
    border: shades.gray_2
};

/* body */
const body = {
    body: shades.white
};

/* variants */
const variants = {
    primary: {color: shades.white, backgroundColor: main.primary, colorHover:shades.gray_1, backgroundColorHover: main.primary_hover},
    secondary: {color: shades.white, backgroundColor: main.secondary, colorHover:shades.gray_1, backgroundColorHover: main.secondary_hover},
    success: {color: shades.white, backgroundColor: feedbacks.success, colorHover:shades.gray_1, backgroundColorHover: feedbacks.success_hover},
    error: {color: shades.white, backgroundColor: feedbacks.error, colorHover:shades.gray_1, backgroundColorHover: feedbacks.error_hover},
    warning: {color: shades.black, backgroundColor: feedbacks.warning, colorHover:shades.gray_1, backgroundColorHover: feedbacks.warning_hover},
    disable: {color: shades.gray_6, backgroundColor: shades.gray_2},
    link: {color: typography.link, backgroundColor: 'transparent', colorHover:shades.gray_1, backgroundColorHover: typography.link_visited},
};

/* layout colors */
const layoutColors = {
    headerTitle: shades.black,
};
const lightOrDark = (color) => {

    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
    }
    else {

        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace(
            color.length < 5 && /./g, '$&$&'));

        r = color / 16;
        g = color / 256 & 255;
        b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp>127.5) {

        return 'light';
    }
    else {

        return 'dark';
    }
};
export const colors = {
    ...main,
    ...shades,
    ...feedbacks,
    ...typography,
    ...borders,
    ...body,
    ...layoutColors,
    lightOrDark,
    variants
};