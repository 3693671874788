class Adventure {
    constructor(
        id,
        title,
        adventure_name,
        adventuretype,
        adventuredata_id,
        identifier,
        is_generic,
        is_main,
        is_paid,
        is_published,
        json_data,
        passcode,
        thumbnail,
        user_email,
        yearend_csv_batch,
        yearend_email_send,
        calculated_price,
        language,
        created_at,
    ) {
        this.id = id;
        this.title = title;
        this.adventure_name = adventure_name;
        this.adventuretype = adventuretype;
        this.adventuredata_id = adventuredata_id;
        this.identifier = identifier;
        this.is_generic = is_generic;
        this.is_main = is_main;
        this.is_paid = is_paid;
        this.is_published = is_published;
        this.json_data = json_data;
        this.passcode = passcode;
        this.thumbnail = thumbnail;
        this.user_email = user_email;
        this.yearend_csv_batch = yearend_csv_batch;
        this.yearend_email_send = yearend_email_send;
        this.calculated_price = calculated_price;
        this.language = language;
        this.created_at = created_at;
    }
}

export default Adventure;