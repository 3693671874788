import React from 'react';
import PropTypes from 'prop-types';
import {Col, Row} from 'react-grid-system'

const Grid = ({
                  children,
                  items,
                  gutterWidth,
                  rowMargin,
                  cellSizes,

              }) => {
    const cells = children || items;

    return (
        <Row className={'flex-wrap'} gutterWidth={gutterWidth}>
            {cells.map((cell, index) => <Col key={index} {...cellSizes}> {cell} </Col>)}
        </Row>
    )
};

Grid.defaultProps = {
    items: [],
    cellSizes: {xs: 12, sm: 6, md: 4},
    fluid: false,
    columns: 12,
    gutterWidth: 10
};

Grid.propTypes = {
    items: PropTypes.array,
    children: PropTypes.array,
    cellSizes: PropTypes.object,
    fluid: PropTypes.bool,
    columns: PropTypes.oneOf([12, 24]),
    gutterWidth: PropTypes.number
};

export default Grid;