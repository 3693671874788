import React from 'react';

class IconUpload extends React.Component {
    render() {
        const {size, color, style, onClick} = this.props;
        return (
            <svg width={size} height={size} viewBox="0 0 15 15" style={style && style} onClick={onClick && onClick}>
                <g id="עיצוב" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="upload-black" fill={color}>
                        <polygon id="Fill-1" points="12.6483032 10.7142857 12.6483032 12.8367347 2.3521406 12.8367347 2.3521406 10.7142857 0 10.7142857 0 15 15 15 15 10.7142857"/>
                        <polygon id="Fill-2" points="7.52299203 0 2.14285714 5.22208884 6.3274065 5.22208884 6.3274065 10.7142857 8.71857756 10.7142857 8.71857756 5.22208884 12.8571429 5.22208884"/>
                    </g>
                </g>
            </svg>
        )
    }
    static defaultProps = {
        color: '#151515',
        size: '15px',
    }
}

export default IconUpload;
