import React from "react";
import {FilesUploader} from "../../02_molecules";
import {Col, Row} from "react-grid-system";

export const GameLobbyUi = (
    {
        lobby_image_desktop,
        // lobby_image_tablet,
        lobby_image_mobile,
        handleChange
    }
) => {
    const Trigger = ({title, subtitle}) => {
        return (
            <div>
                <p>{title}</p>
                <p>{subtitle}</p>
            </div>
        )
    }
    const triggerStyle = {
        backgroundColor: "#fff",
        border: "2px dashed #bbbbbb",
    }

    return (
        <Row className="adventure-type-game-images">
            <Col>
                <FilesUploader name={'lobby_image_desktop'}
                               value={lobby_image_desktop}
                               label={"תמונת רקע דסקטופ"}
                               previewWidth={'33rem'}
                               previewHeight={'14rem'}
                               multiple={false}
                               shrinkImage={false}
                               triggerValue={<Trigger title={"תמונת רקע דסקטופ"} subtitle={"1920*400"}/>}
                               triggerStyle={{...triggerStyle, width: "33rem", height: "14rem"}}
                               changeHandler={handleChange}
                               controlStyle={{paddingRight: 0, width: "33rem", height: "14rem"}}/>
            </Col>
            <Col>
                <FilesUploader name={'lobby_image_mobile'}
                               value={lobby_image_mobile}
                               label={"תמונת רקע מובייל"}
                               multiple={false}
                               shrinkImage={false}
                               previewWidth={'15rem'}
                               previewHeight={'17rem'}
                               triggerValue={<Trigger title={"תמונת רקע מובייל"} subtitle={"360*480"}/>}
                               triggerStyle={{...triggerStyle, width: "15rem", height: "17rem"}}
                               changeHandler={handleChange}
                               controlStyle={{width: "15rem", height: "17rem"}}/>
            </Col>
        </Row>
    )
};