import {API_URL, fetchData} from "./fetch-data";
import {adventureDataObject, adventureObject, adventureTypeObject, riddlesObject} from "../models/build-models";
import {createAdventureData, setSingleAdventureData} from "./adventure-data-actions";
import {getSingleFrame} from "./frames-actions";
import {duplicateRiddles} from "./riddle-actions";

const api = API_URL;
const extension = 'adventures';
const extension_2 = 'adventure2';
const url = `${api}/${extension}`;

const token = localStorage.getItem("token");

export const SET_ADVENTURES = 'SET_ADVENTURES';
export const SET_LOGIN_USER_ADVENTURES = 'SET_USER_LOGIN_USER_ADVENTURES';
export const SET_SINGLE_ADVENTURE = 'SET_SINGLE_ADVENTURE';
export const CREATE_ADVENTURE = 'CREATE_ADVENTURE';
export const UPDATE_ADVENTURE = 'UPDATE_ADVENTURE';
export const DELETE_ADVENTURE = 'DELETE_ADVENTURE';

export const getAdventure = (adventureId, cb) => {
    fetchData(
        "get",
        `${extension}/${adventureId}`,
        {},
        response => {
            const adventure = adventureObject(response);
            cb(adventure);
        }
    )
};

export const getAdventuresByEmail = async (pageIndex = 0, filterByEmail, cb) => {

    await fetchData(
        "get",
        `adventures-by-email?page=${pageIndex}&email=${filterByEmail}`,
        {},
        response => {
            let allAdventures = [];
            for (const key in response) {
                const adventure = response[key];
                allAdventures.push(adventureObject(adventure))
            }
            if (cb) {
                cb(allAdventures)
            }
        }
    )
}

export const setAllAdventures = (pageIndex = 0, filterBy, filterValue) => {
    return async (dispatch, getState) => {
        let url;
        if (!!filterBy) {
            url = `adventures-by-${filterBy}?page=${pageIndex}&${filterBy}=${filterValue}`;
        } else {
            url = `${extension}/?page=${pageIndex}`;
        }

        await fetchData(
            'get',
            url,
            {},
            response => {
                let allAdventures = [];
                for (const key in response) {
                    const adventure = response[key];
                    allAdventures.push(adventureObject(adventure))
                }
                dispatch({
                    type: SET_ADVENTURES,
                    allAdventures: allAdventures
                });
            }
        )
    }
};

export const setLoginUserAdventures = (email, pageIndex = 0, cb) => {
    return async (dispatch) => {
        await getAdventuresByEmail(
            pageIndex,
            email,
            userAdventures => {
                dispatch({
                    type: SET_LOGIN_USER_ADVENTURES,
                    adventures: userAdventures || [],
                    pageIndex: pageIndex
                })
                if (cb) {
                    cb(userAdventures)
                }
            });
    }
};

export const setSingleAdventure = (adventureId, cb) => {
    return async (dispatch) => {
        await fetchData(
            "get",
            `getAdvenureWithDataAndRiddles/${adventureId}`,
            "",
            response => {
                const adventure = adventureObject(response.adventure);
                const adventureData = adventureDataObject(response.adventureData);
                const riddles = riddlesObject(response.adventureData.riddles.map(riddleId => response.riddles.find(riddle => riddleId === riddle.id)));
                const adventureType = adventureTypeObject(response.adventureType);

                Promise.all(riddles.map(riddle => riddle.riddlestemplates).flat().map(getSingleFrame)).then(
                    frames => {
                        dispatch({
                            type: SET_SINGLE_ADVENTURE,
                            adventure: adventure,
                            adventureData: adventureData,
                            riddles: riddles,
                            adventureType: adventureType,
                            frames: frames
                        });
                        if (cb) {
                            cb(adventure, adventureType, adventureData, riddles, frames)
                        }
                        dispatch(setSingleAdventureData(response.adventure.adventuredata_id));
                    }
                );

            }
        )
    }
};

export const duplicateAdventure = (adventureId, cb) => {
    return async (dispatch) => {
        dispatch(
            setSingleAdventure(
                adventureId,
                (adventure, adventureType, adventureData, riddles, frames) => {
                    dispatch(
                        duplicateRiddles(
                            riddles,
                            frames,
                            (newRiddles) => {
                                dispatch(
                                    createAdventureData(
                                        {...adventureData, riddles: newRiddles.map(riddle => riddle.id)},
                                        newAdventureData => {
                                            getAdventure(
                                                adventureId,
                                                adventure => {
                                                    dispatch(
                                                        createAdventure(
                                                            {...adventure, adventuredata_id: newAdventureData.id}
                                                        ))
                                                    if (cb) {
                                                        cb(adventure)
                                                    }
                                                }
                                            )
                                        }
                                    )
                                )
                            }
                        )
                    )
                }
            ))
    }
};

export const createAdventure = (adventure, cb) => {
    return async (dispatch) => {
        await fetchData(
            'post',
            extension_2,
            {...adventure, adventuretype: adventure.adventuretype || adventure.adventureType},
            success => {
                dispatch({
                    type: CREATE_ADVENTURE,
                    adventure: adventureObject(success.data)
                    
                });
                if (cb) {
                    cb(success.data)
                }
            }
        )
    }
};

export const updateAdventure = (adventure,id, callback) => {
    return async (dispatch) => {
        await fetchData(
            "put", `${extension}/${id}`, adventure,
            success => {
                dispatch({type: UPDATE_ADVENTURE, ...success.data});
                if (callback) {
                    callback(success.data)
                }
            }
        )
    };
};

export const deleteAdventure = adventureId => {
    return async (dispatch) => {
        await fetch(`${url}/${adventureId}`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "access-Token": token
            }
        }).then(
            response => response.json()
        ).then(
            success => {
                dispatch({type: DELETE_ADVENTURE, id: adventureId})
                return success
            }
        )
    }
};