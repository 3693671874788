import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import {Col, Container, Row} from 'react-grid-system';
import {Link} from "react-router-dom";
import {AuthContext} from "../../../../providers/auth-provider";
import {Birds, Fireworks, Moon, StarsBackground, Sun, Sunset} from "../../../03_organisms";
import {getAdventure} from "../../../../store/actions/adventure-actions";
import FieldText from "../../../02_molecules/form-fields/text/m-field-text";
import {fetchData} from "../../../../store/actions/fetch-data";
import Button from "../../../01_atoms/button/a-button";
import "../../../../assets/styles/screen-dashboard.scss";
import {getYoutubeId, getYoutubeUrl} from "../../../../helpers/youtube-helper";

const ScreenDashboard = () => {
    const {userData, isLogin} = useContext(AuthContext);
    const {name} = userData;
    const [adventure, setAdventure] = useState(null);
    const [dailySong, setDailySong] = useState(null);

    useEffect(() => {
        getAdventure(5660, (res) => {
            setAdventure(res);
            setDailySong(res?.json_data?.opening_video_url)
        })
    }, []);

    const menu = [
        {label: "הרפתקאות", path: "adventures"},
        {label: "הזמנות", path: "reservations"},
        {label: "תבניות", path: "adventure-types"},
        {label: "לקוחות", path: "customers"},
        {label: "כלים דיגיטליים", path: "categories"},
        {label: "בלוג", path: "blogs"},
        {label: "חידות", path: "riddles"},
        {label: "תרגומים", path: "translations"},
        {label: "לאתר", path: "website"},
    ];

    let today = new Date();
    let curHr = today.getHours();
    const isNight = curHr >= 23 || curHr < 6;
    const isMorning = curHr >= 6 && curHr < 12;
    const isNoon = curHr >= 12 && curHr < 18;
    const isEvening = curHr >= 18 && curHr < 23;

    let greeting = isNight ? NIGHT : isMorning ? MORNING : isNoon ? NOON : EVENING;

    if (!isLogin) {
        window.location.href = "/he/login";
        return <div></div>
    }



    const videoId = dailySong && getYoutubeId(dailySong);
    const youtubeUrl = getYoutubeUrl(videoId);
    const IframeYoutube = () => {
        return <iframe width="560"
                       height="315"
                       src={youtubeUrl}
                       frameBorder="0"
                       allowFullScreen/>
    };
    const changeDailySong = async (name, url) => {
        await fetchData(
            "put",
            `adventures/5660`,
            {...adventure, json_data: {opening_video_url: url}},
            success => setDailySong(url)
        )
    };

    return (
        <div className={classNames("dashboard", {isMorning, isEvening, isNoon, isNight})}>
            {isEvening && <Sunset/>}
            {isNight && <StarsBackground/>}
            <Fireworks style={{top: 0, left: 0}}/>

            {(isMorning || isNoon) && <><Sun/><Birds/></>}
            {isEvening && <Birds/>}
            {isNight && <Moon/>}

            <Container>
                <h1 className="dashboard-title">{`היי ${name}`}</h1>
                <span className="dashboard-greeting">{greeting}</span>
                <p className="dashboard-question">במה בא לך לצפות היום?</p>
                <Row className="dashboard-menu">
                    {menu.map((menuItem, index) => {
                        const path = menuItem.path === "website" ? "/" : `/he/admin/${menuItem.path}`;
                        return (
                            <Col key={index} sm={index === menu.length - 1 ? 12 : 6} md={4} className="dashboard-menu-item">
                                <Link className="dashboard-menu-item-link" to={path}>
                                    <div className="dashboard-menu-item-container">
                                        <p>{menuItem.label}</p>
                                    </div>
                                </Link>
                            </Col>
                        )
                    })}
                </Row>
                {dailySong &&
                <div className={"daily-song"}>
                    <p>בינתיים תוכלו להסתכל על הזיקוקים או להאזין לשיר היומי:</p>
                    <div className="d-flex justify-content-center mb-3">
                        <IframeYoutube/>
                    </div>
                    <h4 className={"mb-0"}>לבחירת השיר היומי, הכנס קישור מ<a href={dailySong}
                                                                             target={"_blank"}>יוטיוב</a></h4>
                    <div className={"d-flex align-items-center pb-5"}>
                        <FieldText name={"בחר את השיר היומי"}
                                   changeHandler={changeDailySong}
                                   controlStyle={{paddingLeft: "20px", marginBottom: 0}}
                                   value={dailySong}/>
                        <Button>שמור</Button>
                    </div>
                </div>
                }
            </Container>
        </div>
    )
};

const NIGHT = "לילה טוב", EVENING = "ערב טוב", NOON = "צהריים טוב", MORNING = "בוקר טוב";

ScreenDashboard.default = {
    title: 'ברוכים הבאים',
};
ScreenDashboard.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

export default ScreenDashboard;