import React from 'react';
import {fieldStyle} from '../field-style/m-field-style';


const FieldComponent = ({
    name,
    changeHandler,
    value
}) => {
    const ComponentAdvanced = value;
    
    return (
        <div style={fieldStyle.control}>
            <ComponentAdvanced isChild={true} changeHandler={changeHandler}/>
        </div>
    )
};

FieldComponent.defaultProps = {
    type: 'component',
};

FieldComponent.propTypes = {
    
};

export default FieldComponent; 