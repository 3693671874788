import React from "react";
import {Col, Row} from "react-grid-system";
import {FieldSelect, FieldText} from "../../../../02_molecules";

export const CssCreateBorder = ({borderWidth, borderRadius, borderStyle="solid", labelStyle, inputStyle, changeHandler}) => {
     return (
         <Row>
            <Col xs={12}>
                <label className="general-css-form-subtitle">מסגרת (border)</label>
            </Col>
            <Col>
                <FieldText label="עובי"
                           type="number"
                           name="borderWidth"
                           value={borderWidth}
                           changeHandler={changeHandler}/>
            </Col>
            <Col>
                <FieldText label="פינות מעוגלות (%)"
                           type="number"
                           name="borderRadius"
                           value={borderRadius}
                           changeHandler={changeHandler}/>
            </Col>
            <Col>
                <FieldSelect label="סגנוןן"
                             name="borderStyle"
                             labelStyle={{marginBottom: "8px", display: "block"}}
                             value={{id: borderStyle, value: borderStyle, label: borderStyle}}
                             options={["dotted", "dashed", "solid", "double", "groove", "ridge", "inset", "outset"].map(item => {
                                 return {id: item, value: item, label: item}
                             })}
                             changeHandler={(name, value) => {
                                 changeHandler(name, value.label)
                             }}
                             customControlStyle={{
                                 display: "inline-flex",
                                 height: "30px"
                             }}
                             inputStyle={{height: "30px"}}/>
            </Col>
        </Row>
    )
}