import React, {useContext, useEffect, useState} from "react";
import {Container} from "react-grid-system";
import classNames from "classnames";
import {GallerySection} from "./gallery-section";
import Image from "../../01_atoms/image/a-image";
import {FrameContext} from "../../05_screens/tool/screen-tool";
import "../../../../../../assets/styles/gallery-images.scss";

export const ToolGalleryImages = (
    {gallery_view, items = [], item_style, indicatorStyle, arrowColor, arrowStyle}
) => {
    const {handleFrameFinish, toolPassword, isFrameFinish} = useContext(FrameContext);

    const itemsLength = items.length;
    const [activeItem, setActiveItem] = useState(itemsLength > 1 ? -1 : 0);

    useEffect(() => {
        if (!isFrameFinish && !toolPassword) {
            handleFrameFinish();
        }
    }, [handleFrameFinish, isFrameFinish]);

    const GalleryIcons = () => {
        return (
            <div className={classNames("tool-gallery-images-icons", {active: activeItem >= 0})}>
                {items.map((item, index) => {
                    if (!item.icon) {
                        return <div/>
                    }
                    return <div className={classNames("tool-gallery-icon", {active: activeItem === index})}
                                onClick={() => setActiveItem(index)}
                                key={index}>
                        <Image src={item.icon}/>
                    </div>
                })}
            </div>
        )
    };

    const renderGallerySection = (item, index) => {
        return <GallerySection {...item}
                               itemStyle={item_style}
                               view={gallery_view}
                               active={activeItem === index}
                               indicatorStyle={indicatorStyle}
                               arrowColor={arrowColor}
                               arrowStyle={arrowStyle}
                               key={index}/>;
    };

    return (
        <Container className="tool-gallery-images">
            {items.some(item => item.icon) && <GalleryIcons/>}
            {items.map(renderGallerySection)}
        </Container>
    )
};