import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {gutters} from '../../../../00_base/variables';
import {FilesUploader} from '../../../../02_molecules';
import {CreateAdventureContext} from "../../../pages/adventure-create/screen-adventure-create";

const checkIsValid = ({singular_images = 0, plural_images = 0}) => {
    const expected = 10;
    const length = singular_images.length + plural_images.length;
    return length / expected * 100
};
const CreateToolTreasure = (
    {
        toolData,
        handleToolDataChange,
    }) => {

    const adventureContext = useContext(CreateAdventureContext);
    const adventure_name = adventureContext.adventure_name;
    const handleChange = (name, value) => {
        handleToolDataChange(checkIsValid, name, value);
    };

    // useEffect(() => {
    //     checkIfValid(checkIsValid);
    // }, []);

    const {singularLabel, pluralLabel, singular_images, plural_images} = toolData; // user_images, treasure_images,

    return (
        <div className={'create-treasure'}>
            <FilesUploader minItems={6}
                           maxItems={6}
                           expectedLength={6}
                           backgroundSize={"cover"}
                           onFileSelect={files => console.log(files)}
                           multiple={true}
                           name={'singular_images'}
                           controlStyle={{marginBottom: gutters.sm}}
                           previewStyle={{height: '100px', marginTop: 0, marginBottom: 0}}
                           value={singular_images}
                           triggerStyle={{width:"auto", minWidth:"100px", padding:`0 ${gutters.md}`}}
                           label={singularLabel ? singularLabel.replace("{name}", adventure_name) : "העלו 6 תמונות"}
                           triggerValue={singularLabel ? singularLabel.replace("{name}", adventure_name) : "העלו 6 תמונות"}
                           notModal={true}
                           changeHandler={handleChange}
                           setIsFileLoading={data => console.log('data', data)}
                           fileUploaded={handleChange}/>

            <FilesUploader minItems={4}
                           maxItems={4}
                           expectedLength={4}
                           onFileSelect={files => console.log(files)}
                           multiple={true}
                           name={'plural_images'}
                           backgroundSize={"cover"}
                           controlStyle={{marginBottom: gutters.sm}}
                           triggerStyle={{width:"auto", minWidth:"100px", padding:`0 ${gutters.md}`}}
                           previewStyle={{height: '100px', width:"auto", marginTop: 0, marginBottom: 0}}
                           value={plural_images}
                           // hideTrigger={true}
                           label={pluralLabel ? pluralLabel.replace("{name}", adventure_name) : "העלו 4 תמונות"}
                           triggerValue={pluralLabel ? pluralLabel.replace("{name}", adventure_name) : "העלו 4 תמונות"}
                           notModal={true}
                           changeHandler={handleChange}
                           setIsFileLoading={data => console.log('data', data)}
                           fileUploaded={handleChange}/>

        </div>
    )
};

CreateToolTreasure.defaultProps = {
    submitHandler: (toolData) => console.log('toolData', toolData)
};

CreateToolTreasure.propTypes = {
    toolData: PropTypes.object,
    submitHandler: PropTypes.func
};


export default CreateToolTreasure;


// ************* updateIdomoImages ******************* //
//
// const updateIdomoImages = () => {
//     const {singular_images, plural_images} = toolData;
//
//     const idomoObject = {
//         "Image 1": singular_images[0],
//         "Image 2": singular_images[1],
//         "Image 3": plural_images[0],
//         "Image 4": plural_images[1],
//         "Image 5": singular_images[2],
//         "Image 6": singular_images[3],
//         "Image 7": singular_images[4],
//         "Image 8": plural_images[2],
//         "Image 9": plural_images[3],
//         "Image 10": singular_images[5],
//         "Text End": adventureName
//     };
//
//     dispatch(
//         adventureDataActions.updateAdventureDataIdomo(
//             adventureDataId,
//             idomoObject,
//             success => console.log(success)
//         )
//     )
// };
//
