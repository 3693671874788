class Category {
    constructor(id, title, category_name, icon, description, description_short, mock_data, created_at) {
        this.id = id;
        this.title = title;
        this.category_name = category_name;
        this.icon = icon;
        this.description = description;
        this.description_short = description_short;
        this.mock_data = mock_data;
        this.created_at = created_at;
    }
}

export default Category;