import React, {useCallback, useReducer, useState} from 'react';
import PropTypes from "prop-types";
import {Button} from '../../01_atoms';
import {
    FieldCheckbox,
    FieldColor,
    FieldComponent,
    FieldRadio,
    FieldSelect,
    FieldSelectMulti,
    FieldText,
    FieldTextArea,
    FieldVideo,
    FilesUploader
} from '../../02_molecules';
import DragDrop from '../drag-drop/o-drag-drop';

const UPDATE_INPUT = 'UPDATE_INPUT';

const formReducer = (state, action) => {
    if (action.type === UPDATE_INPUT) {
        const updatedValues = {
            ...state.inputValues,
            [action.input]: action.value
        };
        const updateValiditeis = {
            ...state.inputValidities,
            [action.input]: action.isValid
        };
        let updatedFormIsValid = true;
        for (const key in updateValiditeis) {
            updatedFormIsValid = updatedFormIsValid && updateValiditeis[key]
        }

        return {
            inputValues: updatedValues,
            inputValidities: updateValiditeis,
            formIsValid: updatedFormIsValid,
        }
    }
    return state;
};

const initialValues = (fields) => {
    let fieldsData = {};
    for (const key in fields) {
        fieldsData = {
            ...fieldsData,
            [fields[key].name]: fields[key].value
        }
    }
    return fieldsData;
}

const initialValidities = (fields) => {
    let fieldsData = [];
    for (const key in fields) {
        fieldsData = {
            ...fieldsData,
            [fields[key].name]: !!fields[key].name
        }
    }
    return fieldsData;
}

const getFieldComponent = type => {
    switch (type) {
        case 'text':
            return FieldText;
        case 'color':
            return FieldColor;
        case 'select':
            return FieldSelect;
        case 'multi-select':
            return FieldSelectMulti;
        case 'radio':
            return FieldRadio;
        case 'checkbox':
            return FieldCheckbox;
        case 'textarea':
            return FieldTextArea;
        case 'files':
            return FilesUploader;
        case 'video':
            return FieldVideo;
        case 'dragDrop':
            return DragDrop;
        case 'component':
            return FieldComponent
        default:
            return FieldText;
    }
};

const Form = ({
                  fields,
                  formStyle,
                  handleFormSubmit,
                  submitLabel,
                  submitBlock,
                  className,
                  mode,
                  handleFieldChange = (name, value, isValid) => {
                  },
                  submitStyle,
                  componentBeforeSubmitButton,
                  hideSubmitButton
              }) => {
    const [isLoading, setIsLoading] = useState();
    const [error, setError] = useState();
    const [formState, dispatchFormState] = useReducer(formReducer, {
        inputValues: initialValues(fields),
        inputValidities: initialValidities(fields),
        formIsValid: !!fields
    });

    const inputChangedHandler = useCallback((name, value, isValid) => {

        dispatchFormState({
            type: UPDATE_INPUT,
            value: value,
            isValid: isValid,
            input: name
        });

        handleFieldChange(name, value, isValid);
    }, [dispatchFormState, handleFieldChange]);

    const formSubmitHandler = useCallback(async (callback) => {
        // if (!formState.formIsValid) {
        //     alert('Form is not valid. Please check your fields');
        //     return;
        // }

        setError(null);
        setIsLoading(true);

        handleFormSubmit(
            formState.inputValues,
            (success) => handleSubmitSuccess(success, callback),
            handleSubmitError
        );
    }, [handleFormSubmit, formState]);

    const handleSubmitSuccess = (success, callback) => {
        setIsLoading(false);
        if (typeof callback === "function") {
            callback(success);
        }
    };

    const handleSubmitError = () => {
        setIsLoading(false);
        setError(true);
    }

    const renderField = (field, index) => {
        const Field = getFieldComponent(field.type);
        return (
            <Field
                {...field}
                style={field.fieldStyle}
                key={index}
                mode={mode}
                isSaving={isLoading}
                changeHandler={field.changeHandler || inputChangedHandler}
                initialValue={formState.inputValues[field.name]}
                initiallyValid={formState.inputValidities[field.name]}
                formSubmitHandler={formSubmitHandler}/>
        )
    };

    const ComponentAdvanced = componentBeforeSubmitButton;
    return (
        <div style={{width: '100%', ...formStyle}} className={className}>
             {fields.map(renderField)}

            {!!componentBeforeSubmitButton && <ComponentAdvanced changeHandler={inputChangedHandler}/>}

            {error && <div className="error-message">{error}</div>}

            {mode !== 'read' && !hideSubmitButton &&
            <Button
                value={submitLabel}
                block={submitBlock}
                onClick={formSubmitHandler}
                style={submitStyle}/>}
        </div>
    )
}

Form.defaultProps = {
    submitLabel: 'submit',
    submitBlock: true
};

Form.propTypes = {
    submitLabel: PropTypes.string,
    submitBlock: PropTypes.bool,
    submitStyle: PropTypes.object,
    handleFormSubmit: PropTypes.func,
    fields: PropTypes.array.isRequired
};

export default Form;