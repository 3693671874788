import React from "react";
import {FieldVideo} from "../../../../02_molecules";

export const VideoContent = ({itemValue, changeHandler}) => {
    return (
        <FieldVideo name="value"
                    value={itemValue}
                    triggerValue="העלה סרטון"
                    changeHandler={changeHandler}/>
    )
};