import React from "react";
import {paymentDictionary} from "./payment-dictionary";
import {Translation} from "../../../../languages";
import {DICT} from "../../../../languages/dictionary-names";
import {gutters} from "../../../00_base/variables";

const {deliveryAddress, streetWarning} = paymentDictionary;

export const mainFormFields = (paymentForm, validationActive, adventureType, language, dictionary) => {
    const hideDeliveryField = adventureType.id === 30 || adventureType.id === 31 || adventureType.id === 263 || adventureType.id === 40;

    const deliveryOptions = [dictionary.selfCollection, dictionary.deliveryAddress];
    return [{
        label: <Translation id={DICT.ADVENTURES_AMOUNT}/>,
        type: 'number',
        name: 'boxesLength',
        // min: 1,
        // max: 8,
        value: paymentForm.boxesLength,
    }, {
        type: hideDeliveryField ? 'hidden' : 'radio',
        name: 'deliveryType',
        labelStyle: {fontSize: '20px', cursor: 'pointer'},
        noteStyle: {fontSize: '18px'},
        options: deliveryOptions,
        value: deliveryOptions[paymentForm.deliveryType],
        initialValue: deliveryOptions[paymentForm.deliveryType],
        label: <Translation id={DICT.HOW_TO_GET_ADVENTURE}/>,
        note: <Translation id={DICT.DELIVERY_MESSAGE}/>,
        noteCondition: deliveryAddress[language]
    }]
};

export const selfCollectingFields = (paymentForm, validationsActive) => {
    return [{
        type: 'text',
        name: 'email',
        value: paymentForm.email || "",
        label: <Translation id={DICT.MAIL_TO_FOLLOW}/>,
        labelStyle: {paddingRight:gutters.sm},
        className: 'col-12 px-4',
        errorMessageActive: validationsActive && !paymentForm.email,
        errorMessage: <Translation id={DICT.MAIL_WARNING}/>
    }, {
        type: 'text',
        name: 'full_name',
        value: paymentForm.full_name || "",
        label: <Translation id={DICT.CONTACT_MEMBER}/>,
        labelStyle: {paddingRight:gutters.sm},
        className: 'col-5 pr-4',
        errorMessageActive: validationsActive && !paymentForm.full_name,
        errorMessage: <Translation id={DICT.CONTACT_WARNING}/>
    }, {
        type: 'text',
        name: 'phone',
        value: paymentForm.phone || "",
        label: <Translation id={DICT.CONTACT_PHONE_NUMBER}/>,
        labelStyle: {paddingRight:gutters.sm},
        className: 'col-7 pr-4'
    }]
};

export const deliveryFields = (paymentForm, validationsActive, language) => {
    return [{
        type: 'text',
        name: 'city',
        value: paymentForm.city || "",
        label: <Translation id={DICT.CITY}/>,
        errorMessageActive: validationsActive && !paymentForm.city,
        errorMessage: <Translation id={DICT.CITY_WARNING}/>,
        className: 'col-6 pr-0'
    }, {
        type: 'text',
        name: 'street',
        value: paymentForm.street || "",
        label: <Translation id={DICT.STREET}/>,
        errorMessageActive: validationsActive && !paymentForm.street,
        errorMessage: streetWarning[language],
        className: 'col-6 pr-0'
    }, {
        type: 'text',
        name: 'home_number',
        value: paymentForm.home_number || "",
        label: <Translation id={DICT.HOME_NUMBER}/>,
        className: 'col-4 pr-0'
    }, {
        type: 'text',
        name: 'apartment',
        value: paymentForm.apartment || "",
        label: <Translation id={DICT.APARTMENT}/>,
        className: 'col-4 pr-0'
    }, {
        type: 'text',
        name: 'postal_code',
        value: paymentForm.postal_code || "",
        label: <Translation id={DICT.POSTAL_CODE}/>,
        className: 'col-4 pr-0 mb-5'
    },
        ...selfCollectingFields(paymentForm, validationsActive, language)
    ]
};