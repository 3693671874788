import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {Col, Row} from "react-grid-system";
import {selectShowInSiteAdventureTypes} from "../../../../store/selectors";
import {AdventuresBlock} from "../homepage/hp-block-adventures";
import {LanguageContext, Translation} from "../../../../languages";
import {DICT} from "../../../../languages/dictionary-names";
import {ContactUsCard} from "../../../03_organisms";
import {Image} from "../../../01_atoms";

const DRAWING_PERSONAL_BOX = "https://storage.googleapis.com/assets.youdoadventures.com/website/Drawing_PersonalBox.png";


export const AdventuresCustomizeGallery = () => {
    const adventureTypes = useSelector(selectShowInSiteAdventureTypes);
    const {userLanguage} = useContext(LanguageContext);
    const isEnglish = userLanguage === "en";

    const filterByTemplate = item => item.adventuretemplate_id === 1
    const filterByLanguage = (item) => {
        if (isEnglish) {
            return item.language_id === 2
        }
        return item.language_id === 1
    }

    const customizeAdventures = adventureTypes.filter(filterByTemplate).filter(filterByLanguage);

    return (
        <div className={"container"}>
            <Row className={"pt-6"}>
                <Col lg={8} xl={7}>
                    <h1 className={"mt-0 mb-3 fs-8 fs-lg-9"}>
                        <Translation id={DICT.HOMEPAGE_CUSTOMIZE_ADVENTURES_TITLE}/>
                    </h1>
                    <p className={"font-size-md color-black"} style={{lineHeight: "3.5rem"}}>
                        <Translation id={DICT.INNER_CUSTOMIZE_ADVENTURES_SUBTITLE}/>
                    </p>
                </Col>
                <Col lg={4} xl={5}>
                    <Image src={DRAWING_PERSONAL_BOX} width={"100%"}/>
                </Col>
                <Col lg={7}>

                    <p className={"color-black"}><Translation id={DICT.INNER_CUSTOMIZE_ADVENTURES_DESCRIPTION}/></p>
                </Col>
            </Row>
            <AdventuresBlock items={customizeAdventures} hideDetails={true}/>
            <ContactUsCard/>
        </div>
    )
};