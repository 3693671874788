class Language {
    constructor(
        id,
        title,
        name,
        translations,
        flag
    ) {
        this.id = id;
        this.title = title;
        this.name = name;
        this.translations = translations;
        this.flag = flag;
    }
}

export default Language;