const font_family = {
    medium: 'Fedra Sans Hebrew Medium',
    book: 'Fedra Sans Hebrew Book'
};

const sizes = {
    min: 1.3 + 'rem',
    xxs: 1.5 + 'rem',
    xs: 1.6 + 'rem',
    base: 1.8 + 'rem',
    sm: 2 + 'rem',
    md: 2.4 + 'rem',
    lg: 2.8 + 'rem',
    xl: 3.8 + 'rem',
    xxl: 7.2 + 'rem',
    xxxl: 9 + 'rem',
    jumbo: 11.2 + 'rem',
    max: 20 + 'rem',
};

const headings = {
    h1: sizes.xxl,
    h2: sizes.xl,
    h3: sizes.lg,
    h4: sizes.md,
    h5: sizes.sm,
    h6: sizes.xs,
};

const font_size = {
    ...sizes,
    ...headings,
};

export { font_size, font_family };