import React from 'react';
import '../../../assets/styles/navigation-group.css';

const NavigationGroupItem = ({index, groupIndex, pageIndex, setPageIndex}) => {
    const i = index + groupIndex * 10;
    const classNames = `navigation-group-item ${pageIndex === i - 1 ? 'active' : ''}`;
    const onClick = () => setPageIndex(i - 1);
    return <div className={classNames}
                onClick={onClick}
                key={i}>
        {i}
    </div>
};

export default NavigationGroupItem;