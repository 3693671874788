class AdventureTemplate {
    constructor(
        id,
        name,
        created_at
    ) {
        this.id = id;
        this.name = name;
        this.created_at = created_at;
    }
};

export default AdventureTemplate;