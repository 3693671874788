import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import * as templatesActions from '../../../../store/actions/templates-actions';
import Gallery from '../../../03_organisms/gallery/o-gallery';

const SceenTemplatesGallery = props => {
    const [allTemplates, setAllTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingBeforeDelete, setLoadingBeforeDelete] = useState(null);
    const [error, setError] = useState(null);
    const [pageIndex, setPageIndex] = useState(0);
    const dispatch = useDispatch();

    const getTemplatesByPageIndex = useCallback(() => {
        try {
            dispatch(templatesActions.setAllTemplates(pageIndex)).then(response => {
                setAllTemplates(response);
                setIsLoading(false);
            });
        } catch (err) {
            setError(err.message);
            console.log(error);
            setIsLoading(false);
        }
    }, [dispatch, pageIndex, error]) ;

    useEffect(() => {
        setIsLoading(true);
        if (pageIndex === 0 && allTemplates && allTemplates.length > 0) {
            setIsLoading(null);
            getTemplatesByPageIndex();
        } else {
            getTemplatesByPageIndex();
        }
    }, [getTemplatesByPageIndex, allTemplates, pageIndex]);

    const deletTemplateHandler = (templateId) => {
        dispatch(templatesActions.deleteTemplate(templateId)).then(response => {
            setAllTemplates(allTemplates.filter(template => template.id !== templateId));
            setLoadingBeforeDelete(null);
        })
    };

    return (
        <div style={{width: '100%'}}>
        <Gallery
            loadingBeforeDelete={loadingBeforeDelete}
            setLoadingBeforeDelete={setLoadingBeforeDelete}
            getNextPage={() => setPageIndex(pageIndex + 1)}
            setPageIndex={setPageIndex}
            deletItemHandler={deletTemplateHandler}
            isLoading={isLoading && allTemplates.length < 1}
            title={'מאגר התבניות'}
            pluralLabel={"תבניות"}
            thumbWidth={50}
            name={'template'}
            apiName={'riddlesTemplate'}
            label={'תבנית'}
            items={allTemplates} />
        </div>
    )
}

export default SceenTemplatesGallery;