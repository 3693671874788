import React from "react";
import "../../../assets/styles/sun.scss";

export const Sun = () => {
    return (
        <div className="sun d-none d-sm-flex">
            <div className="sun_beam"/>
            <div className="sun_beam"/>
            <div className="sun_beam"/>
            <div className="sun_beam"/>
        </div>
    )
};