import React from "react";
import {FieldColor, FilesUploader} from "../../../02_molecules";

export const RiddleDesign = ({templatedata, handleChange}) => {
    return (
        <div className="riddle-design">
            <FilesUploader name="backgroundImage"
                           label="תמונת רקע"
                           triggerValue="העלו תמונת רקע עבור הסצנה"
                           multiple={false}
                           triggerStyle={{width:"220px"}}
                           value={templatedata?.backgroundImage}
                           changeHandler={handleChange}/>
            <FieldColor name="backgroundColor"
                           label="צבע רקע"
                           value={templatedata?.backgroundColor}
                           changeHandler={handleChange}/>
        </div>
    )
};