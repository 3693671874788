import React from 'react';
import {GoogleMapsView} from "./google-maps-view";
import PropTypes from "prop-types";
import SimpleLoader from "../../01_atoms/loaders/simple-loader/";


class GoogleMaps extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    render() {
        const {state} = this,
            {isLoading} = state;


        return (
            isLoading ?
                <SimpleLoader/> :
                <GoogleMapsView src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13581.74414653707!2d34.564130382957764!3d31.676645724356984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15029c3718e8d381%3A0x2799333a32b28444!2sLeonardo+Ashkelon!5e0!3m2!1sen!2sil!4v1565519024296!5m2!1sen!2sil"}/>
        )
    }

    static defaultProps = {
        className: '',
    }
}

GoogleMaps.propTypes = {
    className: PropTypes.string,
};

export default GoogleMaps;