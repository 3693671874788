import React from 'react';
import PropTypes from 'prop-types';
import {colors, font_size} from "../../00_base/variables";

const Text = ({ tag = "div", block, color = colors.gray_10, variant, style, className, textAlign, lineHeight, size, fontWeight, fontFamily, fontSize, value, children }) => {
    return React.createElement(
        tag,
        {
            className: className,
            style: {
                color: color ? color : variant ? colors[variant] : "#000",
                fontSize: fontSize ? fontSize : font_size[size],
                fontFamily: fontFamily,
                textAlign: textAlign,
                fontWeight: fontWeight,
                display: block && 'block',
                lineHeight: lineHeight,
                ...style
            }
        },
        children || value || ""
    )
};

Text.propTypes = {
    tag: PropTypes.oneOf(['div', 'p', 'label', 'span']),
    fontSize: PropTypes.string,
    fontFamily: PropTypes.string,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']),
    color: PropTypes.string,
    variant: PropTypes.oneOf(['primary', 'secondary', 'success', 'error', 'warning', 'disable', 'link']),
    fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.object
};

export default Text;