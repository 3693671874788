import AdventureTemplate from "../models/adventureTemplate";
import {CREATE_ADVENTURE} from "./adventure-actions";
import {API_URL} from "./fetch-data";

const api = API_URL;
const extension = 'adventureTemplate';
const url = `${api}/${extension}`;
const token = localStorage.getItem("token");

export const SET_ADVENTURE_TEMPLATES = 'SET_ADVENTURE_TEMPLATES';
export const SET_SINGLE_ADVENTURE_TEMPLATE = 'SET_SINGLE_ADVENTURE_TEMPLATE';
export const CREATE_ADVENTURE_TEMPLATE = 'CREATE_ADVENTURE_TEMPLATE';
export const UPDATE_ADVENTURE_TEMPLATE = 'UPDATE_ADVENTURE_TEMPLATE';
export const DELETE_ADVENTURE_TEMPLATE = 'DELETE_ADVENTURE_TEMPLATE';

export const setAllAdventureTemplates = (pageIndex = 0) => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${url}/?page=${pageIndex}`);
            if (!response.ok) {
                throw new Error('Something went wrong');
            }
            const adventureTemplates = await response.json();
            let allAdventureTemplates = [];

            for (const key in adventureTemplates) {
                const template = adventureTemplates[key];
                allAdventureTemplates.push(
                    new AdventureTemplate(
                        template.id,
                        template.name,
                        template.created_at
                    )
                )
            }

            dispatch({
                type: SET_ADVENTURE_TEMPLATES,
                allAdventureTemplates: allAdventureTemplates
            });
        } catch (error) {
            throw error;
        }
    }
};

export const createAdventureTemplate = (adventureTemplate) => {

    return async (dispatch, getState) => {
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(adventureTemplate),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "access-Token": token
            }
        }).then(response => response.ok ? response.json() : alert('error')).then(
            success => {
                dispatch({
                    type: CREATE_ADVENTURE,
                    id: success.id,
                    name: success.name,
                    created_at: success.created_at
                });
            }
        )
    }
};

export const getSingleAdventureTemplate = id => {
    return async (dispatch, getState) => {
        try {
            const response = await fetch(`${url}/${id}`);
            if (!response.ok) {
                throw new Error('Something went wrong');
            }
            const template = await response.json();

            return new AdventureTemplate(
                template.id,
                template.name,
                template.created_at
            )
        } catch (error) {
            throw error
        }
    }
};


export const updateAdventureTemplate = (adventureTemplate) => {
    return async (dispatch, getState) => {
        fetch(`${url}/${adventureTemplate.id}`, {
            method: 'PUT',
            body: JSON.stringify({...adventureTemplate}),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "access-Token": token
            }
        }).then(response => {
            if (response.ok) {
                response.json()
            } else {
                alert('error')
            }
        }).then(
            success => {
                dispatch({
                    type: UPDATE_ADVENTURE_TEMPLATE,
                    ...adventureTemplate
                });
            }
        );
    }
};
export const deleteAdventureTemplate = id => {
    return async (dispatch, getState) => {
        fetch(`${url}/${id}`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "access-Token": token
            }
        }).then(
            response => response.json()
        ).then(
            success => {
                dispatch({type: DELETE_ADVENTURE_TEMPLATE, id: id})
            }
        )
    }
};
;
;

