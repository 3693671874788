export const gutters = {
    xxxs: '.2rem',
    xxs: '.5rem',
    xs: '.8rem',
    sm: '1.5rem',
    md: '2rem',
    lg: '3.5rem',
    xl: '4.5rem',
    xxl: '6rem',
    xxxl: '8rem'
};