

export const ar = {
    digitalAdventures:"المغامرات الرقمية",
    customizeAdventures:"مغامرات مخصصة", 
    homepageCustomizeAdventuresTitle: "صندوق مغامرة لا تُنسى",
    homepageDigitalAdventuresTitle: "مغامرات رقمية مُخصصة",
    homepageDigitalAdventuresDescription: "لديك الفرصة للمشاركة في إنشاء لعبة هروب رقمية مُخصصة. بعد ملء التفاصيل على الموقع الإلكتروني، ستتلقى اللعبة مباشرة على بريدك الإلكتروني ويمكنك مشاركتها مع أحبائك. نرافقك طوال عملية الإنشاء بحيث يمكنك التركيز على أهم شيء... الاستمتاع!",
    innerCustomizeAdventuresSubtitle: "تجربة لا تُنسى تبقى معك! اختر نوع الحدث الذي تحتفل به وقم بتخصيص اللعبة لتناسبك.",
    innerCustomizeAdventuresDescription: "كل ما عليك فعله هو اختيار نوع الحدث الذي ترغب في اللعب به، وتحميل الصور وأسئلة المعلومات العامة ومقاطع الفيديو التحية، واستلام صندوق هروب مُخصص مليء بالألغاز والمفاجآت التي سيستمتع الجميع بحلها وفتحها معًا.",
    shekel: `₪`,
    aboutUsTitle: "من نحن؟",
    aboutUsDescription1: "YouDo هي شركة متخصصة في التعليم المغامر وتطوير أدوات التوجيه والتعلم للقطاعين العام والخاص. قبل كل شيء، تتمحور أنشطتنا حول الأفراد.",
    aboutUsDescription2: "نحن نعرف كيف نكيف طرق التدريس لأي محتوى بطريقة أصلية، ملهمة، ومحفزة تسمح للمشاركين بتجربة مغامرة لا تُنسى.",
    aboutUsDescription3: "نحن نؤمن بأن الطريق إلى قلب وعقل كل فرد ينبع من العمل المشترك والتحدي، مما يخلق دافعًا داخليًا للنجاح وتنفيذ المعرفة.",
    adventureBox: "صندوق المغامرة الخاص بي",
    userPageTitle: "إليك مغامراتك",
    userPageDescription: "اختر المغامرة التي تريد تعديلها ومشاركتها مع أصدقائك بعد الانتهاء.",
    whoWeAre: "من نحن",
    didNotFindWhatYouLookingFor: "لم تجد ما تبحث عنه؟",
    customersTitle: "كان من دواعي سرورنا التعاون مع المنظمات التالية",
    galleryTitle: "يشارك المغامرون",
    galleryMoreImages: "المزيد من الصور",
    register: "تسجيل",
    login: "تسجيل الدخول",
    logout: "تسجيل الخروج",
    blog: "المدونة",
    hello: "مرحبًا",
    businesses: "الشركات",
    products: "المنتجات",
    adventureBlog: "مدونة المغامرات",
    terms: "الشروط",
    contactUs: "اتصل بنا",
    faq: "الأسئلة الشائعة",
    allRights: "كل الحقوق محفوظة لشركة YOUDO المحدودة.",
    adventures: "المغامرات",
    createdAt: "تم الإنشاء في ",
    admin: "المشرف",
    moreDetails: "مزيد من التفاصيل",
    adventuresInProgress: "المغامرات قيد التقدم",
    subscribeDescription: "انضم إلى مجتمعنا لتلقي ألغازًا وتحديات ونصائح لتحسين مغامراتك.",
    subscribeInputPlaceholder: "ادخل عنوان بريدك الإلكتروني :)",
    digital: "رقمي",
    personalBoxes: "صناديق شخصية",
    riddles: "ألغاز",
    escapeRooms: "غرف هروب",
    clickHere: "انقر هنا",
    uniqueSolutions: "حلول فريدة",
    forSpecialBusinesses: "للشركات المغامرة",
    adventuresForBusinessesTitle: "مغامرات للشركات",
    adventuresForBusinessesDescription: "حلول إبداعية للشركات المغامرة. ألعاب هروب مصممة خصيصًا لاحتياجاتك مع محتوى، قيم، ورسائلك.",
    businessesDescriptionMain: "قم بأخذ جميع موظفيك في مغامرة استثنائية دون مغادرة المكتب.",
    businessesDescriptionSub: "نحن متخصصون في تطوير مناطق المغامرة لمختلف الأحداث. نعرف كيف نكيف ألعاب الهروب لدينا لأي محتوى بطريقة أصلية، ملهمة، ومحفزة تسمح للمشاركين بتجربة مغامرة لا تُنسى. سنخلق لك تجربة فريدة ومبتكرة لمنطقة المغامرة تركز تمامًا على التنظيم والفريق والمحتوى الخاص بك.",
    whatCanWeDoTogether: "إذا، ماذا يمكننا القيام به معًا؟",
    whatCanWeDoTogetherDescription: "لا تتردد في اختيار مغامراتنا الحالية. سنُخصص الخبرة لك. لنبدأ هذه الرحلة معًا.",
};

  export const en = {
    digitalAdventures:"Custom made adventures",
    customizeAdventures:"Digital Adventures",
    homepageCustomizeAdventuresTitle: "Unforgettable Adventure Box",
    homepageDigitalAdventuresTitle: "Personalized Digital Adventures",
    homepageDigitalAdventuresDescription: "You have the opportunity to take part in creating a personalized digital escape game. After filling out the details on the website, you will receive the game directly to your email and can share it with your loved ones. We accompany you throughout the entire creation process so you can focus on the most important thing... enjoying!",
    innerCustomizeAdventuresSubtitle: "An unforgettable experience that stays with you! Choose the type of event you are celebrating and customize the game to suit you.",
    innerCustomizeAdventuresDescription: "All you need to do is choose the type of event you want to play, upload photos, trivia questions, and greeting videos, and receive a personalized escape box filled with puzzles and surprises that everyone will enjoy solving and cracking together.",
    shekel: `₪`,
    aboutUsTitle: "Who Are We?",
    aboutUsDescription1: "YouDo is a company specializing in adventure education and the development of guidance and learning tools for the public and private sectors. Above all, our activities are people-focused.",
    aboutUsDescription2: "We know how to adapt teaching methods to any content in an original, inspiring, and challenging way that allows participants to experience an unforgettable adventure.",
    aboutUsDescription3: "We believe that the path to every individual's heart and mind arises from shared action and challenge, creating internal motivation for success and knowledge implementation.",
    adventureBox: "My Adventure Box",
    userPageTitle: "Here are your adventures",
    userPageDescription: "Choose the adventure you want to edit and share it with your friends after you've finished.",
    whoWeAre: "Who We Are",
    didNotFindWhatYouLookingFor: "Did not find what you're looking for?",
    customersTitle: "It was a pleasure to collaborate with the following organizations",
    galleryTitle: "Adventurers Share",
    galleryMoreImages: "More Images",
    register: "Register",
    login: "Login",
    logout: "Logout",
    blog: "Blog",
    hello: "Hello",
    businesses: "Businesses",
    products: "Products",
    adventureBlog: "Adventure Blog",
    terms: "Terms",
    contactUs: "Contact Us",
    faq: "FAQ",
    allRights: "All Rights Reserved YOUDO Ltd.",
    adventures: "Adventures",
    createdAt: "Created At ",
    admin: "Admin",
    moreDetails: "More Details",
    adventuresInProgress: "Adventures in Progress",
    subscribeDescription: "Join our community to receive puzzles, challenges, and tips to upgrade your adventures.",
    subscribeInputPlaceholder: "Enter your email address :)",
    digital: "Digital",
    personalBoxes: "Personal Boxes",
    riddles: "Riddles",
    escapeRooms: "Escape Rooms",
    clickHere: "Click Here",
    uniqueSolutions: "Unique Solutions",
    forSpecialBusinesses: "For Adventure Businesses",
    adventuresForBusinessesTitle: "Adventures for Businesses",
    adventuresForBusinessesDescription: "Creative solutions for adventurous businesses. Escape games tailored to your needs with your content, values, and messages.",
    businessesDescriptionMain: "Take all your employees on an extraordinary adventure without leaving the office.",
    businessesDescriptionSub: "We specialize in developing adventure zones for various events. We know how to adapt our escape games to any content in an original, inspiring, and challenging way that allows participants to experience an unforgettable adventure. We will create for you a unique and innovative experience of an adventure zone that focuses entirely on organization, team, and most importantly - your content.",
    whatCanWeDoTogether: "So what can we do together?",
    whatCanWeDoTogetherDescription: "Feel free to choose from our existing adventures. We will personalize the experience for you. Let's embark on this journey together.",
};

export const he  = {
    digitalAdventures:"הרפתקאות דיגיטליות",
    customizeAdventures:"הרפתקאות בהתאמה אישית",
    homepageCustomizeAdventuresTitle: "קופסת בריחה לאירוע בלתי נשכח",
    homepageDigitalAdventuresTitle: "הרפתקאות דיגיטליות בהתאמה אישית",
    homepageDigitalAdventuresDescription: "יש לכם הזדמנות לקחת חלק ביצירת משחק בריחה דיגיטלי בהתאמה אישית. לאחר שתמלאו את הפרטים באתר, תקבלו את המשחק ישירות למייל שלכם ותוכלו לשתף אותו עם האהובים שלכם. אנחנו מלווים אתכם לאורך כל תהליך היצירה כדי שאתם תוכלו להתמקד בדבר הכי חשוב… להנות",
    innerCustomizeAdventuresSubtitle: "חוויה בלתי נשכחת שנשארת אתכם! בחרו את סוג האירוע שאתם חוגגים והתאימו את המשחק אליכם.",
    innerCustomizeAdventuresDescription: "כל מה שצריך זה לבחור את סוג האירוע בו אתם רוצים לשחק, להעלות את התמונות, שאלות הטריוויה וסרטוני הברכות ולקבל קופסת בריחה מותאמת אישית, מלאה בחידות והפתעות שכולם יהנו לפתור ולפצח ביחד.",
    shekel: `ש"ח`,
    aboutUsTitle: "מי אנחנו?",
    aboutUsDescription1: "חברת YouDo הינה חברה המתמחה בחינוך הרפתקני ופיתוח אמצעי הדרכה ולמידה למגזר הציבורי והפרטי. לפני הכל, הפעילויות שלנו ממוקדת אדם.",
    aboutUsDescription2: "אנו יודעים להתאים את שיטות ההדרכה לכל תוכן בצורה מקורית, מלהיבה ומאתגרת המאפשרת למשתתפים לחוות חוויה בלתי נשכחת.",
    aboutUsDescription3: "אנו מאמינים כי הדרך לליבו ושכלו של כל אדם נובעת מתוך עשייה ואתגר משותפים היוצרים מוטיבציה פנימית להצלחה ולהטמעת ידע.",
    adventureBox: "תיבת ההרפתקאות שלי",
    userPageTitle: "הנה ההרפתקאות שלך",
    userPageDescription: "userPageDבחרו את ההרפתקה שאותה אתם רוצים לערוך ושתפו אותה עם החברים אחרי שסיימתםescription",
    whoWeAre:"מי אנחנו",
    didNotFindWhatYouLookingFor: "לא מצאתם מה שחיפשתם?",
    customersTitle: "היה לנו העונג לשתף פעולה עם הארגונים הבאים",
    galleryTitle:"הרפתקנים משתפים",
    galleryMoreImages: "עוד תמונות",
    register: "הרשמה",
    login: "התחבר",
    logout: "התנתק",
    blog:"בלוג",
    hello: "שלום",
    businesses:"עסקים",
    products:"מוצרים",
    adventureBlog: "בלוג הרפתקני",
    terms: "תקנון",
    contactUs: "צור קשר",
    faq:"שאלות נפוצות",
    allRights: "כל הזכויות שמורות YOUDO בע'מ",
    adventures: "הרפתקאות",
    createdAt: "נוצר ",
    admin: "אדמין",
    moreDetails: "לפרטים נוספים",
    adventuresInProgress: "הרפתקאות בהכנה",
    subscribeDescription: "הצטרפו לקהילה שלנו כדי לקבל חידות, אתגרים וטיפים לשדרוג ההרפתקאות שלכם",
    subscribeInputPlaceholder: "הזינו את כתובת המייל שלכם :)",
    digital: "דיגיטלי",
    personalBoxes: "קופסאות אישיות",
    riddles: "חידות",
    escapeRooms: "חדרי בריחה",
    clickHere: "הקליקו כאן",
    uniqueSolutions: "חוויות ייחודיות",
    forSpecialBusinesses: "לעסקים הרפתקנים",
    adventuresForBusinessesTitle: "הרפתקאות לעסקים",
    adventuresForBusinessesDescription: "פתרונות יצירתיים לעסקים הרפתקנים. משחקי בריחה שמתאימים בדיוק לצרכים שלכם עם התוכן, הערכים והמסרים שלכם.",
    businessesDescriptionMain: "קחו את כל העובדים להרפתקה יוצאת דופן מבלי לצאת מהמשרד.",
    businessesDescriptionSub: "אנחנו מתמחים בפיתוח זירות הרפתקניות לאירועים מגוונים. אנו יודעים להתאים את משחקי הבריחה שלנו לכל תוכן בצורה מקורית, מלהיבה ומאתגרת המאפשרת למשתתפים לחוות חוויה בלתי נשכחת. אנחנו ניצור עבורכם חוויה ייחודית וחדשנית של זירה הרפתקנית שכל כולה עוסקת בארגון , בצוות ובעיקר - בתוכן שלכם",
    whatCanWeDoTogether: "אז מה אנחנו יכולים לעשות ביחד?",
    whatCanWeDoTogetherDescription: "מוזמנים לבחור ממגוון ההרפתקאות הקיימות. אנחנו כבר נתאים לכם את החוויה באופן אישי. בואו נצא לדרך.",
   
};

  

  
  
  
  