import styled from 'styled-components';
import {colors} from "../../00_base/variables";

const VerticalScroller = styled.div`
    flexWrap: wrap;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    width: 100%;
    height: 100%;
    position: relative;
    &::-webkit-scrollbar-track {
        background-color: #fff;
        height: .2rem;
    }

    &::-webkit-scrollbar {
        height: .2rem;
        background-color: ${colors.white};
        width: .6rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${colors.black};
        height: .2rem;
        border-radius: 20px;
    }
`;

export default VerticalScroller;