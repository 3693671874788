import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {CreateTranslatorSection} from "./create-translator-section";
import {createNewId} from "../trivia/create-tool-trivia";
import {Button} from '../../../01_atoms';
import {FieldText} from "../../../02_molecules";
import {ColorPickerBgText} from "../../../03_organisms";
import {Table, tableHeader, Tr} from "../../../03_organisms";
import {TranslatorPreview} from "./translator-preview/translator-preview";
import "../../../../assets/styles/create-tool-translator.scss";
import {font_size, gutters} from "../../../00_base/variables";

class initialSection {
    constructor(id, label, value, image, textColor, bgColor) {
        this.id = id;
        this.label = label;
        this.textColor = textColor;
        this.bgColor = bgColor;
        this.value = value;
        this.image = image;
    }
}

const CreateToolTranslator = (
    {
        items = [],
        itemStyle = {},
        letterFontSize,
        sentence,
        handleToolDataChange
    }) => {
    const [translatorItems, setTranslatorItems] = useState(items);
    const [translatorItemStyle, setTranslatorItemStyle] = useState(itemStyle);

    const handleChange = (name, value, id) => {
        translatorItems.find(item => item.id === id)[name] = value;
        setTranslatorItems(translatorItems);
        handleToolDataChange("items", translatorItems)
    };

    const addSection = () => {
        const newId = createNewId(translatorItems);
        setTranslatorItems(translatorItems.concat({...initialSection, id: translatorItems.length > 0 ? newId : 0}))
    };

    const removeSection = (itemId) => {
        setTranslatorItems(translatorItems.filter(item => item.id !== itemId));
    };
    const commonColorsChange = (name, value) => {
        const colorName = name === "bgColor" ? "background" : name === "textColor" ? "color" : "";
        const updatedItemStyle = {...translatorItemStyle, [colorName]: value};
        const updatedTranslatorItems = translatorItems.map(item => {
            return {...item, [name]: value}
        });

        handleToolDataChange("itemStyle", updatedItemStyle);
        setTranslatorItemStyle(updatedItemStyle);
        setTranslatorItems(updatedTranslatorItems);
        setTimeout(() => handleToolDataChange("items", updatedTranslatorItems), 1000)
    };
    const handleItemStyleChange = (name, value) => handleToolDataChange("itemStyle", {
        ...itemStyle,
        [name]: value
    });
    const renderSection = (item, index) => {
        return (
            <CreateTranslatorSection index={index}
                                     key={index}
                                     formStyle={formStyle}
                                     itemsLength={translatorItems.length}
                                     removeSection={removeSection}
                                     {...item}
                                     handleChange={handleChange}/>
        )
    };

    const tableHeaderItems = ["אות", "טקסט", "תמונה", "צבע"];


    return (
        <div className="create-tool translator-create">
            {/*<button onClick={fixeOldVersion} style={{marginRight:"auto"}}>FIX OLD VERSION BUG</button>*/}
            <FieldText name="sentence"
                       value={sentence}
                       label={"טקסט ליצירת כתב החידה"}
                       placeholder={"לורם איפסום..."}
                       changeHandler={handleToolDataChange}/>

            {sentence &&
            <TranslatorPreview sentence={sentence}
                               items={items}/>}

            <div className={"d-flex align-items-center justify-content-end pl-3"}>
                <FieldText changeHandler={handleItemStyleChange}
                           placeholder={12}
                           value={itemStyle.fontSize}
                           type={"number"}
                           controlStyle={{marginBottom:0, marginLeft:gutters.sm, width:"270px"}}
                           dir={"horizontal"}
                           labelStyle={{marginBottom:0, fontSize:font_size.xs}}
                           label={"גודל פונט"}
                           name={"fontSize"}/>
                <ColorPickerBgText handleChange={commonColorsChange}
                                   bgColor={translatorItemStyle.background}
                                   textColor={translatorItemStyle.color}
                                   className={"justify-content-end pl-4"}/>


            </div>
            <Table>
                <thead><Tr>{tableHeaderItems.map(tableHeader)}</Tr></thead>
                <tbody>{translatorItems.map(renderSection)}</tbody>
            </Table>
            <Button outline className="tool-add-section" onClick={addSection}>הוסף אות</Button>



        </div>
    )
};

const formStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
};


CreateToolTranslator.defaultProps = {
    submitHandler: (toolData) => console.log('toolData', toolData),
    toolData: {items: []}
};

CreateToolTranslator.propTypes = {
    toolData: PropTypes.object,
    submitHandler: PropTypes.func
};


export default CreateToolTranslator;