export const
    breakpoints     = [375, 576, 768, 992, 1200],
    containerWidths = [375, 540, 750, 960, 1400],
    gutterWidth     = 10,
    gridColumns     = 12,
    fontSizeBase    = 16,
    colors = {
        primary: '#214B63',
        secondary: '#6610f2',
        success: '#7ec166',
        info: '#e83e8c',
        warning: '#dc3545',
        danger: '#fd7e14',
        light: '#495057',
        dark: '#6c757d'
    };