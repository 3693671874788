import React from 'react';
import styled from 'styled-components';

class IconPlus extends React.Component {
    render() {
        const {size, color, style, onClick, index} = this.props;
        return (
            <PlusStyle onClick={onClick && onClick}
                       data-index={index && index}
                       style={style && style}
                       size={size}
                       color={color}/>
        )
    }

    static defaultProps = {
        size: 28,
        color: '#151515'
    }
}

export default IconPlus;

const PlusStyle = styled.div`
    position: relative;
    width: ${props => props.size}px;
    height: ${props => props.size}px;

    &:before,
    &:after{
        content: "";
        position: absolute;
        background-color: ${props => props.color};
        transition: transform 0.25s ease-out;
    }

    /* Vertical line */
    &:before{
        top: 0;
        left: 50%;
        width: ${props => props.size / 9}px;
        height: 100%;
        margin-left: ${props => props.size / -18}px;
    }

    /* horizontal line */
    &:after{
        top: 50%;
        left: 0;
        width: 100%;
        height: ${props => props.size / 9}px;
        margin-top: ${props => props.size / -18}px;
    }
`;