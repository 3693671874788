import React from "react";
import {Col, Container, Row} from "react-grid-system";
import {FieldColor, FieldText} from "../../../02_molecules";

import {font_size} from "../../../00_base/variables";

export const CssColor = ({key = "", title = "עיצוב צבע", defaultProps = {}, changeHandler, PreviewComponent, className = ""}) => {

    const {elementBackgroundColor, elementBorderColor, elementBorderSize} = defaultProps;

    const labelStyle = {marginBottom: 0, paddingLeft: "5px", fontSize: font_size.sm};
    const inputStyle = {marginRight: 0, maxWidth: "100px"};

    const fieldCommonProps = {inputStyle, labelStyle, changeHandler};

    return (
        <div className={`text-css-form ${className}`}>

            <div className="text-css-form-body">
                {PreviewComponent && <PreviewComponent/>}
                <Container className="text-css-form-section">
                    <Row>
                        <Col>
                            <FieldColor name="elementBackgroundColor"
                                        label="צבע רקע"
                                        value={elementBackgroundColor}
                                        dir="horizontal"
                                        {...fieldCommonProps}/>
                        </Col>
                        <Col>
                            <FieldColor name="elementBorderColor"
                                        label="צבע מסגרת"
                                        value={elementBorderColor}
                                        dir="horizontal"
                                        {...fieldCommonProps}/>
                        </Col>
                        <Col>
                            <FieldText name="elementBorderSize"
                                       type="number"
                                       label="גודל מסגרת"
                                       value={elementBorderSize}
                                       {...fieldCommonProps}/>
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    )
}