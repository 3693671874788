import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {FieldSelect} from "../../../../02_molecules";
import {getToolComponent} from "../../../riddle/template/get-tool-component";
import {selectAllCategories} from "../../../../../store/selectors";

export const CategoryContent = ({itemValue = {toolData:{}}, changeHandler}) => {
    const allCategories = useSelector(selectAllCategories);
    const {toolId} = itemValue || {toolData:{}};
    const category = allCategories.find(item => item.id === toolId) || {};

    const [toolDataState, setToolDataState] = useState(itemValue.toolData);

    const getCategoryObject = (category) => {
        return {id: category.id, label: category.title || "בחר כלי", value: category.id}
    };
    const handleToolDataChange = (name, value) => setToolDataState({toolId:toolId, ...toolDataState, [name]: value});

    useEffect(() => {
        changeHandler("value", {toolId: category.id, toolData: toolDataState});
    }, [changeHandler, category.id, toolDataState])

    const CreateTool = getToolComponent(itemValue.toolId);

    return (
        <>
            <FieldSelect label="כלי דיגיטלי"
                         placeholder="בחר כלי דיגיטלי"
                         value={getCategoryObject(category)}
                         name="value"
                         changeHandler={(name, value) => changeHandler(name, {toolId:value.id})}
                         options={allCategories.map(getCategoryObject)}/>
            <CreateTool handleToolDataChange={handleToolDataChange} {...toolDataState}/>
        </>
    )
};