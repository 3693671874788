import React from "react";
import {Translation} from "../../../languages";
import {DICT} from "../../../languages/dictionary-names";


export const mainNavigation = (lang) => {
    return [
        {id: 1, label: <Translation id={DICT.PRODUCTS}/>, childNavigation: productsNavigation(lang)},
        {id: 2, label: <Translation id={DICT.BUSINESSES}/>, href: `/${lang}/businesses`, route: true},
        {id: 3, label: <Translation id={DICT.WOE_WE_ARE}/>, href: `/${lang}/about-us`, route: true},
        {id: 4, label: <Translation id={DICT.BLOG}/>, href: `/${lang}/blogs`, route: true},
        {id: 5, label: <Translation id={DICT.FAQ}/>, href: `/${lang}/faq`, route: true},
        {id: 6, label: <Translation id={DICT.GALLERY_TITLE}/>, href: `/${lang}/gallery`, route: true},
    ];
};

export const userNavigation = (isLogin, logout, lang) => {
    return [
        {
            id: 8,
            label: <Translation id={DICT.ADVENTURE_BOX}/>,
            href: `/${lang}/user`,
            route: true,
        },
        {
            id: 9,
            label: <Translation id={isLogin ? DICT.LOGOUT : DICT.LOGIN}/>,
            onClick: isLogin && logout,
            href: !isLogin && `/${lang}/register`,
        },
    ];
};

export const productsNavigation = (lang) => {
  return [
      {id: 6, label: <Translation id={DICT.CUSTOMIZE_ADVENTURES}/>, href: `/${lang}/customize-adventures`, route: true},
      {id: 7, label: <Translation id={DICT.DIGITAL_ADVENTURES}/>, href: `/${lang}/digital-adventures`, route: true},
  ];
};

export const adminNavigation = (isAdmin, lang) => {
    return isAdmin ?
        [{
            id: 10,
            label: <Translation id={DICT.ADMIN}/>,
            href: `/${lang}/admin/dashboard`,
            route: false,
            target:"_blank"
        }]
        :
        []
}