import React, {useEffect, useState} from "react";
import {MdClose} from 'react-icons/md';
import {font_size} from "../../../00_base/variables";
import {FieldOptions, FieldText, FilesUploader} from "../../../02_molecules";
import {Td, Tr} from "../../../03_organisms";
import {ColorPickerBgText} from "../../../03_organisms";

export const CreateTruthOrLieSection = (
    {
        id,
        index,
        sentence,
        sentence_image,
        textColor,
        bgColor,
        color,
        removeSection,
        handleChange,
        sentence_type,
        answer,
        icon_truth,
        icon_lie,
    }
) => {

    const [checkedAnswer] = useState(answer);
    const changeHandler = (name, value) => handleChange(name, value, id);

    useEffect(() => {
        if (checkedAnswer !== answer) {
            handleChange("answer", checkedAnswer, id);
        }
    }, [handleChange, answer, id, checkedAnswer]);


    const options = [
        {label: "אמת", value: "אמת"},
        {label: "שקר", value: "שקר"},
    ];
    return (
        <Tr className="truth-or-lie-section">
            <Td>
                {index + 1}
            </Td>
            <Td>
                <FieldText name={'sentence'}
                           value={sentence}
                           controlStyle={{margin: "0 0 5px", width: "100%"}}
                           placeholder={'הכנס משפט'}
                           changeHandler={changeHandler}/>
                    <FilesUploader name="sentence_image"
                                   value={sentence_image}
                                   previewWidth={"100%"}
                                   multiple={false}
                                   changeHandler={changeHandler}/>
            </Td>

            <Td>
                <FieldOptions options={options}
                              name={`sentence_type_${index}`}
                              changeHandler={(name, value) => handleChange('sentence_type', value, index)}
                              index={index}
                              controlStyle={{marginBottom: 0}}
                              value={options.find(option => option.value === sentence_type)}/>
            </Td>
            <Td>
                <FilesUploader maxItems={1}
                               multiple={false}
                               name={`icon_truth`}
                               index={index}
                               value={icon_truth}
                               triggerValue={'איקון אמת'}
                               previewWidth={'70px'}
                               previewHeight={'70px'}
                               triggerStyle={{fontSize: "14px"}}
                               labelStyle={{fontSize: font_size.sm, textAlign: "right"}}
                               controlStyle={{width: "auto"}}
                               setIsFileLoading={(data) => console.log("loading", data)}
                               fileUploaded={(data) => {
                                   console.log('data', data)
                               }}
                               changeHandler={changeHandler}/>
            </Td>
            <Td>
                <FilesUploader maxItems={1}
                               multiple={false}
                               name={'icon_lie'}
                               labelStyle={{fontSize: font_size.sm, textAlign: "right"}}
                               triggerValue={'איקון שקר'}
                               value={icon_lie}
                               index={index}
                               previewWidth={'70px'}
                               previewHeight={'70px'}
                               triggerStyle={{fontSize: "14px"}}
                               controlStyle={{width: "auto"}}
                               setIsFileLoading={(data) => console.log("loading", data)}
                               fileUploaded={(data) => console.log('data', data)}
                               changeHandler={changeHandler}/>
            </Td>
            <Td>

                <ColorPickerBgText handleChange={changeHandler}
                                   textColor={textColor}
                                   bgColor={bgColor}/>

                {/*<FieldColor changeHandler={changeHandler}*/}
                {/*            name={"color"}*/}
                {/*            controlStyle={{marginBottom: 0, textAlign: "left"}}*/}
                {/*            value={color}/>*/}

            </Td>
            <Td className="truth-or-lie-section-remove">
                <MdClose onClick={removeSection}/>
            </Td>

        </Tr>
    )
};