import React from "react";
import {MdAdd, MdClose, MdDragHandle} from "react-icons/md";
import {SortableHandle} from "react-sortable-hoc";
import {FilesUploader, ToolTip} from "../../../02_molecules";
import {Heading} from "../../../01_atoms";
import {ToggleIcon} from "../../../03_organisms";

export const GallerySectionHeader = ({icon, changeHandler, index, isOpen, remove, addItem, id, toggle}) => {
    const iconUploader = <FilesUploader name="icon"
                                        triggerValue={"איקון"}
                                        previewWidth={40}
                                        previewHeight={40}
                                        value={icon}
                                        multiple={false}
                                        changeHandler={changeHandler}/>;
    const buttonStyle = {margin: "0 5px"};
    // const ToggleIcon = isOpen ? MdKeyboardArrowDown : MdKeyboardArrowUp;
    const DraggableHandle = SortableHandle(() => {
        return (
            <div className="galleries-drag-handle">
                <MdDragHandle size={20} color={"#d2d2d2"}/>
            </div>
        )
    });
    return (
        <div className="create-tool-gallery-section-header">
            <div className="create-tool-gallery-images-title">
                <DraggableHandle/>
                <Heading tag={"h5"}>{`גלריה ${index + 1}`}</Heading>
                <ToolTip placeholder={iconUploader}
                         tip={"איקון גלריה"}
                         id={`gallery-section-icon-${id}`}/>
            </div>
            <div className="create-tool-gallery-section-actions">
                <ToggleIcon toggle={toggle}
                            size={25}
                            isOpen={isOpen}/>
                <div style={buttonStyle}>
                    <MdAdd onClick={addItem}
                           cursor={"pointer"}
                           size={20}/>
                </div>
                <MdClose onClick={remove}
                         size={20}
                         cursor={"pointer"}/>
            </div>
        </div>
    )
}