import React, {useEffect, useState} from "react";
import {MdClose} from "react-icons/md";
import {font_size} from "../../../../00_base/variables";
import {Heading} from "../../../../01_atoms";
import {FieldSelect, FieldText} from "../../../../02_molecules";
import {AdvancedList} from "./advanced-list";

export const CreateGalleryAdvancedData = ({items, handleChange, advancedDataType, onChange, sectionIndex, galleryItems, gallery, removeNumbers}) => {
    const [itemsState, setItemsState] = useState([]);

    useEffect(() => {
        setItemsState(items);
    }, [items]);

    const advancedDataOptions = ["none", "text", "list"];
    const renderOption = (option) => {
        return {id: option, value: option, label: option}
    };
    const handleNumberChange = (sectionIndex, numberIndex, value) => {
        gallery.hints[numberIndex] = value;
        handleChange('hints', gallery.hints, gallery.id);
    };


    const FieldByType = ({index, hints, item}) => {
        const value = hints ? hints[index] : 0;
        switch (advancedDataType) {
            case "list":
                return <AdvancedList hints={hints ? Array.isArray(hints[index]) ? hints[index] : []  : []}
                                     labelStyle={{fontSize: font_size.xs, whiteSpace: "nowrap"}}
                                     label={`תמונה ${index + 1}`}
                                     changeHandler={(value) => {
                                         handleNumberChange(sectionIndex, index, value)
                                     }}/>
            case "text":
                return <FieldText label={`תמונה ${index + 1}`}
                                  labelStyle={{fontSize: font_size.xs, whiteSpace: "nowrap"}}
                                  type={advancedDataType}
                                  name={'hint'}
                                  placeholder={index + 1}
                                  value={value}
                                  inputStyle={{maxWidth: "100%"}}
                                  dir={"vertical"}
                                  changeHandler={(name, value) => {
                                      handleNumberChange(sectionIndex, index, value)
                                  }}/>
            default:
                return <div/>
        }
    }

    const getTitle = () => {
        let title = "";
        switch (advancedDataType) {
            case "color":
                title = "בחר צבע עבור כל תמונה. הצבע ייחשף כאשר תתבצע לחיצה על התמונה."
                break;
            case "list":
                title = "הכנס רשימה עבור כל תמונה. הרשימה ייחשף כאשר תתבצע לחיצה על התמונה."
                break;
            case "text":
                title = "הכנס טקסט עבור כל תמונה. הטקסט ייחשף כאשר תתבצע לחיצה על התמונה."
                break;
            default:
                title = ""
        }
        return title;
    }

    return (
        <div className="advanced-numbers">

            <header className="advanced-numbers-header">
                <FieldSelect options={advancedDataOptions.map(renderOption)}
                             name="advancedDataType"
                             label="סוג התוכן בלחיצה על תמונה"
                             value={renderOption(advancedDataType)}
                             controlStyle={{maxWidth: "200px"}}
                             changeHandler={(name, value) => onChange(name, value.value)}/>
                <MdClose size={20} cursor="pointer" onClick={removeNumbers}/>
            </header>

            {itemsState && items.length > 0 && <Heading tag='h5'>{getTitle()}</Heading>}

            {itemsState.map((item, index) => {
                const hints = galleryItems[sectionIndex].hints;

                return (
                    <div className="advanced-numbers-item" key={index}>
                        <FieldByType hints={hints} item={item} index={index}/>
                    </div>
                )
            })}
        </div>
    )
};