import React from 'react';
import {Element} from 'react-stylesheet';
import {colors} from "../../00_base/variables";

const LoadingTitle = (
    {
        height = '2rem',
        marginBottom = '1.5rem',
        background = colors.gray_1,
        width,
        center
    }) => {
    return (
        <Element width={width ? width : `${(Math.random() * 100) + 50}px`}
                 height={height}
                 marginBottom={center ? `0 auto ${marginBottom}` : marginBottom}
                 background={background}
                 borderRadius={'2rem'}
        />
    )
};

export default LoadingTitle;