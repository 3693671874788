import React, {useReducer, useState} from "react";
import {Label} from "../../../../01_atoms";
import {FieldText} from "../../../../02_molecules";
import {MdClose} from "react-icons/md";

const ADD = "ADD";
const CHANGE = "CHANGE";
const REMOVE = "REMOVE";

export const AdvancedList = ({changeHandler, list = [], label, labelStyle}) => {
    const reducer = (state, action) => {
        switch (action.type) {
            case CHANGE:
                state[action.index] = action.value;
                break;
            case ADD:
                state = state.concat(action.value);
                break;
            case REMOVE:
                state = state.filter((value, index) => index !== action.index);
                break;
            default:
                return state;
        }
        changeHandler("list", state.filter(item => item !== ""));
        return state;
    };
    const [items, setItems] = useReducer(reducer, list);

    const [newItemValue, setNewItemValue] = useState("");

    const handleAddNew = (value) => {
         setItems({type: ADD, value: value});
        setNewItemValue("");
    };




    return (
        <div style={{marginBottom:"15px"}}>
            <Label value={label} style={{...labelStyle, fontSize:"16px"}}/>
            {items.map((item, index) => {
                const handleChange = (name, value) => setItems({type: CHANGE, index: index, value: value});
                const handleRemove = () => setItems({type: REMOVE, index: index});
                const isLast = index === items.length - 1;
                return (
                    <div style={{display: "flex", alignItems: "center", marginBottom: isLast ? 0 : "4px"}} key={index}>
                        <FieldText
                            changeHandler={handleChange}
                            index={index}
                            name="list"
                            value={item}
                            inputStyle={{width:"95%", height: "25px", padding: "0 3px"}}
                            controlStyle={{marginBottom: 0}}/>
                        <MdClose cursor="pointer" onClick={handleRemove}/>
                    </div>
                )
            })}

            <FieldText changeHandler={(name, value) => handleAddNew(value)}
                       name="list"
                       newItemValue={newItemValue}
                       placeholder="הכנס טקסט"
                       keydownHandler={e => e.key === "Enter" && handleAddNew(e.target.value)}
                       value={newItemValue}
                       inputStyle={{border: 0, borderBottom: "1px solid #000", borderRadius: 0, paddingRight: "3px"}}/>
        </div>
    )
};