import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Table,
    TableContainer,
    tableHeader,
    TableSearchBar,
    TableTitle,
    TableWrapper,
    Tr
} from "../../../03_organisms";
import {fetchData} from "../../../../store/actions/fetch-data";
import {NavigateItems} from "../../../03_organisms/navigation-groups/navigate-items";
import {AdventureItem} from "./single-adventure-item";
import {adventureTypeInnerMenuItems} from "./adventure-type-inner-menu";
import {AdventuresLoading} from "./screen-adventures-loading";
import {selectAllAdventures} from "../../../../store/selectors";
import * as adventureActions from '../../../../store/actions/adventure-actions';
import {AuthContext} from "../../../../providers/auth-provider";
import "../../../../assets/styles/adventures-gallery.scss";

const ScreenAdventuresGallery = props => {
    const adventures = useSelector(selectAllAdventures);

    const {setAllAdventures, updateAdventure, duplicateAdventure, deleteAdventure} = adventureActions;

    const [innerMenuActive, setInnerMenuActive] = useState(null);
    const [itemsLength, setItemsLength] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [markItems, setMarkItems] = useState([]);

    const {isCreator, isTom} = useContext(AuthContext);

    const filterByEmail = props?.location?.emailFilter;

    const dispatch = useDispatch();

    const setFilteredAdventuresByEmail = useCallback((email) => {
        dispatch(setAllAdventures(pageIndex, "email", email));
    }, [dispatch, pageIndex, setAllAdventures]);

    const setFilteredAdventuresByTitle = (title) => {
        dispatch(setAllAdventures(pageIndex, "title", title));
    };

    const update_adventure = (data) => dispatch(updateAdventure(data));

    const toggleMarkItem = (id, isChecked) => {
        if (isChecked) {
            setMarkItems(markItems.concat(id))
        } else {
            setMarkItems(markItems.filter(item => item !== id))
        }
    };

    const filterAdventuresByEmail = useCallback((str) => {
        const email = (!!str && typeof str === "string") && str;
        if (email) {
            setFilteredAdventuresByEmail(email.replace(/\s/g, ''));
        } else {
            dispatch(setAllAdventures(pageIndex))
        }
    }, [setFilteredAdventuresByEmail, dispatch, setAllAdventures, pageIndex]);

    useEffect(() => {
        if (!filterByEmail) {
            dispatch(setAllAdventures(pageIndex))
        }
    }, [dispatch, pageIndex, filterByEmail, setAllAdventures]);

    useEffect(() => {
        fetchData("get", "adventures/count", "", response => {
                if (response && response.count) {
                    setItemsLength(parseInt(response.count) - 1)
                }
            }
        )
    }, []);

    useEffect(() => {
        if (filterByEmail) {
            filterAdventuresByEmail(filterByEmail);
        }
    }, [filterAdventuresByEmail, filterByEmail]);


    const filterAdventuresByTitle = (str) => {
        const title = (!!str && typeof str === "string") && str;
        if (title) {
            setFilteredAdventuresByTitle(title.replace(/\s/g, ''));
        } else {
            dispatch(setAllAdventures(pageIndex))
        }
    };

    const open_single_page = id => window.location.href = id > 0 ? `/he/admin/reservation/${id}` : '/he/admin/new-reservation';

    const delete_adventure = (adventureTypeId) => {
        if (window.confirm("Are you sure?")) {
            dispatch(deleteAdventure(adventureTypeId));
            setInnerMenuActive(null);
        }
    };

    const deleteMultipleAdventures = () => {
        for (let i = 0; i < markItems.length; i++) {
            dispatch(deleteAdventure(markItems[i]));
        }
    };
    const deleteAdventuresButton = <span className={"button-delete-items"}
                                         style={{cursor: "pointer", color: "red", textDecoration: "underline"}}
                                         onClick={deleteMultipleAdventures}>מחק</span>;

    const duplicate_adventure = (id) => dispatch(duplicateAdventure(id))

    const innerMenuItems = adventureTypeInnerMenuItems(delete_adventure, duplicate_adventure);

    const tableRow = (adventure, index) => {
        return <AdventureItem item={adventure}
                              key={adventure.id}
                              index={index}
                              setInnerMenuActive={() => setInnerMenuActive(innerMenuActive === index ? null : index)}
                              changeHandler={update_adventure}
                              innerMenuItems={innerMenuItems}
                              toggleMarkItem={toggleMarkItem}
                              menuActive={innerMenuActive === index}/>
    };

    const tomsOtherHeaderItems = [
        markItems.length > 0 ? deleteAdventuresButton :
            "",
        "#",
    ];

    const tableHeaderItems = ((1===2 && isTom) ? tomsOtherHeaderItems : []).concat(["לקוח", "שם ההרפתקה", "סוג", "מחיר", "ת.יצירה", "passcode", "סטטוס", "קופון", "הערות"]);

    return (
        <TableWrapper>
            <TableTitle>
                <h1>הזמנות</h1>
            </TableTitle>

            <TableContainer className={"adventures-gallery"} fluid style={{width: "100%"}}>

                {/*<SpecialEmails clickHandler={filterAdventuresByEmail}/>*/}

                <TableSearchBar filterIndicators={[]}
                                handleSearchClick={filterAdventuresByEmail}
                                filterByTitle={filterAdventuresByTitle}
                                addLabel={"+ צור הזמנה חדשה"}
                                handleAddClick={() => open_single_page("new")}/>

                <Table cellSpacing="0" cellPadding="0">
                    <thead><Tr>{tableHeaderItems.map(tableHeader)}</Tr></thead>
                    <tbody>{adventures.map(tableRow)}</tbody>
                </Table>

                {adventures.length === 0 && <AdventuresLoading/>}

                {itemsLength > 15 && <NavigateItems length={itemsLength}
                                                    currentPageIndex={pageIndex}
                                                    handleChange={(index) => setPageIndex(index)}/>}

            </TableContainer>
        </TableWrapper>
    )
};

export default ScreenAdventuresGallery;