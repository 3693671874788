import React from 'react';

class IconSpeechBubble extends React.Component {
    render() {
        const {color} = this.props;
        return (
            <svg width="16px" height="14px" viewBox="0 0 16 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-261.000000, -3373.000000)" fill={color ? color : '#151515'} fillRule="nonzero">
                        <path d="M268.998981,3373 C264.588842,3373 261,3375.82855 261,3379.30643 C261,3382.78432 264.588842,3385.62493 268.998981,3385.62493 C269.894315,3385.62664 270.785684,3385.5076 271.648325,3385.27111 L275.779264,3387 L275.247357,3383.26077 C276.343206,3382.23041 276.974519,3380.80966 277,3379.31648 C276.997962,3375.83056 273.40912,3373 268.998981,3373 Z M274.770475,3385.69529 L271.697236,3384.41872 L271.556617,3384.45893 C270.726203,3384.7005 269.864749,3384.82238 268.998981,3384.82079 C265.037193,3384.82079 261.815183,3382.34204 261.815183,3379.30844 C261.815183,3376.27484 265.037193,3373.80414 268.998981,3373.80414 C272.960769,3373.80414 276.182779,3376.27283 276.182779,3379.30844 C276.182779,3380.58501 275.597886,3381.82941 274.534072,3382.81246 L274.379187,3382.9552 L274.770475,3385.69529 Z"></path>
                    </g>
                </g>
            </svg>
        )
    }
}

export default IconSpeechBubble;