import React, {useContext} from "react";
import {CreateAdventureContext} from "./screen-adventure-create";
import {colors, font_family} from "../../../00_base/variables";
import {FieldText, FilesUploader} from "../../../02_molecules";
import {useSelector} from "react-redux";
import {selectCurrentAdventure, selectCurrentAdventureType} from "../../../../store/selectors";
import {ThemeContext} from "../../../../providers/theme-provider";

export const AdventureCreateHeader = () => {
    const adventureContext = useContext(CreateAdventureContext);
    const adventure = useSelector(selectCurrentAdventure);
    const adventureType = useSelector(selectCurrentAdventureType);
    const {mode} = useContext(ThemeContext);
    const darkMode = mode === "dark";

    const controlStyle = {border: 0, marginBottom: 0};
    const inputStyle = {
        color: colors[darkMode ? "white" : "black"],
        border: 0,
        fontFamily: font_family.medium,
        fontWeight: "bold",
        textAlign: "right",
    };
    return (
        <div className="create-adventure-header">
            <div className="d-block d-md-flex align-items-center">
                <div className="create-adventure-thumbnail">
                    <FilesUploader multiple={false}
                                   value={adventure.thumbnail}
                                   name="thumbnail"
                                   maxItems={1}
                                   expectedLength={1}
                                   triggerValue={"תמונת הרפתקה"} // ${finish ? "משימה הושלמה" : "העלו תמונות"} (${images_max || 35} / ${(userData || images || []).length})` <Placeholders />
                                   previewWidth={120}
                                   previewHeight={120}
                                   changeHandler={adventureContext.handleAdventureChange}/>
                </div>
                <div className="create-adventure-title pr-md-5">
                    <FieldText value={adventure.title}
                               name="title"
                               changeHandler={adventureContext.handleAdventureChange}
                               controlStyle={controlStyle}
                               inputStyle={inputStyle}
                               className="create-adventure-title-field"/>


                    {adventureType && adventure &&
                    <p>{`הרפתקת ${adventureType.name} עבור`}
                        <input style={{...inputStyle, backgroundColor:darkMode ? "#000" : "#fff", outline:0,fontSize: "28px", fontWeight: "normal", paddingRight: "10px"}}
                               defaultValue={adventure.adventure_name}
                               name="adventure_name"
                               onChange={(e) => adventureContext.handleAdventureChange("adventure_name", e.target.value)}/>

                        {/*<FieldText changeHandler={adventureContext.handleAdventureChange}*/}
                        {/*           name="adventure_name"*/}
                        {/*           controlStyle={{...controlStyle, display: "inline !important"}}*/}
                        {/*           inputStyle={inputStyle}*/}
                        {/*           value={adventure.adventure_name}/>*/}
                    </p>}

                </div>
            </div>
            {/*<div className="create-adventure-hero">*/}
            {/*    <FieldText value={adventure.adventure_name}*/}
            {/*               name="adventure_name"*/}
            {/*               changeHandler={adventureContext.handleAdventureChange}*/}
            {/*               controlStyle={controlStyle}*/}
            {/*               inputStyle={inputStyle}*/}
            {/*               placeholder="הכנס את שם גיבור.ת ההרפתקה"*/}
            {/*               className="create-adventure-name-field"/>*/}
            {/*</div>*/}


        </div>
    )
};