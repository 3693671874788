import {fetchData} from "./fetch-data";
import AdventureType from "../models/adventureType";
import {createAdventureData, getAdventureData} from "./adventure-data-actions";
import {duplicateRiddles, setAdventureRiddles} from "./riddle-actions";
import {createAdventure, getAdventure, setSingleAdventure} from "./adventure-actions";

const extension = 'adventureType';

export const SET_ALL_ADVENTURE_TYPES = "SET_ALL_ADVENTURE_TYPES";
export const SET_SHOW_IN_SITE_ADVENTURE_TYPES = "SET_SHOW_IN_SITE_ADVENTURE_TYPES";
export const SET_SINGLE_ADVENTURE_TYPE = "SET_SINGLE_ADVENTURE_TYPE";
export const UPDATE_ADVENTURE_TYPE = "UPDATE_ADVENTURE_TYPE";
export const CREATE_ADVENTURE_TYPE = "CREATE_ADVENTURE_TYPE";
export const DELETE_ADVENTURE_TYPE = "DELETE_ADVENTURE_TYPE";


export const adventureTypeModel = (adventureType) => {
    return new AdventureType(
        adventureType.id,
        adventureType.name,
        adventureType.description,
        adventureType.logo_url,
        adventureType.game_subdomain,
        adventureType.theme_id,
        adventureType.guide_id,
        adventureType.base_price,
        adventureType.opening_video_url,
        adventureType.final_video_url,
        adventureType.storyboard_id,
        adventureType.game_board,
        adventureType.answers_file,
        adventureType.is_active,
        adventureType.show_in_site,
        adventureType.description_short,
        adventureType.description_homepage,
        adventureType.description_inner,
        adventureType.tags,
        adventureType.adventuredata_id,
        adventureType.adventuretemplate_id,
        adventureType.age,
        adventureType.players,
        adventureType.duration,
        adventureType.instructions_main,
        adventureType.instructions_title,
        adventureType.instructions_image,
        adventureType.site_image_main,
        adventureType.site_image_mobile,
        adventureType.site_image_tablet,
        adventureType.site_image_desktop,
        adventureType.entrance_cta,
        adventureType.entrance_color,
        adventureType.entrance_background,
        adventureType.entrance_image_desktop,
        adventureType.entrance_image_mobile,
        adventureType.lobby_title,
        adventureType.lobby_subtitle,
        adventureType.lobby_image_desktop,
        adventureType.lobby_image_mobile,
        adventureType.game_title,
        adventureType.game_subtitle,
        adventureType.personal_customize,
        adventureType.user_id,
        adventureType.faq,
        adventureType.welcome_title_size,
        adventureType.bg_color,
        adventureType.text_color,
        adventureType.continue_bg_color,
        adventureType.continue_text_color,
        adventureType.logo_type,
        adventureType.welcome_pre_title,
        adventureType.language_id
    )
};

export const setAllAdventureTypes = (pageIndex) => {
    return async (dispatch, getState) => {
        await fetchData(
            'get',
            `${extension}?page=${pageIndex}`,
            {},
            response => {
                let allAdventureTypes = [];
                for (const key in response) {
                    const adventureType = response[key];
                    allAdventureTypes.push(adventureTypeModel(adventureType))
                }
                dispatch({
                    type: SET_ALL_ADVENTURE_TYPES,
                    adventureTypes: allAdventureTypes
                });
          
            }
        )
    }
};

export const setAllShowInSiteAdventureTypes = (pageIndex) => {
    return async (dispatch, getState) => {
        await fetchData(
            'get',
            `getActiveShowInSiteAdventureType`,
            {},
            response => {
                let adventureTypes = [];
                for (const key in response) {
                    const adventureType = response[key];
                    adventureTypes.push(adventureTypeModel(adventureType))
                }
                dispatch({
                    type: SET_SHOW_IN_SITE_ADVENTURE_TYPES,
                    adventureTypes: adventureTypes
                });
            }
        )
    }
};

export const setSingleAdventureType = (adventureTypeId, cb) => {
    return async (dispatch, getState) => {
        if (adventureTypeId) {
            await fetchData(
                `get`,
                `${extension}/${adventureTypeId}`,
                {},
                response => {
                    dispatch({
                        type: SET_SINGLE_ADVENTURE_TYPE,
                        adventureType: adventureTypeModel(response)
                    })
                    cb && cb(adventureTypeModel(response))
                },
                error => cb(error)
            )
        } else {
            await dispatch({
                type: SET_SINGLE_ADVENTURE_TYPE,
                adventureType: {}
            })
        }

    }
};

export const createAdventureType = (adventureTypeData = {name: `הרפתקה-${Math.random().toString(36).substr(2, 6)}`}, handleSuccess) => {
    return async (dispatch, getState) => {
        const onSuccess = success => {
            dispatch({type: CREATE_ADVENTURE_TYPE, adventureType: success.data});
            if (handleSuccess) handleSuccess(success.data)
        };
        const fetching = async (data) => {
            const newName = adventureTypeData.name || `הרפתקה-${Math.random().toString(36).substr(2, 6)}`;
            fetchData(
                'post',
                extension,
                {...data, name: newName},
                onSuccess
                
                
            );
        };
        if (adventureTypeData.adventuredata_id) {
            await fetching(adventureTypeData);
        } else {
            await dispatch(createAdventureData(
                {adventurename: adventureTypeData.name},
                async (newAdventureData) => {
                    const data = {...adventureTypeData, adventuredata_id: newAdventureData.id};
                    await fetching(data);
                }
            ))
        }
    }
};


export const duplicateAdventureType = (adventureType, cb) => {
    return async (dispatch) => {
        await getAdventureData(
            adventureType.adventuredata_id,
            adventureData => {
                dispatch(
                    setAdventureRiddles(adventureData.riddles,
                        (riddles, frames) => {
                            dispatch(
                                duplicateRiddles(
                                    riddles,
                                    frames,
                                    (newRiddles) => {
                                        dispatch(
                                            createAdventureData(
                                                {...adventureData, riddles: newRiddles.map(riddle => riddle.id)},
                                                newAdventureData => {
                                                    const adventureTypeData = {
                                                        ...adventureType,
                                                        id: null,
                                                        adventuredata_id: newAdventureData.id
                                                    };
                                                    dispatch(
                                                        createAdventureType(
                                                            adventureTypeData,
                                                            newAdventureType => {
                                                                dispatch({
                                                                    type: CREATE_ADVENTURE_TYPE,
                                                                    adventureType: newAdventureType
                                                                })
                                                                if (cb) {
                                                                    cb(newAdventureType);
                                                                }
                                                            })
                                                    );
                                                }
                                            )
                                        )
                                    }
                                )
                            )
                        }
                    )
                )
            }
        )

    }
};

export const updateAdventureType = (data) => {
    return async (dispatch) => {
        const objData = {...data, base_price: parseInt(data.base_price)};
        dispatch({
            type: UPDATE_ADVENTURE_TYPE,
            adventureType: adventureTypeModel(objData)
        })
        await fetchData(
            'put',
            `${extension}/${data.id}`,
            objData,
        )
    };
};

export const deleteAdventureType = adventureTypeId => {
    return async (dispatch, getState) => {
        await fetchData(
            'delete',
            `${extension}/${adventureTypeId}`,
            {},
            success => dispatch({type: DELETE_ADVENTURE_TYPE, adventureTypeId: adventureTypeId})
        )
    }
};