import React, {useContext} from 'react';
import {Col, Row} from 'react-grid-system';
import {BackgroundImage} from '../../../01_atoms';
import {Box} from '../../../03_organisms';
import {LanguageContext, Translation} from "../../../../languages";
import {DICT} from "../../../../languages/dictionary-names";
import {useSelector} from "react-redux";
import {selectAllBlogs} from "../../../../store/selectors";
import "../../../../assets/styles/single-blog.scss";
import {dateFormat} from "../../../../helpers/dates/date-format";

const ScreenBlog = () => {
    const allBlogs = useSelector(selectAllBlogs);
    const {userLanguage, filterByLanguage, rtl} = useContext(LanguageContext);
    const topImage = 'https://admin.youdoadventures.com/wp-content/uploads/2019/06/background_blog_res.jpg';
    const blogsByLanguage = filterByLanguage(allBlogs);
    const activeBlogs = blogsByLanguage.filter(blog => blog.is_active);

    const renderBlogItem = (blog, i) => {
        const url = blog.url || `/${userLanguage}/blog/${blog.id}`;
        return (
            <Col sm={6} md={6} lg={4} xl={3} className="blogs-gallery-item p-md-5 p-3" key={blog.id}>
                <Box title={blog.title}
                     subtitle={blog.description_short}
                     image={blog.thumbnail_image || blog.main_image}
                     created_at={blog.created_at}
                     imageHeight="25rem"
                     url={url}
                     textAlign={rtl ? 'right' : 'left'}/>
                     <span className={"font-size-xxs"}>{`נוצר ב- ${dateFormat(blog.created_at)}`}</span>
            </Col>
        )
    };

    return (
        <div className={"screen-blogs"}>
            <BackgroundImage position={"top"} url={topImage} height={'40rem'}/>
            <div className={"container"}>
                <div className="d-flex justify-content-center align-items-center">
                    <h1><Translation id={DICT.ADVENTURE_BLOG}/></h1>
                </div>
                <div className={"py-4"}>
                    <Row className="blogs-gallery">
                        {activeBlogs.map(renderBlogItem)}
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default ScreenBlog;
