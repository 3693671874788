import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { Page } from "../../../04_layout";
import Login from "./login";

const ScreenLogin = () => {
  const params = useParams();
  const location = useLocation();

  const title = location?.title;
  const goAfterLogin = location?.goAfterLogin;
  const language = params?.lang || "he";
  return (
    <Page
      style={{ justifyContent: "center" }}
      className={`registration language-${language}`}
    >
      <Login title={title} goAfterLogin={goAfterLogin} language={language} />
    </Page>
  );
};

export default ScreenLogin;
