import React, {useState} from "react";
import {createNewId} from "../trivia/create-tool-trivia";
import {ChooseItemSection} from "./choose-item-section";
import {Button} from "../../../01_atoms";
import {FieldColor, FieldSelect, FieldText, FieldTextArea} from "../../../02_molecules";
import {CssGeneral} from "../../ui/css-general/css-general";
import {Table, TableContainer, tableHeader, TableWrapper, Tr} from "../../../03_organisms";
import "../../../../assets/styles/create-tool-chose-item.scss";

const initialSection = {
    id: 0,
    text: 'text',
    image: ""
};

export const CreateToolChooseItem = (
    {
        items = [],
        correctItem,
        handleToolDataChange,
        items_view = "blocks",
        itemStyle = {},
        wrongTitle = "",
        wrongTitleColor = "#000"
    }
) => {
    const [chooseItemSections, setChooseItemSections] = useState(items);
    const [itemsView, setItemsView] = useState(items_view);

    // useEffect(() => {
    //     handleToolDataChange("items_view", itemsView);
    // }, [handleToolDataChange, itemsView]);

    const itemsViewOption = (option) => {
        return {id: option, value: option, label: option}
    };

    const addSection = () => {
        const newId = createNewId(chooseItemSections);
        setChooseItemSections(chooseItemSections.concat({
            ...initialSection,
            id: chooseItemSections.length > 0 ? newId : 0
        }));
    };

    const removeSection = (itemId) => {
        setChooseItemSections(chooseItemSections.filter(item => item.id !== itemId));
    };

    const handleChange = (name, value, id) => {
        chooseItemSections.find(item => item.id === id)[name] = value;
        setChooseItemSections(chooseItemSections);
        handleToolDataChange("items", chooseItemSections);
    };

    const handleItemCssChange = (name, value) => {
        handleToolDataChange("itemStyle", {...itemStyle, [name]: value});
    };


    const tableRow = (item, index) => <ChooseItemSection {...item}
                                                         index={index}
                                                         removeSection={removeSection}
                                                         handleChange={handleChange}
                                                         key={item.id}/>;

    const tableHeaderItems = (["", "צבע", "תמונה", "טקסט"]);


    return (
        <div className="create-tool-choose-item">
            <TableWrapper>
                <TableContainer>
                    <Table cellSpacing="0" cellPadding="0">
                        <thead><Tr>{tableHeaderItems.map(tableHeader)}</Tr></thead>
                        <tbody>{chooseItemSections.map(tableRow)}</tbody>
                    </Table>

                    <FieldText name="correctItem"
                               label={"תשובה נכונה"}
                               changeHandler={handleToolDataChange}
                               value={correctItem}/>

                    <Button outline className="tool-add-section" onClick={addSection} value="הוסף פריט"/>

                    <FieldSelect options={["blocks", "grid", "gallery"].map(itemsViewOption)}
                                 name="items_view"
                                 label="אופן תצוגת הרישמה"
                                 value={itemsViewOption(itemsView)}
                                 controlStyle={{zIndex: 10, position: "relative"}}
                                 changeHandler={(name, item) => {
                                     setItemsView(item.value);
                                     handleToolDataChange("items_view", item.value);
                                 }}/>

                    <FieldTextArea name="wrongTitle"
                                   label="טקסט תשובה שגוייה"
                                   placeholder={"טקסט תשובה שגוייה"}
                                   changeHandler={handleToolDataChange}
                                   value={wrongTitle}/>
                    {wrongTitle &&
                    <FieldColor changeHandler={handleToolDataChange}
                                name="wrongTitleColor"
                                value={wrongTitleColor}/>
                    }

                    <CssGeneral title={"עיצוב פריט"}
                                defaultProps={itemStyle}
                                changeHandler={handleItemCssChange}/>
                </TableContainer>
            </TableWrapper>
        </div>
    )
}