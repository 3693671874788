import React, {useContext} from "react";
import {CreateAdventureContext} from "../screen-adventure-create";
import {RiddleContext} from "./adventure-create-frame";
import {MdCheckCircle} from "react-icons/md";
import {Button} from "../../../../01_atoms";
import "../../../../../assets/styles/riddle-header.scss";
import {colors} from "../../../../00_base/variables";

export const RiddleHeader = () => {
    const {setActiveRiddle, adventure_name} = useContext(CreateAdventureContext);
    const {active, index, frame, isRiddleValid} = useContext(RiddleContext);
    const percents = frame.categorydata.isValid || 0;
    const PERCENTS = `${parseInt(percents >= 100 ? 100 : percents).toFixed()}%`
    return (
        <div className="riddle-header" onClick={() => setActiveRiddle(!active && index)}>
            <div className="d-flex align-items-center">
                <Button radius={0}
                        size="xs"
                        className="py-4 px-5 d-none d-sm-block"
                        style={{width: "95px"}}>
                    {isRiddleValid ? <MdCheckCircle color={colors.white} size={30}/> : active ? "שמור" : "ערוך"}
                </Button>
                <Button radius={0}
                        size="xs"
                        className="py-4 px-5 d-sm-none"
                        style={{width: "95px"}}>
                    {isRiddleValid ?
                        <MdCheckCircle color={colors.white} size={30}/> :
                        <h3 className="color-white d-sm-none">
                            {PERCENTS}
                        </h3>}
                </Button>
                <h3 className="color-black fs-4 fs-sm-5 px-3">{frame.title.replace("{name}", adventure_name)}</h3>
            </div>
            <div>
                <h3 className="color-black px-3 d-none d-sm-block">{PERCENTS}</h3>
            </div>
        </div>
    )
};