import React, {useEffect, useState} from "react";
import {arrayMoveImmutable} from "array-move";
import {contentViewOptions, indicatorsLocationsOptions, TABS} from "./create-content-consts";
import {Button} from "../../../01_atoms";
import {FieldSelect} from "../../../02_molecules/";
import {SortableList} from "../../../03_organisms";
import {ToolContentSection} from "./tool-content-section";
import {createNewId} from "../trivia/create-tool-trivia";
import {CssColor} from "../../ui/css-color/css-color";
import {CARROUSEL} from "../gallery-images/create-gallery-consts";
import {CssGeneral} from "../../ui/css-general/css-general";
import {StyledDiv} from "../../ui/css-general/css-styled";
import _ from "lodash";

const initialSection = {
    id: 0,
    type: 'text',
    value: ''
};

export const CreateToolContent = (
    {
        items = [],
        elementBackgroundColor,
        elementBorderColor,
        elementBorderSize,
        handleToolDataChange,
        indicatorStyle,
        content_view = "block",
        indicators_location = "down"
    }) => {
    const [contentSections, setContentSections] = useState(items);
    const [contentView, setContentView] = useState(content_view);

    useEffect(() => {
        if (!_.isEqual(contentView, content_view)) {
            handleToolDataChange("content_view", contentView);
        }
    }, [handleToolDataChange,contentView]);

    useEffect(() => {
        if (!_.isEqual(contentSections, items)) {
            handleToolDataChange("items", contentSections);
        }
    }, [handleToolDataChange, contentSections]);

    const contentViewOption = (option) => {
        return {id: option, value: option, label: option}
    };

    const addSection = () => {
        const newId = createNewId(contentSections);
        setContentSections(contentSections.concat({...initialSection, id: contentSections.length > 0 ? newId : 0}));
    };

    const removeSection = (itemId) => {
        setContentSections(contentSections.filter(item => item.id !== itemId));
    };

    const handleChange = (name, value, id) => {
        contentSections.find(item => item.id === id)[name] = value;
        setContentSections(contentSections);
        handleToolDataChange("items", contentSections);
    };

    const onSortEnd = ({oldIndex, newIndex}) => {
        const updatedSections = arrayMoveImmutable(contentSections, oldIndex, newIndex);
        setContentSections(updatedSections);
        handleToolDataChange("items", updatedSections);
    };

    const handleIndicatorStyleChange = (name, value) => {
        handleToolDataChange("indicatorStyle", {...indicatorStyle, [name]: value});
    };

    const IndicatorPreviewComponent = () => {
        return (
            <StyledDiv {...indicatorStyle} className={"slider-create-preview-indicator"}
                       style={{display: "flex", justifyContent: "center", alignItems: "center"}}>1</StyledDiv>
        )
    };

    const renderSection = (item, index) => <ToolContentSection {...item}
                                                               contentView={contentView}
                                                               index={index}
                                                               removeSection={removeSection}
                                                               handleChange={handleChange}
                                                               key={item.id}/>;

    const controlStyle= {width: "130px", marginRight: "auto"};

    return (
        <div className="create-tool-content">

            <FieldSelect label="אופן תצוגת התוכן"
                         name="content_view"
                         options={contentViewOptions.map(contentViewOption)}
                         value={contentViewOption(contentView)}
                         controlStyle={controlStyle}
                         changeHandler={(name, value) => setContentView(value.value)}/>

            {contentView === TABS &&
            <FieldSelect label={"מיקום האיקונים"}
                         name={"indicators_location"}
                         options={indicatorsLocationsOptions.map(contentViewOption)}
                         value={contentViewOption(indicators_location)}
                         controlStyle={controlStyle}
                         changeHandler={(name, value) => handleToolDataChange(name, value.id)}/>}

            <SortableList items={contentSections.map(renderSection)}
                          ItemTag="li"
                          ListTag="ul"
                          useDragHandle={true}
                          onSortEnd={onSortEnd}/>

            <Button outline className="tool-add-section mb-5" onClick={addSection} value="הוסף תוכן"/>

            {(contentView === CARROUSEL || contentView === TABS) &&
            <CssGeneral key={"content-carrousel-indicator-ui"}
                        className="content-carrousel-indicator-ui"
                        title="עיצוב כפתור אינדיקציה"
                        changeHandler={handleIndicatorStyleChange}
                        PreviewComponent={IndicatorPreviewComponent}
                        defaultProps={indicatorStyle}/>}

            <CssColor changeHandler={handleToolDataChange}
                      defaultProps={{elementBackgroundColor, elementBorderColor, elementBorderSize}}/>

        </div>
    )
};