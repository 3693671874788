import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-grid-system";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { Heading, Paragraph } from "../../../01_atoms";
import { useDispatch } from "react-redux";
import { StarsBackground } from "../../../03_organisms/stars-background/stars-background";
import {
  colors,
  font_family,
  font_size,
  gutters,
} from "../../../00_base/variables";
import Payment from "../payment";
import Button from "../../../01_atoms/button/a-button";
import SPACE_VIDEO from "../../../../assets/video/space_website _video.mp4";
import { spaceDictionary } from "./space-dictionary.jsx";
import "../../../../assets/styles/space-adventure-style.scss";
import { FieldText } from "../../../02_molecules";
import FilesUploader from "../../../02_molecules/form-fields/files-uploader/m-files-uploader";
import {
  createAdventure,
  getAdventure,
  updateAdventure,
} from "../../../../store/actions/adventure-actions";
import { setSingleAdventureType } from "../../../../store/actions/adventure-type-actions";
import { LanguageContext } from "../../../../languages";
import { AuthContext } from "../../../../providers/auth-provider";

// 4852

export const CreateSpaceAdventure = () => {
  const params = useParams();
  const { isLogin } = useContext(AuthContext);
  const { rtl, userLanguage } = useContext(LanguageContext);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [userTitle, setUserTitle] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userImage, setUserImage] = useState("");

  const [adventureId, setAdventureId] = useState(null);
  const [adventure, setAdventure] = useState(null);
  const [warningsActive, setWarningsActive] = useState(false);

  useEffect(() => {
    if (params.adventureId) {
      getAdventure(params.adventureId, (adventure) => {
        if (adventure) {
          setUserTitle(adventure.json_data.name);
          setUserPhone(adventure.json_data.number);
          setUserImage(adventure.json_data.image);
          setAdventure(adventure);
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(false);
    }
  }, [params.adventureId]);

  useEffect(() => {}, [userTitle]);

  useEffect(() => {
    setWarningsActive(false);
  }, [userImage, userPhone, userTitle]);

  const handleSubmit = () => {
    if (userTitle.length > 1 && userPhone && userImage) {
      const jsonData = {
        title: "הרפתקת חלל",
        adventuredata_id: userLanguage === "en" ? 2120 : 1514,
        adventuretype: 31,
        adventureType: 31,
        adventure_name: "space-adventure",
        language: userLanguage === "en" ? "english" : "hebrew",
        thumbnail: userImage,
        json_data: {
          name: userTitle,
          number: userPhone.replaceAll("-", ""),
          image: userImage,
        },
      };
      if (adventure) {
        dispatch(
          updateAdventure({ ...adventure, ...jsonData }, createAdventureSuccess)
        );
      } else {
        dispatch(createAdventure(jsonData, createAdventureSuccess));
      }
    } else {
      setWarningsActive(true);
    }
  };

  const createAdventureSuccess = (adventure) => {
    dispatch(setSingleAdventureType(31)).then(() =>
      setAdventureId(adventure.id)
    );
  };

  const {
    mainParagraph,
    price,
    createNewAdventure,
    purchaseButton,
    pleaseRegister,
    heroPhone,
    heroImage,
    heroName,
    nameWarning,
    phoneWarning,
    imageWarning,
  } = spaceDictionary;

  const labelStyle = { color: "#fff", textAlign: rtl ? "right" : "left" };
  const warningStyle = { color: "#fff", textAlign: "center" };
  const controlStyle = { marginBottom: "2rem" };

  return (
    <Wrapper className={`create-space-adventure ${userLanguage}`}>
      <StarsBackground />
      {!isLoading && (
        <Container>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              margin: "0 auto 30px",
              maxWidth: "800px",
            }}
          >
            {/*<Image src={SPACE_LOGO}/>*/}
            <video width="100%" id={"audio"} controls autoPlay={true}>
              <source src={SPACE_VIDEO} type="video/mp4" />
            </video>

            <Paragraph
              textAlign="center"
              color={"#fff"}
              dangerouslySetInnerHTML={mainParagraph[userLanguage]}
            />

            <Paragraph
              textAlign="center"
              color={"#fff"}
              fontSize={font_size.lg}
            >
              {price[userLanguage]}
            </Paragraph>
          </div>
          {adventureId && (
            <div style={paymentWrapper}>
              <Payment
                adventureId={adventureId}
                fixedPrice={"99"}
                language={userLanguage}
                hideTitle={true}
                textColor={"#fff"}
              />
            </div>
          )}

          <div
            style={{
              width: "500px",
              maxWidth: "100%",
              margin: "0 auto",
              textAlign: "center",
            }}
          >
            {!adventureId && (
              <Heading
                tag={"h3"}
                value={createNewAdventure[userLanguage]}
                textAlign={"center"}
                color={"#fff"}
              />
            )}

            <FieldText
              type="text"
              name="title"
              labelStyle={labelStyle}
              warningStyle={warningStyle}
              inputStyle={{ color: "#fff", borderColor: "#fff" }}
              // inputStyleWithValue={{backgroundColor: "#fff"}}
              value={userTitle}
              controlStyle={controlStyle}
              label={heroName[userLanguage]}
              errorMessageActive={warningsActive && userTitle.length < 2}
              errorMessage={nameWarning[userLanguage]}
              language={userLanguage}
              changeHandler={(name, value) => setUserTitle(value)}
            />

            <FieldText
              type="text"
              name={"description"}
              label={heroPhone[userLanguage]}
              labelStyle={labelStyle}
              // inputStyleWithValue={{backgroundColor: "#fff"}}
              value={userPhone}
              inputStyle={{ color: "#fff", borderColor: "#fff" }}
              warningStyle={warningStyle}
              controlStyle={controlStyle}
              errorMessageActive={warningsActive && !userPhone}
              errorMessage={phoneWarning[userLanguage]}
              language={userLanguage}
              changeHandler={(name, value) => setUserPhone(value)}
            />

            <FilesUploader
              type={"files"}
              multiple={false}
              justifyContent={"center"}
              controlStyle={{ width: "100%", marginBottom: 0 }}
              name="image"
              previewWidth={"100%"}
              triggerValue={heroImage[userLanguage]}
              triggerStyle={{ width: "100%" }}
              label={heroImage[userLanguage]}
              errorMessageActive={warningsActive && !userImage}
              errorMessage={imageWarning[userLanguage]}
              warningStyle={warningStyle}
              language={userLanguage}
              value={userImage}
              changeHandler={(name, value) => setUserImage(value)}
            />

            {/*<Span fontSize={"13px"}*/}
            {/*      block*/}
            {/*      style={{*/}
            {/*          ...warningStyle,*/}
            {/*          marginTop: ".4rem",*/}
            {/*          visibility: (warningsActive && !userImage) ? "visible" : "hidden",*/}
            {/*          textAlign: userLanguage === "he" ? "right" : "left"*/}
            {/*      }}*/}
            {/*      color={'red'}*/}
            {/*      value={imageWarning[userLanguage]}/>*/}

            {/*<Form fields={spaceFields(language, () => setWarningsActive(false))}*/}
            {/*      fieldsStyle={{backgroundColor: "#fff"}}*/}
            {/*      handleFormSubmit={handleSubmit}*/}
            {/*      submitLabel={purchaseButton[language]}*/}
            {/*      hideSubmitButton={!isLogin}*/}
            {/*      submitStyle={{*/}
            {/*          backgroundColor: colors.primary,*/}
            {/*          width: "160px",*/}
            {/*          margin: '0 auto',*/}
            {/*          fontFamily: font_family.medium,*/}
            {/*          color: "#fff"*/}
            {/*      }}/>*/}

            {isLogin ? (
              <Button
                onClick={handleSubmit}
                style={{
                  display: adventureId && "none",
                  backgroundColor: colors.primary,
                  width: "160px",
                  margin: "20px auto 0",
                  fontFamily: font_family.medium,
                  color: "#fff",
                }}
              >
                {purchaseButton[userLanguage]}
              </Button>
            ) : (
              <Button>
                <Link
                  to={{
                    pathname: `${userLanguage}/register`,
                    title: pleaseRegister[userLanguage],
                    goAfterLogin: window.location.href,
                    language: userLanguage,
                  }}
                  style={{
                    color: colors.white,
                    width: "100%",
                    display: "block",
                  }}
                >
                  {adventure?.is_paid ? "שמור" : purchaseButton[userLanguage]}
                </Link>
              </Button>
            )}
          </div>
        </Container>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 50px 0;
  width: 100%;
`;

const paymentWrapper = {
  backgroundColor: colors.gray_1,
  borderRadius: "20px",
  padding: `${gutters.md} ${gutters.md}`,
  marginBottom: "50px",
};
