import React from "react";
import ResetPassword from "./reset-password";
import { Page } from "../../../04_layout";
import { useParams } from "react-router-dom";

const ScreenResetPassword = () => {
  const params = useParams();
  const language = params?.lang || "he";
  return (
    <Page
      style={{ justifyContent: "center" }}
      className={`forgot-password language-${language}`}
    >
      <ResetPassword />
    </Page>
  );
};

export default ScreenResetPassword;
