import React from 'react';

class IconAdventureDraft extends React.Component {
    render() {
        const {width, height} = this.props;
        return (
            <svg width={width ? width : '78px'} height={height ? height : '45px'} viewBox="0 0 78 45" version="1.1">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="15-פרופיל" transform="translate(-1292.000000, -203.000000)">
                        <g id="Group-2" transform="translate(1289.000000, 200.000000)">
                            <g id="Glyphs/Search" transform="translate(13.195876, 25.567010)" fill="#151515">
                                <path d="M15.7063939,14.3703494 L11.3258225,9.98806368 C12.1110461,8.9170964 12.5316874,7.62232255 12.5258225,6.29434939 C12.5108399,2.82789882 9.70710473,0.020085074 6.2406796,6.36775157e-05 C4.58179978,-0.00744179766 2.98881832,0.648814042 1.81660644,1.82263596 C0.644394559,2.99645787 -0.00967420411,4.59033858 0.00010817168,6.24920653 C0.0150906982,9.71597269 2.81908174,12.5240423 6.28582246,12.5440637 C7.61920554,12.5498427 8.91870988,12.1242916 9.99039389,11.3309208 L9.99496531,11.3274922 L14.3715367,15.7063494 C14.608164,15.9545934 14.9607548,16.0550767 15.2926966,15.9688668 C15.6246385,15.8826569 15.8837737,15.6232998 15.9696995,15.2912843 C16.0556253,14.9592688 15.9548403,14.6067641 15.7063939,14.3703494 L15.7063939,14.3703494 Z M6.28125103,11.2892065 C3.50800996,11.2732534 1.2648536,9.0270382 1.2526796,6.25377796 C1.24519456,4.92685547 1.76849262,3.65200699 2.70608557,2.71301882 C3.64367851,1.77403065 4.91774745,1.24883748 6.2446796,1.25434939 C9.01792067,1.27030252 11.261077,3.51651773 11.273251,6.28977796 C11.2807361,7.61670045 10.757438,8.89154894 9.81984506,9.83053711 C8.88225212,10.7695253 7.60818318,11.2947184 6.28125103,11.2892065 Z" id="Search"/>
                            </g>
                            <g id="Group" transform="translate(35.463918, 4.948454)">
                                <polygon stroke="#151515" strokeWidth="2" points="33.4020619 9.23705556e-14 22.2680412 4.52080947 11.1340206 9.23705556e-14 0 4.52080947 0 40.6872852 11.1340206 36.1664758 22.2680412 40.6872852 33.4020619 36.1664758 44.5360825 40.6872852 44.5360825 4.52080947"/>
                                <polygon fill="#151515" points="15.9057437 12.818408 15.9057437 11.2240787 17.6730486 11.3835116 17.5590289 12.977841"/>
                                <polygon fill="#151515" points="12.017673 13.3706965 14.059892 11.9255836 14.8453608 13.3706965 12.9602356 14.7316033"/>
                                <polygon fill="#151515" points="19.4403535 13.2083354 20.4619646 11.9255836 22.2680412 13.6091954 21.1686903 14.7316033"/>
                                <polygon fill="#151515" points="10.2503682 17.136763 10.8186334 14.7316033 12.017673 15.2928072 11.5131798 17.5376229"/>
                                <polygon fill="#151515" points="21.5611193 16.2090151 22.9602356 15.7838606 23.3284242 18.4206688 21.8556701 18.5898803"/>
                                <polygon fill="#151515" points="10.2503682 20.3436426 12.017673 20.3436426 12.017673 18.5898803 10.2503682 18.5898803"/>
                                <polygon fill="#151515" points="21.5611193 19.3781965 23.0979061 19.2913852 23.3284242 21.8334637 21.7908689 22.0974049"/>
                                <polygon fill="#151515" points="8.48306333 23.917034 9.85406346 22.0974049 11.3107511 22.9314016 9.85406346 24.9034246"/>
                                <polygon fill="#151515" points="38.8355842 22.0974049 39.9410898 23.195106 37.3352552 25.7806975 39.9410898 28.3662889 38.8355842 29.4632065 36.2297496 26.8776151 33.623915 29.4632065 32.5184094 28.3662889 35.1234544 25.7806975 32.5184094 23.195106 33.623915 22.0974049 36.2297496 24.6837799"/>
                                <polygon fill="#151515" points="22.2680412 23.1106898 23.5155506 22.0974049 25.095729 23.7342497 24.0145543 24.9034246"/>
                                <polygon fill="#151515" points="5.65537555 24.6565988 7.90155296 24.2019197 8.48306333 25.4360487 5.98814258 25.955682"/>
                                <polygon fill="#151515" points="25.802651 26.3648932 26.1461538 24.9034246 28.6303387 25.1957183 28.4598722 26.6571869"/>
                                <polygon fill="#151515" points="29.6907216 26.6571869 31.4580265 26.6571869 31.4580265 24.9034246 29.6907216 24.9034246"/>
                            </g>
                            <path d="M18.0948599,7.33879864 C17.0017955,9.18480677 13.9750579,8.05016716 15.1063317,6.04031215 C16.181656,4.12603448 19.3912534,5.28798191 18.0948599,7.33879864 M23.0061443,12.5682448 C21.1734533,11.4732015 23.4264487,9.09605638 24.9657454,9.75690545 C27.116394,10.6826403 24.7146544,13.6332495 23.0061443,12.5682448 M10.3970118,25.1516848 C13.0048097,23.7357748 13.981881,21.4555724 15.1186134,18.8736187 L16.3699742,16.0336062 C16.4927905,15.7537011 16.7479753,14.7364851 17.0577451,14.6258884 C18.0866722,14.2599638 19.1251516,13.8885775 19.9630312,13.1690166 C20.3942526,12.7989958 20.9701242,11.9415305 21.6005808,12.209147 C22.1450661,12.439898 22.3333843,13.1731128 23.0129674,13.3779214 C24.0487176,13.6878651 25.2891615,13.2222669 25.9537337,12.390744 C26.6565154,11.5087016 26.6756202,10.1897343 25.7640509,9.45378873 C24.392603,8.34645692 22.767335,9.3554806 21.8011807,10.4518893 C21.0915758,11.2574698 20.4092634,12.0603194 19.4335568,12.5463985 C18.9654905,12.7826111 18.4769548,12.9928812 17.9638559,13.114401 C17.3565979,13.2591324 17.1246117,13.3383251 16.8571453,12.8317652 C15.8596046,10.9516223 17.5831256,9.2981342 18.5056119,7.94776286 C20.0776596,5.64571425 17.7032126,3.32318478 15.4079138,4.78824893 C14.5536587,5.33440519 13.9873395,6.3816598 14.2602644,7.40160661 C14.3994561,7.91908966 14.7419769,8.34645692 15.18548,8.64001591 C15.6194306,8.92538255 16.3767974,8.95405576 16.4600395,9.50021201 C16.5582925,10.1651572 15.5443763,11.6397791 15.2878268,12.2323587 C14.7228722,13.5431337 14.7296953,13.519922 13.3473305,13.8776544 C12.3156742,14.1425402 3.76001168,17.3438754 4.09510816,17.7190703 C4.43020464,18.0942653 9.2577768,16.9688883 9.62466495,16.8636848 C11.3757043,16.3615824 12.1478253,16.1756068 13.8003859,15.6663161 C12.6663827,18.8285608 11.531015,21.9894401 10.3970118,25.1516848 Z" id="Fill-3" fill="#151515" transform="translate(15.275746, 14.741163) rotate(-153.000000) translate(-15.275746, -14.741163) "/>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default IconAdventureDraft;