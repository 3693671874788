import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "react-grid-system";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import GAME_ICON from "../../../assets/images/logos/youdo_logo_white.png";
import YOUDO from "../../../assets/images/logos/youdo_logo.png";
import { Button, Image, Paragraph } from "../../01_atoms";
import ScreenLoading from "../loading/screen-loading";
import MenuHeader from "./menu-header";
import ToolClues from "../tool/clues/tool-clues";

import {
  Cover,
  IconImage,
  iconLink,
  iconsContainer,
  iconsWrapper,
  IconsWrapper,
  iconWrapper,
  PageContainer,
  PageMenuWrapper,
  PageWrapper,
  RiddleIconLabel,
} from "./menu-style";
import * as journeyActions from "../../../store/actions/journey/journey-actions";
import ToolIcon from "./tool-icon";
import { IconsTemplateArrows } from "./icons-template-arrows/icons-template-arrows";
import { LobbyCircle } from "./lobby-circle/lobby-circle";
import { useEventListener } from "../../../hooks/use-event-listener";

const ScreenMenu = (props) => {
  const navigate = useNavigate();
  const english = props.lang === "en";
  const gameData = useSelector((state) => state.gameData);
  const journey = useSelector((state) => state.journey);

  const {
    adventure = props.adventure,
    adventureType = props.adventureType,
    riddles = props.riddles,
  } = gameData || {};
  const { isPreview } = props;
  const [shakeActive, setShakeActive] = useState(null);

  const [clueMenuActive, setClueMenuActive] = useState(false);

  const dispatch = useDispatch();

  const logo = adventure?.thumbnail || adventureType?.logo_url || GAME_ICON;
  const activeIcons = riddles?.filter((riddle) => !!riddle.thumbnail); // !!riddle.templatedata &&
  const showFakeIcons = adventure?.json_data?.show_fake_icons;
  const fakeIcons = adventure?.json_data?.lobby_fake_icons;
  const iconsLength =
    activeIcons.length + (showFakeIcons ? fakeIcons?.length : 0);
  const isSpecialTemplate =
    adventureType?.id === 30 || adventureType?.id === 40;
  const colSize = isSpecialTemplate
    ? 12
    : iconsLength <= 6
    ? 6
    : iconsLength <= 12
    ? 4
    : 3;

  const goToRiddle = (riddleId) => {
    if (!isPreview) {
      const pathname = english
        ? `/riddle-en/${riddleId}/1`
        : `/riddle/${riddleId}/1`;
      dispatch(journeyActions.riddleStart(riddleId));
      navigate(pathname);
    }
  };

  const PRINCIPALS = adventureType.id === 311;
  const TALMUD = adventureType.id === 344;
  const HAEMEK = adventureType.id === 333;
  const HIMUSH = adventureType.id === 362;
  const SHOW_BIG_CIRCLE = PRINCIPALS;

  const NO_LINEAR = HAEMEK || PRINCIPALS || TALMUD || HIMUSH;
  const keyPressHandler = ({ key }) => {
    if (NO_LINEAR && key > 0 && key < activeIcons.length) {
      goToRiddle(activeIcons[key - 1].id);
    }
  };

  useEventListener("keydown", keyPressHandler);

  const isAllFinish = () => {
    return activeIcons
      .slice(0, activeIcons.length - 1)
      .every((riddle) => journey[`riddle_${riddle.id}_finish`]);
  };

  const renderToolIcon = (riddle, index, imgStyle = {}) => {
    const { id, thumbnail, riddle_name, templatedata } = riddle;
    const { thumbnail_correct } = templatedata || {};
    const isResolve = !isPreview && !!journey[`riddle_${id}_finish`];
    const previousRiddleId = riddles[index > 0 ? index - 1 : 0].id;
    const isPreviousRiddleResolve = isPreview
      ? true
      : journey[`riddle_${previousRiddleId}_finish`];
    const canContinue = NO_LINEAR || index === 0 || isPreviousRiddleResolve;
    const opacity = !!thumbnail_correct ? 1 : canContinue ? 1 : 0.6;
    const isLastScene = index === riddles.length - 1;

    const hideTreasureIcon = isLastScene && HAEMEK && !isAllFinish();
    if (hideTreasureIcon) {
      return <></>;
    }

    const iconWrapperStyle = { ...iconWrapper, opacity: opacity };
    const iconButtonStyle = {
      ...iconLink,
      width: PRINCIPALS && "auto",
      display: "inline-block",
    };
    const iconWrapperClassname = classNames("lobby-icon-wrapper", {
      resolve: canContinue,
      shake: shakeActive === id,
    });
    const iconSrc = isResolve
      ? thumbnail_correct || thumbnail || THUMBNAIL
      : thumbnail || THUMBNAIL;
    const iconFontSize = showFakeIcons ? "14px" : "18px";
    const buttonClickHandler = () =>
      canContinue ? goToRiddle(id) : setShakeActive(id);

    return (
      <Col
        xs={colSize}
        className={iconWrapperClassname}
        style={iconWrapperStyle}
        key={riddle.id}
      >
        <Button style={iconButtonStyle} onClick={buttonClickHandler}>
          {" "}
          {/* index > gameLevel ? '/menu' : */}
          <IconImage src={iconSrc} style={imgStyle} />
        </Button>
        {riddle_name && (
          <RiddleIconLabel fontSize={iconFontSize} className="riddle-name">
            {riddle_name}
          </RiddleIconLabel>
        )}
      </Col>
    );
  };

  if (!riddles) return <ScreenLoading />;

  if (!riddles) {
    return (
      <PageWrapper>
        <Cover>
          <Image src={YOUDO} width={200} />
        </Cover>
      </PageWrapper>
    );
  }

  const backgroundStyle = {
    mobile:
      adventure?.json_data?.lobby_image_mobile ||
      adventureType?.lobby_image_mobile,
    tablet:
      adventure?.json_data?.lobby_image_desktop ||
      adventureType?.lobby_image_desktop,
    desktop:
      adventure?.json_data?.lobby_image_desktop ||
      adventureType?.lobby_image_desktop,
  };

  const lobbyTitle = adventure?.json_data?.lobby_title?.replace(
    "{name}",
    adventure?.adventure_name || ""
  );
  const lobbySubtitle = adventure?.json_data?.lobby_subtitle?.replace(
    "{name}",
    adventure?.adventure_name || ""
  );

  const specificIconsWrapper = {
    maxWidth: isSpecialTemplate ? "100%" : "100%",
    display: isSpecialTemplate ? "block" : "flex",
  };

  const showThumbnail = PRINCIPALS || HAEMEK;

  const HEADER = (
    <MenuHeader
      clueMenuActive={clueMenuActive}
      setClueMenuActive={setClueMenuActive}
      logo={logo}
      fullSize={isSpecialTemplate}
    />
  );

  const CLUES = (
    <ToolClues
      isActive={clueMenuActive}
      close={() => setClueMenuActive(false)}
      cluesData={[
        {
          subtitle:
            "עקבו אחר רצף החידות (גם במסך וגם בגלויה). שימו לב - פתרון של כל חידה יפתח לכם את הכלי הדיגיטלי הבא\n",
        },
      ]}
    />
  );

  const TITLE = lobbyTitle && <h1 className={"lobby-title"}>{lobbyTitle}</h1>;

  const SUBTITLE = lobbySubtitle && (
    <Paragraph
      style={{ marginTop: 0 }}
      textAlign="center"
      value={lobbySubtitle}
    />
  );

  const LOGO = logo && <Image width={400} block src={logo} />;

  const THUMBNAIL = adventure.thumbnail && showThumbnail && (
    <div
      style={{ marginTop: "20px", padding: "20px 30px", textAlign: "center" }}
    >
      <Image src={adventure.thumbnail} maxWidth={260} />
    </div>
  );

  const FAKE_ICONS =
    showFakeIcons &&
    fakeIcons.map((name, index) => {
      return (
        <ToolIcon
          key={index}
          iconsLength={iconsLength}
          colSize={colSize}
          showName={activeIcons.some((icon) => icon.riddle_name)}
          name={name}
        />
      );
    });

  const ICONS = (
    <Row style={{ justifyContent: "center" }}>
      {activeIcons
        .slice(0, adventureType.id === 311 ? activeIcons.length - 1 : 100)
        .map((icon, index) => renderToolIcon(icon, index))}
      {FAKE_ICONS}
    </Row>
  );

  const ICONS_ARROWS = (
    <IconsTemplateArrows
      activeIcons={activeIcons}
      renderToolIcon={(icon, index) => renderToolIcon(icon, index, {})}
      showArrows={false}
    />
  );

  const riddlesFinish =
    !isPreview &&
    riddles.map((riddle) => !!journey[`riddle_${riddle.id}_finish`]);

  // console.log("riddlesFinish", riddlesFinish)
  // const isGameFinished = riddlesFinish.slice(0, PRINCIPALS ? activeIcons.length - 1 : 100).filter(item => !!item).length >= activeIcons.length - 1;
  // const isGameFinished = riddlesFinish?.map(item => item === true);
  const CIRCLE = SHOW_BIG_CIRCLE && (
    <LobbyCircle
      backgroundImage={adventure.thumbnail}
      pieItems={riddlesFinish}
      finalIcon={() =>
        renderToolIcon(riddles[riddles.length - 1], riddles.length - 1, {})
      }
      isFinish={
        riddlesFinish
          ?.slice(0, PRINCIPALS || HAEMEK ? activeIcons.length - 1 : 100)
          .filter((item) => !!item).length >=
        activeIcons.length - 1
      }
    />
  );

  // const FINAL_LOGO =

  return (
    <PageMenuWrapper
      id="screen-menu"
      className={`adventure-${adventure?.id} adventure-type-${adventureType?.id}`}
    >
      {CLUES}
      <PageWrapper {...props} {...backgroundStyle}>
        <PageContainer>
          {HEADER}
          <IconsWrapper style={{ ...iconsWrapper, ...specificIconsWrapper }}>
            {isSpecialTemplate ? (
              <>
                {TITLE}
                {SUBTITLE}
                {LOGO}
                {ICONS_ARROWS}
              </>
            ) : (
              <Container style={iconsContainer}>
                {TITLE}
                {CIRCLE}
                {SUBTITLE}
                {ICONS}
                {/*{LOGO_WHITE}*/}
                {/*{THUMBNAIL}*/}
              </Container>
            )}
          </IconsWrapper>
        </PageContainer>
      </PageWrapper>
    </PageMenuWrapper>
  );
};

export default ScreenMenu;
