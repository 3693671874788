import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {selectUserAdventures} from "../../../store/selectors";
import {Button} from "../../01_atoms";
import {colors} from "../../00_base/variables";
import {Link} from "react-router-dom";
import {LanguageContext} from "../../../languages";
import {dateFormat, getDayName} from "../../../helpers/dates/date-format";
import "../../../assets/styles/adventure-progress-message.scss";

export const  AdventureProgressMessage = ({continueCreate}) => {
    const userAdventures = useSelector(selectUserAdventures);
    const {userLanguage} = useContext(LanguageContext);

    const adventure = userAdventures.filter(adventure => !adventure.is_paid)[0];
    const {id, title, created_at} = adventure;
    const dayName = getDayName(created_at);
    const date = dateFormat(created_at);

    return (
        <div className="adventure-progress-message text-center px-2 py-3 pt-sm-4 pb-sm-5">
            <h2 className="mb-3 mt-3">הי! כבר התחלת הרפתקה!</h2>
            <p className="font-size-md">
                {`שמנו לב שכבר יצרת הרפתקה בשם `}
                <span className="font-size-md" style={{fontWeight:"bold"}}>{title}</span>
                {` ביום ${dayName} ה- ${date} .`}
                <br/>
                {`יש לך את האפשרות להמשיך ולערוך אותה.`}
            </p>
            <div className="mt-5 ">
                <Button className="mx-auto mb-3 py-4"
                        block
                        radius="10px">
                    <Link to={`/${userLanguage}/create-adventure/${id}`}>{`המשך בעריכת ${title}`}</Link>
                </Button>
                <Button className="mx-auto py-4"
                        radius="10px"
                        block
                        background={colors.link}
                        color={colors.black}
                        fontWeight={"bold"}
                        style={{textDecoration:"underline"}}
                        onClick={continueCreate}>
                    התחל הרפתקה חדשה
                </Button>
            </div>
        </div>
    )
};