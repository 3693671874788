import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BackgroundImage from '../background-image/a-background-image';
import Iframe from "../iframe";

const Video = ({source, placeholder, height = 560, width = 800, autoPlay, controls, muted, loop, videoStyle, type, afterColor}) => {
    const isYoutube = (url) => {
        if (url) {
            const regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
            if (url.match(regExp)) {
                return true;
            }
        }
        return false;
    };
    return (
        <VideoWrapper className={'video-wrapper'} afterColor={afterColor} style={{...videoDefaultStyle, ...videoStyle}}>
            {placeholder &&
            <BackgroundImage url={placeholder}
                             style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}/>
            }
            {isYoutube ?
                <Iframe src={source}
                        width={width}
                        height={height}
                        autoPlay={autoPlay}
                        isYoutube={isYoutube}
                        style={{maxWidth: "100%", maxHeight: "100%"}}/>
                :
                <video className={'video'}
                       autoPlay={autoPlay}
                       muted={muted}
                       loop={loop}
                       controls={controls}
                       style={{height: '100%'}}>
                    <source src={source} type={type}/>
                </video>}
        </VideoWrapper>
    )
};

const VideoWrapper = styled.div`
    width: 100%;
    height: 100%;
    &:after {
        content: ${props => props.isYoutube ? "" : null};
        background-color: ${props => props.afterColor};
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
`;
const videoDefaultStyle = {
    position: 'absolute',
    left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)'
}

Video.defaultProps = {
    type: 'video/mp4',
    autoPlay: false,
    muted: false,
    loop: false,
    controls: true,
    afterColor: 'rgba(0,0,0,0.5)'
};

Video.propTypes = {
    source: PropTypes.string,
    placeholder: PropTypes.string,
    controls: PropTypes.bool,
    autoPlay: PropTypes.bool,
    muted: PropTypes.bool,
    loop: PropTypes.bool,
    afterColor: PropTypes.string,
    videoStyle: PropTypes.object,
    type: PropTypes.string
};

export default Video;