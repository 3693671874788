import React, {useContext} from "react";
import classNames from "classnames";
import {TriviaContext} from "./tool-trivia";

export const TriviaOption = ({value, active, color, bgColor, textColor, onClick, index}) => {
    const {adventureName, isGrid} = useContext(TriviaContext);
    const style = {
        backgroundColor: active && (bgColor || color),
        color: textColor,
        borderColor: bgColor || color
    };
    return (
        <div onClick={onClick} className={classNames("trivia-option", {active})}>
            <div className="trivia-option-container"
                 style={style}>
                {isGrid ? `${index + 1}. ` : ""}
                {value.replace("{name}", adventureName)}
            </div>
        </div>
    )
};
