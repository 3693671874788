import React from "react";
import {FieldText, FilesUploader} from "../../../02_molecules";
import {Td, Tr} from "../../../03_organisms";
import {ColorPickerBgText} from "../../../03_organisms/color-picker-bg-text/color-picker-bg-text";

export const CreateTranslatorSection = (
    {
        id,
        index,
        textColor,
        bgColor,
        handleChange,
        dispatchFormState,
        formStyle,
        label,
        onChange,
        value,
        image,
        itemsLength
    },
) => {
    const changeHandler = (name, value) => handleChange(name, value, id);

    return (
        <Tr className="translator-create-section">
            {/*<CreateToolActions*/}
            {/*    index={index}*/}
            {/*    color={color}*/}
            {/*    handleChange={handleChange} */}
            {/*    hideColorTextInput={true}*/}
            {/*    dispatchFormState={dispatchFormState}*/}
            {/*    length={itemsLength}/>*/}

            <Td width="50px">
                <FieldText placeholder={`הכנס אות`}
                           value={label}
                           index={index}
                           controlStyle={{marginBottom: 0, padding:"0 5px", width: '100%'}}
                           inputStyle={{width:"90px", backgroundColor: '#fff'}}
                           name={'label'}
                           changeHandler={changeHandler}/>

            </Td>
            <Td>
                <FieldText placeholder={`הכנס טקסט`}
                           value={value}
                           index={index}
                           controlStyle={{marginBottom: 0, padding:"0 5px", width: '100%'}}
                           inputStyle={{width:"140px", backgroundColor: '#fff'}}
                           name={'value'}
                           changeHandler={changeHandler}/>
            </Td>
            <Td>
                <FilesUploader name={'image'}
                               previewWidth={'90px'}
                               previewHeight={'90px'}
                               value={image}
                               index={index}
                               multiple={false}
                               triggerValue={'העלה תמונה'}
                               hideTrigger
                               maxItems={1}
                               setIsFileLoading={data => console.log('data', data)}
                               changeHandler={changeHandler}/>
            </Td>
            <Td>
                <ColorPickerBgText handleChange={changeHandler}
                                   bgColor={bgColor}
                                   textColor={textColor}/>
            </Td>
        </Tr>
    )
};