import React, { useContext } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { colors } from "../../00_base/configurations/configurations";
import PropTypes from "prop-types";
import { ThemeContext } from "../../../providers/theme-provider";

const ToolTip = (props) => {
  const { id, placeholder, tip } = props;
  const { mode } = useContext(ThemeContext);
  return (
    <>
      <span data-tip data-for={`tooltip-${id}`}>
        {placeholder}
      </span>
      <ReactTooltip
        {...props}
        backgroundColor={
          props.backgroundColor ||
          (mode === "dark" ? "rgba(255,255,255,0.9)" : "rgba(0,0,0,0.9)")
        }
        textColor={
          props.textColor || (mode === "dark" ? colors.black : colors.white)
        }
        id={`tooltip-${id}`}
      >
        <span>{tip}</span>
      </ReactTooltip>
    </>
  );
};

ToolTip.defaultProps = {
  effect: "solid",
  offset: { top: 15 },
};

ToolTip.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.any.isRequired,
  tip: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["success", "warning", "error", "info", "light"]),
  effect: PropTypes.oneOf(["float", "solid"]),
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  offset: PropTypes.object,
};

export default ToolTip;
