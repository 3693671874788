import React from "react";
import {FieldText} from "../../../../02_molecules";

export const CreateToolCoder = (
    {toolData, handleToolDataChange}
) => {

    const checkIsValid = (toolData) => {
        // const items = toolData.items.map(item => item.items.filter(galleryItem => galleryItem.editable)).flat();
        // const exceptedLength = items.length;
        // const invalidItems = items.filter(item => !item.image);
        // const invalidLength = invalidItems.length
        return true
    };
    const changeHandler = (name, value) => {
        handleToolDataChange(checkIsValid, name, value);
    };


    return (
        <div className={"create-tol-content-text"}>
            <FieldText name={"password"}
                       label={"ccc"}
                       value={toolData.password}
                       changeHandler={changeHandler}/>
        </div>
    )
};