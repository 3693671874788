import React from "react";
import NavigationGroupItem from "./navigation-group-item";
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';

export const NavigationGroup = (
    {
        id,
        setGroupIndex,
        setPageIndex,
        pageIndex,
        groupIndex,
        itemsLength
    }) => {
    let items = [];
    for (let i = 0; i < 10; i++) {
         if (groupIndex * 120 < parseInt(itemsLength)) {
            items.push(
                <NavigationGroupItem index={i + 1}
                                     key={i}
                                     setPageIndex={setPageIndex}
                                     pageIndex={pageIndex}
                                     groupIndex={groupIndex}/>
            )
        }
    }

    return (
        <div id={id} className='navigation-group'>
            <div className='navigation-group-nav'
                 style={{visibility: groupIndex > 0 ? "visible" : "hidden"}}
                 onClick={() => setGroupIndex(groupIndex - 1)}>
                <MdKeyboardArrowRight size={18}/>
            </div>

            {items.map(item => item)}

            {items.length === 10 &&
            <div className='navigation-group-nav'
                 onClick={() => setGroupIndex(groupIndex + 1)}>
                <MdKeyboardArrowLeft size={18}/>
            </div>
            }
        </div>
    )
};