import React from "react";
import {FieldText, FieldTextArea, FilesUploader} from "../../02_molecules/form-fields";

export const FrameFinalContent = (
    {
        title = "לאחר פיתרון הפריים:",
        handleChange,
        correctTitle,
        correctSubTitle,
        correctDescription,
        finalImageUrl
    }
) => {
    return (
        <div>
            <h5 className={"my-0"}>{title}</h5>

            <FieldText label="כותרת"
                       name={"correctTitle"}
                       value={correctTitle}
                       changeHandler={handleChange}/>

            <FieldText label="כותרת משנה"
                       name={"correctSubTitle"}
                       value={correctSubTitle}
                       changeHandler={handleChange}/>

            <FieldTextArea label="טקסט"
                           name={"correctDescription"}
                           value={correctDescription}
                           wysiwyg={true}
                           toolbarHidden={true}
                           changeHandler={handleChange}/>

            <FilesUploader label="תמונה"
                           name={"finalImageUrl"}
                           value={finalImageUrl}
                           multiple={false}
                           triggerValue={"תמונה שתוצג לאחר פתרון הפריים"}
                           previewWidth={"100%"}
                           triggerStyle={{width: "100%"}}
                           changeHandler={handleChange}/>
        </div>
    )
};