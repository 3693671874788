import Tag from "../models/tag";
import {API_URL, fetchData} from "./fetch-data";

const api = API_URL;
const extension = 'tags';
const url = `${api}/${extension}`;

const token = localStorage.adminToken;


export const SET_ALL_TAGS = 'SET_ALL_TAGS';
export const SET_SINGLE_TAG = 'SET_SINGLE_TAG';
export const CREATE_TAG = 'CREATE_TAG';
export const UPDATE_TAG = 'UPDATE_TAG';
export const DELETE_TAG = 'DELETE_TAG';

export const setAllTags = (pageIndex = 0,) => {
    return async (dispatch, getState) => {
        await fetchData(
            "GET",
            `${extension}/?page=${0}`,
            {},
            tagsData => {
                let allTags = [];
                for (const key in tagsData) {
                    const tagData = tagsData[key];
                    allTags.push(
                        new Tag(
                            tagData.id,
                            tagData.title,
                            tagData.tag_name,
                            tagData.description,
                            tagData.description_short,
                            tagData.icon,
                            tagData.created_at
                        )
                    )
                }
                fetchData(
                    "GET",
                    `${extension}/?page=${1}`,
                    {},
                    tagsData => {
                        for (const key in tagsData) {
                            const tagData = tagsData[key];
                            allTags.push(
                                new Tag(
                                    tagData.id,
                                    tagData.title,
                                    tagData.tag_name,
                                    tagData.description,
                                    tagData.description_short,
                                    tagData.icon,
                                    tagData.created_at
                                )
                            )
                        }
                        fetchData(
                            "GET",
                            `${extension}/?page=${2}`,
                            {},
                            tagsData => {
                                for (const key in tagsData) {
                                    const tagData = tagsData[key];
                                    allTags.push(
                                        new Tag(
                                            tagData.id,
                                            tagData.title,
                                            tagData.tag_name,
                                            tagData.description,
                                            tagData.description_short,
                                            tagData.icon,
                                            tagData.created_at
                                        )
                                    )
                                }
                                dispatch({
                                    type: SET_ALL_TAGS,
                                    allTags: allTags
                                });
                            }
                        )
                    }
                )
            }
        )
    }
};

export const getSingleTag = tagId => {
    return async (dispatch, getState) => {
        try {
            const response = await fetch(`${url}/${tagId}`);
            if (!response.ok) {
                throw new Error('Something went wrong');
            }

            const tagData = await response.json();
            return new Tag(
                tagData.id,
                tagData.title,
                tagData.tag_name,
                tagData.description,
                tagData.description_short,
                tagData.icon,
                tagData.created_at
            )
        } catch (error) {
            throw error
        }
    }
};

export const createTag = (tagData, callback = success => console.log(success)) => {
    return async (dispatch, getState) => {
      await fetchData(
          "POST",
          extension,
          tagData,
          success => {
              dispatch({
                  type: CREATE_TAG,
                  tag:success.data
              });
              callback(success.data);
          }
      )

    }
};

export const updateTag = (tagData, callback = success => console.log(success)) => {
    return async (dispatch, getState) => {
        await fetchData(
            "PUT",
            `${extension}/${tagData.id}`,
            tagData,
            success => {
                dispatch({
                    type: UPDATE_TAG,
                    ...success.data
                });
                callback(success.data);
            })
    };
};

export const deleteTag = tagId => {
    return async (dispatch, getState) => {
        await fetchData(
            "DELETE",
            `${extension}/${tagId}`,
            {},
            success => {
                dispatch({type: DELETE_TAG, id: tagId})
                return success
            }
        )
    }
};