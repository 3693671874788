import React from "react";
import {Row, Col} from "react-grid-system";
import {DICT} from "../../../../languages/dictionary-names";
import {Translation} from "../../../../languages";
import {font_family} from "../../../00_base/variables";
import {Heading, Image} from "../../../01_atoms/";

const THUMBNAIL =  "https://storage.googleapis.com/assets.youdoadventures.com/website/riddles_thumbnail.png";

export const RiddleStoreHeader = () => {
    return (
        <div className="pb-5">
            <Row className="justify-content-center align-items-start pt-5 pt-lg-7 pb-lg-5">
                <Col xl={7}>
                    <Heading tag="h1"
                             style={{fontFamily: font_family.medium, marginBottom: 0, fontWeight: 500}}
                             value={<Translation id={DICT.RIDDLE_STOCK_TITLE}/>}/>
                    <p className="color-black font-size-lg" style={{lineHeight: "3.6rem"}}>
                        <Translation id={DICT.RIDDLE_STOCK_DESCRIPTION}/>
                    </p>
                </Col>
                <Col xs={8} xl={5}
                     className="d-flex align-items-center justify-content-start justify-content-xl-end">
                    <Image src={THUMBNAIL} width="100%"/>
                </Col>
            </Row>

            {/*<StarsDictionary isLoading={isLoading}*/}
            {/*getFilteredRiddlesStoreData={getFilteredRiddlesStoreData}*/}
            {/*difficultyFilterLevel={difficultyFilterLevel}*/}
            {/*setDifficultyFilterLevel={setDifficultyFilterLevel}/>*/}

            {/*{!isLoading && <MobileHidden><DifficultyDictionary/></MobileHidden>}*/}
            {/*<MobileHidden>*/}
            {/*    {*/}
            {/*        (difficultyFilterLevel !== null) &&*/}
            {/*        <div className={"d-flex justify-content-center align-items-center my-3"}>*/}
            {/*            {buildStars(difficultyFilterLevel).map((Star, index) => <Star key={index} size={30}*/}
            {/*                                                                          color={starColor}/>)}*/}
            {/*            <h4 className={"mr-4"}>({getFilteredRiddlesStoreData().length} חידות) </h4>*/}
            {/*            <h6 className={"mr-4"} style={{cursor: "pointer", textDecoration: "underline"}}*/}
            {/*                onClick={() => setDifficultyFilterLevel(null)}>בטל</h6>*/}
            {/*        </div>*/}
            {/*    }*/}
            {/*</MobileHidden>*/}
        </div>
    )
};