import React from "react";
import {MdClose} from "react-icons/md";
import {FieldText, FilesUploader} from "../../../02_molecules";
import {Td, TdContent, Tr} from "../../../03_organisms";
import {ColorPickerBgText} from "../../../03_organisms";

export const ChooseItemSection = (
    {
        id, text, index, bgColor, textColor, image, removeSection, handleChange
    }) => {

    const changeHandler = (name, value) => {
        handleChange(name, value, id)
    };


    return (
        <Tr className="create-tool-choose-item">
            <Td>
                <TdContent>
                    {index}
                </TdContent></Td>
            <Td>
                <TdContent>
                    <ColorPickerBgText handleChange={changeHandler}
                                       bgColor={bgColor}
                                       textColor={textColor}/>
                </TdContent>
            </Td>
            <Td>
                <TdContent>
                    <FilesUploader name="image"
                                   previewWidth={60}
                                   previewHeight={60}
                                   triggerValue="הכנס תמונה"
                                   triggerStyle={{fontSize: "16px"}}
                                   changeHandler={changeHandler}
                                   controlStyle={{marginBottom: "0"}}
                                   multiple={false}
                                   value={image}/>
                </TdContent>
            </Td>
            <Td>
                <TdContent>
                    <FieldText name="text"
                               value={text}
                               placeholder="הכנס טקסט"
                               controlStyle={{marginBottom: "0"}}
                               changeHandler={changeHandler}/>
                </TdContent>
            </Td>
            <Td>
                <TdContent className="content-tool-action-icon">
                    <MdClose onClick={() => removeSection(id)} size={20}/>
                </TdContent>
            </Td>
        </Tr>
    )
}