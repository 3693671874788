import React from 'react';
import {Link} from 'react-router-dom';
import {Element, HBox, VBox} from 'react-stylesheet';
import {gutters} from "../../../00_base/variables";
import {Button, Heading, Paragraph} from "../../../01_atoms";
import {SkewElement} from "../../../02_molecules";
import {Modal} from "../../../03_organisms";

const PaymentSuccess = () => {
    return (
        <Modal hideTrigger={true} isActive={"active"} closeModal={() => window.location.href = '/user'}
               background={'#000000'}>
            <VBox alignItems={'center'}>
                <Element marginBottom={gutters.lg}>
                    <Heading textAlign={'center'}>תודה שיצאתם איתנו להרפתקה</Heading>
                    <Paragraph textAlign={'center'}>היכנסו למייל ועיקבו אחר ההנחיות</Paragraph>
                </Element>

                <SkewElement background={'#efe7e2'}
                             before={{deg: '-2', origin: 'left', direction: 'Y'}}
                             after={{deg: '-1', origin: 'right', direction: 'Y'}}>
                    <HBox justifyContent={'center'}>
                        <Link to={'/user'}>
                            <Button>לתיבת ההרפתקאות שלי</Button>
                        </Link>
                    </HBox>
                </SkewElement>
            </VBox>
        </Modal>
    )
};

export default PaymentSuccess;