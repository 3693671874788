import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {createTag} from "../../../store/actions/tags-actions";
import {selectAllTags} from "../../../store/selectors";
import {font_size} from "../../00_base/variables";
import FieldSelectMulti from "../../02_molecules/form-fields/field-select-muilti/m-field-select-multi";

export const TagsSelector = ({label = "תגיות", value, name, handleChange}) => {
    const allTags = useSelector(selectAllTags);
    const dispatch = useDispatch();

    const changeHandler = (name, value) => handleChange(name, value.map(item => item.value));

    const createNewTag = (selectedOptions) => {
        const newValue = selectedOptions.find(item => item.__isNew__);
        dispatch(createTag(
            {title: newValue.label},
            newTag => {
                const updatedValue = selectedOptions.map(item => item.__isNew__ ? {
                    label: newTag.title,
                    value: newTag.id,
                    id: newTag.id
                } : item);

                changeHandler(name, updatedValue)
            }
        ))
    };

    const tagsValue = allTags.length && value.filter(tag => tag !== null).map(tag => allTags.find(item => item.id === tag))?.map(tag => {
        return {label: tag?.title, value: tag?.id}
    });

    const tagsOptions = allTags.map(tag => {
        return {id: tag.id, label: tag.title, value: tag.id}
    });

    if (allTags?.length > 0) {
        return (
            <FieldSelectMulti name={name}
                              label={label}
                              isMulti={true}
                              options={tagsOptions}
                              inputStyle={{fontSize: font_size.sm}}
                              value={tagsValue}
                              handleCreateNew={createNewTag}
                              changeHandler={changeHandler}/>
        )
    }
    return <></>
};