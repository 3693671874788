import React from 'react';
import PropTypes from 'prop-types';
import {BlockAdventures} from './hp-block-adventures';
import BlockMain from './block-main/hp-block-main';
import {BlockExtra} from './block-extra/hp-block-extra';

const ScreenHomepage = () => {
    return (
        <div className={"container page-homepage"}>
            <BlockMain/>
            <BlockAdventures/>
            <BlockExtra/>
        </div>
    )
};

ScreenHomepage.default = {
    title: 'ברוכים הבאים',
};
ScreenHomepage.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

export default ScreenHomepage;