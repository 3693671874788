import React from 'react';
import styled from 'styled-components';
import {viewport} from '../../00_base/variables/v-breakpoints';

const youdo_logo = 'https://storage.googleapis.com/assets.youdoadventures.com/website/youdo_logo.png';
const youdo_logo_white = 'https://storage.googleapis.com/assets.youdoadventures.com/website/youtube-white.png';

class YoudoAdventuresLogo extends React.Component {
    render() {
        const {width, maxWidth, white, mobileMaxWidth, backgroundColor} = this.props;
        const logoStyle = {
            display: 'block',
            margin: '0 auto',
            width: width,
            maxWidth: maxWidth,
            backgroundColor: backgroundColor
        };
        return (
            <SiteLogo id='site-logo'
                      className='site-logo'
                      mobileMaxWidth={mobileMaxWidth}>
                <img src={white ? youdo_logo_white : youdo_logo} alt="Youdo Logo" style={logoStyle}/>
            </SiteLogo>
        )
    }

    static defaultProps = {
        width: '150px',
        maxWidth: '100%',
        src: youdo_logo
    }
}

export default YoudoAdventuresLogo;

const SiteLogo = styled.div`
    @media screen and (max-width: ${viewport.md}) {
        max-width: ${props => props.mobileMaxWidth};
        margin-left: auto;
        margin-right: auto;
    }
`;