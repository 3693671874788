import React, {useCallback, useContext, useEffect, useState} from 'react';
import classNames from "classnames";
import {useSelector} from "react-redux";

import {
    Table,
    TableContainer,
    tableHeader,
    TableHeaderLabel, TableInnerMenu,
    TableSearchBar,
    TableTitle,
    TableWrapper,
    Tr
} from "../../../03_organisms";
import {BLOGS_MOCK} from "../../../../store/mocks/blog"
import {BlogItem} from "./blog-item/blog-item";
import _ from "lodash"
import Modal from "../../../03_organisms/modal";
import {VBox} from "react-stylesheet";
import {selectAllBlogs} from "../../../../store/selectors";
import SingleBlog from "../../../website/pages/blog/single-blog";
import {LanguageContext} from "../../../../languages";


const ScreenAdminBlog = () => {
    const {languages} = useContext(LanguageContext)

    const [searchBy, setSearchBy] = useState("");
    const [activeModalId, setActiveModalId] = useState(null);
    const blogs = useSelector(selectAllBlogs);


    const HeaderLabel = ({name, label, action}) => {
        let Icon, iconSize, onClick, menuItems = [];

        return (
            <TableHeaderLabel>
                {Icon && <Icon onClick={onClick}
                               className={classNames("table-header-icon")}
                               size={iconSize}
                               color={"#000"}/>}
                {label}
                {/* {menuActive && <HeaderMenu items={menuItems}/>} */}
            </TableHeaderLabel>
        )
    };

    const blogProperties = [
        {title: "מזהה", key: "id", style: {width: "15px"}},
        {title: "כותרת", key: "title", editable: true},
        {title:"פעיל",key:"is_active", editable:true},
        {title:"שפה",key:"language_id", editable:true},
        {title: "תאריך יצירה", key: "created_at"},
    ];

    const tableHeaderItems = blogProperties.map(blogProperty => <HeaderLabel label={blogProperty.title}
                                                                             name={blogProperty.key}/>);

    const tableRow = (blog, index) => {
        return <BlogItem item={blog}
                         blogProperties={blogProperties}
                         key={blog.id}
                         index={index}
                         closeInnerMenu={() => {
                         }}
                         changeHandler={() => {
                         }}
                         toggleMarkItem={() => {
                         }}
                         setActiveModalId={setActiveModalId}
        />
    };

    const filterBlogs = (newFilterString) => {
        setActiveModalId(null)
        setSearchBy(newFilterString)
    };

    const filteredBlogs = !_.isEmpty(searchBy) ?
        blogs.filter(items => Object.values(items).filter(item => item.includes ? item.includes(searchBy) : item === searchBy).length > 0) :
        blogs;

    return (
        <TableWrapper>
            <TableTitle>
                <h1>בלוג</h1>
            </TableTitle>
            <TableContainer fluid className={"adventure-type-gallery"}>
                {activeModalId !== null &&
                <Modal isActive={"active"} hideTrigger closePreActions={() => setActiveModalId(null)}
                       contentStyle={{margin: "0", padding: "0", height: "96vh", width: "96vw"}}
                       childrenWrapperStyle={{height: "100%", width: "100%"}}>
                    <SingleBlog id={activeModalId}/>
                    {/*<iframe src={"https://youdoadventures.com/he/blog"} style={{height: "100%", width: "100%"}}/>*/}
                </Modal>
                }

                <TableSearchBar filterIndicators={() => {
                }}
                                removeAllFilters={() => {
                                }}
                                filterLength={0}
                                handleSearchClick={filterBlogs}
                                addLabel={"+ צור בלוג חדש"}
                                handleAddClick={() => window.location.href = window.location.href.substring(0, window.location.href.length - 1) + "/"}/>

                <Table cellSpacing="0" cellPadding="0">
                    <thead><Tr>{tableHeaderItems.map(tableHeader)}</Tr></thead>
                    <tbody>{filteredBlogs.map(tableRow)}</tbody>
                </Table>

            </TableContainer>
        </TableWrapper>
    )
}

export default ScreenAdminBlog;