import React from "react";
import {MdArrowDropDown, MdArrowDropUp} from "react-icons/md";

export const ToggleIcon = (
    {
        isOpen,
        toggle,
        size = 20,
        color = "#000",
        className
    }) => {
    const Icon = isOpen ? MdArrowDropUp : MdArrowDropDown;

    return <Icon onClick={toggle}
                 size={size}
                 color={color}
                 className={className}
                 style={{cursor: "pointer"}}/>
};