import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import {colors, font_size} from '../../00_base/variables';

const Button = ({
    size,
    padding,
    block,
    border,
    style,
    className,
    children,
    value,
    radius,
    isLoading,
    variant,
    pointer,
    onClick,
    outline }) => {

    return (
        <ButtonStyle
            onClick={onClick}
            className={`btn ${className}`}
            fontSize={font_size[size]}
            block={block}
            border={border}
            radius={radius}
            padding={padding}
            {...colors.variants[variant]}
            outline={outline}
            pointer={pointer}
            style={style}>
            {isLoading ? '...' : children || value}
        </ButtonStyle>
    )
}

const ButtonStyle = styled.button`
    width: ${props => props.block ? '100%' : 'auto'};
    display: ${props => props.block && 'block'};
    background-color: ${props => props.outline ? 'transparent' : props.backgroundColor};
    color: ${props => props.outline ? colors.black : props.color};
    font-size: ${props => props.fontSize};
    border: ${props =>  props.border ?  props.border : props.outline ? `2px solid ${props.backgroundColor}` : 0};
    border-color: ${props => props.outline && props.backgroundColor};
    border-radius: ${props => props.radius};
    padding: ${props => props.padding};
    cursor: ${props => props.pointer && 'pointer'};
    outline: none; 
    &:hover {
        background-color: ${props => props.backgroundColorHover};
        color: ${props => props.colorHover}
    }
`;

Button.defaultProps = {
    className: '',
    value: 'button',
    variant: 'primary',
    size: 'sm',
    border: 0,
    radius: 0,
    padding:'5px 15px',
    block: false,
    outline: false,
    onClick: null
};

Button.propTypes = { 
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']),
    padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.oneOf(['primary', 'secondary', 'success', 'error', 'warning', 'disable', 'link']),
    block: PropTypes.bool,
    outline: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default Button;