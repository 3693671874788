import styled from 'styled-components';
import {colors, font_size, gutters} from '../../../00_base/variables/';


export const fieldStyle = {
    control: {
        width: '100%',
        marginBottom: gutters.sm
    },
    label: {
        color: colors.black,
        fontSize: font_size.sm,
        marginBottom: gutters.xs,
    },
    input: {
        padding: `${gutters.xs} ${gutters.sm}`,
        color: colors.black,
        border: `.1rem solid ${colors.border}`,
        backgroundColor: 'transparent',
        outline: 'none',
        borderRadius: '.2rem',
        fontSize: font_size.sm
    },
    horizontal: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    vertical: {
        display: 'flex',
        flexDirection: 'column',
    }
};

export const FieldControl = styled.div`
    width: 100%;
    margin-bottom: ${props => props.marginBottom || gutters.sm};
    position: relative;
`;
export const FieldLabel = styled.label`
    color: ${colors.black};
    font-size: ${font_size.sm};
    position: absolute;
    z-index: 1;
    transition: all .3s;
    padding-right: ${gutters.sm};
    left: 0;
    top: 8px;
    color: ${colors.gray_4};
    .theme-dark & {
        color: ${colors.gray_1};
    }
    input:focus + &, 
    input.focus + & {
        .route-website & {
            top: -16px;
            color: ${colors.black};
            font-size: ${font_size.xs};
            .theme-dark & {
                color: ${colors.white};
            }
        }
    }
    .lang-rtl & {
        padding-right: 0;
        right: 0;
        left: auto;
        padding-left: ${gutters.sm};
    }
    .route-admin & {
        position: static;
        font-weight: normal;
        color: black;
        margin-bottom: .8rem
    }
`;
export const FieldInput = styled.input`
    padding: ${gutters.xs} 0;
    color: ${colors.black};
    border:0;
    border-bottom: .1rem dashed ${colors.black};
    background-color: transparent;
    outline: 0;
    z-index: 2;
    font-size: ${font_size.sm};
    .theme-dark & {
        color: ${colors.white};
        border-bottom: .1rem dashed ${colors.white};
    }
    .route-admin & {
        border: .1rem solid ${colors.border};
        border-radius: .8rem;
        height: 3rem;
        border-radius: .2rem;
        padding: ${gutters.xs} ${gutters.sm};
        &.focus, &:focus {
            background-color:#fff;
        }
        font-size: ${font_size.xs};
     }
    ${props => `
        &:-webkit-autofill {
            // -webkit-box-shadow: 0 0 0px 1000px white inset !important; 
        }
        &::placeholder {
            color: ${props.colorPlaceholder}
        }
        // &:focus {
        //     border: .1rem solid ${colors.black};
        //     background: ${props.backgroundFocus || colors.white};
        //     box-shadow: ${props.boxShadow};
        // }
        // &:hover {
        //     border: ${props.borderHover};
        //     background: ${props.backgroundHover || colors.white};
        //     box-shadow: ${props.boxShadow};
        // }
    `}
`;
