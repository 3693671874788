import React from 'react';
import styled from 'styled-components';

class IconSearch extends React.Component {
    render() {
        return (
           <SearchStyle className={'search'}>
                   <input id="input_search" type="text"/>
           </SearchStyle>
        )
    }
}

export default IconSearch;

const SearchStyle = styled.div`
    display: inline-block;
	position: relative;
	height: 14px;
	width: 14px; 
	box-sizing: border-box;
    margin: 7px 0px 8px 0px;
    padding: 0 7px;
	border: 1px solid #151515;
	border-radius: 25px;
	transition: all 200ms ease;
	cursor: text;
	
	&:after {
		content: "";
		position: absolute;
		width: 1px;
		height: 9px;
        right: -1px;
        top: 9px;
		background: #151515;
		border-radius: 2px;
		transform: rotate(-45deg);
		transition: all 200ms ease;
	}
	
	&.active,
	&:hover {
		width: 110px;
		margin-right: 0px;
		height: 20px;
		
		&:after {
			height: 0px;	
		}
	}
	
	input {
		width: 100%;
		border: none;
		box-sizing: border-box;
		font-family: Helvetica;
		font-size: 15px;
		color: inherit;
		background: transparent;
		outline-width: 0px;
		padding: 0;
		line-height: 0;
	}
`;
