import React, {useContext, useEffect, useReducer, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import * as adventureTypeActions from "../../../../../store/actions/adventure-type-actions";
import {selectCurrentAdventureType} from "../../../../../store/selectors";
import {LanguageContext} from "../../../../../languages";
import {Button} from "../../../../01_atoms";
import {colors, gutters} from "../../../../00_base/variables";
import {FieldDatePicker, FieldOptions, FieldText, FilesUploader} from "../../../../02_molecules";

const OVED = 324;
const OVEDET = 330;
const ENGLISH = 332;

const reducer = (state, action) => {
    state[action.name] = action.value;
    return state;
};

export const AdventureTypeFormSpecial = ({dispatchAdventureData, createNewAdventure}) => {
    const adventureType = useSelector(selectCurrentAdventureType);
    const language = useContext(LanguageContext).userLanguage
    const {dictionary} = useContext(LanguageContext);
    const isEnglish = language === "en";
    const isHebrew = language === "he";

    const genderOptions = [
        {id: 0, value: "male", label: dictionary.male},
        {id: 1, value: "female", label: dictionary.female},
    ];

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (isHebrew && adventureType.id === ENGLISH) {
            navigate(`/adventure/${OVED}`);
        }

        if (!isHebrew && (adventureType.id === OVED || adventureType.id === OVEDET)) {
            navigate("/adventure/332");
        }

    }, [adventureType.id, navigate, isHebrew, language]);

    const defaultFormValue = {
        employee_name: "",
        employee_gender: genderOptions[adventureType.id === OVEDET ? 1 : 0],
        employee_email: "",
        employee_year_of_birth: "",
        employee_company_name: "",
        employee_company_logo: "",
        language: adventureType.id === ENGLISH ? "en" : "he"
    }
    const [form, dispatchForm] = useReducer(reducer, defaultFormValue)
    const [isLoading, setIsLoading] = useState(false);
    const [warningsActive, setWarningsActive] = useState(false);
    const isFemale = form.employee_gender?.value === "female" || adventureType.id === OVEDET;
    const textAlign = isEnglish ? "left" : "right";
    const submitStyle = {marginTop: "20px", color: colors.white}


    const handleChange = (name, value) => {
        dispatchForm({name: name, value: value});
        setWarningsActive(false);
        const adventureTypeId = value === "en" ? ENGLISH : value === "female" ? OVEDET : OVED;

        if (name === "employee_gender" || name === "language") {
            navigate(`/${language}/adventure/${adventureTypeId}`);
            dispatch(adventureTypeActions.setSingleAdventureType(
                adventureTypeId,
                (adventureType) => {
                    dispatchAdventureData(adventureType.adventuredata_id)
                }
            ))
        }
    };

    const checkValidation = () => {

        return (
            form.employee_name &&
            form.employee_gender &&
            form.employee_email &&
            form.employee_year_of_birth &&
            form.employee_company_name &&
            form.employee_company_logo
        )
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const adventure = {
            title: `${isEnglish ? "Birthday to " : "יומולדת ל"}${form.employee_name}`,
            adventure_name: form.employee_name,
            thumbnail: form.employee_company_logo,
            language: form.language,
            json_data: {...form, employee_gender: form.employee_gender?.value}
        };

        if (checkValidation()) {
            setWarningsActive(false)
            createNewAdventure(adventure);
        } else {
            setWarningsActive(true);
            setIsLoading(false);
        }
    };


    const EMPLOYEE_NAME = <FieldText name="employee_name"
                                     labelStyle={{textAlign: textAlign}}
                                     inputStyle={{textAlign: textAlign}}
                                     label={isEnglish ? "Employee Name" : isFemale ? "שם העובדת" : "שם העובד"}
                                     changeHandler={handleChange}
                                     errorMessage={isEnglish ? "Please insert employees' name" : "יש להכניס את שם העובד"}
                                     errorMessageActive={warningsActive && !form.employee_name}/>;

    const EMPLOYEE_GENDER = !isEnglish && <FieldOptions name="employee_gender"
                                                        options={genderOptions}
                                                        inputHeight={"50px"}
                                                        controlStyle={{marginBottom: gutters.lg}}
        // label="זכר / נקבה"
                                                        labelStyle={{textAlign: textAlign}}
                                                        inputStyle={{textAlign: textAlign, fontSize: "20px"}}
                                                        value={form.employee_gender}
                                                        initialValue={form.employee_gender}
                                                        changeHandler={handleChange}/>;

    const EMPLOYEE_EMAIL = <FieldText name="employee_email"
                                      labelStyle={{textAlign: textAlign}}
                                      inputStyle={{textAlign: textAlign}}
                                      label={isEnglish ? "Employee email" : isFemale ? "מייל העובדת" : "מייל העובד"}
                                      changeHandler={handleChange}
                                      errorMessage={isEnglish ? "Please insert employees' email address" : "יש להכניס את כתובת המייל של העובד"}
                                      errorMessageActive={warningsActive && !form.employee_email}/>;

    const EMPLOYEE_YEAR_OF_BIRTH = <FieldDatePicker name="employee_year_of_birth"
                                                    labelStyle={{textAlign: textAlign}}
                                                    inputStyle={{textAlign: textAlign}}
                                                    controlStyle={{marginBottom:"20px", zIndex: 3}}
                                                    label={isEnglish ? "Employee year or birth" : isFemale ? "תאריך הלידה של העובדת" : "תאריך הלידה של העובד"}
                                                    changeHandler={(name, value) => handleChange(name, value.year?.toString())}
                                                    errorMessage={isEnglish ? "Please insert employees' date of birth" : "יש להכניס את תאריך הלידה של העובד"}
                                                    errorMessageActive={warningsActive && !form.employee_year_of_birth}/>;

    const EMPLOYEE_COMPANY_NAME = <FieldText name="employee_company_name"
                                             labelStyle={{textAlign: textAlign}}
                                             inputStyle={{textAlign: textAlign}}
                                             label={isEnglish ? "Company name" : "שם החברה"}
                                             changeHandler={handleChange}
                                             errorMessage={isEnglish ? "Please insert the company name" : "יש להכניס את שם החברה"}
                                             errorMessageActive={warningsActive && !form.employee_company_name}/>;

    const EMPLOYEE_COMPANY_LOGO = <FilesUploader name="employee_company_logo"
                                                 labelStyle={{
                                                     textAlign: textAlign,
                                                     display: "block",
                                                     marginBottom: "5px"
                                                 }}
                                                 triggerValue={isEnglish ? "Company logo" : "לוגו חברה"}
                                                 label={isEnglish ? "Company logo" : "לוגו חברה"}
                                                 multiple={false}
                                                 previewWidth={180}
                                                 previewHeight={180}
                                                 changeHandler={handleChange}
                                                 errorMessage={isEnglish ? "Please insert the company logo" : "יש להכניס את לוגו החברה"}
                                                 errorMessageActive={warningsActive && !form.employee_company_logo}/>;

    const submitValue = isLoading ? "..." : isEnglish ? "Start creating your adventure" : "התחל ביצירת ההרפתקה"
    return (
        <div>
            {EMPLOYEE_GENDER}
            {EMPLOYEE_NAME}
            {EMPLOYEE_EMAIL}
            {EMPLOYEE_YEAR_OF_BIRTH}
            {EMPLOYEE_COMPANY_NAME}
            {EMPLOYEE_COMPANY_LOGO}
            <Button block
                    onClick={handleSubmit}
                    value={submitValue}
                    style={submitStyle}/>
        </div>
    )
};
