import React, { useState } from "react";
import { DICTIONARY } from "../../../dictionary/dictionary";
import { Link } from "react-router-dom";
import { colors, font_size, gutters, viewport } from "../../00_base/variables";
import styled from "styled-components";
import { StarsBackground } from "../../03_organisms/stars-background/stars-background";
import Page from "../../04_layout/app-page/l-page";
import { Heading, Button, Span } from "../../01_atoms/";
import FieldText from "../../02_molecules/form-fields/text/m-field-text";
import SimpleLoader from "../../01_atoms/loaders/simple-loader/loader";
import Image from "../../01_atoms/image/a-image";
import Circle from "../../02_molecules/shapes/circle/circle";
import LOGO_WHITE from "../../../assets/images/logos/youdo_logo_white.png";
import LOGO_BLACK from "../../../assets/images/logos/youdo_logo.png";

export const ScreenHomepageView = ({
  props,
  adventure,
  adventureData,
  adventureType,
  riddles,
  isPageLoading,
  passwordConfirmed,
  passcode,
  setPasscode,
  isLoading,
  passcodeSubmitHandler,
  counting,
  videoEnded,
  error,
}) => {
  const icon = adventure?.thumbnail || adventureType?.logo_url;
  const language = adventure?.language || "Hebrew";
  const {
    welcome_title_size = 32,
    bg_color,
    text_color,
    continue_bg_color,
    continue_text_color,
    logo_type = "white",
    welcome_pre_title = "",
  } = adventureType || {};
  const logoType = adventure?.json_data?.logo_type || logo_type;
  const opening_video_url =
    adventure?.json_data?.opening_video_url ||
    adventureType?.opening_video_url ||
    (language === "Arabic"
      ? "https://api.youdoadventures.com/images/1597009618276-instructions_video_arabic_mp4.mp4"
      : "https://api.youdoadventures.com/images/1584977124801-instructions_mp4.mp4");

  const employeeBirthday =
    adventureType?.id === 324 ||
    adventureType?.id === 330 ||
    adventureType?.id === 332;
  const noLobby = employeeBirthday || "";

  const title = (
    adventure?.json_data?.welcome_pre_title ||
    welcome_pre_title ||
    ""
  ).concat(adventure?.title || "");
  const titleDynamicStyle = {
    color: adventure?.json_data?.text_color || text_color,
    fontSize: `${
      adventure?.json_data?.welcome_title_size || welcome_title_size || 32
    }px`,
  };

  const showStars =
    !passwordConfirmed ||
    (!(
      (adventureData?.welcome_screen_url_mobile &&
        adventureData?.welcome_screen_url_mobile !== "false") ||
      adventureType?.entrance_image_mobile
    ) &&
      !(
        (adventureData?.welcome_screen_url_desktop &&
          adventureData?.welcome_screen_url_desktop !== "false") ||
        adventureType?.entrance_image_desktop
      ));

  const mobileBackgroundImage =
    adventureData?.welcome_screen_url_mobile &&
    adventureData?.welcome_screen_url_mobile !== "false"
      ? adventureData?.welcome_screen_url_mobile
      : adventure?.json_data?.entrance_image_mobile ||
        adventureType?.entrance_image_mobile;
  const desktopBackgroundImage =
    adventureData?.welcome_screen_url_desktop &&
    adventureData?.welcome_screen_url_desktop !== "false"
      ? adventureData?.welcome_screen_url_desktop
      : adventure?.json_data?.entrance_image_desktop ||
        adventureType?.entrance_image_desktop;

  return (
    <Page
      {...props}
      style={{
        display: "flex",
        flexDirection: "column !important",
        backgroundColor: adventure?.json_data?.bg_color || bg_color,
        color: adventure?.json_data?.text_color || text_color,
      }}
    >
      <WrapperImage
        style={wrapper}
        mobile={mobileBackgroundImage}
        desktop={desktopBackgroundImage}
      >
        {showStars && <StarsBackground />}
        {!isPageLoading &&
          (!passwordConfirmed ? (
            <div style={passcodeFormWrapper}>
              {/*{adventureType && <Image src={adventureType.logo_url} width={180}/>}*/}
              <Heading tag={"h2"} style={titleStyle}>
                הזן סיסמת הרפתקה
              </Heading>
              <Span style={{ ...validationMessage, opacity: !!error ? 1 : 0 }}>
                {error ? "הרפתקה לא קיימת" : `---`}
              </Span>

              <FieldText
                changeHandler={(name, value) => setPasscode(value)}
                value={passcode}
                initialValue={passcode}
                keydownHandler={(e) =>
                  e.keyCode === 13 && passcodeSubmitHandler(e.target.value)
                }
                name="passcode"
                inputStyle={inputStyle}
              />
              <Button onClick={passcodeSubmitHandler} style={submitStyle}>
                {isLoading ? (
                  <SimpleLoader size={20} color={colors.white} />
                ) : (
                  "המשך"
                )}
              </Button>
            </div>
          ) : (
            <div style={successWrapper}>
              <Heading
                tag="h2"
                value={title}
                style={{ ...titleStyle, ...titleDynamicStyle }}
              />
              {icon && (
                <div style={iconWrapper}>
                  <Image width="200px" src={icon} />
                </div>
              )}
              {!employeeBirthday && (
                <div>
                  <video id="homepage-video" autoPlay controls style={video}>
                    <source
                      type="video/mp4"
                      src={
                        adventure?.json_data?.opening_video_url ||
                        opening_video_url
                      }
                    />
                  </video>
                </div>
              )}
              {(employeeBirthday || videoEnded) && (
                <Button
                  style={{
                    ...startButton,
                    backgroundColor:
                      adventure?.json_data?.continue_bg_color ||
                      continue_bg_color,
                  }}
                >
                  <Link
                    to={
                      noLobby && riddles
                        ? `/riddle/${riddles[0].id}/1`
                        : `/menu/${adventure?.adventuredata_id}`
                    }
                    style={{
                      ...startLinkStyle,
                      color:
                        adventure?.json_data?.continue_text_color ||
                        continue_text_color ||
                        "#fff",
                      direction: adventureType?.id === 332 ? "ltr" : "rtl",
                    }}
                  >
                    {adventure?.json_data?.entrance_cta ||
                      adventureType?.entrance_cta ||
                      (adventureType?.id === 332
                        ? "Are You Ready?"
                        : DICTIONARY.are_you_ready[language])}
                  </Link>
                </Button>
              )}
              {!!counting && (
                <Circle style={countingCircle} size={200}>
                  <Span value={counting} style={countingStyle} />
                </Circle>
              )}
            </div>
          ))}

        {logoType !== "hidden" && (
          <div style={logoWrapper}>
            <Image
              src={logoType === "black" ? LOGO_BLACK : LOGO_WHITE}
              width={160}
            />
          </div>
        )}
      </WrapperImage>
    </Page>
  );
};

const WrapperImage = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  ${(props) => `
        ${
          (props.mobile || props.desktop) &&
          `background-image: url(${props.mobile || props.desktop})`
        };
            ${
              props.desktop &&
              `@media(min-width: ${viewport.lg}) {
                    background-image: url(${props.desktop});
                }`
            }`}
`;

const wrapper = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100%",
  width: "100%",
  padding: `0 ${gutters.md}`,
  margin: "0 auto",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  flex: 1,
};
const logoWrapper = {
  position: "relative",
  zIndex: 1,
  marginTop: gutters.md,
  textAlign: "center",
};
const passcodeFormWrapper = {
  position: "relative",
  zIndex: 1,
  width: "600px",
  maxWidth: "100%",
  margin: "0 auto",
};
const countingCircle = {
  position: "absolute",
  border: `3px solid ${colors.white}`,
  background: colors.black,
};
const countingStyle = {
  fontSize: font_size.max,
  color: "#fff",
};
const startButton = {
  fontSize: font_size.lg,
  color: colors.white,
  backgroundColor: colors.primary,
  minWidth: "150px",
  padding: "10px 20px",
  borderRadius: ".8rem",
  margin: "30px auto",
};
const startLinkStyle = {
  display: "block",
  color: "#fff",
};
const titleStyle = {
  color: colors.white,
  textAlign: "center",
  textShadow: "1px 1px 1px #000",
  marginBottom: gutters.xs,
};
const video = {
  width: "100%",
};
const iconWrapper = {
  marginBottom: gutters.lg,
};
const successWrapper = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  zIndex: 1,
};
const validationMessage = {
  color: colors.white,
  textAlign: "center",
  fontSize: font_size.sm,
};
const inputStyle = {
  backgroundColor: "#fff",
  textAlign: "center",
  fontSize: font_size.xl,
};

const submitStyle = {
  height: "4rem",
  width: "120px",
  borderRadius: ".8rem",
  margin: "0 auto",
  display: "block",
  fontSize: font_size.md,
};
