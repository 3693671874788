import React from "react";
import {Container} from "react-grid-system";
import {LoaderContent} from "../../01_atoms/loaders/loader-content/loader-content";

export const SingleAdventuresLoading = () => {
    return <div className={"adventure-type"}>
        <Container style={loadingContainer}>
            <LoaderContent/>
            <LoaderContent/>
            <LoaderContent/>
        </Container>
    </div>
};

const loadingContainer = {
    paddingTop: "100px"
};