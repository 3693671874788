import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {selectUserAdventures} from "../../../store/selectors";
import {Feed, VerticalScroller} from "../index";
import {ScreenUserItem} from "../../website/pages/user/screen-user-item";
import {Heading} from "../../01_atoms";
import {Translation} from "../../../languages";
import {DICT} from "../../../languages/dictionary-names";
import "../../../assets/styles/user-progress-adventures.scss";
import {ThemeContext} from "../../../providers/theme-provider";
import {colors} from "../../00_base/variables";

export const UserProgressAdventures = () => {
    const userAdventures = useSelector(selectUserAdventures);
    const {mode} = useContext(ThemeContext);
    const textColor = colors[mode === "light" ? "black" : "white"];

    const progressAdventures = userAdventures.filter(adventure => !adventure.is_paid);
    const renderFeedItem = (adventure) => {
        return (
            <ScreenUserItem adventure={adventure}
                            minimized={true}
                            key={adventure.id}/>
        )
    };
    return (
        <VerticalScroller className="user-progress-adventures">
            <Heading tag={"h5"} color={textColor}><Translation id={DICT.ADVENTURES_IN_PROGRESS}/></Heading>
            <Feed items={progressAdventures.slice(0,4).map(renderFeedItem)} spaceBetweenItems={5}/>
        </VerticalScroller>
    )
}