import React, {useState} from 'react';
import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import {fieldStyle} from '../field-style/m-field-style';
import {Label} from '../../../01_atoms';
import {Element, HBox} from 'react-stylesheet';
import {MdEdit} from 'react-icons/md';
import {font_size, gutters} from '../../../00_base/variables';
import {Grid, Modal} from '../../../03_organisms';
import Card4 from '../../card/card-types/m-card-4';
// const options = [
//     { value: 'chocolate', label: 'Chocolate' },
//     { value: 'strawberry', label: 'Strawberry' },
//     { value: 'vanilla', label: 'Vanilla' },
// ];

/**
 * Customize styles: https://react-select.com/styles
 */

const FieldSelect = ({
                         name,
                         options,
                         label,
                         value,
                         inputStyle,
                         inputStyleWithValue = {backgroundColor: "#fff"},
                         labelStyle,
                         placeholder,
                         className,
                         hideArrow,
                         controlStyle,
                         isMulti,
                         changeHandler,
                         createOptionHandler,
                         editItemButton,
                         optionsMode,
                         customSingleValueStyle = {},
                         customControlStyle = {},
                         customInputStyle = {}
                     }) => {
    const [selectedOption, setSelectedOption] = useState(value);

    const handleChange = selectedOption => {
        setSelectedOption(selectedOption);
        const value = !selectedOption ? [] : isMulti ? selectedOption.map(option => {
            return {...option}
        }) : {...selectedOption, id: selectedOption.value};

        changeHandler(name, value, true);
    };
    const createOption = (newValue) => {
        createOptionHandler(
            newValue,
            handleChange
        );
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            // borderBottom: '1px dotted pink',
            // color: state.isSelected ? 'red' : 'blue',
            // padding: 20,
            cursor: "pointer",
            color: "#000",
            backgroundColor: state.isFocused ? "#eaf1f4" : "#fff",
            fontSize: font_size.sm
        }),
        control: (provided, state) => {
            return {
                display: "flex",
                border: "1px solid #7b7a7d",
                borderRadius: ".2rem",
                ...customControlStyle
            }
        },
        input: (provided, state) => {
            return {
                ...customInputStyle
            }
        },
        indicatorsContainer: (provided, state) => {
            if (hideArrow) {
                return {
                    display: "none"
                }
            }
            return {
                display: "flex",
                alignItems: "center"
            }
        },
        indicatorSeparator: (provided, state) => {
            if (hideArrow) {
                return {
                    display: "none"
                }
            }
            return {}
        },
        dropdownIndicator: (provided, state) => {
            if (hideArrow) {
                return {
                    display: "none"
                }
            }
            return {}
        },
        singleValue: (provided, state) => {
            return {
                ...fieldStyle.input,
                ...customSingleValueStyle,
                border: 0,
                whiteSpace: "nowrap"
            }
        },
        multiValueLabel: (styles, {data}) => ({
            ...styles,
            fontSize: font_size.sm
        }),
    };

    const EditItem = () => {
        return (
            <Element>
                <Modal
                    name={name}
                    childrenWrapperStyle={editItemButton.componentWrapperStyle}
                    triggerStyle={{...editItemButton.triggerStyle ? editItemButton.triggerStyle : {}, height: '100%'}}
                    triggerValue={editItemButton.triggerValue || <MdEdit/>}
                    contentStyle={{width: '1200px'}}>
                    <editItemButton.component
                        id={!!selectedOption ? selectedOption.id || selectedOption.value : null}
                        {...selectedOption && selectedOption[0]}
                        changeDataOnModal={editItemButton.changeDataHandler}/>
                </Modal>
            </Element>
        )
    }

    const SelectRadio = props => {
        return (
            <Grid items={[...props.options.map(item => {
                const id = item.value;
                const isActive = id === selectedOption.value;
                return (
                    <RadioItem isActive={isActive} onClick={(name, value) => {
                        return props.onChange(props.options.find(option => option.value === id))
                    }}>
                        <Card4 title={item.label}/>
                    </RadioItem>
                )
            }), <EditItem/>]}/>
        )
    };

    const SelectComponent = optionsMode === 'radio' ? SelectRadio : !!createOptionHandler ? CreatableSelect : Select;
    return (
        <div style={{...fieldStyle.control, ...controlStyle}}>
            {!!label &&
            <Label
                style={{...fieldStyle.label, ...labelStyle}}
                value={label}/>}
            <HBox width={"100%"}
                  alignItems={'stretch'}
                  overflow={'visible'}>
                <Element width={'100%'}
                         overflow={'visible'}
                         paddingLeft={optionsMode !== 'radio' && !!editItemButton && editItemButton.component && '1rem'}>
                    <SelectComponent placeholder={placeholder}
                                     className={className}
                                     styles={customStyles}
                                     isMulti={isMulti}
                                     value={!!value ? value : isMulti ? [] : {}}
                                     onChange={handleChange}
                                     onCreateOption={createOption}
                                     options={options}
                                     style={{
                                         ...fieldStyle.input,
                                         height: '3.6rem', ...inputStyle, ...selectedOption && inputStyleWithValue
                                     }}/>
                </Element>
                {!!editItemButton && editItemButton.component && optionsMode !== 'radio' && <EditItem/>}
            </HBox>
        </div>
    );
};

const RadioItem = styled.div`
    margin-bottom: ${gutters.sm};
    cursor: pointer;
`;

export default FieldSelect; 