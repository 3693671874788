import React, {useContext} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Element} from 'react-stylesheet';
import {gutters} from '../../../../00_base/variables';
import {Paragraph} from '../../../../01_atoms';
import {FieldText} from '../../../../02_molecules';
import {ThemeContext} from "../../../../../providers/theme-provider";

const checkIsValid = (toolData) => {
    let expectedLength = toolData.items.length;
    // const invalidItems = [].concat.apply([], toolData.items.map(item => item.options));
    // const validLength = invalidItems.filter(option => !option || option === /^\s+$/.test(option) || option === '').length;
    // const validLength = expectedLength - invalidLength;

    const validLength = toolData.items.filter(item => item.sentence).length;

    if (validLength >= expectedLength) {
        return 100
    } else {
        return validLength / expectedLength * 100;
    }
};

const CreateToolTruthOrLie = ({handleToolDataChange, toolData, invalidFields}) => {
    const {mode} = useContext(ThemeContext);
    const darkMode = mode === "dark";
    const renderSection = ({id, sentence, sentence_type}, index) => {
        const placeholder = 'הכנס משפט';
        const name = 'sentence';
        const handleChange = (name, value) => handleToolDataChange(checkIsValid, name, value, index);
        const labelStyle = {marginBottom: 0, marginLeft: gutters.sm, textAlign: 'center'};
        const controlStyle = {margin: 0, flex: 1};
        const inputStyle = {flex: 1, backgroundColor: darkMode ? "#000" : '#fff'};
        return (
            <SectionWrapper key={id}>
                <Paragraph value={sentence_type}
                           className="color-black"
                           fontWeight="bold"
                           style={{margin: 0, color: darkMode ? "#fff" : "#000"}}/>
                <Element width={'100%'}>
                    <form>
                        <div style={{...fieldStyle, width: '100%'}}>
                            <FieldText required
                                       value={sentence}
                                       invalid={invalidFields && !sentence}
                                       initialValue={sentence}
                                       labelStyle={labelStyle}
                                       controlStyle={controlStyle}
                                       placeholder={placeholder}
                                       index={index}
                                       inputStyle={inputStyle}
                                       name={name}
                                       changeHandler={handleChange}/>

                        </div>
                    </form>
                </Element>
            </SectionWrapper>
        )
    };

    return (
        <Element className={'create-truthOrLie'}>
            {!!toolData.items && toolData.items.map(renderSection)}
        </Element>
    )
};

const SectionWrapper = styled.div`
    display: flex;
    align-items: center;
    max-width: 60rem;
    margin: 0 auto;
    padding: ${gutters.sm} 0;
`;
const fieldStyle = {
    justifyContent: 'flex-end',
    display: 'flex',
    position: 'relative',
    padding: `0 ${gutters.sm}`,
    alignItems: 'center',
    width: '100%',
};

CreateToolTruthOrLie.defaultProps = {
    handleToolDataChange: (toolData) => console.log('toolData', toolData),
    toolData: {items: []}
};

CreateToolTruthOrLie.propTypes = {
    toolData: PropTypes.object,
    warningActive: PropTypes.bool,
    handleToolDataChange: PropTypes.func
};


export default CreateToolTruthOrLie;