import React from "react";
import styled from "styled-components";
import BackgroundImage from "../../01_atoms/background-image/a-background-image";
import Video from "../../01_atoms/video";
import {MdClose} from "react-icons/md";
import IconClueX from "../../02_molecules/icons/icon-close-x";
import Circle from "../../02_molecules/shapes/circle/circle";

export const GalleryAdvancedData = ({advancedDataType, closeAdvancedData, advancedData, itemStyle}) => {
    let Advanced;
    switch (advancedDataType) {
        case "text":
            Advanced = AdvancedText;
            break;
        case "textarea":
            Advanced = AdvancedTextArea;
            break;
        case "list":
            Advanced = AdvancedList;
            break;
        case "image":
            Advanced = AdvancedImage;
            break;
        case "video":
            Advanced = AdvancedVideo;
            break;
        default:
            Advanced = <div/>
    }

    return (
        <AdvancedDataStyle {...itemStyle} className="gallery-advanced-data">
            <Circle background="#fff"
                    size={30}
                    onClick={closeAdvancedData}
                    border="2px solid #000"
                    style={{position: "absolute", zIndex: 10, top: "10px", left: "10px", cursor:"pointer"}}>
                <IconClueX size={14}
                           color="#000"/>
            </Circle>
            <Advanced {...advancedData}/>
        </AdvancedDataStyle>
    )
};

const AdvancedText = ({text}) => {
    return <p>{text}</p>
};

const AdvancedTextArea = ({textarea}) => {
    return <p dangerouslySetInnerHTML={{__html: textarea}}/>
};
const AdvancedImage = ({image}) => {
    return <BackgroundImage url={image} width={"100%"} height={"100%"}/>
};
const AdvancedVideo = ({video}) => {
    return <div>
        <Video source={video}
               autoPlay={true}
               videoStyle={{height: "100%"}}
               width="100%"
               height="100%"/>
    </div>
};

const AdvancedList = ({list}) => {
    return (
        <ul>
            {list.map((item, index) => <li key={index}>{item}</li>)}
        </ul>
    )
};

const AdvancedDataStyle = styled.div`
    background-color: ${props => props.backgroundColor || "#fff"};
    color: ${props => props.color || "#000"};
    border-radius: ${props => props.borderRadius || 0}px;
    font-size: ${props => props.fontSize || "55"}px;
    font-weight: bold;
`;