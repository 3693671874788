import CreateToolTranslator from "./translator";
import CreateToolTifzoret from "./tifzoret";
import CreateToolContactList from "./contact-list";
import CreateToolDefiner from "./definer";
import CreateToolTrivia from "./trivia";
import CreateToolGallery from "./gallery";
import CreateToolVideo from "./video/create-tool-video";
import CreateToolTruthOrLie from "./truth-or-lie/create-tool-truth-or-lie";
import CreateToolTreasure from "./treasure";
import CreateToolPuzzle from "./puzzle/create-tool-puzzle";
import React from "react";
import {CreateToolGalleryImages} from "./gallery-images/create-tool-gallery-images";
import {CreateToolContent} from "./content/create-tool-content";
import {CreateToolCoder} from "./coder/create-tool-coder";

export const getToolComponent = toolId => {
    switch (toolId) {
        case 15:
            return CreateToolTranslator;
        case 46:
            return CreateToolTifzoret;
        case 47:
            return CreateToolContactList;
        case 48:
            return CreateToolDefiner;
        case 49:
            return CreateToolTrivia;
        case 56:
            return CreateToolGallery;
        case 57:
            return CreateToolVideo;
        case 66:
            return CreateToolTruthOrLie;
        case 60:
            return CreateToolTreasure;
        case 62:
            return CreateToolContent;
        case 63:
            return CreateToolCoder;
        case 38:
            return CreateToolPuzzle;
        case 79:
            return CreateToolTruthOrLie;
        case 80:
            return CreateToolGalleryImages;
        // case 237: return CreateToolVideo;
        default: return <div>?</div>
    }
};
 // { id: 9, component: props => <ToolTifzoret {...props}/> }