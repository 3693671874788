import React from 'react';

class Iframe extends React.Component {
    render() {
        const {id, src, className, width, height, maxWidth, style, controls=0, autoPlay=0, position} = this.props;
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#]*).*/,
            match = src ? src.match(regExp) : '';

        const youtubeUrl = match && match[2].length === 11 ? match[2] : 'error';

        return (
            <div className={`iframe ${className ? className : ''}`} style={style && style}>
                {(youtubeUrl && youtubeUrl !== 'error') ?
                    <iframe id={id} src={`//www.youtube.com/embed/${youtubeUrl}?autoplay=${autoPlay}&showinfo=0&controls=${controls}&rel=0`}
                            title={className}
                            width={width && width}
                            allowFullScreen="allowfullscreen"

                            style={{maxWidth: maxWidth ? maxWidth : '100%', width: width && width, height: height && height, position: position }}
                             />

                    :
                    <iframe id={id}
                            src={src}
                            title={className}
                            width={width ? width : 'inherit'}
                            height={height ? height : 'inherit'}
                            allowFullScreen="allowfullscreen"
                            style={{maxWidth: maxWidth, position: position}}
                            frameBorder={'0'}
                            autoPlay={autoPlay}/>
                }
            </div>
        )
    }
    static defaultProps = {
        autoPlay: false,
        position: 'static'
    }
}

export default Iframe;