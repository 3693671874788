import React from 'react';
import {Element} from 'react-stylesheet';
import {colors} from "../../00_base/variables";

const LoadingCircle = ({size = "10rem", style, background = colors.gray_1}) => {
    return <Element width={size}
                    height={size}
                    background={background || colors.gray_loading}
                    borderRadius={'50%'}
                    style={style}/>
};

export default LoadingCircle;