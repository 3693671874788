import React from "react";
import ScreenFaq from "../../faq";
import {useSelector} from "react-redux";
import {selectCurrentAdventureType} from "../../../../../store/selectors";

export const AdventureFaq = () => {
    const adventureType = useSelector(selectCurrentAdventureType);

    return (
        <div className={"single-adventure-faq"}>
            <ScreenFaq isSection={true} sections={adventureType?.faq}/>
        </div>
    )
}