import React from "react";
import {Col, Row} from "react-grid-system";
import {FieldText} from "../../../../02_molecules";

export const CssCreateTypo = ({fontSize, labelStyle, inputStyle, changeHandler}) => {
    const fieldCommonProps = {labelStyle, inputStyle, changeHandler};
    return (
        <Row justify={"start"}>
            <Col xs={4}>
                <FieldText type="number"
                           label="גודל פונט"
                           value={fontSize}
                           name="fontSize"
                           {...fieldCommonProps}/>
            </Col>
        </Row>
    )
}