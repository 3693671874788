import React, {useContext} from "react";
import {gutters} from "../../../00_base/variables";
import {FieldText, FilesUploader} from "../../../02_molecules";
import {FrameContext} from "./single-frame";


export const SingleFrameHeader = (
    {title, thumbnail}
) => {
    const {handleFrameChange} = useContext(FrameContext);
    return (
        <div className="single-frame-header">

            <div className="frame-fields-group">
                <FilesUploader name="thumbnail"
                               value={[thumbnail]}
                               multiple={false}
                               previewWidth="50"
                               previewHeight="50"
                               triggerValue={"איקון"}
                               className="frame-thumbnail"
                               changeHandler={handleFrameChange}/>
                <FieldText name="title"
                           value={title}
                           changeHandler={handleFrameChange}
                           controlStyle={{marginLeft: gutters.md}}
                           inputStyle={{border: 0, borderRadius: 0, maxWidth: "200px", borderBottom: "1px solid #000"}}
                           inputStyleWithValue={{backgroundColor: "transparent"}}/>

            </div>
        </div>
    )
}