class Tag {
    constructor(
        id,
        title,
        tag_name,
        description,
        description_short,
        icon,
        created_at
    ) {
        this.id = id;
        this.title = title;
        this.tag_name = tag_name;
        this.description = description;
        this.description_short = description_short;
        this.icon = icon;
        this.created_at = created_at;
    }
};
export default Tag;