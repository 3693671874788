import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {LanguageContext, Translation} from "../../../languages";
import {colors, font_family, font_size} from "../../00_base/variables";
import {BackgroundImage, Paragraph} from "../../01_atoms";
import {DICT} from "../../../languages/dictionary-names";
import "../../../assets/styles/adventure-type-card.scss";
import {ThemeContext} from "../../../providers/theme-provider";

const THUMBNAIL = "https://storage.googleapis.com/assets.youdoadventures.com/website/default_thumbnail.jpg";

export const AdventureTypeCard = (
    {
        id,
        name,
        site_image_main,
        description = "",
        description_short = "",
        hidePrice,
        base_price,
        hideDetails,
        url
    }
) => {
    const {userLanguage, dictionary} = useContext(LanguageContext);
    const {mode} = useContext(ThemeContext);
    const textColor = colors[mode === "light" ? "black" : "white"];

    const URL = url || (id === 31 ? `/${userLanguage}/space-adventure` : `/${userLanguage}/adventure/${id}`);
    const TITLE = <h6 className={`responsive-text color-black m-0`}>{name}</h6>;

    const DESCRIPTION = (description_short) &&
        <Paragraph className={`mb-0 mt-3`}
                   color={textColor}
                   font_size={font_size.xs}
                   margin={0}
                   value={(description_short)}/>;

    const IMAGE = <BackgroundImage url={site_image_main || THUMBNAIL}
                                   mobileHeight='30rem'
                                   tabletHeight='32rem'
                                   desktopHeight='22rem'
                                   size='cover'
                                   height='22rem'
                                   width='100%'/>;


    const isFree = !(base_price > 0);

    const PRICE = !hidePrice &&
        <div className="d-flex justify-content-between align-items-end adventure-type-card-price">
            <h3 className={`pl-1 m-0 color-${isFree ? "action" : "black"}`}
                style={{fontFamily: font_family.medium, fontSize: "3.4rem", fontWeight: "bold"}}>

                {!isFree ?
                    <>
                        {base_price && parseInt(base_price).toFixed(0)}
                        <span className="px-3" style={{font_family: font_family.medium}}>
                        {dictionary[DICT["SHEKEL"]].replace("'", '"')}
                    </span>
                    </>
                    :
                    <Translation id={DICT.FREE}/>
                }
            </h3>
        </div>;

    const CTA = (
        <span style={{color: colors.info, fontSize: font_size.xs,}}>
            <Translation id={DICT.MORE_DETAILS}/>
        </span>
    );

    return (
        <Link to={URL} className={`adventure-type-card`}>
            {IMAGE}
            {!hideDetails &&
            <div className='adventure-hp-details details-small mt-4'>
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                    {TITLE}
                    {PRICE}
                </div>
                {DESCRIPTION}
                {CTA}
            </div>
            }

        </Link>
    )
};