import React from 'react';

class IconCheckmark extends React.Component {
    render() {
        const {width, height, borderWidth, color, style} = this.props;
        return (
            <div className="icon-checkmark"
                 style={{
                     ...style && style,
                     width: width,
                     height: height,
                     borderBottom: `${borderWidth} solid ${color}`,
                     borderRight:  `${borderWidth} solid ${color}`,
                     ...checkmark}}/>
        )
    }
    static defaultProps = {
        color: '#151515',
        width: '15px',
        height: '25px',
        borderWidth: '5px'
    }
}

export default IconCheckmark;

const checkmark = {
    display: 'inline-block',
    transform: 'rotate(45deg)',
};