import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import classNames from "classnames";
import {
  selectAllAdventureTemplates,
  selectAllAdventureTypes,
} from "../../../../store/selectors";
import { NavigateItems } from "../../../03_organisms/navigation-groups/navigate-items";
import {
  createAdventureType,
  deleteAdventureType,
  duplicateAdventureType,
  updateAdventureType,
  setAllAdventureTypes
} from "../../../../store/actions/adventure-type-actions";
import { AuthContext } from "../../../../providers/auth-provider";
import { fetchData } from "../../../../store/actions/fetch-data";
import IconAngle from "../../../01_atoms/icons/icon-angle";
import SVG_CLOSE from "../../../../assets/images/svg-icons/svg-close.svg";
import SVG_EDIT from "../../../../assets/images/svg-icons/svg-pen.svg";
import SVG_DROPDOWN from "../../../../assets/images/svg-icons/svg-dashboard.svg";
import SVG_DROPDOWN_ACTIVE from "../../../../assets/images/svg-icons/svg-dashboard.svg";
import SVG_EYE from "../../../../assets/images/svg-icons/svg-eye-icon.svg";
import SVG_SORT from "../../../../assets/images/svg-icons/svg-sort.svg";
import SVG_SORT_A_Z from "../../../../assets/images/svg-icons/svg-sort-a-z.svg";
import SVG_SORT_UP from "../../../../assets/images/svg-icons/sort-icon.svg";

import SVG_API from "../../../../assets/images/svg-icons/svg-api-icon.svg";

import { FieldCheckbox } from "../../../02_molecules";
import { Panel } from "../../../03_organisms";

import {
  Table,
  TableContainer,
  tableHeader,
  TableHeaderLabel,
  TableSearchBar,
  TableTitle,
  TableWrapper,
  Tr,
} from "../../../03_organisms";

import { AdventureTypeItem } from "./adventureType-item";
import { AdventuresTypeLoading } from "./screen-adventure-type-loading";
import { colors } from "../../../00_base/variables";
import "../../../../assets/styles/table-items-header.scss";

const ScreenAdventureTypeGallery = (props) => {
  const navigate = useNavigate();
  const adventureTypes = useSelector(selectAllAdventureTypes);
  const adventureTemplates = useSelector(selectAllAdventureTemplates);
  const { isTom } = useContext(AuthContext);
  const [headerMenuActive, setHeaderMenuActive] = useState("");
  const [innerMenuActive, setInnerMenuActive] = useState(null);
  const [itemsLength, setItemsLength] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [markItems, setMarkItems] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortedAdventureTypes, setSortedAdventureTypes] = useState();
  const [sortOrder, setSortOrder] = useState("asc");
  const [filterByTemplate, setFilterByTemplate] = useState([]);
  const [filterByName, setFilterByName] = useState("");

  const dispatch = useDispatch();

  const createNewAdventureType = () => {
            const name = `הרפתקה-${Math.random().toString(36).substr(2, 9)}`;
            const handleSuccess = (data) =>{ 
              navigate(`/he/admin/adventure/${data.id}/details`);
            }
            dispatch(createAdventureType({name: name }, handleSuccess));
  };

  const changeAdventureType = (data) => dispatch(updateAdventureType(data));

  useEffect(() => {
    fetchData("get", "adventureType/count","", (response) => {
      if (response && response.count) {
        setItemsLength(parseInt(response.count) - 1);
      }
    });
  }, []);
  useEffect(() => {
        dispatch(setAllAdventureTypes(pageIndex))
}, [dispatch, pageIndex, , setAllAdventureTypes]);

  const sortAdventureTypes = useCallback(() => {
    if (sortBy === "base_price") {
      const parsePrice = (adventureType) => parseInt(adventureType.base_price);
      return _.sortBy(adventureTypes, parsePrice, [sortOrder]);
    }
    if (sortBy === "is_active") {
      const sortBoolean = (adventureType) => {
        if (sortOrder === "asc") {
          return adventureType.is_active ? 1 : 0;
        }
        return adventureType.is_active ? 0 : 1;
      };
      return _.sortBy(adventureTypes, sortBoolean, [sortOrder]);
    }
    if (sortBy) {
      return _.orderBy(adventureTypes, [sortBy], [sortOrder]);
    }
    return adventureTypes;
  }, [adventureTypes, sortBy, sortOrder]);

  useEffect(() => {
    if (sortBy) {
      setSortOrder(sortOrder === "desc" ? "asc" : "desc");
      setSortedAdventureTypes(sortAdventureTypes);
    }
    return () => setSortBy("");
  }, [sortAdventureTypes, sortBy, sortOrder]);

  const filterBySearch = (str) => {
    setFilterByName(str);
  };

        const open_single_page = id => window.location.href = `/he/admin/adventure/${id}/details`;

  const view_entrance_in_site = (id) => window.open(`/he/adventure/${id}`);

  const open_api = (id) =>
    window.open(
      `https://api.youdoadventures.com/adventureType/${id}`,
      "_blank"
    );

  const duplicate_adventure_type = (id) => {
    const adventureType = adventureTypes.find((item) => item.id === id);
    dispatch(
      duplicateAdventureType(
        { ...adventureType, name: `${adventureType.name} - Copy`, id: null },
        (newAdventureType) => {
          open_single_page(newAdventureType.id);
        }
      )
    );
  };

  const delete_adventure_type = (adventureTypeId) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteAdventureType(adventureTypeId));
      setInnerMenuActive(null);
    }
  };

  const toggleMarkItem = (id, isChecked) => {
    if (isChecked) {
      setMarkItems(markItems.concat(id));
    } else {
      setMarkItems(markItems.filter((item) => item !== id));
    }
  };

  const innerMenuItems = [
    {
      Icon: () => <img src={SVG_EDIT} alt="edit" />,
      label: "עריכה",
      onClick: open_single_page,
    },
    {
      Icon: () => <img src={SVG_SORT_UP} alt="duplicate" />,
      label: "שכפול",
      onClick: duplicate_adventure_type,
    },
    {
      Icon: () => <img src={SVG_CLOSE} width={8.82} height={8.82} alt="remove" />,
      label: "מחיקה",
      onClick: delete_adventure_type,
    },
    {
      Icon: () => <img src={SVG_EYE} alt="view in site" />,
      label: "צפייה בעמוד המוצר",
      onClick: view_entrance_in_site,
    },
    // {
    //     Icon: () => <img src={SVG_EYE} alt="view in site"/>,
    //     label: "צפייה בעמוד הכניסה",
    //     onClick: view_entrance_in_site
    // },
    {
      Icon: () => <img src={SVG_API} alt="api" />,
      label: "API",
      onClick: open_api,
    },
  ];

  const tableRow = (item, index) => {
    return (
      <AdventureTypeItem
        item={item}
        key={item.id}
        index={index}
        setInnerMenuActive={() =>
          setInnerMenuActive(innerMenuActive === index ? null : index)
        }
        closeInnerMenu={() => setInnerMenuActive(null)}
        changeHandler={changeAdventureType}
        innerMenuItems={innerMenuItems}
        toggleMarkItem={toggleMarkItem}
        menuActive={innerMenuActive === index}
      />
    );
  };

  const deleteMultipleAdventureTypes = () => {
    for (let i = 0; i < markItems.length; i++) {
      dispatch(deleteAdventureType(markItems[i]));
    }
  };
  const deleteAdventuresButton = (
    <span
      className={"button-delete-items"}
      style={{ cursor: "pointer", color: "red", textDecoration: "underline" }}
      onClick={deleteMultipleAdventureTypes}
    >
      מחק
    </span>
  );

  const toggleHeaderMenu = (name) => {
    setHeaderMenuActive(headerMenuActive === name ? "" : name); //
  };

  const HeaderMenu = ({ items }) => {
    return (
      <Panel className={"table-filter-menu"}>
        {items.map((item, index) => {
          const changeHandler = (name, isChecked) => {
            if (isChecked) {
              setFilterByTemplate(filterByTemplate.concat(name));
            } else {
              setFilterByTemplate(filterByTemplate.filter((i) => i !== name));
            }
          };
          return (
            <div className="table-filter-menu-item" key={index}>
              <FieldCheckbox
                options={[item.name]}
                size={11}
                inputBackground="#4d4d4d"
                inputBackgroundActive="#5388ff"
                value={
                  filterByTemplate.some(
                    (filterItem) => filterItem === item.name
                  )
                    ? [item.name]
                    : []
                }
                inputBorder="0"
                controlStyle={{ margin: 0, width: "auto" }}
                name={item.name}
                changeHandler={changeHandler}
              />
            </div>
          );
        })}
      </Panel>
    );
  };

  const HeaderLabel = ({ name, label, action }) => {
    const menuActive = headerMenuActive === name;

    let Icon,
      iconSize,
      onClick,
      menuItems = [];
    if (action === "sort") {
      Icon = () => (
        <div
          onClick={() => setSortBy(name)}
          className={classNames("sort-icon px-2")}
        >
          <img alt={"sorting"} src={menuActive ? SVG_SORT_UP : SVG_SORT_UP} />
        </div>
      );
      iconSize = 14;
      onClick = () => setSortBy(name);
    }
    if (action === "toggleMenu") {
      const className = classNames("toggle-menu-icon", {
        menuActive,
        filterActive: filterByTemplate.length > 0 || filterByName.length > 0,
      });
      Icon = (props) => (
        <div className={className} style={{ cursor: "pointer" }}>
          {/* CHANGE ICONS */}
          <IconAngle
            dir="down"
            width={menuActive ? 13 : 11}
            height={menuActive ? 9 : 8}
            fill={colors.white}
            // color={colors.gray_6}
            src={menuActive ? SVG_DROPDOWN_ACTIVE : SVG_DROPDOWN}
            {...props}
            alt="dropdown"
          />
        </div>
      );
      iconSize = 18;
      onClick = () => toggleHeaderMenu(name);

      if (name === "template") {
        menuItems = adventureTemplates;
      }
    }
    return (
      <TableHeaderLabel>
        {Icon && (
          <Icon
            onClick={onClick}
            className={classNames("table-header-icon", { active: menuActive })}
            size={iconSize}
            color={menuActive ? "#fff" : "#000"}
          />
        )}
        {label}
        {menuActive && <HeaderMenu items={menuItems} />}
      </TableHeaderLabel>
    );
  };

  const tomsOtherHeaderItems = [
    markItems.length > 0 ? deleteAdventuresButton : "",
    <HeaderLabel label="#" name="id" />,
  ];
  const tableHeaderItems = (isTom ? tomsOtherHeaderItems : []).concat([
    <HeaderLabel label="שם ההרפתקה" name="name" action="sort" />,
    <HeaderLabel label="תבנית" name="template" action="toggleMenu" />,
    <HeaderLabel label="שפה" name="language_id" />,
    <HeaderLabel label="יוצר ההרפתקה" />,
    <HeaderLabel label="פעיל" name="is_active" action="sort" />,
    <HeaderLabel label="מוצג באתר" name="show_in_site" action="sort" />,
    <HeaderLabel label="מחיר" name="base_price" action="sort" />,
    <HeaderLabel label="הזמנות" />,
    <HeaderLabel label="פריימים" />,
  ]);

  const adventureNameFilter = (adventureType) => {
    return adventureType.name.includes(filterByName);
  };

  const adventureTemplateFilter = (adventureType, index) => {
    const adventureTemplate = adventureTemplates.find(
      (template) => template.id === adventureType.adventuretemplate_id
    );
    if (filterByTemplate.length === 0) {
      return true;
    }
    return (
      adventureTemplate &&
      filterByTemplate.some(
        (templateName) => adventureTemplate.name === templateName
      )
    );
  };

  const removeAllFilters = () => {
    setFilterByTemplate([]);
    setFilterByName("");
  };

  const filterByTemplatesIndicators = filterByTemplate.map((templateName) => {
    return {
      name: templateName,
      removeFilter: () =>
        setFilterByTemplate(filterByTemplate.filter((i) => i !== templateName)),
    };
  });

  const filterBySearchIndicators = filterByName
    ? [
        {
          name: filterByName,
          removeFilter: () => setFilterByName(""),
        },
      ]
    : [];

  const filterIndicators = filterByTemplatesIndicators.concat(
    filterBySearchIndicators
  );
  const renderAdventures =
    (sortedAdventureTypes || adventureTypes)
      ?.filter(adventureTemplateFilter)
      .filter(adventureNameFilter) || [];

  return (
    <TableWrapper>
      <TableTitle>
        <h1>הרפתקאות</h1>
      </TableTitle>

      <TableContainer fluid className={"adventure-type-gallery"}>
        <TableSearchBar
          filterIndicators={filterIndicators}
          removeAllFilters={removeAllFilters}
          filterLength={renderAdventures.length}
          handleSearchClick={filterBySearch}
          addLabel={"+ צור הרפתקה חדשה"}
          handleAddClick={createNewAdventureType}
        />

        <Table cellSpacing="0" cellPadding="0">
          <thead>
            <Tr>{tableHeaderItems.map(tableHeader)}</Tr>
          </thead>
          <tbody>{renderAdventures.map(tableRow)}</tbody>
        </Table>

        {renderAdventures.length === 0 && <AdventuresTypeLoading />}

        {itemsLength > 15 && (
          <NavigateItems
            length={itemsLength}
            currentPageIndex={pageIndex}
            handleChange={(index) => setPageIndex(index)}
          />
        )}
   
      </TableContainer>
    </TableWrapper>
  );
};
export default ScreenAdventureTypeGallery;
