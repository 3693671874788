import React from "react";
import {MdArtTrack, MdDashboard, MdSettingsEthernet, MdViewCarousel, MdViewModule, MdViewStream} from "react-icons/md";
import {FieldOptions} from "../../02_molecules";

class Option {
    constructor(id, value, Icon) {
        this.id = id;
        this.value = value;
        this.label = <Icon size={22}/> // <ToolTip placeholder={<Icon size={22}/>} tip={value} id={`ui-option-${value}`}/>
    }
}

const uiOptions = [
    new Option(0, "blocks", MdViewStream),
    new Option(1, "grid", MdViewModule),
    new Option(1, "slider", MdSettingsEthernet),
    new Option(2, "carrousel", MdViewCarousel),
    new Option(3, "tabs", MdArtTrack),
    new Option(4, "matching", MdDashboard),
];

export const FrameUiPicker = ({name, value, changeHandler, label = "אופן תצוגה"}) => {
    return <FieldOptions name={name}
                         label={label}
                         options={uiOptions}
                         value={uiOptions.find(option => option.value === value)}
                         changeHandler={changeHandler}
                         labelStyle={{paddingLeft: "5px"}}
                         controlStyle={{display: "flex", marginBottom: 0}}/>
};