import React, {useContext} from "react";
import {Container} from "react-grid-system";
import {Tabs} from "../../../03_organisms";
import {TableContainer, TableTitle, TableWrapper} from "../../../03_organisms";
import {LanguageContext} from "../../../../languages";
import {LoaderPageContent} from "../../../01_atoms";
import {SingleLanguage} from "./single-language";
import {MdAdd} from "react-icons/md";

export const ScreenTranslations = () => {
    const {languages} = useContext(LanguageContext);
    console.log("allLanguages", languages);

    if (!languages) {
        return (
            <LoaderPageContent/>
        )
    }

    const tabsItems = languages.map((lang, index) => {
        return {id: index, label: lang.title, content: <SingleLanguage {...lang} />}
    }).concat({
        id: "new",
        label: <MdAdd/>,
        content: <SingleLanguage/>
    });


    return (
        <TableWrapper>
            <TableTitle>
                <h1>שפות</h1>
            </TableTitle>
            <TableContainer className={"screen-translations"}>
                <Container>
                    <Tabs items={tabsItems}
                          contentStyle={{paddingTop: "20px"}}/>
                </Container>
            </TableContainer>
        </TableWrapper> 
    )
};