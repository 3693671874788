import React, {useState} from "react";
import {createNewId} from "../trivia/create-tool-trivia";
import {Button} from "../../../01_atoms";
import {FieldColor, FieldText, FilesUploader} from "../../../02_molecules";
import "../../../../assets/styles/create-tool-cutting-wires.scss";

const initialSection = {
    color: "#999"
};

export const CreateToolCuttingWires = ({items = [], image, correctImage, colors, correctIndex, handleToolDataChange}) => {
    const [wiresItems, setWiresItems] = useState(items);

    const addSection = () => {
        const newId = createNewId(wiresItems);
        setWiresItems(wiresItems.concat({...initialSection, id: wiresItems.length > 0 ? newId : 0}))
    };

    // const removeSection = (itemId) => {
    //     setWiresItems(wiresItems.filter(item => item.id !== itemId));
    // };

    const handleItemsChange = (name, value, id) => {
        wiresItems.find(item => item.id === id)[name] = value;
        setWiresItems(wiresItems);
        handleToolDataChange("items", wiresItems)
    };


    const renderSection = (item, index) => {
        return (
            <FieldColor changeHandler={(name, value) => handleItemsChange(name, value, index)}
                        name={"color"}
                        dir={"horizontal"}
                        label={`חוט ${index + 1}`}
                        value={item.color}
                        key={index}
                        labelStyle={{width: "100%"}}
                        controlStyle={{flexWrap: "wrap", width: "auto"}}
                        inputStyle={{marginRight: "5px", maxWidth: "100px"}}/>
        )
    }


    return (
        <div className="create-tool-cutting-wires">
            <div className="cutting-wire-images">
                <div className="cutting-wire-image">
                    <FilesUploader name="image"
                                   label="תמונה"
                                   triggerValue="הוסף תמונה"
                                   value={image}
                                   multiple={false}
                                   previewWidth={140}
                                   changeHandler={handleToolDataChange}/>
                </div>
                <div className="cutting-wire-image">
                    <FilesUploader name="correctImage"
                                   label="תמונה נכונה"
                                   triggerValue="הוסף תמונה נכונה"
                                   multiple={false}
                                   previewWidth={140}
                                   value={correctImage}
                                   changeHandler={handleToolDataChange}/>
                </div>
            </div>

            <FieldText changeHandler={handleToolDataChange}
                       type={"number"}
                       label={"חוט נכון לחיתוך"}
                       name={"correctIndex"}
                       value={correctIndex}/>

            <div className="cutting-wires-items">
                {wiresItems.map(renderSection)}
            </div>

            <Button outline className="tool-add-section" onClick={addSection}>הוסף חוט</Button>
        </div>
    )
}