import styled from "styled-components";
import {colors, font_size} from "../../00_base/variables";

export const Wrapper = styled.div`
    width: 100%;
    max-height: 100%; 
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    // background-color: ${props => props.backgroundColor};
    // background-image: url(${props => props.backgroundImage});
    background-repeat: no-repeat;
    .tool-treasure-container {
        border-radius: 20px; 
        // background-color: rgba(241,241,241,0.7);
        padding: 30px;
    }
`;
export const BottomBackground = styled.div`
    display: flex;
    padding: 50px 0;
    height: 100%;
    justify-content: center;
    align-items:  center;
    background-size: cover; 
    background-position: bottom left;
    position: relative; 
    overflow: visible; 
    width: 100%;
    .game-logo {
        width: 20rem;
        position: relative;
    }
    @media (min-width: 1440px) {
        // background-image: none;
        justify-content: center;
        // height: 120px;
        // padding: 50px;
        .game-logo {
            width: 20rem;
            // top: -120px;
        }
    }
    
`;
export const MobileOnly = styled.div`
    @media (min-width: 1440px) {
        display: none;
    }
`;

export const styles = (
    newsletterButtonColor,
    newsletterButtonBackground,
    fieldsColor,
    fieldsBackground,
    contentColor
) => {
    return {
        submit : {
            width: '23rem',
            borderRadius: '30px',
            display: 'block',
            margin: '0 auto',
            padding: '10px 0',
            color: newsletterButtonColor,
            fontWeight: 'bold',
            fontSize: font_size.md,
            backgroundColor: newsletterButtonBackground
        },
        fields: {
            inputStyle: {color: fieldsColor},
            backgroundColor: fieldsBackground,
            paddingVertical: '10px',
            paddingHorizontal: '10px',
            borderRadius: '50px',
            border: `1px solid ${colors.primary_dark}`
        },
        hr: {
            border: `2px dashed ${contentColor}`,
            width: '100%'
        }
    }
};