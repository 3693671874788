import styled from 'styled-components';
import { colors } from '../../../00_base/variables/v-colors';
import { gutters } from '../../../00_base/variables/v-gutters';
import { font_size } from '../../../00_base/variables';


export const fieldStyle = {
    control: {
        width: '100%',
        marginBottom: gutters.lg,
    },
    label: {
        color: colors.black,
        fontSize: font_size.sm,
        marginBottom: gutters.xs,
        paddingLeft: gutters.sm
    },
    input: {
        padding: `${gutters.xs} ${gutters.sm}`,
        color: colors.black,
        // height: '5.5rem',
        cursor:'pointer',
        border: `.1rem solid ${colors.border}`,
        backgroundColor: 'transparent',
        outline: 'none',
        borderRadius: '.8rem',
        fontSize: font_size.sm
    },
    horizontal: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    vertical: {
        display: 'flex',
        flexDirection: 'column',
    }
};

export const FieldControl = styled.div`
    width: 100%;
    margin-bottom: ${props => props.marginBottom || gutters.lg};
`;
export const FieldLabel = styled.div`
    color: ${colors.black};
    font-size: ${font_size.sm};
    margin-bottom: ${gutters.xs};
    padding-left: ${gutters.sm};
`;
export const FieldInput = styled.input`
    padding: ${gutters.xs} ${gutters.sm};
    color: ${colors.black};
    height: 5.5rem;
    border: .1rem solid ${colors.border};
    background-color: transparent;
    outline: 0;
    border-radius: .8rem;
    font-size: ${font_size.sm};
    ${props => `
        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px white inset !important; 
        }
        &::placeholder {
            color: ${props.colorPlaceholder}
        }
        &:focus {
            border: .1rem solid ${colors.black};
            background: ${props.backgroundFocus || colors.white};
            box-shadow: ${props.boxShadow};
        }
        &:hover {
            border: ${props.borderHover};
            background: ${props.backgroundHover || colors.white};
            box-shadow: ${props.boxShadow};
        }
    `}
`;

