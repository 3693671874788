import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../01_atoms/button/a-button";
import styled from "styled-components";
import PropTypes from "prop-types";
import { colors, font_size, gutters } from "../../00_base/variables";
import "../../../assets/styles/single-adventure.scss"

const Tabs = ({
  items,
  activeTab,
  tabLabelStyle,
  tabLabelActiveStyle,
  labelStyle = {},
  labelsStyle = {},
  contentStyle = {},
  containerStyle = {},
  horizontal,
  direction,
}) => {
  const [activeItem, setActiveItem] = useState(
    activeTab && activeTab >= 0 ? activeTab : 0
  );
  const navigate = useNavigate();
  const { name } = items[activeItem];

  // useEffect(() => {
  //   if (navigate && name) {
  //     navigate(name);
  //   }
  // }, [navigate, name, activeItem]);

  return (
    <TabsContainer
      className="tabs"
      horizontal={horizontal}
      direction={direction}
      style={containerStyle}
    >
      <TabsLabels
        className="tabs-labels"
        horizontal={horizontal}
        style={labelsStyle}
      >
        {items.map((item, i) => {
          const isActive = activeItem === i;
          return (
            <TabLabel
              className="tabs-label"
              isActive={isActive}
              horizontal={horizontal}
              style={labelStyle}
              key={i}
            >
              <Button
                style={{
                  fontSize: font_size.md,
                  ...tabLabelStyle,
                  cursor: "pointer",
                  ...(isActive && tabLabelActiveStyle),
                }}
                radius={0}
                color={"#000"}
                onClick={() => !isActive && setActiveItem(i)}
                block
                value={item.label}
              />
            </TabLabel>
          );
        })}
      </TabsLabels>

      <TabsContent className="tabs-content" style={contentStyle}>
        {items.find((item, i) => activeItem === i).content}
      </TabsContent>
    </TabsContainer>
  );
};

const TabsContainer = styled.div`
  display: ${(props) => (props.horizontal ? "flex" : "block")};
  flex-direction: ${(props) => props.direction};
  // justify-content: space-between;
`;
const TabsLabels = styled.div`
  display: inline-flex;
  flex-direction: ${(props) => (props.horizontal ? "column" : "row")};
  border-bottom: 1px solid #000;
  width: 100%;
  // justify-content: ${(props) =>
    props.horizontal ? "flex-start" : "center"};
`;
const TabLabel = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.horizontal ? "column" : "row")}
    justify-content: ${(props) => (props.horizontal ? "flex-start" : "center")}
    opacity: ${(props) => (props.isActive ? 1 : ".8")};
background-color: ${(props) => (props.isActive ? colors.white : "transparent")};
    margin-top: ${(props) => props.horizontal && gutters.xs};
    margin-left: 20px;
        margin-right: 20px;
    margin-bottom: ${(props) => props.horizontal && gutters.xs};
    white-space: nowrap;
    &:first-child {margin-right: ${(props) => !props.horizontal && 0};
    &:last-child {margin-left:  ${(props) => !props.horizontal && 0};
`;
const TabsContent = styled.div`
  width: 100%;
  // max-height: 100vh;
  overflow-y: hidden;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
`;

Tabs.defaultProps = {
  tabLabelStyle: {
    color: colors.gray_4,
    backgroundColor: "transparent",
    borderBottom: `4px solid transparent`,
  },
  tabLabelActiveStyle: {
    color: colors.primary,
    backgroundColor: "transparent",
    borderBottom: `4px solid ${colors.primary}`,
  },
  horizontal: false,
  direction: "row",
};
Tabs.propTypes = {
  /**
   * each item must include: label (String) and content (String or Component)
   */
  items: PropTypes.array.isRequired,
  /**
   * Define the default active tab (0, 1...)
   */
  activeTab: PropTypes.number,
  /**
   * style object for the tabs label buttons
   */
  tabLabelStyle: PropTypes.object,
  /**
   * style object for the active tab label buttons
   */
  tabLabelActiveStyle: PropTypes.object,
  /**
   * style object for the tabs content
   */
  contentStyle: PropTypes.object,
  /**
   * define an horizontal tabs
   */

  horizontal: PropTypes.bool,
  /**
   * define the flex-direction of the tabs container
   */
  direction: PropTypes.oneOf([
    "row",
    "row-reverse",
    "column",
    "column-reverse",
  ]),
};

export default Tabs;
