import React, {useContext} from "react";
import {Col, Row} from "react-grid-system";
import {TriviaSection} from "./trivia-section";
import {TriviaContext} from "./tool-trivia";

export const ToolTriviaSections = ({items}) => {
    const {isGrid} =  useContext(TriviaContext);

    return (
        <Row className={`trivia-sections`}>
            {items.map((item, index) => {
                const colSize = isGrid ? 6 :  12;
                return (
                    <Col xs={colSize} key={index}>
                        <TriviaSection {...item} questionIndex={index}/>
                    </Col>
                )
            })}
        </Row>
    )
};