import React, {useContext, useReducer, useState} from 'react';
import {Col, Row} from 'react-grid-system';
import {fetchData} from "../../../../store/actions/fetch-data";
import {Translation} from "../../../../languages";
import {DICT} from "../../../../languages/dictionary-names";
import {AuthContext} from "../../../../providers/auth-provider";
import {gutters} from '../../../00_base/variables/';
import {Button, Paragraph, SimpleLoader} from '../../../01_atoms/';
import {FieldText, FieldTextArea} from "../../../02_molecules";
import "../../../../assets/styles/screen-contact-us.scss"

const formBg = "https://storage.googleapis.com/assets.youdoadventures.com/website/Kodan_BG.png";
const subscribe = "https://storage.googleapis.com/assets.youdoadventures.com/website/subscribe.png";

const reducer = (state, action) => {
    return {...state, [action.name]: action.value}
};

const ScreenContactUs = () => {
    const {userData} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [sendSuccess, setSendSuccess] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const defaultFormValue = {name: userData?.name || "", email: userData?.email || "", phone: "", message: ""}
    const [formData, setFormData] = useReducer(reducer, defaultFormValue)

    const submitHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setShowValidation(false);
        if (formData.name && formData.email) {
            await fetchData(
                'post',
                'contact',
                formData,
                success => {
                    setIsLoading(false)
                    setSendSuccess(true);
                }
            );
        } else {
            setShowValidation(true);
            setIsLoading(false);
        }

    };

    const handleChange = (name, value) => {
        setFormData({name, value});
        setShowValidation(false)
    }

    const ContactForm = () => {
        const defaultInputSettings = {
            inputStyle: {"font-size": "small", fontWeight: "bolder"},
            warningStyle: {
                fontSize: "1.2rem",
                lineHeight: "1rem",
                marginTop: ".2rem"
            },
            changeHandler: handleChange,
        }
        return (
            <form>
                <Row gutterWidth={10}>
                    <Col sm={12} className={"input-container"}>
                        <FieldText type={'text'}
                                   placeholder={'שם'}
                                   name={'name'}
                                   value={formData.name}
                                   required={true}
                                   errorMessage={"* נא למלא שם פרטי"}
                                   errorMessageActive={showValidation && !formData.name}
                                   {...defaultInputSettings}
                        />
                    </Col>
                    <Col sm={7} className={"input-container"}>
                        <FieldText type={'text'}
                                   placeholder={'כתובת דוא"ל'}
                                   name={'email'}
                                   value={formData.email}
                                   errorMessage={'* נא למלא כתובת דוא"ל'}
                                   errorMessageActive={showValidation && !formData.email}
                                   {...defaultInputSettings}/>
                    </Col>
                    <Col sm={5} className={"input-container"}>
                        <FieldText type={'text'}
                                   placeholder={'טלפון'}
                                   name={'phone'}
                                   value={formData.phone}
                                   {...defaultInputSettings}/>
                    </Col>
                    <Col xs={12}>
                        <FieldTextArea name={"message"}
                                       inputStyle={{fontSize: "small", resize: "none", marginTop: "15px"}}
                                       fieldStyle={{backgroundColor: "transparent !important"}}
                                       showRows
                                       placeholder={"איך נוכל לעזור?"}
                                       rows={5}
                                       value={formData.message}
                                       controlStyle={{marginBottom: gutters.xxs}}
                                       changeHandler={handleChange}/>
                    </Col>
                    <Button className={"px-5 mt-4 mx-auto fs-8"} onClick={submitHandler}>
                        {isLoading ? <SimpleLoader color={"#fff"} size={20}/> : <Translation id={DICT.SEND}/>}
                    </Button>
                </Row>
            </form>
        )
    };

    const THANK_YOU = (
        <div className="mb-5 p-6">
            <h4 className="text-center m-0">תודה</h4>
            <Paragraph value='הודעתכם נקלטה במערכת, ונהיה עמכם בקשר בהקדם!'/>
        </div>
    );

    return (
        <div className="container pt-sm-7 contact-us-container">
            <Row>
                <Col md={6} lg={7}>
                    <div className="contact-us-text">
                        <h1 className="mt-sm-3 mb-0 fs-8 fs-md-8 fs-lg-9">צרו איתנו קשר<br/>אנחנו נהנים לשמוע</h1>
                        <p className="fs-7" style={{lineHeight: "38px"}}>סקרנים? נתקלתם בבעיה?<br/>נשמח לשמוע מכם
                            ולענות על כל שאלה שעולה :)</p>
                    </div>
                </Col>
                <Col md={6} lg={5} className="px-md-6">
                    <div className="contact-form-wrapper mt-md-0 mt-7">
                        <div className="contact-form-container"/>
                        <img src={formBg} alt={"formBg"} className="form-background"/>
                        <img src={subscribe} alt={"subscribe"} className="form-subscribe"/>
                        <div className="contact-form-container py-5 px-5">
                            {sendSuccess ? THANK_YOU : <ContactForm/>}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="align-items-end my-5">
                <Col sm={6} lg={4}>
                    <h3 className="my-3 further-contact-us-text-title">לבירורים נוספים</h3>
                    <div className="further-contact-us-text flex-md-row">
                        <p>אימייל: info@youdoadventures.com<br/>טלפון קווי: 0737428611</p>
                    </div>
                </Col>
                <Col sm={6} lg={4}>
                    <p>כתובת: נחל איילון 16, צור יצחק.<br/>טלפון: 0586275306</p>
                </Col>
            </Row>
        </div>
    )
};

export default ScreenContactUs;
