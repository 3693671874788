import Swal from 'sweetalert2';
import "../../assets/styles/swal.scss";
import _ from "lodash"
export const API_URL = "https://api.youdoadventures.com";//'https://api.youdoadventures.com'; // process.env.REACT_APP_BE_SERVER || ; // API stg-

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-start',
    showConfirmButton: false,
    timer: 3000,
    customClass: "swal-container"
});



const GET = "GET";
const DELETE = "DELETE";
export const fetchData = async (
    method = GET,
    extension,
    data = {},
    successHandler = success => console.log(success),
    errorHandler = error => console.log(error),
    swalData,
) => {
    const token = localStorage.getItem('token');

    const url = `${API_URL}/${extension}`;
    const HEADERS = {
        "Content-Type": "application/json; charset=utf-8",
        "access-Token": token
    };
    const BODY = JSON.stringify(data);
    const METHOD = method.toUpperCase();
    const URL = url;

    const fetchData = (
        METHOD === GET || METHOD === DELETE ?
            {method: METHOD, headers: HEADERS} :
            {method: METHOD, body: BODY, headers: HEADERS}
    );
     await fetch(
        URL,
        fetchData
    ).then(
        response => {
            if (!response.ok) {
                throw new Error('Something went wrong');
            }
            return response.json()
        }
    ).then(success => {
        if(!_.isEmpty(swalData)){
            Toast.fire({
                title: swalData.successTitle
            })
        }

        successHandler(success);
     }
    ).catch(error => {
         if(!_.isEmpty(swalData)){
             Toast.fire({
                 title: swalData.failTitle
             })
         }
         errorHandler(error)
     }
    );
};