import React from 'react';
import {font_size} from '../../00_base/variables';

class Timer extends React.Component {
    constructor(props) {
        super(props);

        const seconds = parseInt(localStorage.seconds);
        const minutes = parseInt(localStorage.minutes);

        this.state = { seconds: seconds ? seconds : 0, minutes: minutes ? minutes : 0 };
    }

    tick() {
        this.setState(prevState => ({
            seconds: prevState.seconds + 1 <= 59 ? prevState.seconds + 1 : 0 ,
            minutes: prevState.seconds === 59 ? prevState.minutes + 1 : prevState.minutes
        }));
    }

    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {

        const {seconds, minutes} = this.state;
        const {fontSize, color} = this.props;
        const time = `${seconds < 10 ? `0${seconds}` : seconds} : ${minutes < 10 ? `0${minutes}` : minutes}`;

        localStorage.seconds = seconds;
        localStorage.minutes = minutes;

        return (
            <div style={timer}>
                <p style={{color: color ? color : '#000', fontSize: fontSize ? fontSize : font_size.sm, margin: 0, textAlign:'center', whiteSpace: 'nowrap'}}>
                    {time}
                </p>
            </div>
        );
    }
}

export default Timer;

const timer = {

    fontWeight: 'bold',

};