const shades = {
    white: "#fff",
    gray_1: "#eeeeee",
    gray_2: "#dddddd",
    gray_3: "#bbbbbb",
    gray_4: "#888888",
    gray_5: "#777777",
    gray_6: "#454545",
    black: "#000",
    light: "$gray-100",
    dark: "$gray-600",
};

const theme = {
    blue: "#00ADDC",
    green: "#26B48C",
    pink: "#FF5FA0",
    yellow: "#FFD23B",
    light_pink: "#FEAEBB",
    light_blue: "#8FD5FF",
    lilach: "#B1A2FF",
    orange: "#FF8E6C",
    bla:"#123456"
};

const shortcuts = {
    info: theme.blue,
    select: theme.green,
    action: theme.pink,
    disabled: shades.gray_5,
    title: theme.blue,
    special: theme.pink,
    button: theme.green,
    link:"transparent"
};

export const colors = {
    ...shades,
    ...theme,
    ...shortcuts
};