import React from 'react';

class IconLock extends React.Component {
    render() {
        const {color, onClick, onMouseOver, onMouseLeave, style, width, height} = this.props;
        return (
            <svg width={width ? width : '43px'} height={height ? height : '56px'} viewBox="0 0 43 56" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 onClick={onClick && onClick} style={style && style}
                 onMouseLeave={onMouseLeave && onMouseLeave}
                 onMouseOver={onMouseOver && onMouseOver}>
                <g id="עיצוב" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="עמוד-הרפתקה-ילדים" transform="translate(-523.000000, -2548.000000)" fill={color ? color : '#ffffff'} fillRule="nonzero">
                        <path d="M561.402215,2566.24706 L560.949419,2566.24706 C560.893572,2555.49713 554.126824,2548 544.450576,2548 C534.775423,2548 528.008128,2555.49713 527.952281,2566.24706 L527.49839,2566.24706 C525.024166,2566.24706 523,2568.27068 523,2570.74655 L523,2598.64341 C523,2601.11873 525.024166,2603.1418 527.49839,2603.1418 L561.402215,2603.1418 C563.876986,2603.1418 565.901152,2601.11873 565.901152,2598.64341 L565.901152,2570.74655 C565.901152,2568.27068 563.876439,2566.24706 561.402215,2566.24706 Z M546.324723,2585.96311 L546.311583,2590.01418 C546.309393,2591.25156 545.445412,2592.26392 544.391444,2592.26392 C543.338024,2592.26392 542.477329,2591.25047 542.478972,2590.01418 L542.487184,2585.91328 C541.253631,2585.22341 540.419764,2583.90664 540.419764,2582.39057 C540.419764,2580.16491 542.224924,2578.3603 544.451124,2578.3603 C546.677323,2578.3603 548.481388,2580.16491 548.481388,2582.39057 C548.48084,2583.94223 547.607553,2585.28802 546.324723,2585.96311 Z M534.258569,2566.24706 C534.299632,2558.84793 538.476631,2553.68759 544.450576,2553.68759 C550.423974,2553.68759 554.60152,2558.84793 554.641489,2566.24706 L534.258569,2566.24706 Z" id="lock"/>
                    </g>
                </g>
            </svg>
        )
    }
}

export default IconLock;