import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HBox } from "react-stylesheet";
import { colors } from "../../00_base/variables";
import { Button, Heading, Image, Paragraph } from "../../01_atoms";
import Anchor from "../../01_atoms/anchor/anchor";
import Form from "../../03_organisms/form";
import { SocialShare } from "./social-share";
import { shareFormFields } from "./share-form-fields";
import { BottomBackground, styles, Wrapper } from "./tool-treasure-styles";
import { MobileOnly } from "../../01_atoms/responsive/responsive";
import { DICTIONARY } from "../../../dictionary/dictionary";
import "../../../../../../assets/styles/tool-treasure-style.scss";
import * as journeyActions from "../../../store/actions/journey/journey-actions";
import LOGO_BLACK from "../../../assets/images/logos/youdo_logo.png";
import LOGO_WHITE from "../../../assets/images/logos/youdo_logo_white.png";

const ToolTreasure = ({
  finalTitle = "כל הכבוד!",
  finalSubtitle = "פיצחתם את ההרפתקה",
  finalImage,
  finalVideo,
  secondLogo,
  secondLogoUrl,
  videoBorderWidth = 0,
  videoBorderColor,
  textColor,
  selfieButtonBackground,
  selfieButtonColor,
  newsletterButtonBackground,
  newsletterButtonColor,
  fieldsBackground = "#fff",
  fieldsColor,
  previewMode,
}) => {
  const gameData = useSelector((state) => state.gameData);
  const { adventure, adventureData, adventureType } = gameData || {};
  const [showValidationError, setShowValidationError] = useState(false);
  const [selfieSuccess, setSelfieSuccess] = useState(false);
  const [newsletterLoading, setNewsletterLoading] = useState(false);
  const [selfieUrl, setSelfieUrl] = useState(false);
  const logoType = adventure?.json_data?.logo_type || adventureType?.logo_type;

  const [videoEnded, setVideoEnded] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    let video = document.getElementById("adventure-final-video");
    dispatch(journeyActions.adventureEnd());

    if (!!video) {
      videoStarted();
    } else {
      setVideoEnded(true);
    }
  }, [dispatch]);

  const videoStarted = () => {
    let video = document.getElementById("adventure-final-video");
    video.onended = () => setVideoEnded(true);
  };

  const handleShareFormSubmit = (formData) => {
    const data = {
      adventure_id: adventure.id,
      name: formData.name,
      text: formData.description,
      title: formData.title,
      images: [formData.selfie],
    };
    if (data.name && data.title && data.images && data.images.length > 0) {
      postData("reviews", data, (success) => setSelfieSuccess(true));
    } else {
      setShowValidationError(true);
    }
  };

  const postData = (type, data, handleSuccess, handleError) => {
    setSelfieSuccess("loading");

    fetch("https://api.youdoadventures.com/reviews", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access-Token": localStorage.token && localStorage.token,
      },
    })
      .then(
        (response) =>
          response.status >= 200 && response.status < 300 && response.json()
      )
      .then((success) => handleSuccess(success))
      .then((error) => (handleError ? handleError(error) : console.log(error)));
  };

  const sendEmailToServer = (formData) => {
    const data = {
      title: "הרפתקת אסיף",
      name: "",
      phone: "",
      email: formData.email,
    };

    setNewsletterLoading(true);

    fetch("https://api.youdoadventures.com/contact", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }).then((success) => {
      setNewsletterLoading("done");
    });
  };

  const language = localStorage.getItem("adventure-language");
  const isRoshhashana = adventureType?.id === 304;

  const shareForm = shareFormFields(
    "",
    selfieButtonBackground,
    selfieButtonColor,
    fieldsBackground,
    fieldsColor,
    selfieUrl,
    showValidationError,
    isRoshhashana,
    (data) => setSelfieUrl(data)
  );

  const style = styles(newsletterButtonColor, newsletterButtonBackground);

  const emailField = {
    type: "text",
    name: "email",
    placeholder: DICTIONARY.email_address[language],
    inputStyle: { backgroundColor: fieldsBackground },
  };

  return (
    <Wrapper className={"tool-treasure has-background-image background"}>
      <div className={"tool-treasure-container"}>
        {finalVideo && !videoEnded && !previewMode ? (
          <video
            id="adventure-final-video"
            autoPlay={true}
            controls
            style={{
              border: `${videoBorderWidth}px solid ${videoBorderColor}`,
            }}
          >
            <source src={finalVideo} type="audio/mp4" />
          </video>
        ) : (
          <div>
            {isRoshhashana && (
              <div
                className={"tool-treasure-titles"}
                style={{ color: textColor }}
              >
                <Heading
                  tag="h1"
                  value={"פתרתם את האיגרת המוצפנת."}
                  color={textColor}
                />
                <Heading
                  tag="h3"
                  value={"קבלו מסבא וסבתא דבורין ברכה לשנה החדשה:"}
                  color={textColor}
                />
                <div
                  style={{
                    padding: "30px",
                    // backgroundColor: "green"
                  }}
                >
                  <a
                    href={"https://bit.ly/asif-rosh"}
                    target={"_blank"}
                    rel="noopener noreferrer"
                    style={{
                      padding: "20px",
                      display: "inline-block",
                      backgroundColor: "green",
                    }}
                  >
                    <Image src={finalImage} width={"400px"} />
                  </a>
                </div>
              </div>
            )}

            <div className={"tool-treasure-content"}>
              <div className={"tool-treasure-titles"}>
                {finalTitle && (
                  <Heading
                    tag="h1"
                    color={textColor}
                    style={{ margin: 0 }}
                    value={finalTitle.replace(
                      "{name}",
                      adventure?.adventure_name || ""
                    )}
                  />
                )}
                {finalSubtitle && (
                  <Heading
                    tag="h3"
                    color={textColor}
                    style={{ marginTop: 0 }}
                    value={finalSubtitle.replace(
                      "{name}",
                      adventure?.adventure_name || ""
                    )}
                  />
                )}
                {finalImage && !isRoshhashana && (
                  <div style={{ padding: "0", backgroundColor: "transparent" }}>
                    <Image src={finalImage} width={"400px"} />
                  </div>
                )}
              </div>

              <Form
                fields={shareForm}
                setIsFileLoading={(data) => console.log("loadingdata", data)}
                submitLabel={
                  !selfieSuccess
                    ? DICTIONARY.share[language]
                    : selfieSuccess === "loading"
                    ? "..."
                    : "תמונתכם נכנסה להיכל התהילה"
                }
                hideTrigger={selfieSuccess && selfieSuccess !== "loading"}
                handleFormSubmit={handleShareFormSubmit}
                submitStyle={{
                  backgroundColor: selfieButtonBackground,
                  cursor: "pointer",
                  color: selfieButtonColor,
                }}
              />
              {showValidationError && (
                <Paragraph value="העלו תמונה וספרו לנו איך היה" />
              )}

              {selfieSuccess && selfieSuccess !== "loading" && (
                <Button style={{ background: colors.secondary }} block>
                  <Anchor
                    target={"_blank"}
                    href={`https://youdoadventures.com/hall-of-fame/${localStorage.getItem(
                      "adventureId"
                    )}`}
                    value="צפו בהיכל התהילה"
                  />
                </Button>
              )}

              <SocialShare />

              {!selfieUrl && (
                <MobileOnly style={{ width: "100%" }}>
                  <hr style={{ ...style.hr, color: textColor }} />
                </MobileOnly>
              )}

              {!isRoshhashana && (
                <div className={"tool-treasure-email-form"}>
                  {adventureData?.id !== 1319 && (
                    <Heading
                      tag="h3"
                      color={textColor}
                      value={DICTIONARY.join_our_community[language]}
                    />
                  )}
                  <Form
                    submitStyle={style.submit}
                    submitLabel={
                      newsletterLoading === "done"
                        ? "תודה שהצטרפתם להרפתקה"
                        : newsletterLoading
                        ? "..."
                        : DICTIONARY.send[language]
                    }
                    fields={[emailField]}
                    handleFormSubmit={sendEmailToServer}
                  />
                </div>
              )}

              <HBox justifyContent={"space-around"} alignItems={"center"}>
                {!isRoshhashana && (
                  <a
                    href={"https://www.youdoadventures.com"}
                    rel="noopener noreferrer"
                    style={{ width: "100%" }}
                    target={"_blank"}
                  >
                    <Image
                      src={logoType === "black" ? LOGO_BLACK : LOGO_WHITE}
                      width={"100px"}
                    />
                  </a>
                )}
                {secondLogo && (
                  <BottomBackground>
                    <a
                      href={secondLogoUrl}
                      rel="noopener noreferrer"
                      target={"_blank"}
                    >
                      <Image
                        src={secondLogo}
                        width={160}
                        className={"game-logo"}
                      />
                    </a>
                  </BottomBackground>
                )}
              </HBox>
              <Paragraph color={textColor}>Powered by YOUDO</Paragraph>
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default ToolTreasure;
