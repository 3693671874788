import React from 'react';
import {Element} from 'react-stylesheet';
import styled from 'styled-components';

const Feed = ({ items, spaceBetweenItems, padding, className }) => {
    return (
        <Element className={`feed ${className ? className : ''}`} padding={padding}>
            {items.map((item, index) => (
                <FeedItem
                    key={index}
                    className={'feed-item'}
                    animationDelay={index * .1}
                    marginTop={spaceBetweenItems}
                    marginBottom={spaceBetweenItems}>
                    {item}
                </FeedItem>))}
        </Element>
    )
}

const FeedItem = styled.div`
    margin-top: ${props => props.marginTop};
    margin-bottom: ${props => props.marginBottom};
`;


export default Feed;