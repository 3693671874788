import React, {useState} from 'react';
import Select from 'react-select';
import {fieldStyle} from '../field-style/m-field-style';
import {Label} from '../../../01_atoms';
import {font_size} from "../../../00_base/variables";
import CreatableSelect from 'react-select/creatable';

// const options = [
//     { value: 'chocolate', label: 'Chocolate' },
//     { value: 'strawberry', label: 'Strawberry' },
//     { value: 'vanilla', label: 'Vanilla' },
// ];

/**
 * Customize styles: https://react-select.com/styles
 */

const FieldSelectMulti = ({
                              name,
                              options,
                              isMulti,
                              changeHandler,
                              label,
                              value,
                              inputStyle,
                              labelStyle,
                              controlStyle,
                              handleCreateNew
                          }) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = selectedOption => {
        if (selectedOption.some(item => item.__isNew__)) {
            handleCreateNew(selectedOption)
        } else {
            changeHandler(name, selectedOption, true);
        }
        setSelectedOption(selectedOption);
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: font_size.sm,
        }),
        input: (provided, state) => ({
            ...provided,
            fontSize: font_size.sm,
        }),
        multiValue: (provided, state) => ({
            ...provided,
            fontSize: font_size.sm,
        }),
    };

    const SelectComponent = handleCreateNew ? CreatableSelect : Select;

    return (
        <div style={{...fieldStyle.control, ...controlStyle}}>
            {!!label && <Label style={{...fieldStyle.label, ...labelStyle}} value={label}/>}
            <SelectComponent isMulti={isMulti}
                             value={!!selectedOption ? selectedOption : value}
                             onChange={handleChange}
                             options={options}
                             style={{...fieldStyle.input, ...inputStyle}}
                             styles={customStyles}/>
        </div>
    );
};

export default FieldSelectMulti;