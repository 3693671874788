import React from 'react';
import {MdDelete, MdKeyboardArrowDown, MdKeyboardArrowUp} from 'react-icons/md';
import {Element, HBox} from 'react-stylesheet';
import {gutters} from '../../../00_base/variables';
import {Button} from '../../../01_atoms';
import {FieldColor} from '../../../02_molecules';


const SWAP_SECTION = 'SWAP_SECTION';
const DELETE_SECTION = 'DELETE_SECTION';

const CreateToolActions = ({ index, color, handleChange, dispatchFormState, length, noSections }) => {
    const deleteItemHandler = index => {
        dispatchFormState({
            type: DELETE_SECTION,
            index: index
        })
    };

    const buttons = [
        { icon: MdKeyboardArrowUp, opacity: index > 0 ? 1 : .4, action: () => index > 0 && dispatchFormState({ type: SWAP_SECTION, index: index, dir: 'up' }) },
        { icon: MdKeyboardArrowDown, opacity: index < length - 1 ? 1 : .4, action: () => {
            index < length - 1 && dispatchFormState({ type: SWAP_SECTION, index: index, dir: 'down' });
        }},
        { icon: MdDelete, opacity: 1, action: () => deleteItemHandler(index) }
    ];
    
    return (
        <HBox
            overflow={'visible'}
            position={'relative'}
            width={'100%'}
            padding={`0 ${gutters.sm}`}
            alignItems={'center'}>
            <Element>
                <FieldColor
                    width={30}
                    height={30}
                    name={'color'}
                    value={color}
                    initialValue={color}
                    controlStyle={{ marginBottom: 0 }}
                    changeHandler={(name, value) => handleChange(name, value, index)} />
            </Element>
            {!noSections &&
                <HBox>
                    {buttons.map((btn, index) => {
                        const Icon = btn.icon;
                        return (
                            <Button
                                key={index}
                                radius={0}
                                pointer
                                onClick={btn.action}
                                style={{ opacity: btn.opacity }}>
                                <Icon />
                            </Button>
                        )
                    })}
                </HBox>
            }
        </HBox>
    )
}

export default CreateToolActions;