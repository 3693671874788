import React, {useContext, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Col, Row} from "react-grid-system";
import {selectAllTags} from "../../../../store/selectors";
import {buildStars} from "./stars-dictionary";
import {colors} from "../../../00_base/variables";
import {Paragraph, Button, Image, SimpleLoader} from "../../../01_atoms";
import {AuthContext} from "../../../../providers/auth-provider";
import {updateRiddle} from "../../../../store/actions/riddle-actions";

export const RiddleThumbnail = ({riddle, handleRiddleClick, handleAnswerClick}) => {
    const allTags = useSelector(selectAllTags);
    const {isAdmin} = useContext(AuthContext);
    const [isPublishing, setIsPublishing] = useState(false);
    const dispatch = useDispatch();
    const publishRiddle = () => {
        setIsPublishing(true);
        dispatch(updateRiddle({
            ...riddle,
            is_published: true
        }, () => setIsPublishing(false)));
    }

    const authorImage = riddle.author_image ? riddle.author_image.url : 'https://admin.youdoadventures.com/wp-content/uploads/2020/01/new-logo-mid.png';

    return (
        <>
            {riddle.thumbnail &&
            <Col sm={5} onClick={() => handleRiddleClick(riddle)} className="mb-3 mb-sm-0">
                <Image src={riddle.thumbnail}/>
            </Col>
            }

            <Col sm={7} className="mb-3 mb-sm-0">
                <Row className="d-flex">
                    <Col xs={12} sm={9}>
                        <h6 className="color-black my-0">{riddle.title}</h6>

                        {riddle.description_short &&
                        <Paragraph className="color-black" dangerouslySetInnerHTML={riddle.description_short}/>}

                        {riddle.author &&
                        <div className="d-flex align-items-center">
                            <div style={{overflow: 'hidden'}}>
                                <a style={{textDecoration: riddle.website_url ? 'underline' : 'none'}}
                                   href={riddle.website_url}>
                                    <div style={{...authorImageStyle, backgroundImage: `url(${authorImage})`}}/>
                                </a>
                            </div>
                            <p style={{marginBottom: '0'}}>
                                {riddle.website_url ?
                                    <a style={{textDecoration: 'underline'}}
                                       className="color-black"
                                       href={riddle.website_url}>
                                        {riddle.author}
                                    </a>
                                    :
                                    <span>{riddle.author}</span>
                                }
                            </p>
                        </div>}

                        <div style={{flexWrap: "wrap", marginTop: "10px"}}>
                            {riddle.tags?.split(',').map((tag, index) => {
                                const tagValue = !isNaN(tag) ? allTags.find(item => item.id === parseInt(tag))?.title : tag;
                                // handleFilterChange
                                const style = {fontSize: '14px', marginLeft: '5px', cursor: 'pointer'};
                                return <span style={style}
                                             className="color-black" key={index}>
                                    {tagValue ? `#${tagValue}` : ""}
                                    </span>
                            })}
                        </div>
                    </Col>
                    <Col xs={12} sm={3} className="d-flex flex-column align-items-center">
                        {riddle.level &&
                        <div className="d-flex justify-content-center stars mb-3">
                            {buildStars(riddle.level).map((Star, index) =>
                                <Star size={25}
                                      color={colors.yellow}
                                      key={index}/>)}
                        </div>}
                        <Button onClick={() => riddle.answer ? handleAnswerClick(riddle) : handleRiddleClick(riddle)}
                                className="px-5" value={riddle.answer ? "פיתרון" : "שחק"}/>
                        {isAdmin && riddle.show_in_store && !riddle.is_published &&
                        <Button className={"mt-3"}
                                onClick={publishRiddle}>
                            {isPublishing ? <SimpleLoader size={16}/> :  "פרסם"}
                        </Button>
                        }
                    </Col>
                </Row>
            </Col>
        </>
    )
};

const authorImageStyle = {
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    overflow: 'hidden',
    border: '1px solid #000',
    marginLeft: '10px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
};