import React, {useContext, useState} from "react";
import {MdAddCircleOutline, MdClose} from "react-icons/md";
import {FieldText} from "../../../02_molecules";
import {Label} from "../../../01_atoms";
import {FrameContext} from "../../riddle/template/single-frame";
import {FrameModalContext} from "../../riddle/template/single-frame-body/frame-modals/frame-modal";
import "../../../../assets/styles/tips.scss";

export const CreateToolTips = ({tips = []}) => {
    const {handleFrameChange} = useContext(FrameContext);
    const {handleModalChange} = useContext(FrameModalContext);

    const [isOpen, setIsOpen] = useState(true);

    const handleChange = (tips) => {
        const changeHandler = handleModalChange || handleFrameChange;
        changeHandler("tips", tips);
    };

    const changeTip = (index, value) => {
        tips[index] = value;
        handleChange(tips);
    };

    const appTip = (e) => {
        e.stopPropagation();
        handleChange(tips.concat("טיפ"));
    };

    const removeTip = (index) => {
        handleChange(tips.filter((tip, i) => i !== index));
    };

    const renderTip = (tip, index) => {
        return (
            <div className="tips-field" key={`${tip}-${index}`}>
                <span className="tips-field-index">{index + 1}</span>
                <div>
                <FieldText value={tip}
                           controlStyle={{marginBottom: 0, paddingLeft:"30px"}}
                           changeHandler={(name, value) => changeTip(index, value)}
                           name={`tip-${index}`}/>
                </div>
                <MdClose size={20} className="tips-remove" onClick={() => removeTip(index)}/>
            </div>
        )
    };

    return (
        <div className="single-frame-section">
            <div className="tips">
                <div className="tips-header" onClick={() => setIsOpen(!isOpen)}>
                    <Label fontSize="1.6rem"
                           textAlign='right'
                           style={{cursor: "pointer", marginBottom: "5px"}}
                           value="טיפים"/>
                    <button className="tips-add" onClick={appTip}><MdAddCircleOutline/><span>הוסף טיפ</span></button>
                </div>
                {isOpen && <div className="tips-body">{tips.map(renderTip)}</div>}
            </div>
        </div>
    )
};