import React, { useContext } from "react";
import { LanguageContext } from "../../../../../languages/";
import { PageContentHeader } from "../../../../03_organisms";
import "../../../../../assets/styles/adventure-thumbnail-big.scss";
import "../../../../../assets/styles/hp-block-main.scss";

const BlockMain = () => {
  const { userLanguage } = useContext(LanguageContext);
  const isHebrew = userLanguage === "he";
  const isEnglish = userLanguage === "en";

  const TITLE_HE = (
    <>
      הדור הבא <br /> של חדרי הבריחה
    </>
  );
  const TITLE_EN = (
    <>
      The next generation <br /> of escape rooms
    </>
  );
  const DESCRIPTION_HE = (
    <>
      צאו להרפתקה עם אנשים שאתם אוהבים <br />
      מכל מקום שרק תרצו. משחקי הבריחה שלנו <br />
      מתאימים לכל הגילאים ולכל אירוע בו תרצו לאתגר, <br />
      להצחיק ולרגש. כל המשחקים שלנו כוללים כלים דיגיטליים <br />
      ייחודיים המשתלבים במשחק יחד עם התוכן האישי שלכם. <br />
      אתם מחליטים מי גיבור.ת ההרפתקה.
    </>
  );
  const DESCRIPTION_EN = (
    <>
      The escape rooms have found their
      <br />
      way home to you and this time you have
      <br />
      the opportunity to create for yourself
      <br />
      the perfect adventure. You decide how do
      <br />
      you like to have fun and we help you realize that.
    </>
  );

  const title = isHebrew ? TITLE_HE : isEnglish ? TITLE_EN : "";
  const description = isHebrew
    ? DESCRIPTION_HE
    : isEnglish
    ? DESCRIPTION_EN
    : "";
  const image_url =
    "https://storage.googleapis.com/assets.youdoadventures.com/website/Drawing_mainheader.png";

  return (
    <div className={"homepage-main"}>
      <PageContentHeader
        title={title}
        description={description}
        image={image_url}
      />
    </div>
  );
};

export default BlockMain;
