import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {phoneNumbers} from "./phone-numbers";
import {MdPerson} from "react-icons/md";
import {StyledDiv} from "../../ui/css-general/css-styled";
import {Image} from "../../01_atoms";

const className = "contact-list-item";
const classNameThumbnail = className + "-thumbnail";
const classNameContainer = className + "-container";
const classNameLink = className + "-link";
const classNameDetails = className + "-details";
const classNameName = className + "-name";
const classNamePhone = className + "-phone";

export const ContactListItem = (
    {
        name,
        icon,
        playIcon,
        hideNumbers,
        phone_number,
        index,
        itemStyle,
        showScreenCall
    }) => {
    const {iconsSize = 40} = itemStyle;

    return (
        <StyledDiv {...itemStyle} className={className}>
            <Thumbnail className={classNameThumbnail} size={iconsSize} color='#000'>
                {icon ? <img src={icon} alt={`${name}-icon`} width="100%"/> : <MdPerson color={'#fff'} size={23}/>}
            </Thumbnail>

            <div className={classNameContainer}>
                <div className={classNameLink} onClick={showScreenCall}>
                    <div className={classNameDetails}>

                            <Name className={classNameName} color={itemStyle.color}
                                  fontSize={itemStyle.fontSize}>{name}</Name>

                            {!hideNumbers &&
                            <PhoneNumber className={classNamePhone} color={itemStyle.color}>
                                {phone_number || phoneNumbers[index]}
                            </PhoneNumber>
                            }

                        {playIcon &&
                        <PlayIcon size={(itemStyle.height || 40) - 10}>
                            <Image src={playIcon}/>
                        </PlayIcon>
                        }
                    </div>
                </div>
            </div>
        </StyledDiv>
    );
};

const PlayIcon = styled.div`
    ${props => `
        width: ${props.size}px;
        height: ${props.size}px;
    `}
`;

const Thumbnail = styled.div`
    ${props => `
        width: ${props.size}px;
        height: ${props.size}px;
        background: ${props.background || 'transparent'};
    `} 
`;
const Name = styled.span`
    &.contact-list-item-name {
        display:block;
        ${props => `
            color: ${props.color};
            font-size: ${props.fontSize}px;
        `};
    }
`;
const PhoneNumber = styled.span`
    &.contact-list-item-phone {
         ${props => `
             color: ${props.color};
        `};
    }
`;

ContactListItem.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
    audio: PropTypes.string,
    video: PropTypes.string,
    placeholder: PropTypes.string,
    phone_number: PropTypes.string,
    index: PropTypes.number,
    itemStyle: PropTypes.object,
}