class Theme {
    constructor(
        id,
        key,
        main,
        sub,
        text,
        buttons,
        fifth,
    ) {
        this.id = id;
        this.key = key;
        this.main = main;
        this.sub = sub;
        this.text = text;
        this.buttons = buttons;
        this.fifth = fifth;
    }
};

export default Theme;