import React from "react";
import {Col, Row} from "react-grid-system";
import {FieldText} from "../../../../02_molecules";


export const CssCreateSizes = ({width, height, labelStyle, inputStyle, changeHandler}) => {
    const fieldCommonProps = {labelStyle, inputStyle, changeHandler};
    return (
        <Row justify={"start"}>
            <Col xs={6}>
                <FieldText type="number"
                           label="רוחב"
                           value={width}
                           name="width"
                           {...fieldCommonProps}/>
            </Col>
            <Col xs={6}>
                <FieldText type="number"
                           label="גובה"
                           value={height}
                           name="height"
                           {...fieldCommonProps}/>
            </Col>
        </Row>
    )
}