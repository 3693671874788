import React, {useState} from "react";
import {useSelector} from "react-redux";
import {MdRemoveRedEye} from "react-icons/md";
import {
    selectCurrentAdventure,
    selectCurrentAdventureData,
    selectCurrentAdventureType,
    selectCurrentRiddles
} from "../../../../store/selectors";
import {FieldText, FilesUploader} from "../../../02_molecules";
import {GameEntranceUi, GameLobbyUi} from "../../../03_organisms";
import Modal from "../../../03_organisms/modal/modal";
import {ScreenHomepageView} from "../../../youdo-tools/src/components/05_screens/homepage/screen-homepage-view";
import ScreenMenu from "../../../youdo-tools/src/components/05_screens/menu/screen-menu";

export const AdventureTypeGame = ({handleChange, handleJsonDataChange}) => {
    const adventure = useSelector(selectCurrentAdventure);
    const adventureType = useSelector(selectCurrentAdventureType);
    const adventureData = useSelector(selectCurrentAdventureData);
    const riddles = useSelector(selectCurrentRiddles);
    const {
        game_title,
        game_subtitle,
        lobby_title,
        lobby_subtitle,
        lobby_image_desktop,
        lobby_image_mobile,
        game_board,
    } = adventureType;
    const [homepagePreviewActive, setHomepagePreviewActive] = useState(false);
    const [lobbyPreviewActive, setLobbyPreviewActive] = useState(false);

    const [isEntranceOpen, setIsEntranceOpen] = useState(true);
    const [isLobbyOpen, setIsLobbyOpen] = useState(true);

    const Trigger = ({title, subtitle}) => {
        return (
            <div>
                <p>{title}</p>
                <p>{subtitle}</p>
            </div>
        )
    };

    const inputNoBorders = {fontSize: "20px", borderTop: 0, borderLeft: 0, borderRight: 0, borderRadius: 0, padding: 0};
    const triggerStyle = {
        backgroundColor: "#fff",
        border: "2px dashed #bbbbbb",
    };

    const HOMEPAGE_PREVIEW = (
        homepagePreviewActive &&
        <Modal isActive="active"
               hideTrigger={true}
               zIndex={999999}
               padding={"10px"}
               closePreActions={() => setHomepagePreviewActive(false)}
               childrenWrapperStyle={{height: "100%"}}
               contentStyle={{
                   maxHeight: "100%",
                   width: "100%",
                   height: "98%"
               }}>
            <ScreenHomepageView adventureType={adventureType}
                                passwordConfirmed={true}/>
        </Modal>);

    const LOBBY_PREVIEW = (
        lobbyPreviewActive &&
        <Modal isActive="active"
               hideTrigger={true}
               zIndex={999999}
               padding={"10px"}
               closePreActions={() => setLobbyPreviewActive(false)}
               contentStyle={{width: "100%", height: "100%", maxHeight: "100%"}}
               childrenWrapperStyle={{height: "100%"}}>
            <ScreenMenu adventure={adventure}
                        isPreview={true}
                        adventureType={adventureType}
                        adventureData={adventureData}
                        riddles={riddles}/>
        </Modal>);

    return (
        <div className="adventure-type-game">

            {HOMEPAGE_PREVIEW}
            {LOBBY_PREVIEW}

            <h4 onClick={() => setIsEntranceOpen(!isEntranceOpen)} style={{cursor: "pointer"}}>כניסה</h4>

            {isEntranceOpen &&
            <div className="adventure-type-tab-item">
                <button className="eye-button active" onClick={() => setHomepagePreviewActive(true)}>
                    <MdRemoveRedEye color={"#fff"}
                                    size={"20px"}/>
                </button>

                <div className="adventure-type-tab-container">

                    <FieldText name="game_title"
                               value={game_title}
                               changeHandler={handleChange}
                               inputStyleTitle={true}
                               inputStyle={{...inputNoBorders, width: "57rem"}}
                               placeholder="כותרת עמוד כניסה"/>

                    <FieldText name="game_subtitle"
                               value={game_subtitle}
                               changeHandler={handleChange}
                               inputStyleTitle={true}
                               inputStyle={{...inputNoBorders, fontSize: "16px"}}
                               placeholder="כותרת משנה"/>

                    {/*<FieldVideo label="סרטון וידאו"*/}
                    {/*            name="entrance_video_url"*/}
                    {/*            triggerValue={"העלה קובץ"}*/}
                    {/*            triggerStyle={{backgroundColor: colors.gray_1}}/>*/}

                    <GameEntranceUi {...adventureType}
                                    handleChange={handleChange}
                                    handleJsonDataChange={handleJsonDataChange}/>

                    <div className="adventure-type-game-board">
                        <FilesUploader name={'game_board'}
                                       value={game_board}
                                       label={"לוח משחק להדפסה"}
                                       previewWidth={'20rem'}
                                       previewHeight={'14rem'}
                                       multiple={false}
                                       shrinkImage={false}
                                       triggerValue={<Trigger title={"לוח משחק להדפסה"} subtitle={"1920*400"}/>}
                                       triggerStyle={{...triggerStyle, width: "33rem", height: "14rem"}}
                                       changeHandler={handleChange}
                                       controlStyle={{paddingRight: 0}}/>
                    </div>
                </div>
            </div>
            }


            <h4 onClick={() => setIsLobbyOpen(!isLobbyOpen)} style={{cursor: "pointer"}}>לובי</h4>

            {isLobbyOpen &&
            <div className="adventure-type-tab-item">
                <button className="eye-button active">
                    <MdRemoveRedEye color={"#fff"} size={"20px"} onClick={() => setLobbyPreviewActive(true)}/>
                </button>

                <div className="adventure-type-tab-container">
                    <FieldText name="lobby_title"
                               value={lobby_title}
                               changeHandler={handleChange}
                               inputStyleTitle={true}
                               inputStyle={{...inputNoBorders, width: "57rem", fontSize: "20px"}}
                               placeholder="כותרת לובי"/>

                    <FieldText name="lobby_subtitle"
                               value={lobby_subtitle}
                               changeHandler={handleChange}
                               inputStyleTitle={true}
                               inputStyle={{...inputNoBorders, fontSize: "16px"}}
                               placeholder="כותרת משנה לובי"/>

                    <GameLobbyUi lobby_image_desktop={lobby_image_desktop}
                                 lobby_image_mobile={lobby_image_mobile}
                                 handleChange={handleChange}/>
                </div>
            </div>
            }
        </div>
    )
};