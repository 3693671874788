import React from 'react';
import {FieldVideo} from '../../../02_molecules';

const CreateToolVideo = ({ handleToolDataChange, videoUrl}) => {
    return (
        <div>
            <FieldVideo
                name="videoUrl"
                triggerValue="העלו סרטון"
                label="סרטון וידאו"
                value={videoUrl}
                changeHandler={handleToolDataChange} />
        </div>
    )
}

CreateToolVideo.defaultProps = {

};
 
CreateToolVideo.propTypes = {

};


export default CreateToolVideo;