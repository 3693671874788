import React from 'react';
import Timer from '../../03_organisms/timer/timer';
import {colors, gutters} from '../../00_base/variables';
import {Button, Image} from "../../01_atoms";
import LIGHT from "../../../assets/images/light.png";
import LOGO from '../../../assets/images/logos/youdo_logo_white.png';

const MenuHeader = ({setClueMenuActive}) => {
    return (
        <div style={wrapper} className='menu-header'>
            <div style={{...container, maxWidth:"100%"}}>
                <div style={buttonWrapper}>
                    <Button onClick={() => setClueMenuActive(true)} style={clueButton}>
                        <Image src={LIGHT} width={15}/>
                    </Button>
                    <Timer color={'#fff'}/>
                </div>
                <Image src={LOGO} height={"35px"}/>
            </div>
        </div>
    )
};

const wrapper = {
    padding: `${gutters.xs} ${gutters.sm}`,
    background: colors.gray_10,
    height: "50px",
    position:"sticky"
};
const container = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: '60rem'
};
const buttonWrapper = {
    display: 'flex',
    alignItems: 'center'
};
const clueButton = {
    // marginLeft: gutters.sm,
    // marginRight: gutters.sm,
    marginLeft: 0,
    marginRight: 0,
    paddingRight: 0,
    backgroundColor: "transparent",
    radius: '10px',
    cursor: 'pointer'
};

export default MenuHeader;