import React, {useContext, useEffect, useState} from 'react';

import {fieldStyle} from "../field-style/m-field-style";
import Label from "../../../01_atoms/text/a-label";
import "../../../../assets/styles/date-picker.scss";
import {FieldSelect} from "../index";
import {Col, Row} from "react-grid-system";
import Span from "../../../01_atoms/text/a-span";
import {font_size} from "../../../00_base/variables";
import {LanguageContext, Translation} from "../../../../languages";
import {DICT} from "../../../../languages/dictionary-names";
import "react-datepicker/dist/react-datepicker.css";
// DOCS: https://reactdatepicker.com //

export const FieldDatePicker = ({
                                    label,
                                    value,
                                    name,
                                    changeHandler,
                                    labelStyle,
                                    inline,
                                    start = new Date(),
                                    warningStyle,
                                    end,
                                    errorMessage,
                                    errorMessageActive
                                }) => {
    const {rtl} = useContext(LanguageContext);
    const [day, setDay] = useState(<Translation id={DICT.DAY}/>);
    const [month, setMonth] = useState(<Translation id={DICT.MONTH}/>);
    const [year, setYear] = useState(<Translation id={DICT.YEAR}/>);

    useEffect(() => {
        if (parseInt(day) && parseInt(month) && parseInt(year)) {
            changeHandler(name, {day, month, year})
        }

    }, [changeHandler, day, month, name, year]);

    // const onChange = (date) => {
    //     setStartDate(date);
    // };
    const createArray = (start, end) => {
        let arr = []
        for (let i = start; i <= end; i++) {
            arr.push(i)
        }
        return arr;
    }

    const longMonth = [1, 3, 5, 7, 8, 10, 12].some(m => m === month);

    const shortMonth = parseInt(month) === 2 && year % 4 === 0;
    const veryShortMonth = parseInt(month) === 2 && year % 4 !== 0;
    const daysOptions = createArray(1, veryShortMonth ? 28 : shortMonth ? 29 : longMonth ? 31 : 30);
    const monthsOptions = createArray(1, 12);
    const yearsOptions = createArray(1920, 2021).reverse();

    const getOption = option => {
        return {value: option, id: option, label: option}
    };

    const inputStyle = {
        height: "50px",
        border: ".1rem solid #d1d0d2",
        borderRadius: ".8rem",
        paddingRight: ".5rem",
        paddingLeft: ".5rem",
    }
    const customControlStyle = {
        border: ".1rem solid #d1d0d2",
        borderRadius: ".8rem",

    }
    const customSingleValueStyle = {
        paddingRight: 0,
        paddingLeft: 0
    };

    return (
        <div className={"field-date-picker"}>
            {!!label && <Label style={{...fieldStyle.label, marginBottom: "6px", ...labelStyle}} value={label}/>}

            <Row className={"field-date-picker-container"} style={{justifyContent: "flex-start"}} gutterWidth={5}>
                <Col xs={2}>
                    <FieldSelect options={yearsOptions.map(getOption)}
                                 changeHandler={(name, value) => setYear(value.id)}
                                 inputStyle={inputStyle}
                                 value={getOption(year)}
                                 controlStyle={{marginBottom: 0}}
                                 customControlStyle={customControlStyle}
                                 customSingleValueStyle={{...customSingleValueStyle, color: errorMessageActive && !parseInt(year) && "#7b2115"}}
                                 hideArrow={true}/>
                </Col>
                <Col xs={2}>
                    <FieldSelect options={monthsOptions.map(getOption)}
                                 changeHandler={(name, value) => setMonth(value.id)}
                                 inputStyle={inputStyle}
                                 value={getOption(month)}
                                 controlStyle={{marginBottom: 0}}
                                 customControlStyle={customControlStyle}
                                 customSingleValueStyle={{...customSingleValueStyle, color: errorMessageActive && !parseInt(month) && "#7b2115"}}
                                 hideArrow={true}/>
                </Col>
                <Col xs={2}>
                    <FieldSelect options={daysOptions.map(getOption)}
                                 changeHandler={(name, value) => setDay(value.id)}
                                 value={getOption(day)}
                                 controlStyle={{marginBottom: 0}}
                                 customControlStyle={customControlStyle}
                                 customSingleValueStyle={{...customSingleValueStyle, color: errorMessageActive && !parseInt(day) && "#7b2115"}}
                                 inputStyle={inputStyle}
                                 hideArrow={true}/>
                </Col>
            </Row>
            {errorMessage && <Span
                block
                fontSize={font_size.xs}
                color={'#7b2115'}
                style={{
                    visibility: (!day || !month || !year || errorMessageActive) ? "visible" : "hidden",
                    textAlign: rtl ? "right" : "left",
                    ...warningStyle
                }}

                value={errorMessage}/>}
        </div>
    )
//
// return (
//     <div className={"field-date-picker"} style={controlStyle}>
//         {!!label && <Label style={{...fieldStyle.label, marginLeft: "6px", ...labelStyle}} value={label}/>}
//         <DatePicker selected={startDate}
//                     inline={inline}
//                     customInput={<Button/>}
//                     onChange={onChange}
//                     calendarClassName="date-picker"/>
//     </div>
// );
};