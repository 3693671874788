import React, {useState} from "react";
import {MdClose, MdDragHandle} from "react-icons/md";
import {SortableHandle} from "react-sortable-hoc";
import {ToolContentSectionType} from "./tool-content-section-type/tool-content-section-type";
import {CARROUSEL, TABS} from "./create-content-consts";
import {gutters} from "../../../00_base/variables";
import {FieldSelect, FilesUploader} from "../../../02_molecules/";
import {ColorPickerBgText, ToggleIcon} from "../../../03_organisms";
import "../../../../assets/styles/create-tool-content.scss";


export const TEXT = "text", IMAGE = "image", VIDEO = "video", TOOL = "tool";
const labels = [{text: "טקסט"}, {image: "תמונה"}, {video: "סרטון"}, {tool: "כלי דיגיטלי"}];
const typeOptions = [TEXT, IMAGE, VIDEO, TOOL];

const getDropdownData = (value) => {
    return {id: value, label: labels.find(label => Object.keys(label)[0] === value)[value], value: value}
}

export const ToolContentSection = (
    {type = TEXT, id, value, maxHeight, bgColor, textColor, indicator = "", contentView, imageSize, fontSize, textAlign, color, removeSection, handleChange}
) => {

    const [isOpen, setIsOpen] = useState(false);
    const [itemType, setItemType] = useState(type);
    const [itemValue, setItemValue] = useState(value);

    const itemsStyle = {maxHeight, imageSize, fontSize, textAlign, color};

    const changeHandler = (name, value) => {
        if (name === "type") {
            setItemType(value);
            setItemValue(null);
        }
        if (name === "value") {
            setItemValue(value);
        }
        handleChange(name, value, id)
    };

    const DraggableHandle = SortableHandle(() => {
        return (
            <div className="tool-content-drag-handle">
                <MdDragHandle size={20} color={"#282626"}/>
            </div>
        )
    });


    return (
        <div className="create-tool-content-section">
            <div className="create-tool-content-header">
                <div>
                    <DraggableHandle/>

                    <p className={"px-3"}>{getDropdownData(itemType)?.label}</p>

                    {(contentView === CARROUSEL || contentView === TABS) &&
                    <FilesUploader name="indicator"
                                   triggerValue="העלה איקון"
                                   triggerStyle={{maxHeight: "60px"}}
                                   value={indicator}
                                   previewWidth={80}
                                   previewHeight={80}
                                   triggerSize={50}
                                   multiple={false}
                                   controlStyle={{marginBottom: 0}}
                                   changeHandler={changeHandler}/>}

                </div>
                <div>
                    <div className="content-tool-action-icon">
                        <ToggleIcon toggle={() => setIsOpen(!isOpen)} isOpen={isOpen} size={20}/>
                    </div>
                    <div className="content-tool-action-icon">
                        <MdClose onClick={() => removeSection(id)} size={20}/>
                    </div>
                </div>
            </div>

            {isOpen && <>
                <FieldSelect options={typeOptions.map(getDropdownData)}
                             name="type"
                             value={getDropdownData(itemType)}
                             changeHandler={(name, value) => changeHandler(name, value.id)}
                             inputStyle={{height: "50px"}}
                             controlStyle={{
                                 paddingRight: gutters.sm,
                                 paddingLeft: gutters.sm,
                                 width: "140px"
                             }}/>
                <ToolContentSectionType itemValue={itemValue}
                                        itemType={itemType}
                                        changeHandler={changeHandler}
                                        itemsStyle={itemsStyle}/>

                <ColorPickerBgText handleChange={changeHandler}
                                   bgColor={bgColor}
                                   textColor={textColor}/>
            </>}


        </div>
    )
};