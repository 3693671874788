import React, {useContext, useEffect} from 'react';
import Iframe from '../../01_atoms/iframe';
import {FrameContext} from "../../05_screens/tool/screen-tool";
import "../../../../../../assets/styles/tool-video.scss";

const ToolVideo = ({videoUrl = "", handleFrameFinish}) => {
    const frameContext = useContext(FrameContext);
    const isResolve = frameContext.isFrameFinish;
    const isYoutube = (url) => {
        if (url) {
            const regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
            if (url.match(regExp)) {
                return true;
            }
        }
        return false;
    };
    useEffect(() => {
        if (!isResolve) {
            handleFrameFinish();
        }

    }, [isResolve, handleFrameFinish]);

    return (
        <div className={"tool-video"}>
            {isYoutube ?
                <Iframe src={videoUrl} width={800} height={560} style={{maxWidth:"100%", maxHeight:"100%"}}/>
                :
                <video id='homepage-video' autoPlay loop controls style={{width:"1000px", maxWidth:"100%", maxHeight:"100%"}}>
                    <source type="video/mp4"
                            src={videoUrl}/>
                </video>
            }

        </div>
    )

}

export default ToolVideo;

