import React, {useEffect, useReducer, useState} from 'react';
import PropTypes from 'prop-types';
import {MdVisibilityOff} from "react-icons/md";
import classNames from "classnames";
import styled from "styled-components";
import {FieldControl, FieldInput, FieldLabel, fieldStyle} from '../field-style/m-field-style';
import {Heading, Label} from '../../../01_atoms';
import {colorsList} from "./colors-list";

const INPUT_CHANGE = 'INPUT_CHANGE';
const INPUT_BLUR = 'INPUT_BLUR';

const inputReducer = (state, action) => {
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid,
                blur: false
            };
        case INPUT_BLUR:
            return {
                ...state,
                blur: true
            };
        default:
            return state
    }
};

const FieldColor = (
    {
        type,
        name,
        label,
        className,
        changeHandler,
        inputId = "",
        labelStyle,
        inputStyle,
        controlStyle,
        value,
        dir,
        readOnly,
        mode,
        blurHandler,
        defaultMenuActive,
        invalid
    }) => {
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: value || '',
        isValid: true,
        blur: false
    });

    const [menuActive, setMenuActive] = useState(defaultMenuActive);

    useEffect(() => {
        dispatch({
            type: INPUT_CHANGE,
            value: value,
            isValid: true
        })
    }, [value]);

    const handleInputChange = event => {
        const value = event.target.value;
        let isValid = true;
        setMenuActive(false);
        dispatch({
            type: INPUT_CHANGE,
            value: value,
            isValid: isValid
        })
    };

    const handleInputBlur = () => {
        dispatch({type: INPUT_BLUR});
        changeHandler(name, inputState.value, inputState.isValid);

        if (blurHandler) {
            blurHandler(name, inputState.value);
        }
    };


    if (mode === 'read') {
        return (
            <FieldControl style={{...fieldStyle[dir], ...controlStyle, display: type === 'hidden' ? 'none' : 'flex'}}>
                <Heading tag={'h4'} style={{margin: 0}}>
                    {!!label &&
                    <Label style={{...fieldStyle.label, ...labelStyle, display: 'inline', fontWeight: 'normal'}}
                           value={label}/>}: {inputState.value.toString()}
                </Heading>
            </FieldControl>
        )
    }
    const hasValue = !!inputState.value || inputState.value?.length > 0;

    return (
        <FieldControl style={{
            ...fieldStyle[dir], ...controlStyle,
            position: "relative",
            display: type === 'hidden' ? 'none' : 'flex'
        }}
                      className={className}>
            <div className="d-flex align-items-center position-relative">
                {!!label && <FieldLabel style={labelStyle}>{label}</FieldLabel>}
                <ColorSquareInput color={inputState.value} onClick={() => setMenuActive(!menuActive)}/>
                <FieldInput {...(inputId ? {id: {inputId}} : {})}
                            type={"text"}
                            className={classNames({focus: hasValue})}
                            onChange={handleInputChange}
                            onBlur={handleInputBlur}
                            value={inputState.value?.toString() || ""}
                            readOnly={readOnly}
                            style={{
                                border: invalid && "2px solid #7b2115",
                                width: "65px",
                                padding: "0 2px",
                                height: "auto", ...inputStyle
                            }}/>

                <ColorsMenu className={classNames("color-menu", {active: menuActive})}>
                    {colorsList.map((color, index) => {
                        return (
                            <ColorSquare color={color}
                                         key={index}
                                         onClick={() => {
                                             handleInputChange({target: {value: color}});
                                             changeHandler(name, color, true);
                                         }}/>
                        );
                    })}
                    <ColorSquare onClick={() => {
                        handleInputChange({target: {value: "transparent"}});
                        changeHandler(name, "transparent", true)}}>
                        <MdVisibilityOff size={18} color={"#fff"}/>
                    </ColorSquare>
                </ColorsMenu>
            </div>
        </FieldControl>
    )
};

const ColorsMenu = styled.div`
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 0 0 #333;
    transition: height .1s;  
    width: 150px;
    max-width: 100%;
    position: absolute;
    height: 0;
    position: absolute;
    top: calc(100% + 5px);
    left:0;
    z-index: 3;
    overflow:hidden;
    align-content: baseline;
    &.active {
        height: 90px;
    }
`;
const ColorSquareInput = styled.input`
    width: 23px;
    height: 23px;
    background-color: ${props => props.color};
    margin-left: 10px;
    cursor: pointer;
    border: 0;
    border-radius: .2rem;
    border: 1px solid #000;
    outline: none;
`;
const ColorSquare = styled.div`
    width: 15px;
    height: 15px;
    background-color: ${props => props.color || "#000"};
    cursor: pointer;
    display:flex;
    justify-content: center;
    align-items: center;
`;

FieldColor.defaultProps = {
    type: 'text',
    value: '',
    initialValue: '',
    dir: 'horizontal',
    initiallyValid: true
};

FieldColor.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'number', 'hidden', 'password']),
    style: PropTypes.object,
    errorMessage: PropTypes.string,
    dir: PropTypes.oneOf(['horizontal', 'vertical']),
    changeHandler: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initiallyValid: PropTypes.bool,
    required: PropTypes.bool,
    email: PropTypes.bool,
    min: PropTypes.bool,
    max: PropTypes.bool,
    minLength: PropTypes.number,
};

export default FieldColor; 