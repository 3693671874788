import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LanguageContext } from "../../../../languages";
import { AuthContext } from "../../../../providers/auth-provider";
import { Col, Container, Row } from "react-grid-system";
import {
  selectCurrentAdventureType,
  selectUserAdventures,
} from "../../../../store/selectors";
import { setSingleAdventureType } from "../../../../store/actions/adventure-type-actions";
import { duplicateAdventureData } from "../../../../store/actions/adventure-data-actions";
import {
  createAdventure,
  setLoginUserAdventures,
} from "../../../../store/actions/adventure-actions";
import { colors } from "../../../00_base/variables";
import { LoaderPageBulletList } from "../../../01_atoms/";
import { fetchData } from "../../../../store/actions/fetch-data";
import { AdventureHeader } from "./adventure-header/adventure-header";
import { AdventureDetails } from "./adventure-details/adventure-details";
import { AdventureInitialForm } from "./advenutre-initial-form";
import { AdventureFaq } from "./adventure-faq/adventure-faq";
import { AdventureReviews } from "./adventure-reviews/adventure-reviews";
import { AdventureSubscribe } from "./adventure-subscribe/adventure-subscribe";
import { CustomizeAdventures } from "../homepage/hp-block-adventures";
import {
  AdventureProgressMessage,
  ContactUsCard,
  Modal,
} from "../../../03_organisms";
import "../../../../assets/styles/screen-adventure.scss";
import ScreenGallery from "../gallery/screen-gallery";

export const AdventureContext = React.createContext({});

const ScreenAdventure = ({ adventureTypeId }) => {
  const params = useParams();
  const id = adventureTypeId || params?.id;

  const navigate = useNavigate();
  const adventureType = useSelector(selectCurrentAdventureType);
  const userAdventures = useSelector(selectUserAdventures);
  const progressAdventures = userAdventures.filter(
    (adventure) => !adventure.is_paid
  );

  const { userLanguage } = useContext(LanguageContext);
  const { userData, isLogin } = useContext(AuthContext);

  const [progressMessageActive, setProgressMessageActive] = useState(
    progressAdventures.length > 0
  );
  const [adventureData, setAdventureData] = useState({ riddles: [] });
  const [isLoading, setIsLoading] = useState(!adventureTypeId);
  const { instructions_main, instructions_title, instructions_image } =
    adventureType;

  const formRef = "formRef";
  const scrollToForm = () =>
    document
      .getElementById(formRef)
      .scrollIntoView({ behavior: "smooth", block: "start" });

  const dispatch = useDispatch();

  const isEmployeeBirthday =
    adventureType?.id === 324 ||
    adventureType?.id === 330 ||
    adventureType?.id === 332;

  const dispatchAdventureData = useCallback((adventureDataId) => {
    fetchData("get", `adventureData/${adventureDataId}`, "", (response) => {
      setAdventureData(response);
    }).then();
  }, []);

  useEffect(
    () => setProgressMessageActive(progressAdventures.length > 0),
    [progressAdventures.length]
  );

  useEffect(() => {
    if (id && !adventureTypeId) {
      dispatch(
        setSingleAdventureType(id, (response) => {
          if (!response?.id) {
            navigate("/");
          }
        })
      ).then(setIsLoading);
    } else {
      setIsLoading(false);
    }
  }, [adventureTypeId, dispatch, navigate, id]);

  useEffect(() => {
    if (adventureType && adventureType.adventuredata_id) {
      dispatchAdventureData(adventureType.adventuredata_id);
    }
  }, [dispatchAdventureData, adventureType]);

  useEffect(() => {
    if (adventureType?.id && !adventureType?.show_in_site) {
      navigate("/");
    }
  }, [adventureType, navigate]);

  const createNewAdventure = ({
    title,
    thumbnail,
    adventure_name,
    json_data,
  }) => {
    const createTheAdventure = (newAdventureDataId) => {
      const adventure = {
        title: title,
        adventure_name: adventure_name,
        thumbnail: thumbnail,
        adventuredata_id: newAdventureDataId,
        adventureType: json_data?.employee_gender === "female" ? 330 : id,
        language: userLanguage,
        json_data: json_data,
      };
      dispatch(
        createAdventure(adventure, (newAdventure) => {
          if (isLogin) {
            debugger;
            dispatch(setLoginUserAdventures(userData.email));
          }
          navigate(`/${userLanguage}/create-adventure/${newAdventure.id}`);
        })
      );
    };
    dispatch(duplicateAdventureData(adventureData.id, createTheAdventure));
  };
  const modal = (
    <Modal
      backgroundColor={"rgba(0,0,0,0.8)"}
      contentStyle={{
        padding: "30px 30px",
        width: "1000px",
        backgroundColor: colors.gray_1,
      }}
      triggerValue={"התחל ביצירה"}
    >
      <div
        style={{ backgroundColor: colors.gray_1 }}
        className={"px-md-7"}
        id={"tg"}
      >
        {progressMessageActive ? (
          <AdventureProgressMessage
            continueCreate={() => setProgressMessageActive(false)}
          />
        ) : adventureType.id && adventureData.id ? (
          <AdventureInitialForm
            createNewAdventure={createNewAdventure}
            dispatchAdventureData={dispatchAdventureData}
            instructions_main={instructions_main}
            instructions_title={instructions_title}
            instructions_image={instructions_image}
          />
        ) : (
          <div />
        )}
      </div>
    </Modal>
  );

  if (isLoading) return <LoaderPageBulletList />;

  return (
    <AdventureContext.Provider value={421}>
      <div className="single-adventure">
        <AdventureHeader scrollToForm={scrollToForm} />

        <Container className={"container"}>
          <Row>
            <Col sm={12}>
              <div id={formRef}>
                <AdventureDetails
                  hideList={isEmployeeBirthday}
                  modal={modal}
                  numOfRiddles={
                    adventureData.riddles.length > 0
                      ? adventureData.riddles.length
                      : ""
                  }
                  scrollToForm={scrollToForm}
                />
              </div>

              <AdventureReviews />

              <AdventureFaq />

              <AdventureSubscribe />

              <div className="container single-adventure-similar">
                <h3>הרפתקאות דומות</h3>
                <CustomizeAdventures hideDetails />
              </div>
            </Col>
          </Row>

          <ScreenGallery homepage={true} />

          <ContactUsCard />
        </Container>
      </div>
    </AdventureContext.Provider>
  );
};

export default ScreenAdventure;
