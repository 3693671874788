import React, {useEffect, useState} from "react";
import {Modal, VerticalScroller} from "../../../03_organisms";
import {SingleFrameTool} from "../../riddle/template/single-frame-body/single-frame-tool";
import {ScreenTool} from "../../../youdo-tools/src/components/05_screens/tool/screen-tool";
import {Col, Row} from "react-grid-system";
import Button from "../../../01_atoms/button/a-button";
import {useDispatch, useSelector} from "react-redux";
import {setActiveCategory, UPDATE_CATEGORY, updateCategory} from "../../../../store/actions/categories-actions";
import {SimpleLoader} from "../../../01_atoms";
import {colors} from "../../../00_base/variables";
import {selectActiveCategory} from "../../../../store/selectors";

export const CategoryModal = ({id, frame, categories}) => {
    const [isSaving, setIsSaving] = useState(false);
    const activeCategory = useSelector(selectActiveCategory)

    const dispatch = useDispatch();

    useEffect(() => {
    }, []);

    const handleCategoryDataChange = (name, value) => {
        dispatch({
            type: UPDATE_CATEGORY,
            category: {...activeCategory, mock_data: {...activeCategory.mock_data, [name]: value}}
        })
    };

    const saveMock = () => {
        setIsSaving(true);
        if (!isSaving) {
            dispatch(
                updateCategory(
                    {...activeCategory, mock_data: activeCategory.mock_data},
                    (res) => setIsSaving(false)
                )
            )
        }
    };

    const closeModal = () => dispatch(setActiveCategory(null));
    return (
        <Modal closePreActions={closeModal}
               isActive="active"
               hideTrigger={true}
               contentStyle={contentStyle}
               childrenWrapperStyle={{height: "100%"}}
               zIndex={1000000}>
            <Row className="d-flex" style={{height: "100%"}}>
                <Col md={6} style={colStyle}>
                    <VerticalScroller>
                        <SingleFrameTool categorydata={activeCategory.mock_data}
                                         categories={[categories]}
                                         className={"category-preview-tool"}
                                         constant={true}
                                         handleCategoryDataChange={handleCategoryDataChange}
                                         id={id}
                                         frame={frame}/>
                    </VerticalScroller>
                </Col>
                <Col md={6} style={colStyle}>
                    <ScreenTool toolId={categories}
                                toolData={{categorydata: activeCategory.mock_data}}
                                previewMode={true}/>
                </Col>
            </Row>
            <Button onClick={saveMock} style={saveButton}>
                {isSaving ? <SimpleLoader size={18} color={colors.white}/> : "שמור"}
            </Button>
        </Modal>
    )
};

const contentStyle = {
    position: "relative",
    width: "90%",
    height: "95%",
    padding: "20px 20px"
};

const saveButton = {
    width: "8rem",
    height: "3.6rem",
    position: "absolute",
    left: "10px",
    bottom: "10px",
    cursor: "pointer",
    zIndex: 100
};

const colStyle = {
    height: "100%",
    overflow: "hidden"
}