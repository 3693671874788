import React, {useState} from "react";
import {Image} from "../../01_atoms";
import classNames from "classnames";
import styled from "styled-components";
import {GalleryAdvancedData} from "./gallery-advanced";
import Slider from "../../03_organisms/slider";
import LINES from "../../../assets/images/scrambled_lines.png";

export const GallerySection = (
    {
        icon,
        active,
        itemStyle = {},
        indicatorStyle = {},
        arrowColor,
        arrowStyle,
        view = "blocks",
        items = []
    }) => {

    const renderImage = ((item, index) => <GallerySectionItem {...item}
                                                              galleryView={view}
                                                              itemStyle={itemStyle}
                                                              indicatorStyle={indicatorStyle}
                                                              key={index}/>);

    return (
        <div className={classNames(`tool-gallery-section view-${view}`, {active})}>
            {view === "matching" && <div className="tool-gallery-matching-lines"><Image src={LINES}/></div>}

            {view === "carrousel" ?
                <Slider items={items.map(renderImage)}
                        arrowColor={arrowColor}
                        arrowStyle={arrowStyle}
                        indicatorStyle={indicatorStyle}/>
                :
                <div className="tool-gallery-section-images">
                    {items.map(renderImage)}
                </div>
            }
        </div>
    )
};


const GallerySectionItem = (
    {
        image,
        frame,
        icon,
        advancedData,
        advancedDataType,
        galleryView,
        editable,
        itemStyle,
    }
) => {
    const [advancedDataActive, setAdvancedDataActive] = useState(false);
    const handleClick = () => {
        if (advancedDataType) {
            setAdvancedDataActive(!advancedDataActive)
        }
    };
    const isMatchingLines = galleryView === "matching";
    const itemClassName = `tool-gallery-item ${isMatchingLines ? editable ? "editable" : "default" : ""}`;

    return (
        <GalleryItem className={itemClassName} image={isMatchingLines && image} onClick={handleClick}>

            {isMatchingLines && icon &&
            <GalleryIcon image={image} className="gallery-item-icon">
                <Image src={icon}/>
            </GalleryIcon>}

            {frame &&
            <GalleryFrame {...itemStyle} image={image} pointer={advancedDataType} className="gallery-item-frame">
                <Image src={frame} width={"100%"} block/>
            </GalleryFrame>}
            <GalleryImage image={image}
                          className="gallery-item-image"
                          {...itemStyle}>

                {advancedDataActive &&
                <GalleryAdvancedData advancedData={advancedData}
                                     itemStyle={itemStyle}
                                     closeAdvancedData={() => setAdvancedDataActive(false)}
                                     advancedDataType={advancedDataType}/>
                }
                <Image src={isMatchingLines ? icon : image} block width="100%"/>
            </GalleryImage>
        </GalleryItem>
    )
};


const GalleryItem = styled.div`
    position: relative;
    overflow: hidden;
    ${props => props.image ? `background-image:url(${props.image})` : ""};
`;

const GalleryFrame = styled.div`
    &.gallery-item-frame {
        border-radius:${props => props.borderRadius || 0}px;
        cursor: ${props => props.pointer ? "pointer" : "default"};
        z-index: 1;
        ${props => props.frame && `
            background-image: url(${props.frame});
            background-size: cover;
            padding: 0;
        `}
        & + .gallery-item-image {
            background-image: url(${props => props.image});
            background-size: contain;
            background-position: center center
            position: absolute;
            // background-repeat: no-repeat;
            img {
                display: none;
            }
        }
    }
`;

const GalleryIcon = styled.div`
        width: 13px;
        height: 13px;
        position: absolute;
        top: 2px;
        left: 2px;
`;

const GalleryImage = styled.div`
    border-radius:${props => props.borderRadius || 0}px;
`;