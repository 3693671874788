import {colors, font_size} from "../../00_base/variables";
import {DICTIONARY} from "../../../dictionary/dictionary";

const language = localStorage.getItem('adventure-language') || "Hebrew";

export const shareFormFields = (
    contentColor,
    selfieButtonBackground,
    selfieButtonColor,
    fieldsBackground,
    fieldsColor,
    selfieUrl,
    showValidationError,
    isRoshhashana,
    fileUploaded
) => [{
    type: 'files',
    maxItems: 1,
    name: 'selfie',
    color: contentColor,
    labelStyle:{color:contentColor},
    inputStyle: {color: fieldsColor, paddingRight: '10px', backgroundColor: fieldsBackground},
    // label: DICTIONARY.upload_a_victory_photo[language],
     singleFile: true,
    multiple: false,
    hidePreview: false,
    previewWidth: '200px',
    previewHeight: '200px',
    buttonWidth: '100%',
    triggerStyle: {borderRadius: '30px', height: '45px', fontSize:font_size.sm, padding:0, backgroundColor: selfieButtonBackground, color: selfieButtonColor},
    triggerSize: '50px',
    triggerValue: DICTIONARY.winning_selfie[language],
    value: selfieUrl && [selfieUrl],
    setIsFileLoading: (data) => console.log("loading", data),
    fileUploaded: fileUploaded,
    styleMobile: {fontSize: font_size.md, fontWeight: 'bold', width: '250px'},
    styleDesktop: {position: 'fixed', opacity: 0, width: '60%', height: '100%', zIndex: 2, top: 0, right: 0},
    allowDownload: true
}, {
    type: 'text',
    label: DICTIONARY.name[language],
    name: 'name',
    placeholder: DICTIONARY.first_name[language],
    color: contentColor,
    labelStyle:{color:contentColor},
    inputStyle: {color: fieldsColor, paddingRight: '10px', backgroundColor: fieldsBackground},
    paddingVertical: '10px',
    paddingHorizontal: '10px',
    border: `0 0 1px solid ${colors.primary_dark}`,
    hide: !selfieUrl,
    errorMessage: 'יש להעלות שם פרטי',

}, {
    type: 'text',
    label: DICTIONARY.how_was_it[language],
    name: 'title',
    color: contentColor,
    labelStyle:{color:contentColor},
    inputStyle: {color: fieldsColor, paddingRight: '10px', backgroundColor: fieldsBackground},
    backgroundColor: fieldsBackground,
    paddingVertical: '10px',
    paddingHorizontal: '10px',
    border: `0 0 1px solid ${colors.primary_dark}`,
    hide: !selfieUrl
}
];
