import React from 'react';

class IconArrow extends React.Component {
    render() {
        const {color, onClick, right, width, height, style} = this.props;
        return (
            <div style={{cursor: 'pointer', display: 'inline-block', transform: `rotate(${right ? '180deg' : '0deg'})`, ...style && style}} onClick={onClick && onClick}>
                <svg width={width} height={height} viewBox="0 0 29 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="6-מחולל-חידות--מלא" transform="translate(-609.000000, -2299.000000)" fill={color}>
                            <g transform="translate(623.500000, 2307.776596) rotate(180.000000) translate(-623.500000, -2307.776596) translate(609.000000, 2299.276596)">
                                <path
                                    d="M20.5468085,16.906383 C20.0531915,16.906383 19.5595745,16.7212766 19.1893617,16.3510638 C18.5106383,15.6106383 18.5106383,14.4382979 19.2510638,13.7595745 L24.5574468,8.7 L19.2510638,3.64042553 C18.5106383,2.96170213 18.5106383,1.7893617 19.1893617,1.04893617 C19.8680851,0.308510638 21.0404255,0.308510638 21.7808511,0.987234043 L28.1978723,7.09574468 C28.6297872,7.52765957 28.8765957,8.08297872 28.8765957,8.7 C28.8765957,9.31702128 28.6297872,9.93404255 28.1978723,10.3042553 L21.7808511,16.412766 C21.4723404,16.7212766 20.9787234,16.906383 20.5468085,16.906383 Z"
                                    id="Path"/>
                                <path
                                    d="M26.4085106,10.4893617 L1.85106383,10.4893617 C0.80212766,10.4893617 9.23705556e-14,9.68723404 9.23705556e-14,8.63829787 C9.23705556e-14,7.5893617 0.80212766,6.78723404 1.85106383,6.78723404 L26.4085106,6.78723404 C27.4574468,6.78723404 28.2595745,7.5893617 28.2595745,8.63829787 C28.2595745,9.68723404 27.3957447,10.4893617 26.4085106,10.4893617 Z"
                                    id="Path"/>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        )
    }

    static defaultProps = {
        color: '#000000',
        width: '29px',
        height: '17px'
    }
}

export default IconArrow;
