import React, {useContext} from "react";
import {DigitalAdventures} from "../homepage/hp-block-adventures";
import {Col, Row} from "react-grid-system";
import {LanguageContext, Translation} from "../../../../languages";
import {DICT} from "../../../../languages/dictionary-names";
import {Image} from "../../../01_atoms";
const DIGITAL = "https://storage.googleapis.com/assets.youdoadventures.com/website/Drawing_digital%402x.png";

export const AdventuresDigitalGallery = () => {
    const {dictionary} = useContext(LanguageContext);
    return (
        <div className={"container"}>
            <Row className={"py-6"}>
                <Col lg={8} xl={7}>
                    <h1 className={"mt-0 mb-3 fs-8 fs-lg-9"}><Translation
                        id={DICT.HOMEPAGE_DIGITAL_ADVENTURES_TITLE}/></h1>
                    <p className="fs-6 color-black"
                       dangerouslySetInnerHTML={{__html: dictionary.homepageDigitalAdventuresDescription.replaceAll("\n", "<br/>")}}/>
                </Col>
                <Col lg={4} xl={5}>
                    <Image src={DIGITAL} width={"100%"}/>
                </Col>
            </Row>
            <DigitalAdventures hideDetails={true}/>
        </div>
    )
};