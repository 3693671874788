import React from 'react';
import {Element} from 'react-stylesheet';
import {colors} from "../../00_base/variables";

const LoadingBox = (
    {
        width = '100%',
        height = '25rem',
        background = colors.gray_1
    }) => {
    return (
        <Element width={width}
                 height={height}
                 background={background || colors.gray_1}
                 borderRadius='1rem'/>
    )
};

export default LoadingBox;