import React, {useContext} from "react";
import {colors, font_size} from "../../../00_base/variables";
import {Heading, Button, Image} from "../../../01_atoms";
import {Modal} from "../../../03_organisms";
import Login from "../../../05_screens/authentication/login/login";
import {AuthContext} from "../../../../providers/auth-provider";

export const RiddleAnswerModal = ({riddle, closeModal}) => {
    const {isLogin} = useContext(AuthContext);

    const {description_image, description_image_1, description_image_2, description_image_3} = riddle;
    return (
        <Modal isActive={'active'}
               closeModal={closeModal}
               contentStyle={{backgroundColor: isLogin ? "#fff" : "transparent", width: "1000px"}}
               closePreActions={closeModal}>
            {!!isLogin ?
                <div className={"p-5"}>
                    <Heading tag={'h2'} style={{margin: '10px 0'}}>{riddle.title}</Heading>
                    <p style={{fontSize: font_size.md}}>{riddle.description}</p>
                    <p style={{fontSize: font_size.md}}>פיתרון: {riddle.answer}</p>
                    {description_image &&
                    <Image src={description_image} alt={riddle.title}/>}
                    {description_image_1 &&
                    <Image src={description_image_1} alt={riddle.title}/>}
                    {description_image_2 &&
                    <Image src={description_image_2} alt={riddle.title}/>}
                    {description_image_3 &&
                    <Image src={description_image_3} alt={riddle.title}/>}
                    <div className="text-center">
                        <Button
                            onClick={closeModal}
                            background={colors.special}
                            value={'חזור'}
                            style={{width: '130px', margin: '0 auto'}}/>
                    </div>
                </div>
                :
                <div className={"bg-white"}>
                    <Login/>
                </div>
            }
        </Modal>
    )
};
