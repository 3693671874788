import React from 'react';

class IconEdit extends React.Component {
    render() {
        const {width,  height, onClick, index, style, color, opacity, display} = this.props;
        return (
            <div onClick={onClick && onClick} data-index={index && index} style={{...style && style, display: display}}>
                <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 19 27`} version="1.1" onClick={onClick && onClick} data-index={index && index}
                     style={{...style && style, display: 'inline'}}>
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"
                       opacity={opacity ? opacity : "0.582821801"}>
                        <g transform="translate(-1584.000000, -587.000000)" fill={color}>
                            <g transform="translate(1593.192532, 600.192532) rotate(-13.000000) translate(-1593.192532, -600.192532) translate(1582.192532, 589.192532)">
                                <g>
                                    <polygon
                                        points="3.3273625 13.8117375 8.1907375 18.6723625 17.8748625 8.9882375 13.0142375 4.1248625"/>
                                    <path
                                        d="M21.597125,3.3193875 L18.68075,0.4030125 C18.143125,-0.1346125 17.27275,-0.1346125 16.7365,0.4030125 L14.7895,2.3472625 L19.652875,7.2078875 L21.597125,5.2636375 C22.13475,4.7260125 22.13475,3.8570125 21.597125,3.3193875"/>
                                    <polygon points="-9.09494702e-13 22 7.3195 20.3102721 1.68654275 14.6805"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        )
    }

    static defaultProps = {
        color: '#D4D4D4',
        width: '19',
        height: '27',
        display: 'inline'
    }
}

export default IconEdit;