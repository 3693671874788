import React from "react";
import {FieldText, FilesUploader} from "../../../../02_molecules";

export const ImageContent = ({itemValue, changeHandler, imageSize}) => {
    return (
        <>
            <FilesUploader name="value"
                           value={itemValue}
                           previewWidth={"100%"}
                           multiple={false}
                           changeHandler={changeHandler}/>
            {itemValue &&
            <FieldText name="imageSize"
                       changeHandler={changeHandler}
                       label={"גודל התמונה"}
                       value={imageSize}/>
            }
        </>
    )
};