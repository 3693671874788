import React from 'react';

class IconClock extends React.Component {
    render() {
        const {color = "#000", size=22} = this.props;
        const cl2 = {stroke:color,strokeWidth:"2px",fill:"none",strokeLinecap:"round",strokeMiterlimit:"10"};
        const width = size;
        const height = size * 1.1;
        return (
            <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                <g id="icon_clock" transform="translate(1 1)">
                    <g id="Layer_1">
                        <circle id="Ellipse_186" cx="9" cy="9" r="9" fill="#fff" stroke={color} strokeWidth="2px"
                                transform="translate(0 4)"/>
                        <path id="Path_3139"
                              d="M104.774 1.331v.444h-.444v-.444h.444M105.391 0h-1.677a.713.713 0 0 0-.714.714v1.677a.713.713 0 0 0 .714.714h1.677a.713.713 0 0 0 .714-.714V.714a.713.713 0 0 0-.714-.714z"
                              transform="translate(-95.386 -1)"/>
                        <g id="Group_727" transform="translate(.4)">
                            <path id="Line_23" d="M0 0L0 2.029" className="cls-2" transform="translate(8.517 4.768)"/>
                            <path id="Line_24" d="M2.029 0L0 0" className="cls-2" transform="translate(14.805 13.085)"/>
                            <path id="Line_25" d="M0 2.029L0 0" className="cls-2" transform="translate(8.517 19.373)"/>
                            <path id="Line_26" d="M0 0L2.029 0" className="cls-2" transform="translate(.2 13.085)"/>
                            <path id="Line_27" d="M0 0L0.767 0.767" style={cl2}
                                  transform="translate(2.636 7.204)"/>
                            <path id="Line_28" d="M0.767 0L0 0.767" style={cl2}
                                  transform="translate(13.631 7.204)"/>
                            <path id="Line_29" d="M0.767 0.767L0 0" style={cl2}
                                  transform="translate(13.631 18.199)"/>
                            <path id="Line_30" d="M0 0.767L0.767 0" style={cl2}
                                  transform="translate(2.636 18.199)"/>
                        </g>
                        <path id="Path_3140"
                              d="M109.548 139.34L113.2 137l-2.34 3.647a.946.946 0 1 1-1.592-1.022.972.972 0 0 1 .28-.285z"
                              transform="translate(-101.035 -126.872)"/>
                    </g>
                </g>
            </svg>

        )
        // return (
        //     <svg width={size} height={size} viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
        //         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        //             <path d="M9.5,2.375 C9.7622,2.375 9.975,2.5878 9.975,2.85 L9.975,9.025 L15.675,9.025 C15.9372,9.025 16.15,9.2378 16.15,9.5 C16.15,9.7622 15.9372,9.975 15.675,9.975 L9.5,9.975 C9.2378,9.975 9.025,9.7622 9.025,9.5 L9.025,2.85 C9.025,2.5878 9.2378,2.375 9.5,2.375 M9.5,0 C4.25315,0 0,4.2533875 0,9.5 C0,14.74685 4.25315,19 9.5,19 C14.7466125,19 19,14.74685 19,9.5 C19,4.2533875 14.7466125,0 9.5,0" fill={color}/>
        //         </g>
        //     </svg>
        // )
    }

}

export default IconClock;