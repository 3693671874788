import React from "react";
import ForgotPassword from "./forgot-password";
import { useParams } from "react-router-dom";
import { Page } from "../../../04_layout";

const ScreenForgotPassword = (props) => {
  const params = useParams();
  const language = params.lang || "he";
  return (
    <Page
      style={{ justifyContent: "center" }}
      className={`forgot-password language-${language}`}
    >
      <ForgotPassword />
    </Page>
  );
};

export default ScreenForgotPassword;
