import React from "react";
import {useSelector} from "react-redux";
import classNames from "classnames";
import {MdRemoveRedEye} from "react-icons/md";
import {selectCurrentAdventureType} from "../../../../store/selectors";
import {gutters} from "../../../00_base/variables";
import {FieldCheckbox, FieldText, FieldTextArea, FilesUploader} from "../../../02_molecules";
import {Modal} from "../../../03_organisms";
import ScreenAdventure from "../../../website/pages/adventure/screen-adventure";
import {CreateFaq} from "../../../03_organisms/create-faq/create-faq";

export const AdventureTypeSiteDetails = ({handleChange}) => {
    const adventureType = useSelector(selectCurrentAdventureType);

    const {
        show_in_site,
        description,
        description_short,
        base_price,
        players,
        age,
        duration,
        site_image_main,
        site_image_mobile,
        site_image_tablet,
        site_image_desktop,
        instructions_main,
        instructions_title,
        instructions_image,
        faq,
        personal_customize = true
    } = adventureType;

    const imageControlStyle = (width) => {
        return {
            width: `${width}%`,
            minHeight: "16rem",
            padding: `0 ${gutters.sm}`,
            display: "flex",
            flexDirection: "column"
        }
    };

    const Trigger = ({title, subtitle}) => {
        return (
            <div>
                <p>{title}</p>
                <p>{subtitle}</p>
            </div>
        )
    }

    const horizontalFieldStyle = {
        labelStyle: {paddingLeft: ".8rem", marginBottom: 0},
        controlStyle: {width: "inherit"},
        inputStyle: {width: "90px"}
    };

    const eyeButtonStyle = {
        backgroundColor: show_in_site ? "#5388ff" : "#b3b3b3",
        border: 0,
        padding: "5px 10px",
        width: "47px",
        height: "30px",
        borderRadius: "5px",
        textAlign: "center",
        cursor: show_in_site ? "pointer" : "auto",
        fontSize: "20px",
        color: "#fff"
    };
    const triggerStyle = {
        backgroundColor: "#fff",
        border: "2px dashed #bbbbbb",
    }
    return (
        <>
            <div className="adventure-type-tab-item adventure-type-site-details">
                <div className="adventure-type-tab-item-flex">
                    <FieldCheckbox changeHandler={handleChange}
                                   name="show_in_site"
                                   options={["מוצג למכירה באתר"]}
                                   controlStyle={{marginBottom: 0, display: "flex", alignItems: "center"}}
                                   inputBackground={"#4d4d4d"}
                                   inputBorder={0}
                                   value={[show_in_site ? "מוצג למכירה באתר" : ""]}/>
                    <Modal triggerStyle={eyeButtonStyle}
                           triggerComponent={MdRemoveRedEye}
                           contentStyle={{width: "93%", height: "93%", maxHeight: "100%"}}>
                        <ScreenAdventure isModal={true} adventureTypeId={adventureType.id}/>
                    </Modal>
                    {/*<a href={show_in_site && `/adventure/${adventureType.id}`}*/}
                    {/*target={show_in_site && "_blank"}*/}
                    {/*className={classNames("eye-button", {active: show_in_site})}>*/}
                    {/*<MdRemoveRedEye color={"#fff"} size={"20px"}/>*/}
                    {/*</a>*/}

                </div>

                {show_in_site &&
                <div className="adventure-type-site-details-container">
                    <div className="adventure-type-tab-container">
                        <FieldTextArea name="description"
                                       label="תיאור ההרפתקה באתר"
                                       wysiwyg={true}
                                       changeHandler={handleChange}
                                       value={description}
                                       rows={5}/>

                        <FieldTextArea name="description_short"
                                       label="תיאור מקוצר לעמוד הבית"
                                       changeHandler={handleChange}
                                       value={description_short}
                                       rows={3}/>

                    </div>

                    <div className="adventure-type-additional-details">
                        <FieldText name="base_price"
                                   label="מחיר:"
                                   className={"field"}
                                   dir="horizontal"
                                   changeHandler={handleChange}
                                   {...horizontalFieldStyle}
                                   value={base_price}/>

                        <FieldText type="number"
                                   name="players"
                                   label="מספר שחקנים:"
                                   className="field"
                                   dir="horizontal"
                                   changeHandler={handleChange}
                                   {...horizontalFieldStyle}
                                   value={players}/>

                        <FieldText name="age"
                                   label="גילאי השחקנים:"
                                   className="field"
                                   changeHandler={handleChange}
                                   dir="horizontal"
                                   {...horizontalFieldStyle}
                                   value={age}/>

                        <FieldText name="duration"
                                   label="משך זמן ההרפתקה:"
                                   className="field"
                                   changeHandler={handleChange}
                                   dir="horizontal"
                                   {...horizontalFieldStyle}
                                   inputStyle={{width: "90px"}}
                                   value={duration}/>
                    </div>
                    <div className="adventure-type-site-details-images">
                        <FilesUploader name={'site_image_main'}
                                       value={site_image_main}
                                       label={"תמונה ראשית באתר"}
                                       multiple={false}
                                       shrinkImage={false}
                                       previewWidth={'100%'}
                                       previewHeight={'14rem'}
                                       previewStyle={{margin: "0 auto"}}
                                       triggerValue={<Trigger title={"העלה תמונה ראשית"}/>}
                                       triggerStyle={{...triggerStyle, width:"100%", height:"14rem"}}
                                        changeHandler={handleChange}
                                       controlStyle={{...imageControlStyle("100"), paddingRight: 0}}/>

                        <FilesUploader name={'site_image_desktop'}
                                       value={site_image_desktop}
                                       label={"תמונת רקע דסקטופ"}
                                       triggerValue={<Trigger title={"תמונת רקע דסקטופ"} subtitle={"1920*400"}/>}
                                       previewWidth={'20rem'}
                                       previewHeight={'14rem'}
                                       triggerStyle={{...triggerStyle, width:"20rem", height:"14rem"}}
                                       multiple={false}
                                       shrinkImage={false}
                                       changeHandler={handleChange}
                                       controlStyle={{...imageControlStyle(35), paddingRight: 0}}/>

                        <FilesUploader name={'site_image_tablet'}
                                       value={site_image_tablet}
                                       label={"תמונת רקע טאבלט"}
                                       triggerValue={<Trigger title={"תמונת רקע טאבלט"} subtitle={"1180*400"}/>}
                                       triggerStyle={{...triggerStyle, width:"20rem", height:"14rem"}}
                                       previewWidth={'20rem'}
                                       previewHeight={'14rem'}
                                       multiple={false}
                                       shrinkImage={false}
                                       changeHandler={handleChange}
                                       controlStyle={imageControlStyle(30)}/>

                        <FilesUploader name={'site_image_mobile'}
                                       value={site_image_mobile}
                                       label={"תמונת רקע מובייל"}
                                       previewWidth={'20rem'}
                                       previewHeight={'14rem'}
                                       triggerValue={<Trigger title={"תמונת רקע מובייל"} subtitle={"360*480"}/>}
                                       triggerStyle={{...triggerStyle, width:"20rem", height:"14rem"}}
                                       multiple={false}
                                       shrinkImage={false}
                                       changeHandler={handleChange}
                                       controlStyle={{...imageControlStyle(20)}}/>
                    </div>
                </div>
                }
            </div>

            <div className="adventure-type-tab-item adventure-type-site-details mt-4">
                <div className="adventure-type-tab-item-flex">
                    <FieldCheckbox changeHandler={handleChange}
                                   name="personal_customize"
                                   options={["ניתנת למכירה בהתאמה אישית"]}
                                   inputBackground={"#4d4d4d"}
                                   inputBorder={0}
                                   controlStyle={{marginBottom: 0, display: "flex", alignItems: "center"}}
                                   value={[personal_customize ? "ניתנת למכירה בהתאמה אישית" : ""]}/>

                    <a href={personal_customize ? "https://play.youdoadventures.com" : `javascript:void(0)`}
                       target={personal_customize && "_blank"}
                       className={classNames("eye-button", {active: personal_customize})}>
                        <MdRemoveRedEye color={"#fff"} size={"20px"}/>
                    </a>

                </div>
                {personal_customize &&
                <div className="adventure-type-site-details-container">
                    <FieldTextArea label="הנחיה כללית"
                                   name="instructions_main"
                                   placeholder={"בשלב הראשון מלאו את הפרטים והעלו את התוכן הייחודי שלכם"}
                                   changeHandler={handleChange}
                                   value={instructions_main}
                                   rows={3}/>

                    <FieldText label={"הנחיה עבור גיבור ההרפתקה"}
                               placeholder={"למשל: הכניסו את שם בעל.ת היומולדת"}
                               name="instructions_title"
                               value={instructions_title}
                               changeHandler={handleChange}/>

                    <FieldText label={"הנחיה עבור תמונת ההרפתקה"}
                               placeholder={"למשל: הכניסו את תמונת בעל.ת היומולדת"}
                               name="instructions_image"
                               value={instructions_image}
                               changeHandler={handleChange}/>
                </div>
                }
            </div>

            <div className="adventure-type-tab-item adventure-type-site-details mt-4">
                <CreateFaq sections={faq} handleSubmit={(sections) => handleChange("faq", sections)}/>
            </div>
        </>
    )
}