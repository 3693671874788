import React, {useContext} from 'react';
import {Element} from 'react-stylesheet';
import styled from "styled-components";
import {CreateAdventureContext} from "../../../pages/adventure-create/screen-adventure-create";
import {LanguageContext, Translation} from "../../../../../languages";
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import {DICT} from "../../../../../languages/dictionary-names";
import {colors, gutters} from '../../../../00_base/variables';
import {Circle, Heading} from '../../../../01_atoms';
import {FieldText} from '../../../../02_molecules';
import {ThemeContext} from "../../../../../providers/theme-provider";

export const TriviaSection = (
    {
        id,
        question,
        answer,
        options,
        questionLabel,
        isFirstSection,
        isLastSection,
        isActive,
        handleToolDataChange,
        switchToNextQuestion,
        switchToPreviousQuestion,
        tabHandler,
        incompleteActive,
        invalidFields,
        index
    }) => {
    const adventureContext = useContext(CreateAdventureContext);
    const languageContext = useContext(LanguageContext);
    const {warningsActive} = useContext(CreateAdventureContext);
    const {mode} = useContext(ThemeContext);
    const darkMode = mode === "dark";
    const adventure_name = adventureContext.adventure_name;

    const placeholder = 'הכנס שאלה';
    const name = `question`;


    const onChange = (name, value) => handleToolDataChange(name, value, index);

    const rightStyle = {opacity: isFirstSection ? 1 : .4, cursor: 'pointer'};
    const leftStyle = {opacity: isLastSection ? 1 : .4, cursor: 'pointer'};
    const labelStyle = {marginBottom: 0, marginLeft: gutters.sm, textAlign: 'center'};
    const inputStyle = {
        flex: 1,
        borderColor: `${warningsActive && !question ? "#7d2115" : colors.gray_2}`
    };

    const {rtl} = useContext(LanguageContext);

    const RIGHT_ICON = <MdKeyboardArrowRight color={darkMode ? colors.black : colors.white} size={25}/>;
    const LEFT_ICON = <MdKeyboardArrowLeft color={darkMode ? colors.black : colors.white} size={25}/>;

    return (
        <SectionWrapper isActive={isActive} key={id} className="create-trivia-section">
            <Heading
                tag={'h4'}
                className={"color-black"}
                textAlign={'center'}>
                {questionLabel || <Translation id={DICT.QUESTION_NUMBER}/>} {!questionLabel ? index + 1 : ""}
            </Heading>
            <Element marginTop={gutters.lg}>
                <form>
                    <div className="create-trivia-question">
                        <Circle size={35}
                                onClick={switchToPreviousQuestion}
                                background={darkMode ? colors.white : colors.gray_6}
                                style={rightStyle}>
                            {rtl ? RIGHT_ICON : LEFT_ICON}
                        </Circle>
                        <FieldText required
                                   value={question.replace("{name}", adventure_name)}
                                   initialValue={question.replace("{name}", adventure_name)}
                                   invalid={(incompleteActive && !question) || (invalidFields && !question)}
                                   placeholder={placeholder}
                                   index={index}
                                   controlStyle={{marginBottom: 0}}
                                   labelStyle={labelStyle}
                                   inputStyle={inputStyle}
                                   name={name}
                                   changeHandler={onChange}
                                   className="create-trivia-section-input"/>
                        <Circle size={35}
                                onClick={switchToNextQuestion}
                                background={darkMode ? colors.white : colors.gray_6}
                                style={leftStyle}>
                            {rtl ? LEFT_ICON : RIGHT_ICON}
                        </Circle>
                    </div>

                    <div className="create-trivia-options">
                        {options.map((option, o) => {

                            const isChecked = answer === o;
                            // const showWarning = warningActive && !option;
                            const controlStyle = {marginBottom: gutters.xxs};
                            // const border = `1px solid ${showWarning ? "#7d2115" : colors.gray_2}`;

                            const placeholder = languageContext.dictionary[DICT[isChecked ? "INSERT_CORRECT_ANSWER" : "INSERT_INCORRECT_ANSWER"]];
                            const name = `option_${index}_${o + 1}`;
                            const changeHandler = (name, value, isValid) => handleToolDataChange('options', value, index, o);

                            return <div className="create-trivia-option" key={o}>
                                <FieldText name={name}
                                           controlStyle={controlStyle}
                                           label={placeholder}
                                           value={option.replace("{name}", adventure_name)}
                                           labelStyle={{color: isChecked && colors.select}}
                                           invalid={(incompleteActive && !option) || (invalidFields && !option) || (warningsActive && !option)}
                                           keydownHandler={e => tabHandler(e, o)}
                                           changeHandler={changeHandler}/>
                            </div>
                        })}
                    </div>
                </form>
            </Element>
        </SectionWrapper>
    )
};

const SectionWrapper = styled.div`
    display: ${props => props.isActive ? 'block' : 'none'};
    max-width: 60rem;
    margin: 0 auto;
`;