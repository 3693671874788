import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Element, VBox} from 'react-stylesheet';
import ContactListItem from "./create-tool-contact-list-item";
import "../../../../../assets/styles/create-contact-list.scss";

const checkIsValid = ({items}) => {
    const expectedLength = items.length * 2;
    const invalidNamesLength = items.filter(item => !item.name).length;
    const invalidVideosLength = items.filter(item => !item.audio && !item.video).length;
    const validLength = expectedLength - (invalidNamesLength + invalidVideosLength);

    if (!validLength) {
        return 0
    }
    return validLength / expectedLength * 100;
};

const CreateToolContactList = ({toolData, handleToolDataChange, adventureType, invalidFields, warningActive}) => {
    const [dropdownOpen, setDropdownOpen] = useState(-1);
    const [activeSection, setActiveSection] = useState(0);

    return (
        <Element className={`create-contact-list`} position={'relative'} onClick={() => setDropdownOpen(-1)}>
            {!!toolData.items &&
                <VBox>
                    {toolData.items.map((item, index) => {
                        const handleChange = (name, value, index, optionIndex) => handleToolDataChange(checkIsValid, name, value, index, optionIndex);
                        return <ContactListItem {...item}
                                                handleToolDataChange={handleChange}
                                                dropdownOpen={dropdownOpen}
                                                warningActive={warningActive}
                                                setDropdownOpen={setDropdownOpen}
                                                setActiveSection={setActiveSection}
                                                activeSection={activeSection}
                                                toolData={toolData}
                                                invalidFields={invalidFields}
                                                adventureType={adventureType}
                                                index={index}
                                                key={item.id}/>
                    })}
                </VBox>}
        </Element>
    )
};

CreateToolContactList.defaultProps = {
    submitHandler: (toolData) => console.log('toolData', toolData),
    toolData: {items: []}
};

CreateToolContactList.propTypes = {
    toolData: PropTypes.object,
    submitHandler: PropTypes.func
};


export default CreateToolContactList;