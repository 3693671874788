import React from "react";
import {FieldTextArea} from "../../../02_molecules/form-fields";

export const SingleFrameAdvanced = ({handleChange, description}) => {
  return(
      <div style={{width:"100%"}} className={"pr-5 pt-3 mb-5"}>
          <FieldTextArea changeHandler={handleChange}
                         name={"description"}
                         value={description}
                         wysiwyg={true}
                         toolbarHidden={false}
                         label={"תיאור הפריים"}/>
      </div>
      )
};