import React, {useContext, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {MdAddCircle, MdLock, MdLockOpen} from 'react-icons/md';
import {Element} from 'react-stylesheet';
import styled from "styled-components";
import classNames from "classnames";
import {AuthContext} from "../../../providers/auth-provider";
import * as adventureTemplatesActions from "../../../store/actions/adventure-template-actions";
import {colors, gutters, viewport} from '../../00_base/variables';
import {Button, Image, Span} from '../../01_atoms';
import {ToolTip} from "../../02_molecules";
import {sidebarCreateItems, sidebarManagementItems, sidebarSupportItems} from "./sidebar-items";
import "../../../assets/styles/adventure-type-sequence.scss"

const YOUDOLOGO = "https://storage.googleapis.com/assets.youdoadventures.com/website/youdo_logo.png";

const AppSidebar = () => {
    const {userData, logout, isLogin, isCreator, isTranslator} = useContext(AuthContext);
    const firstName = userData.name;
    const lastName = userData.lastName;

    const username = firstName + " " + (lastName && lastName !== "null" ? lastName : "");
    const [currentPathname, setCurrentPathname] = useState(window.location.pathname);
    const [isOpen, setIsOpen] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        if (isOpen) {
            document.getElementById("app-wrapper").classList.add("sidebar-open");
        } else {
            document.getElementById("app-wrapper").classList.remove("sidebar-open");
        }
    }, [isOpen]);

    useEffect(() => {
        dispatch(adventureTemplatesActions.setAllAdventureTemplates(0))
    }, [dispatch]);

    const renderTabItem = item => {
        const {id, icon, MdIcon, href, label, singleLabel, hrefCreate} = item;
        const isActive = currentPathname === href;
        const className = classNames("sidebar-item", {active: isActive});
        return (
            <SidebarItem onClick={() => setCurrentPathname(href)} className={className} key={id}>
                <a className="sidebar-item-icon-style" href={href || '/'}
                   target={item.target}
                   style={{
                       fontSize: "17px",
                       color: colors.white,
                       display: 'flex',
                       alignItems: 'center'
                   }}>
                    <div className="sidebar-item-icon d-flex justify-content-center">
                        {icon && <img src={icon} alt={`sidebar icon ${label}`}/>}
                        {MdIcon && <MdIcon color={"#000"}/>}
                    </div>
                    <Span>{label}</Span>
                </a>

                {!!hrefCreate &&
                <Element>
                    <ToolTip tip={`צור ${singleLabel}`}
                             id={hrefCreate}
                             placeholder={
                                 <MdAddCircle size={20} color={colors.gray_6}/>
                             }/>
                </Element>
                }
            </SidebarItem>
        )
    };

    return (
        <Sidebar id='app-sidebar' className={classNames("sidebar", {open: isOpen})}>
            <div className="sidebar-content">
                <div className="sidebar-logo-wrapper">
                    <Image src={YOUDOLOGO}/>
                </div>
                <div className={"logout-wrapper"}>
                    <p className={"sidebar-username"}>{username}</p>
                    <div className={"logout-button"} onClick={logout}>התנתק</div>
                    <div className="sidebar-minimize" onClick={() => setIsOpen(!isOpen)}>
                        {isOpen ? <MdLock size={30}/> : <MdLockOpen size={30}/>}
                    </div>
                </div>
                <hr/>
                {isLogin &&
                <div className="sidebar-items-wrapper">
                    <h6>יצירה</h6>
                    {sidebarCreateItems.filter(item => !(item.adminsOnly && isCreator)).map(renderTabItem)}
                    <hr/>
                    <h6>תמיכה</h6>
                    {sidebarSupportItems.filter(item => !(item.adminsOnly && isCreator)).map(renderTabItem)}
                    <hr/>
                    <h6>ניהול</h6>
                    {sidebarManagementItems.filter(item => !(item.adminsOnly && isCreator && !isTranslator)).map(renderTabItem)}
                </div>
                }

            </div>

        </Sidebar>
    )
};

const Sidebar = styled.div`
    background:${colors.gray_2};
    border-left: 1px solid #000
    overflow: auto;
    position: fixed;
    right:0;
    top:0;
    bottom:0;
    z-index:10;
    transition: width .3s;
    width: 5.5rem; 
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    &::-webkit-scrollbar {
       display: none;
    }
    @media(min-width:${viewport.lg}) {
      display: flex;
    }
    &:hover {
         width: 23.2rem;
        .sidebar-username, .logout-button {
            visibility: visible;
        }
        .sidebar-logo-wrapper {
            padding: 20px;
        }
         .sidebar-logo-wrapper {
            padding: 20px;
        }
    }
    &.open, &:hover {
        width: 23.2rem;
        .sidebar-username, .logout-button {
                    opacity: 1;
        }
        .sidebar-logo-wrapper {
            padding: 20px;
            img {
             width: 120px 
            }
        }
    }
    .sidebar-content {
        position: relative;
        flex: 1;
        width: 100%;
    }
    .sidebar-logo-wrapper {
        padding: 10px;
        transition: padding .3s;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        img {
             width: 30px 
             transition: width .3s;
        }
    }
    .sidebar-items-wrapper {
        // margin-top: 3.5rem;
        h6 {
           padding: 5px 16px 0;
           margin: 0;
           font-weight: bold;
           display:none;
        }
    }
    .sidebar-item-icon {
        width: 50px;
        text-align: center;
        display: flex;
        align-items: center
    }
    &.open, &:hover {
        .sidebar-items-wrapper h6 {
            display: block;
        }
        .sidebar-minimize {
          left: 12px;
        }
    }
    .sidebar-minimize {
        position: absolute;
        bottom: 10px;
        left: 50px
        transition: left .3s;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .logout-wrapper {
        padding: ${gutters.sm} ${gutters.xl};
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        position: relative;
    }
    .sidebar-username {
        opacity: 0;
        transition: opacity .3s;
        font-size: 17px;
        margin: 0;
        white-space: nowrap;
    }
    .logout-button {
        opacity: 0;
        transition: opacity .3s;
        background-color: transparent
        display: inline;
        text-align: right;
        font-size: 15px;
        color: #5388ff;
        text-decoration: underline;
        padding: 0;
    }
    
`;

const SidebarItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${gutters.xxs} 0;
    cursor: pointer;
    .sidebar-item-icon {
        padding:0 16px;
        text-align:center
    }
    a {
        display: block;
            padding: 10px 0;
        width: 100%;
    }
    span {
        padding: 0 10px;
        white-space: nowrap;
     }
    
    &:hover a {
        background: ${colors.gray_1};
 
    }
    &.active a {
        background: ${colors.white};
    }

`;

export default AppSidebar;