import React, {useEffect, useReducer, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Container} from 'react-grid-system';
import {Element, HBox} from 'react-stylesheet';
import * as couponsActions from '../../../store/actions/coupons-actions';
import {selectAllAdventureTypes} from "../../../store/selectors";
import {setAllAdventureTypes} from "../../../store/actions/adventure-type-actions";
import {gutters} from '../../00_base/variables';
import {TailLoader} from '../../01_atoms';
import {CreatorHeader, Form} from '../../03_organisms';
import {API_URL} from "../../../store/actions/fetch-data";
import { useParams } from 'react-router-dom';


const SET_COUPON_DATA = 'SET_COUPON_DATA';
const FIELD_CHANGE = 'FIELD_CHANGE';

const formReducer = (state, action) => {
    switch (action.type) {
        case SET_COUPON_DATA:
            return {
                couponData: action.couponData
            };
        case FIELD_CHANGE:
            return {
                couponData: {...state.couponData, [action.name]: action.value},
            };
        default:
            return state
    }
};

const CreateCoupon = props => {
    const params = useParams()
    const adventureTypes = useSelector(selectAllAdventureTypes);
    const couponId = props.id ? props.id : params ? parseInt(params?.id) : null;
    const [formState, dispatchFormState] = useReducer(formReducer, {
        couponData: props.couponData || {
            name: '',
            type: ['סכום'],
            sum: 0,
            max_uses: null,
            is_active: false
        }
    });

    const [isLoading, setIsLoading] = useState(false);
    const [initialUpdated, setInitialUpdated] = useState(false);

    const dispatch = useDispatch();

    const setAdventureTypes = () => dispatch(setAllAdventureTypes(0));

    useEffect(() => {
        let ignore = false;
        async function fetchData() {
            setAdventureTypes();
        }
        fetchData();
        return () => { ignore = true; }
    }, [dispatch]);

    useEffect(() => {
        if (couponId) {
            dispatch(couponsActions.getSingleCoupon(couponId)).then(response => {
                dispatchFormState({
                    type: SET_COUPON_DATA,
                    couponData: response,
                });
                setIsLoading(false);
                setInitialUpdated(true)
            })
        } else {
            setIsLoading(false);
            setInitialUpdated(true)
        }
    }, [dispatch, couponId]);


    const formSubmitHandler = formData => {
        setIsLoading(true);
        debugger
        if (couponId) {
            dispatch(
                couponsActions.updateCoupon(
                    {
                        ...formData,
                        type: formData.type && formData.type.id,
                        id: couponId,
                    },
                    (success) => {
                        console.log('success', success);
                        setIsLoading(false);
                    })
            )
        } else {
            dispatch(
                couponsActions.createCoupon({
                    ...formData,
                    type: formData.type && formData.type.id,
                }, (success) => {
                    window.location.href = `/coupons`
                })
            )
        }
    };


    // const activeTemplate = allTemplates.find(template => template.id === formState.couponData.couponstemplates[0]);
    const duplicateHandler = () => {
        dispatch(
            couponsActions.createCoupon({
                    ...formState.couponData,
                    id: null
                },
                success => {
                    window.location.href = `/coupon/${success.id}`
                }
            )
        )
    };

    //  id,
    // name,
    // type,
    // sum,
    // max_uses,
    // is_active,
    // created_at

    const {type, name, sum, max_uses, adventuretype_id} = formState.couponData;
    console.log("type'", type)


    const formFields = [{
        type: 'text',
        label: 'שם הקופון',
        name: 'name',
        value: name,
        placeholder: 'הכניסו את שם הקופון',
    }, {
        type: 'select',
        name: 'type',
        value: type?.id,
        label: 'אופן חישוב ההנחה',
        options: [{value: 'sum', label: 'סכום'}, {value: 'percent', label: 'אחוזים'}],
        controlStyle: {width: '200px', zIndex: 2}
    },
        {
            type: 'number',
            label: 'כמות ההנחה',
            placeholder: 'הכניסו את סכום ההנחה',
            name: 'sum',
            value: sum,
            controlStyle: {flex: 1, paddingRight: gutters.md}
        }, {
            type: 'number',
            label: 'מספר שימושים מקסימלי',
            placeholder: 'הכניסו מספר שימושים מקסימלי',
            name: 'max_uses',
            value: max_uses
        }, {
            type: 'select',
            name: 'adventuretype_id',
            value: adventuretype_id,
            label: 'שיוך לסוג הרפתקה',
            options: adventureTypes.map(item => {return {value: item.id, label: item.name}})
        }];

    if (!initialUpdated) {
        return (
            <Element padding={`0 ${gutters.sm}`} width={'100%'}>
                <HBox justifyContent={'center'} width={'100%'} paddingTop={gutters.xxxl}>
                    <TailLoader/>
                </HBox>
            </Element>
        )
    }

    const couponUrl = `coupon.youdoadventures.com/${couponId}`;
    const api = `${API_URL}/coupons/${couponId}`;

    return (
        <Element padding={`0 ${gutters.sm}`} width={'100%'}>
            <Container style={{width: '100%'}}>
                <CreatorHeader
                    title={'צור קופון'}
                    showButtons={!!couponId}
                    dupicator={duplicateHandler}
                    api={api}
                    url={couponUrl}/>

                {initialUpdated &&
                <Form
                    formStyle={{display: 'flex', flexWrap: 'wrap'}}
                    width={'100%'}
                    fields={formFields}
                    handleFormSubmit={formSubmitHandler}
                    submitStyle={{backgroundColor: 'red'}}
                    submitLabel={isLoading ? '...' : 'שמור'}/>
                }


                {/* <TemplatesModal/> */}
            </Container>
        </Element>
    )
};

export default CreateCoupon;