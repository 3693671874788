import React from "react";
import {AdventuresForBusinessCard, ReviewMocks, Subscribe} from "../../../../03_organisms";

export const BlockExtra = () => {
    return (
        <div>
            <div className="py-4 py-lg-7">
                <ReviewMocks/>
            </div>
            <div className="py-sm-4 py-lg-5">
                <Subscribe/>
            </div>
            <div className="py-4 py-lg-7">
                <AdventuresForBusinessCard/>
            </div>
        </div>
    )
};