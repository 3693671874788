import styled from "styled-components";


const cssObject = ({width, height, paddingVertical, paddingHorizontal, backgroundColor, backgroundColorHover, color, fontSize, colorHover, backgroundImage, borderRadius, borderColor, borderWidth, borderStyle, borderColorHover, cursor, boxShadow}) => `
        box-sizing: border-box;
        padding: ${paddingVertical}px ${paddingHorizontal}px;
        ${width ? `width: ${width}` : ""}px;
        ${height ? `height: ${height}` : ""}px;
        ${cursor ? `cursor: ${cursor}` : ""};
        ${backgroundColor ? `background-color: ${backgroundColor}` : ""};
        ${color ? `color: ${color}` : ""};
        ${backgroundImage ? `background-image: url(${backgroundImage})` : ""}; 
        ${fontSize ? `font-size: ${fontSize}px` : ""};
        ${borderColor ? `border-color: ${borderColor}` : ""};
        ${borderWidth ? `border-width: ${borderWidth}px` : ""};
        ${borderStyle ? `border-style: ${borderStyle}` : ""};
        ${borderRadius ? `border-radius: ${borderRadius}%` : ""};
        ${boxShadow ? `box-shadow: ${boxShadow}` : ""};
        ${(backgroundColorHover || colorHover || borderColorHover) &&
        `&:hover {
            ${backgroundColorHover ? `background-color: ${backgroundColorHover}` : ""};
            ${colorHover ? `color: ${colorHover}` : ""};
            ${borderColorHover ? `border-color: ${borderColorHover}` : ""};
        }`}
  
    `;

export const StyledDiv = styled.div`
    &.${props => props.className}{
        ${props => cssObject(props)}
    }
`;

export const StyledButton = styled.button`
    &.${props => props.className}{
        ${props => cssObject(props)}
       
    }
`;
