import React from 'react';
import styled from 'styled-components';

class IconBars extends React.Component {
    render() {
        const {color, onClick, style} = this.props;

        const lines = (length) => {
            const linesList = [];
            for (let i = 0; i < length; i++) {
                linesList.push(<div className={'line'} key={i}/>)
            }
            return linesList;
        };

        return (
            <Bars onClick={onClick && onClick} style={style && style} color={color}>
                {lines(this.props.lines)}
            </Bars>
        )
    }

    static defaultProps = {
        lines: 2,
        color: '#fff'
    }
}

export default IconBars;

const Bars = styled.div`
    width: 1.9rem;
    .line {
        width: 100%;
        height: .3rem;
        background: ${props => props.color ? props.color : '#151515'};
        &:nth-child(2) {
            margin: .4rem 0;
        }
    }
`;