import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Container } from "react-grid-system";
import { colors } from "../../00_base/variables";
import { Modal, Tabs } from "../../03_organisms";
import { TableContainer, TableTitle, TableWrapper } from "../../03_organisms";
import CreateRiddle from "../riddle/create-riddle";
import { SingleFrame } from "../riddle/template/single-frame";
import {
  setSingleAdventure,
  updateAdventure,
} from "../../../store/actions/adventure-actions";
import { setAllAdventureTypes } from "../../../store/actions/adventure-type-actions";
import { updateAdventureData } from "../../../store/actions/adventure-data-actions";
import { selectAdventure } from "../../../store/selectors";
import { adventureTabsItems } from "./adventure-tabs-items";
import { SingleAdventuresLoading } from "./single-adventures-loading";
import "../../../assets/styles/single-adventure.scss";
import { ScreenHomepageView } from "../../youdo-tools/src/components/05_screens/homepage/screen-homepage-view.jsx";
import { useParams } from "react-router-dom";

export const AdventureContext = React.createContext({
  activeFrame: null,
});

export const tabsRoutes = ["main", "welcome", "lobby", "sequence", "comments"];

export const SingleAdventure = () => {
  const params = useParams();
  const id = params?.id;
  // const activeFrameRoute = params.activeFrame;
  const singleAdventure = useSelector(selectAdventure);
  const {
    adventure,
    adventureData,
    adventureType,
    riddles,
    frames,
    activeFrame,
    activeRiddle,
  } = singleAdventure;
  const activeTab = tabsRoutes.findIndex((item) => item === params.activeTab);
  const [previewActive, setPreviewActive] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      dispatch(setAllAdventureTypes());
    }

    fetchData();
  }, [dispatch]);

  useEffect(() => {
      async function fetchData() {
          dispatch(setSingleAdventure(id))
      }
      fetchData();
  }, [dispatch, id]);
  
  const setAdventure = useCallback(() => {
    dispatch(setSingleAdventure(id));
  }, [dispatch, id]);

  useEffect(() => {
    setAdventure();
    // const interval = setInterval(() => {
    //     setAdventure();
    // }, 3000);
    // return () => clearInterval(interval);
  }, [setAdventure]);

  const handleAdventureDataChange = (n, value) => {
    let name = n;
    if (n === "entrance_image_desktop") {
      name = "welcome_screen_url_desktop";
    }
    if (n === "entrance_image_mobile") {
      name = "welcome_screen_url_mobile";
    }
    dispatch(updateAdventureData({ ...adventureData, [name]: value }));
  };
  const handleChange = (name, value) => {
    
    dispatch(updateAdventure({ ...adventure, [name]: value }, id));
  };
  const handleJsonDataChange = (name, value) => {
    dispatch(
      updateAdventure({
        ...adventure,
        json_data: { ...adventure.json_data, [name]: value },
      })
    );
  };

  const togglePreview = () => {
    setPreviewActive(!previewActive);
  };

  if (!adventure?.id) {
  return <SingleAdventuresLoading />;
  }

  const tabsItems = adventureTabsItems(
    handleAdventureDataChange,
    handleChange,
    handleJsonDataChange,
    singleAdventure,
    activeFrame,
    togglePreview
  );
  const adventureContext = { activeFrame: activeFrame };

  const singleFrame = (
    <div
      className={classNames("adventure-type-single-frame", {
        active: activeFrame,
      })}
    >
      {activeFrame && (
        <SingleFrame frame={frames.find((frame) => frame.id === activeFrame)} />
      )}
    </div>
  );

  const singleRiddle = (
    <div
      className={classNames("single-riddle-modal", { active: activeRiddle })}
    >
      {activeRiddle && (
        <Container className="single-riddle-modal-content">
          <CreateRiddle
            frames={frames}
            riddle={riddles.find((riddle) => riddle.id === activeRiddle)}
          />
        </Container>
      )}
    </div>
  );

  return (
    <AdventureContext.Provider value={adventureContext}>
      {previewActive && (
        <Modal
          isActive="active"
          hideTrigger={true}
          zIndex={999999}
          padding={"10px"}
          closePreActions={() => setPreviewActive(false)}
          contentStyle={{ maxHeight: "100%", width: "100%" }}
        >
          <ScreenHomepageView
            adventure={adventure}
            adventureType={adventureType}
            adventureData={adventureData}
            riddles={riddles}
            passwordConfirmed={true}
            passcode={adventure.passcode}
          />
        </Modal>
      )}
      <TableWrapper className={"single-adventure"}>
        <TableTitle className={"single-adventure-header"}>
          <h1>
            {adventure && adventure.title ? adventure.title : "הזמנה חדשה"}
          </h1>
        </TableTitle>
        <TableContainer>
          {singleRiddle}
          {singleFrame}
          <div
            className="single-adventure-form pb-5"
            style={{ maxWidth: "1200px" }}
          >
            <Tabs
              items={tabsItems}
              horizontal={true}
              activeTab={activeTab}
              {...tabsStyle}
            />
          </div>
        </TableContainer>
      </TableWrapper>
    </AdventureContext.Provider>
  );
};
const tabsStyle = {
  containerStyle: { width: "100%" },
  contentStyle: {},
  labelsStyle: { width: "auto", borderBottom: 0 },
  labelStyle: { width: "100%", margin: "5px 0", textAlign: "center" },
  tabLabelActiveStyle: {
    border: 0,
    backgroundColor: colors.gray_1,
    borderRadius: "4px",
  },
};
