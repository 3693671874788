import React, {useEffect, useState} from "react";
import {FieldCheckbox} from "../../../../../02_molecules";
import {SingleFrameTool} from "../single-frame-tool";
import {MdArrowDropDown, MdArrowDropUp} from "react-icons/md";

export const AdditionalDataTool = (
    {handleToolDataChange, extraTool = {}}
) => {
    const [isActive, setIsActive] = useState(extraTool.toolId);
    const [isOpen, setIsOpen] = useState(extraTool.toolId);
    const {toolData = {}, toolId} = extraTool;

    const toggle = (name, value) => {
        setIsActive(value);
        if (!value) {
            handleToolDataChange("extraTool", "");
        }
    };

    useEffect(() => {
        setIsOpen(isActive);
    }, [isActive])

    const handleChange = (name, value) => {
        if (name === "categories") {
            handleToolDataChange("extraTool", {...extraTool || {}, toolId: value[0]});
        } else {
            handleToolDataChange("extraTool", {...extraTool || {}, toolData: {...toolData, [name]: value}});
        }
    };

    const IconDrop = isOpen ? MdArrowDropUp : MdArrowDropDown;
    const wrapperStyle = {borderBottom: `${isOpen ? 1 : 0}px dashed #000`, marginBottom: "10px"};

    return (
        <div style={wrapperStyle}>
            <div className={"d-flex justify-content-between align-items-center"}>
                <FieldCheckbox options={[LABEL]}
                               name="extraTool"
                               controlStyle={{marginBottom: 0}}
                               value={isActive ? [LABEL] : []}
                               changeHandler={toggle}/>
                {isActive && <IconDrop onClick={() => setIsOpen(!isOpen)} cursor={"pointer"} size={20}/>}
            </div>

            {isActive && isOpen &&
            <div className={"pt-3 px-3 pb-0"}>
                <SingleFrameTool categorydata={toolData}
                                 handleChange={handleChange}
                                 categories={[toolId]}
                                 id={toolId}
                                 handleCategoryDataChange={handleChange}/>
            </div>
            }
        </div>
    )
};

const LABEL = "כלי נוסף";