import React, {useEffect, useState} from 'react'
import {Td, TdContent, Tr} from "../../../03_organisms";
import {MdMoreVert} from "react-icons/md";
import {Circle} from "../../../01_atoms";
import {TableInnerMenu} from "../../../03_organisms/table/table-inner-menu/table-inner-menu";
import {dateFormat} from "../../../../helpers/dates/date-format";
import {fetchData} from "../../../../store/actions/fetch-data";

export const SingleCustomer = (
    {
        user,
        menuActive,
        index,
        innerMenuItems,
        SingleUser,
        setInnerMenuActive,
        goToOrdersPage
    }) => {

    const {user_id, name, active, lastname, email, role, phone, created_at} = user;
    const [userDetails, setUserDetails] = useState({});
    const [adventuresLength, setAdventuresLength] = useState("");

    useEffect(() => {
            fetchData(
                "get",
                `users/${user_id}`,
                {},
                response => {
                    setUserDetails(response)
                }
            )}
    , [user_id]);

    useEffect(() => {
        debugger
        if (userDetails.email) {
            fetchData(
                "get",
                `/adventures-by-email/count?email=${userDetails.email}`, // TODO: Change url
                {},
                response => setAdventuresLength(response[0]?.count)
            )
        }

    }, [userDetails])

    return (
        <Tr className="single-user" key={user_id}>
            <Td><TdContent>{name || "-"}</TdContent></Td>
            <Td><TdContent>{lastname || "-"}</TdContent></Td>
            <Td><TdContent>{phone || "-"}</TdContent></Td>
            <Td><TdContent>{email || "-"}</TdContent></Td>
            <Td><TdContent>{role || "-"}</TdContent></Td>
            <Td><TdContent>{dateFormat(created_at) || "-"}</TdContent></Td>
            <Td><TdContent style={{cursor:"pointer"}} onClick={goToOrdersPage}>{adventuresLength}</TdContent></Td>
            <Td><TdContent>{active ? "פעיל" : "סגור"}</TdContent></Td>
            <Td><TdContent>{user_id}</TdContent></Td>
            <Td>
                <TdContent>
                    <Circle background={menuActive ? "#f1f1f1" : "transparent"}
                            onClick={setInnerMenuActive}
                            cursor={"pointer"} size={25}>
                        <MdMoreVert size={20} color={"#d2d2d2"}/>
                    </Circle>
                    {menuActive && <TableInnerMenu items={innerMenuItems} itemId={user_id}/>}
                </TdContent>
            </Td>
        </Tr>
    )
};