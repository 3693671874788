import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../../store/actions/fetch-data";
import {
  Table,
  TableContainer,
  TableHeaderLabel,
  TableTitle,
  TableWrapper,
  Tr,
} from "../../../03_organisms";
import { tableHeader } from "../../../03_organisms/table/table-header";
import { NavigateItems } from "../../../03_organisms/navigation-groups/navigate-items";
import { MdMoreVert } from "react-icons/md";
import { SingleCustomer } from "./single-customer";
import { TableItemsHeader } from "./table-header";
import classNames from "classnames";

import SVG_SORT from "../../../../assets/images/svg-icons/sort-icon.svg";
import { LoaderPageContent } from "../../../01_atoms/loaders/loader-content/loader-content";

const CustomersGallery = () => {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [innerMenuActive, setInnerMenuActive] = useState(null);
  const [itemsLength, setItemsLength] = useState(200);
  const [filterLength, setFilterLength] = useState(null);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedCustomers, setSortedCustomers] = useState();

  const getAllCustomers = useCallback(() => {
    fetchData(`get`, `users?page=${pageIndex}`, {}, (response) => {
      setCustomers(response);
      setItemsLength(200);
      setFilterLength(null);
    });
  }, [setCustomers, setItemsLength, setFilterLength, pageIndex]);

  useEffect(getAllCustomers, [getAllCustomers, pageIndex]);

  useEffect(() => {}, [sortedCustomers]);

  useEffect(() => {
    if (sortBy) {
      setSortOrder(sortOrder === "desc" ? "asc" : "desc");
      setPageIndex(0);

      fetchData(`get`, `userCustomSort?key=${sortBy}`, {}, (response) => {
        setSortedCustomers(response);
      });
    }
    return () => setSortBy("");
  }, [sortBy, sortOrder]);

  const HeaderLabel = ({ name, label, action }) => {
    let Icon, iconSize, onClick;
    if (action === "sort") {
      Icon = () => (
        <div
          onClick={() => setSortBy(name)}
          className={classNames("sort-icon")}
        >
          <img src={SVG_SORT} alt={"sort"} />
        </div>
      );
      iconSize = 14;

      onClick = () => setSortBy(name);
    }
    return (
      <TableHeaderLabel>
        {Icon && (
          <Icon
            onClick={onClick}
            className={classNames("table-header-icon")}
            size={iconSize}
            color={"#000"}
          />
        )}
        {label}
      </TableHeaderLabel>
    );
  };
  if (!customers) {
    return <LoaderPageContent />;
  }

  const searchCustomerBy = (str) => {
    fetchData("get", `userCustomSearch?str=${str}`, {}, (response) => {
      setCustomers(response);
      setFilterLength(response.length);
      setItemsLength(response.length);
    });
  };

  const removeAllFilters = () => {
    getAllCustomers();
  };

  const goToOrdersPage = (email) => {
    // const customerEmail = customers.find(customer => customer.user_id === customerId).email;
    navigate({ pathname: `/he/admin/reservations`, emailFilter: email });
  };

  const innerMenuItems = [{ Icon: MdMoreVert, label: "עריכה" }];
  const tableHeaderItems = [
    <HeaderLabel label="שם פרטי" name="name" action="sort" />,
    <HeaderLabel label="שם משפחה" name="lastname" action="sort" />,
    <HeaderLabel label="טלפון" />,
    <HeaderLabel label="מייל" name="user_email" action="sort" />,
    <HeaderLabel label="סוג משתמש" />,
    <HeaderLabel label="ת.הרשמה" name="id" action="sort" />,
    <HeaderLabel label="הזמנות" />,
    <HeaderLabel label="סטטוס" />,
    <HeaderLabel label="מזהה" />,
  ];
  const tableRow = (item, index) => {
    return (
      <SingleCustomer
        user={item}
        key={index}
        index={pageIndex * 15 + index + 1}
        // setActiveItemId={() => setActiveItemId(item.id)}
        setInnerMenuActive={() =>
          setInnerMenuActive(innerMenuActive === index ? null : index)
        }
        // changeHandler={updateAdventureType}
        goToOrdersPage={() => goToOrdersPage(item.email)}
        innerMenuItems={innerMenuItems}
        menuActive={innerMenuActive === index}
      />
    );
  };

  return (
    <TableWrapper>
      <TableTitle>
        <h1>לקוחות</h1>
      </TableTitle>

      <TableContainer fluid style={{ width: "100%" }}>
        {/*{!!activeItemId && adventureTypeModal}*/}

        <TableItemsHeader
          clickHandler={searchCustomerBy}
          filterLength={filterLength}
          removeAllFilters={removeAllFilters}
        />

        <Table cellSpacing="0" cellPadding="0">
          <thead>
            <Tr>{tableHeaderItems.map(tableHeader)}</Tr>
          </thead>
          <tbody>{customers.map(tableRow)}</tbody>
        </Table>

        {itemsLength > 15 && (
          <NavigateItems
            length={itemsLength}
            handleChange={(index) => setPageIndex(index)}
          />
        )}
      </TableContainer>
    </TableWrapper>
  );
};

export default CustomersGallery;
