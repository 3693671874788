const shuffle = (array) => {
    var currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
};
const initialData = (level, multiplier, boardSize) => {
    const size_0 = boardSize * 0;
    const size_1 = boardSize * (multiplier * .01);
    const size_2 = boardSize * (multiplier * .02);
    const size_3 = boardSize * (multiplier * .03);
    const size_4 = boardSize * (multiplier * .04);
    const size_5 = boardSize * (multiplier * .05);
    const size_6 = boardSize * (multiplier * .06);

    /* LEVEL 1 */
    if (level === 1) {
        const arr = [
            { index: 0, backgroundPosition: `${-size_0}px ${-size_0}px` },
            { index: 1, backgroundPosition: `${-size_1}px ${-size_0}px` },
            { index: 2, backgroundPosition: `${-size_2}px ${-size_0}px` },
            { index: 3, backgroundPosition: `${-size_0}px ${-size_1}px` },
            { index: 4, backgroundPosition: `${-size_1}px ${-size_1}px` },
            { index: 5, backgroundPosition: `${-size_2}px ${-size_1}px` },
            { index: 6, backgroundPosition: `${-size_0}px ${-size_2}px` },
            { index: 7, backgroundPosition: `${-size_1}px ${-size_2}px` },
        ];
        return ([
            { location: 0, ...arr[2], left: size_0, top: size_0 },
            { location: 1, ...arr[0], left: size_1, top: size_0 },
            { location: 2, ...arr[6], left: size_2, top: size_0 },
            { location: 3, ...arr[5], left: size_0, top: size_1 },
            { location: 4, ...arr[4], left: size_1, top: size_1 },
            { location: 5, ...arr[1], left: size_2, top: size_1 },
            { location: 6, ...arr[7], left: size_0, top: size_2 },
            { location: 7, ...arr[3], left: size_1, top: size_2 },
        ])
    }
    /* LEVEL 2 */
    if (level === 2) {
        const arr = [
            { index: 0, backgroundPosition: `${-size_0}px ${-size_0}px` },
            { index: 1, backgroundPosition: `${-size_1}px ${-size_0}px` },
            { index: 2, backgroundPosition: `${-size_2}px ${-size_0}px` },
            { index: 3, backgroundPosition: `${-size_3}px ${-size_0}px` },
            { index: 4, backgroundPosition: `${-size_0}px ${-size_1}px` },
            { index: 5, backgroundPosition: `${-size_1}px ${-size_1}px` },
            { index: 6, backgroundPosition: `${-size_2}px ${-size_1}px` },
            { index: 7, backgroundPosition: `${-size_3}px ${-size_1}px` },
            { index: 8, backgroundPosition: `${-size_0}px ${-size_2}px` },
            { index: 9, backgroundPosition: `${-size_1}px ${-size_2}px` },
            { index: 10, backgroundPosition: `${-size_2}px ${-size_2}px` },
            { index: 11, backgroundPosition: `${-size_3}px ${-size_2}px` },
            { index: 12, backgroundPosition: `${-size_0}px ${-size_3}px` },
            { index: 13, backgroundPosition: `${-size_1}px ${-size_3}px` },
            { index: 14, backgroundPosition: `${-size_2}px ${-size_3}px` },
        ];

        // 10, 15, 5, 14, 1, 4, 8, 12, 11, 13, 3, 9, 6, 2, 7

        return ([
            { location: 0, ...arr[9], left: size_0, top: size_0 },
            { location: 1, ...arr[14], left: size_1, top: size_0 },
            { location: 2, ...arr[4], left: size_2, top: size_0 },
            { location: 3, ...arr[13], left: size_3, top: size_0 },
            { location: 4, ...arr[0], left: size_0, top: size_1 },
            { location: 5, ...arr[3], left: size_1, top: size_1 },
            { location: 6, ...arr[7], left: size_2, top: size_1 },
            { location: 7, ...arr[11], left: size_3, top: size_1 },
            { location: 8, ...arr[10], left: size_0, top: size_2 },
            { location: 9, ...arr[12], left: size_1, top: size_2 },
            { location: 10, ...arr[2], left: size_2, top: size_2 },
            { location: 11, ...arr[8], left: size_3, top: size_2 },
            { location: 12, ...arr[5], left: size_0, top: size_3 },
            { location: 13, ...arr[1], left: size_1, top: size_3 },
            { location: 14, ...arr[6], left: size_2, top: size_3 },
        ])
    }

    /* LEVEL 3 */
    if (level === 3) {
        const arr = [
            { index: 0, backgroundPosition: `${-size_0}px ${-size_0}px` },
            { index: 1, backgroundPosition: `${-size_1}px ${-size_0}px` },
            { index: 2, backgroundPosition: `${-size_2}px ${-size_0}px` },
            { index: 3, backgroundPosition: `${-size_3}px ${-size_0}px` },
            { index: 4, backgroundPosition: `${-size_4}px ${-size_0}px` },
            { index: 5, backgroundPosition: `${-size_0}px ${-size_1}px` },
            { index: 6, backgroundPosition: `${-size_1}px ${-size_1}px` },
            { index: 7, backgroundPosition: `${-size_2}px ${-size_1}px` },
            { index: 8, backgroundPosition: `${-size_3}px ${-size_1}px` },
            { index: 9, backgroundPosition: `${-size_4}px ${-size_1}px` },
            { index: 10, backgroundPosition: `${-size_0}px ${-size_2}px` },
            { index: 11, backgroundPosition: `${-size_1}px ${-size_2}px` },
            { index: 12, backgroundPosition: `${-size_2}px ${-size_2}px` },
            { index: 13, backgroundPosition: `${-size_3}px ${-size_2}px` },
            { index: 14, backgroundPosition: `${-size_4}px ${-size_2}px` },
            { index: 15, backgroundPosition: `${-size_0}px ${-size_3}px` },
            { index: 16, backgroundPosition: `${-size_1}px ${-size_3}px` },
            { index: 17, backgroundPosition: `${-size_2}px ${-size_3}px` },
            { index: 18, backgroundPosition: `${-size_3}px ${-size_3}px` },
            { index: 19, backgroundPosition: `${-size_4}px ${-size_3}px` },
            { index: 20, backgroundPosition: `${-size_0}px ${-size_4}px` },
            { index: 21, backgroundPosition: `${-size_1}px ${-size_4}px` },
            { index: 22, backgroundPosition: `${-size_2}px ${-size_4}px` },
            { index: 23, backgroundPosition: `${-size_3}px ${-size_4}px` },
        ];
        return ([
            { location: 0, ...arr[21], left: size_0, top: size_0 },
            { location: 1, ...arr[0], left: size_1, top: size_0 },
            { location: 2, ...arr[15], left: size_2, top: size_0 },
            { location: 3, ...arr[5], left: size_3, top: size_0 },
            { location: 4, ...arr[17], left: size_4, top: size_0 },
            { location: 5, ...arr[4], left: size_0, top: size_1 },
            { location: 6, ...arr[10], left: size_1, top: size_1 },
            { location: 7, ...arr[19], left: size_2, top: size_1 },
            { location: 8, ...arr[9], left: size_3, top: size_1 },
            { location: 9, ...arr[16], left: size_4, top: size_1 },
            { location: 10, ...arr[20], left: size_0, top: size_2 },
            { location: 11, ...arr[18], left: size_1, top: size_2 },
            { location: 12, ...arr[23], left: size_2, top: size_2 },
            { location: 13, ...arr[1], left: size_3, top: size_2 },
            { location: 14, ...arr[2], left: size_4, top: size_2 },
            { location: 15, ...arr[3], left: size_0, top: size_3 },
            { location: 16, ...arr[6], left: size_1, top: size_3 },
            { location: 17, ...arr[11], left: size_2, top: size_3 },
            { location: 18, ...arr[14], left: size_3, top: size_3 },
            { location: 19, ...arr[12], left: size_4, top: size_3 },
            { location: 20, ...arr[13], left: size_0, top: size_4 },
            { location: 21, ...arr[7], left: size_1, top: size_4 },
            { location: 22, ...arr[8], left: size_2, top: size_4 },
            { location: 23, ...arr[22], left: size_3, top: size_4 },
        ])
    }

    /* LEVEL 4 */
    if (level === 4) {
        const arr = shuffle([
            { index: 0, backgroundPosition: `${-size_0}px ${-size_0}px` },
            { index: 1, backgroundPosition: `${-size_1}px ${-size_0}px` },
            { index: 2, backgroundPosition: `${-size_2}px ${-size_0}px` },
            { index: 3, backgroundPosition: `${-size_3}px ${-size_0}px` },
            { index: 4, backgroundPosition: `${-size_4}px ${-size_0}px` },
            { index: 5, backgroundPosition: `${-size_5}px ${-size_0}px` },

            { index: 6, backgroundPosition: `${-size_0}px ${-size_1}px` },
            { index: 7, backgroundPosition: `${-size_1}px ${-size_1}px` },
            { index: 8, backgroundPosition: `${-size_2}px ${-size_1}px` },
            { index: 9, backgroundPosition: `${-size_3}px ${-size_1}px` },
            { index: 10, backgroundPosition: `${-size_4}px ${-size_1}px` },
            { index: 11, backgroundPosition: `${-size_5}px ${-size_1}px` },

            { index: 12, backgroundPosition: `${-size_0}px ${-size_2}px` },
            { index: 13, backgroundPosition: `${-size_1}px ${-size_2}px` },
            { index: 14, backgroundPosition: `${-size_2}px ${-size_2}px` },
            { index: 15, backgroundPosition: `${-size_3}px ${-size_2}px` },
            { index: 16, backgroundPosition: `${-size_4}px ${-size_2}px` },
            { index: 17, backgroundPosition: `${-size_5}px ${-size_2}px` },

            { index: 18, backgroundPosition: `${-size_0}px ${-size_3}px` },
            { index: 19, backgroundPosition: `${-size_1}px ${-size_3}px` },
            { index: 20, backgroundPosition: `${-size_2}px ${-size_3}px` },
            { index: 21, backgroundPosition: `${-size_3}px ${-size_3}px` },
            { index: 22, backgroundPosition: `${-size_4}px ${-size_3}px` },
            { index: 23, backgroundPosition: `${-size_5}px ${-size_3}px` },

            { index: 24, backgroundPosition: `${-size_0}px ${-size_4}px` },
            { index: 25, backgroundPosition: `${-size_1}px ${-size_4}px` },
            { index: 26, backgroundPosition: `${-size_2}px ${-size_4}px` },
            { index: 27, backgroundPosition: `${-size_3}px ${-size_4}px` },
            { index: 28, backgroundPosition: `${-size_4}px ${-size_4}px` },
            { index: 29, backgroundPosition: `${-size_5}px ${-size_4}px` },

            { index: 30, backgroundPosition: `${-size_0}px ${-size_5}px` },
            { index: 31, backgroundPosition: `${-size_1}px ${-size_5}px` },
            { index: 32, backgroundPosition: `${-size_2}px ${-size_5}px` },
            { index: 33, backgroundPosition: `${-size_3}px ${-size_5}px` },
            { index: 34, backgroundPosition: `${-size_4}px ${-size_5}px` },
        ]);
        return ([
            { location: 0, ...arr[0], left: size_0, top: size_0 },
            { location: 1, ...arr[1], left: size_1, top: size_0 },
            { location: 2, ...arr[2], left: size_2, top: size_0 },
            { location: 3, ...arr[3], left: size_3, top: size_0 },
            { location: 4, ...arr[4], left: size_4, top: size_0 },
            { location: 5, ...arr[5], left: size_5, top: size_0 },
            { location: 6, ...arr[6], left: size_0, top: size_1 },
            { location: 7, ...arr[7], left: size_1, top: size_1 },
            { location: 8, ...arr[8], left: size_2, top: size_1 },
            { location: 9, ...arr[9], left: size_3, top: size_1 },
            { location: 10, ...arr[10], left: size_4, top: size_1 },
            { location: 11, ...arr[11], left: size_5, top: size_1 },
            { location: 12, ...arr[12], left: size_0, top: size_2 },
            { location: 13, ...arr[13], left: size_1, top: size_2 },
            { location: 14, ...arr[14], left: size_2, top: size_2 },
            { location: 15, ...arr[15], left: size_3, top: size_2 },
            { location: 16, ...arr[16], left: size_4, top: size_2 },
            { location: 17, ...arr[17], left: size_5, top: size_2 },
            { location: 18, ...arr[18], left: size_0, top: size_3 },
            { location: 19, ...arr[19], left: size_1, top: size_3 },
            { location: 20, ...arr[20], left: size_2, top: size_3 },
            { location: 21, ...arr[21], left: size_3, top: size_3 },
            { location: 22, ...arr[22], left: size_4, top: size_3 },
            { location: 23, ...arr[23], left: size_5, top: size_3 },
            { location: 24, ...arr[24], left: size_0, top: size_4 },
            { location: 25, ...arr[25], left: size_1, top: size_4 },
            { location: 26, ...arr[26], left: size_2, top: size_4 },
            { location: 27, ...arr[27], left: size_3, top: size_4 },
            { location: 28, ...arr[28], left: size_4, top: size_4 },
            { location: 29, ...arr[29], left: size_5, top: size_4 },
            { location: 30, ...arr[30], left: size_0, top: size_5 },
            { location: 31, ...arr[31], left: size_1, top: size_5 },
            { location: 32, ...arr[32], left: size_2, top: size_5 },
            { location: 33, ...arr[33], left: size_3, top: size_5 },
            { location: 34, ...arr[34], left: size_4, top: size_5 }
        ])
    }

    /* LEVEL 5*/
    if (level === 5) {
        const arr = shuffle([
            { index: 0, backgroundPosition: `${-size_0}px ${-size_0}px` },
            { index: 1, backgroundPosition: `${-size_1}px ${-size_0}px` },
            { index: 2, backgroundPosition: `${-size_2}px ${-size_0}px` },
            { index: 3, backgroundPosition: `${-size_3}px ${-size_0}px` },
            { index: 4, backgroundPosition: `${-size_4}px ${-size_0}px` },
            { index: 5, backgroundPosition: `${-size_5}px ${-size_0}px` },
            { index: 6, backgroundPosition: `${-size_6}px ${-size_0}px` },

            { index: 7, backgroundPosition: `${-size_0}px ${-size_1}px` },
            { index: 8, backgroundPosition: `${-size_1}px ${-size_1}px` },
            { index: 9, backgroundPosition: `${-size_2}px ${-size_1}px` },
            { index: 10, backgroundPosition: `${-size_3}px ${-size_1}px` },
            { index: 11, backgroundPosition: `${-size_4}px ${-size_1}px` },
            { index: 12, backgroundPosition: `${-size_5}px ${-size_1}px` },
            { index: 13, backgroundPosition: `${-size_6}px ${-size_1}px` },

            { index: 14, backgroundPosition: `${-size_0}px ${-size_2}px` },
            { index: 15, backgroundPosition: `${-size_1}px ${-size_2}px` },
            { index: 16, backgroundPosition: `${-size_2}px ${-size_2}px` },
            { index: 17, backgroundPosition: `${-size_3}px ${-size_2}px` },
            { index: 18, backgroundPosition: `${-size_4}px ${-size_2}px` },
            { index: 19, backgroundPosition: `${-size_5}px ${-size_2}px` },
            { index: 20, backgroundPosition: `${-size_6}px ${-size_2}px` },

            { index: 21, backgroundPosition: `${-size_0}px ${-size_3}px` },
            { index: 22, backgroundPosition: `${-size_1}px ${-size_3}px` },
            { index: 23, backgroundPosition: `${-size_2}px ${-size_3}px` },
            { index: 24, backgroundPosition: `${-size_3}px ${-size_3}px` },
            { index: 25, backgroundPosition: `${-size_4}px ${-size_3}px` },
            { index: 26, backgroundPosition: `${-size_5}px ${-size_3}px` },
            { index: 27, backgroundPosition: `${-size_6}px ${-size_3}px` },

            { index: 28, backgroundPosition: `${-size_1}px ${-size_4}px` },
            { index: 29, backgroundPosition: `${-size_2}px ${-size_4}px` },
            { index: 30, backgroundPosition: `${-size_3}px ${-size_4}px` },
            { index: 31, backgroundPosition: `${-size_4}px ${-size_4}px` },
            { index: 32, backgroundPosition: `${-size_5}px ${-size_4}px` },
            { index: 33, backgroundPosition: `${-size_6}px ${-size_4}px` },

            { index: 34, backgroundPosition: `${-size_0}px ${-size_5}px` },
            { index: 35, backgroundPosition: `${-size_1}px ${-size_5}px` },
            { index: 36, backgroundPosition: `${-size_2}px ${-size_5}px` },
            { index: 37, backgroundPosition: `${-size_3}px ${-size_5}px` },
            { index: 38, backgroundPosition: `${-size_4}px ${-size_5}px` },
            { index: 39, backgroundPosition: `${-size_5}px ${-size_5}px` },
            { index: 40, backgroundPosition: `${-size_6}px ${-size_5}px` },

            { index: 41, backgroundPosition: `${-size_0}px ${-size_5}px` },
            { index: 42, backgroundPosition: `${-size_1}px ${-size_5}px` },
            { index: 43, backgroundPosition: `${-size_2}px ${-size_5}px` },
            { index: 44, backgroundPosition: `${-size_3}px ${-size_5}px` },
            { index: 45, backgroundPosition: `${-size_4}px ${-size_5}px` },
            { index: 46, backgroundPosition: `${-size_5}px ${-size_5}px` },
            { index: 47, backgroundPosition: `${-size_6}px ${-size_5}px` },


        ]);
        return ([
            { location: 0, ...arr[0], left: size_0, top: size_0 },
            { location: 1, ...arr[1], left: size_1, top: size_0 },
            { location: 2, ...arr[2], left: size_2, top: size_0 },
            { location: 3, ...arr[3], left: size_3, top: size_0 },
            { location: 4, ...arr[4], left: size_4, top: size_0 },
            { location: 5, ...arr[5], left: size_5, top: size_0 },
            { location: 6, ...arr[6], left: size_6, top: size_0 },

            { location: 7, ...arr[7], left: size_0, top: size_1 },
            { location: 8, ...arr[8], left: size_1, top: size_1 },
            { location: 9, ...arr[9], left: size_2, top: size_1 },
            { location: 10, ...arr[10], left: size_3, top: size_1 },
            { location: 11, ...arr[11], left: size_4, top: size_1 },
            { location: 12, ...arr[12], left: size_5, top: size_1 },
            { location: 13, ...arr[13], left: size_6, top: size_1 },

            { location: 14, ...arr[14], left: size_0, top: size_2 },
            { location: 15, ...arr[15], left: size_1, top: size_2 },
            { location: 16, ...arr[16], left: size_2, top: size_2 },
            { location: 17, ...arr[17], left: size_3, top: size_2 },
            { location: 18, ...arr[18], left: size_4, top: size_2 },
            { location: 19, ...arr[19], left: size_5, top: size_2 },
            { location: 20, ...arr[20], left: size_6, top: size_2 },

            { location: 21, ...arr[21], left: size_0, top: size_3 },
            { location: 22, ...arr[22], left: size_1, top: size_3 },
            { location: 23, ...arr[23], left: size_2, top: size_3 },
            { location: 24, ...arr[24], left: size_3, top: size_3 },
            { location: 25, ...arr[25], left: size_4, top: size_3 },
            { location: 26, ...arr[26], left: size_5, top: size_3 },
            { location: 27, ...arr[27], left: size_6, top: size_3 },

            { location: 28, ...arr[28], left: size_0, top: size_4 },
            { location: 29, ...arr[29], left: size_1, top: size_4 },
            { location: 30, ...arr[30], left: size_2, top: size_4 },
            { location: 31, ...arr[31], left: size_3, top: size_4 },
            { location: 32, ...arr[32], left: size_4, top: size_4 },
            { location: 33, ...arr[33], left: size_5, top: size_4 },
            { location: 34, ...arr[34], left: size_6, top: size_4 },

            { location: 35, ...arr[35], left: size_0, top: size_5 },
            { location: 36, ...arr[36], left: size_1, top: size_5 },
            { location: 37, ...arr[37], left: size_2, top: size_5 },
            { location: 38, ...arr[38], left: size_3, top: size_5 },
            { location: 39, ...arr[39], left: size_4, top: size_5 },
            { location: 40, ...arr[40], left: size_5, top: size_5 },
            { location: 41, ...arr[41], left: size_6, top: size_5 },

            { location: 42, ...arr[42], left: size_0, top: size_6 },
            { location: 43, ...arr[43], left: size_1, top: size_6 },
            { location: 44, ...arr[44], left: size_2, top: size_6 },
            { location: 45, ...arr[45], left: size_3, top: size_6 },
            { location: 46, ...arr[46], left: size_4, top: size_6 },
            { location: 47, ...arr[47], left: size_5, top: size_6 },
        ])
    }
}

export default initialData;