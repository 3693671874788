import {Col, Row} from "react-grid-system";
import React from "react";
import {Label} from "../../../../01_atoms";
import {FieldColor, ToolTip} from "../../../../02_molecules";

export const CssCreateColors = ({id, backgroundColor, backgroundColorHover, backgroundColorActive, color, colorHover, colorActive, borderColor, borderColorHover, borderColorActive, labelStyle, inputStyle, changeHandler}) => {
    const fieldCommonProps = {labelStyle, inputStyle, changeHandler};
    return (
        <>
            <Row align={"end"}>
                <Col xs={4}>
                    <Label style={labelStyle}>רקע</Label>
                    <ToolTip id={`dynamic-background-color-${id}`}
                             tip={"צבע רקע"}
                             placeholder={
                                 <FieldColor name="backgroundColor"
                                             value={backgroundColor}
                                             dir="horizontal"
                                             {...fieldCommonProps}/>}/>

                </Col>
                <Col xs={4}>
                    <ToolTip id={`dynamic-background-color-hover-${id}`}
                             tip="רקע במעבר עכבר"
                             placeholder={
                                 <FieldColor name="backgroundColorHover"
                                             value={backgroundColorHover}
                                             dir="horizontal"
                                             {...fieldCommonProps}/>
                             }/>
                </Col>
                <Col xs={4}>
                    <ToolTip id={`dynamic-background-color-active-${id}`}
                             tip="רקע פעיל"
                             placeholder={
                                 <FieldColor name="backgroundColorActive"
                                             value={backgroundColorActive}
                                             dir="horizontal"
                                             {...fieldCommonProps}/>}/>

                </Col>
            </Row>

            <Row align={"end"}>
                <Col xs={4}>
                    <Label style={labelStyle}>טקסט</Label>
                    <ToolTip id={`dynamic-color-${id}`}
                             tip={"צבע טקסט"}
                             placeholder={
                                 <FieldColor name="color"
                                             value={color}
                                             dir="horizontal"
                                             {...fieldCommonProps}/>}/>
                </Col>
                <Col xs={4}>
                    <ToolTip id={`dynamic-color-hover-${id}`}
                             tip="טקסט במעבר עכבר"
                             placeholder={
                                 <FieldColor name="colorHover"
                                             value={colorHover}
                                             dir="horizontal"
                                             {...fieldCommonProps}/>
                             }/>
                </Col>
                <Col xs={4}>
                    <ToolTip id={`dynamic-color-active-${id}`}
                             tip="טקסט פעיל"
                             placeholder={
                                 <FieldColor name="colorActive"
                                             value={colorActive}
                                             dir="horizontal"
                                             {...fieldCommonProps}/>}/>
                </Col>
            </Row>

            <Row align={"end"}>
                <Col xs={4}>
                    <Label style={labelStyle}>מסגרת</Label>

                    <ToolTip id={`dynamic-border-color-${id}`}
                             tip={"צבע מסגרת"}
                             placeholder={
                                 <FieldColor name="borderColor"
                                             value={borderColor}
                                             dir="horizontal"
                                             {...fieldCommonProps}/>}/>

                </Col>
                <Col xs={4}>
                    <ToolTip id={`dynamic-border-color-hover-${id}`}
                             tip="מסגרת במעבר עכבר"
                             placeholder={
                                 <FieldColor name="borderColorHover"
                                             value={borderColorHover}
                                             dir="horizontal"
                                             {...fieldCommonProps}/>}/>
                </Col>
                <Col xs={4}>
                    <ToolTip id={`dynamic-border-color-active-${id}`}
                             tip="מסגרת פעיל"
                             placeholder={
                                 <FieldColor name="borderColorActive"
                                             value={borderColorActive}
                                             dir="horizontal"
                                             {...fieldCommonProps}/>}/>
                </Col>
            </Row>
        </>
    )
}