import React, {useCallback, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {TriviaSection} from "./create-tool-trivia-section";
import {CreateAdventureContext} from "../../../pages/adventure-create/screen-adventure-create";
import "../../../../../assets/styles/create-tool-trivia.scss";

const checkIsValid = (toolData) => {
    const items = toolData.items;
    let expectedLength = 0;
    for (let i = 0; i < items.length; i++) {
        const questionLength = 1;
        expectedLength += (questionLength + items[i].options.length)
    }
    const invalidItems = [].concat.apply([], items.map(item => item.options));
    const invalidOptions = invalidItems.filter(option => option === /^\s+$/.test(option) || option === '').length;
    const invalidQuestions = items.map(item => item.question).filter(question => !question).length;
    const invalidLength = invalidOptions + invalidQuestions;
    const validLength = expectedLength - invalidLength;

    if (!invalidLength) {
        return 100
    } else {
        return validLength / expectedLength * 100;
    }
};

const CreateToolTrivia = (
    {
        toolData,
        invalidFields,
        warningActive,
        handleToolDataChange
    }) => {
    const adventureContext = useContext(CreateAdventureContext);
    const {incompleteActive} = adventureContext; // adventureName,
    const [activeSection, setActiveSection] = useState(0);

    const switchToPreviousQuestion = useCallback(() => {
        if (activeSection > 0) {
            setActiveSection(activeSection - 1)
        }
    }, [activeSection]);

    const switchToNextQuestion = useCallback(() => {
        if (activeSection + 1 < toolData.items.length) {
            setActiveSection(activeSection + 1)
        }
    }, [activeSection, toolData.items]);

    const tabHandler = (e, index) => {
        if (parseInt(index) === 3) {
            if (e.keyCode === 9) {
                e.preventDefault();
                if (activeSection < toolData.items.length - 1) {
                    setActiveSection(activeSection + 1)
                }
            }
        }
    };


    return (
        <div className={'create-trivia'}>
            {toolData.items.map((item, index) => {
                const handleChange = (name, value, index, optionIndex) => handleToolDataChange(checkIsValid, name, value, index, optionIndex);
                return <TriviaSection {...item}
                                      key={index}
                                      index={index}
                                      isActive={activeSection === index}
                                      handleToolDataChange={handleChange}
                                      isFirstSection={activeSection > 0}
                                      isLastSection={activeSection < toolData.items.length - 1}
                                      tabHandler={tabHandler}
                                      switchToPreviousQuestion={switchToPreviousQuestion}
                                      switchToNextQuestion={switchToNextQuestion}
                                      incompleteActive={incompleteActive}
                                      invalidFields={invalidFields}
                                      warningActive={warningActive}/>
            })}
        </div>
    )
};


CreateToolTrivia.defaultProps = {
    handleToolDataChange: (toolData) => console.log('toolData', toolData),
    toolData: {items: []}
};

CreateToolTrivia.propTypes = {
    toolData: PropTypes.object,
    warningActive: PropTypes.bool,
    handleToolDataChange: PropTypes.func
};


export default CreateToolTrivia;