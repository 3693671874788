import React from 'react';
import PropTypes from "prop-types";

const Image = ({
    className,
    src,
    alt,
    width,
    height,
    maxWidth,
    block,
    style
}) => {
    return (
        <img src={src}
            alt={alt}
            className={`image ${className}`}
            width={width}
            height={height}
            style={{ ...style, display: block && 'block', maxWidth: maxWidth }} />
    )
};

Image.defaultProps = {
    className: '',
    maxWidth: '100%',
    alt: ''
};

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    block: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string,
};

export default Image; 