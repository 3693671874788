import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-grid-system";
import { LanguageContext, Translation } from "../../../../languages/";
import { DICT } from "../../../../languages/dictionary-names";
import { ThemeContext } from "../../../../providers/theme-provider";
import { AuthContext } from "../../../../providers/auth-provider";
import { selectAllAdventureTypes } from "../../../../store/selectors";
import { colors } from "../../../00_base/variables";
import Card from "../../../02_molecules/card/m-card";
import { AdventureTypeCard } from "../../../03_organisms";
import "../../../../assets/styles/adventure-thumbnail.scss";

const KODAN_ICON =
  "https://storage.googleapis.com/assets.youdoadventures.com/website/kodan_icon.svg";
const DRAWING_PERSONAL_BOX =
  "https://storage.googleapis.com/assets.youdoadventures.com/website/Drawing_PersonalBox.png";

const Gallery = ({ items }) => {
  return (
    <Row className={"justify-content-center"}>
      {items.map((item, i) => {
        return (
          <Col
            xs={12}
            md={4}
            className="main-adventure-thumbnail px-3 px-lg-5"
            key={i}
          >
            <AdventureTypeCard {...item} />
          </Col>
        );
      })}
    </Row>
  );
};

export const AdventuresBlock = ({
  items,
  title,
  titleStyle,
  imageWidth = "20rem",
  description,
  dangerouslyDescription,
  image,
  direction,
  cta,
  ctaUrl,
  hideDetails,
}) => {
  const { mode } = useContext(ThemeContext);
  const textColor = colors[mode === "light" ? "black" : "white"];
  return (
    <Row className={`justify-content-center py-${hideDetails ? 0 : 6}`}>
      {items.length > 0 && (
        <>
          {!hideDetails && (
            <Col xs={12} lg={10} xl={10} className="mb-4">
              <Card
                direction={direction}
                title={title}
                titleStyle={titleStyle}
                dangerouslyDescription={dangerouslyDescription}
                description={description}
                descriptionStyle={{ color: textColor }}
                image={image}
                imageWidth={imageWidth}
                cta={cta}
                ctaUrl={ctaUrl}
              />
            </Col>
          )}
          <Col lg={12} className={`mt-${hideDetails ? 0 : 4}`}>
            <Gallery items={items} />
          </Col>
        </>
      )}
    </Row>
  );
};

export const CustomizeAdventures = ({ hideDetails, imageWidth }) => {
  const adventureTypes = useSelector(selectAllAdventureTypes);
  const { userLanguage, dictionary } = useContext(LanguageContext);
  const isEnglish = userLanguage === "en";
  const heAdventures = [299, 302, 303];
  const enAdventures = [356, 357, 358];
  const ids = isEnglish ? enAdventures : heAdventures;

  return (
    <AdventuresBlock
      title={<Translation id={DICT.HOMEPAGE_CUSTOMIZE_ADVENTURES_TITLE} />}
      dangerouslyDescription={(
        dictionary.homepageCustomizeAdventuresDescription || ""
      ).replaceAll("\n", "<br/>")}
      items={adventureTypes.filter((item) => ids.some((id) => id === item.id))}
      image={DRAWING_PERSONAL_BOX}
      imageWidth={imageWidth}
      hideDetails={hideDetails}
      ctaUrl={`/${userLanguage}/customize-adventures`}
      cta={`${isEnglish ? "view all" : "לכל הקופסאות בהתאמה אישית"} >>`}
      direction="row-reverse"
    />
  );
};

export const DigitalAdventures = ({
  image = KODAN_ICON,
  imageWidth,
  hideDetails,
}) => {
  const adventureTypes = useSelector(selectAllAdventureTypes);
  const { userLanguage } = useContext(LanguageContext);
  const isEnglish = userLanguage === "en";
  const heAdventures = [31, 40, 324];
  const enAdventures = [359, 263, 332];
  const ids = isEnglish ? enAdventures : heAdventures;

  return (
    <AdventuresBlock
      title={<Translation id={DICT.HOMEPAGE_DIGITAL_ADVENTURES_TITLE} />}
      description={
        <Translation id={DICT.HOMEPAGE_DIGITAL_ADVENTURES_DESCRIPTION} />
      }
      items={adventureTypes.filter((item) => ids.some((id) => id === item.id))}
      image={image}
      imageWidth={imageWidth}
      hideDetails={hideDetails}
      ctaUrl={`/${userLanguage}/digital-adventures`}
      direction="row"
    />
  );
};

export const BlockAdventures = ({ preview }) => {
  const { userLanguage } = useContext(LanguageContext);
  const { isAdmin } = useContext(AuthContext);

  const isHebrew = userLanguage === "he";
  return (
    <div className="d-flex flex-column align-items-center" id="self-adventures">
      {(isHebrew || isAdmin) && <CustomizeAdventures />}
      <DigitalAdventures />
    </div>
  );
};
