import React, { createContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useScrollPosition } from "../../../hooks/scroll-hook";
import classNames from "classnames";

export const ScrollContext = createContext({
  isScrolled: null,
  y: 0,
});

const ScrollToTop = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [scrolling, setScrolling] = useState(false);
  const [y, setY] = useState(0);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const targetScrollValue = location.pathname.includes("create-adventure")
        ? -130
        : -50;
      setY(currPos.y);
      setScrolling(currPos.y < targetScrollValue);
    },
    [scrolling, location.pathname]
  );

  useEffect(() => {
    const listen = () => {
      if (!location.pathname.includes("create-adventure")) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
    };
    listen();
  }, [navigate, location.pathname]);

  return (
    <ScrollContext.Provider value={{ isScrolled: scrolling, y: y }}>
      <div className={classNames({ scrolling })}>{children}</div>
    </ScrollContext.Provider>
  );
};

export default ScrollToTop;
