export const API_URL = 'https://api.youdoadventures.com'; //process.env.REACT_APP_BE_SERVER || API STG

const token = localStorage.getItem('token');
const GET = "GET";

export const fetchData = async (
    method = GET,
    extension,
    data = {},
    successHandler = success => console.log(success),
    errorHandler = error => console.log(error),
) => {

    const url = `${API_URL}/${extension}`;
    const HEADERS = {
        "Content-Type": "application/json; charset=utf-8",
        "access-Token": token
    };
    const BODY = JSON.stringify(data);
    const METHOD = method.toUpperCase();
    const URL = url;

    const fetchData = (
        METHOD === GET ?
            {method: METHOD, headers: HEADERS} :
            {method: METHOD, body: BODY, headers: HEADERS}
    );

    await fetch(
        URL,
        fetchData
    ).then(
        response => {
            if (!response.ok) {
                throw new Error('Something went wrong');
            }
            return response.json()
        }
    ).then(
        success => {
            successHandler(success)
            return success
        }
    ).catch(
        error => errorHandler(error)
    );
};