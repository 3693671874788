import React from "react";
import LoadingBox from "./loading-box";
import LoadingTitle from "./loading-title";
import LoadingDescription from "./loading-description";

export const LoadingSection = () => {
    return (
        <>
            <LoadingBox/>
            <br/>
            <LoadingTitle/>
            <LoadingDescription height="3rem"/>
        </>
    )
}