import React from 'react';

const TailLoader = () => {
    return <div>
        <svg width="110px" height="110px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <defs>
                <filter id="ldio-krokte686v-filter" x="-100%" y="-100%" width="300%" height="300%" colorInterpolationFilters="sRGB">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="3"></feGaussianBlur>
                    <feComponentTransfer result="cutoff">
                        <feFuncA type="linear" slope="60" intercept="-40"></feFuncA>
                    </feComponentTransfer>
                </filter>
            </defs>
            <g filter="url(#ldio-krokte686v-filter)" transform="rotate(15.6373 50 50)">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="3.0303030303030303s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                <g transform="rotate(3.81193 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="0" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.7666666666666666 0 0.6666666666666666 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(4.18983 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="1" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.7333333333333333 0 0.6333333333333333 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(4.62687 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="2" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.7 0 0.6 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(5.13611 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="3" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.6666666666666666 0 0.5666666666666667 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(5.73446 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="4" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.6333333333333333 0 0.5333333333333333 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(5.94116 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="5" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.6 0 0.5 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(6.86674 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="6" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.5666666666666667 0 0.4666666666666667 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(7.98403 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="7" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.5333333333333333 0 0.43333333333333335 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(9.34642 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="8" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.5 0 0.4 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(11.0267 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="9" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.4666666666666667 0 0.36666666666666664 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(13.1262 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="10" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.43333333333333335 0 0.3333333333333333 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(16.7462 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="11" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.4 0 0.3 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(19.2252 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="12" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.3666666666666667 0 0.26666666666666666 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(23.75 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="13" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.33333333333333337 0 0.23333333333333334 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(29.853 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="14" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.30000000000000004 0 0.2 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(38.3249 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="15" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.26666666666666666 0 0.16666666666666666 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(50.5037 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="16" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.23333333333333334 0 0.13333333333333333 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(68.7807 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="17" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.2 0 0.1 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(97.7053 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="18" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.16666666666666669 0 0.06666666666666667 1" repeatCount="indefinite"></animateTransform>
                </g><g transform="rotate(127.169 50 50)">
                    <g transform="translate(50 20)">
                        <circle cx="0" cy="0" r="19" fill="#fe718d" transform="scale(0.5)"></circle>
                    </g>
                    <animateTransform attributeName="transform" calcMode="spline" type="rotate" values="0 50 50;360 50 50" keyTimes="0;1" dur="1.0101010101010102" keySplines="0.13333333333333333 0 0.03333333333333333 1" repeatCount="indefinite"></animateTransform>
                </g>
            </g>
        </svg>
    </div>
}


export default TailLoader;