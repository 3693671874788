import React from "react";
import {DICT} from "../../../../../languages/dictionary-names";
import {Translation} from "../../../../../languages";
import {FilesUploader} from "../../../../02_molecules";

export const CreateToolGalleryImages = (
    {toolData, handleToolDataChange}
) => {
    const items = toolData.items.map(item => item.items.filter(galleryItem => galleryItem.editable)).flat();
    const checkIsValid = (toolData) => {
        const items = toolData.items.map(item => item.items.filter(galleryItem => galleryItem.editable)).flat();
        const exceptedLength = items.length;
        const invalidItems = items.filter(item => !item.image);
        const invalidLength = invalidItems.length;
        return 100 - invalidLength / exceptedLength * 100
    };

    const changeHandler = (name, value) => {
        const updatedItems = toolData.items;
        for (let i = 0; i < updatedItems.length; i++) {
            const section = updatedItems[i];
            section.items = section.items.map(item => {
                return {...item, image: item.editable ? "" : item.image}
            })
        }

        for (let i = 0; i < value.length; i++) {
            const findItem = item => item.editable && !item.image;
            const sectionToUpdate = updatedItems.find(section => section.items.find(findItem));
            const itemToUpdate = sectionToUpdate?.items.find(findItem);
            if (itemToUpdate) {
                itemToUpdate.image = value[i];
            }
        }

        handleToolDataChange(checkIsValid, "items", updatedItems)
    };

    return (
        <div className="create-tool-gallery-images" style={{marginTop: "10px"}}>
            <FilesUploader name="images"
                           value={items.filter(item => item.image).map(item => item.image)}
                           initialValue={items.map(item => item.image)}
                           triggerValue={<Translation id={DICT.UPLOAD_IMAGES}/>}
                           maxItems={items.length}
                           minItems={items.length}
                           changeHandler={changeHandler}/>
        </div>
    )
};