import {colors, font_size, gutters, zIndex} from "../../../00_base/variables";

export const wrapper = {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    width: '100%',
    height: '100vh',
    background: '#136a8a',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    zIndex: zIndex.max,
    top: 0,
    left: 0,
};

export const arrowWrapper = {
    borderRadius: '10px',
    position: 'absolute',
    top: '10px',
    left: '10px',
    backgroundColor: colors.secondary
};

export const continueButton = {
    backgroundColor: colors.white,
    color: colors.black,
    fontSize: font_size.md
};

export const clueWrapper = {
    padding: gutters.sm,
    textAlign: 'center'
};
export const clueTriggerWrapper = {
    padding: gutters.sm,
    textAlign: 'center'
};
export const clueButton = {
    backgroundColor: colors.white,
    color: colors.black,
    fontSize: font_size.md
};

export const clueText = (size) => {
    return {
        color: colors.white,
        fontSize: font_size[size]
    }
};