import React from 'react';
import '../../../assets/styles/fireworks.css';

const Fireworks = ({ style }) => {
    return (
        <div className={"pyro"} style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow:"hidden",
            ...style
        }}>
            <div className={"before"}></div>
            <div className={"after"}></div>
        </div>
    )
}

export default Fireworks;