import React, {useState, useContext} from "react";
import {useSelector} from "react-redux";
import {font_size} from "../../00_base/variables";
import {LanguageContext} from "../../../languages";
import {ThemeContext} from "../../../providers/theme-provider";
import {ToggleIcon} from "../index";
import {selectAllTags} from "../../../store/selectors";

const WHITE = "white", BLACK = "black", DARK = "dark", COLOR = "color", BG = "bg";

export const ChooseTag = ({tags, title, showToggleIcon, changeHandler}) => {
    const allTags = useSelector(selectAllTags);
    const {mode} = useContext(ThemeContext);
    const [activeTags, setActiveTags] = useState(tags);
    const [isOpen, setIsOpen] = useState(true);

    const clickHandler = (tagTitle) => {
        const isExist = activeTags.some(tag => tag === tagTitle);
        const updatedTags = isExist ? activeTags.filter(item => item !== tagTitle) : activeTags.concat([tagTitle]);
        setActiveTags(updatedTags);
        changeHandler(updatedTags);
    };

    const renderTagItem = tag => {
        const isActive = activeTags.some(item => item === tag.title);
        const bgColor = isActive ? BLACK : WHITE;
        const textColor = isActive ? WHITE : BLACK;
        const borderColor = mode === DARK ? WHITE : BLACK;
        const style = {...tagItemStyle, border: `2px solid ${borderColor}`};
        const className = `${BG}-${bgColor} ${COLOR}-${textColor}`;

        return <div key={tag.id} className="mb-3 ml-3">
            <div style={style} className={className} onClick={() => clickHandler(tag.title)}>
                {tag.title}
            </div>
        </div>
    };

    const toggleTagsList = () => setIsOpen(!isOpen)
    return (
        <div>
            {!!allTags.length && title &&
            <div className="d-flex justify-content-between align-items-center mb-3"
                 onClick={toggleTagsList}
                 style={{width: "100%", borderBottom: `2px solid ${mode === "dark" ? "#fff" : "#000"}`}}>
                <h6 className="color-black m-0">{title}</h6>
                {showToggleIcon && <ToggleIcon toggle={toggleTagsList} isOpen={isOpen}/>}
            </div>}
            {isOpen &&
            <div className="d-flex flex-wrap">
                {allTags.map(renderTagItem)}
            </div>
            }
        </div>
    )
};

const tagItemStyle = {
    border: "2px solid #000",
    fontSize: font_size.xs,
    borderRadius: "6px",
    padding: "0 10px",
    cursor: "pointer"
};