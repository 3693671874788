import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Span from "../text/a-span";
import { colors } from "../../00_base/variables";

const Anchor = ({ href, value, children, style, route, target }) => {
  const linkValue = children || value;
  if (route) {
    return (
      <Link to={route} style={style}>
        {linkValue}
      </Link>
    );
  }
  if (href) {
    return (
      <a href={href} target={target} style={{ ...defaultAnchorStyle, style }}>
        {linkValue}
      </a>
    );
  }
  return <Span style={style}>{linkValue}</Span>;
};

const defaultAnchorStyle = {
  color: colors.info,
  textDecoration: "none",
};

Anchor.defaultProps = {
  // label: PropTypes.string,
  style: defaultAnchorStyle,
  route: false,
};

Anchor.propTypes = {
  href: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.symbol,
    PropTypes.element,
    PropTypes.number,
    PropTypes.func,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.symbol,
    PropTypes.element,
    PropTypes.number,
    PropTypes.func,
  ]),
  style: PropTypes.object,
  route: PropTypes.bool,
};

export default Anchor;
