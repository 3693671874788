import React from 'react';

class IconAngleRight extends React.Component {
    render() {
        const {color, width, height, onClick, style, borderWidth} = this.props;
        return (
            <svg width={width} height={height} viewBox="0 0 19 32" version="1.1" style={{...style && style, transform: 'rotate(180deg)'}} onClick={onClick && onClick}>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                    <g transform="translate(-148.000000, -2108.000000)" fillRule="nonzero" stroke={color} strokeWidth={borderWidth}>
                        <path d="M150.503704,2124.16296 L165.666667,2138.66667 L150.503704,2124.16296 L165.666667,2109 L150.503704,2124.16296 Z M150.503704,2124.16296 L149.5,2125.16667 L150.503704,2124.16296 L150.333333,2124 L150.503704,2124.16296 Z" id="Combined-Shape"/>
                    </g>
                </g>
            </svg>
        )
    }

    static defaultProps = {
        color: '#151515',
        width: '19px',
        height: "32px", 
        borderWidth: '3px'
    }
}

export default IconAngleRight
