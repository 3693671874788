// import Too from "../../_models/project-model";
import Theme from "../models/theme";
import {API_URL} from "./fetch-data";

const api = API_URL;
const extension = 'themes';
const url = `${api}/${extension}`;

const token = localStorage.getItem("token");

export const SET_THEMES = 'SET_THEMES';
export const SET_SINGLE_THEME = 'SET_SINGLE_THEME';
export const CREATE_THEME = 'CREATE_THEME';
export const UPDATE_THEME = 'UPDATE_THEME';
export const DELETE_THEME = 'DELETE_THEME';

export const getAllThemes = props => {
    return async(dispatch, getState) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Something went wrong');
            }
            const riddlesData = await response.json();

            let allThemes = [];
            for (const key in riddlesData) {
                const themeData = riddlesData[key];
                allThemes.push(
                    new Theme(
                        themeData.id,
                        themeData.key,
                        themeData.main,
                        themeData.sub,
                        themeData.text,
                        themeData.buttons,
                        themeData.fifth,
                    )
                )
            }

            dispatch({
                type: SET_THEMES,
                allThemes: allThemes
            });

            return allThemes
        } catch (error) {
            throw error
        }
    }
};

export const getSingleTheme = themeId => {
    return async(dispatch, getState) => {
        try {
            const response = await fetch(`${url}/${themeId}`);
            if (!response.ok) {
                throw new Error('Something went wrong');
            }
            const themeData = await response.json();
            return new Theme(
                themeData.id,
                themeData.key,
                themeData.main,
                themeData.sub,
                themeData.text,
                themeData.buttons,
                themeData.fifth,
            )
        } catch (error) {
            throw error
        }
    }
};

export const createTheme = (themeData, callback = success => console.log(success)) => {
    return async(dispatch, getState) => {
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(themeData),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "access-Token": token
            }
        }).then(response => response.ok ? response.json() : alert('error')).then(
            success => {
                dispatch({
                    type: CREATE_THEME,
                    id: success.data.id,
                    key: success.data.key,
                    main: success.data.main,
                    sub: success.data.sub,
                    text: success.data.text,
                    buttons: success.data.buttons,
                    fifth: success.data.fifth,
                });
                callback(success.data);
            }
        )
    }
};

export const updateTheme = (themeData, callback = success => console.log(success)) => {
    return async(dispatch, getState) => {
        fetch(`${url}/${themeData.id}`, {
            method: 'PUT',
            body: JSON.stringify({...themeData }),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "access-Token": token
            }
        }).then(response => response.ok ? response.json() : alert('error')).then(
            success => {
                dispatch({
                    type: UPDATE_THEME,
                    ...success
                });
                callback(success.data);
            }
        );
    };
};

export const deleteTheme = riddleId => {
    return async(dispatch, getState) => {
        fetch(`${url}/${riddleId}`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "access-Token": token
            }
        }).then(
            response => response.json()
        ).then(
            success => {
                dispatch({ type: DELETE_THEME, id: riddleId })
                return success
            }
        )
    }
};