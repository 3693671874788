export const phoneNumbers = [
    '058-0000315',
    '054-0000638',
    '053-0000927',
    '052-0000738',
    '057-0000299',
    '057-0000299',
    '054-0000375',
    '058-4237720',
    '054-5702712',
    '052-7926443',
    '052-8286610',
    '054-6691535',
    '056-9460455',
    '058-7160372',
    '053-9677979',
    '055-1931173',
    '057-1024042',
    '052-3451015',
    '055-6826558',
    '056-9302033',
    '053-4632202',
    '054-8982390',
    '054-2492684',
    '050-6155581',
    '050-6453499',
    '052-9302033',
    '054-7516394',
    '053-6966920',
    '052-3215160',
    '052-5137860',
    '050-9302033',
    '055-8562719',
    '058-4237720',
    '054-5702712',
    '052-7926443',
    '052-8286610',
    '054-6691535',
    '056-9460455',
    '058-7160372',
    '053-9677979',
    '055-1931173',
    '057-1024042',
    '052-3451015',
    '055-6826558',
    '056-9302033',
    '053-4632202',
    '054-8982390',
    '054-2492684',
    '050-6155581',
    '050-6453499',
    '052-9302033',
    '054-7516394',
    '053-6966920',
    '052-3215160',
    '052-5137860',
    '050-9302033',
    '055-8562719',
    '058-4237720',
    '054-5702712',
    '052-7926443',
    '052-8286610',
    '054-6691535',
    '056-9460455',
    '058-7160372',
    '053-9677979',
    '055-1931173',
    '057-1024042',
    '052-3451015',
    '055-6826558',
    '056-9302033',
    '053-4632202',
    '054-8982390',
    '054-2492684',
    '050-6155581',
    '050-6453499',
    '052-9302033',
    '054-7516394',
    '053-6966920',
    '052-3215160',
    '052-5137860',
    '050-9302033',
    '055-8562719',
    '058-4237720',
    '054-5702712',
    '052-7926443',
    '052-8286610',
    '054-6691535',
    '056-9460455',
    '058-7160372',
    '053-9677979',
    '055-1931173',
    '057-1024042',
    '052-3451015',
    '055-6826558',
    '056-9302033',
    '053-4632202',
    '054-8982390',
    '054-2492684',
    '050-6155581',
    '050-6453499'
];

export const letters = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט', 'י', 'כ', 'ל', 'מ', 'נ', 'ס', 'ע', 'פ', 'צ', 'ק', 'ר', 'ש', 'ת'];