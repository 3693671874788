import React from "react";
import {FieldColor, FieldText} from "../../../02_molecules";
import {ColorPickerBgText} from "../../../03_organisms";
import {gutters} from "../../../00_base/variables";

export const TruthOrLieStyle = (
    {
        handleToolDataChange,
        colorTruth,
        colorLie,
        colorSentence,
        bgSentence,
        truthLabel,
        lieLabel,
        sentenceSize = 25
    }) => {
    const handleSentenceColorChange = (name, value) => {
        if (name === "textColor") {
            handleToolDataChange("colorSentence", value);
        }
        if (name === "bgColor") {
            handleToolDataChange("bgSentence", value);
        }
    };

    return (
        <div className={"pt-5"}>
            <h5 className={"my-0"}>טקסט אמת ושקר</h5>
            <div className={"d-flex"}>
                <FieldText changeHandler={handleToolDataChange}
                           placeholder={"נכון"}
                           value={truthLabel}
                           name={"truthLabel"}/>
                <FieldText changeHandler={handleToolDataChange}
                           placeholder={"לא נכון"}
                           value={lieLabel}
                           name={"lieLabel"}/>
            </div>

            <h5 className={"my-0"}>צבעים כלליים</h5>
            <div className={"d-flex align-items-center"}>
                <ColorPickerBgText label="משפט"
                                   textColor={colorSentence}
                                   bgColor={bgSentence}
                                   handleChange={handleSentenceColorChange}/>
                <FieldText changeHandler={handleToolDataChange}
                           controlStyle={{marginRight: gutters.sm}}
                           inputStyle={{height: "23px", width: "90px"}}
                           type={"number"}
                           placeholder={20}
                           name={"sentenceSize"}
                           value={sentenceSize}/>

                <FieldColor name="colorTruth"
                            label="אמת"
                            changeHandler={handleToolDataChange}
                            value={colorTruth}/>

                <FieldColor name="colorLie"
                            label="שקר"
                            changeHandler={handleToolDataChange}
                            value={colorLie}/>
            </div>
        </div>
    )
};