import React from "react";
import CreateToolTranslator from "../../tools/translator";
import CreateToolTifzoret from "../../tools/tifzoret";
import CreateContactList from "../../tools/contact-list";
import CreateToolDefiner from "../../tools/definer";
import CreateToolTrivia from "../../tools/trivia";
import CreateToolGallery from "../../tools/gallery";
import CreateToolVideo from "../../tools/video/create-tool-video";
import CreateToolTreasure from "../../tools/treasure/create-tool-treasure";
import CreateToolTruthOrLie from "../../tools/truth-or-lie";
import CreateToolPuzzle from "../../tools/puzzle";
import CreateToolSwitches from "../../tools/switches";
import CreateToolCoder from "../../tools/coder";
import CreateToolSlider from "../../tools/slider";
import CreateToolContent from "../../tools/content";
import CreateToolCuttingWires from "../../tools/cutting-wires";
import CreateToolAudio from "../../tools/audio";
import CreateToolAudioList from "../../tools/audio-list";
import CreateToolRotaries from "../../tools/rotaries";
import CreateToolChooseItem from "../../tools/choose-item/";
import CreateToolGalleryImages from "../../tools/gallery-images";
import {CreateToolPeekLock} from "../../tools/peek-lock/peek-lock-create";
import {CreateToolGoogleMaps} from "../../tools/google-maps/create-tool-google-maps";

export const getToolComponent = toolId => {
    switch (toolId) {
        case 15:
            return CreateToolTranslator;
        case 46:
            return CreateToolTifzoret;
        case 47:
            return CreateContactList;
        case 48:
            return CreateToolDefiner;
        case 49:
            return CreateToolTrivia;
        case 56:
            return CreateToolGallery;
        case 57:
            return CreateToolVideo;
        case 60:
            return CreateToolTreasure;
        case 66:
            return CreateToolTruthOrLie;
        case 38:
            return CreateToolPuzzle;
        case 61:
            return CreateToolSwitches;
        case 62:
            return CreateToolContent;
        case 63:
            return CreateToolCoder;
        case 64:
            return CreateToolRotaries;
        case 65:
            return CreateToolSlider;
        case 73:
            return CreateToolCuttingWires;
        case 75:
            return CreateToolAudio;
        case 76:
            return CreateToolAudioList;
        case 77:
            return CreateToolGoogleMaps;
        case 78:
            return CreateToolChooseItem;
        case 79:
            return CreateToolTruthOrLie;
        case 80:
            return CreateToolGalleryImages;
        case 81:
            return CreateToolPeekLock;

            default:
            return () => <div style={{minHeight:"400px"}}>No tool, please contact Sagiv or Tom</div>
    }
};