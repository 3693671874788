import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Element, HBox } from "react-stylesheet";
import styled from "styled-components";
import { Container } from "react-grid-system";
import { useNavigate } from "react-router-dom";
import * as tagsActions from "../../../../store/actions/tags-actions";
import { colors, gutters, zIndex } from "../../../00_base/variables";
import {
  Button,
  Heading,
  LoaderPageContent,
  TailLoader,
} from "../../../01_atoms";
import { Card } from "../../../02_molecules";
import { Grid } from "../../../03_organisms";
import { Page } from "../../../04_layout";
import {
  Table,
  TableContainer,
  tableHeader,
  TableSearchBar,
  TableTitle,
  TableWrapper,
  Tr,
} from "../../../03_organisms";
import { NavigateItems } from "../../../03_organisms/navigation-groups/navigate-items";
import { createRiddle } from "../../../../store/actions/riddle-actions";
import { LanguageContext } from "../../../../languages";
import { TagsItem } from "./tags-item";
import SVG_API from "../../../../assets/images/svg-icons/svg-api.svg";
import SVG_CLOSE from "../../../../assets/images/svg-icons/svg-close.svg";
import { fetchData } from "../../../../store/actions/fetch-data";
import { selectAllTags } from "../../../../store/selectors";

const THUMBNAIL =
  "https://storage.googleapis.com/assets.youdoadventures.com/website/default_thumbnail.jpg";

const ScreenTagsGallery = (props) => {
  const navigate = useNavigate();
  const allTags = useSelector(selectAllTags);
  const { userLanguage } = useContext(LanguageContext);
  const [innerMenuActive, setInnerMenuActive] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingBeforeDelete, setLoadingBeforeDelete] = useState(null);
  const [error, setError] = useState(null);
  const [itemsLength, setItemsLength] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  const dispatch = useDispatch();

  // useEffect(() => {
  //     try {
  //         dispatch(tagsActions.setAllTags(pageIndex)).then(response => {
  //             setIsLoading(false);
  //         });
  //     } catch (err) {
  //         setError(err.message);
  //         setIsLoading(null);
  //     }
  // }, [dispatch, pageIndex]);

  useEffect(() => {
    fetchData("get", "tags/count", "", (response) => {
      if (response && response.count) {
        setItemsLength(parseInt(response.count) - 1);
      }
    });
  }, []);

  const deleteTagHandler = (tagId) => {
    dispatch(tagsActions.deleteTag(tagId)).then((response) => {
      setLoadingBeforeDelete(null);
    });
  };
  const open_api = (id) =>
    window.open(`https://api.youdoadventures.com/tags/${id}`, "_blank");

  const createNewTag = () => {
    dispatch(
      createRiddle(
        { title: "חידה חדשה", show_in_store: true },
        "",
        (riddleData) =>
          navigate(`/${userLanguage}/admin/riddle/${riddleData.id}`)
      )
    );
  };

  const tableHeaderItems = [
    "",
    "כותרת",
    "איקון",
    "תיאור",
    "תיאור מקוצר",
    "תאריך יצירה",
  ];
  const innerMenuItems = [
    {
      Icon: () => <img src={SVG_CLOSE} alt="remove" />,
      label: "מחיקה",
      onClick: deleteTagHandler,
    },
    {
      Icon: () => <img src={SVG_API} alt="api" />,
      label: "API",
      onClick: open_api,
    },
  ];

  const tableRow = (tag, index) => {
    return (
      <TagsItem
        item={tag}
        index={index}
        setInnerMenuActive={() =>
          setInnerMenuActive(innerMenuActive === index ? null : index)
        }
        closeInnerMenu={() => setInnerMenuActive(null)}
        innerMenuItems={innerMenuItems}
        menuActive={innerMenuActive === index}
        key={index}
      />
    );
    return (
      <Element margin={`50px 0`} position={"relative"}>
        {loadingBeforeDelete === tag.id && (
          <DeleteTagCover>
            <Heading tag={"h5"} color={colors.white}>
              למחוק את התגית?
            </Heading>
            <HBox>
              <Button
                onClick={() => deleteTagHandler(tag.id)}
                style={{ margin: `0 ${gutters.sm}` }}
              >
                כן
              </Button>
              <Button
                onClick={() => setLoadingBeforeDelete(null)}
                style={{ margin: `0 ${gutters.sm}` }}
              >
                לא
              </Button>
            </HBox>
          </DeleteTagCover>
        )}
        <Card
          cardType={2}
          bodyHeight={"200px"}
          {...tag}
          description={tag.description_short}
          image={tag.thumbnail ? tag.thumbnail : THUMBNAIL}
          actions={[
            { label: "edit", href: `/tag/${tag.id}` },
            {
              label: isLoading === tag.id ? "..." : "delete",
              onClick: () => setLoadingBeforeDelete(tag.id),
            },
          ]}
        />
      </Element>
    );
  };
  if (error) {
    return (
      <Page {...props}>
        <HBox
          justifyContent={"center"}
          width={"100%"}
          paddingTop={gutters.xxxl}
        >
          {error}
        </HBox>
      </Page>
    );
  }

  if (!allTags) {
    return <LoaderPageContent />;
    return (
      <Page {...props}>
        <HBox
          justifyContent={"center"}
          width={"100%"}
          paddingTop={gutters.xxxl}
        >
          <TailLoader />
        </HBox>
      </Page>
    );
  }
  return (
    <TableWrapper>
      <TableTitle>
        <h1>תגיות</h1>
      </TableTitle>
      <TableContainer fluid>
        <TableSearchBar addLabel={"+ צור חידה"} handleAddClick={createNewTag} />

        <Table cellSpacing="0" cellPadding="0">
          <thead>
            <Tr>{tableHeaderItems.map(tableHeader)}</Tr>
          </thead>
          <tbody>{allTags.map(tableRow)}</tbody>
        </Table>

        {itemsLength > 15 && (
          <NavigateItems
            length={itemsLength}
            currentPageIndex={pageIndex}
            handleChange={(index) => setPageIndex(index)}
          />
        )}
      </TableContainer>
    </TableWrapper>
  );
  return (
    <Page {...props}>
      <Container>
        <Heading tag={"h1"} textAlign={"center"}>
          מאגר התגיות
        </Heading>
        <Grid
          cellSizes={{ xs: 6, sm: 6, lg: 4 }}
          items={allTags.map(tableRow)}
          gutterWidth={50}
        />
      </Container>
    </Page>
  );
};

const DeleteTagCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: ${zIndex.medium};
`;

export default ScreenTagsGallery;
