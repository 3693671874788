import React from "react";
import classNames from "classnames";
import "../../../../../../../assets/styles/lobby-circle.scss";
import {BackgroundImage} from "../../../01_atoms";

export const LobbyCircle = ({backgroundImage, pieItems, finalIcon, isFinish}) => {
    const sliceSize = 360 / pieItems.length;

    const renderSlice = (item, index) => {
        const rotate = index * sliceSize;
        const className = classNames('slice', {transparent: isFinish || item})
        return (
            <div key={index}>
                <div className={className}
                     style={{transform: `rotate(${rotate}deg) skewY(-60deg)`}}>
                    <div className='slice-contents' style={{transform: `skewY(60deg)`}}/>
                </div>
                <div className={className}
                     style={{transform: `rotate(${rotate + sliceSize / 2}deg) skewY(-60deg)`}}>
                    <div className='slice-contents' style={{transform: `skewY(60deg)`}}/>
                </div>
            </div>
        )
    };

    return (
        <>
            <BackgroundImage url={backgroundImage}
                             size={"contain"}
                             position={"center center"}
                             repeat={"no-repeat"}
                             className='lobby-circle'>
                {pieItems.map(renderSlice)}
            </BackgroundImage>
            {isFinish &&
            <div className={"lobby-circle-final"}>
                {finalIcon()}
            </div>
            }
        </>
    )
};