import React, {useContext, useEffect, useState} from 'react';
import {Element} from 'react-stylesheet';
import _ from "lodash";
import {Col, Row} from 'react-grid-system';
import PropTypes, {oneOfType} from "prop-types";
import {MdCheck} from "react-icons/md";
import classNames from "classnames";
import imageCompression from 'browser-image-compression';
import {API_URL} from "../../../../store/actions/fetch-data";
import FileActions from './file-actions';
import ImageCropperModal from './file-image-cropper';
import {fieldStyle} from "../field-style/m-field-style";
import {LanguageContext, Translation} from "../../../../languages";
import {DICT} from "../../../../languages/dictionary-names";
import {ThemeContext} from "../../../../providers/theme-provider";
import {colors, font_size} from '../../../00_base/variables';
import {Button, Circle, Heading, Image, Label, SimpleLoader, Span} from '../../../01_atoms';
import {VerticalScroller} from "../../../03_organisms";
import {RouteContext} from "../../../04_layout/app-content/l-app-content";
import "../../../../assets/styles/files-uploader.scss";

const PDF = "https://storage.googleapis.com/assets.youdoadventures.com/website/pdf_logo.jpg";

const FilesUploader = (
    {
        name,
        label,
        value = [],
        multiple = true,
        accept,
        minItems,
        maxItems,
        shrinkImage = false,
        labelStyle,
        changeHandler,
        controlStyle,
        triggerValue = "העלו תמונה",
        triggerBackground,
        triggerColor,
        justifyContent = "start",
        triggerStyle,
        previewWidth,
        optionalImages,
        errorMessage,
        errorMessageActive,
        warningStyle,
        videoField,
        backgroundSize,
        previewHeight,
        allowPdf,
        aspect
    }) => {
    const {rtl} = useContext(LanguageContext);
    const {admin} = useContext(RouteContext);
    const {mode} = useContext(ThemeContext);

    const [imagesSrc, setImagesSrc] = useState(value || []);
    const [cropperActive, setCropperActive] = useState(false);
    const [sizeErrorMessage, setSizeErrorMessage] = useState(false);
    const [loadingIndex, setLoadingIndex] = useState([]);
    const [uploadedIndex, setUploadedIndex] = useState([]);

    const darkMode = mode === "dark";

    useEffect(() => {
        if (!_.isEqual(value, imagesSrc)) {
            setImagesSrc(value || []);
        }
    }, [value]);

    const fixFileName = (fileName) => {
        const extension = /(?:\.([^.]+))?$/.exec(fileName)[1];
        return `${fileName.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.${extension}`;
    };

    useEffect(() => {
        if (sizeErrorMessage) {
            setTimeout(() => {
                setSizeErrorMessage(false);
            }, 5000)
        }
    }, [sizeErrorMessage]);

    const onChange = async (e) => {
        const files = e.target.files;
        let allFiles = [];
        let index = imagesSrc?.length || 0;

        const pushFile = (file) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                allFiles.push(reader.result);
                setImagesSrc(imagesSrc.concat(allFiles));
                loadingIndex.push(index);
                setLoadingIndex(loadingIndex);
                index++;
            });
            reader.readAsDataURL(file);
        };
        for await (let file of files) {
            if (file.size > 30000000) {
                setSizeErrorMessage(true);
            } else {
                if (file) {
                    pushFile(file)
                }

                if (file.name === files[files.length - 1].name) {
                    uploadToServer(files, allFiles)
                }
            }
        }
    };

    const singleFileHandler = (file, index) => {
        let allFiles = imagesSrc;
        if (file.size > 30000000) {
            setSizeErrorMessage(true);
        } else {
            const fileName = fixFileName(file.name);
            fetchImage(
                fileName,
                file,
                success => {
                    if (multiple) {
                        allFiles[index] = success;
                    }
                    setImagesSrc(multiple ? allFiles.map((item, i) => index === i ? success : item) : success);
                    setCropperActive(null);
                    changeHandler(name, multiple ? allFiles : success);
                }
            )
        }
    };

    const uploadToServer = (files) => {
        let filesArray = [];
        for (let i = 0; i < files.length; i++) {
            filesArray.push(uploadSingleFile(i, files[i]));
        }
        Promise.all(filesArray).then(result => {
            const updated = (multiple ? imagesSrc.concat(result).slice(0, maxItems) : typeof result === "string" ? result : result[0]);
            setImagesSrc(updated);
            if (aspect) {
                setCropperActive(1)
            }
            changeHandler(
                name,
                updated,
                result.length > 0
            );
        });
    };

    const uploadSingleFile = async (index, file) => {
        const options = {
            maxSizeMB: 2.2,
            maxWidthOrHeight: 1000,
            useWebWorker: true,
        };

        const compressedFile = (!videoField && shrinkImage && file.type !== "application/pdf") ? await imageCompression(file, options) : file;
        return new Promise((resolve, reject) => {
            try {
                let fileData = new FormData();
                const fileName = fixFileName(file.name);
                fileData.append('file', shrinkImage ? compressedFile : file, (fileName));
                fetchImage(
                    fileName,
                    file,
                    success => {
                        uploadedIndex.push(index + imagesSrc.length);
                        setUploadedIndex(uploadedIndex);
                        resolve(success);
                    });
            } catch (error) {
                console.log(error);
                reject(error)
            }
        })
    };

    const fetchImage = (fileName, file, handleSuccess) => {
        fetch(`${API_URL}/sign-url?fileName=${fileName}&type=${file.type}`)
            .then(
                response => response.json()
            ).then(
            success => {
                const {uploadUrl, fileUrl} = success;
                let reader = new FileReader();
                let b = reader.readAsArrayBuffer(file);
                console.log("b", b);
                reader.addEventListener(
                    'loadend',
                    () => {
                        fetch(uploadUrl, {
                            method: "PUT",
                            body: reader.result,
                            headers: {"Content-type": file.type}
                        }).then(
                            success => {
                                handleSuccess(fileUrl);
                            }
                        )
                    });
            }
        ).then(
            error => console.log('error', error)
        );
    };

    const deleteFileHandler = (index) => {
        const removedImage = multiple && imagesSrc.splice(index, 1);
        console.log("removedImage", removedImage);

        if (!multiple) {
            setImagesSrc("");
        }

        changeHandler(name, multiple ? imagesSrc : "", true);
    };

    const handleOptionalImageClick = (image) => {
        setImagesSrc(image);
        changeHandler(name, image, true);
    };

    // const previewMode = !(imagesSrc && imagesSrc.length > 0);

    const ImagePreview = ({src, index}) => {
        const isUploadedSuccessfully = uploadedIndex.some(item => item === index);
        const isLoading = !isUploadedSuccessfully && loadingIndex.some(item => item === index);
        const isPdf = src && src.includes(".pdf");

        return <Element className="image-preview-wrapper"
                        height={previewHeight}
                        width={previewWidth}
                        key={index}>
            {isLoading &&
            <Element className={`image-preview-loading`}
                     height={previewHeight}
                     width={previewWidth}>
                <Circle size={30} background={'rgba(255,255,255,.6)'}>
                    <SimpleLoader size='20' color={colors.select}/>
                </Circle>
            </Element>}

            {!videoField &&
            <div className={classNames("image-preview-image", {small: previewWidth < 140})}
                 style={{backgroundImage: `url(${isPdf ? PDF : src}`, backgroundSize: backgroundSize}}>
                {/*{isPdf && <iframe src={src} height={previewHeight} width={previewWidth}/>}*/}
                {!isLoading &&
                <FileActions fileIndex={index}
                             imageSrc={src}
                            deleteOnly={previewWidth < 100}
                             smallPreview={previewWidth < 140}
                             cropToggleHandler={setCropperActive}
                             deleteHandler={deleteFileHandler}/>}
            </div>}
        </Element>
    };

    const input = <input id={`input-file_${name}`}
                         type="file"
                         onChange={onChange}
                         max={maxItems}
                         accept={accept || 'image/*'}
                         disabled={!admin && maxItems <= imagesSrc.length}
                         multiple={multiple}
                         name={"image-name"}/>;

    const hasImages = imagesSrc?.length > 0;
    // const uploadMoreImages = minItems && imagesSrc.length < minItems;
    const enoughImages = minItems && imagesSrc.length >= minItems;
    return (
        <div className={"files-uploader"} style={controlStyle}>
            {cropperActive &&
            <ImageCropperModal src={multiple ? imagesSrc[cropperActive] : imagesSrc}
                               index={cropperActive}
                               setCropperActive={setCropperActive}
                               uploadSingleFile={singleFileHandler}/>
            }

            {label && !!imagesSrc && imagesSrc.length > 0 &&
            <Label textAlign={"center"} style={{...fieldStyle.label, color: darkMode ? "#fff" : "#000", ...labelStyle}}
                   value={label}/>}

            <div className="preview-wrapper">
                <div className={"d-flex align-items-center mb-0"}>
                    {!(!multiple && hasImages) &&
                    <Button background={triggerBackground}
                            color={triggerColor}
                            className={classNames("images-trigger", {add: hasImages})}
                            style={{
                                ...triggerStyle
                            }}>
                             <span style={{color: triggerColor}} className={"d-flex align-items-center"}>
                                 {hasImages ? (!admin && imagesSrc?.length >= maxItems) ?
                                     <MdCheck size={20}/> : "+" : triggerValue}
                             </span>
                        {input}
                    </Button>}

                    {!admin && hasImages && multiple &&
                    <div className="px-3">
                        <span className="font-size-md color-black">{`${imagesSrc.length}/${minItems}`}</span>
                        {enoughImages &&
                        <span className="px-3 color-black">{"יופי! יש מספיק תמונות, אפשר להמשיך"}</span>}
                    </div>}
                </div>

                {sizeErrorMessage && <Span fontSize={font_size.sm} color="red">גודל תמונה מקסימלי: 10MB</Span>}

                {imagesSrc?.length > 0 &&
                <VerticalScroller className={`images-container justify-content-${justifyContent}`} id="image-preview">
                    {/*{multiple && placeholderButton}*/}
                    {imagesSrc && Array.isArray(imagesSrc) ?
                        imagesSrc.map((src, i) => <ImagePreview index={i} src={src} key={i}/>)
                        :
                        <ImagePreview index={0} src={imagesSrc}/>}
                </VerticalScroller>}

            </div>
            {optionalImages?.length > 0 &&
            <div>
                <Heading tag={"h5"} textAlign={"center"}>
                    <Translation id={DICT.OR}/> <Translation id={DICT.CHOOSE_IMAGE}/>
                </Heading>
                <Row className="files-optional-images">
                    {optionalImages.map((defaultImage, index) => {
                        const onClick = () => handleOptionalImageClick(defaultImage);
                        return (
                            <Col sm={2} onClick={onClick} className="files-optional-images-item" key={index}>
                                <Image src={defaultImage}/>
                            </Col>
                        )
                    })}
                </Row>
            </div>
            }
            {errorMessage &&
            <Span block
                  fontSize={font_size.xs}
                  color={'#7b2115'}
                  value={errorMessage}
                  style={{
                      marginTop: ".4rem",
                      visibility: (errorMessageActive) ? "visible" : "hidden",
                      textAlign: rtl ? "right" : "left",
                      ...warningStyle
                  }}

            />}
        </div>
    )
};

FilesUploader.defaultProps = {
    singleFile: false,
    buttonWidth: '120px',
    apiUrl: API_URL,
    triggerSize: 60,
    // previewWidth: "100%",
    submitTrigger: false,
    triggerBackground: colors.action,
    triggerColor: colors.white,
    backgroundSize: "contain"
};
FilesUploader.propTypes = {
    apiUrl: PropTypes.string.isRequired,
    singleFile: PropTypes.bool,
    buttonWidth: PropTypes.string,
    previewWidth: oneOfType([PropTypes.string, PropTypes.number]),
    previewStyle: PropTypes.object,
    triggerStyle: PropTypes.object,
    TriggerIcon: PropTypes.element,
    submitTrigger: PropTypes.bool,
    triggerColor: PropTypes.string,
    triggerBackground: PropTypes.string,
    backgroundSize: PropTypes.oneOf(["cover", "contain"]),
    triggerSize: oneOfType([PropTypes.string, PropTypes.number])
};


export default FilesUploader;