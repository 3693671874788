import React from 'react';
import styled, {keyframes} from "styled-components";

class IconSpinner extends React.Component {
    render() {
        const {color, size} = this.props;
        return (
            <Rotate>
            <svg className="spinner" viewBox="0 0 50 50" style={{width: `${size}px`, height: `${size}px`}}>
                <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"  stroke={color} style={path}/>
            </svg>
            </Rotate>
        )
    }
    static defaultProps = {
        color: '#5492bc',
        size: 50
    }
}

export default IconSpinner;

// Create the keyframes
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
// Here we create a component that will rotate everything we pass in over two seconds
const Rotate = styled.div`
  display: inline-block;
  animation: ${rotate} 2s linear infinite;
  padding: 2rem 1rem;
  font-size: 1.2rem;
`;

const path = {
    strokeLinecap: 'round',
    // animation: `${dash} 1.5s ease-in-out infinite`
};
