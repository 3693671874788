import React, {useContext, useState} from "react";
import SingleFrameInstructions from "../../../tools/instructions/single-frame-instructions";
import {CreateToolTips} from "../../../tools/tips/create-tool-tips";
import {FrameContext} from "../single-frame";
import {FrameModalContext} from "./frame-modals/frame-modal";
import {colors} from "../../../../00_base/variables";
import {FieldCheckbox} from "../../../../02_molecules";

const emptyInstructions = {title: "", subtitle: "", description: ""};

export const SingleFrameEditable = ({editable, instructions, tips}) => {
    const frameModalContext = useContext(FrameModalContext);
    const {handleFrameChange} = useContext(FrameContext);

    const [isOpen, setIsOpen] = useState(!!frameModalContext);


    const INSTRUCTIONS = <SingleFrameInstructions instructions={instructions || emptyInstructions}
                                                  editable={editable}
                                                  checkboxStyle={checkboxStyle}/>;

    const TIPS = <CreateToolTips tips={tips} editable={editable}/>;

    return (
        <div className="single-frame-box">
            <div className="instructions-header" onClick={() => setIsOpen(!isOpen)}>
                <FieldCheckbox options={["ניתן לעריכה בהתאמה אישית"]}
                               name={"editable"}
                               value={editable ? ["ניתן לעריכה בהתאמה אישית"] : []}
                               changeHandler={handleFrameChange}
                               {...checkboxStyle}
                               controlStyle={{margin: 0}}/>
            </div>
            {editable &&
            <>
                {INSTRUCTIONS}
                {TIPS}
            </>
            }
        </div>
    )
};

const checkboxStyle = {
    inputBackground: colors.gray_6,
    inputBackgroundActive: colors.gray_6,
    inputBorder: 0
};