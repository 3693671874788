export const DICT = {
    CREATE_ADVENTURE: "createNewAdventure",
    HOMEPAGE_TITLE: "homepageTitle",
    HOMEPAGE_DESCRIPTION_1: "homepageDescription1",
    HOMEPAGE_DESCRIPTION_2: "homepageDescription2",
    HOMEPAGE_DESCRIPTION_3: "homepageDescription3",
    HOMEPAGE_DESCRIPTION_4: "homepageDescription4",
    HOMEPAGE_INSTRUCTIONS_1: "homepageInstructions1",
    HOMEPAGE_INSTRUCTIONS_2: "homepageInstructions2",
    HOMEPAGE_INSTRUCTIONS_3: "homepageInstructions3",
    HOMEPAGE_CUSTOMIZE_ADVENTURES_TITLE: "homepageCustomizeAdventuresTitle",
    HOMEPAGE_CUSTOMIZE_ADVENTURES_DESCRIPTION: "homepageCustomizeAdventuresDescription",
    HOMEPAGE_DIGITAL_ADVENTURES_TITLE: "homepageDigitalAdventuresTitle",
    HOMEPAGE_DIGITAL_ADVENTURES_DESCRIPTION: "homepageDigitalAdventuresDescription",
    HOMEPAGE_ADVENTURES_TITLE: "homepageAdventuresTitle",
    HOMEPAGE_ADVENTURES_DESCRIPTION: "homepageAdventuresDescription",
    INNER_CUSTOMIZE_ADVENTURES_SUBTITLE: "innerCustomizeAdventuresSubtitle",
    INNER_CUSTOMIZE_ADVENTURES_DESCRIPTION: "innerCustomizeAdventuresDescription",
    RIDDLE_STOCK_TITLE: "riddleStockTitle",
    RIDDLE_STOCK_DESCRIPTION: "riddleStockDescription",
    RIDDLE_STOCK_FULL: "riddleStockFull",
    SHEKEL: "shekel",
    EXPERT: "expert",
    HARD: "hard",
    MEDIUM: "medium",
    EASY: "easy",
    ABOUT_US_TITLE: "aboutUsTitle",
    ABOUT_US_DESCRIPTION_1: "aboutUsDescription1",
    ABOUT_US_DESCRIPTION_2: "aboutUsDescription2",
    ABOUT_US_DESCRIPTION_3: "aboutUsDescription3",
    ADVENTURE_BOX: "adventureBox",
    USER_PAGE_TITLE: "userPageTitle",
    USER_PAGE_DESCRIPTION: "userPageDescription",
    WOE_WE_ARE: "whoWeAre",
    CUSTOMIZE_ADVENTURES: "customizeAdventures",
    DIGITAL_ADVENTURES: "digitalAdventures",
    DID_FOUND_WHAT_YOU_LOOKING_FOR: "didNotFindWhatYouLookingFor",
    CUSTOMERS_TITLE: "customersTitle",
    CUSTOMERS_DESCRIPTION: "customersDescription",
    GALLERY_TITLE: "galleryTitle",
    GALLERY_MORE_IMAGES: "galleryMoreImages",
    REGISTER: "register",
    LOGIN: "login",
    LOGOUT: "logout",
    BLOG: "blog",
    HELLO: "hello",
    BUSINESSES: "businesses",
    PRODUCTS: "products",
    ADVENTURE_BLOG: "adventureBlog",
    GALLERY: "gallery",
    TERMS: "terms",
    CONTACT_US: "contactUs",
    TALK_WITH_US: "talkWithUs",
    FAQ: "faq",
    WANT_EVENT_ADVENTURE: "wantEventAdventure",
    ALL_RIGHTS: "allRights",
    PLEASE_REGISTER: "pleaseRegister",
    ADVENTURES: "adventures",
    CREATED_AT: "createdAt",
    ADMIN: "admin",

    ADVENTURE: "adventure",
    ADVENTURE_TYPE:"adventureType",
    PAYMENT_MAIN_TITLE: "paymentMainTitle",
    PAYMENT_DELIVERY_MESSAGE: "deliveryMessage",
    FIRST_BOX: "firstBox",
    OTHER_BOXES: "otherBoxes",
    FIRST_ADVENTURE: "firstAdventure",
    OTHER_ADVENTURES: "otherAdventures",
    INSERT_CUSTOM_PRICE: "insertCustomPrice",
    ADVENTURES_AMOUNT: "adventuresAmount",
    HOW_TO_GET_ADVENTURE: "howToGetAdventure",
    SELF_COLLECTING: "selfCollection",
    COMMENTS: "comments",
    DELIVERY: "delivery",
    DELIVERY_ADDRESS: "deliveryAddress",
    DELIVERY_MESSAGE: "deliveryMessage",
    DELIVERY_WARNING: "deliveryWarning",
    CUSTOMER_DETAILS: "customerDetails",
    MAIL_TO_FOLLOW: "mailToFollow",
    MAIL_WARNING: "mailWarning",
    COUPON_DISCOUNT: "couponDiscount",
    CONTACT_PHONE_NUMBER: "contactPhoneNumber",
    CONTACT_MEMBER: "contactMember",
    CONTACT_WARNING: "contactWarning",
    COUPON_CODE: "couponCode",
    COUPON_INSTRUCTION: "couponInstruction",
    COUPON_NOT_EXIST: "couponNotExist",
    SEND: "send",
    I_CONFIRM_THE: "iConfirmThe",
    GO_TO_PAYMENT: "goToPayment",
    FINISH_ORDER: "finishOrder",
    CITY: "city",
    CITY_WARNING: "cityWarning",
    STREET: "street",
    TERMS_WARNING: "termsWarning",
    STREET_WARNING: "streetWarning",
    HOME_NUMBER: "homeNumber",
    APARTMENT: "apartment",
    POSTAL_CODE: "postalCode",
    UPLOAD_IMAGE: "uploadImage",
    UPLOAD_IMAGES: "uploadImages",
    UPLOAD_VIDEO: "uploadVideo",
    TASK_FINISHED: "missionFinished",
    TASK_FULLY_COMPLETED: "taskFullyCompleted",
    TASK_NOT_FULLY_COMPLETED: "taskNotFullyCompleted",
    ADVENTURE_SAVED: "adventureSaved",
    QUESTION_NUMBER: "questionNumber",
    INSERT_CORRECT_ANSWER: "insertCorrectAnswer",
    INSERT_INCORRECT_ANSWER: "insertIncorrectAnswer",
    MALE: "male",
    FEMALE: "female",
    MORE_DETAILS: "moreDetails",


    CHOOSE_IMAGE: "chooseImage",
    OR: "or",
    YEAR: "year",
    MONTH: "month",
    DAY: "day",
    CONTINUE: "continue",
    GO_BACK: "goBack",
    PASSWORD:"password",
    ADVENTURES_IN_PROGRESS: "adventuresInProgress",
    ADVENTURE_PROCESS_FINISH: "adventureProcessFinish",
    ADVENTURE_DETAILS_WAITING_IN_EMAIL: "adventureDetailsWaitingInEmail",

    NO_EMAIL_LOOK_AT_PROMOTIONS: "noEmailLootAtPromotions",
    HAVE_ANY_PROBLEM: "haveAnyProblem",
    SUBSCRIBE_DESCRIPTION: "subscribeDescription",
    SUBSCRIBE_INPUT_PLACEHOLDER: "subscribeInputPlaceholder",

    DIGITAL: "digital",
    PERSONAL_BOXES: "personalBoxes",
    RIDDLES: "riddles",
    ESCAPE_ROOMS: "escapeRooms",
    CLICK_HERE: "clickHere",
    UNIQUE_SOLUTIONS: "uniqueSolutions",

    FOR_SPECIAL_BUSINESSES: "forSpecialBusinesses",
    ADVENTURES_FOR_BUSINESSES_TITLE: "adventuresForBusinessesTitle",
    ADVENTURES_FOR_BUSINESSES_DESCRIPTION: "adventuresForBusinessesDescription",
    BUSINESSES_DESCRIPTION_MAIN: "businessesDescriptionMain",
    BUSINESSES_DESCRIPTION_SUB: "businessesDescriptionSub",
    WHAT_CAN_WE_DO_TOGETHER: "whatCanWeDoTogether",
    WHAT_CAN_WE_DO_TOGETHER_DESCRIPTION: "whatCanWeDoTogetherDescription",
    FREE: "free",
    EDIT:"edit",
    PLAY:"play",
    DELETE:"delete",
    NEXT:"next",
    PREVIOUS:"previous"
};