export const BLOCKS = "blocks";
export const GRID = "grid";
export const SLIDER = "slider";
export const CARROUSEL = "carrousel";
export const TABS = "tabs";
export const contentViewOptions = [BLOCKS, GRID, SLIDER, CARROUSEL, TABS];

export const UP = "up";
export const DOWN = "down";
export const indicatorsLocationsOptions = [UP, DOWN];

