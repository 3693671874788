import React, {useContext, useEffect, useReducer, useState} from "react";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import {ACTIVE_SECTION, SHOW_FEEDBACK, USER_ANSWERS} from "./trivia-local-storage-consts";
import {TriviaIndicators} from "./trivia-indicators";
import {TriviaResults} from "./trivia-results";
import {TriviaButtons} from "./trivia-buttons";
import "../../../../../../assets/styles/trivia-style.scss";
import {FrameContext} from "../../05_screens/tool/screen-tool";
import {ToolTriviaSections} from "./tool-trivia-sections";
import {useEventListener} from "../../../hooks/use-event-listener";


const defaultUserAnswers = (items) => items.map((item, index) => {
    return {a: item.answer, u: -1, key: index}
});

const answersReducer = (state, action) => {
    if (action.type === "reset") {
        return defaultUserAnswers(action.items)
    } else {
        const index = action.questionIndex || 0;
        if (index < state.length) {
            state[index].u = action.answerIndex;
            localStorage.setItem(USER_ANSWERS, JSON.stringify(state));
        }
        localStorage.setItem(USER_ANSWERS, JSON.stringify(state));
        return state;
    }

};

export const TriviaContext = React.createContext({
    activeSection: 0,
    userAnswers: [],
    setUserAnswers: answersReducer,
    allCorrect: false,
    finalScreenActive: false,
    isResolve: false
});

export const ToolTrivia = (
    {
        items,
        className,
        wrongTitle,
        wrongSubTitle,
        wrongDescription,
        correctTitle,
        correctSubTitle,
        correctDescription,
        finalImageUrl,
        nextButtonBackground,
        nextButtonColor,
        prevButtonBackground,
        prevButtonColor,
        sections_view = "tabs",
        previewMode
    }) => {

    if (previewMode) {
        localStorage.setItem(USER_ANSWERS, null);
        localStorage.setItem(ACTIVE_SECTION, null);
        localStorage.setItem(SHOW_FEEDBACK, null)
    }
    const isSlider = sections_view === "slider";
    const isTabs = sections_view === "tabs";
    const isCarrousel = sections_view === "carrousel";
    const isBlocks = sections_view === "blocks";
    const isGrid = sections_view === "grid";

    const localUserAnswers = JSON.parse(localStorage.getItem(USER_ANSWERS));
    const {isResolve, handleFrameFinish,} = useContext(FrameContext);

    const adventureName = useSelector(state => state.gameData?.adventure)?.adventure_name || "____";

    const [activeSection, setActiveSection] = useState(JSON.parse(localStorage.getItem(ACTIVE_SECTION)) || 0);
    const [showFeedback, setShowFeedback] = useState(JSON.parse(localStorage.getItem(SHOW_FEEDBACK)));

    const [userAnswers, setUserAnswers] = useReducer(answersReducer, localUserAnswers || defaultUserAnswers(items));
    const allCorrect = userAnswers.every(item => item.u === item.a);

    const finalScreenActive = activeSection >= items.length;

    useEffect(() => {
        localStorage.setItem(SHOW_FEEDBACK, JSON.stringify(showFeedback));
    }, [showFeedback]);

    useEffect(() => {
        localStorage.setItem(ACTIVE_SECTION, JSON.stringify(activeSection))
        if (finalScreenActive) {
            setShowFeedback(true);
        }
    }, [activeSection, finalScreenActive]);

    useEffect(() => {
        if (allCorrect) handleFrameFinish();
    }, [allCorrect, handleFrameFinish]);


    const keyPressHandler = ({key}) => {
        if (!previewMode) {
            switch (key) {
                case "ArrowLeft":
                    if (activeSection < items.length) {
                        setActiveSection(activeSection + 1);
                    }
                    break;
                case "ArrowRight":
                    if (activeSection > 0) {
                        setActiveSection(activeSection - 1);
                    }
                    break;
                default:
                    return;
            }
        }
    };
    useEventListener('keydown', keyPressHandler);
    const showContinueButton = !finalScreenActive || !isResolve;
    const triviaContext = {
        userAnswers,
        setUserAnswers,
        adventureName,
        sections_view,
        activeSection,
        allCorrect,
        finalScreenActive,
        isResolve,
        isSlider,
        isGrid,
        isTabs,
        isCarrousel,
        isBlocks
    };

    return (
        <TriviaContext.Provider value={triviaContext}>
            <div className={classNames(`trivia trivia-view-${sections_view}`, `${className}`)}>
                {items.length === 0 ?
                    <p>לא נמצאו שאלות</p> :
                    (finalScreenActive && !allCorrect) ?
                        <TriviaResults title={wrongTitle}
                                       subtitle={wrongSubTitle}
                                       description={wrongDescription}
                                       finalImageUrl={finalImageUrl}/>
                        :
                        <ToolTriviaSections items={items}/>
                }

                {!isGrid && !isBlocks &&
                <>
                    <TriviaIndicators items={items}
                                      showFeedback={showFeedback}
                                      onClick={(index) => setActiveSection(index)}/>

                    <TriviaButtons setActiveSection={setActiveSection}
                                   showContinueButton={showContinueButton}
                                   nextButtonBackground={nextButtonBackground}
                                   nextButtonColor={nextButtonColor}
                                   prevButtonBackground={prevButtonBackground}
                                   prevButtonColor={prevButtonColor}/>
                </>}

            </div>
        </TriviaContext.Provider>
    )
};

ToolTrivia.defaultProps = {
    items: [],
    className: "",
    correctTitle: "כל הכבוד!",
    correctSubTitle: "עניתם נכון על כל השאלות",
    correctDescription: "",
    wrongTitle: "כמעט",
    wrongSubTitle: "בדקו שוב את כל השאלות",
    wrongDescription: "",
    handleToolFinish: () => console.log(""),
    nextButtonBackground: "#004A8B",
    nextButtonColor: "#fff",
    prevButtonBackground: "#8b0000",
    prevButtonColor: "#fff",
};

ToolTrivia.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            questionLabel: PropTypes.string,
            question: PropTypes.string,
            questionImageUrl: PropTypes.string,
            options: PropTypes.arrayOf(PropTypes.string),
            answer: PropTypes.number
        })
    ).isRequired,
    className: PropTypes.string,
    correctTitle: PropTypes.string,
    correctSubTitle: PropTypes.string,
    correctDescription: PropTypes.string,
    wrongTitle: PropTypes.string,
    wrongSubTitle: PropTypes.string,
    wrongDescription: PropTypes.string,
    handleToolFinish: PropTypes.func,
    finalImageUrl: PropTypes.string,
    nextButtonBackground: PropTypes.string,
    nextButtonColor: PropTypes.string,
    prevButtonBackground: PropTypes.string,
    prevButtonColor: PropTypes.string,
};