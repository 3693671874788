import React, {useState} from "react";
import {Button, Circle} from "../../01_atoms";
import {colors} from "../../00_base/variables";
import {FieldTextArea} from "../../02_molecules";

export const Comment = ({comment, deleteHandler, updateHandler}) => {
    const {created_at, full_name, content} = comment;
    const acronyms = full_name.split(' ').map(item => item[0]).join('').slice(0,2);
    const [isEditable, setIsEditable] = useState(false);
    const [commentContent, setCommentContent] = useState(comment.content);
    const handleSave = () => {
        updateHandler({...comment, content: commentContent});
        setIsEditable(false)
    };
    const handleCancel = () => {
        setIsEditable(false);
        setCommentContent(comment.content);
    };
    return (
        <div className="admin-conversation-comment">
            <Circle size={30}
                    background={colors.info}
                    style={{fontWeight:"bold"}}
                    className={"ml-3"}>
                {acronyms}
            </Circle>
            <div>
                <div className="d-flex align-items-center">
                    <h6 className="my-0" style={{fontWeight: "bold"}}>{full_name}</h6>
                    <span className="px-3">{created_at}</span>
                </div>
                <div>
                    {isEditable ?
                        <>
                            <FieldTextArea changeHandler={(name, value) => setCommentContent(value)}
                                           rows={5}
                                           controlStyle={{marginBottom: 0}}
                                           value={content}
                                           wysiwyg={true}
                                           toolbarHidden={false}
                                           name="edit-comment"/>
                            <div className="d-flex justify-content-end py-3">
                                <Button className="ml-3"
                                        radius="5px"
                                        style={{backgroundColor: "transparent", color: colors.gray_6}}
                                        value="ביטול"
                                        onClick={handleCancel}/>
                                <Button radius="5px"
                                        value="שמור"
                                        onClick={handleSave}/>
                            </div>

                        </>
                        :
                        <p className="my-0" dangerouslySetInnerHTML={{__html: commentContent}}/>
                    }
                </div>
                {!isEditable &&
                <div className="d-flex align-items-center">
                    <Button value="Edit"
                            className="px-0 fs-2 bg-transparent color-gray-600"
                            onClick={() => setIsEditable(true)}/>
                    <Circle className="bg-gray-600 mx-3" size={3}/>
                    <Button value="Delete"
                            className="px-0 fs-2 bg-transparent color-gray-600"
                            onClick={deleteHandler}/>
                </div>}
            </div>
        </div>
    )
};