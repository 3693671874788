import styled from 'styled-components';
import {colors, gutters} from "../../00_base/variables";
import React from "react";

export const Wrapper = styled.div`
    background: ${props => !props.screenBackgroundImage && props.screenBackground};
    background-image: ${props => props.screenBackgroundImage && `url(${props.screenBackgroundImage})`};
    border: ${props => `${props.borderWidth}px solid ${props.borderColor}`};
    height: 100%;
`;
export const Sentence = styled.div`
    display: ${props => props.isActive ? 'block' : 'none'};
`;
export const IndicatorWrapper = styled.div`
    padding:  ${props => props.padding};
`;


export const OptionSquare = styled.div`
    padding: ${gutters.xs};
    min-height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 3rem;
    border-radius: 30px;
    border: 2px solid ${props => props.bgColor || (props.isActive ? colors.black : colors.white)};
    // background: ${props =>  (props.isActive && props.bgColor) || (props.background || colors.white)};
    // color: ${props => (props.isActive && props.textColor) || (colors.black)};
    
    
    height: 100%;
    width: 100%;
    box-sizing: border-box !important;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    transition: box-shadow 0.3s;
    img {
        max-height: 100%;
    }
    &:hover {
        cursor: pointer;
        box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    }
`;



