import React from 'react';
import {FieldText, FieldVideo, FilesUploader} from '../../../02_molecules';
import {gutters} from "../../../00_base/variables";

const CreateToolTreasure = (
    {
        finalTitle,
        finalSubtitle,
        singularLabel,
        pluralLabel,
        singular_images,
        plural_images,
        finalImage,
        selfieCover,
        secondLogo,
        secondLogoUrl,
        finalVideo,
        handleToolDataChange,
    }) => {
    const controlStyle = {marginBottom: gutters.sm};
    const triggerStyle = {width: "100%"};
    const fieldProps = {
        controlStyle: controlStyle,
        changeHandler: handleToolDataChange
    };
    const filesUplaodProps = {
        ...fieldProps,
        triggerStyle: triggerStyle
    };
    return (
        <div className={"create-tool-treasure"}>
            <FieldText label='כותרת'
                       name='finalTitle'
                       value={finalTitle}
                       {...fieldProps}/>

            <FieldText label='תת כותרת'
                       name='finalSubtitle'
                       value={finalSubtitle}
                       {...fieldProps}/>

            <FilesUploader label='תמונת סיום'
                           triggerValue='תמונת סיום'
                           multiple={false}
                           name='finalImage'
                           value={finalImage}
                           {...filesUplaodProps}/>

            <FilesUploader label='קאבר לסלפי'
                           triggerValue='תמונת קאבר לסלפי'
                           multiple={false}
                           name='selfieCover'
                           value={selfieCover}
                           {...filesUplaodProps}/>

            <FilesUploader label='לוגו נוסף'
                           triggerValue='לוגו נוסף'
                           maxFiles={1}
                           multiple={false}
                           name='secondLogo'
                           value={secondLogo}
                           {...filesUplaodProps}/>

            <h4>התאמה אישית</h4>
            <FieldText label='הנחייה עבור תמונות יחידניות'
                       name='singularLabel'
                       value={singularLabel}
                       {...fieldProps}/>

            <FilesUploader label="תמונות יחידניות"
                           triggerValue="תמונות יחידניות"
                           name="singular_images"
                           value={singular_images}
                           {...filesUplaodProps}/>

            <FieldText label='הנחייה עבור תמונות קבוצתיות'
                       name='pluralLabel'
                       value={pluralLabel}
                       {...fieldProps}/>

            <FilesUploader label="תמונות קבוצתיות"
                           triggerValue="תמונות קבוצתיות"
                           name="singular_images"
                           value={plural_images}
                           {...filesUplaodProps}/>


            <FieldText label='קישור לוגו נוסף'
                       name='secondLogoUrl'
                       value={secondLogoUrl}
                       {...fieldProps}/>

            <FieldVideo label='סרטון'
                        triggerValue='העלה סרטון'
                        name='finalVideo'
                        value={finalVideo}
                        changeHandler={handleToolDataChange}/>
        </div>
    )
};


CreateToolTreasure.defaultProps = {};

CreateToolTreasure.propTypes = {};


export default CreateToolTreasure;