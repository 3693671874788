import React from "react";
import {Container} from "react-grid-system";
import ContentLoader, {BulletList, Facebook, Instagram, List} from 'react-content-loader'

const defaultBackgroundColor = "#e6e6e6";
const loop = (items, section) => {
    let sections = [];
    for (let i = 0; i < items; i++) {
        sections.push(section)
    }
    return sections;
};
export const LoaderContent = ({backgroundColor = defaultBackgroundColor}) => {
    return <ContentLoader rtl={true} backgroundColor={backgroundColor} />
};

export const LoaderFacebook = ({backgroundColor = defaultBackgroundColor}) => {
    return <Facebook rtl={true} backgroundColor={backgroundColor} />
};

export const LoaderInstagram = ({backgroundColor = defaultBackgroundColor}) => {
    return <Instagram rtl={true} backgroundColor={backgroundColor} />
};

export const LoaderBulletList = ({backgroundColor = defaultBackgroundColor, height}) => {
    return <BulletList rtl={true} backgroundColor={backgroundColor} height={height} />
}

export const LoaderList = ({backgroundColor = defaultBackgroundColor}) => {
    return <List rtl={true} backgroundColor={backgroundColor} />
}


export const LoaderPageContent = ({items = 4, backgroundColor = defaultBackgroundColor, height}) => {
    const sections = loop(items, LoaderContent)
    return (
        <Container>
            {sections.map((Item, i) => <Item  key={i} backgroundColor={backgroundColor} height={height}/>)}
        </Container>
    )
}

export const LoaderPageBulletList = ({items = 3, backgroundColor = defaultBackgroundColor, height}) => {
    const sections = loop(items, LoaderBulletList);

    return (
        <Container>
            {sections.map((Item, i) => <Item  key={i} backgroundColor={backgroundColor} height={height}/>)}
        </Container>
    )
}

export const LoaderPageList = ({items = 3, backgroundColor = defaultBackgroundColor, height}) => {
    const sections = loop(items, LoaderList);
    return (
        <>
            {sections.map((Item, i) => <Item  key={i} backgroundColor={backgroundColor} height={height}/>)}
        </>
    )
}