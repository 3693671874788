import React, {useContext} from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import {colors, font_size} from '../../00_base/variables';
import {RouteContext} from "../../04_layout/app-content/l-app-content";

const Button = (props) => {
    const {admin} = useContext(RouteContext);
    const {
        background = colors.select,
        color = colors.white,
        padding = `${admin ? ".5" : "1.2"}rem 1.6rem`,
        border = `1px solid ${colors.select}`,
        size = "sm",
        margin,
        block,
        style,
        className,
        children,
        value,
        radius = 20,
        isLoading,
        fontWeight,
        underline,
        width,
        pointer,
        backgroundColorHover,
        onClick,
        colorHover,
        outline
    } = props;
    return (
        <ButtonStyle
            onClick={onClick}
            className={`btn ${className}`}
            fontSize={font_size[size]}
            block={block}
            border={border}
            fontWeight={fontWeight}
            radius={radius}
            padding={padding}
            margin={margin}
            width={width}

            colorHover={colorHover}
            textDecoration={underline && 'underline'}
            backgroundColorHover={backgroundColorHover}
            background={background}
            color={color}
            outline={outline}
            pointer={pointer}
            style={style}>
            {isLoading ? '...' : children || value}
        </ButtonStyle>
    )
};

const ButtonStyle = styled.button`
    width: ${props => props.width || 'auto'};
    display: ${props => props.block && 'block'};
    background-color: ${props => props.background ? props.background : props.outline ? 'transparent' : props.backgroundColor};
    color: ${props => props.color ? props.color : props.color || props.outline ? colors.black : colors.black};
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    border: ${props => props.border ? props.border : props.outline ? `2px solid ${props.backgroundColor}` : 0};
    border-color: ${props => props.outline && props.backgroundColor};
    border-radius: ${props => props.radius};
    padding: ${props => props.padding};
    margin: ${props => props.margin};
    cursor: ${props => props.pointer && 'pointer'};
    outline: 0 !important;
    &:hover {
        background-color: ${props => props.backgroundColorHover};
        color: ${props => props.colorHover}
    }
    a {
        color: ${props => props.color ? props.color : props.color || props.outline ? colors.black : colors.black};
    }
`;

Button.defaultProps = {
    className: '',
    value: '---',
    size: 'sm',
    border: 0,
    background: colors.select,
    radius: '2rem',
    block: false,
    outline: false,
    onClick: null,
    pointer: true
};

Button.propTypes = {
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']),
    padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    border: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // variant: PropTypes.oneOf(['primary', 'secondary', 'success', 'error', 'warning', 'disable', 'link']),
    block: PropTypes.bool,
    pointer: PropTypes.bool,
    outline: PropTypes.bool,
    background: PropTypes.string,
    backgroundColorHover: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default Button;