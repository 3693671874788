import React from 'react';
import {Element} from 'react-stylesheet';
import {gutters} from '../../../00_base/variables';
import {Image} from "../../../01_atoms";

const CREDIT_CARDS =  "https://storage.googleapis.com/assets.youdoadventures.com/website/credit-cards.png";
const PCI_LOGO = "../../../../assets/images/pci_logo.png";

const TranzilaIframe = (props) => {
    const {sum, full_name, email, phone, homemade, title} = props;
    const {boxesLength, city, street, home_number, postal_code, couponDiscount, passcode, adventureId, apartment} = props;
    const {deliveryType, selfCollection, singleBoxPrice, notes} = props;
    console.log(props);
    return (
        <Element background={'transparent'} paddingTop={gutters.sm}>
            <iframe title={title}
                    src={`https://direct.tranzila.com/youdoltd/iframenew.php?
                          sum=${parseInt(sum.replace('ש"ח', ""))}&
                          currency=1&
                          cred_type=1&
                          tranmode=A& 
                          currency=1&
                          homemade=${homemade}&
                          full_name=${full_name}&
                          email=${email}&
                          phone=${phone}&
                          ${city && `city=${city}`}&
                          ${street && `street=${street}`}&
                          ${home_number && `home_number=${home_number}`}&
                          ${postal_code && `postal_code=${postal_code}`}&
                          ${apartment && `apartment=${apartment}`}&
                          ${selfCollection && `selfCollection=${selfCollection}`}&
                          ${couponDiscount && `couponDiscount=${couponDiscount}`}&
                          ${singleBoxPrice && `singleBoxPrice=${singleBoxPrice}`}&
                          ${notes && `notes=${notes}`}&
                          passcode=${passcode}&
                          adventureId=${adventureId}&
                          deliveryType=${deliveryType}&
                          boxesLength=${boxesLength}&
                          trBgColor=f2f2f2&
                          nologo=1&
                          lang=il&
                          ppnewwin=2`}

                    width={'370px'}
                    height={'400px'}
                    style={{border: '0', maxWidth: "100%"}}
            />

            <Element maxWidth="37rem" padding="0 10px">
                <Image src={PCI_LOGO} block maxWidth="100px" className={"mb-3 mx-auto"}/>
                <Image src={CREDIT_CARDS} block/>
            </Element>
        </Element>
    )
}

export default TranzilaIframe;