const gutter = 1;
const gutters = {
    xxs: gutter / 3 + 'em',
    xs: gutter / 2 + 'em',
    sm: gutter + 'em',
    md: gutter * 1.5 + 'em',
    lg: gutter * 2 + 'em',
    xl: gutter * 3 + 'em',
    xxl: gutter * 4 + 'em',
    xxxl: gutter * 5 + 'em',
};

export {gutter, gutters};