export const HEBREW_CODER = "HEBREW_CODER";
export const ENGLISH_CODER = "ENGLISH_CODER";
export const NUMBERS_CODER = "NUMBERS_CODER";

export const getInitialCoderStatus = (password) => {
    const firstLetter = password[0] || 1;
    const firstLetterEnglish = firstLetter.match(/[a-z]/i)
    const firstLetterHebrew = firstLetter.match(/[\u0590-\u05FF]/);
    if (firstLetterHebrew) {
        return HEBREW_CODER
    }
    if (firstLetterEnglish) {
        return ENGLISH_CODER
    }
    return NUMBERS_CODER
}