import React, {useContext} from "react";
import {FieldColor, FieldText} from "../../../../../02_molecules";
import {FrameContext} from "../../single-frame";

export const SingleFrameDesignColor = (
    {backgroundColor = "#fff", textColor = "#000", borderColor, borderWidth}
) => {
    const {handleToolDataChange} = useContext(FrameContext);

    const labelStyle = {marginBottom: 0, paddingLeft: 0, width: "80px"};
    return (
        <div>
            <FieldColor changeHandler={handleToolDataChange}
                        name="backgroundColor"
                        label="צבע רקע:"
                        labelStyle={labelStyle}
                        value={backgroundColor}/>
            <FieldColor changeHandler={handleToolDataChange}
                        name="textColor"
                        label="צבע טקסט:"
                        labelStyle={labelStyle}
                        value={textColor}/>
            <FieldColor changeHandler={handleToolDataChange}
                        label="צבע מסגרת:"
                        labelStyle={labelStyle}
                        name="borderColor"
                        value={borderColor}/>
            <FieldText type="number"
                       changeHandler={handleToolDataChange}
                       label="עובי מסגרת:"
                       name="borderWidth"
                       value={borderWidth}
                       dir={"horizontal"}
                       inputStyle={{backgroundColor: "#fff", height: "27px", maxWidth: "70px"}}
                       labelStyle={labelStyle}/>
        </div>
    )
}