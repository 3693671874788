class ContactListItem {
    constructor(
        id,
        name,
        phone_number,
        sentence,
        sentencesOptions= [],
        keyword,
        placeholder,
        icon,
        video,
        color
    ) {
        this.id = id;
        this.name = name;
        this.phone_number = phone_number;
        this.sentence = sentence;
        this.sentencesOptions = sentencesOptions;
        this.placeholder = placeholder;
        this.keyword = keyword;
        this.icon = icon;
        this.video = video;
        this.color = color;
    }
}

export const MOCK = [
    new ContactListItem(0, "אמיר", "052-4825711"),
    new ContactListItem(1, "נדב", "052-4825711"),
    new ContactListItem(2, "גלית", "052-4825711"),
    new ContactListItem(3, "שלומי", "052-4825711"),
    new ContactListItem(4, "רונה", "052-4825711"),
    new ContactListItem(5, "גיורא", "052-4825711")
];