import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MdMoreVert} from "react-icons/md";
import * as adventureTemplatesActions from '../../../../store/actions/adventure-template-actions';
import {selectAllAdventureTemplates} from "../../../../store/selectors";
import {Circle} from "../../../01_atoms";
import {FieldText} from "../../../02_molecules";
import {
    Table,
    TableContainer,
    tableHeader,
    TableInnerMenu,
    TableTitle,
    TableWrapper,
    Td,
    TdContent,
    Tr
} from "../../../03_organisms";
import {TableItemsHeader} from "./table-items-header";

export const ScreenAdventureTemplatesGallery = () => {
    const adventureTemplates = useSelector(selectAllAdventureTemplates);
    const [innerMenuActive, setInnerMenuActive] = useState(null);
    const [activeItemId] = useState(0);

    const dispatch = useDispatch();


    useEffect(() => setInnerMenuActive(null), [setInnerMenuActive, activeItemId]);


    const open_api = id => window.open(`https://api.youdoadventures.com/adventureTemplate/${id}`, '_blank');

    const createNewAdventureTemplate = () => dispatch(adventureTemplatesActions.createAdventureTemplate());

    const delete_template = (adventureTemplatesId) => {
        if (window.confirm("Are you sure?")) {
            dispatch(adventureTemplatesActions.deleteAdventureTemplate(adventureTemplatesId))
            setInnerMenuActive(null);
        }
    };

    const innerMenuItems = [
        {Icon: MdMoreVert, label: "מחיקה", onClick: delete_template},
        {Icon: MdMoreVert, label: "API", onClick: open_api},
    ];

    const tableRow = (item, index) => {
        const Cell = ({onClick, children}) => {
            return (
                <Td onClick={onClick && onClick}>
                    <TdContent backgroundColor="#e7e7e7">{children}</TdContent>
                </Td>
            )
        };
        const controlStyle = {margin: 0, height: "100%"};
        const inputStyle = {border: 0, height: "100%", padding: 0};
        const handleChange = (name, value) => {
            dispatch(adventureTemplatesActions.updateAdventureTemplate({...item, [name]: value}));
        };
        const menuActive = innerMenuActive === item.id;
        return (
            <Tr key={item.id}>
                <Cell>
                    {item.id}
                </Cell>
                <Cell>
                    <FieldText name="name"
                               placeholder={"תבנית ההרפתקה"}
                               value={item.name}
                               controlStyle={controlStyle}
                               inputStyle={inputStyle}
                               changeHandler={handleChange}/>
                </Cell>
                <Cell>
                    <Circle background={menuActive ? "#f1f1f1" : "transparent"}
                            onClick={() => setInnerMenuActive( innerMenuActive === item.id ? null :  item.id)}
                            style={{marginRight: "auto"}}
                            cursor={"pointer"} size={25}>
                        <MdMoreVert size={20} color={"#d2d2d2"}/>
                    </Circle>
                    {menuActive && <TableInnerMenu items={innerMenuItems} itemId={item.id}/>}
                </Cell>
            </Tr>
        )
    };

    const tableHeaderItems = ["#", "שם התבנית"];


    return (
        <TableWrapper>
            <TableTitle>
                <h1>תבניות הרפתקה</h1>
            </TableTitle>
            <TableContainer fluid style={{width: "100%", maxWidth: "700px"}}>
                <TableItemsHeader clickHandler={createNewAdventureTemplate}/>
                <Table cellSpacing="0" cellPadding="0">
                    <thead><Tr>{tableHeaderItems.map(tableHeader)}</Tr></thead>
                    <tbody>{adventureTemplates.map(tableRow)}</tbody>
                </Table>
            </TableContainer>
        </TableWrapper>
    )
};

export default ScreenAdventureTemplatesGallery;