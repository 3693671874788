import React from 'react';
import PropTypes from 'prop-types';
import {FieldText, FilesUploader} from "../../../02_molecules/";
// import "../../../../assets/styles/peek-lock-create.scss";

export const CreateToolPeekLock = (
    {
        moveSpeed = .1,
        lockSize = 1.5,
        lockedImage,
        handleToolDataChange,
    }) => {
    return (
        <div className={"create-tool create-tool-peek-lock"}>
            <FilesUploader label="תמונה מוסתרת"
                           triggerValue="תמונה מוסתרת"
                           name="lockedImage"
                           value={lockedImage}
                           previewWidth="100%"
                           multiple={false}
                           changeHandler={handleToolDataChange}/>

            <FieldText label="מהירות תזוזה"
                       name="moveSpeed"
                       value={moveSpeed}
                       type="number"
                       changeHandler={handleToolDataChange}/>

            <FieldText label="גודל מנעול"
                       name="lockSize"
                       value={lockSize}
                       type="number"
                       changeHandler={handleToolDataChange}/>
        </div>
    )
};


CreateToolPeekLock.defaultProps = {
    submitHandler: (toolData) => console.log('toolData', toolData),
    toolData: {items: []}
};

CreateToolPeekLock.propTypes = {
    toolData: PropTypes.object,
    submitHandler: PropTypes.func,
    defaultProps: PropTypes.object
};

