import React, {useState} from "react";
import {Button} from "../../../01_atoms";
import {ScreenTool} from "../../../youdo-tools/src/components/05_screens/tool/screen-tool";
import {FinalScreen} from "./single-riddle-final";
import {colors} from "../../../00_base/variables";
import RiddleTimer from "./riddle-timer";

export const SingleRiddleGame = (
    {riddle, frames}
) => {
    const [activeFrame, setActiveFrame] = useState(0);
    const [toolContinueActive, setToolContinueActive] = useState(false);
    const [finalGameTime, setFinalGameTime] = useState("");

    const handleContinueClick = () => {
        setActiveFrame(activeFrame + 1);
        setToolContinueActive(false);
    };

    const handleFramePreviewContinue = () => setToolContinueActive(true);

    const frame = frames[activeFrame];
    const gameFinished = activeFrame >= frames.length;

    const toolId = frame?.categories[0];
    const toolData = {categorydata: frame?.categorydata};

    const stopTimer = (m, s) => {
        setFinalGameTime(`${m}:${s}`);
    };

    return (
        <div className="d-block mt-3 pb-5" style={{backgroundColor: colors.white, borderRadius: "10px"}}>

            <RiddleTimer stop={!finalGameTime && gameFinished && stopTimer} finalTime={finalGameTime}/>

            {gameFinished ?
                <FinalScreen riddle={riddle}  /> :
                <>
                    <ScreenTool toolId={toolId}
                                toolData={toolData}
                                handleFramePreviewContinue={handleFramePreviewContinue}
                                previewFrameFinish={toolContinueActive}
                                hideTopBar={true}
                                previewMode={true}/>
                    {toolContinueActive &&
                    <Button className="px-5 mt-4 mx-auto d-block"
                            style={{fontWeight: "bold"}}
                            onClick={handleContinueClick}>
                        המשך
                    </Button>}
                </>
            }
        </div>
    )
};