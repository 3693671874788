import React, {createContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {fetchData} from "../store/actions/fetch-data";
import {YoudoAdventuresLogo} from "../components/03_organisms";
import {IS_LOGIN, NEW_LOGIN, TOKEN, TOKEN_EXP, USER_DATA} from "./auth-local-storage";
import {setLoginUserAdventures} from "../store/actions/adventure-actions";

export const AuthContext = createContext({
    isLogin: "",
    isAdmin: "",
    isCreator: null,
    isTranslator: null,
    logout: null,
    register: null,
    userData: {},
    fullName: "",
    updateUserDetails: null
});

export const AuthProvider = ({children}) => {
    const [userData, setUserData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isLogin, setIsLogin] = useState(localStorage.getItem(IS_LOGIN));

    const isAdmin = userData?.role === "admin";

    const dispatch = useDispatch();

    useEffect(() => {
        const now = new Date().getTime();
        const token = localStorage.getItem(TOKEN);
        const tokenExp = localStorage.getItem(TOKEN_EXP);
        const userData = JSON.parse(localStorage.getItem(USER_DATA));
        const newLogin = localStorage.getItem(NEW_LOGIN); // This is here just to make sure that user is not logged in with the previous version
        const isExpire = tokenExp < now;

        if ((token && isExpire) || !newLogin) {
            logout();
        } else {
            setUserData(userData || {});
            setIsLoading(false)
        }
    }, []);

    const getUserAdventures = (userEmail) => {
        dispatch(setLoginUserAdventures(userEmail));
    };

    const register = (userData, actionAfterLogin, goAfterLogin, handleError) => {
        const {email, password} = userData;
        fetchData(
            'post',
            'register',
            userData,
            () => {
                return login(email, password, actionAfterLogin, goAfterLogin, handleError)
            }
        );
    };

    const login = async (email, password, actionAfterLogin, goAfterLogin, handleError) => {
        await fetchData(
            'post',
            'login',
            {email: email, password: password},
            success => {
                if (success) {
                    handleLoginSuccess(success, actionAfterLogin, goAfterLogin);
                    localStorage.setItem(NEW_LOGIN, "true"); // This is here just to make sure that user is not logged in with the previous version
                } else {
                    localStorage.setItem(IS_LOGIN, null);
                }
            },
            () => {
                if (handleError) {
                    handleError()
                }
            }
        )
    };

    const updateUserDetails = async (updatedUserData, handleSuccess) => {
        const name = updatedUserData.name || userData.name;
        const lastName = updatedUserData.lastName || userData.lastName;
        const avatar = updatedUserData.avatar || userData.avatar;

        await fetchData(
            'put',
            `users/${userData.id || 19}`,
            {name, lastName, avatar},
            success => {
                const updatedData  = {...success.data, lastName: success.data.lastname};
                localStorage.setItem(USER_DATA, JSON.stringify(updatedData));
                setUserData(updatedData);
                handleSuccess(updatedData)
            }
        )
    }

    const forgotPasswordRequest = async (email, handleSuccess, handleError) => {
        await fetchData(
            'post',
            'forgot-password-request',
            {email: email},
            success => {
                if (success) {
                    handleSuccess()
                }
            },
            () => {
                if (handleError) {
                    handleError()
                }
            }
        )
    };

    const resetPasswordRequest = async (token, password, handleSuccess, handleError) => {
        await fetchData(
            'post',
            'forgot-password-reset',
            {token, password},
            success => {
                if (success) {
                    handleSuccess()
                } else {
                    handleError()
                }
            },
            handleError
        )
    };

    const handleLoginSuccess = (userData, actionAfterLogin, goAfterLogin) => {
        const token_exp = new Date(Date.now() + 12096e5).getTime();
        setIsLogin("true");
        localStorage.setItem(IS_LOGIN, "true");
        localStorage.setItem(USER_DATA, JSON.stringify(userData));
        localStorage.setItem(TOKEN, userData.token);
        localStorage.setItem(TOKEN_EXP, token_exp.toString());
        setUserData(userData);
        getUserAdventures(userData.email);
        if (actionAfterLogin) {
            actionAfterLogin(userData)
        }
    };

    const logout = () => {
        localStorage.clear();
        localStorage.setItem(NEW_LOGIN, "true"); // This is here just to make sure that user is not logged in with the previous version
        window.location.href = '/';
    };

    const creators = [BOSKO, AMITAI, MICAHL, YAEL, DEBBIE];
    const translators = [DEBBIE];
    const isCreator = creators.find(email => email === userData.email);
    const isTranslator = translators.find(email => email === userData.email);
    const provider = {
        isLogin,
        isAdmin,
        isCreator,
        isTranslator,
        register,
        login,
        logout,
        forgotPasswordRequest,
        resetPasswordRequest,
        updateUserDetails,
        userData,
        fullName: userData.name + " " + userData.lastName,
        isTom: userData.id === 19
    };

    if (isLoading) {
        return <div style={loadingWrapperStyle}>
            <YoudoAdventuresLogo/>
        </div>
    }

    return (
        <AuthContext.Provider value={provider}>
            {children || ""}
        </AuthContext.Provider>
    );
};

const loadingWrapperStyle = {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

// ADMINS //
const TOM = "tfellert@gmail.com";

// CREATORS //
const BOSKO = "hotportion@gmail.com";
const AMITAI = "congobongo@gmail.com";
const MICAHL = "mish.mesh@gmail.com";
const YAEL = "yaelweinberg@gmail.com";

// TRANSLATORS //
const DEBBIE = "shmebs@gmail.com";
