import React, {useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {updateAdventure} from "../../../../store/actions/adventure-actions";
import {fetchData} from "../../../../store/actions/fetch-data";
import {LanguageContext, Translation} from "../../../../languages";
import classNames from "classnames";
import _ from "lodash";
import {DICT} from "../../../../languages/dictionary-names";
import {ThemeContext} from "../../../../providers/theme-provider";
import {colors} from "../../../00_base/variables";
import {BackgroundImage, Paragraph} from "../../../01_atoms";
import {FieldText} from "../../../02_molecules";

const THUMBNAIL = "https://storage.googleapis.com/assets.youdoadventures.com/website/default_thumbnail.jpg";

export const ScreenUserItem = ({handleDeleteAdventure, adventure, minimized}) => {
    const [adventureData, setAdventureData] = useState(null);
    const {userLanguage} = useContext(LanguageContext);
    const dispatch = useDispatch();
    const {mode} = useContext(ThemeContext);
    const textColor = colors[mode === "light" ? "black" : "white"];

    const isSpaceAdventure = adventure?.adventuretype === 31;
    const thumbImage = adventure.thumbnail;
    const image = thumbImage || THUMBNAIL;

    const handleTitleChange = (name, value) => {
        dispatch(updateAdventure({...adventure, [name]: value}));
    };

    const parseToDate = (date) => {
        if (!_.isEmpty(date)) {
            date = new Date(date);
            const DAY = date.getDate();
            const MONTH = date.getMonth() + 1;
            const YEAR = date.getFullYear().toString().substr(-2);
            const HOURS = date.getHours();
            const MINUTES = date.getMinutes();
            const DATE = `${DAY}/${MONTH}/${YEAR}`;
            const TIME = `${HOURS < 10 ? "0" + HOURS : HOURS}:${MINUTES < 10 ? "0" + MINUTES : MINUTES}`;
            return `${DATE} | ${TIME}`;
        }
        return ""
    }
    const subdomain = isSpaceAdventure ? "space" : (adventure.subdomain || "play");
    const {is_paid} = adventure;
    const linkTo = is_paid ?
        `https://${subdomain}.youdoadventures.com/${adventure.id}` :
        isSpaceAdventure ? `/${userLanguage}/space-adventure/${adventure.id}` :
            `/${userLanguage}/create-adventure/${adventure.id}`;

    const renderButtons = () => {
        if (!minimized) {

            return (
                <div className="screen-user-item-details-footer">
                    {is_paid ?
                        <a className="screen-user-item-edit" href={linkTo} target={"_blank"} rel="noopener noreferrer">
                            <Translation id={DICT.PLAY}/>
                        </a>
                        :
                        <Link className="screen-user-item-edit" to={linkTo}>
                            <Translation id={DICT.EDIT}/>
                        </Link>
                    }
                    <div className={"screen-user-item-delete"}
                         onClick={() => handleDeleteAdventure(adventure.id)}>
                        <Translation id={DICT.DELETE}/>
                    </div>
                </div>
            )
        }
        return <div/>
    }

    useEffect(() => {
        if (adventure?.adventuredata_id) {
            fetchData("get",
                `adventureData/${adventure.adventuredata_id}`,
                {},
                (adventureData) => {
                    setAdventureData(adventureData)
                })
        }
    }, [adventure]);

    if (!adventure.adventuredata_id) {
        return <div/>
    }

    const TITLE = <FieldText initialValue={adventure.title}
                             value={adventure.title}
                             name={"title"}
                             changeHandler={handleTitleChange}
                             controlStyle={{border: 0, width: "100%"}}
                             className={classNames("screen-user-item-title", {"mb-0": minimized})}/>;

    const SUBTITLE = adventureData && adventureData.adventurename && !minimized &&
        <Paragraph className={"m-0"} color={textColor} fontSize={minimized ? "1.4rem" : "1.8rem"}>
            <Translation id={DICT.ADVENTURE_TYPE}/>: {adventureData.adventurename}<br/>
            <Translation id={DICT.PASSWORD}/>: {adventure.passcode}
        </Paragraph>

    const DATES = <div className={minimized ? "mt-0" : "mt-4"} style={{color:textColor}}>
        {!_.isEmpty(adventure.created_at) ?
            <span className="screen-user-item-created m-0 mt-2">
               <Translation id={DICT.CREATED_AT}/> {`${parseToDate(adventure.created_at)}`}
            </span> :
            <span className="screen-user-item-created m-0 mt-2">{`...`}</span>
        }

        {/* TODO: Add adventure.updated_at when it ready */}
        {/*{!_.isEmpty(adventure.updated_at) ?*/}
        {/*    <span className="screen-user-item-created m-0">{` עודכן ב-${parseToDate(adventure.updated_at)}`}</span> :*/}
        {/*    <span className="screen-user-item-created m-0">{`, אין תאריך עדכון`}</span>*/}
        {/*}*/}
    </div>

    return (
        <div key={adventure.id}
             className={classNames("screen-user-item", {minimized})}
             data-id={adventure.id}
             onClick={() => minimized ? window.location.href = linkTo : {}}>


            <div className={"screen-user-item-image"}>
                <BackgroundImage width={minimized ? "50px" : "300px"}
                                 height={minimized ? "40px" : "167px"}
                                 size="cover"
                                 url={image}/>
            </div>

            <div className={"screen-user-item-details"}>
                <div className={classNames("screen-user-item-details-header", {"px-3": minimized, "pb-0": minimized})}>
                    <div className={"screen-user-item-details-titles"}>
                        {TITLE}
                        {SUBTITLE}
                        {DATES}
                    </div>
                </div>

                {renderButtons()}
            </div>
        </div>
    )
}