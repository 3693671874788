import React from 'react';
import { font_size } from '../../00_base/variables';
 
class Timer extends React.Component {
    constructor(props) {
        super(props);

        const seconds = parseInt(localStorage.getItem('seconds'));
        const minutes = parseInt(localStorage.getItem('minutes'));

        this.state = { seconds: seconds ? seconds : 0, minutes: minutes ? minutes > 100 ? 0 : minutes : 0 };
    }

    tick() {
        this.setState(prevState => ({
            seconds: prevState.seconds + 1 <= 59 ? prevState.seconds + 1 : 0 ,
            minutes: prevState.seconds === 59 ? prevState.minutes + 1 : prevState.minutes
        }));
    }

    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {

        const {seconds, minutes} = this.state;
        const {fontSize, color} = this.props;
        const time = `${seconds < 10 ? `0${seconds}` : seconds} : ${minutes < 10 ? `0${minutes}` : minutes}`;
        const timerTextStyle = {color: color || '#fff', fontSize: fontSize || font_size.sm, margin: 0, textAlign:'left', whiteSpace: 'nowrap'};
        const timerStyle = {width: "50px", fontWeight: 'bold'};
        localStorage.setItem("seconds", seconds);
        localStorage.setItem("minutes", minutes);

        return (
            <div style={timerStyle}>
                <p style={timerTextStyle}>
                    {time}
                </p>
            </div>
        );
    }
}

export default Timer;