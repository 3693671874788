import React from 'react';

class IconTrash extends React.Component {
    render() {
        const {color, width, height, onClick, style, index, className} = this.props;
        return (
                <svg width={width} height={height} className={className} viewBox="0 0 25 28" version="1.1" xmlns="http://www.w3.org/2000/svg" onClick={onClick && onClick} data-index={index && index} color={color} style={{...style && style, cursor: 'pointer'}}>
                    <g id="עיצוב" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="delete-black" fill={color}>
                            <path d="M3.69230769,6 L5.05725022,25.4626667 C5.15683729,26.8882493 6.25879631,27.9925433 7.58921861,28 L19.0410864,28 C20.3928009,27.9988612 21.5106242,26.8683854 21.593529,25.4186667 L22.6923077,6 L3.69230769,6 Z M11.1448939,22.258 C11.1448939,22.5941573 10.8912851,22.8666667 10.5784427,22.8666667 L9.66393126,22.8666667 C9.35108892,22.8666667 9.09748011,22.5941573 9.09748011,22.258 L9.09748011,10.2753333 C9.09748011,9.93917602 9.35108892,9.66666667 9.66393126,9.66666667 L10.5784427,9.66666667 C10.8912851,9.66666667 11.1448939,9.93917602 11.1448939,10.2753333 L11.1448939,22.258 Z M17.2871353,22.258 C17.2871353,22.5941573 17.0335265,22.8666667 16.7206841,22.8666667 L15.8061726,22.8666667 C15.4933303,22.8666667 15.2397215,22.5941573 15.2397215,22.258 L15.2397215,10.2753333 C15.2397215,9.93917602 15.4933303,9.66666667 15.8061726,9.66666667 L16.7206841,9.66666667 C17.0335265,9.66666667 17.2871353,9.93917602 17.2871353,10.2753333 L17.2871353,22.258 Z" id="Shape" fillRule="nonzero"/>
                            <path d="M16.9276018,1.07142857 C16.9276018,0.479694911 16.4535504,0 15.8687783,0 L9.5158371,0 C8.93106502,0 8.45701357,0.479694911 8.45701357,1.07142857 C8.45701357,1.66316223 7.98296213,2.14285714 7.39819005,2.14285714 L1.75113122,2.14285714 C1.16635913,2.14285714 0.692307692,2.62255205 0.692307692,3.21428571 L0.692307692,3.92857143 C0.692307692,4.52030509 1.16635913,5 1.75113122,5 L23.6334842,5 C24.2182563,5 24.6923077,4.52030509 24.6923077,3.92857143 L24.6923077,3.21428571 C24.6923077,2.62255205 24.2182563,2.14285714 23.6334842,2.14285714 L17.9864253,2.14285714 C17.4016533,2.14285714 16.9276018,1.66316223 16.9276018,1.07142857 Z" id="Path"/>
                        </g>
                    </g>
                </svg>
        )
    }

    static defaultProps = {
        color: '#151515',
        width: '25px',
        height: '28px'
    }
}

export default IconTrash;
