import React, { useContext, useEffect, useState } from "react";
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import { Col, Row } from "react-grid-system";
import { useNavigate } from "react-router-dom";
import { Element } from "react-stylesheet";
import { colors, font_size } from "../../../00_base/variables";
import {
  Button,
  Heading,
  Image,
  LoaderPageBulletList,
} from "../../../01_atoms";
import { Modal } from "../../../03_organisms/";
import classNames from "classnames";
import { LoadingSection } from "../../../03_organisms/loading-sections/loading-section";
import "../../../../assets/styles/riddles-store.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllTags,
  selectShowInStoreRiddles,
} from "../../../../store/selectors";
import { setShowInStoreRiddles } from "../../../../store/actions/riddle-actions";
import { RiddleThumbnail } from "./riddle-thumbnail";
import { RiddleStoreHeader } from "./riddles-store-header";
import { RiddlesStoreFilter } from "./riddles-store-filter";
import { ScrollContext } from "../../../04_layout/scroll-to-top/l-scroll-to-top";
import { LanguageContext } from "../../../../languages";
import { RiddleModal } from "./riddle-modal";
import { RiddleAnswerModal } from "./riddle-answer-modal";
import { AuthContext } from "../../../../providers/auth-provider";

const RiddleStore = () => {
  const navigate = useNavigate();
  const { y } = useContext(ScrollContext);
  const { userLanguage } = useContext(LanguageContext);
  const { isAdmin } = useContext(AuthContext);
  const allTags = useSelector(selectAllTags);
  const showInStoreRiddles = useSelector(selectShowInStoreRiddles);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowInStoreRiddles(0));
  }, [dispatch]);

  const [isLoading, setIsLoading] = useState(true);
  const [modalActive, setModalActive] = useState(false);
  const [answerModalActive, setAnswerModalActive] = useState(false);
  const [riddlesStore, setRiddlesStore] = useState(["", "", "", "", "", ""]);
  const [activeGroup, setActiveGroup] = useState(0);
  const [levelFilter, setLevelFilter] = useState([]);
  const [tagsFilter, setTagsFilter] = useState([]);

  useEffect(() => {
    if (showInStoreRiddles?.length) {
      fetch(
        "https://admin.youdoadventures.com/wp-json/wp/v2/riddles?_embed&per_page=100",
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((success) => {
          const riddles = success.map((item) => {
            return { ...item.acf, thumbnail: item.acf.file?.url };
          });
          const storeRiddles = showInStoreRiddles
            .filter((riddle) => isAdmin || riddle.is_published)
            .map((riddle) => {
              return { ...riddle, tags: riddle.tags.join() };
            });
          setRiddlesStore(storeRiddles.concat(riddles));
          setIsLoading(false);
        })
        .catch((error) => console.log(error));
    }
  }, [showInStoreRiddles]);

  const handleRiddleClick = (riddleData) => {
    if (riddleData.id) {
      navigate(`/${userLanguage}/single-riddle/${riddleData.id}`);
    } else {
      setModalActive(riddleData);
    }
  };

  const closeModal = () => {
    setAnswerModalActive(false);
    setModalActive(false);
  };

  const handleAnswerClick = (item) => {
    setAnswerModalActive(item);
  };

  const hideAnswerModal = (e) => {
    setAnswerModalActive(false);
    setModalActive(null);
  };

  const renderDigitalRiddlesGrid = (riddle, index) => {
    return (
      <Row
        key={index}
        className="p-md-5 p-3 align-items-center" //Col sm={6} md={homepage ? 4 : 6} lg={4} className='p-md-5 p-3'
        style={{ cursor: "pointer" }}
      >
        {isLoading ? (
          <LoadingSection />
        ) : (
          <RiddleThumbnail
            riddle={riddle}
            handleFilterChange={handleFilterChange}
            handleRiddleClick={handleRiddleClick}
            handleAnswerClick={handleAnswerClick}
          />
        )}
      </Row>
    );
  };

  const Navigate = () => {
    const prevDisabled = activeGroup <= 0;
    const nextDisabled = (activeGroup + 1) * 4 > filterGroup.length;
    return (
      <div className={"d-flex justify-content-center"}>
        <div
          className={classNames("riddles-store-nav prev", {
            disabled: prevDisabled,
          })}
          onClick={() => !prevDisabled && setActiveGroup(activeGroup - 1)}
        >
          <MdNavigateNext size={24} />
        </div>
        <div
          className={classNames("riddles-store-nav next", {
            disabled: nextDisabled,
          })}
          onClick={() => !nextDisabled && setActiveGroup(activeGroup + 1)}
        >
          <MdNavigateBefore size={24} />
        </div>
      </div>
    );
  };

  const handleFilterChange = (filterName, value) => {
    const toggleItem = (items, value) => {
      const isExist = items.some((item) => item === value);
      return isExist
        ? items.filter((item) => item !== value)
        : items.concat([value]);
    };
    setActiveGroup(0);
    switch (filterName) {
      case "level":
        const updatedLevels = toggleItem(levelFilter, value);
        setLevelFilter(updatedLevels);
        return;
      case "tags":
        setTagsFilter(value);
        return;
    }
  };

  const filterByLevel = (riddle) =>
    !levelFilter.length ||
    levelFilter.some((item) => item === parseInt(riddle.level));
  const filterByTags = (riddle) => {
    if (riddle.tags) {
      const splitTags = riddle.tags.split(",");
      const getTagById = (id) => allTags.find((tag) => tag.id === parseInt(id));
      const updatedTags = splitTags
        .map((tagItem) =>
          !isNaN(tagItem) ? getTagById(tagItem)?.title : tagItem
        )
        .join();
      const isTagExist = (tag) => updatedTags.includes(tag);
      return !tagsFilter.length || tagsFilter.some(isTagExist);
    }
    return true;
  };
  const filterGroup = riddlesStore.filter(filterByTags).filter(filterByLevel);
  const sliceGroup = filterGroup.slice(activeGroup * 4, activeGroup * 4 + 4);
  const renderRiddles = sliceGroup.map(renderDigitalRiddlesGrid);
  const isSticky = renderRiddles.length > 0 && y < -600;

  return (
    <div className={classNames("riddle-store", { sticky: isSticky })}>
      {!!modalActive && (
        <RiddleModal riddle={modalActive} closeModal={closeModal} />
      )}

      {!!answerModalActive && (
        <RiddleAnswerModal
          riddle={answerModalActive}
          closeModal={hideAnswerModal}
        />
      )}

      <div className="container">
        <RiddleStoreHeader />
      </div>

      <div className="container" style={{ minHeight: "60vh" }}>
        <Row className={`scroll-element justify-content-between`}>
          <Col lg={3} className={classNames("riddles-store-filter-wrapper")}>
            {isLoading ? (
              <LoaderPageBulletList items={6} />
            ) : (
              <RiddlesStoreFilter
                levelFilter={levelFilter}
                tagsFilter={tagsFilter}
                handleChange={handleFilterChange}
              />
            )}
          </Col>
          <Col lg={9} className="riddles-store-riddles-wrapper">
            {renderRiddles}
          </Col>
        </Row>

        {filterGroup.length > 4 && <Navigate />}
      </div>
    </div>
  );
};

export default RiddleStore;
