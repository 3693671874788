import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import * as themesActions from '../../../../store/actions/themes-actions';
import TailLoader from '../../../01_atoms/loaders/tail-loader';
import {Page} from '../../../04_layout/index';
import {Element} from 'react-stylesheet';
import {gutters} from '../../../00_base/variables';
import {HBox} from 'react-stylesheet/lib/Box';
import Gallery from "../../../03_organisms/gallery/o-gallery";

const ScreenThemesGallery = props => {
    const [allThemes, setAllThemes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
     const [error, setError] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        try {
            dispatch(themesActions.getAllThemes()).then(response => {
                setAllThemes(response);
                setIsLoading(null);
            });
        } catch (err) {
            setError(err.message);
            setIsLoading(null);
        }
    }, [dispatch]);

    if (isLoading === true) {
        return (
            <Page {...props}>
                <HBox justifyContent={'center'} width={'100%'} paddingTop={gutters.xxxl}>
                    <TailLoader />
                </HBox>
            </Page>
        )
    }

    if (error) {
        return (
            <div>{error}</div>
        )
    }

    return (
        <Gallery label={"תבנית צבעים"}
                 name={"theme"}
                 apiName={'themes'}
                 title={"תבניות צבעים"}
                 items={allThemes.map(item => {
                     return {
                         ...item,
                         title: item.key,
                         previewImage:  <HBox flexWrap="wrap">
                             <Element background={item.main} width="100%" height="20"/>
                             <Element background={item.sub} width="100%" height="20"/>
                             <Element background={item.text} width="100%" height="20"/>
                             <Element background={item.buttons} width="100%" height="20"/>
                             <Element background={item.fifth} width="100%" height="20"/>
                         </HBox>
                     }
                 })}
                 viewMode={"table"}/>
    )
};

export default ScreenThemesGallery;