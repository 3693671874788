import React from 'react';

const GoogleMapsView = (props) => {
    const {src} = props;
    return (
        <div className={'map'}>
            <iframe src={src} title={"google-map"} width="100%" height="450" frameBorder="0" style={{border:0}} allowFullScreen/>
        </div>
    )
};

export {GoogleMapsView};