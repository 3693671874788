const zIndex = {
    hidden: -1, 
    base: 0,
    medium: 10,
    high: 100,
    header: 500,
    modal: 1000,
    max: 2000, 
};

export default zIndex;