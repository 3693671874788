import React from "react";
import {
    MdArrowBack, MdArrowDownward,
    MdArrowForward, MdArrowUpward, MdImage,
    MdLineStyle,
    MdVerticalAlignBottom,
    MdVerticalAlignTop,
    MdViewModule,
    MdViewStream
} from "react-icons/md";
import {FieldCheckbox, FieldColor, FieldOptions, FieldText, FilesUploader, ToolTip} from "../../../02_molecules";

class Option {
    constructor(id, value, Icon) {
        this.id = id;
        this.value = value;
        this.label = <ToolTip placeholder={<Icon size={22}/>} tip={value} id={`ui-option-${value}`}/>
    }
}

export const CreateToolRotariesCommon = (
    {
        handleToolDataChange,
        direction,
        showNumbers,
        hideZero,
        rotaryBorderWidth,
        rotaryBorderColor,
        rotaryBorderRadius,
        numbersInside,
        startLocation,
        size,
        rotaryWidth,
        rotarySize,
        rotaryDotStyle,
        numbersSize,
        numbersColor,
        numbersScale,
        itemBackgroundImage,
        dotCustomImage,
        degressAddOnClick = 4
    }) => {
    const directionOptions = [
        new Option(0, "row", MdViewModule),
        new Option(1, "column", MdViewStream),
    ];
    const startOptions = [
        new Option(0, "top", MdVerticalAlignTop),
        new Option(1, "bottom", MdVerticalAlignBottom),
    ];

    const dotStyleOptions = [
        new Option(0, "dot", MdViewStream),
        new Option(1, "arrow", MdArrowUpward),
        new Option(2, "outArrow", MdArrowDownward),
        new Option(3, "line", MdLineStyle),
        new Option(4, "custom", MdImage),
    ];

    const directionValue = directionOptions.find(option => option.value === direction) || directionOptions[0];
    const startLocationValue = startOptions.find(option => option.value === startLocation) || startOptions[0];
    const dotStyleValue = dotStyleOptions.find(option => option.value === rotaryDotStyle) || dotStyleOptions[0];

    const controlStyle = {display: "flex"};
    const showNumbersLabel = "הראה מספרים";
    const hideZeroLabel = "הסתר 0";
    const numbersInsideLabel = "מספרים בפנים";

    return (
        <>
            <FieldOptions label="כיוון"
                          name="direction"
                          controlStyle={controlStyle}
                          changeHandler={handleToolDataChange}
                          options={directionOptions}
                          value={directionValue}/>

            <FieldText label="כמה מספרים?"
                       type="number"
                       name="degressAddOnClick"
                       changeHandler={handleToolDataChange}
                       value={degressAddOnClick}/>

            <FieldOptions label="מיקום התחלה"
                          type="number"
                          changeHandler={handleToolDataChange}
                          options={startOptions}
                          name={"startLocation"}
                          controlStyle={controlStyle}
                          value={startLocationValue}/>

            <FieldCheckbox changeHandler={handleToolDataChange}
                           options={[showNumbersLabel]}
                           name={"showNumbers"}
                           value={showNumbers ? [showNumbersLabel] : []}/>

            {showNumbers &&
            <>
                <FieldColor changeHandler={handleToolDataChange}
                            label="צבע מספרים"
                            name={"numbersColor"}
                            value={numbersColor}
                            controlStyle={controlStyle}/>
                <FieldText type="number"
                           label="גודל מספרים"
                           changeHandler={handleToolDataChange}
                           name={"numbersSize"}
                           value={numbersSize}/>
                <FieldText changeHandler={handleToolDataChange}
                           name={"numbersScale"}
                           value={numbersScale}
                           label={"היקף מספרים"}/>
            </>
            }

            <FieldCheckbox changeHandler={handleToolDataChange}
                           options={[hideZeroLabel]}
                           name={"hideZero"}
                           value={hideZero ? [hideZeroLabel] : []}/>

            <FieldCheckbox changeHandler={handleToolDataChange}
                           options={[numbersInsideLabel]}
                           name={"numbersInside"}
                           value={numbersInside ? [numbersInsideLabel] : []}
                           controlStyle={controlStyle}/>

            <FieldText label="גודל"
                       name="size"
                       type="number"
                       value={size}
                       changeHandler={handleToolDataChange}
                       controlStyle={controlStyle}/>

            <FieldColor label="צבע מסגרת"
                        name="rotaryBorderColor"
                        value={rotaryBorderColor}
                        changeHandler={handleToolDataChange}
                        controlStyle={controlStyle}/>

            <FieldText label="עובי מסגרת"
                       name="rotaryBorderWidth"
                       type="number"
                       value={rotaryBorderWidth}
                       changeHandler={handleToolDataChange}
                       controlStyle={controlStyle}/>

            <FieldText label="עובי חוגה"
                       name="rotaryWidth"
                       type="number"
                       value={rotaryWidth}
                       changeHandler={handleToolDataChange}
                       controlStyle={controlStyle}/>

            <FieldText label="גודל חוגה"
                       name="rotarySize"
                       type="number"
                       value={rotarySize}
                       changeHandler={handleToolDataChange}
                       controlStyle={controlStyle}/>

            <div className={"d-flex align-items-end mb-4"}>
                <FieldOptions label="סגנון נקודה"
                              changeHandler={handleToolDataChange}
                              options={dotStyleOptions}
                              labelStyle={{marginBottom:"8px"}}
                              name="rotaryDotStyle"
                              controlStyle={{width:"auto", marginBottom:0}}
                              value={dotStyleValue}/>

                {dotStyleValue.value === "custom" &&
                <FilesUploader name={"dotCustomImage"}
                               previewWidth={"100px"}
                               previewHeight={"100px"}
                               controlStyle={{padding:0}}
                               value={dotCustomImage}
                               multiple={false}
                               changeHandler={handleToolDataChange}/>
                }
            </div>
            
            <FieldText label="עיגוליות"
                       name="rotaryBorderRadius"
                       type="number"
                       value={rotaryBorderRadius}
                       changeHandler={handleToolDataChange}
                       controlStyle={controlStyle}/>

            <FilesUploader multiple={false}
                           label="תמונת רקע לבוררים"
                           triggerValue="תמונת רקע לבוררים"
                           name="itemBackgroundImage"
                           value={itemBackgroundImage}
                           changeHandler={handleToolDataChange}/>
        </>
    )
};

