import React, {useContext} from 'react';
import {FrameContext} from "../../riddle/template/single-frame";
import {FrameModalContext} from "../../riddle/template/single-frame-body/frame-modals/frame-modal";
import {FieldText, FieldTextArea} from "../../../02_molecules";
import "../../../../assets/styles/tool-instructions.scss";

const initialInstructions = {
    title: '',
    subtitle: '',
    description: '',
};

const SingleFrameInstructions = ({instructions = initialInstructions}) => {
    const {handleFrameChange} = useContext(FrameContext);
    const {handleModalChange} = useContext(FrameModalContext);

    const handleFieldChange = (name, value) => {
        const changeHandler = handleModalChange || handleFrameChange;
        changeHandler("instructions", {...instructions, [name]: value})
    };
    
    const {title, subtitle, description} = instructions;

    return (
        <div className="single-frame-section">
            <div className="instructions">
                <div className="instructions-wrapper">
                    <label className="instructions-title">הנחיות</label>
                    <div className="instructions-body">
                        <FieldText label="כותרת"
                                   changeHandler={handleFieldChange}
                                   value={title}
                                   name="title"/>

                        <FieldText label="כותרת משנה"
                                   changeHandler={handleFieldChange}
                                   value={subtitle}
                                   name="subtitle"/>

                        <FieldTextArea label="תיאור"
                                       changeHandler={handleFieldChange}
                                       value={description}
                                       name="description"/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SingleFrameInstructions;