import React, {useContext, useReducer} from 'react';
import PropTypes from 'prop-types';
import {HBox} from 'react-stylesheet';
import Label from '../../../01_atoms/text/a-label';
import Span from '../../../01_atoms/text/a-span';
import {fieldStyle} from '../field-style/m-field-style';
import {colors, gutters} from '../../../00_base/variables';
import {MdCheck} from 'react-icons/md';
import SimpleLoader from '../../../01_atoms/loaders/simple-loader/simple-loader';
import {LanguageContext} from "../../../../languages";

const TOGGLE_CHECKBOX = 'TOGGLE_CHECKBOX';

const inputReducer = (state, action) => {
    switch (action.type) {
        case TOGGLE_CHECKBOX:
            let updatedState;
            if (state.value.find(opiton => opiton === action.value)) {
                updatedState = state.value.filter(option => option !== action.value);
            } else {
                updatedState = state.value.concat(action.value);
            }

            return {
                value: updatedState,
                isValid: action.isValid
            };
        default:
            return state
    }
};

const FieldCheckbox = ({
                           name,
                           label,
                           options,
                           dir,
                           size = 20,
                           required,
                           inputBackground = "#fff",
                           inputBackgroundActive = "#5388ff",
                           inputBorder = "2px solid #5388ff",
                           errorMessage,
                           changeHandler,
                           value,
                           inputStyle,
                           initiallyValid,
                           labelStyle,
                           marginLeft,
                           marginRight,
                           hideOptionLabel,
                           controlStyle = {},
                           checkboxLoading
                       }) => {
    const {rtl} = useContext(LanguageContext);

    const [inputState, dispatch] = useReducer(inputReducer, {
        value: value,
        isValid: initiallyValid,
    });

    const handleInputChange = event => {
        event.stopPropagation();
        const isChecked = event.target.checked;
        let isValid = true;
        if (required && isChecked.trim().length === 0) {
            isValid = false;
        }

        dispatch({
            type: TOGGLE_CHECKBOX,
            value: event.target.value,
            isValid: isValid
        });
        changeHandler(name, isChecked, isValid);
    };

    return (
        <div style={{...fieldStyle.control, ...controlStyle}}>
            {!!label && <Label style={fieldStyle.label} value={label}/>}

            <div style={{...checkboxesContainer, ...fieldStyle[dir], marginRight: 0}}>
                {options.map((option, index) => {
                    const checked = !!inputState.value.find(item => item === option) && 'checked';
                    return <div key={index}
                                style={{
                                    ...fieldStyle.input, ...inputStyle, ...checkboxWrapper,
                                    marginRight: index === 0 ? 0 : '1rem'
                                }}>
                        <label style={{...checkboxLabel, ...labelStyle}}>
                            <HBox
                                justifyContent={'center'}
                                alignItems={'center'}
                                width={`${size}px`}
                                height={`${size}px`}
                                borderRadius={'2px'}
                                border={inputBorder}
                                style={{
                                    cursor: 'pointer',
                                    marginLeft: marginLeft || (rtl ? gutters.sm : 0),
                                    marginRight: marginRight || (rtl ? 0 : gutters.sm)
                                }}
                                position={'relative'}
                                transition={'background .3s'}
                                background={checked ? inputBackgroundActive : inputBackground}>

                                <input
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        cursor: 'pointer',
                                        opacity: 0,
                                        margin: 0
                                    }}
                                    type={'checkbox'}
                                    checked={checked}
                                    name={name}
                                    value={option}
                                    onChange={handleInputChange}
                                />
                                {checkboxLoading ?
                                    <SimpleLoader size={20}/> :
                                    !!inputState.value.find(item => item === option) &&
                                    'checked' &&
                                    <MdCheck size={20} color={colors.white}/>
                                }
                            </HBox>
                            {!hideOptionLabel && option}
                        </label>
                    </div>
                })}
            </div>

            {!inputState.isValid && <Span value={errorMessage}/>}
        </div>
    )
}

const
    checkboxesContainer = {},
    checkboxWrapper = {
        display: 'flex',
        marginRight: '1rem',
        padding: 0,
        border: 0
    },
    checkboxLabel = {
        display: 'flex',
        alignItems: 'center'
    };

FieldCheckbox.defaultProps = {
    value: [],
    initiallyValid: true,
    dir: 'horizontal'
};

FieldCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    style: PropTypes.object,
    // value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    options: PropTypes.array.isRequired,
    dir: PropTypes.oneOf(['horizontal', 'vertical']),
    errorMessage: PropTypes.string,
    changeHandler: PropTypes.func.isRequired,
    value: PropTypes.array,
    initiallyValid: PropTypes.bool,
    required: PropTypes.bool,
};

export default FieldCheckbox; 