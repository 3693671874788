import React, {useContext, useState} from "react";
import classNames from "classnames";
import {SingleFrameDesignColor} from "./single-frame-design-color";
import {SingleFrameDesignImage} from "./single-frame-design-image";
import {CssGeneral} from "../../../../ui/css-general/css-general";
import {FrameContext} from "../../single-frame";

export const SingleFrameDesign = ({categorydata}) => {
    const {handleToolDataChange} = useContext(FrameContext);

    const [isOpen, setIsOpen] = useState(false);
    const {toolWrapperStyle} = categorydata;

    const handleCssChange = (name, value) => {
        handleToolDataChange("toolWrapperStyle", {...toolWrapperStyle, [name]: value});
    }
    return (
        <div className={"single-frame-box"}>
            <h4 className="single-frame-box-title" onClick={() => setIsOpen(!isOpen)}>עיצוב מסך</h4>
            <div className={classNames("single-frame-box-content additional-data-body", {active: isOpen})}>
                <SingleFrameDesignColor {...categorydata}/>
                <SingleFrameDesignImage {...categorydata}/>
                <CssGeneral title="עיצוב קונטיינר"
                            defaultProps={toolWrapperStyle}
                            changeHandler={handleCssChange}/>
            </div>
        </div>
    )
};