import React, {useContext, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import styled from "styled-components";
import {LanguageContext} from "../../../../languages";
import {FieldControl, FieldInput, FieldLabel, fieldStyle} from '../field-style/m-field-style';
import {colors, font_size} from "../../../00_base/variables";
import {Heading, Label, Span} from '../../../01_atoms';
import {RouteContext} from "../../../04_layout/app-content/l-app-content";

const INPUT_CHANGE = 'INPUT_CHANGE';
const INPUT_BLUR = 'INPUT_BLUR';

const inputReducer = (state, action) => {
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid,
                blur: false
            };
        case INPUT_BLUR:
            return {
                ...state,
                blur: true
            };
        default:
            return state
    }
};

const FieldText = (
    {
        name,
        type,
        label,
        className,
        note,
        noteStyle,
        errorMessage,
        changeHandler,
        keydownHandler,
        inputId = "",
        labelStyle,
        inputStyle,
        inputStyleWithValue,
        controlStyle,
        value,
        initiallyValid,
        placeholder,
        dir,
        required,
        email,
        warningStyle,
        min,
        max,
        minLength,
        errorMessageActive,
        mode,
        sideIcon,
        backgroundFocus,
        readOnly,
        marginBottom,
        blurHandler,
        invalid
    }) => {
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: value || '',
        isValid: initiallyValid,
        blur: false
    });
    // useEffect(() => {
    //     if (inputState.blur && inputState.value !== value) {
    //         changeHandler(name, inputState.value, inputState.isValid);
    //     }
    // }, [changeHandler, inputState, name, value]);

    useEffect(() => {
        dispatch({
            type: INPUT_CHANGE,
            value: value,
            isValid: true
        })
    }, [value]);

    const {rtl} = useContext(LanguageContext);
    const {admin, adminInsideWebsite} = useContext(RouteContext);

    const handleInputChange = event => {
        const value = event.target.value;
        const emailRegex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = true;
        if (required && value.trim().length === 0) {
            isValid = false;
        }
        if (email && !emailRegex.test(value.toLowerCase())) {
            isValid = false;
        }
        if (min != null && +value < min) {
            isValid = false;
        }
        if (max != null && +value > max) {
            isValid = false;
        }
        if (minLength != null && value.length < minLength) {
            isValid = false;
        }
        dispatch({
            type: INPUT_CHANGE,
            value: value,
            isValid: isValid
        })
    };

    const handleInputBlur = () => {
        dispatch({type: INPUT_BLUR});
        changeHandler(name, inputState.value, inputState.isValid);

        if (blurHandler) {
            blurHandler(name, inputState.value);
        }
    };


    if (mode === 'read') {
        return (
            <FieldControl style={{...fieldStyle[dir], ...controlStyle, display: type === 'hidden' ? 'none' : 'flex'}}>
                {/*<Heading tag={'h4'} style={{margin: 0}}>*/}
                    {!!label &&
                    <Label style={{...fieldStyle.label, ...labelStyle, display: 'inline', fontWeight: 'normal'}}
                           value={label}/>}{inputState.value.toString()}
                {/*</Heading>*/}
            </FieldControl>
        )
    }
    const hasValue = !!inputState.value || inputState.value?.length > 0;

    return (
        <FieldControl style={{...fieldStyle[dir], ...controlStyle, display: type === 'hidden' ? 'none' : 'flex'}}
                      marginBottom={marginBottom} className={className}>

            {!!label && (admin || adminInsideWebsite) &&
            <FieldLabel style={labelStyle}>
                {label}
            </FieldLabel>}

            <FieldInput {...(inputId ? {id: {inputId}} : {})}
                        backgroundFocus={backgroundFocus}
                        placeholder={placeholder}
                        type={type}
                        min={min}
                        max={max}
                        className={classNames({focus: hasValue})}
                        onChange={handleInputChange}
                        onKeyDown={keydownHandler && keydownHandler}
                        onBlur={handleInputBlur}
                        value={inputState.value?.toString() || ""}
                        readOnly={readOnly}
                        style={{borderBottom: invalid && `2px solid ${colors.orange}`, ...inputStyle, ...hasValue && inputStyleWithValue}}/>

            {!!label && !admin && !adminInsideWebsite &&
            <FieldLabel style={{...labelStyle, paddingRight:invalid && "10px"}}>{label}</FieldLabel>}

            {sideIcon && <FieldIcon>{sideIcon}</FieldIcon>}

            {note && <Span value={note} style={noteStyle}/>}

            {errorMessage && <Span
                block
                fontSize={font_size.xs}
                color={'#7b2115'}
                style={{
                    marginTop: ".4rem",
                    visibility: (!inputState.isValid || errorMessageActive) ? "visible" : "hidden",
                    textAlign: rtl ? "right" : "left",
                    ...warningStyle
                }}

                value={errorMessage}/>}
        </FieldControl>
    )
};
const FieldIcon = styled.div`
    position: absolute;
    left: 5px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 10
`;

FieldText.defaultProps = {
    type: 'text',
    value: '',
    initialValue: '',
    dir: 'vertical',
    initiallyValid: true
};

FieldText.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'number', 'hidden', 'password']),
    style: PropTypes.object,
    errorMessage: PropTypes.string,
    dir: PropTypes.oneOf(['horizontal', 'vertical']),
    changeHandler: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    initiallyValid: PropTypes.bool,
    required: PropTypes.bool,
    email: PropTypes.bool,
    min: PropTypes.bool,
    max: PropTypes.bool,
    minLength: PropTypes.number,
};

export default FieldText; 