import React, {useReducer, useEffect} from 'react';
import PropTypes from 'prop-types';
import Label from '../../../01_atoms/text/a-label';
import Span from '../../../01_atoms/text/a-span';
import {fieldStyle, FieldInput, FieldControl} from '../field-style/m-field-style';
import {Heading} from '../../../01_atoms';

const FieldText = ({
                       name,
                       type,
                       label,
                       className,
                       note,
                       noteStyle,
                       errorMessage,
                       errorMessageActive,
                       changeHandler,
                       keydownHandler,
                       labelStyle,
                       inputStyle,
                       borderRadius,
                       controlStyle,
                       initialValue,
                       initiallyValid,
                       placeholder,
                       dir,
                       required,
                       email,
                       min,
                       max,
                       minLength,

                       mode,
                       backgroundFocus,
                       readOnly,
                       marginBottom,
                       blurHandler,
                       invalid,
                   }) => {
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: !!initialValue ? initialValue : '',
        isValid: initiallyValid,
        blur: false
    });

    useEffect(() => {
        if (inputState.blur) {
            changeHandler(name, inputState.value, inputState.isValid);
        }
    }, [inputState, changeHandler, name]);

    const handleInputChange = event => {
        const value = event.target.value;
        const emailRegex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = true;
        if (required && value.trim().length === 0) {
            isValid = false;
        }
        if (email && !emailRegex.test(value.toLowerCase())) {
            isValid = false;
        }
        if (min != null && +value < min) {
            isValid = false;
        }
        if (max != null && +value > max) {
            isValid = false;
        }
        if (minLength != null && value.length < minLength) {
            isValid = false;
        }
        dispatch({
            type: INPUT_CHANGE,
            value: value,
            isValid: isValid
        })
    };

    const handleInputBlur = () => {
        dispatch({type: INPUT_BLUR});
        if (blurHandler) {
            blurHandler(name, inputState.value);
        }
    };

    if (mode === 'read') {
        return (
            <FieldControl style={{...fieldStyle[dir], ...controlStyle, display: type === 'hidden' ? 'none' : 'flex'}}>
                <Heading tag={'h4'} style={{margin: 0}}>
                    {!!label &&
                    <Label style={{...fieldStyle.label, ...labelStyle, display: 'inline', fontWeight: 'normal'}}
                           value={label}/>}: {inputState.value.toString()}
                </Heading>
            </FieldControl>
        )
    }

    return (
        <FieldControl
            style={{...fieldStyle[dir], ...controlStyle, display: type === 'hidden' ? 'none' : 'flex'}}
            marginBottom={marginBottom}
            className={className}>
            {!!label && <Label style={{...fieldStyle.label, ...labelStyle}} value={label}/>}
            <FieldInput
                backgroundFocus={backgroundFocus}
                placeholder={placeholder}
                type={type}
                onChange={handleInputChange}
                onKeyDown={keydownHandler && keydownHandler}
                onBlur={handleInputBlur}
                value={inputState.value.toString()}
                readOnly={readOnly}
                style={{...inputStyle, border: invalid && "1px solid red"}}/>
            {note && <Span value={note} style={noteStyle}/>}
            {(!inputState.isValid || errorMessageActive) && <Span color={'red'} value={errorMessage}/>}
        </FieldControl>
    )
};

const INPUT_CHANGE = 'INPUT_CHANGE';

const INPUT_BLUR = 'INPUT_BLUR';

const inputReducer = (state, action) => {
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid
            };
        case INPUT_BLUR:
            return {
                ...state,
                blur: true
            };
        default:
            return state
    }
};

FieldText.defaultProps = {
    type: 'text',
    value: '',
    initialValue: '',
    dir: 'vertical',
    initiallyValid: true
};

FieldText.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'number', 'hidden']),
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    errorMessage: PropTypes.string,
    dir: PropTypes.oneOf(['horizontal', 'vertical']),
    changeHandler: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    initiallyValid: PropTypes.bool,
    required: PropTypes.bool,
    email: PropTypes.bool,
    min: PropTypes.bool,
    max: PropTypes.bool,
    minLength: PropTypes.number,
};

export default FieldText; 