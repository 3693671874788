import React, {useState} from 'react';
import {MdClose} from "react-icons/md";
import {FieldText, FieldTextArea, FilesUploader} from "../../../02_molecules";
import {ToggleIcon, ColorPickerBgText} from "../../../03_organisms";
import {Td, Tr} from "../../../03_organisms";

export const CreateDefinerSection = (
    {
        index,
        handleChange,
        removeSection,
        title,
        description,
        image,
        bgColor,
        textColor
    }
) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <Tr className="magdir-section">
            <Td vAlign={"top"}>
                {index + 1}
            </Td>
            <Td vAlign={"top"} style={{minWidth:"330px"}}>
                <FieldText placeholder={`הכנס כותרת`}
                           value={title}
                           index={index}
                           controlStyle={controlStyle}
                           inputStyle={inputStyle}
                           name={'title'}
                           changeHandler={handleChange}/>
                {isOpen &&
                <FieldTextArea placeholder={`הכנס תיאור`}
                               value={description}
                               index={index}
                               wysiwyg={true}
                               toolbarHidden={false}
                               controlStyle={controlStyle}
                               inputStyle={inputStyle}
                               name={'description'}
                               changeHandler={handleChange}/>}
            </Td>
            <Td vAlign={"top"}>
                <FilesUploader name='image'
                               value={image}
                               index={index}
                               triggerValue='תמונה'
                               multiple={false}
                               previewWidth={"13rem"}
                               controlStyle={{padding:"0 5px"}}
                               setIsFileLoading={data => console.log('data', data)}
                               changeHandler={handleChange}/>
            </Td>
            <Td vAlign={"top"}>
                <ColorPickerBgText handleChange={handleChange}
                                   bgColor={bgColor}
                                   textColor={textColor}/>

            </Td>
            <Td vAlign={"top"}>
                <div className={"d-flex align-items-center"}>
                <ToggleIcon isOpen={isOpen} size={23} toggle={() => setIsOpen(!isOpen)}/>
                <MdClose onClick={removeSection}
                         className={"mr-3"}
                         style={{cursor: "pointer"}}/>
                </div>
            </Td>

        </Tr>
    )
};

const controlStyle = {
    marginBottom: 0,
    width: '100%'
};

const inputStyle = {
    backgroundColor: '#fff'
};