import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { arrayMoveImmutable } from "array-move";
import { createNewId } from "../slider/create-tool-slider";
import { CreateToolSwitchesItem } from "./create-tool-switches-item";
import { Button } from "../../../01_atoms";
import { Table, tableHeader, Tr } from "../../../03_organisms";
// import "../../../../assets/styles/create-tool-switches.scss";
import FilesUploader from "../../../02_molecules/form-fields/files-uploader/m-files-uploader";
import FieldText from "../../../02_molecules/form-fields/text/m-field-text";
import _ from "lodash";

const initialSection = {
  id: 0,
  topTitle: "",
  bottomTitle: "",
  trueLocation: "Top",
  status: "",
  answer: "",
  wrapperColor: "#000",
  buttonColor: "#999",
};
const CreateToolSwitches = ({
  items = [],
  switches_on,
  switches_off,
  switchWidth,
  switchHeight,
  handleToolDataChange,
}) => {
  const [switchesItems, setSwitchesItems] = useState(items);

  useEffect(() => {
    if (!_.isEqual(items, switchesItems)) {
      setSwitchesItems(items);
    }
  }, [items]);

  const addSection = () => {
    const newId = createNewId(switchesItems);
    const updatedSwitches = switchesItems.concat({
      ...initialSection,
      id: switchesItems.length > 0 ? newId : 0,
    });
    setSwitchesItems(updatedSwitches);
    handleToolDataChange("items", updatedSwitches);
  };

  const removeSection = (itemId) => {
    const updatedSwitches = switchesItems.filter((item) => item.id !== itemId);
    setSwitchesItems(updatedSwitches);
    handleToolDataChange("items", updatedSwitches);
  };

  const handleChange = (name, value, id) => {
    switchesItems.find((item) => item.id === id)[name] = value;
    setSwitchesItems(switchesItems);
    handleToolDataChange("items", switchesItems);
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedSections = arrayMoveImmutable(
      switchesItems,
      oldIndex,
      newIndex
    );
    setSwitchesItems(updatedSections);
    handleToolDataChange("items", updatedSections);
  };

  const renderSection = (item, index) => {
    return (
      <CreateToolSwitchesItem
        {...item}
        index={index}
        handleChange={handleChange}
        removeSection={removeSection}
        key={item.id}
      />
    );
  };
  const tableHeaderItems = [
    "#",
    "כותרות",
    "מיקום האמת",
    "סטטוס דיפולטי",
    "תשובה רצויה",
  ];

  return (
    <div className="create-tool switches-create">
      {switchesItems?.length > 0 && (
        <Table>
          <thead>
            <Tr>{tableHeaderItems.map(tableHeader)}</Tr>
          </thead>
          <tbody>{switchesItems.map(renderSection)}</tbody>
        </Table>
      )}
      <div className="gallery-add text-center">
        <Button onClick={addSection}>הוסף מתג</Button>
      </div>

      <div>
        <div className="d-flex py-5">
          <FilesUploader
            label="כפתור ON"
            triggerValue={"כפתור ON"}
            multiple={false}
            previewWidth={100}
            name={"switches_on"}
            value={switches_on}
            controlStyle={{ marginLeft: "20px" }}
            changeHandler={handleToolDataChange}
          />
          <FilesUploader
            label="כפתור OFF"
            triggerValue={"כפתור OFF"}
            multiple={false}
            previewWidth={100}
            name={"switches_off"}
            value={switches_off}
            changeHandler={handleToolDataChange}
          />
        </div>
        <div className="d-flex">
          <FieldText
            name="switchWidth"
            value={switchWidth}
            label="רוחב מתג"
            type="number"
            changeHandler={handleToolDataChange}
          />
          <FieldText
            name="switchHeight"
            value={switchHeight}
            label="גובה מתג"
            type="number"
            changeHandler={handleToolDataChange}
          />
        </div>
      </div>
    </div>
  );
};

CreateToolSwitches.defaultProps = {
  submitHandler: (toolData) => console.log("toolData", toolData),
  toolData: { items: [] },
};

CreateToolSwitches.propTypes = {
  toolData: PropTypes.object,
  submitHandler: PropTypes.func,
  switchHeight: PropTypes.string,
  switchWidth: PropTypes.string,
  buttonSize: PropTypes.string,
};

export default CreateToolSwitches;
