import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import {Element} from 'react-stylesheet';
import {gutters} from '../../../../00_base/variables';
import {Button} from '../../../../01_atoms';
import {FieldText, FilesUploader} from '../../../../02_molecules';
import {Grid} from '../../../../03_organisms';
import toolReducer from '../create-tool-reducer';
import CreateToolActions from '../create-tool-actions';

const THUMBNAIL = "https://storage.googleapis.com/assets.youdoadventures.com/website/default_thumbnail.jpg";
const UPDATE_INPUT = 'UPDATE_INPUT';
const ADD_SECTION = 'ADD_SECTION';

class initialSection {
    constructor(id, label, value, image, color) {
        this.id = id;
        this.label = label;
        this.value = value;
        this.image = image;
        this.color = color;
    }
}
const initialSections = [
    new initialSection(0, 'א', '', THUMBNAIL, null),
    new initialSection(1, 'ב', '', THUMBNAIL, null),
    new initialSection(2, 'ג', '', THUMBNAIL, null),
    new initialSection(3, 'ד', '', THUMBNAIL, null),
    new initialSection(4, 'ה', '', THUMBNAIL, null),
    new initialSection(5, 'ו', '', THUMBNAIL, null),
    new initialSection(6, 'ז', '', THUMBNAIL, null),
    new initialSection(7, 'ח', '', THUMBNAIL, null),
    new initialSection(8, 'ט', '', THUMBNAIL, null),
    new initialSection(9, 'י', '', THUMBNAIL, null),
    new initialSection(10, 'כ', '', THUMBNAIL, null),
    new initialSection(11, 'ל', '', THUMBNAIL, null),
    new initialSection(12, 'מ', '', THUMBNAIL, null),
    new initialSection(13, 'נ', '', THUMBNAIL, null),
    new initialSection(14, 'ס', '', THUMBNAIL, null),
    new initialSection(15, 'ע', '', THUMBNAIL, null),
    new initialSection(16, 'פ', '', THUMBNAIL, null),
    new initialSection(17, 'צ', '', THUMBNAIL, null),
    new initialSection(18, 'ק', '', THUMBNAIL, null),
    new initialSection(19, 'ר', '', THUMBNAIL, null),
    new initialSection(20, 'ש', '', THUMBNAIL, null),
    new initialSection(21, 'ת', '', THUMBNAIL, null)
];

const CreateToolTranslator = ({ toolData, submitHandler }) => {
    const [formState, dispatchFormState] = useReducer(toolReducer, {
        toolData: {
            items: !!toolData.items ? toolData.items : initialSections
        }
    });

    const handleChange = (name, value, index) => {
        dispatchFormState({
            type: UPDATE_INPUT,
            name: name,
            value: value,
            section: index
        })
    };

    const handleSubmit = () => {
        submitHandler({
            ...formState,
            toolData: {
                items: toolData.items.map(item => {

                    return {
                        ...item,
                        image: !!item.image && typeof item.image === 'string' ? item.image : item.image[0]
                    }
                })
            }
        })
    };

    const addSection = () => {
        dispatchFormState({
            type: ADD_SECTION,
            initialSection: new initialSection(22, '', '', THUMBNAIL, null),
        })
    };

    const renderSection = ({ id, label, value, image, color }, index) => {
        const onChange = (name, value, isValid) => handleChange(name, value, index);
        const imageSrc = !!image ? typeof image === 'string' ? image : !!image[0] ? image[0] : THUMBNAIL : THUMBNAIL;

        return (
            <Element key={id}
                border={'1px solid #000'}
                style={{
                    // borderRight: index & 1 ? '1px solid #000' : '2px solid #000',
                    // borderLeft: !(index & 1) ? '1px solid #000' : '2px solid #000',
                    // borderBottom: '1px solid #000',
                    // borderTop: index > 1 ? '1px solid #000' : '2px solid #000'
                }}
                position={'relative'}
                background={'#f1f1f1'}
                padding={`${gutters.lg} 0`}>


                <CreateToolActions
                    index={index}
                    color={color}
                    handleChange={handleChange}
                    dispatchFormState={dispatchFormState}
                    length={formState.toolData.items.length} />

                <Element marginTop={gutters.lg}>
                    <Element style={formStyle}>
                        <Element width={'100%'} padding={`0 ${gutters.sm}`}>
                            <FieldText
                                label={'אות'}
                                placeholder={`הכנס אות`}
                                value={label}
                                initialValue={label}
                                index={index}
                                controlStyle={{ marginBottom: 0, width: '100%' }}
                                inputStyle={{ backgroundColor: '#fff' }}
                                name={'label'}
                                changeHandler={onChange} />
                            <FieldText
                                label={'טקסט'}
                                placeholder={`הכנס טקסט`}
                                value={value}
                                initialValue={value}
                                index={index}
                                controlStyle={{ marginBottom: 0, width: '100%' }}
                                inputStyle={{ backgroundColor: '#fff' }}
                                name={'value'}
                                changeHandler={onChange} />
                            <FilesUploader
                                name={'image'}
                                previewStyle={{ width: '100px', height: '100px', marginTop: 0, marginBottom: 0, }}
                                previewWidth={'90px'}
                                previewHeight={'90px'}
                                initialValue={imageSrc}
                                index={index}
                                label={'תמונה'}
                                triggerValue={'העלו תמונה'}
                                singleFile={true}
                                hideTrigger
                                maxFiles={1}
                                setIsFileLoading={data => console.log('data', data)}
                                changeHandler={onChange}
                                value={[imageSrc]} />
                        </Element>
                    </Element>
                </Element>
            </Element>
        )
    }

    return (
        <Element>
            {!!formState.toolData.items &&
                <Grid
                    gutterWidth={0}
                    cellSizes={{ xs: 6, sm: 4, md: 3 }}
                    items={formState.toolData.items.map(renderSection)} />}

            <Element marginTop={gutters.md}>
                <Button onClick={addSection}>add section</Button>
                <Button onClick={handleSubmit}>save</Button>
            </Element>
        </Element>
    )
}

const formStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
};

CreateToolTranslator.defaultProps = {
    submitHandler: (toolData) => console.log('toolData', toolData),
    toolData: { items: [] }
};

CreateToolTranslator.propTypes = {
    toolData: PropTypes.object,
    submitHandler: PropTypes.func
};


export default CreateToolTranslator;