const UPDATE_INPUT = 'UPDATE_INPUT';
const ADD_SECTION = 'ADD_SECTION';
const SWAP_SECTION = 'SWAP_SECTION';
const DELETE_SECTION = 'DELETE_SECTION';

const toolReducer = (state, action) => {
    if (action.type === UPDATE_INPUT) {
        if (action.userData) {
            state.toolData = {...state.toolData, [action.name]: action.value}
        } else {
            if (action.section >= 0) {
                if (action.optionIndex >= 0) {
                    state.toolData.items[action.section][action.name] = state.toolData.items[action.section][action.name].map((option, i) => i === action.optionIndex ? action.value : option);
                } else {
                    if (!!state.toolData.items) {
                        state.toolData.items[action.section][action.name] = action.value;
                    }
                }
            } else {
                state.toolData[action.name] = action.value;
            }
        }
        return {
            isValid: action.isValid,
            toolData: state.toolData
        }
    }

    if (action.type === ADD_SECTION) {
        state.toolData = {
            items: [
                ...state.toolData.items, {
                    ...action.initialSection,
                    id: Math.max(...state.toolData.items.map(item => item.id)) + 1
                }
            ]
        };
        return {
            isValid: state.isValid,
            toolData: state.toolData
        };
    }

    if (action.type === DELETE_SECTION) {
        return {
            toolData: {
                items: state.toolData.items.filter((item, index) => index !== action.index)
            }
        }
    }

    if (action.type === SWAP_SECTION) {
        const indexA = action.index;
        const indexB = action.dir === 'up' ? indexA - 1 : indexA + 1;
        let updated = state.toolData.items;
        let temp = updated[indexA];
        updated[indexA] = updated[indexB];
        updated[indexB] = temp;

        return {
            isValid: state.isValid,
            toolData: {
                items: updated
            }
        }
    }
};

export default toolReducer;