import React from "react";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import {MdDragHandle} from "react-icons/md";

export const SortableList = SortableContainer(({items, ItemTag = "li", ListTag = "ul", orders, itemClassName = ""}) => {
    return (
        <ListTag>
            {items.map((value, index) => (
                <SortableItem key={index}
                              index={index}
                              value={value}
                              className={itemClassName}
                              order={orders ? orders[index] : 0}
                              Tag={ItemTag}/>
            ))}
        </ListTag>
    );
});

export const SortableItem = SortableElement(({value, Tag, order, className}) => {
    const vAlign = Tag === "td" ? {vAlign: "middle"} : {};
     return (
        <Tag {...vAlign}
             className={`sortable-item ${className}`}
             style={{order: order}}>
            {value}
        </Tag>
    )
});

export const DraggableHandle = SortableHandle(({size = 20, color = "#d2d2d2"}) => {
    return (
        <div className="frame-drag-handle" style={{cursor:"grab"}}>
            <MdDragHandle size={size} color={color}/>
        </div>
    )
});