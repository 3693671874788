import React from "react";
import {Col, Row} from "react-grid-system";
import {Image} from "../../01_atoms";
import COLOR_BOX from "../../../assets/images/color-bg/Drawing_mainheader.png";
import "../../../assets/styles/page-content-header.scss";

export const PageContentHeader = ({title, description, image=COLOR_BOX, className}) => {
    return (
        <div className={`page-content-header justify-content-center align-items-start pt-5 pt-lg-7 pb-lg-5 ${className}`}>
            <div >
                {title &&
                <h1 className="page-content-header-title">
                    {title}
                </h1>}
                {description &&
                <p className="page-content-header-description">
                    {description}
                </p>}
            </div>
            <div 
                 className="page-content-header-image d-flex align-items-center justify-content-start justify-content-xl-end">
                <Image src={image} width="100%"/>
            </div>
        </div>
    )
};


