import React, {useContext} from "react";
import {gutters} from "../../00_base/variables";
import {FieldText, FilesUploader, FieldTextArea} from "../../02_molecules/form-fields";
import {TagsSelector} from "../../03_organisms/";
import {ChooseItemLanguage} from "../../03_organisms/choose-item-language/choose-item-language";
import {AuthContext} from "../../../providers/auth-provider";

export const RiddleMainContent = ({handleRiddleChange, riddle}) => {
    const {fullName} = useContext(AuthContext);

    const {title, description, description_short, tags, thumbnail, level, author, language_id} = riddle;

    return (
        <div className="py-3">
            <ChooseItemLanguage label="שפה"
                                value={language_id}
                                changeHandler={handleRiddleChange}/>

            <FieldText name="title"
                       value={title}
                       label="שם החידה"
                       changeHandler={handleRiddleChange}/>

            <FieldText name="author"
                       value={author || fullName}
                       label={"יוצר החידה"}
                       changeHandler={handleRiddleChange}/>

            <FilesUploader name="thumbnail"
                           value={thumbnail}
                           multiple={false}
                           previewWidth={200}
                           controlStyle={{marginBottom: gutters.sm}}
                           changeHandler={handleRiddleChange}/>

            <FieldTextArea label="תיאור החידה"
                           wysiwyg={true}
                           toolbarHidden={false}
                           name="description"
                           value={description}
                           changeHandler={handleRiddleChange}/>

            <FieldTextArea label="תיאור מקוצר לגלריה"
                           wysiwyg={true}
                           toolbarHidden={false}
                           name="description_short"
                           value={description_short}
                           changeHandler={handleRiddleChange}/>

            <FieldText type="number"
                       name="level"
                       label={"דרגת קושי"}
                       min={0}
                       max={4}
                       value={level}
                       changeHandler={handleRiddleChange}/>

            <TagsSelector value={tags}
                          name="tags"
                          handleChange={handleRiddleChange}/>
        </div>
    )
};