import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import ScreenTool from '../tool';
import ScreenLoading from "../loading/screen-loading";
import * as journeyActions from "../../../store/actions/journey/journey-actions";
import { updateLocalStorage } from "../../../store/actions/adventure/adventure-actions";
import "../../../../../../assets/styles/screen-riddle.scss";
import classNames from "classnames";
import { ScreenTool } from "../tool/screen-tool";

export const RiddleContext = React.createContext({
  updateGameLevel: null,
  handleRiddleFinish: null,
  savePlayerData: null,
  playerData: null,
  adventureName: "",
  isRiddleFinish: null,
});

const ScreenRiddle = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const gameData = useSelector((state) => state.gameData);
  const journey = useSelector((state) => state.journey);
  const gameLevel = localStorage.getItem("game-level");
  const riddleId = params?.id;
  const frameIndex = params?.frameIndex || 1;
  const { riddles, adventure, adventureData = {}, frames } = gameData;
  const riddle =
    riddles && riddles.find((riddle) => riddle.id === parseInt(riddleId));
  const riddleIndex = riddles && riddles.indexOf(riddle);
  const adventureName = adventure && adventure.adventure_name;
  const userDataName = `riddle-${riddleIndex}-user-data`;
  const playerData = localStorage.getItem(userDataName);

  const [frameLevel, setFrameLevel] = useState(frameIndex - 1);

  const dispatch = useDispatch();
  const isToolFullscreen = location?.hash === "#ring-ring";

  useEffect(() => {
    if (riddles && riddleIndex < 0) {
      window.location.href = "/";
    }
  }, [riddleIndex, riddles]);

  useEffect(() => {
    if (frameLevel + 1 !== parseInt(frameIndex)) {
      navigate(`/riddle/${riddleId}/${frameLevel + 1}`);
    }
  }, [frameIndex, frameLevel, navigate, riddleId]);

  const { riddlestemplates, style = {}, templatedata = {} } = riddle;

  const isRiddleFinish = journey[`riddle_${riddle.id}_finish`];
  const handleRiddleFinish = useCallback(
    () => dispatch(journeyActions.riddleFinish(riddleId)),
    [dispatch, riddleId]
  );
  const savePlayerData = (data) =>
    updateLocalStorage(userDataName, JSON.stringify(data));
  const updateGameLevel = () =>
    updateLocalStorage("game-level", riddleIndex + 1);
  const gotoNextFrame = () => setFrameLevel(frameLevel + 1);

  useEffect(() => {
    const frames = riddle.riddlestemplates;
    const isAllFrameResolved = frames?.every(
      (frameId) => journey[`frame_${frameId}_finish`]
    );
    if (isAllFrameResolved && !isRiddleFinish) {
      handleRiddleFinish();
    }
  }, [handleRiddleFinish, isRiddleFinish, journey, riddle.riddlestemplates]);

  const isLastFrame = parseInt(frameIndex) === riddlestemplates.length;

  const handleFrameContinue = () => {
    if (isLastFrame) {
      const { handleRiddleSolve } = riddle;
      if (handleRiddleSolve) {
        handleRiddleSolve();
      }
      navigate(`/menu/${adventureData?.id}`);
    } else {
      gotoNextFrame();
    }
  };

  if (!riddle) {
    return <ScreenLoading />;
  }

  if (riddleIndex > gameLevel) {
    // history.push("/menu");
  }

  const riddleContext = {
    updateGameLevel,
    savePlayerData,
    handleRiddleFinish,
    isRiddleFinish,
    playerData,
    adventureName,
  };

  const frame = frames.find(
    (frame) => frame.id === riddlestemplates[frameLevel]
  );
  // const frameFinish = journey[`frame_${frame.id}_finish`];

  return (
    <RiddleContext.Provider value={riddleContext}>
      <RiddleWrapper
        id="screen-riddle"
        className={classNames("screen-riddle", {
          fullscreen: isToolFullscreen,
        })}
        style={style}
        {...templatedata}
      >
        {/*{riddle.title && <div className={"riddle-header"}>*/}
        {/*    <h3>{riddle.title}</h3>*/}
        {/*</div>*/}
        {/*}*/}

        <ScreenTool
          key={frame.id}
          riddleData={riddle}
          toolId={frame.categories[0]}
          toolData={frame}
          isFullScreen={isToolFullscreen}
          riddleIndex={riddleIndex}
          riddles={riddles}
          handleFrameContinue={handleFrameContinue}
          isLastFrame={isLastFrame}
        />
      </RiddleWrapper>
    </RiddleContext.Provider>
  );
};

const RiddleWrapper = styled.div`
  ${(props) => `
        ${
          props.backgroundImage
            ? `background-image: url(${props.backgroundImage})`
            : ""
        };
        ${
          props.backgroundColor
            ? `background-color: ${props.backgroundColor}`
            : ""
        };
    `}
`;

export default ScreenRiddle;
