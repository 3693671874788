import React from "react";
import {IMAGE, TOOL, VIDEO} from "../tool-content-section";
import {TextContent} from "./text-content";
import {ImageContent} from "./image-content";
import {VideoContent} from "./video-content";
import {CategoryContent} from "./category-content";

export const ToolContentSectionType = (
    {itemType, itemValue, changeHandler, itemsStyle}
) => {
    const props = {changeHandler, itemValue, ...itemsStyle};
    switch (itemType) {
        case IMAGE:
            return <ImageContent {...props}/>;
        case VIDEO:
            return <VideoContent {...props}/>;
        case TOOL:
            return <CategoryContent {...props}/>;
        default:
            return <TextContent {...props}/>;
    }
}