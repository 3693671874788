import React, {useContext, useState} from 'react';
import {useSelector} from "react-redux";
import {AuthContext} from "../../../../providers/auth-provider";
import {selectCurrentAdventureType} from "../../../../store/selectors";
import {colors, gutters} from '../../../00_base/variables';
import {Button, SimpleLoader} from '../../../01_atoms';
import {FieldText, FilesUploader} from "../../../02_molecules";
import Login from "../../../05_screens/authentication/login/login";
import {AdventureTypeFormSpecial} from "./adventure-type-form-special/adventure-type-form-special";
import {Translation} from "../../../../languages";
import {DICT} from "../../../../languages/dictionary-names";
import {FieldLabel} from "../../../02_molecules/form-fields/field-style/m-field-style";

export const AdventureInitialForm = (
    {
        instructions_main = "בשלב הראשון מלאו את הפרטים והעלו את התוכן הייחודי שלכם",
        instructions_title,
        instructions_image,
        submitLabel,
        createNewAdventure,
        dispatchAdventureData
    }) => {
    const adventureType = useSelector(selectCurrentAdventureType);
    const {isLogin} = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [adventureTitle, setAdventureTitle] = useState("");
    const [adventureThumbnail, setAdventureThumbnail] = useState("");
    const [heroName, setHeroName] = useState("");
    const [warningActive, setWarningActive] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);

        const adventure = {
            title: adventureTitle,
            adventure_name: heroName,
            thumbnail: adventureThumbnail
        };

        if (adventureTitle && heroName && adventureThumbnail) {
            createNewAdventure(adventure);
        } else {
            setWarningActive(true);
            setIsLoading(false);
        }
    };

    const isEmployeeBirthday = adventureType?.id === 324 || adventureType?.id === 330 || adventureType?.id === 332;

    // const [loginFormActive, setLoginFormActive] = useState(false);

    return (
        <div className={"create-adventure-form-wrapper"}>

            <div>
                {isLogin ?
                    <div className="create-adventure-form">
                        <div className="create-adventure-form-description mb-5">
                            <h2 className="text-center">בואו ניצור הרפתקה</h2>
                            <p>
                                {instructions_main ||
                                (isEmployeeBirthday ?
                                        "מלאו את הפרטים והתחילו ביצירת ההרפתקה שלכם" :
                                        "ענו על השאלות למטה והעלו קבצים כדי ליצור הרפתקה ייחודית"
                                )}
                            </p>
                        </div>
                        {isEmployeeBirthday ?
                            <AdventureTypeFormSpecial dispatchAdventureData={dispatchAdventureData}
                                                      createNewAdventure={createNewAdventure}/>
                            :
                            <form>
                                <FieldText changeHandler={(name, value) => setAdventureTitle(value)}
                                           name={"title"}
                                           label={"כותרת ההרפתקה"}
                                           required={true}
                                           errorMessage="יש להכניס את כותרת ההרפתקה"
                                           errorMessageActive={warningActive && !adventureTitle}
                                           value={adventureTitle}
                                           warningStyle={{textAlign: "right"}}
                                           controlStyle={{marginBottom: gutters.sm}}/>

                                <FieldText changeHandler={(name, value) => setHeroName(value)}
                                           name={"adventure_name"}
                                           label={instructions_title}
                                           required={true}
                                           errorMessage={instructions_title || "יש להכניס שם גיבור.ת ההרפתקה"}
                                           errorMessageActive={warningActive && !heroName}
                                           value={heroName}
                                           warningStyle={{textAlign: "right"}}
                                           controlStyle={{marginBottom: gutters.sm}}/>

                                <div className={"d-flex flex-wrap align-items-center mb-5"}>
                                    <FieldLabel style={{position: "static", color: colors.black}}>
                                        {instructions_image}
                                    </FieldLabel>
                                    <FilesUploader changeHandler={(name, value) => setAdventureThumbnail(value)}
                                        // label={instructions_image}
                                                   triggerValue={"לחצו כאן כדי להעלות את התמונה"}
                                                   labelStyle={{
                                                       textAlign: "right",
                                                       display: "block",
                                                       marginBottom: gutters.xs,
                                                       fontWeight: "normal",
                                                       visibility: adventureThumbnail ? "hidden" : "visible"
                                                   }}
                                                   triggerStyle={{
                                                       color: "#fff",
                                                       width: "auto",
                                                       whiteSpace: "nowrap",
                                                       paddingLeft: "40px",
                                                       paddingRight: "40px",
                                                       borderRadius: "20px",
                                                       border: 0,
                                                       backgroundColor: colors.action
                                                   }}
                                                   hideCameraIcon={true}
                                                   errorMessage={"יש להכניס תמונה של גיבור.ת ההרפתקה"}
                                                   errorMessageActive={warningActive && !adventureThumbnail}
                                                   warningStyle={{position: "absolute", marginTop: 0}}
                                                   multiple={false}
                                                   maxFiles={1}
                                                   previewWidth={"300px"}
                                                   name={"image"}
                                                   value={adventureThumbnail}/>
                                </div>
                                {/*<Heading tag={"h4"}*/}
                                {/*         textAlign={"center"}*/}
                                {/*         style={{display:"none !important"}}*/}
                                {/*         value={"לקוחות יקרים, עקב הביקוש הרב, זמן האספקה של הקופסה ארוך מהרגיל"}/>*/}

                                <Button className="create-adventure-form-submit" width={"26rem"} onClick={handleSubmit} block>
                                    {isLoading ? <SimpleLoader color="#fff"/> : <Translation id={DICT.CONTINUE}/>}
                                </Button>
                                {warningActive &&
                                <p className="font-size-xs text-center mt-1 mb-0" style={{color: "#7b2115"}}>
                                    נא להשלים את כל השדות
                                </p>}
                            </form>
                        }
                    </div>
                    :

                    <Login title={"בכדי ליצור הרפתקה חדשה אנא בצע הרשמה לאתר"}
                           titleSize={"lg"}
                        // actionAfterLogin={() => setLoginFormActive(false)}
                           className={"screen-adventure-login"}/>

                }
            </div>
        </div>
    )
};