import React from "react";
import SVG_EDIT from "../../../../assets/images/svg-icons/svg-pen.svg";
import SVG_CLOSE from "../../../../assets/images/svg-icons/svg-close.svg";
import SVG_EYE from "../../../../assets/images/svg-icons/svg-eye-icon.svg";
import SVG_API from "../../../../assets/images/svg-icons/svg-api-icon.svg";

export const adventureTypeInnerMenuItems = (deleteAdventure, duplicateAdventure, isCreator) => {
    const open_single_page = id => window.location.href = id > 0 ?  `/he/admin/reservation/${id}` : '/he/admin/new-reservation';
    const open_in_site = id => window.open(`/he/create-adventure/${id}`, '_blank');
    const open_api = id => window.open(`https://api.youdoadventures.com/adventures/${id}`, '_blank');
    const open_game = id => window.open(`https://play.youdoadventures.com`, '_blank');

    return [
      {Icon: () => <img  width={10} height={10} src={SVG_EDIT} alt="edit"/>, label: "עריכה", onClick: open_single_page},
      {Icon: () => <img  width={10} height={10} src={SVG_EDIT} alt="edit"/>, label: "שכפול", onClick: duplicateAdventure},
      {Icon: () => <img  width={10.25} height={10.25}  src={SVG_CLOSE} alt="remove"/>, label: "מחיקה", onClick: deleteAdventure, adminsOnly: true},
      {Icon: () => <img  width={14.34} height={12}  src={SVG_EYE} alt="view in site"/>, label: "צפייה בהזמנה באתר", onClick: open_in_site},
      {Icon: () => <img  width={14.34} height={12}  src={SVG_EYE} alt="view in site"/>, label: "צפה במשחק בדיגיטלי", onClick: open_game},
      {Icon: () => <img  width={14} height={11}  src={SVG_API} alt="api"/>, label: "API", onClick: open_api, adminsOnly: true},
  ];
}