import React from 'react';
import PropTypes from 'prop-types';
import {DICT} from "../../../../../languages/dictionary-names";
import {Translation} from "../../../../../languages";
import {FilesUploader} from '../../../../02_molecules';

const checkIsValid = ({correctImages, correct_min = 15}) => {
    const invalidLength = correct_min - correctImages.length;
    const validLength = correct_min - invalidLength;
    return validLength / correct_min * 100
};

const CreateToolTifzoret = ({toolData, handleToolDataChange, invalidFields}) => {
    const {correctImages = [], correct_max  =35, correct_min = 15} = toolData;
    const finish = correctImages.length >= (correct_min);

    const triggerValue = finish ? <Translation id={DICT.TASK_FINISHED}/> : <Translation id={DICT.UPLOAD_IMAGE}/>;

    return (
        <div className="create-tifzoret">
            <FilesUploader minItems={correct_min}
                           maxItems={correct_max}
                           backgroundSize={"cover"}
                           name="correctImages"
                           multiple={true}
                           triggerValue={triggerValue}
                           expectedLength={correct_max}
                           changeHandler={(name, value) => handleToolDataChange(checkIsValid, name, value)}
                           value={correctImages}
                           invalidFields={invalidFields}/>
        </div>
    )
}

CreateToolTifzoret.defaultProps = {
    submitHandler: (toolData) => console.log('toolData', toolData)
};

CreateToolTifzoret.propTypes = {
    toolData: PropTypes.object,
    submitHandler: PropTypes.func
};


export default CreateToolTifzoret;