import React from "react";
import classNames from "classnames";

import "../../../../../assets/styles/translator-preview.scss";

export const TranslatorPreview = ({sentence, items}) => {
    const specials = [".", ",", "?", "!"];
    const sentenceItems = sentence.split("");

    const renderLetter = (letter) => {
        const image = items.find(item => item.label?.trim() === letter)?.image;
        const special = specials.some(item => item === letter);
        const space = letter === " ";
        return <div className={classNames("translator-preview-item", {special, space})}>
            {(special || space) ?
                <span className="translator-preview-special">{letter}</span>
                :
                <img src={image} alt={letter} className="translator-preview-image"/>
            }
        </div>
    }

    return (
        <div className="translator-preview">
            {sentenceItems.map(renderLetter)}
        </div>
    )
};