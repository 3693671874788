import React from "react";
import Slider from "../../03_organisms/slider";
import "../../../../../../assets/styles/tool-slider.scss";
import {Image} from "../../01_atoms";

export const ToolSlider = ({items = [], nextFrameButton, indicatorStyle = {width: 30, height: 30}}) => {
    const sliderItem = item => {
        const {title, description, image} = item;
        return (
            <div className="tool-slider-item">
                {image && <Image src={image} className="tool-slider-image"/>}
                <div className="tool-slider-content">
                    {title && <h5>{title}</h5>}
                    {description && <p>{description}</p>}
                </div>
            </div>
        )
    };
    return (
        <div className="tool-slider">
            <Slider items={items.map(sliderItem)}
                    indicatorStyle={indicatorStyle}/>
        </div>
    )
}