import React, {useEffect} from 'react';
import {Element, HBox} from 'react-stylesheet';
import {Image, Span} from '../../01_atoms';
import {colors} from "../../00_base/variables";

const ToolTranslator = (
    {
        items = [],
        itemStyle = {},
        textColor = colors.white,
        borderColor = colors.white,
        letterFontSize = 12,
        handleFrameFinish
    }) => {

    const originalLetters = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט', 'י', 'כ', 'ל', 'מ', 'נ', 'ס', 'ע', 'פ', 'צ', 'ק', 'ר', 'ש', 'ת'];



    useEffect(() => {
        handleFrameFinish();
    }, [handleFrameFinish]);

    const renderLetter = (item, index) => {
        const letterStyle = {
            fontSize: `${itemStyle?.fontSize || letterFontSize}px`,
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            fontWeight: 'bold',
            color: item.textColor || itemStyle?.color || textColor
        };
        return (
            <HBox key={item.id} justifyContent='center' alignItems='center'
                  padding='2rem' width='50%' border={`1px solid ${borderColor}`}
                  style={{...itemStyle, backgroundColor: item.bgColor || itemStyle.background || "#095d90"}}>
                <Span style={letterStyle} value={item.label || originalLetters[index]}/>
                <Element width={'60px'} height={'60px'}>
                    <Image src={item.image} width='60px'/>
                </Element>
            </HBox>
        )
    };

    return (
        <div>
            <HBox flexWrap='wrap' margin={"0 auto"} maxWidth={"700px"}>
                {items.map(renderLetter)}
            </HBox>
        </div>
    )
};

export default ToolTranslator;
