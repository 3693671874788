import {API_URL, fetchData} from "./fetch-data";
import {categoriesObject} from "../models/build-models";

const api = API_URL; // API stg-
const extension = 'categories';
const url = `${api}/${extension}`;
const token = localStorage.getItem("token");

export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_ACTIVE_CATEGORY = "SET_ACTIVE_CATEGORY";
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

export const getSingleCategory = categoryId => {
    return async(dispatch, getState) => {
        try {
            const response = await fetch(`${url}/${categoryId}`);
            if (!response.ok) {
                throw new Error('Something went wrong');
            }
            const categoryData = await response.json();
            return categoriesObject(categoryData)
        } catch (error) {
            throw error
        }
    }
};

export const setActiveCategory = category => {
    return async(dispatch, getState) => {
        if (category?.id) {
            try {
                const response = await fetch(`${url}/${category.id}`);
                if (!response.ok) {
                    throw new Error('Something went wrong');
                }
                const categoryData = await response.json();
                dispatch({
                    type: SET_ACTIVE_CATEGORY,
                    category: categoryData
                })
            } catch (error) {
                throw error
            }
        } else {
            dispatch({
                type: SET_ACTIVE_CATEGORY,
                category: {}
            })
        }

    }
};

export const setAllCategories = () => {
    return async(dispatch, getState) => {
        await fetchData(
            "get",
            `${extension}?page=${0}`,
            {},
            categoriesData => {
                let allCategories = [];
                for (const key in categoriesData) {
                    const categoryData = categoriesData[key];
                    allCategories.push(categoriesObject(categoryData))
                }
                fetchData(
                    "get",
                    `${extension}?page=${1}`,
                    {},
                    categoriesData => {
                        for (const key in categoriesData) {
                            const categoryData = categoriesData[key];
                            allCategories.push(categoriesObject(categoryData))
                        }
                        fetchData(
                            "get",
                            `${extension}?page=${2}`,
                            {},
                            categoriesData => {
                                for (const key in categoriesData) {
                                    const categoryData = categoriesData[key];
                                    allCategories.push(categoriesObject(categoryData))
                                }
                                dispatch({
                                    type: SET_CATEGORIES,
                                    allCategories: allCategories
                                });
                            }
                        )
                    }
                )

            }
        )
    }
};

export const createCategory = (categoryData, callback = success => console.log(success)) => {
    return async(dispatch, getState) => {
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(categoryData),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "access-Token": token
            }
        }).then(response => response.ok ? response.json() : alert('error')).then(
            success => {
                dispatch({
                    type: CREATE_CATEGORY,
                    category: categoriesObject(success.data)
                });
                callback(success.data);
            }
        )
    }
};

export const updateCategory = (categoryData, callback = success => console.log(success)) => {
    return async(dispatch, getState) => {
        fetch(`${url}/${categoryData.id}`, {
            method: 'PUT',
            body: JSON.stringify({...categoryData }),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                'access-Token': token
            }
        }).then(
            response => response.ok ? response.json() : alert('error')
        ).then(
            success => {
                dispatch({
                    type: UPDATE_CATEGORY,
                    category: categoriesObject(success.data)
                });
                callback(success.data);
            }
        );
    };
};

export const deleteCategory = categoryId => {
    return async(dispatch, getState) => {
        fetch(`${url}/${categoryId}`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                'access-token': token
            }
        }).then(
            response => response
        ).then(
            success => dispatch({ type: DELETE_CATEGORY, id: categoryId })
        ).then(
            error => console.log('error', error)
        );
    }
};