import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import Label from '../../../01_atoms/text/a-label';
import Span from '../../../01_atoms/text/a-span';
import {fieldStyle} from '../field-style/m-field-style';

const INPUT_CHANGE = 'INPUT_CHANGE';
const INPUT_BLUR = 'INPUT_BLUR';

const inputReducer = (state, action) => {
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid
            }
        case INPUT_BLUR:
            return {
                ...state,
                blur: true
            }
        default:
            return state
    }
}

const FieldTextArea = ({
    name,
    label,
    rows,
    dir,
    errorMessage,
    changeHandler,
    initialValue,
    initiallyValid,
    required,
    email,
    min,
    max,
    minLength,
    backgroundColor,
    color,
    fontSize,
    textAlign,
    labelStyle,
    paddingVertical,
}) => {
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: initialValue ? initialValue : '',
        isValid: initiallyValid,
        blur: false
    })

    useEffect(() => {
        if (inputState.blur) {
            changeHandler(name, inputState.value, inputState.isValid);
        }
    }, [inputState, changeHandler, name]);

    const handleInputChange = event => {
        const value = event.target.value;
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = true;
        if (required && value.trim().length === 0) { isValid = false; }
        if (email && !emailRegex.test(value.toLowerCase())) { isValid = false; }
        if (min != null && +value < min) { isValid = false; }
        if (max != null && +value > max) { isValid = false; }
        if (minLength != null && value.length < minLength) { isValid = false; }
        dispatch({
            type: INPUT_CHANGE,
            value: value,
            isValid: isValid
        })
    };

    const handleInputBlur = () => {
        changeHandler(name, inputState.value, inputState.isValid);
        dispatch({ type: INPUT_BLUR })
    };


    return (
        <div style={{ ...fieldStyle.control, ...fieldStyle[dir] }}>
            <Label style={{...fieldStyle.label, ...labelStyle && labelStyle, color: color ? color : 'initial'}} value={label} />
            <textarea
                rows={rows}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                value={inputState.value.toString()}
                style={{
                    ...fieldStyle.input,
                    color: color ? color : 'initial',
                    fontSize: fontSize ? fontSize : 'initial',
                    backgroundColor: backgroundColor,
                    textAlign: textAlign ? textAlign : 'initial',
                    padding: `${paddingVertical} 0`
                }} />
            {!inputState.isValid && <Span value={errorMessage} />}
        </div>
    )
}

FieldTextArea.defaultProps = {
    value: '',
    rows: 3,
    initialValue: '',
    dir: 'vertical',
    initiallyValid: true
};

FieldTextArea.propTypes = {
    name: PropTypes.string.isRequired,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rows: PropTypes.number,
    dir: PropTypes.oneOf(['horizontal', 'vertical']),
    errorMessage: PropTypes.string,
    changeHandler: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    initiallyValid: PropTypes.bool,
    required: PropTypes.bool,
    email: PropTypes.bool,
    min: PropTypes.bool,
    max: PropTypes.bool,
    minLength: PropTypes.number,
};

export default FieldTextArea; 