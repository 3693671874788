import React from 'react';

class Iframe extends React.Component {
    render() {
        const {src, className, width, height, maxWidth, style, autoPlay, position} = this.props;
        
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|v=)([^#]*).*/,
            match = !!src ? src.match(regExp) : '';

        const youtubeUrl = match && match[2].length === 11 ? match[2] : 'error';

        return (
            <div className={`iframe ${className ? className : ''}`} style={style && style}>
                {(youtubeUrl && youtubeUrl !== 'error') ?
                    <iframe src={`//www.youtube.com/embed/${youtubeUrl}`}
                            width={width && width}
                            title={youtubeUrl}
                            allowFullScreen="allowfullscreen"
                            style={{maxWidth: maxWidth ? maxWidth : '100%', width: width && width, height: height && height, position: position }}
                            frameBorder={'0'}/>

                    :
                    <iframe src={src}
                            width={width ? width : 'inherit'}
                            height={height ? height : 'inherit'}
                            allowFullScreen="allowfullscreen"
                            title={youtubeUrl}
                            style={{maxWidth: maxWidth, position: position}}
                            frameBorder={'0'}
                            autoPlay={autoPlay}/>
                }
            </div>
        )
    }
    static defaultProps = {
        autoPlay: false,
        position: 'static'
    }
}

export default Iframe;