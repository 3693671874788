import Template from "../models/template";
import {API_URL, fetchData} from "./fetch-data";

const api = API_URL;
const extension = 'riddlesTemplate';
const url = `${api}/${extension}`;

const token = localStorage.getItem("token");
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_SINGLE_FRAME = 'SET_SINGLE_FRAME';
export const SET_ADVENTURE_FRAMES = 'SET_ADVENTURE_FRAMES';
export const CREATE_FRAME = 'CREATE_FRAME';
export const UPDATE_FRAME = 'UPDATE_FRAME';
export const DELETE_FRAME = 'DELETE_FRAME';

export const templateObject = (template) => {
    return new Template(
        template.id,
        template.title,
        template.description,
        template.categories,
        template.categorydata,
        template.description_short,
        template.printimageurl,
        template.riddle_name,
        template.show_in_store,
        template.thumbnail,
        template.user_email,
        template.videourl,
        template.is_published,
        template.tags,
        template.created_at,
        template.editable,
        template.clues || [],
        template.instructions || {title: "", subtitle: "", description: ""},
        template.tips || [],
        template.modals
    )
};

export const setAllTemplates = (pageIndex) => {
    return async (dispatch, getState) => {
        try {
            const response = await fetch(`${url}${pageIndex >= 0 ? `/?page=${pageIndex}` : ''}`);
            if (!response.ok) {
                throw new Error('Something went wrong');
            }
            const templatesData = await response.json();

            let allTemplates = [];
            for (const key in templatesData) {
                const templateData = templatesData[key];
                allTemplates.push(templateObject(templateData))
            }

            dispatch({
                type: SET_TEMPLATES,
                allTemplates: allTemplates
            });

            return allTemplates
        } catch (error) {
            throw error
        }
    }
};

export const setAdventureTemplates = (frames) => {
    templateObject(frames);
    return async (dispatch, getState) => {
            dispatch({
                type: SET_ADVENTURE_FRAMES,
                frames: frames
            });
    }
};


export const getSingleTemplate = (templateId, callback) => {
    return async (dispatch, getState) => {
        await fetchData(
            "get",
            `${extension}/${templateId}`,
            {},
            templateData => {
                const frame = templateObject(templateData);
                callback(frame)
            }
        )
    }
};
export const createTemplate = (templateData, callback = success => console.log(success)) => {
    return async (dispatch, getState) => {
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(templateData),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "access-Token": token
            }
        }).then(response => response.ok ? response.json() : alert('error')).then(
            success => {

                dispatch({
                    type: CREATE_FRAME,
                    ...templateObject(success.data)
                });
                callback(success.data);
            }
        )
    }
};

export const updateTemplate = (templateData, callback = success => console.log("")) => {
    return async (dispatch, getState) => {
        fetch(`${url}/${templateData.id}`, {
            method: 'PUT',
            body: JSON.stringify({...templateData, category: templateData.categories}),
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "access-Token": token
            }
        }).then(
            response => response.ok ? response.json() : alert('error')
        ).then(
            success => {
                dispatch({
                    type: UPDATE_FRAME,
                    ...templateObject(success.data)
                });
                callback(templateObject(success.data));
            }
        );
    };
};

export const deleteTemplate = templateId => {
    return async (dispatch, getState) => {
        fetch(`${url}/${templateId}`, {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "access-Token": token
            }
        }).then(
            response => response
        ).then(
            success => dispatch({type: DELETE_FRAME, id: templateId})
        ).then(
            error => console.log('error', error)
        );
    }
}