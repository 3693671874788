import React from 'react';

class IconPlayers extends React.Component {
    render() {
        const {width = 29.667, height = 16.182} = this.props;
        return (
            <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
                <path
                    d="M26.257 25.455a4.72 4.72 0 1 0-7.363 0 8.145 8.145 0 0 0-3.061 2.733 8.145 8.145 0 0 0-3.061-2.733 4.72 4.72 0 1 0-7.363 0A8.091 8.091 0 0 0 1 32.652 1.348 1.348 0 0 0 2.348 34h26.97a1.348 1.348 0 0 0 1.348-1.348 8.091 8.091 0 0 0-4.409-7.197zm-17.166-4.94a2.023 2.023 0 1 1-2.023 2.023 2.023 2.023 0 0 1 2.023-2.023zM3.868 31.3a5.394 5.394 0 0 1 10.445 0zm18.708-10.785a2.023 2.023 0 1 1-2.023 2.023 2.023 2.023 0 0 1 2.023-2.023zM17.353 31.3a5.394 5.394 0 0 1 10.445 0z"
                    transform="translate(-1 -17.818)"/>
            </svg>

        )
    }
}

export default IconPlayers;