import React, {useCallback, useState} from 'react';
import {fieldStyle} from '../field-style/m-field-style';
import {Label} from '../../../01_atoms';
import classnames from "classnames";
import "../../../../assets/styles/field-options.scss";

export const FieldOptions = ({
                          name,
                          options,
                          label,
                          value,
                          inputStyle = {},
                          labelStyle,
                          controlStyle,
                          changeHandler,
                          inputStyleActive,
                      }) => {

    const [selectedOption, setSelectedOption] = useState(value);

    const handleChange = useCallback( (selectedOption) => {
        setSelectedOption(selectedOption);
        changeHandler(name, selectedOption.value);
    },[changeHandler, name]);

    const renderOption = (option, index) => {
        const onClick = () => handleChange(option);
        const isActive = selectedOption === option ||  selectedOption?.value === option?.value;
        const className = classnames("field-options-item", {active: isActive})

        return <div className={className}
                    onClick={onClick}
                    style={{...inputStyle, ...isActive && inputStyleActive}}
                    key={index}>
            {option.label}
        </div>
    };

    return (
        <div style={{...fieldStyle.control, ...controlStyle}} className="field-options">
            {!!label && <Label style={{...fieldStyle.label, paddingLeft:"10px", ...labelStyle, whiteSpace:"nowrap"}} value={label}/>}
            <div className="field-options-container">
                {options.map(renderOption)}
            </div>

        </div>
    );
};
