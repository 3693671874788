import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../providers/auth-provider";
import { LanguageContext, Translation } from "../../../../languages";
import { useDispatch, useSelector } from "react-redux";
import { HBox, Element } from "react-stylesheet";
import classNames from "classnames";
import {
  MdFace,
  MdDelete,
  MdEdit,
  MdNavigateBefore,
  MdNavigateNext,
  MdPerson,
} from "react-icons/md";
import {
  selectIsUserAdventuresLoaded,
  selectUserAdventures,
  selectUserAdventuresPageIndex,
} from "../../../../store/selectors";
import {
  deleteAdventure,
  setLoginUserAdventures,
} from "../../../../store/actions/adventure-actions";
// import {IconNoAdventure} from "../../../02_molecules/icons";
import {
  Button,
  LoaderContent,
  LoaderPageBulletList,
  Paragraph,
} from "../../../01_atoms";
import { Link, useNavigate } from "react-router-dom";
import { ScreenUserItem } from "./screen-user-item";

import { ToolTip } from "../../../02_molecules";
import "../../../../assets/styles/screen-user.scss";
import { DICT } from "../../../../languages/dictionary-names";
import { ThemeContext } from "../../../../providers/theme-provider";
import { colors } from "../../../00_base/variables";
import UserDetails from "./user-details";

export const ScreenUser = () => {
  const userAdventuresPageIndex = useSelector(selectUserAdventuresPageIndex);
  const isUserAdventuresLoaded = useSelector(selectIsUserAdventuresLoaded);
  const adventures = useSelector(selectUserAdventures);
  const { mode } = useContext(ThemeContext);
  const textColor = colors[mode === "light" ? "black" : "white"];
  const { isLogin, userData, fullName } = useContext(AuthContext);
  const { userLanguage, rtl } = useContext(LanguageContext);

  const [currentAdventuresPage, setCurrentAdventuresPage] = useState(0);
  const [activeMenu, setActiveMenu] = useState(null);
  const [noMoreAdventures, setNoMoreAdventures] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userName = userData.name;
  const userEmail = userData.email;
  const adventuresPerPage = 15;
  const isFinalAdventuresPage =
    (currentAdventuresPage + 1) * adventuresPerPage > adventures.length;

  useEffect(() => {
    if (!isLogin || !userEmail) {
      navigate("/register");
    }
  }, [navigate, userEmail, isLogin]);

  const getMoreAdventures = () => {
    dispatch(
      setLoginUserAdventures(userEmail, userAdventuresPageIndex + 1, (res) =>
        setNoMoreAdventures(!(res?.length > 0))
      )
    );
  };

  const getAllAdventuresItems = (adventures) => {
    if (adventures) {
      return adventures.map((adventure, i) => {
        const innerMenuItems = [
          {
            Icon: MdEdit,
            label: adventure.is_paid ? "שתף" : "ערוך",
            onClick: (id) =>
              navigate(`/${userLanguage}/create-adventure/${id}`),
          },
          { Icon: MdDelete, label: "מחק", onClick: handleDeleteAdventure },
        ];
        if (
          i >= currentAdventuresPage * adventuresPerPage &&
          i < (currentAdventuresPage + 1) * adventuresPerPage
        ) {
          const isMenuActive = activeMenu === adventure.id;
          return (
            <ScreenUserItem
              key={adventure.id}
              setShowMenu={() =>
                setActiveMenu(isMenuActive ? null : adventure.id)
              }
              menuActive={isMenuActive}
              innerMenuItems={innerMenuItems}
              handleDeleteAdventure={handleDeleteAdventure}
              adventure={adventure}
            />
          );
        }
        return <div />;
      });
    }
  };

  const handleDeleteAdventure = (adventureId) => {
    if (window.confirm("למחוק את ההרפתקה?")) {
      dispatch(deleteAdventure(adventureId));
    }
  };

  const renderNoAdventures = () => {
    return (
      <div className={"no-adventures-container container"}>
        <Paragraph className={"mr-6"}>
          לא קיימות הרפתקאות בתיבה, <br /> התחילו ליצור את ההרפתקה הראשונה שלכם!
        </Paragraph>

        <Element marginBottom={"5rem"} marginTop={"3rem"}>
          {/* <IconNoAdventure/> */}
        </Element>

        <HBox justifyContent={"center"}>
          <Link to={"/#"}>
            <Button>צרו הרפתקה</Button>
          </Link>
        </HBox>
      </div>
    );
  };

  const renderAdventuresButtons = () => {
    const isNextDisabled = isFinalAdventuresPage;
    const isPreviousDisabled = currentAdventuresPage === 0;
    const handleNext = () => {
      if (!isNextDisabled) {
        setCurrentAdventuresPage(currentAdventuresPage + 1);
        getMoreAdventures();
      }
    };
    const handlePrev = () =>
      !isPreviousDisabled &&
      setCurrentAdventuresPage(currentAdventuresPage - 1);
    const className = classNames("d-flex justify-content-center", {
      "flex-row": rtl,
      "flex-row-reverse": !rtl,
    });
    return (
      <>
        <div className={className}>
          <ToolTip
            id={"previous-adventure-button"}
            tip={<Translation id={DICT.PREVIOUS} />}
            delayShow={500}
            placeholder={
              <div
                className={classNames("adventures-proceed-button", {
                  disabled: isPreviousDisabled,
                })}
                onClick={handlePrev}
              >
                <MdNavigateNext />
              </div>
            }
          />
          <ToolTip
            id={"next-adventure-button"}
            tip={<Translation id={DICT.NEXT} />}
            delayShow={500}
            placeholder={
              <div
                className={classNames("adventures-proceed-button", {
                  disabled: isNextDisabled,
                })}
                onClick={handleNext}
              >
                <MdNavigateBefore />
              </div>
            }
          />
        </div>

        {/*{isFinalAdventuresPage &&*/}
        {/*    <Button onClick={getMoreAdventures} width={"250px"}*/}
        {/*            className={"d-block mt-4 px-5"} value={"טען הרפתקאות נוספות"}/>*/}
        {/*}*/}
      </>
    );
  };

  if (!isUserAdventuresLoaded) {
    return (
      <div className={"container pt-7"}>
        <LoaderContent />
        <LoaderPageBulletList />
      </div>
    );
  }

  return (
    <div className={"container pt-6 pt-md-7 screen-user-container"}>
      <div
        className={`d-flex flex-column-reverse flex-md-row justify-content-between`}
      >
        <div>
          <h1 className="fs-8 fs-md-9 m-0">
            <Translation id={DICT.HELLO} />, {userName}!
            <br className={"d-none d-sm-block"} />
            <Translation id={DICT.USER_PAGE_TITLE} />
          </h1>
          <Paragraph
            className={"fs-7"}
            color={textColor}
            style={{ maxWidth: "600px", lineHeight: "3.8rem" }}
          >
            <Translation id={DICT.USER_PAGE_DESCRIPTION} />
          </Paragraph>
        </div>
        <UserDetails
          userName={userData.name}
          userLastName={userData.lastName}
          userImage={userData.avatar}
          adventures={adventures}
        />
      </div>
      {!adventures ? (
        renderNoAdventures()
      ) : (
        <>
          <div className={"screen-user-gallery"}>
            {getAllAdventuresItems(adventures)}
          </div>
          {adventures.length > 14 && renderAdventuresButtons()}
        </>
      )}
    </div>
  );
};
