import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {colors, gutters} from '../../../00_base/variables';
import {Element, HBox} from 'react-stylesheet';
import {Anchor, Button, Icon} from '../../../01_atoms';
import {Link} from '@reach/router';

const CardActions = ({ items, itemStyle, style }) => {
    const actionButton = (action, index) => {
        const { onClick, href, label, to, icon } = action;
        return (
            <HBox alignItems={'center'} style={styles.item} key={index}>
                <Button style={{ ...itemStyle, ...action.style }} onClick={onClick}>
                    {!!to ? <Link to={to}>
                        {label && label}
                        {icon &&
                            <Element paddingLeft={label ? gutters.xs : 0}>
                                <Icon icon={icon} />
                            </Element>}
                    </Link>
                        :
                        <Anchor href={href}>
                            {label && label}
                            {icon &&
                                <Element paddingLeft={label ? gutters.xs : 0}>
                                    <Icon icon={icon} />
                                </Element>}
                        </Anchor>
                    }
                </Button>
            </HBox>
        )
    }

    return (
        <Actions className={'card-actions'} style={style} >
            {items.map(actionButton)}
        </Actions>
    )
}

const styles = {
    wrapper: {
        display: 'flex',
        backgroundColor: colors.gray_2,
        padding: 0,

    },
    item: {
        padding: `0 ${gutters.xxs}`
    }
};

const Actions = styled.div`
    display: flex;   
    margin: 0 -${gutters.xxs};
 `;

CardActions.defaultProps = {
    style: styles.wrapper,
    items: []
}

CardActions.propTypes = {
    style: PropTypes.object,
    items: PropTypes.array.isRequired,
};

export default CardActions;