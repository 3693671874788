import React, { useContext } from "react";
import { Subscribe } from "../../../03_organisms/subscribe/subscribe";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectShowInStoreRiddles } from "../../../../store/selectors";
import { Row } from "react-grid-system";
import { LoadingSection } from "../../../03_organisms/loading-sections/loading-section";
import { RiddleThumbnail } from "./riddle-thumbnail";
import { LanguageContext } from "../../../../languages";

export const FinalScreen = ({ riddle }) => {
  const navigate = useNavigate();
  const showInStoreRiddles = useSelector(selectShowInStoreRiddles);
  const { userLanguage } = useContext(LanguageContext);

  const renderDigitalRiddlesGrid = (riddleData, index) => {
    const handleRiddleClick = () =>
      navigate(`/${userLanguage}/single-riddle/${riddleData.id}`);
    return (
      <Row
        key={index}
        className="p-md-5 p-3 align-items-center"
        style={{ cursor: "pointer" }}
      >
        <RiddleThumbnail
          riddle={riddleData}
          handleRiddleClick={handleRiddleClick}
        />
      </Row>
    );
  };
  return (
    <div>
      <h2 className="my-0">כל הכבוד!</h2>
      <p className="font-size-lg" style={{ lineHeight: "32px" }}>
        הצלחתם לפתור את {riddle.title}, אין ספק שאתם ניחנים
        <br />
        בחוש יוצא דופן לפתרון חידות ותעלומות. רוצים עוד חידות?
        <br />
        הרשמו למטה לניוזלטר שלנו כדי לקבל חידות נוספות.
      </p>
      {showInStoreRiddles
        .filter((item) => item.id !== riddle.id)
        .slice(0, 3)
        .map(renderDigitalRiddlesGrid)}
      <Subscribe />
    </div>
  );
};
