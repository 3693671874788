import React from 'react';
import {HBox} from "react-stylesheet";
// import {
//     EmailShareButton,
//     FacebookShareButton,
//     InstapaperShareButton,
//     LineShareButton,
//     LinkedinShareButton,
//     LivejournalShareButton,
//     MailruShareButton,
//     OKShareButton,
//     PinterestShareButton,
//     PocketShareButton,
//     RedditShareButton,
//     TelegramShareButton,
//     TumblrShareButton,
//     TwitterShareButton,
//     ViberShareButton,
//     VKShareButton,
//     WhatsappShareButton,
//     WorkplaceShareButton,
//     TwitterIcon
// } from "react-share";
export const SocialShare = () => {
    return (
        <HBox className='social-wrapper'>

        </HBox>
    )
}