import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {Button, Heading} from "../../../../01_atoms";
import "../../../../../assets/styles/adventure-employee-final.scss";
import {selectCurrentAdventure} from "../../../../../store/selectors";
import {Link} from "react-router-dom";
import {CreateAdventureContext} from "../screen-adventure-create";
import {LanguageContext} from "../../../../../languages";

export const AdventureEmployeeFinal = () => {
    const adventure = useSelector(selectCurrentAdventure);
    const {setPaymentWarningActive} = useContext(CreateAdventureContext);
    const {userLanguage} = useContext(LanguageContext);
    const isEnglish = adventure?.language === "en";
    const sendLabel = isEnglish ? "Send it to " : "שלח אותה ל";
    const successLabel = isEnglish ? "The adventure sent to " : "ההרפתקה נשלחה ל";
    const createAnother = isEnglish ? "Create another greeting" : "ליצירת ברכה נוספת";

    const TITLE = <Heading tag="h3"
                           fontWeight={"bold"}>{isEnglish ? "Your adventure is ready" : "ההרפתקה מוכנה"}</Heading>;
    const SUBTITLE = <p>
        {isEnglish && <>You'll find it in your <a href={`/${userLanguage}/user`}>adventures box</a></>}
        {!isEnglish && <>תוכלו למצוא אותה ב<a href={`/${userLanguage}/user`}>תיבת ההרפתקאות</a></>}
    </p>
    const CTA = adventure.is_paid ?
        <Button color="#fff"
                value={`${successLabel}${adventure.json_data?.employee_email}`}/>
        :
        <Button color="#fff"
                onClick={setPaymentWarningActive}
                value={`${sendLabel} - ${adventure.json_data?.employee_email}`}/>;
    const CREATE_ANOTHER = <p><Link to={`/${userLanguage}/adventure/324`}>{createAnother}</Link></p>;


    return (
        <div className="adventure-employee-final">
            {TITLE}
            {SUBTITLE}
            {CTA}
            {CREATE_ANOTHER}
        </div>
    )
};