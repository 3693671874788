import React from 'react';
import {Loader} from './loader-styles';

class SimpleLoader extends React.Component {
    render() {
        return (
            <Loader size={this.props.size} color={this.props.color}/>
        )
    }
    static defaultProps = {
        color: '#07d',
        size: '30'
    }
}

export default SimpleLoader;

