import React from 'react';
import PropTypes from "prop-types";

class Circle extends React.Component {
    render() {
        const {props} = this,
            {style, className, children, size, onClick, cursor, background, border} = props,
            classNames = `circle ${className}`;

        return (
            <div className={classNames}
            onClick={onClick}
            style={{
                width: `${size}px`,
                height: `${size}px`,
                background: background,
                border: border,
                borderRadius: '50%',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: cursor,
                ...style
            }}>
            {children}
        </div>
        )
    }

    static defaultProps = {
        className: '',
        size: 20,
        background: '#ffffff',
    }
}

Circle.propTypes = {
    className: PropTypes.string,
    size: PropTypes.number,
    background: PropTypes.string,
    border: PropTypes.string,
    onClick: PropTypes.func
};
export default Circle;