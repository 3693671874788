import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {font_size, gutters} from "../../00_base/variables";
import {arrayMoveImmutable} from "array-move";
import Heading from "../../01_atoms/text/a-heading";
import {Tabs} from "../../03_organisms";
import {RiddleMainDetails} from "./main-details/riddle-main-details";
import {RiddleTemplates} from "./template/riddle-templates";
import {RiddleDesign} from "./design/riddle-design";
import {Button} from "../../01_atoms";
import {setActiveRiddle, updateRiddle} from "../../../store/actions/riddle-actions";
import "../../../assets/styles/create-riddle.scss";

const CreateRiddle = ({frames, riddle = {}, singleRiddle, addNewFrame}) => {
    const [renderFrames, serRenderFrames] = useState(frames);

    const isNewRiddle = riddle.id === -1;

    const dispatch = useDispatch();


    const reorderFrames = ({oldIndex, newIndex}) => {
        const updatedFrames = arrayMoveImmutable(frames, oldIndex, newIndex);
        serRenderFrames(updatedFrames);
        handleChange("riddlestemplates", updatedFrames.map(frame => frame.id));
    };

    const handleChange = (name, value) => {
        dispatch(updateRiddle({...riddle, [name]: value}));
    };

    const handleTemplateDataChange = (name, value) => {
        dispatch(updateRiddle({...riddle, templatedata: {...riddle.templatedata || {}, [name]: value} }));
    };

    const tabsItems = [
        {label: singleRiddle ? "פרטי החידה" : "פרטי הסצנה", content: <RiddleMainDetails riddle={riddle} handleChange={handleChange}/>},
        {label: "פריימים", content: <RiddleTemplates addNewFrame={addNewFrame} indexStart={0} frames={renderFrames}  reorderFrames={reorderFrames} templatedata={riddle.templatedata} handleChange={handleChange}/>},
        {label: "עיצוב", content: <RiddleDesign templatedata={riddle.templatedata} handleChange={handleTemplateDataChange}/>},
    ];

    return (
        <div className="single-riddle scroller">

            <Button onClick={() => dispatch(setActiveRiddle(null))}>חזור</Button>

            <Heading tag={"h3"} value={riddle.title}/>

            <Tabs items={isNewRiddle ? tabsItems.slice(0,2) : tabsItems}
                  direction={"row"}
                  tabLabelStyle={{fontSize: font_size.md, backgroundColor: "transparent"}}
                  contentStyle={{marginTop: gutters.lg}}/>

        </div>
    )
};

export default CreateRiddle;