import styled from "styled-components";
import {colors, viewport} from "../../00_base/variables";

export const TableWrapper = styled.div`
    width: 100%;
    overflow:hidden;
`
export const TableContainer = styled.div`
    max-width: 1040px;
    padding: 110px 5px 0;
    margin: 0 auto;
    width: 100%;
    min-height: 100vh;
    overflow: auto;
    @media(min-width: ${viewport.lg}) {
        padding: 110px 15px 0;
    }
    .single-adventure & {
        padding-top: 84px;
    }
`;
export const TableTitle = styled.div`
    position: fixed;
    width: 100%;
    // left: 0;
    // right: 0;
    top:0;
    z-index: 100000;
    background-color: #f1f1f1; 
    border-bottom: 1px solid #000;
    transition: right .3s;
    padding: 15px 0;
    transition: width .3s;
    .sidebar-open & {
        width: calc(100% - 231px);
    }
    h1, h2 {
        font-size: 36px;
        text-align: center;
        font-weight: normal;
        margin: 0 0;
        color: ${colors.black};
    }
`;
export const Table = styled.table`
    width: 100%;
`;
export const TableHeaderLabel = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    .table-header-icon {
        cursor: pointer;
        // border-radius: 2.5rem;
        &.active {
            background-color: #5388ff;
        }
    }
`;
export const TableFilterMenu = styled.div`
    position: absolute;
    box-shadow: .6px .6px 3px 0 rgba(0,0,0,.75);
    border-radius: 1.3px;
    top: 30px;
    right: 20px;
    z-index: 1;
    background-color:#fff;
    padding: 5px 0;
    .table-filter-menu-item {
        label {
            font-size: 16px;
            font-weight: normal;
            display: flex;
            cursor: pointer;
            align-items: center;
            white-space: nowrap;
            padding:  5px 15px;
        }
        &:hover {
            background-color: #eaf1f4;
        }
    }
   
`;
export const Th = styled.th`
    font-size: 1.4rem;
    text-align: right;
`;
export const Tr = styled.tr`
    padding: 0 5px;
    margin: 10px 0;
    // margin-bottom :20px;
    height: 50px;
    vertical-align: ${props => props.vAlign};
`;
export const Td = styled.td`
    font-size: 1.6rem;
    padding: 8px 0;
    vertical-align: ${props => props.vAlign || "middle"};
`;
export const TdContent = styled.div`
   height: ${props => props.maxHeight || "50px"};
   background-color: ${props => props.isHead ? "transparent" : props.backgroundColor || "#fff"};
   padding: 13px 10px;
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: center;
   th & {
     padding: 10px 10px 0;
   }
   td:first-child & {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        
    }
    td:last-child & {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px; 
        
    }
`;
export const TdInnerMenuTrigger = styled.input`
    position: absolute;
    width:100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
`;