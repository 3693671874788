import React, {useEffect, useState} from "react";
import {FieldCheckbox, FieldSelect, FieldVideo} from "../../../../../02_molecules";
// import {MdArrowDropDown, MdArrowDropUp} from "react-icons/all";

const BACKGROUND = "background";
const END = "end";

const playOnTypes = [
    {id: 0, label: "ברקע", value: BACKGROUND},
    {id: 1, label: "בפיתרון הפריים", value: END},
];

export const AdditionalDataAudio = (
    {handleToolDataChange, frameAudio, audioPlayOn = END}
) => {
    const [isActive, setIsActive] = useState(!!frameAudio);
    const [isOpen, setIsOpen] = useState(!!frameAudio);

    useEffect(() => setIsOpen(isActive), [isActive]);

    const toggle = (name, value) => {
        setIsActive(value);
        if (!value) {
            handleToolDataChange("frameAudio", []);
        }
    };

    const findPlayOnType = value => {
        return playOnTypes.find(item => item.value === value)
    };

    const IconDrop = false
    const wrapperStyle = {borderBottom: `${isOpen ? 1 : 0}px dashed #000`, marginBottom: "10px"};

    return (
        <div style={wrapperStyle}>
            <div className={"d-flex justify-content-between align-items-center"}>
            <FieldCheckbox options={["הוסף אודיו"]}
                           name="textActive"
                           controlStyle={{marginBottom: 0}}
                           value={isActive ? ["הוסף אודיו"] : []}
                           changeHandler={toggle}/>
                {isActive && <IconDrop onClick={() => setIsOpen(!isOpen)} cursor={"pointer"} size={20}/>}
            </div>

            {isActive && isOpen &&
            <div className={"p-3"}>
                <FieldSelect label="מתי להשמיע את האודיו?"
                             name="audioPlayOn"
                             options={playOnTypes}
                             value={findPlayOnType(audioPlayOn)}
                             changeHandler={(name, value) => {
                                 handleToolDataChange(name, findPlayOnType(value.id).value)
                             }}/>
                <FieldVideo videoType={2}
                            name={"frameAudio"}
                            triggerValue={"העלו אודיו"}
                            changeHandler={handleToolDataChange}
                            value={frameAudio}/>

            </div>}
        </div>
    )
}