import React from "react";
import {LoaderBulletList} from "../../../01_atoms/loaders/loader-content/loader-content";

export const AdventuresLoading = () => {
    return (
        <>
            <LoaderBulletList/>
            <LoaderBulletList/>
            <LoaderBulletList/>
            <LoaderBulletList/>
            <LoaderBulletList/>
        </>
    )
}
