import React, {useContext} from "react";
import {Col, Row} from "react-grid-system";
import {FilesUploader} from "../../../../../02_molecules";
import {FrameContext} from "../../single-frame";

export const SingleFrameDesignImage = (
    {screenBackgroundImage, screenBackgroundImageDesktop}
) => {
    const {handleToolDataChange} = useContext(FrameContext);
    return (
        <Row>
            <Col>
            <FilesUploader name="screenBackgroundImage"
                           value={screenBackgroundImage}
                           multiple={false}
                           previewWidth="100%"
                           previewHeight="200px"
                           triggerValue={"תמונת רקע Mobile"}
                           labelStyle={{textAlign: "right"}}
                           className="frame-thumbnail"
                           label="תמונת רקע Mobile"
                           changeHandler={handleToolDataChange}/>
            </Col>
            <Col>
            <FilesUploader name="screenBackgroundImageDesktop"
                           value={screenBackgroundImageDesktop}
                           multiple={false}
                           previewWidth="100%"
                           previewHeight="200px"
                           triggerValue={"תמונת רקע Desktop"}
                           labelStyle={{textAlign: "right"}}
                           className="frame-thumbnail"
                           label="תמונת רקע Desktop"
                           changeHandler={handleToolDataChange}/>
            </Col>
        </Row>
    )
}