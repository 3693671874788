import React from "react";
import { Element, HBox } from "react-stylesheet";
import { Col, Container } from "react-grid-system";
import { BackgroundImage, Heading, Paragraph } from "../../../01_atoms";
import { SkewElement } from "../../../02_molecules";
import { gutters } from "../../../00_base/variables";
import { Page } from "../../../04_layout";
import SimpleLoader from "../../../01_atoms/loaders/simple-loader";
import { useParams, useLocation } from "react-router-dom";

const withRouter = (Component) => {
  return (props) => {
    const params = useParams();
    const location = useLocation();
    return <Component {...props} params={params} location={location} />;
  };
};

class ScreenProduct extends React.Component {
  constructor(props) {
    super(props);

    const id = this.props.params.id;
    this.state = {
      isLoading: true,
      ...this.props.location.state?.productData,
      id: id,
    };
  }

  componentDidMount() {
    fetch(
      `https://admin.youdoadventures.com/wp-json/wp/v2/posts/${this.state.id}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((success) =>
        this.setState({
          ...success,
          isLoading: false,
        })
      )
      .catch((error) => console.log(error));
  }

  render() {
    let { title, content, better_featured_image, acf, isLoading } = this.state;
    title = title && title.rendered;
    content = content && content.rendered;

    const { inner_image, about } = acf ? acf : [];
    const postImage = inner_image
      ? inner_image
      : better_featured_image && better_featured_image.source_url;

    if (isLoading) {
      return <SimpleLoader />;
    }
    return (
      <Page className={"flex-column"}>
        <Element width="100%">
          <BackgroundImage url={postImage} width={"100%"} height={"40vh"} />
          <SkewElement
            background={"#ffffff"}
            before={{ deg: -2, origin: "left", direction: "Y" }}
          >
            <Container>
              <HBox className={"justify-content-center py-3 py-sm-5"}>
                <Col sm={10} md={8}>
                  <Element marginBottom={gutters.md}>
                    <Heading
                      tag={"h1"}
                      color={"#000000"}
                      className={"scroll-element text-right responsive-text"}
                      value={title}
                    />
                  </Element>
                  <Paragraph
                    tag={"p"}
                    textAlign={"right"}
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                  <Paragraph
                    tag={"p"}
                    textAlign={"right"}
                    dangerouslySetInnerHTML={{ __html: about }}
                  />
                </Col>
              </HBox>
            </Container>
          </SkewElement>
        </Element>
      </Page>
    );
  }

  static defaultProps = {
    title: { rendered: "" },
    content: { rendered: "" },
    acf: "",
  };
}

export default withRouter(ScreenProduct);
