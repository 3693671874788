import React from "react";
import {FieldText, FieldTextArea} from "../../../../02_molecules";

export const TextContent = ({itemValue, changeHandler, maxHeight}) => {
const value = itemValue?.value || itemValue;
    return (
        <>
            <FieldTextArea name="value"
                           value={typeof value === "string" ? value : ""}
                           wysiwyg={true}
                           toolbarHidden={false}
                           changeHandler={changeHandler}/>

            <FieldText name="maxHeight"
                       label="גובה"
                       value={maxHeight}
                       changeHandler={changeHandler}/>
        </>
    )
};