import React, {useContext, useState} from "react";
import {MdMoreVert, MdRemoveRedEye} from "react-icons/md";
import {AuthContext} from "../../../../providers/auth-provider";
import {dateFormat} from "../../../../helpers/dates/date-format";
import {FieldCheckbox, FieldText, FieldTextArea, FilesUploader} from "../../../02_molecules";
import {TableInnerMenu, Td, TdContent, Tr} from "../../../03_organisms";
import {ToggleIcon} from "../../../03_organisms";
import {colors} from "../../../00_base/variables";
import * as categoriesActions from "../../../../store/actions/categories-actions";
import {setActiveCategory} from "../../../../store/actions/categories-actions";
import {useDispatch} from "react-redux";

export const CategoryItem = (
    {
        item,
        index,
        innerMenuItems,
        setInnerMenuActive,
        menuActive,
        toggleMarkItem,
    }) => {
    const {isTom} = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);

    const dispatch = useDispatch();

    const handleChange = (name, value) => dispatch(categoriesActions.updateCategory({...item, [name]: value}));
    const toggle = () => setIsOpen(!isOpen);
    return (
        <>
            <Tr key={index} className={"category-item"}>
                {isTom &&
                <>
                    <Td>
                        <TdContent>
                            <FieldCheckbox options={['סמן']}
                                           inputBorder={"2px solid #5388ff"}
                                           inputBackground={"#fff"}
                                           controlStyle={{marginBottom: 0}}
                                           changeHandler={(name, isChecked) => toggleMarkItem(item.id, isChecked)}
                                           name={`mark`}
                                           hideOptionLabel={true}/>
                        </TdContent>
                    </Td>
                    <Td>
                        <TdContent style={{fontWeight: "bold"}}>{item.id || "-"}</TdContent>
                    </Td>
                </>
                }

                <Td>
                    <TdContent>
                        <FieldText name={"title"}
                                   changeHandler={handleChange}
                                   value={item.title}
                                   controlStyle={controlStyle}
                                   inputStyle={inputStyle}/>
                    </TdContent>
                </Td>

                <Td className={"p-0"}>
                    <TdContent className={"bg-white"}>
                        {/*<Image height={50} block className={"mx-auto"} src={item.icon}/>*/}
                        <FilesUploader name="icon"
                                       triggerValue={"50 * 50"}
                                       value={item.icon}
                                       multiple={false}
                                       previewWidth={60}
                                       triggerStyle={{cursor:"pointer"}}
                                       previewHeight={70}
                                       controlStyle={{display:"flex", alignItems:"center", justifyContent:"center"}}
                                       changeHandler={handleChange}/>
                    </TdContent>
                </Td>

                <Td>
                    <TdContent>
                        <FieldText name={"category_name"}
                                   changeHandler={handleChange}
                                   value={item.category_name}
                                   controlStyle={controlStyle}
                                   inputStyle={inputStyle}/>
                    </TdContent>
                </Td>

                <Td>
                    <TdContent>
                        <FieldText name={"created_at"}
                                   changeHandler={handleChange}
                                   value={dateFormat(item.created_at)}
                                   controlStyle={controlStyle}
                                   inputStyle={inputStyle}/>
                    </TdContent>
                </Td>

                <Td>
                    <TdContent>
                        <div className={"d-flex align-items-center"}>

                            <MdRemoveRedEye onClick={() => dispatch(setActiveCategory(item))}
                                            cursor={"pointer"}
                                            className={"ml-4"}/>

                            <ToggleIcon toggle={toggle}
                                        size={23}
                                        isOpen={isOpen}/>

                            <MdMoreVert size={20} onClick={setInnerMenuActive}
                                        className="inner-menu-trigger mr-4"
                                        cursor={"pointer"} color={"#d2d2d2"}/>
                        </div>
                        {menuActive && <TableInnerMenu items={innerMenuItems} itemId={item.id}/>}
                    </TdContent>
                </Td>
            </Tr>
            {isOpen && <Tr>
                <Td colSpan={10} height="auto" className={"category-description"}>
                    <FieldTextArea name="description"
                                   changeHandler={handleChange}
                                   wysiwyg={true}
                                   toolbarHidden={false}
                                   controlStyle={{marginBottom: 0}}
                                   inputStyle={{backgroundColor: colors.white}}
                                   value={item.description}/>
                </Td>
            </Tr>}
        </>
    )
}

const controlStyle = {margin: 0, height: "100%"};
const inputStyle = {border: 0, height: "100%", padding: 0};