export const image = "https://c.staticblitz.com/assets/media/client/ui/SideBar/BottomBranding/KendoAd/variants/react_b-e14992871859f5c88d81952414a09dde.png";
export const reviews = [
    {
        name: "גלית כספי",
        description: "הקופסה לבת מצווה מושלמת. האורחים הופתעו, הילדה הרגישה במרכז העניינים ולנו נשארה מזכרת מרגשת מהאירוע. השירות שלכם מעולה. תודה רבה",
        image: image
    },
    {
        name: "ליאורה דוד",
        description: "חגגנו יום הולדת 70 לאבא שלי עם כל המשפחה. קופסת הבריחה הייתה מרשימה ומפתיעה. הברכות והתמונות שולבו בצורה מעניינת שגרמה לכל המשפחה לגלות עניין ולהשתתף.",
        image: image
    },
    {
        name: "יעל ארצי עילם",
        description: "הזמנו את הרפתקת חלל ליום הולדת של ביתי בת ה-11. היה מטורף. לא הפסקנו להתלהב. ההפתעה של החידה בהתאמה אישית משדרגת את החוויה ממש. אתם אלופים.",
        image: image
    },
    {
        name: "אורית ליבוביץ",
        description: "תודה רבה על הרפתקה מושלמת. נויה כל כך נהננתה והתרגשה. ללא ספק יום ההולדת הכי מיוחדת שהייתה לה. תודה על השירות, תודה על האיכות, תודה על הקסם",
        image: image
    },
    {
        name: "ירון בן יהודה , אינטל",
        description: "היה כיף ומרגש. כולם נהנו… צעירים ומבוגרים. תמשיכו להצליח היה מצחיק ומרגש. זה אדיר שכל החידות היו עלינו והכל דרך הזום. כולם שתפו פעולה וזה היה מוצלח מאוד.",
        image: image
    },
];

export const reviews_en = [
    {
        name: "Galit Caspi",
        description: "The bat mitzvah box is perfect. The guests were surprised, the girl felt at the center of things and we were left with an exciting souvenir from the event. Your service is excellent. Thank you",
        image: image
    },
    {
        name: "Liora David",
        description: "We celebrated my dad's 70th birthday with the whole family. The escape box was impressive and surprising. The greetings and photos were incorporated in an interesting way that made the whole family show interest and participate.",
        image: image
    },
    {
        name: "Yael Arzit Ilem",
        description: "We booked the space adventure for my 11-year-old daughter's birthday. Was crazy. We did not stop getting excited. The surprise of the custom riddle really upgrades the experience. You are champions.",
        image: image
    },
    {
        name: "Orit Leiboviz",
        description: "Thank you so much for a perfect adventure. Noya was so enjoying herself and excited. Without a doubt the most special birthday she had. Thank you for the service, thank you for the quality, thank you for the charm\n",
        image: image
    },
    {
        name: "Yaron Ben-Yehuda, Intel",
        description: "It was fun and exciting. Everyone enjoyed… young and old. Keep Successful was funny and exciting. It's awesome that all the puzzles were on us and all through the zoom. Everyone cooperated and it was very successful.\n",
        image: image
    },
];
