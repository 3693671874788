import React from "react";
import {Button} from "../../../01_atoms";
import {FieldText} from "../../../02_molecules";
 import "../../../../assets/styles/table-items-header.scss";

export const TableItemsHeader = ({clickHandler, changeHandler = () => console.log("")}) => {
    return (
        <div className="table-items-header">
            <div className="table-items-header-search">
            <FieldText placeholder="חיפוש"
                       name={"search"}
                       controlStyle={controlStyle}
                       changeHandler={changeHandler}
                       inputStyle={inputStyle}/>
            <Button value="+ צור תבנית חדשה"
                    onClick={clickHandler}
                    className="table-items-add"/>
            </div>
        </div>
    )
}

const inputStyle = {backgroundColor:"#fff", border:0, borderRadius: "2px"};
const controlStyle = {margin: '0 0 0 20px'};