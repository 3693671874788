import React, {useContext, useState} from "react";
import {useSelector} from "react-redux";
import {getToolComponent} from "../get-tool-component";
import {FrameContext} from "../single-frame";
import {selectAllCategories} from "../../../../../store/selectors";
import {FieldSelect} from "../../../../02_molecules";
import {ChooseTool} from "./choose-tool/choose-tool";
import {FrameFinalContent} from "../../../../03_organisms";

export const SingleFrameTool = (
    {
        className,
        riddle,
        constant,
        categories,
        categorydata,
        handleChange,
        handleCategoryDataChange
    }) => {
    const allCategories = useSelector(selectAllCategories);
    const category = allCategories.find(cat => cat.id === categories[0]) || {};
    const CreateTool = getToolComponent(categories[0]);
    const {handleFrameChange, handleToolDataChange} = useContext(FrameContext);

    const [isOpen, setIsOpen] = useState(true);

    const handleToolIdChange = handleChange || handleFrameChange;


    return (
        <div className={className}>
            {!constant &&
            <h4 className="single-frame-box-title" onClick={() => setIsOpen(!isOpen)}>
                {`כלי ראשי ${category.title ? `(${category.title})` : ""}`}
            </h4>}

            <div className={"single-frame-section frame-tool-wrapper additional-data-body-content"}>
                {!constant &&
                <>
                    {category.id ?
                        <FieldSelect name={"categories"}
                                     placeholder={"בחר כלי דיגיטלי"}
                                     value={{...category, label: category.title || "בחר כלי", value: category.id}}
                                     options={allCategories.map(category => {
                                         return {...category, label: category.title, value: category.id}
                                     })}
                                     controlStyle={{maxWidth: "250px", zIndex: 10, position: "relative"}}
                                     changeHandler={(name, value) => handleToolIdChange("categories", [value.id])}/>
                        :
                        <ChooseTool onClick={(category) => {
                            handleToolIdChange("categories", [category.id]);
                            // handleToolDataChange("categorydata", category.mock_data)
                        }}/>
                    }
                </>
                }

                {categories[0] &&
                <CreateTool {...category?.mock_data || {}}
                            {...categorydata}
                    // categorydata={categorydata}
                            templatedata={riddle?.templatedata || {}}
                            handleChange={handleToolIdChange}
                            handleToolDataChange={handleCategoryDataChange || handleToolDataChange}
                />}

                <FrameFinalContent handleChange={handleCategoryDataChange || handleToolDataChange}
                                   correctTitle={categorydata.correctTitle}
                                   correctSubTitle={categorydata.correctSubTitle}
                                   correctDescription={categorydata.correctDescription}
                                   finalImageUrl={categorydata.finalImageUrl}/>
            </div>
        </div>
    )
};