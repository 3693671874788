import React from 'react';
import Text from './a-text';

const Paragraph = (props) => {
    if (props.dangerouslySetInnerHTML) {
        return <Text value={<p dangerouslySetInnerHTML={{__html: props.dangerouslySetInnerHTML}}/>} {...props}/>
    }
    return <Text tag="p" {...props} />
};

export default Paragraph;
